import React from "react";
import axios from "axios";
import { createRoot } from 'react-dom/client';

import { useTranslation } from "react-i18next";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col, Badge, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import { Menu, Item, Separator, useContextMenu } from "react-contexify"
import "react-contexify/dist/ReactContexify.css";

import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as commonFunc from 'scripts/common';

import AGenerateModel, { getRgbaFromHex, convertWidthToGrid } from 'components/generic/AGenerateModel';
import PictureFrag from 'components/generic/PictureFrag';

//Création du schéma pour le paramètrage du formulaire
const schema = yup.object().shape({
    login: yup.string(),
    email: yup.string(),
    refName: yup.string(),
    refFName: yup.string(),
}).required();

function UserProfile(props) {
    const { t } = useTranslation();

    React.useEffect(() => {
        //console.debug("mount");
        if (props.updateUser) props.updateUser(function (data) {
            setUser(data);
        });

        return () => {
            //console.debug("unmount");
            if (selectedFile) {
                URL.revokeObjectURL(selectedFile.preview);
            }
            if (window.modalContainer) {
                window.modalContainer.unmount();
                window.modalContainer = null;
            }
        }
    }, []);

    //Paramètrage du formulaire
    const { register, getValues, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    const [user, setUser] = React.useState({});

    const [selectedFile, setSelectedFile] = React.useState();

    const userAvatar = React.createRef();
    const userPicAvatar = React.createRef();
    const showContextMenu = useContextMenu().show;
    const hideAllMenu = useContextMenu().hideAll;

    const modalGenManager = React.useRef();

    function loadUser() {
        setUser({});
        reset();
        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        if (props.updateUser) props.updateUser(function (data) {//TODO : voir si besoin de réactualiser pour l'appli ou juste pour UserProfile
            setUser(data);

            if (selectedFile) URL.revokeObjectURL(selectedFile.preview);
            setSelectedFile(null);

            Swal.close();
        });
    }

    async function saveUser(data) {
        //e.preventDefault();
        //console.debug(data);

        Swal.fire({
            title: "Sauvegarde en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var req = { login: data.login };
                
        if (selectedFile) {
            console.debug(selectedFile);
            var fileUpReq = {
                name: "AVA_" + Date.now() + "_" + selectedFile.name,
                ftype: "AVA", //cat Photo
                fvisible: 1,
                fcntry: "FR",
                fbindId: 2, //Type
                factId: -1,
            };

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('args', JSON.stringify(fileUpReq));

            //var fileArgs = {};
            //fileArgs.Files = [selectedFile];//Check la récup du File
            //fileArgs.formData = fileUpReq;



            /*autoUpload: false,
                acceptFileTypes: /(\.|\/)(gif|jpe?g|png|avi|mp4)$/i,
                maxFileSize: 9990000,
                sequentialUploads: true,
                //limitConcurrentUploads: 2,
                disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator.userAgent),
                previewMaxWidth: 100,
                previewMaxHeight: 100,
                previewCrop: true,
                dropZone: $("#files")*/

            const onUploadProgress = (event) => {
                console.debug(event);
                const percentage = Math.round((100 * event.loaded) / event.total);
                console.log(percentage);
                /*let progressBarElement = document.getElementById("progress-bar");
                progressBarElement.innerHTML = percentage + "%";
                progressBarElement.setAttribute("aria-valuenow", percentage);
                progressBarElement.style.width = percentage + "%"; */
            };

            await axios.post("../api/data/UploadFiles", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress
            }).then(response => {
                if (response.data && response.data.isSuccess) {
                    req.avatar = "../api/data/DownloadFileByUri?fileID=" + response.data.result + "&bindID=-1&newWindow=false";//&filetypeID=-1
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de l'upload des fichiers :<br>" + response.data.errorMessage
                    });
                }
            }).catch(error => {
                console.debug("FILES ERR");
                console.debug(error);
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de l'upload des fichiers :<br>" + error.message
                });
            });
        }


        commonFunc.postMethod("../api/UpdateUser", req).then((response) => {
            if (response.data.isSuccess) {
                Swal.fire({
                    icon: "success",
                    title: "Confirmation",
                    html: "Modifications apportées avec succès",
                    didOpen: () => { Swal.hideLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).then((result) => {
                    loadUser();
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la modification de l'utilisateur :<br>" + response.data.errorMessage
                });
            }
        });
    }

    function changePwd() {
        var title = "Changement de mot de passe";

        if (window.modalContainer) {
            window.modalContainer.unmount();
            window.modalContainer = null;
        }

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (data, isUpdate) {
            return new Promise((resolve, reject) => {
                if (data) {
                    console.debug(data);

                    var infosPwd = data.pwdStrength;
                    if (infosPwd.value >= 80) {

                        commonFunc.postMethod("../api/ChangePassword", data).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data && data.isSuccess) {
                                Swal.fire({
                                    icon: "success",
                                    title: t("gal_success"),
                                    html: t("gal_successUpd"),
                                    didOpen: () => { Swal.hideLoading() },
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                }).then((result) => {
                                    //Refresh ???
                                });

                                //data.result.idResponse = data.result.idClient;
                                //resolve(data.result);

                            } else {
                                var supErrors = "";
                                if (data.errors && data.errors.length) {
                                    supErrors = data.errors.map((error) => {
                                        return "<br>" + error.desc + "(" + error.value + ")";
                                    });
                                }
                                Swal.fire({
                                    icon: "error",
                                    title: t("gal_fail"),
                                    html: t("gal_errorSave") + " :<br>" + data.errorMessage + supErrors
                                });
                                //resolve(data);
                            }
                            window.modalContainer.unmount();
                            resolve();
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: t("gal_warning"),
                            html: t("register_errorPwdStrength"),
                        });
                    }
                }
                else {
                    window.modalContainer.unmount();
                    resolve();
                }
            });
        };

        commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: "SITES" }).then((responseModels) => {
            Swal.close();

            var data = responseModels.data;
            if (data && data.isSuccess) {
                var models = data.result.models;

                var pwdModel = models.filter((model) => model.extID === "DLG_PWD");
                if (pwdModel && pwdModel.length) {
                    window.modalContainer = createRoot(document.createElement('div'));
                    window.modalContainer.render(<ChangePwdModal parentContext={{ props: props }} data={user} modalGenManager={modalGenManager} title={title} configModel={JSON.parse(pwdModel[0].value)} modelsList={null} callback={callbackModal}></ChangePwdModal>);
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Configuration introuvable"
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Echec de récupération des modèles site :<br>" + responseModels.data.errorMessage
                });
            }
        });
    }

    function changeHandler(event) {
        var file = event.target.files[0];
        if (file) {
            if (selectedFile) {
                URL.revokeObjectURL(selectedFile.preview);
            }
            file.preview = URL.createObjectURL(file);
            setSelectedFile(file);
        }
    }
    async function siteAvatarUpdate(file) {
        if (file) {
            if (selectedFile) {
                URL.revokeObjectURL(selectedFile.preview);
            }
            //file.preview = URL.createObjectURL(file);
            setSelectedFile(file);

            console.debug(file);
            var fileUpReq = {
                name: "AVA_" + Date.now() + "_" + file.name,
                ftype: "AVA", //cat Photo
                fvisible: 1,
                fcntry: "FR",
                fbindId: 2, //Type
                factId: -1,
            };

            const formData = new FormData();
            formData.append('file', file);
            formData.append('args', JSON.stringify(fileUpReq));

            const onUploadProgress = (event) => {
                console.debug(event);
                const percentage = Math.round((100 * event.loaded) / event.total);
                console.log(percentage);
            };

            await axios.post("../api/data/UploadFiles", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress
            }).then(response => {
                if (response.data && response.data.isSuccess) {
                    Swal.fire({
                        icon: "success",
                        title: "Confirmation",
                        html: "Modifications apportées avec succès",
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        loadUser();
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de l'upload des fichiers :<br>" + response.data.errorMessage
                    });
                }
            }).catch(error => {
                console.debug("FILES ERR");
                console.debug(error);
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de l'upload des fichiers :<br>" + error.message
                });
            });
        }
    }
    function fileDel({ sysEvent, props, triggerEvent, data }) {
        if (pictureData.functionManager) pictureData.functionManager.setValue(null);
        setSelectedFile(null);

        //TODO : removeFile
        /*var req = { fileID: fileID, fileTypeID: fileTypeID, bindID: bindID, bindType: globalProps.fileTypeID, OpName: "REMOVE_" + globalProps.fileTypeID + "FILE", isPermanent: true };
        if (req.fileID && req.bindID) {
            var url = globalProps.altRemoveFilesUrl || "../api/data/RemoveFile";
            return commonFunc.postMethod(url, req);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Echec",
                html: "Echec de Suppression : Arguments invalides"
            });
        }*/
    }

    //console.debug("render");

    if (!user) {
        //loading
        return null;
    }
    else {
        //Déclaration des paramètres et référence pour gérer une compatibilitée avec reactstrap, autrement voici un exemple : <input {...register("password")} />
        const { ref: refLogin, ...restLogin } = register("login");
        const { ref: refEmail, ...restEmail } = register("email");
        const { ref: refName, ...restName } = register("name");
        const { ref: refFName, ...restFName } = register("firstname");
        console.debug(selectedFile);

        var accountType;
        var rightsType;
        if (user) {
            switch (user.primaryGroupId) {
                case 1: accountType = "Employé"; break;
                case 2: accountType = "Client"; break;
                case 3: accountType = "Partenaire"; break;
                case 0: accountType = "Gestionnaire"; break;
                default: accountType = "Aucun"; break;
            }
            switch (user.primarySId) {
                case 1: rightsType = "Limité"; break;
                case 2: rightsType = "Classique"; break;
                case 3: rightsType = "Gestionnaire"; break;
                case 4: rightsType = "Admin"; break;
                case 5: rightsType = "API"; break;
                default: rightsType = "Aucun"; break;
            }
        }

        var pictureData = {
            disabled: true,
            argumentsObj: { "url": "api/site/UploadSiteFiles", "ftype": "AVA", "value": "", "target": "IDSite", "trigger": "", "bindType": "SITE", "fileTypeID": 0, "saveProperty": "Avatar", "savePropertyAsUrl": true, cAcceptTypes: "image/png, image/gif, image/jpg, image/jpeg" },
            customDesign: {
                design: {
                    "width": "124px",
                    "height": "124px",
                    "borderRadius": "50%"
                }
            }
        };
        if (user.avatar) pictureData.defaultValue = user.avatar;

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <Card className="card-user">
                                <div className="image">
                                    <img
                                        alt="..."
                                        src={require("assets/img/bg/backblur21.jpg")}
                                        style={{maxWidth: "100%"}}
                                    />
                                </div>
                                <CardBody className="text-center">
                                    <div className="author text-info">
                                        <div>
                                            {user.avatar != null && user.avatar !== undefined ? <div className="d-flex justify-content-center">
                                                <PictureFrag key={user.avatar} data={pictureData} formManager={{ register: () => { return { ref: userPicAvatar }; }, setValue: () => { } }} modelFctManager={{ getRgbaFromHex: getRgbaFromHex, convertWidthToGrid: convertWidthToGrid, extFileCb: siteAvatarUpdate }} bindPropResult="" parentDisabled={false}
                                                    srcFileManager={{ className: "siteAvatar", showContextMenu: showContextMenu, hideAllMenu: hideAllMenu }} />
                                            </div> : ""}
                                            <h5 className="title">{user.userName}</h5>
                                        </div>
                                        <p className="description">Compte {accountType}</p>
                                        <p className="description">Accès {rightsType}</p>
                                        <div><b>Statut :</b> {user.status ? <Badge color="success">Actif</Badge> : <Badge color="danger">Inactif</Badge>} <Badge>{user.provider}</Badge></div>
                                        <div>ID : {user.id}</div>
                                        <div>Email : {user.email}</div>
                                    </div>


                                    <Button
                                        className="btn-round ms-1 mb-3"
                                        color="secondary"
                                        onClick={() => changePwd()}
                                    >
                                        Changer de mot de passe
                                    </Button>
                                </CardBody>
                                <CardFooter className="hidden">
                                    <hr />
                                    <div className="button-container">
                                        <Row>
                                            <Col className="ms-auto" lg="3" md="6" xs="4">
                                                <h5>
                                                    150,963 <br />
                                                    <small>Fichiers</small>
                                                </h5>
                                            </Col>
                                            <Col className="ms-auto me-auto" lg="4" md="6" xs="4">
                                                <h5>
                                                    150 Go <br />
                                                    <small>Stockage</small>
                                                </h5>
                                            </Col>
                                            <Col className="me-auto" lg="3" md="12" xs="4">
                                                <h5>
                                                    1,345 <br />
                                                    <small>Sms</small>
                                                </h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );

        /*<Menu id={`contextMenu-siteAvatar`} onVisibilityChange={() => { }}>
            <Item data="genFileDel" onClick={fileDel.bind(this)}>
                <i className="fa fa-times" aria-hidden="true"></i>&nbsp;Supprimer
            </Item>
        </Menu> */
    }
}

export default UserProfile;


//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de ReportModal
function ChangePwdModal({ title, parentContext, modalGenManager, noScroll, configModel, modelsList, data, callback }) {
    React.useEffect(() => {
        //console.debug("mount");

        //TODO : Check différence draft/ManualReport

        //if (parentContext.props.dataCfg && parentContext.props.dataCfg.dlgSup_enabledMClient) initClientList(null, 0);//TODO
        initChangePwdModal();
    }, []);



    function initChangePwdModal() {

    }

    //console.debug("render modal");

    configModel = JSON.parse(JSON.stringify(configModel));

    return (
        <Modal isOpen={true} className={`modal-report ${noScroll ? "modal-noscroll" : ""}`} size="xl" scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={{ overflowX: "hidden" }}>
                <AGenerateModel parentProps={{ type: "employee", dataCfg: parentContext.props.dataCfg }} ref={modalGenManager} noButtons
                    onSubmit={(data, isUpdate) => { return callback(data, isUpdate) }} appProps={parentContext.props} checkRights={parentContext.props.checkRights} getRoutes={parentContext.props.getRoutes} configModel={configModel} modelsList={modelsList} loadedData={data} />

            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <Button className="px-3" color="success" onClick={() => {
                    var generateModel = modalGenManager.current;
                    if (generateModel && generateModel.submitForm) {
                        generateModel.submitForm();
                    }
                }}><i className="fa fa-check"></i>Confirmer</Button>
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>Annuler</Button>
            </ModalFooter>
        </Modal>
    );
}