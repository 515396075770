import React from "react";
import { useNavigate } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
    FormFeedback
} from "reactstrap";

import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as commonFunc from 'scripts/common';

//Création du schéma pour le paramètrage du formulaire
const schema = yup.object().shape({
    email: yup.string().required("Ce champ est obligatoire").email("Adresse email invalide"),
}).required();

function PwdReset(props) {
    React.useEffect(() => {
        document.body.classList.toggle("login-page");
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };
    });

    const navigate = useNavigate();

    const recaptchaRef = React.useRef();

    //Paramètrage du formulaire
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    async function triggerSubmit(data) {
        //e.preventDefault();

        var req = { email: data.email };
        req.recaptcha = await recaptchaRef.current.executeAsync();


        Swal.fire({
            title: "Traitement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        commonFunc.postMethod("../api/RequestPasswordReset", req).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {
                    Swal.fire({
                        icon: "success",
                        title: "Succès",
                        html: "Nous avons envoyé la procédure par email à l'adresse <b>" + data.email + "</b>.<br/>Il vous suffit de cliquer sur le lien personnalisé dans l'email pour réinitialiser votre mot de passe.<br/><br/> Si vous ne voyez pas l'email, vérifiez les autres dossiers de votre messagerie comme votre courrier indésirable, spam, social, etc.",
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        navigate("../login");
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de la demande de réinitialisation :<br>" + response.data.errorMessage
                    }).then(result => {
                        window.location.reload();
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la demande de réinitialisation"
                }).then(result => {
                    window.location.reload();
                });
            }
        });
    }

    const { ref: refEmail, ...restEmail } = register("email");

    return (
        <div className="login-page">
            <Container>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LfJ5KcpAAAAAFwA7DM-smKf4sfJmq6FHDF-Zt9q"
                />
                <Row>
                    <Col className="ms-auto me-auto" lg="4" md="6">
                        <Card className="card-login text-center">
                            <Form action="" className="form" method="" onSubmit={handleSubmit(triggerSubmit)}>
                                <CardHeader>
                                    {window.history.length > 1 ? <Button className="btn btn-icon btn-round float-start" onClick={() => window.history.back()}><i className="fa fa-arrow-left"></i></Button> : ""}
                                    <CardTitle className='header' tag="h3" style={{clear:"both"}}>Mot de passe oublié ?</CardTitle>
                                    <p>
                                        Saisissez l'adresse e-mail de votre compte Oskala sur laquelle vous souhaitez recevoir les informations de réinitialisation de mot de passe.
                                    </p>
                                </CardHeader>
                                <CardBody>
                                    <InputGroup>
                                        <InputGroupText>
                                            <i className="fa fa-envelope" />
                                        </InputGroupText>
                                        <Input placeholder="Email..." type="email"
                                            autoComplete="no" aria-autocomplete="none"
                                            innerRef={refEmail} {...restEmail}
                                            invalid={errors.email ? true : false} />
                                        <FormFeedback className="text-start">{errors.email ? errors.email.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </InputGroup>

                                </CardBody>
                                <CardFooter>
                                    <Button
                                        block
                                        className="btn-round mb-3"
                                        color="warning"
                                    >
                                        Continuer
                                    </Button>
                                </CardFooter>
                            </Form>
                        </Card>

                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("assets/img/bg/backblurt1.jpg")})`,
                }}
            />
        </div >

    );
}

export default PwdReset;
