import axios from "axios";

export function postMethod(url, args) {
    var getparams = {
        headers: {
            'Content-Type': 'application/json',
            /*//'Authorization': `Bearer ${TOKEN}`
            withCredentials: true*/
        }
    };
    if (!args) args = null;//PATCH undefined >> null
    return axios.post(url, args);//Info : possible de fournir un 3eme argument pour configurer axios
}


export function getMethod(url, args, useJson, t, withCache) {

    useJson = useJson | false;

    var getparams = {
        crossDomain: true,
        timeout: t || 30000,
        headers: {
            /*'Content-Type': 'application/json',
            //'Authorization': `Bearer ${TOKEN}`
            withCredentials: true*/
        }
    };
    if (!withCache) {
        getparams.headers["Cache-Control"] = 'no-cache';
        getparams.headers["Pragma"] = 'no-cache';
        //getparams.headers["Expires"] = '0';
    }

    /*if (useJson) {
        getparams.headers["Content-Type"] = "application/json;charset=utf-8";
        //getparams.dataType = "json";
        if (args) {
            getparams.params = JSON.stringify(args);
        }
    } else {
        getparams.headers["Content-Type"] = 'application/x-www-form-urlencoded';
        if (args) {
            if (Object.prototype.toString.call(args) == '[object String]') {
                getparams.params = args; //encodeURIComponent(args);
            } else {
                getparams.params = toParams(args);//$.param
            }

        }
    }*/
    getparams.params = args;//Info : arguments de la fonction mis dans params de axios
    return new Promise((resolve, reject) => {
        axios.get(url, getparams).then(response => {
            if (response) {
                resolve(response);
                return;
            }
            //reject(new Error(response.error));
            return;
        });
    });
}




export function downloadFile(url, fileName, callback) {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream',
            'Authorization': axios.defaults.headers["Authorization"]
        },
    }).then((response) => response.blob())
        .then(async (blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);

            //if (callback && typeof (callback) === "function") { callback(href); }
        });

}

export function downloadFileFetch(url, fileName, callback) {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream',
            'Authorization': axios.defaults.headers["Authorization"]
        },
    }).then((response) => response.blob())
        .then(async (blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);

            //if (callback && typeof (callback) === "function") { callback(href); }
        });

}

export function toParams(obj) {
    return Object.entries(obj).map(
        pair => {
            if (pair[1] == null) return "";
            return Array.isArray(pair[1]) ?
                pair[1].map(x => `${encodeURIComponent(pair[0])}[]=${encodeURIComponent(x)}`).join('&') :
                typeof pair[1] === 'object' ?
                    Object.entries(pair[1]).map(x => `${encodeURIComponent(pair[0])}[${x[0]}]=${encodeURIComponent(x[1])}`).join('&') :
                    pair.map(encodeURIComponent).join('=')
        }
    ).join('&');
}

export function getValue(obj, path) {
    if (path && path.indexOf(".") >= 0 && obj !== undefined) {
        var subPath = path.split(".");
        var newPath = subPath[0];
        return obj ? getValue(obj[newPath], subPath.slice(1).join(".")) : null;
    }
    else {
        return obj ? obj[path] : obj;
    }
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}


export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

export function decodeEntities(encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
}

/*Fichiers*/

export function bytesToSize(bytes, precision) {
    var kilobyte = 1024;
    var megabyte = kilobyte * 1024;
    var gigabyte = megabyte * 1024;
    var terabyte = gigabyte * 1024;

    if (bytes == 0) return '';

    if ((bytes > 0) && (bytes < kilobyte)) {
        return bytes + ' octet(s)';

    } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
        return (bytes / kilobyte).toFixed(precision) + ' Ko';

    } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
        return (bytes / megabyte).toFixed(precision) + ' Mo';

    } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
        return (bytes / gigabyte).toFixed(precision) + ' Go';

    } else if (bytes >= terabyte) {
        return (bytes / terabyte).toFixed(precision) + ' To';

    } else {
        return bytes + ' B';
    }
}

export function getFilesFaIcon(file, prefix) {
    var extension = file.substr((file.lastIndexOf('.') + 1));
    if (extension) {
        switch (extension.toLowerCase()) {
            case "pdf":
                switch (prefix) {
                    case "PDF": return <i className='fas fa-file-pdf' title='Document Adobe Reader' data-typef='doc'></i>;
                    default: return <i className='fas fa-file-pdf' title='Document Adobe Reader' data-typef='doc'></i>;
                }
            case "doc": return <i className='fas fa-file-word' title='Document Word' data-typef='doc'></i>;
            case "docx": return <i className='fas fa-file-word' title='Document Word' data-typef='doc'></i>;
            case "rtf": return <i className='fas fa-file-word' title='Document Word' data-typef='doc'></i>;

            case "xls": return <i className='fas fa-file-excel' title='Document Excel' data-typef='doc'></i>;
            case "xlsx": return <i className='fas fa-file-excel' title='Document Excel' data-typef='doc'></i>;
            case "csv": return <i className='fas fa-file-excel' title='Document Excel CSV' data-typef='doc'></i>;

            case "ppt": return <i className='fas fa-file-powerpoint' title='Document PowerPoint' data-typef='doc'></i>;
            case "pptx": return <i className='fas fa-file-powerpoint' title='Document PowerPoint' data-typef='doc'></i>;

            case "html": return <i className='fas fa-file-code' title='Document HTML' data-typef='doc'></i>;
            case "htm": return <i className='fas fa-file-code' title='Document HTML' data-typef='doc'></i>;
            case "xml": return <i className='fas fa-file-code' title='Document XML' data-typef='doc'></i>;

            case "zip":
                switch (prefix) {
                    case "RPT":
                        return <i className='fas fa-file-archive text-info' title='Rapport Vierge Zip' data-typef='sys' style={{ color: "#f0ad4e" }} ></i>;
                    case "SAV":
                        return <i className='fas fa-file-archive' title='Sauvegarde Rapport Zip' data-typef='sys'></i>;
                    default:
                        return <i className='fas fa-file-archive' title='Archive' data-typef='doc'></i>;
                }
            case "rar": return <i className='fas fa-file-archive' title='Archive' data-typef='doc'></i>;

            case "jpg": return <i className='fas fa-file-image' title='Photo' data-typef='img'> </i>;
            case "jpeg": return <i className='fas fa-file-image' title='Photo' data-typef='img'></i>;
            case "png": return <i className='fas fa-file-image' title='Photo' data-typef='img'></i>;
            case "gif": return <i className='fas fa-file-image' title='Photo' data-typef='img'></i>;
            case "tif": return <i className='fas fa-file-image' title='Photo' data-typef='img'></i>;
            case "tiff": return <i className='fas fa-file-image' title='Photo' data-typef='img'></i>;
            case "bmp": return <i className='fas fa-file-image' title='Photo' data-typef='img'></i>;
            case "webp": return <i className='fas fa-file-image' title='Photo' data-typef='img'></i>;


            case "avi": return <i className='fas fa-file-video' title='Vidéo' data-typef='vid'></i>;
            case "mp4": return <i className='fas fa-file-video' title='Vidéo' data-typef='vid'></i>;
            case "mpg": return <i className='fas fa-file-video' title='Vidéo' data-typef='vid'></i>;
            case "mpeg": return <i className='fas fa-file-video' title='Vidéo' data-typef='vid'></i>;


            case "txt": return <i className='fas fa-file-alt' title='Document Texte' data-typef='doc'></i>;
            case "json":
                switch (prefix) {
                    case "RPT":
                        return <i className='fa fa-edit' title='Rapport Vierge' data-typef='sys' style={{ color: "#f0ad4e" }}></i>;
                    case "RDI":
                        return <i className='fa fa-pen-square' title='Rapport Complété' data-typef='sys' style={{ color: "#337ab7" }}></i>;
                    case "SAV":
                        return <i className='fa fa-save' title='Sauvegarde Rapport' data-typef='sys'></i>;
                    default:
                        return <i className='fa fa-pen-square' title='Document Json' data-typef='doc'></i>;
                }






            case "mp3": return <i className='fas fa-file-audio' title='Fichier Audio' data-typef='aud'></i>;
            case "wav": return <i className='fas fa-file-audio' title='Fichier Audio' data-typef='aud'></i>;
            default: return <i className='fas fa-file' title={file} data-typef='doc'></i>;
        }
    } return "";
}

export function cellFormatterFileType(cellvalue, options, rowObject) {
    var ext = cellvalue.toLowerCase();
    if (ext == '.') return "Dossier";
    if (ext == 'pdf') return "Document Adobe Reader";
    if (ext == 'doc' || ext == 'docx' || ext == 'rtf') return "Document Word";
    if (ext == 'xls' || ext == 'xlsx') return "Document Excel";
    if (ext == 'ppt' || ext == 'pptx' || ext == 'pps') return "Document PowerPoint";
    if (ext == 'pub') return "Document Publisher";
    if (ext == 'exe' || ext == 'msi') return "Fichier Exécutable";
    if (ext == 'txt' || ext == 'csv') return "Document Texte";
    if (ext == 'wav' || ext == 'mp3') return "Fichier Audio";
    if (ext == 'avi' || ext == 'mp4' || ext == 'mpg' || ext == 'mpeg' || ext == 'mov' || ext == 'wmv') return "Fichier Vidéo";
    if (ext == 'png' || ext == 'jpg' || ext == 'jpeg' || ext == 'bmp' || ext == 'tif' || ext == 'tiff' || ext == 'gif') return "Image " + ext;
    if (ext == 'zip' || ext == 'rar' || ext == 'cab') return "Archive " + ext;
    return "Fichier " + ext;
    // if (ext == 'xml' || ext == 'rar' || ext == 'cab') return "Archive " + ext;
}


function rainbow(numOfSteps, step) {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    var r, g, b;
    var h = step / numOfSteps;
    var i = ~~(h * 6);
    var f = h * 6 - i;
    var q = 1 - f;
    switch (i % 6) {
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
        default: break;
    }
    var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return (c);
}

export function rainbowRGBA(numOfSteps, step, opacity) {
    var rgba = "rgba(" + hexToRgb(rainbow(numOfSteps, step)) + "," + opacity + ")";
    return rgba;
}

export function createProgressBar(value) {
    if (value < 25) return "danger";
    if (value < 50) return "warning";
    if (value < 75) return "success bg-alpha";/*"bg-info";*/
    return "success";
}
export function inverseCreateProgressBar(value) {
    if (value < 25) return "success";
    if (value < 50) return "success bg-alpha";
    if (value < 75) return "warning";/*"bg-info";*/
    return "danger";
}

export function hexToRgb(hex) {
    if (hex.substring(0, 1) === "#") { hex = hex.substring(1); }
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    return r + "," + g + "," + b;
}


//Custom replacement for Jquery trim
export function trim(element) {
    return element.toString().replace(/^([\s]*)|([\s]*)$/g, '');
};


//Custom replacement for Jquery extend
function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key]) && !(source[key] instanceof Date)) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}




/*Dates*/

//Convertit une chaine en date et horaire
export function stringToDate(s) {
    s = s.replace(/[/]/g, "-");
    s = s.split(/[-: ]/);
    var ndate = new Date(s[2], s[1] - 1, s[0], s[3], s[4], 0);
    return new Date(ndate.valueOf() - ndate.getTimezoneOffset() * 60000);
}

export function stringToDayDate(s) {
    s = s.replace(/[/]/g, "-");
    //alert("stringToDate" + s);
    s = s.split(/[-: ]/);
    var ndate = new Date(s[2], s[1] - 1, s[0], 0, 0, 0, 0);
    return new Date(ndate.valueOf() - ndate.getTimezoneOffset() * 60000);
}

export function stringToDateRaw(s) {
    s = s.replace(/[/]/g, "-");
    //alert("stringToDate" + s);
    s = s.split(/[-: ]/);
    var ndate = new Date(s[2], s[1] - 1, s[0], s[3], s[4], 0);
    return ndate;
}

export function restStringToDate(s) {
    var ndate = new Date(s.replace(
        /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)$/,
        '$4:$5 $2/$3/$1'
    ));
    return ndate;
    //return new Date(ndate.valueOf() - ndate.getTimezoneOffset() * 60000);
}


export function formatDateDMY(aDate, noEpoch) {
    if (parseBool(noEpoch)) {
        //var aspDate = '/Date(' + date.getTime() + ')/';
        var epoch = stripDate(aDate).getTime();
        if (epoch <= 0) return "";
    }
    if (aDate && typeof (aDate) == "object") {
        var yyyy = aDate.getFullYear().toString();
        var mm = (aDate.getMonth() + 1).toString(); // getMonth() is zero-based
        var dd = aDate.getDate().toString();
        return (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + yyyy; // padding
    } else {
        return "";
    }
}

//formatte une date  en dd/mm/yyyy hh:MM
export function formatDateTime(aDate, noEpoch) {
    if (typeof noEpoch !== 'undefined' && parseBool(noEpoch)) {
        //var epoch = stripDate(aDate).getTime();
        //if (epoch <= 0) return "";
        if (aDate.getFullYear() == 1970) return "";
    }
    var yyyy = aDate.getFullYear().toString();
    var mm = (aDate.getMonth() + 1).toString(); // getMonth() is zero-based
    var dd = aDate.getDate().toString();
    var hh = aDate.getHours().toString();
    var MM = aDate.getMinutes().toString();
    return (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + yyyy + " " + (hh[1] ? hh : "0" + hh[0]) + ":" + (MM[1] ? MM : "0" + MM[0]); // padding
}


//Supprime les données d'heures de la date
export function stripDate(sd) {
    if (sd) {
        sd.setHours(0, 0, 0, 0);
        return sd;
    }

}

//Convertit une valeur MS en date
export function makeTimeFromMs(val) {
    var msec = val;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    var newDateObj = new Date();
    newDateObj.setHours(hh);
    newDateObj.setMinutes(mm);
    newDateObj.setSeconds(ss);
    return newDateObj;
    //new Date(newDateObj.valueOf() - newDateObj.getTimezoneOffset() * 60000);
}

//Convertit une durée en centième
export function makeDecimal(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return (hours + minutes / 60).toFixed(2);
}


//Convertit une date en horaire
export function makeShortTime(d) {
    var HH = pad2(d.getHours()); //yeilds hours
    var mm = pad2(d.getMinutes()); //yields minutes
    return HH + ":" + mm;
}

//Compare 2 DateTime
export function dateDiffSec(sd, ed) {
    if (sd && ed) {
        var timeDiff = Math.abs(ed.getTime() - sd.getTime());
        //var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); //Jours
        //var diffDays = Math.ceil(timeDiff / (1000 * 3600)); //Heures
        var diffDays = Math.ceil(timeDiff / (1000 * 60)); //Secondes
        return sd.getTime() === ed.getTime();
    }
    return 0;
}

export function isValidDate(aDate, src) {
    if (Object.prototype.toString.call(aDate) === "[object Date]") {
        if (isNaN(aDate.getTime())) {  // d.valueOf() could also work
            return false;
        }
        else {
            if (src) {
                var comp = src.split('/');
                if (src.length < 10 || comp.length < 3) return false;
                var d = parseInt(comp[0], 10);
                var m = parseInt(comp[1], 10);
                var y = parseInt(comp[2], 10);
                return (aDate.getFullYear() == y && aDate.getMonth() + 1 == m && aDate.getDate() == d);
            } else {
                return true;
            }
        }
    }
    else {
        return false;
    }
}

//ajout de minutes à une date
export function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}

/*Formattage*/

export function parseBool(value) {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string' && value.toLowerCase() == 'true') return true;
    if (typeof value === 'string' && value.toLowerCase() == 'false') return false;
    if (typeof value === 'string' && value.toLowerCase() == 'yes') return true;
    if (typeof value === 'string' && value.toLowerCase() == 'no') return false;

    return (parseInt(value) > 0);
}

export function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

//Formatte un chiffre sur 2 numériques
function pad2(number) {
    return (number < 10 ? '0' : '') + number
}


export function formatNumber(n, dec, removeZero) {
    if (removeZero) {
        return parseFloat(n.toFixed(dec >= 0 ? dec : 2)).toString();
    }
    else {
        return n.toFixed(dec >= 0 ? dec : 2)
    }
}

export function formatNumberFr(i) {
    var n = new Number(i);
    var x = n.toLocaleString("fr-FR");
    return x
}

export function formatPrice(lang, currency, number) {
    //return new Intl.NumberFormat(lang, { style: "currency", currency }).format(number);
    return formatNumber((number || 0)) + " €";
}

function formatPriceFr(number) {
    //return new Intl.NumberFormat(lang, { style: "currency", currency }).format(number);
    return formatNumberFr(formatNumber((number || 0), 2)) + " €";
}

function formatPriceInt(lang, currency, number) {
    //return new Intl.NumberFormat(lang, { style: "currency", currency }).format(number);
    return formatNumberFr(formatNumber((number || 0), 0)) + " €";
}
function formatPercent(lang, currency, number) {
    //return new Intl.NumberFormat(lang, { style: "currency", currency }).format(number);
    return formatNumber((number || 0)) + " %";
}

export function capitalize(s) {
    if (typeof s === "string" && s.length >= 2) return s.charAt(0).toUpperCase() + s.slice(1);
    return s;
}

export function camelize(str, advanced) {
    if (advanced) {
        var splitStr = str.split(".");
        var compStr = [];
        splitStr.forEach((sStr) => {
            compStr.push(camelize(sStr));
        });
        return compStr.join(".");
    }
    else {
        str = str.startsWith("ID") ? str.replace("ID", "id") : str;
        str = str.startsWith("PC") ? str.replace("PC", "pc") : str;
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }
}

export function convertPC(pc) {
    if (pc) {
        var value = pc.toString();
        if (value.length >= 5) {
            return value;
        }
        else {
            return convertPC("0" + value);
        }
    }
    return pc;
}