import React, { Component } from 'react';
import axios from "axios";
import moment from "moment";
import PropTypes from 'prop-types';
import * as commonFunc from '../scripts/common';
import 'assets/css/datatables.min.css';
import frenchLang from 'assets/French.txt';

import Mark from 'mark.js';

import { useTranslation } from "react-i18next";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');
require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-colreorder-bs5');
require('datatables.net-fixedcolumns-bs5');
require('datatables.net-fixedheader-bs5');
require('datatables.net-responsive-bs5');
require('datatables.net-rowgroup-bs5');
require('datatables.net-rowreorder-bs5');
require('datatables.net-scroller-bs5');
require('datatables.net-select-bs5');
require('datatables.net-plugins/api/row().show().js');
require('datatables.net-colresize-unofficial/jquery.dataTables.colResize.js');
require('datatables.net-colresize-unofficial/jquery.dataTables.colResize.css');

function reloadTableData(dtId, tableData, allowEmpty) {
    const table = $('#' + dtId).DataTable();
    table.clear();
    if (tableData) {
        var rData = Array.isArray(tableData) ? tableData.filter(rowData => !rowData || allowEmpty || parseInt(rowData[table.init().rowId]) > 0) : tableData;
        table.rows.add(rData);
    }
    table.draw();
}

//Non utilisée
function updateTable(dtId, tableData) {
    const table = $('#' + dtId).DataTable();
    console.debug(table);
    let dataChanged = false;
    table.rows().every(function () {
        const oldNameData = this.data();
        const newNameData = tableData.find((nameData) => {
            return nameData.name === oldNameData.name;
        });
        if (oldNameData.nickname !== newNameData.nickname) {
            dataChanged = true;
            this.data(newNameData);
        }
        return true;
    });

    if (dataChanged) {
        table.draw();
    }
}


class CDataTable extends Component {
    main = React.createRef();

    constructor(props) {
        //console.debug("DT construct");
        super(props);

        $.fn.dataTable.moment = function (format, locale) {
            var types = $.fn.dataTable.ext.type;

            // Add type detection
            types.detect.unshift(function (d) {
                if (d && d.toString().startsWith("<span")) { //$("<span>" + d + "</span>").children().length
                    return moment($(d).text(), format, locale, false).isValid() ?//true
                        'moment-spandatetime' :
                        null;
                }
                else if (d && d.toString().startsWith("<div")) {
                    if ($(d).hasClass("date-weeks")) {
                        return 'moment-prevdate'
                    }
                    else {
                        return moment(d, format, locale, false).isValid() ?//true
                            'moment-' + format :
                            null;
                    }
                }
                else {
                    return moment(d, format, locale, false).isValid() ?//true
                        'moment-' + format :
                        null;
                }
            });

            // Add sorting method - use an integer for the sorting
            types.order['moment-' + format + '-pre'] = function (d) {
                return moment(d, format, locale, false).unix();//true
            };

            types.order['moment-spandatetime-pre'] = function (d) {
                if (d && d.toString().startsWith("<span")) {
                    return moment($(d).text(), format, locale, false).unix();//true
                }
                else {
                    return moment(d, format, locale, false).unix();//true
                }
            };

            /*types.order['moment-prevdate-pre'] = function (d) {
                console.debug("go1");
                var value = $(d).attr("title") || "01/01/1970";
                return (value == "01/01/1970" || value == "01/01/1") ? 99999999999 : moment(value, format, locale, false).unix();//true
            };*/
            types.order['moment-prevdate-asc'] = function (a, b) {
                a = $(a).attr("title") || "01/01/1970";
                b = $(b).attr("title") || "01/01/1970";

                var valueA = (a === "01/01/1970" || a === "01/01/1") ? 99999999999 : moment(a, format, locale, false).unix();
                var valueB = (b === "01/01/1970" || b === "01/01/1") ? 99999999999 : moment(b, format, locale, false).unix();

                return ((valueA < valueB) ? -1 : ((valueA > valueB) ? 1 : 0));
            };
            types.order['moment-prevdate-desc'] = function (a, b) {
                a = $(a).attr("title") || "01/01/1970";
                b = $(b).attr("title") || "01/01/1970";

                var valueA = (a === "01/01/1970" || a === "01/01/1") ? -99999999999 : moment(a, format, locale, false).unix();
                var valueB = (b === "01/01/1970" || b === "01/01/1") ? -99999999999 : moment(b, format, locale, false).unix();

                return ((valueA < valueB) ? 1 : ((valueA > valueB) ? -1 : 0));
            };
        };


        $.fn.dataTable.moment('DD/MM/YYYY HH:mm', 'fr');

        $.fn.dataTable.Api.register('row().show()', function () {
            var page_info = this.table().page.info();
            var new_row_index = this.index();
            var row_position = this.table().rows()[0].indexOf(new_row_index);
            if (row_position >= page_info.start && row_position < page_info.end) {
                return this;
            }
            var page_to_display = Math.floor(row_position / this.table().page.len());
            this.table().page(page_to_display);
            return this;
        });



        $.fn.dataTable.Api.register('row().next()', function () {
            var page_info = this.table().page.info();
            var new_row_index = this.index() + 1;
            var row_position = this.table().rows()[0].indexOf(new_row_index);
            if (row_position >= page_info.start && row_position < page_info.end) {
                return this;
            }
            var page_to_display = Math.floor(row_position / this.table().page.len());
            this.table().page(page_to_display);
            return this;
        });

        $.fn.dataTable.Api.register('sum()', function () {
            return this.flatten().reduce(function (a, b) {
                if (typeof a === 'string') {
                    a = a.replace(/[^\d.-]/g, '') * 1;
                }
                if (typeof b === 'string') {
                    b = b.replace(/[^\d.-]/g, '') * 1;
                }
                var x = parseFloat(a) || 0;
                var y = parseFloat(b) || 0;
                return x + y;
            }, 0);
        });

        $.fn.dataTable.Api.register('average()', function () {
            var data = this.flatten();
            var sum = data.reduce(function (a, b) {
                return (a * 1) + (b * 1); // cast values in-case they are strings
            }, 0);

            return sum / data.length;
        });


        $.fn.DataTable.ext.type.search.string = function (data) {
            return !data ?
                '' :
                typeof data === 'string' ?
                    (data + " " + data
                        .replace(/έ/g, 'ε')
                        .replace(/[ύϋΰ]/g, 'υ')
                        .replace(/ώ/g, 'ω')
                        .replace(/ά/g, 'α')
                        .replace(/[ίϊΐ]/g, 'ι')
                        .replace(/ή/g, 'η')
                        .replace(/\n/g, ' ')
                        .replace(/á/g, 'a')
                        .replace(/â/g, 'a')
                        .replace(/ã/g, 'a')
                        .replace(/é/g, 'e')
                        .replace(/ê/g, 'e')
                        .replace(/ë/g, 'e')
                        .replace(/è/g, 'e')
                        .replace(/í/g, 'i')
                        .replace(/î/g, 'i')
                        .replace(/ï/g, 'i')
                        .replace(/ì/g, 'i')
                        .replace(/ó/g, 'o')
                        .replace(/ô/g, 'o')
                        .replace(/õ/g, 'o')
                        .replace(/ό/g, 'ο')
                        .replace(/ú/g, 'u')
                        .replace(/ü/g, 'u')
                        .replace(/ç/g, 'c')) :
                    data;
        };


        //Déclaration des types d'élément utilisé pour l'ordenancement
        /* Create an array with the values of all the input boxes in a column */
        $.fn.dataTable.ext.order['dom-text'] = function (settings, col) {
            return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
                return $('input', 'textarea', td).val();
            });
        }
        /* Create an array with the values of all the input boxes in a column, parsed as numbers */
        $.fn.dataTable.ext.order['dom-text-numeric'] = function (settings, col) {
            return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
                return parseInt($('input', td).val());
            });
        }
        $.fn.dataTable.ext.order['dom-text-date'] = function (settings, col) {
            return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
                return moment($('input', td).datepicker('getDate')).format('YYYY-MM-DD');
            });
        }
        $.fn.dataTable.ext.order['dom-select'] = function (settings, col) {
            return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
                return $('select', td).find("option[value='" + $('select', td).val() + "']").text();
            });
        }
        $.fn.dataTable.ext.order['dom-select-id'] = function (settings, col) {
            return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
                return $('select', td).val();
            });
        }


        this.state = { dtId: this.props.dtId, tableData: this.props.data, stamp: this.props.stamp };
    }

    componentDidMount() {
        //console.debug("DT mount");

        var context = this;

        context.setConfig();

        if (context.props.functionPanel) context.props.functionPanel(context.props.dtId, {
            resizeResponsive: () => {
                $(context.main.current).DataTable().columns.adjust().responsive.recalc();
            },
            setDisabled: context.setDisabled.bind(this),
            getData: context.getData.bind(this),
        });
    }
    componentDidUpdate(prevProps, prevState) {
        //console.debug("Datatable willUpdate " + (prevProps.stamp !== this.props.stamp));
        /*if (prevProps.config !== this.props.config || prevProps.localConfig !== this.props.localConfig) {
            $(this.main.current).DataTable().clear().draw();
            if (prevProps.stamp !== this.props.stamp) this.setState({ tableData: this.props.data, stamp: this.props.stamp });
            this.setConfig();
        }
        else*/ if (prevProps.stamp !== this.props.stamp) {
            this.setState({ /*dtId: nextProps.dtId, */tableData: this.props.data, stamp: this.props.stamp });
            reloadTableData(this.state.dtId, this.props.data, this.props.allowEmpty);
        }
    }
    componentWillUnmount() {
        //console.debug("DT unmount");
        $(this.main.current).DataTable().clear().destroy(true);//PATCH : ajout de clear >> si un responsive child était affiché, il y avait une erreur
    }

    //Défini si le process doit effectuer un re-render ou non
    shouldComponentUpdate(nextProps, nextState) {
        //console.debug("Datatable update " + (nextProps.stamp !== this.props.stamp));

        if (nextProps.config !== this.props.config || nextProps.stamp !== this.props.stamp) {
            return true;
        }
        else {
            //Callback après MAJ data du tableau
            if (nextState.stamp > 0 && nextState.stamp !== this.state.stamp) {
                if (this.props.localConfig.cbCustom && typeof (this.props.localConfig.cbCustom) === "function") { this.props.localConfig.cbCustom(); }
            }
            return false;
        }
        /*if (nextProps.data.length !== this.state.tableData.length) {
            reloadTableData(this.state.dtId, nextProps.data, nextProps.allowEmpty);
        } else {
            updateTable(this.state.dtId, nextProps.data);
        }
        return false;*/
    }

    render() {
        //console.debug("> DT render");

        var classSup = "table w-100 " + this.props.className;
        var styleSup = this.props.style || {};
        styleSup.marginRight = '-1700px';

        return (<div className="d-flex w-100">
            <div className="flex-fill dash-fit-row compact-mode-90 w-100">
                <table id={this.state.dtId} className={classSup} ref={this.main} style={styleSup} {...this.props.attrSup}>
                    <thead><tr></tr></thead>
                </table>
            </div></div>);
    }

    getDataTableConfig(categ, callback) {
        var context = this;

        commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: categ }).then((responseModels) => {
            var dataModels = responseModels.data;

            var dataConfig = dataModels;
            if (dataModels.result.models && dataModels.result.models.length) {
                dataConfig.result = dataModels.result.models[0].value;
            }
            else {
                dataConfig.result = null;
            }

            var defaultDataTableConfig = context.getDTDefaultConfig(categ, null, dataConfig);

            if (callback && typeof (callback) === "function") { callback(dataConfig, defaultDataTableConfig); }
        });
    }

    getDTDefaultConfig(tagName, version, dataConfig) {
        if (!version) version = "";
        if (dataConfig && dataConfig.isSuccess && dataConfig.result) {
            //console.debug(dataConfig.result);
            var configs = JSON.parse(dataConfig.result);
            if (configs != null) {
                version = configs.customDTVersion || "";
            }
        }

        //Conversion des valeur input pour l'export .csv
        var buttonCommon = {
            exportOptions: {
                format: {
                    body: function (data, row, column, node) {
                        if (data != null && (data.toString().endsWith("€") || data.toString().endsWith("%"))) data = data.replace(/\./g, ",").replace(/ /g, "").replace(/ /g, "");
                        return data;
                    }
                }
            }
        };
        var defaultDataTableConfig = {
            cTabTagName: tagName,
            cTabVersion: version,
            "language": {
                "url": frenchLang,
                searchPlaceholder: "filtrez ici"
            },
            "destroy": true,
            "lengthChange": true,
            "lengthMenu": [[50, 100, 250, 500, -1], [50, 100, 250, 500, "Tous"]],
            "pageLength": 50,
            paging: true,
            "processing": false,
            "scrollX": true,
            "scrollY": "52vh",
            //"scrollCollapse": true,
            //"autoWidth": true,
            rowId: 'ID',
            select: 'single',
            //responsive: true, //fonctionne avec FC
            stateSave: false,
            stateSaveCallback: function (settings, data) {
                localStorage.setItem('DataTables_' + settings.sInstance + "_/" + tagName + version, JSON.stringify(data))
            },
            stateLoadCallback: function (settings) {
                var params = JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance + "_/" + tagName + version));
                if (params && params.select) delete params.select;
                return params;
            },
            stateSaveParams: function (settings, data) {
                data.search.search = "";
            },
            //colReorder: true,// >> bien déclarer order comme ça [[5, "desc"]] >> si il manque une accalade, ça met des erreurs 
            //dom: 'Blfrtip',
            /*dom: "<'row'<'col-sm-12 col-md-4'lB><'col-sm-12 col-md-6'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",*/
            /*dom: "<'row m-0'<''B><'pl-2'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-6 dt-bottom-il'li><'col-sm-12 col-md-6'p>>",*/
            dom: "<'row m-0'<'col-auto ps-0 btn-magnify'B><'col-auto ps-2'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row mt-1'<'col-sm-12 col-md-6 dt-bottom-il'li><'col-sm-12 col-md-6'p>>",
            buttons: [
                $.extend(true, {}, buttonCommon, {
                    extend: 'csv',
                    fieldSeparator: ';',
                    bom: true,
                    text: "<i class='fa fa-file-excel'></i>",
                    className: "btn-sm btn-colsel",
                    exportOptions: {
                        columns: 'th',//:gt(0):lt(13)
                        modifier: { search: 'none' }//Récupère les lignes sans prendre compte de la recherche
                    }
                }),
                {
                    extend: 'colvis',
                    text: "<i class='fa fa-columns'></i>",
                    columns: ':not(:eq(0))',
                    className: "btn-sm btn-colsel",
                    postfixButtons: ['colvisRestore']
                }
            ],
            //PLUGIN colResize
            /*colResize: {
                isEnabled: false,
                saveState: false,
                hoverClass: 'dt-colresizable-hover',
                hasBoundCheck: true,
                minBoundClass: 'dt-colresizable-bound-min',
                maxBoundClass: 'dt-colresizable-bound-max',
                isResizable: function (column) {
                    return true;
                },
                onResizeStart: function (column, columns) {
                },
                onResize: function (column) {
                },
                onResizeEnd: function (column, columns) {
                },
                getMinWidthOf: function ($thNode) {
                },
                stateSaveCallback: function (settings, data) {
                },
                stateLoadCallback: function (settings) {
                }
            }*/
        };

        return defaultDataTableConfig;
    }

    setConfig() {
        var context = this;
        var callbackDT = function (dataConfig, defaultDataTableConfig) {

            var datatableConfig = defaultDataTableConfig;
            var configs = {};
            if (dataConfig.isSuccess && dataConfig.result) {

                configs = JSON.parse(dataConfig.result);
            } else if (context.props.config) {
                configs = dataConfig;
            }
            else {
                //datatableConfig = {};
            }
            var localConfig = context.props.localConfig;
            if (localConfig.responsive) {
                localConfig.responsive = {
                    details: {
                        renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes()
                    }
                };
            }

            datatableConfig.data = context.state.tableData;
            datatableConfig.buttons = [
                $.extend(true, {}, localConfig.buttonCommon, {
                    extend: 'csv',
                    fieldSeparator: ';',
                    bom: true,
                    text: "<i class='fa fa-file-excel'></i>",
                    className: "btn-sm btn-colsel",
                    exportOptions: {
                        columns: configs.cExportSelector || localConfig.cExportSelector || 'th',//:gt(0):lt(13)
                        modifier: { search: 'none' }//Récupère les lignes sans prendre compte de la recherche
                    }
                }),
                {
                    extend: 'colvis',
                    text: "<i class='fa fa-columns'></i>",
                    columns: configs.cColvisSelector || localConfig.cColvisSelector || ':not(:eq(0))',
                    className: "btn-sm btn-colsel",
                    postfixButtons: ['colvisRestore']
                }
            ];
            datatableConfig.initComplete = function (settings) {
                var filterTarget = $(context.main.current).attr("dt-filter-target");
                if (filterTarget) {
                    $("#" + filterTarget).insertAfter($("#" + context.state.dtId + "_filter").parent());//dt-buttons //".dataTables_filter"
                    $("#" + filterTarget).removeClass("d-none").addClass("d-inline-block");
                    $(context.main.current).removeAttr("dt-filter-target").attr("dt-filter-target-done", filterTarget);
                }
                if (configs.selectAllOnlyPage) {
                    $(context.main.current).closest(".dataTables_wrapper").on('click', '.dt-selectAll', function (e) {
                        var table = $(context.main.current).DataTable();
                        if ($(this).is(':checked')) {
                            table.rows({ page: 'current' }).select();
                            $(context.main.current).find("input[name='dt-customSelection']:not(:checked)").click();
                        }
                        else {
                            table.rows({ page: 'current' }).deselect();
                            $(context.main.current).find("input[name='dt-customSelection']:checked").click();
                        }
                    });
                }
                else {
                    $(context.main.current).closest(".dataTables_wrapper").on('click', '.dt-selectAll', function (e) {
                        var table = $(context.main.current).DataTable();
                        if ($(this).is(':checked')) {
                            table.rows({ search: 'applied' }).select();
                            $(context.main.current).find("input[name='dt-customSelection']:not(:checked)").click();
                        }
                        else {
                            table.rows({ search: 'applied' }).deselect();
                            $(context.main.current).find("input[name='dt-customSelection']:checked").click();
                        }
                    });
                }

                $(context.main.current).on('change', 'tr:not(.dtrg-group) [name="dt-customSelection"]', function (e) {
                    var table = $(this).closest("table").DataTable(); //$("#" + dom).DataTable();
                    var tr = $(this).closest("tr");
                    if (tr.hasClass("child")) tr = tr.prev(".parent");
                    var datatr = table.row(tr);
                    var datarow = datatr.data();
                    var name = $(this).attr("data-name");

                    //var value = parseFloat($(this).val()) || 0;
                    //datarow[name] = value;

                    if ($(this).is(":checked")) {
                        datatr.select();
                    }
                    else {
                        datatr.deselect();
                    }
                });


                //updateSidebar();
                $(context.main.current).DataTable().columns.adjust().responsive.recalc();

                if (configs.colResize && configs.colResize.cDefaultDisabled) $(context.main.current).DataTable().colResize.disable();
                if (configs.colReorder && configs.colReorder.cDefaultDisabled) $(context.main.current).DataTable().colReorder.disable();

                if (localConfig.cbInitComplete && typeof (localConfig.cbInitComplete) === "function") { localConfig.cbInitComplete(); }
            };
            datatableConfig.rowCallback = function (row, data) {
                $(row).find('td.dt-clickableCol > :not(.row-clickable)').off('click');
                if (localConfig.dtOnFirstColClick) {
                    $(row).find('td.dt-clickableCol > :not(.row-clickable)').on('click', function (e) {
                        e.target = row;
                        if (localConfig.dtOnFirstColClick && typeof (localConfig.dtOnFirstColClick) === "function") { localConfig.dtOnFirstColClick(e); }
                    });
                }
                $(row).find('td > :not(.row-dblclickable)').off('dblclick');
                if (localConfig.dtOnDblClick) {
                    $(row).find('td > :not(.row-dblclickable)').on('dblclick', function (e) {
                        e.target = row;
                        if (localConfig.dtOnDblClick && typeof (localConfig.dtOnDblClick) === "function") { localConfig.dtOnDblClick(e); }
                    });
                }

                //Mise en évidence d'un champ spécifique
                var targetSearch = context.props.searchParams;
                if (!commonFunc.isBlank(targetSearch) && $(row).find(".searchParams").length) {
                    var markClass = context.props.markClass || "fw-bold";
                    $(row).find(".searchParams").each((index, elem) => {
                        if ($(elem).find("mark").length <= 0) {
                            var instance = new Mark(elem);
                            instance.mark(targetSearch, { /*element: "span",*/ className: markClass });
                        }
                    });
                }

                if (localConfig.cbRowCallback && typeof (localConfig.cbRowCallback) === "function") { localConfig.cbRowCallback(row, data); }
            };

            //Merge des configurations (défaut, distantes, locales)
            if (configs.columnDefs && localConfig.columnDefs) {
                configs.columnDefs = $.merge(configs.columnDefs, localConfig.columnDefs);
                delete localConfig.columnDefs;
            }
            $.extend(datatableConfig, configs, localConfig);
            if (datatableConfig.btnColResize) {
                if (!datatableConfig.buttons) datatableConfig.buttons = [];
                datatableConfig.buttons.push({
                    text: "<i class='fa fa-left-right'></i>",
                    action: function (e, dt, node, config) {
                        var isEnable = $(".colResize-btn").hasClass("triggered");
                        var table = $(context.main.current).DataTable();
                        isEnable ? table.colResize.disable() : table.colResize.enable();
                        isEnable ? table.colReorder.enable() : table.colReorder.disable();
                        $(".colResize-btn").toggleClass("triggered");
                    },
                    className: "colResize-btn btn-sm btn-colsel"
                });
            }
            if (datatableConfig.btnColReorder) {
                if (!datatableConfig.buttons) datatableConfig.buttons = [];
                datatableConfig.buttons.push({
                    text: "<i class='fa fa-up-down-left-right'></i>",
                    action: function (e, dt, node, config) {
                        var isEnable = $(".colReorder-btn").hasClass("triggered");
                        var table = $(context.main.current).DataTable();
                        isEnable ? table.colReorder.disable() : table.colReorder.enable();
                        isEnable ? table.colResize.enable() : table.colResize.disable();
                        $(".colReorder-btn").toggleClass("triggered");
                    },
                    className: "colReorder-btn btn-sm btn-colsel"
                });
            }
            if (datatableConfig.btnClearStates) {
                if (!datatableConfig.buttons) datatableConfig.buttons = [];
                datatableConfig.buttons.push({
                    text: "<i class='fa fa-filter-circle-xmark' title='Supprimer les préférences enregistrés du tableau'></i>",
                    action: function (e, dt, node, config) {
                        var table = $(context.main.current).DataTable();
                        table.order(table.init().aaSorting);
                        //colvisrestore !!!
                        $(context.main.current).closest(".dataTables_wrapper").find(".buttons-colvisRestore").click();
                        if (datatableConfig.colResize) {
                            table.colResize.reset();

                            var labelID = 'DataTablesColResize_' + $(context.main.current).prop("id") + "_/" + table.init().cTabTagName + table.init().cTabVersion;
                            localStorage.removeItem(labelID);

                            var headers = $(context.main.current).closest(".dataTables_wrapper").find(".dataTables_scrollHead table thead th");
                            var hiddenHeaders = $(context.main.current).find("thead th");

                            headers.each((index, header) => {
                                $(header).css("min-width", "");
                                if (datatableConfig.colResize.cUseMaxWidth) $(header).css("width", "");
                            });

                            //table.columns.adjust();
                            table.draw();
                        }
                        if (datatableConfig.colReorder) {
                            table.colReorder.reset();
                        }
                        localStorage.removeItem('DataTables_' + $(context.main.current).prop("id") + "_/" + table.init().cTabTagName + table.init().cTabVersion);
                    },
                    className: "btn-sm btn-colsel px-2"
                });
            }

            if (datatableConfig.colResize) {
                datatableConfig.colResize.isResizable = function (column) { return true; };

                datatableConfig.colResize.onResizeStart = function (column, columns) {
                    /*console.debug(column);
                    console.debug(columns);
                    if (column) {
                        var table = $(context.main.current).DataTable();

                        var header = table.column(column.idx).header();
                        var hiddenHeader = $(context.main.current).find("thead th:eq(" + column.idx + ")");


                        $(header).css("width", "0px");
                        table.draw();
                    }*/
                };

                datatableConfig.colResize.onResize = function (column) { };

                datatableConfig.colResize.onResizeEnd = function (column, columns) {
                    console.debug(column);
                    if (column) {
                        var table = $(context.main.current).DataTable();

                        var header = table.column(column.idx).header();
                        var hiddenHeader = $(context.main.current).find("thead th:eq(" + column.idx + ")");

                        var value = parseFloat(column.width.replace("px", "")) > 20 ? column.width : "20px";

                        $(header).css("min-width", value);
                        if (datatableConfig.colResize.cUseMaxWidth) {
                            $(header).css("width", value);
                            $(header).css("overflow", "hidden");
                            $(header).css("white-space", "normal");
                            $(header).css("word-break", "break-word");
                        }

                        //$(table.column(column.idx).nodes()).width(column.width);
                        //table.columns.adjust();
                        table.draw();
                    }
                };

                datatableConfig.colResize.getMinWidthOf = function ($thNode) { };

                datatableConfig.colResize.stateSaveCallback = function (settings, data) {
                    console.debug(data);
                    var table = $(context.main.current).DataTable();
                    var labelID = 'DataTablesColResize_' + $(context.main.current).prop("id") + "_/" + table.init().cTabTagName + table.init().cTabVersion;
                    if (data && data.length) {
                        //data = data.map(item => item !== 0 ? (item - 36) : item);

                        data = data.map((item, index) => {
                            var value = item !== 0 ? (item - 36) : item;
                            var key = table.column(index).dataSrc();
                            return { key: key, value: value };
                        });
                    }
                    localStorage.setItem(labelID, JSON.stringify(data));
                };

                datatableConfig.colResize.stateLoadCallback = function (settings) {
                    var table = $(context.main.current).DataTable();
                    var labelID = 'DataTablesColResize_' + $(context.main.current).prop("id") + "_/" + table.init().cTabTagName + table.init().cTabVersion;
                    var params = JSON.parse(localStorage.getItem(labelID));
                    if (params) {
                        table.columns().indexes().each((index) => {
                            var header = table.column(index).header();
                            var hiddenHeader = $(context.main.current).find("thead th:eq(" + index + ")");

                            var columnKey = table.column(index).dataSrc();
                            var filteredRows = params.filter(item => item.key === columnKey);
                            if (filteredRows.length) {
                                var value = filteredRows[0].value > 20 ? (filteredRows[0].value + "px") : "20px";

                                $(header).css("min-width", value);
                                if (datatableConfig.colResize.cUseMaxWidth) $(header).css("width", value);
                            }
                            //header.css("min-width", item.value);
                        });
                        table.draw();
                    }
                };
            }

            //Génération des colonnes dans le HTML
            if (!datatableConfig.columns || datatableConfig.columns.length <= 0) datatableConfig.columns = [{ data: null }];
            if (datatableConfig.generateColumns && datatableConfig.columns && datatableConfig.columns.length) {
                $(context.main.current).find("thead tr").empty();
                var withI18n = false;
                $.each(datatableConfig.columns, function (i, e) {
                    if (commonFunc.isBlank(e.name)) { e.name = e.customTitle; }
                    var classSupp = "";
                    if (e.customClass) classSupp = "class='" + e.customClass + "'";
                    if (!commonFunc.isBlank(e.i18n)) { withI18n = true; classSupp += " data-i18n='" + e.i18n + "' "; }
                    $(context.main.current).find("thead tr").append("<th " + classSupp + ">" + e.customTitle + "</th>");
                });

                if (withI18n) $(context.main.current).i18n();
            }


            //Récupération des render
            if (datatableConfig.columnDefs && datatableConfig.columnDefs.length) {
                //var tableConfig = ((generateTableCustom && typeof (generateTableCustom) === "function") ? generateTableCustom() : generateTable());
                var generateProc = ((context.props.generateTableCustom && typeof (generateTableCustom) === "function") ? context.props.generateTableCustom.bind(context) : generateTable.bind(context));
                $.each(datatableConfig.columnDefs, function (i, e) {
                    if (!e.render) {
                        e.render = generateProc(e.generateFunction, e.generateArgs);
                        //e.render = tableConfig[e.generateFunction](e.generateArgs);
                    }
                });
            }
            //console.debug(datatableConfig);

            datatableConfig.data = Array.isArray(datatableConfig.data) ? datatableConfig.data.filter(rowData => !rowData || context.props.allowEmpty || parseInt(rowData[datatableConfig.rowId]) > 0) : datatableConfig.data;

            //Initialisation du DataTable et du select
            var table = $(context.main.current).DataTable(datatableConfig);
            table.off('select').on('select', function (e, dt, type, indexes) {
                if (localConfig.dtOnSelect && typeof (localConfig.dtOnSelect) === "function") { localConfig.dtOnSelect($(context.main.current).DataTable().rows(indexes).data().toArray()[0]); }
            });

            table.off('deselect').on('deselect', function (e, dt, type, indexes) {
                if (localConfig.dtOnUnselect && typeof (localConfig.dtOnUnselect) === "function") { localConfig.dtOnUnselect($(context.main.current).DataTable().rows(indexes).data().toArray()[0]); }
            });

            $(context.main.current).find('tbody').off('click', 'tr td.dt-clickableCol');
            if (localConfig.dtOnFirstColClick) {
                $(context.main.current).find('tbody').on('click', 'tr td.dt-clickableCol', function (e) {
                    if (localConfig.dtOnFirstColClick && typeof (localConfig.dtOnFirstColClick) === "function") { localConfig.dtOnFirstColClick(e); }
                });
            }
            $(context.main.current).find('tbody').off('dblclick', 'tr');
            if (localConfig.dtOnDblClick) {
                $(context.main.current).find('tbody').on('dblclick', 'tr', function (e) {
                    if (localConfig.dtOnDblClick && typeof (localConfig.dtOnDblClick) === "function") { localConfig.dtOnDblClick(e); }
                });
            }

            if (datatableConfig.colResize) {
                table.off('column-reorder').on('column-reorder', function (e, settings, details) {
                    if (!details.drop) {
                        console.debug("column-reorder");
                        $(context.main.current).DataTable().colResize.save();
                    }
                });
            }
        };

        if (context.props.categ) {
            context.getDataTableConfig(context.props.categ, callbackDT);
        }
        else {
            var defaultDataTableConfig = context.getDTDefaultConfig(context.props.tag, context.props.config ? context.props.config.customDTVersion : null);
            callbackDT(context.props.config ? context.props.config : {}, defaultDataTableConfig);
        }
    }

    setDisabled(value) {
        var table = $(this.main.current).DataTable();
        if (value) table.select.style('api');
        else table.select.style(table.init().select.style || table.init().select || "single");
    }

    getData(onlySelected) {
        var table = $(this.main.current).DataTable();
        var rows = onlySelected ? table.rows({ selected: true }) : table.rows();
        return { rowId: table.init().rowId, data: rows.data().toArray(), table: table };
    }
}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}

export default withI18n(CDataTable);

CDataTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
};


export function generateTable(generateFunction, generateArgs) {
    var t;
    var altT;
    if (this) {
        var context = this;

        //Gestion i18n
        t = context.props.i18nManager.t;
        altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    }

    var tableConfig =
    {
        avatar: function (data, type, row) {
            var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
            return "<div class='ui-icon-64 ui-icon-row rounded-circle h-100' style='background-image:url(&quot;" + rowValue + "&quot;)'></div>"; //img?
        },
        priority: function (data, type, row) {
            var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
            switch (parseInt(rowValue)) {
                case 1: return "<div class='text-center text-primary'><i class='fas fa-arrow-down'></i></div>"; /*"Faible"*/; break;
                case 2: return "<div class='text-center text-bold'></div>"; /*"Moyenne"*/; break;
                case 3: return "<div class='text-center text-danger'><i class='fas fa-exclamation'></i></div>"; /*"Urgente"*/; break;
                default: return "N/A"; break;
                /*<i class="fas fa-exclamation"></i>*/
            }
        },
        recall: function (data, type, row) { var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]); return "<span class='badge bg-secondary' style='border-radius:50%;padding: 5px 8px;'>" + rowValue + "</span>"; },
        status: function (data, type, row) {
            var rowValue;
            if (commonFunc.isBlank(generateArgs)) {
                rowValue = data;
            }
            else {
                if (generateArgs.indexOf(".") >= 0) {
                    var split = generateArgs.split(".");
                    rowValue = row[split[0]] ? row[split[0]][split[1]] : "";
                }
                else {
                    rowValue = row[generateArgs];
                }
            }
            return "<span class='" + (rowValue.replace ? rowValue.replace(/label-/g, "bg-").replace(/label/g, "badge").replace("bg-default", "bg-secondary") : "badge bg-sedondary") + "'>" + (data || "N/A") + "</span>";
        },
        datetime: function (data, type, row) {
            var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);

            var mDate = rowValue ? commonFunc.restStringToDate(rowValue) : null;
            if (commonFunc.isValidDate(mDate) && mDate.getFullYear() > 1 && mDate.getFullYear() !== 1969 && mDate.getFullYear() !== 1970 && mDate.getFullYear() !== 2100 /*&& mDate.getFullYear() > 1970*/) {
                return commonFunc.formatDateTime(moment(mDate).utc(true).toDate());
            }
            else {
                //return "";
                //Correctif order date désactiver à cause de présent de champ vide
                if (!commonFunc.isValidDate(mDate)) mDate = "0001-01-01T00:00:00";
                return "<div class='hidden'>" + commonFunc.formatDateDMY(moment(mDate).utc(true).toDate()) + "</div>";
            }
        },
        date: function (data, type, row) {
            var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);

            var mDate = rowValue ? commonFunc.restStringToDate(rowValue) : null;
            if (commonFunc.isValidDate(mDate) && mDate.getFullYear() > 1 && mDate.getFullYear() !== 1969 && mDate.getFullYear() !== 1970 && mDate.getFullYear() !== 2100 /*&& mDate.getFullYear() > 1970*/) {
                return commonFunc.formatDateDMY(moment(mDate).utc(true).toDate());
            }
            else {
                //return "";
                //Correctif order date désactiver à cause de présent de champ vide
                if (!commonFunc.isValidDate(mDate)) mDate = "0001-01-01T00:00:00";
                return "<div class='hidden'>" + commonFunc.formatDateDMY(moment(mDate).utc(true).toDate()) + "</div>";
            }
            /*if (mDate.getFullYear() != 1970 && mDate.getFullYear() != 2100) {
                return formatDateDMY(mDate);
            }
            else {
                return "<span class='hidden'>" + formatDateDMY(mDate) + "</span>";//""
            }*/
        },
        dateNoExclusion: function (data, type, row) {
            var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);

            var mDate = rowValue ? commonFunc.restStringToDate(rowValue) : null;
            if (commonFunc.isValidDate(mDate) && mDate.getFullYear() > 1) {
                return commonFunc.formatDateDMY(moment(mDate).utc(true).toDate());
            }
            else {
                //Correctif order date désactiver à cause de présent de champ vide
                if (!commonFunc.isValidDate(mDate)) mDate = "0001-01-01T00:00:00";
                return "<div class='hidden'>" + commonFunc.formatDateDMY(moment(mDate).utc(true).toDate()) + "</div>";
            }
        },
        datetimeNoExclusion: function (data, type, row) {
            var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);

            var mDate = rowValue ? commonFunc.restStringToDate(rowValue) : null;
            if (commonFunc.isValidDate(mDate) && mDate.getFullYear() > 1) {
                return commonFunc.formatDateTime(moment(mDate).utc(true).toDate());
            }
            else {
                //return "";
                //Correctif order date désactiver à cause de présent de champ vide
                if (!commonFunc.isValidDate(mDate)) mDate = "0001-01-01T00:00:00";
                return "<div class='hidden'>" + commonFunc.formatDateDMY(moment(mDate).utc(true).toDate()) + "</div>";
            }
        },
        price: function (data, type, row) { var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]); return commonFunc.formatPrice("fr-FR", "EUR", rowValue); },
        number: function (data, type, row) { var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]); return commonFunc.formatNumberFr(rowValue); },
        city: function (data, type, row) {
            var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
            //if (!rowValue) { rowValue = i18n.t("page.none2") }; //TODO
            if (rowValue.length) {
                var adresse = $.grep(rowValue, function (e, i) {
                    return e.Categ === "LOCATION";
                });
                if (adresse && adresse.length) {
                    return adresse[0].City;
                }
                else {
                    return rowValue;
                }
            }
            else {
                return rowValue;
            }
        },
        check: function (data, type, row) { var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]); return rowValue ? "<i class='fa fa-check'></i>" : "" },
        checkAlt: function (data, type, row) { var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]); return rowValue ? "<i class='fa fa-check'></i>" : "<i class='fa fa-times-circle'></i>" }
    };

    tableConfig["string"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : generateArgs);
        return rowValue;
    };
    tableConfig["preventEmpty"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : generateArgs);
        return rowValue || "";
    };
    tableConfig["stringLink"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : generateArgs);
        return "<div class='d-none'>" + data + "</div>" + rowValue.replace("@@TARGET@@", data);
    };
    tableConfig["rectAvatar"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        return "<div class='ui-icon-64 ui-icon-row h-100 border-0' style='background-size:contain !important;background-image:url(&quot;" + rowValue + "&quot;)'></div>"; //img?
    };

    tableConfig["ellipsis"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        return "<div title='" + rowValue + "' style='text-overflow: ellipsis;white-space: nowrap;max-width: 250px;overflow: hidden;'>" + rowValue + "</div>";
    };

    tableConfig["comments"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        if (rowValue) {
            return '<div style="height: 100px;overflow: hidden;overflow-y: auto;padding-right: 5px;">' + rowValue + '</div>';
        } else {
            return "";
        }
    };

    tableConfig["statusBadge"] = function (data, type, row) {
        var rowValue;
        if (commonFunc.isBlank(generateArgs)) {
            rowValue = data;
        }
        else {
            if (generateArgs.indexOf(".") >= 0) {
                var split = generateArgs.split(".");
                rowValue = row[split[0]] ? row[split[0]][split[1]] : "";
            }
            else {
                rowValue = row[generateArgs];
            }
        }
        return "<div class='" + rowValue.replace(/label-/g, "bg-").replace(/label/g, "badge").replace("bg-default", "bg-secondary") + " pt-2 pb-1 ps-2 pe-2 m-0' data-bindid='" + row.idReport + "' style='border: 1px solid #aaaaaa;'>&nbsp;&nbsp;</div>";
    };

    tableConfig["versionning"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        return "<span class='badge bg-secondary ps-2 pe-2 m-0' style='border-radius:50%;line-height:1.7'>" + rowValue.replace("-", "") + "</span>";
    };

    tableConfig["cp"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        //if (!rowValue) { rowValue = i18n.t("page.none2") }; //TODO
        rowValue = rowValue.toString();
        if (rowValue && rowValue.length >= 5) {
            var cp = rowValue.match(/\d{5}/g);
            if (!commonFunc.isBlank(cp)) {
                row.dpt = cp;
                return parseInt(cp[cp.length - 1].substr(0, 2)) || "";
            }
            else {
                return "";
            }
        }
        else {
            return "";
        }
    };

    tableConfig["reportSubStatus"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        var result = "";
        if (rowValue) {
            result += "<i class='fa fa-exclamation-triangle' title='A Replanifier' style='color:#f0ad4e;font-size:17px;'></i>&nbsp;&nbsp;";
        }
        if (row.isEmergency) {
            result += "<i class='fa fa-exclamation-circle' title='Urgence' style='color:#d9534f;font-size:17px;'></i>&nbsp;&nbsp;";
        }

        if (row.stepID > 0 && row.stepID != 350) {
            /*var targetStep = $("#btnLstSubStatus").find("input[id='status_" + row.stepID + "']");

            if (targetStep.length !== 0) {
                // $("#aupdRepStatus").closest("div").append("<span class='label statuslabel'><i class='fa " + targetStep.data("css") + "' title='" + targetStep.data("txt") + "' style='color:#d9534f;font-size:17px;'></i></span>");
                result += "<i class='fa " + targetStep.data("css") + "' title='" + targetStep.data("txt") + "' style='color:#d9534f;font-size:17px;'></i>&nbsp;&nbsp;"
            }*/

            if (row.subStatus) {
                // $("#aupdRepStatus").closest("div").append("<span class='label statuslabel'><i class='fa " + targetStep.data("css") + "' title='" + targetStep.data("txt") + "' style='color:#d9534f;font-size:17px;'></i></span>");
                result += "<i class='fa " + row.subStatus.css + "' title='" + row.subStatus.name + "' style='color:#d9534f;font-size:17px;'></i>&nbsp;&nbsp;"
            }
        }
        return result;
    };

    tableConfig["renderSubInfo"] = function (data, type, row) {
        var rowValue;
        if (commonFunc.isBlank(generateArgs)) {
            rowValue = data;
        }
        else {
            if (generateArgs.indexOf(".") >= 0) {
                var split = generateArgs.split(".");
                rowValue = row[split[0]] ? row[split[0]][split[1]] : "";
            }
            else {
                rowValue = row[generateArgs];
            }
        }

        return (data || "") + " " + rowValue;
    };

    tableConfig["time"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);

        var mDate = commonFunc.restStringToDate(rowValue);
        if (commonFunc.isValidDate(mDate) && mDate.getFullYear() > 1970) {
            return commonFunc.makeShortTime(mDate);
        }
        else {
            return "";
        }
    };
    tableConfig["shortTime"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);

        return rowValue.slice(0, 5);
    };


    tableConfig["substr2"] = function (data, type, row) { return data.toString().substr(0, 2); };
    tableConfig["substr2CP"] = function (data, type, row) {
        var value = data.toString();
        if (!value || value.length < 5) {
            value = commonFunc.convertPC(value);
        }
        if (value.length >= 5) {
            return value.substr(0, 2);
        }
        else {
            return "";
        }
    };

    tableConfig["concat"] = function (data, type, row) {
        var rowValue;
        if (commonFunc.isBlank(generateArgs)) {
            rowValue = data;
        }
        else {
            if (generateArgs.indexOf(".") >= 0) {
                var split = generateArgs.split(".");
                if (split[2]) {
                    rowValue = (row[split[0]] && row[split[0]][split[1]]) ? row[split[0]][split[1]][split[2]] : "";
                }
                else {
                    rowValue = row[split[0]] ? row[split[0]][split[1]] : "";
                }
            }
            else {
                rowValue = row[generateArgs];
            }
        }

        return data + " " + rowValue;
    };
    tableConfig["concatRows"] = function (data, type, row) {
        var rowValue;
        if (commonFunc.isBlank(generateArgs)) {
            rowValue = data;
        }
        else {
            if (generateArgs.indexOf(".") >= 0) {
                var split = generateArgs.split(".");
                if (split[2]) {
                    rowValue = (row[split[0]] && row[split[0]][split[1]]) ? row[split[0]][split[1]][split[2]] : "";
                }
                else {
                    rowValue = row[split[0]] ? row[split[0]][split[1]] : "";
                }
            }
            else {
                rowValue = row[generateArgs];
            }
        }

        return (data ? data : "") + "<br/>" + (rowValue ? rowValue : "");
    };

    tableConfig["filesLink"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        var node = "";
        if (rowValue && rowValue.length) {
            $.each(rowValue, function (i, e) {
                var file = e;

                var fileArgs = {
                    fileId: e.idFile,
                    fileName: e.name,
                    container: e.rootContainer,
                    etag: e.etag,
                    accessToken: e.absoluteUri,
                    newWindow: false
                };
                //var filePath = "../api/data/DownloadFileByUri?" + commonFunc.toParams(fileArgs);

                fileArgs.newWindow = true;
                var filePathDirectDl = "../api/data/DownloadFileByUri?" + commonFunc.toParams(fileArgs);

                node += "<a target='_blank' href='" + filePathDirectDl + "' class='text-nowrap btn btn-link btn-sm text-secondary p-0 m-0'>" + getFilesFaIcon(file.name) + "&nbsp;" + file.categ + "</a><br>";
                //node += "<button class='text-nowrap btn btn-link btn-sm text-secondary p-0' onclick='generateOrDl(" + file.ID + ");'><i class='fa fa-file-pdf' title='Fichier PDF'></i>Fichier PDF</button><br>";
            });
        }
        return node;
    };

    tableConfig["empty"] = function (data, type, row) {
        return "";
    };

    tableConfig["link"] = function (data, type, row) {
        return "<div class='d-none'>" + data + "</div><a target='_blank' href='" + data + "'><i class='fa fa-link'></i></a>";
    };

    tableConfig["errorCode"] = function (data, type, row) {
        var rowValue = (!commonFunc.isBlank(generateArgs) ? parseInt(generateArgs) : 200);
        return parseInt(data) !== rowValue ? ('<i class="fa fa-circle-times text-danger" />' + rowValue) : '<i class="fa fa-circle-check text-success" />';
    };

    tableConfig["contentSize"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        return commonFunc.bytesToSize(rowValue, 1);
    };
    tableConfig["msTime"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        return commonFunc.formatNumberFr(rowValue) + " ms";
    };
    tableConfig["urlParameters"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        return rowValue.split("&").filter(args => !args.startsWith("accessToken")).join("<br>");
    };

    tableConfig["renderingAccountType"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        var renderText = "";
        switch (rowValue) {
            case 1:
                renderText = "Compte Client";
                break;
            case 2:
                renderText = "Compte Admin";
                break;
            default:
                break;
        }
        return renderText;
    };

    tableConfig["renderI18nPath"] = function (data, type, row) {
        var rowValue = (commonFunc.isBlank(generateArgs) ? data : row[generateArgs]);
        return t("path." + rowValue.toLowerCase());
    };

    tableConfig["useAlternativeFieldIfEmpty"] = function (data, type, row) {
        if (generateArgs.altGenerateFunction) {
            var targetFunction;
            if (commonFunc.isBlank(data) || (generateArgs.supplementExclusionEnd && data.endsWith(generateArgs.supplementExclusionEnd))) {
                targetFunction = generateTable(generateArgs.altGenerateFunction, generateArgs.altGenerateArgs);
                return targetFunction ? targetFunction(row[generateArgs.altField], type, row) : data;
            }
            else {
                targetFunction = generateTable(generateArgs.currFunction, generateArgs.currArgs);
                return targetFunction ? targetFunction(data, type, row) : data;
            }
        }
        else {
            return data;
        }
    };



    //Editable Table

    tableConfig["customSelection"] = function (data, type, row) {

        return '<div class="custom-control custom-checkbox custom-check-color custom-control-inline"> \
                <input id="dt-customSelection' + data + '" name="dt-customSelection" type="checkbox" class="custom-control-input"> \
                <label class="custom-control-label text-dark" name="dt-customSelection_label" for="dt-customSelection' + data + '"></label> \
            </div>';
    };



    return tableConfig[generateFunction];
}

//Echo à la fonction de common.js mais en string
function getFilesFaIcon(file, prefix) {
    var extension = file.substr((file.lastIndexOf('.') + 1));
    if (extension) {
        switch (extension.toLowerCase()) {
            case "pdf":
                switch (prefix) {
                    case "PDF": return "<i class='fas fa-file-pdf' title='Document Adobe Reader' data-typef='doc'></i>";
                    default: return "<i class='fas fa-file-pdf' title='Document Adobe Reader' data-typef='doc'></i>";
                }

            case "doc": return "<i class='fas fa-file-word' title='Document Word' data-typef='doc'></i>";
            case "docx": return "<i class='fas fa-file-word' title='Document Word' data-typef='doc'></i>";
            case "rtf": return "<i class='fas fa-file-word' title='Document Word' data-typef='doc'></i>";

            case "xls": return "<i class='fas fa-file-excel' title='Document Excel' data-typef='doc'></i>";
            case "xlsx": return "<i class='fas fa-file-excel' title='Document Excel' data-typef='doc'></i>";
            case "csv": return "<i class='fas fa-file-excel' title='Document Excel CSV' data-typef='doc'></i>";

            case "ppt": return "<i class='fas fa-file-powerpoint' title='Document PowerPoint' data-typef='doc'></i>";
            case "pptx": return "<i class='fas fa-file-powerpoint' title='Document PowerPoint' data-typef='doc'></i>";

            case "html": return "<i class='fas fa-file-code' title='Document HTML' data-typef='doc'></i>";
            case "htm": return "<i class='fas fa-file-code' title='Document HTML' data-typef='doc'></i>";
            case "xml": return "<i class='fas fa-file-code' title='Document XML' data-typef='doc'></i>";

            case "zip":
                switch (prefix) {
                    case "RPT":
                        return "<i class='fas fa-file-archive text-info' title='Rapport Vierge Zip' data-typef='sys' style='color:#f0ad4e'></i>";
                    case "SAV":
                        return "<i class='fas fa-file-archive' title='Sauvegarde Rapport Zip' data-typef='sys'></i>";
                    default:
                        return "<i class='fas fa-file-archive' title='Archive' data-typef='doc'></i>";
                }


            case "rar": return "<i class='fas fa-file-archive' title='Archive' data-typef='doc'></i>";

            case "jpg": return "<i class='fas fa-file-image' title='Photo' data-typef='img'> </i>";
            case "jpeg": return "<i class='fas fa-file-image' title='Photo' data-typef='img'></i>";
            case "png": return "<i class='fas fa-file-image' title='Photo' data-typef='img'></i>";
            case "gif": return "<i class='fas fa-file-image' title='Photo' data-typef='img'></i>";
            case "tif": return "<i class='fas fa-file-image' title='Photo' data-typef='img'></i>";
            case "tiff": return "<i class='fas fa-file-image' title='Photo' data-typef='img'></i>";
            case "bmp": return "<i class='fas fa-file-image' title='Photo' data-typef='img'></i>";
            case "webp": return "<i class='fas fa-file-image' title='Photo' data-typef='img'></i>";


            case "avi": return "<i class='fas fa-file-video' title='Vidéo' data-typef='vid'></i>";
            case "mp4": return "<i class='fas fa-file-video' title='Vidéo' data-typef='vid'></i>";
            case "mpg": return "<i class='fas fa-file-video' title='Vidéo' data-typef='vid'></i>";
            case "mpeg": return "<i class='fas fa-file-video' title='Vidéo' data-typef='vid'></i>";


            case "txt": return "<i class='fas fa-file-alt' title='Document Texte' data-typef='doc'></i>";

            case "json":
                switch (prefix) {
                    case "RPT":
                        return "<i class='fas fa-edit text-info' title='Rapport Vierge' data-typef='sys' style='color:#f0ad4e'></i>";
                    case "RDI":
                        return "<i class='fas fa-pen-square text-info' title='Rapport Complété' data-typef='sys' style='color:#337ab7'></i>";
                    case "SAV":
                        return "<i class='fas fas fa-save' title='Sauvegarde Rapport' data-typef='sys'></i>";
                    default:
                        return "<i class='fas fa-pen-square' title='Document Json' data-typef='doc'></i>";
                }

            case "mp3": return "<i class='fas fa-file-audio title='Fichier Audio' data-typef='aud'></i>";
            case "wav": return "<i class='fas fa-file-audio title='Fichier Audio' data-typef='aud'></i>";
            default: return "<i class='fas fa-file' title='" + file + "' data-typef='doc'></i>";
        }
    } return "";
}


export function getDtSelID(id) {
    var table = $("#" + id).DataTable();
    var rows = table.rows({ selected: true });
    var rowIndex = rows[0];
    return table.cell(rowIndex, "ID:name").data() || 0;
}

export function getDtSelRowID(id) {
    var table = $("#" + id).DataTable();
    var rows = table.row({ selected: true });
    var rowId = rows.id();
    return rowId;
}



export function setDtNoSelect(id) {
    var table = $("#" + id).DataTable();
    var rows = table.row({ selected: true });
    if (rows) { rows.deselect(); }
}

export function showDtNextID(id, rowID) {
    //var curId
    if (rowID > 0) {
        var table = $("#" + id).DataTable();
        var idx = table.row('#' + rowID).index();
        idx += 1;
        var nextId = table.cell(idx, "ID:name").data() || 0;
        if (nextId > 0) {
            showDtSelID(id, nextId);
        }
    }
}
/*ID By Selection ID*/
export function showDtSelID(id, rowID) {
    var table = $("#" + id).DataTable();
    //console.debug(table.row('#' + rowID).draw())
    if (table.row('#' + rowID).length) {
        var row = table.row('#' + rowID).draw().show().draw(false);
        //bug DT
        var rows = table.rows('#' + rowID).select();//.nodes().to$().click();
    }
}


export function showDtPrevID(id, rowID) {
    //var curId
    if (rowID > 0) {
        var table = $("#" + id).DataTable();
        var idx = table.row('#' + rowID).index();
        idx -= 1;
        var nextId = table.cell(idx, "ID:name").data() || 0;
        if (nextId > 0) {
            showDtSelID(id, nextId);
        }
    }
}

/*Value By Row ID*/
function getDtValue(id, rowID, colName) {
    var table = $("#" + id).DataTable();
    //var rowIndex = table.row("#row-" + rowID).index();
    var rowIndex = table.row("#" + rowID).index();
    return table.cell(rowIndex, colName + ":name").data();
}
export function getDtSelData(id) {
    var table = $("#" + id).DataTable();
    var rows = table.row({ selected: true });
    if (rows) { return rows.data(); }
    return null;
}