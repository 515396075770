import React, { Component } from 'react';

import AGenerateModel from 'components/generic/AGenerateModel';

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";



import * as commonFunc from 'scripts/common';

import { withRouter } from 'components/withRouter';

class ManageCustom extends Component {
    static displayName = ManageCustom.name;

    static models;

    constructor(props) {
        super(props);

        this.customGen = React.createRef(null);

        this.state = { key: "" };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function (curUser) {
            context.getContent();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.searchParams !== prevProps.searchParams) {
            this.getContent();
        }
    }

    getContent() {
        this.setState({ key: "", config: null, modelsList: null });
        var context = this;

        //Chargement via url
        var urlCustomKey = "";
        if (context.props.searchParams) {
            urlCustomKey = context.props.searchParams.get("key");
        }
        if (!commonFunc.isBlank(urlCustomKey)) {

            commonFunc.getMethod("../api/custom/GetCustomModels", { id: -1, categ: urlCustomKey }).then((responseModel) => {
                var dataModel = responseModel.data;
                if (dataModel.isSuccess && dataModel.result && dataModel.result.models) {
                    //var customConfig = JSON.parse(dataModel.result);

                    var customConfig = JSON.parse(dataModel.result.models.filter((model) => model.extID === urlCustomKey)[0].value);
                    var customModels = {};
                    dataModel.result.models.forEach((model) => {
                        customModels[model.extID] = JSON.parse(model.value);
                    });

                    //TODO : URL_GETSTATUS >> api/client/GetStatus >> api/custom/GetStatus ( voir pour récup spécifiques (CUSTOM_FANSES >> filtrer les statuts terminant par FANSES))
                    
                    this.setState({ key: urlCustomKey, config: customConfig, modelsList: customModels });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModel") + " :<br>" + dataModel.errorMessage
                    });
                }
            });

        } else {
            Swal.fire({
                icon: "error",
                title: context.props.i18nManager.t("gal_fail"),
                html: context.props.i18nManager.t("gal_failLoadModel") + " : " + context.props.i18nManager.t("gal_invalidArgs") 
            });
        }
    }

    render() {
        return this.state.config ? (
            <div className="customModule">
                <AGenerateModel ref={this.props.customGen} parentProps={{ type: "custom", dataCfg: this.props.dataCfg }} noButtons modelsList={this.state.modelsList} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} getUser={this.props.getUser} configModel={this.state.config} />
            </div>
        ) : <div>{this.props.i18nManager.t("gal_loadingModule")}</div>;
    }

}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}

export default withRouter(withI18n(ManageCustom));