import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
    Button,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    FormFeedback,
    Row,Col
} from "reactstrap";

import Swal from "sweetalert2";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Cleave from 'cleave.js/react';

import * as commonFunc from 'scripts/common';

function RegisterStepCompany(props) {
    const { t } = useTranslation();

    //Création du schéma pour le paramètrage du formulaire
    const schema = yup.object().shape({
        company: yup.string().required(t("gal_reqFields")).min(2, t("gal_minChar", [2])).max(50, t("gal_maxChar", [50])),
        //legalForm: yup.string(),
        //companyReg: yup.string().required(t("gal_reqFields")).min(17, t("gal_minChar", [14])).max(17, t("gal_maxChar", [14])),
        //address: yup.string().required(t("gal_reqFields")).min(2, t("gal_minChar", [2])).max(100, t("gal_maxChar", [100])),
        //cp: yup.string().required(t("gal_reqFields")).min(6, t("gal_minChar", [5])).max(6, t("gal_maxChar", [5])),
        //city: yup.string(),
    }).required();

    React.useEffect(() => {
        if (props.triggerAnimate) props.triggerAnimate();
    }, [props.shouldRender]);

    //Paramètrage du formulaire
    const { control, register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    async function triggerSubmit(data) {


        /*Swal.fire({
            title: t("log_checkInfos"),
            didOpen: () => { Swal.showLoading() }
        });
        commonFunc.getMethod("../weatherforecast/WFValidateSiret?siret=" + data.companyReg, null).then((response) => {
            var result = response.data;
            if (result.isSuccess) {
                Swal.close();*/

                if (props.updateStep) props.updateStep(data);
            /*}
            else if (result.errorCode == 400 || result.errorCode == 500) {

                Swal.fire({
                    icon: 'error',
                    title: result.errorMessage,
                    text: '',
                });
            }
        });*/
    }

    const { ref: refCompany, ...restCompany } = register("company");
    //const { ref: refLegalForm, ...restLegalForm } = register("legalForm");
    //const { ref: refCompanyReg, ...restCompanyReg } = register("companyReg");
    //const { ref: refAddress, ...restAddress } = register("address");
    //const { ref: refCp, ...restCp } = register("cp");
    //const { ref: refCity, ...restCity } = register("city");


    //TODO : props.isFrench

    return (
        <Form action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerSubmit)}>
            <CardHeader className="bg-white">
                <CardTitle className='header my-0 py-0' tag="h5">{t("register_companyInfos")}</CardTitle>
            </CardHeader>
            <CardBody>
                <Row className="pb-2">
                    <Col className="align-self-end" xs="12">
                        <InputGroup className="single-input">
                            <InputGroupText className="mandatory">
                                <i className="fa fa-building" />
                            </InputGroupText>
                            <div className="form-floating min-input">
                                <Input id="inputcompany" type="text" autoComplete="no" aria-autocomplete="none" placeholder={t("register_companyName")} innerRef={refCompany} {...restCompany} invalid={errors.company ? true : false} />
                                <Label for="inputcompany">{t("register_companyName")}</Label>
                            </div>
                            <FormFeedback className={`text-start ${errors.company ? "d-block" : ""}`}>{errors.company ? errors.company.message : t("gal_reqFields")}</FormFeedback>
                        </InputGroup>
                    </Col>
                    {/*
                    <Col className="text-start" xs="6">
                        <Label className="mandatory" for="inputlegalForm">{t("register_legalForm")}</Label>
                        <Input id="inputlegalForm" className="w-100 mb-2 floating-size" name="inputlegalForm" type="select" innerRef={refLegalForm} {...restLegalForm} invalid={errors.legalForm ? true : false} defaultValue={"SARL"}>
                            <option value="EI">EI</option>
                            <option value="EIRL">EIRL</option>
                            <option value="EURL">EURL</option>
                            <option value="SARL">SARL</option>
                            <option value="SA">SA</option>
                            <option value="SAS">SAS</option>
                            <option value="SASU">SASU</option>
                            <option value="SNC">SNC</option>
                            <option value="SCOP">SCOP</option>
                            <option value="SCA">SCA</option>
                            <option value="SCS">SCS</option>
                        </Input>
                        <FormFeedback className={`text-start ${errors.legalForm ? "d-block" : ""}`}>{errors.legalForm ? errors.legalForm.message : t("gal_reqFields")}</FormFeedback>
                    </Col>
                    */}
                </Row>
                {/*
                <InputGroup className="single-input">
                    <InputGroupText className="mandatory">
                        <i className="fa fa-landmark" />
                    </InputGroupText>
                    <div className="form-floating min-input">
                        <Controller
                            control={control}
                            name='companyReg'
                            render={({ field: { ref, ...field } }) => (
                                <Cleave id="inputcompanyReg" className={`form-control ${errors.companyReg ? "is-invalid" : ""}`} autoComplete="no" aria-autocomplete="none" placeholder={t("register_siretNum")}
                                    options={{ blocks: [3, 3, 3, 5], numericOnly: true }}
                                    value={field.value}
                                    htmlRef={ref} {...field} />
                            )}
                        />
                        <Label for="inputcompanyReg">{t("register_siretNum")}</Label>
                    </div>
                    <FormFeedback className={`text-start ${errors.companyReg ? "d-block" : ""}`}>{errors.companyReg ? errors.companyReg.message : t("gal_reqFields")}</FormFeedback>
                </InputGroup>
                */}
                {/*
                <InputGroup className="single-input pb-2">
                    <InputGroupText className="mandatory">
                        <i className="fa fa-location-dot" />
                    </InputGroupText>
                    <div className="form-floating min-input">
                        <Input id="inputaddress" type="text" autoComplete="no" aria-autocomplete="none" placeholder={t("register_companyAddress")} innerRef={refAddress} {...restAddress} invalid={errors.address ? true : false} />
                        <Label for="inputaddress">{t("register_companyAddress")}</Label>
                    </div>
                    <FormFeedback className={`text-start ${errors.address ? "d-block" : ""}`}>{errors.address ? errors.address.message : t("gal_reqFields")}</FormFeedback>
                </InputGroup>

                <Row className="pb-2">
                    <Col className="align-self-end" xs="5">
                        <InputGroup className="single-input">
                            <InputGroupText className="mandatory">
                                <i className="fa fa-location-dot" />
                            </InputGroupText>
                            <div className="form-floating min-input">
                                <Controller
                                    control={control}
                                    name='cp'
                                    render={({ field: { ref, ...field } }) => (
                                        <Cleave id="inputcp" className={`form-control ${errors.cp ? "is-invalid" : ""}`} autoComplete="no" aria-autocomplete="none" placeholder={t("register_companyPc")}
                                            options={{ blocks: [2, 3], numericOnly: true }}
                                            value={field.value}
                                            htmlRef={ref} {...field} />
                                    )}
                                />
                                <Label for="inputcp">{t("register_companyPc")}</Label>
                            </div>
                            <FormFeedback className={`text-start ${errors.cp ? "d-block" : ""}`}>{errors.cp ? errors.cp.message : t("gal_reqFields")}</FormFeedback>
                        </InputGroup>
                    </Col>
                    <Col className="text-start" xs="7">
                        <Label className="mandatory d-none" for="inputcity">{t("register_companyCity")}</Label>
                        <Input id="inputcity" className="w-100 floating-size" name="inputcity" type="select" innerRef={refCity} {...restCity} invalid={errors.city ? true : false} defaultValue={"Paris"}>
                            <option value="">Choix de la Ville</option>
                        </Input>
                        <FormFeedback className={`text-start ${errors.city ? "d-block" : ""}`}>{errors.city ? errors.city.message : t("gal_reqFields")}</FormFeedback>
                    </Col>
                </Row>
                */}
            </CardBody>
            <CardFooter className="text-end mt-0">
                {props.prevStep ? <Button title={t("register_previous")} className="btn-icon rounded-circle bg-altLink-color btn btn-primary me-2" color="info" onClick={(e) => { e.preventDefault(); props.prevStep(); }}><i className="fa fa-arrow-left" style={{ fontSize: "1.6em" }}></i></Button> : ""}
                <Button title={t("register_next")} className="btn-icon rounded-circle bg-altLink-color btn btn-primary" color="info"><i className="fa fa-check" style={{ fontSize: "1.6em" }}></i></Button>
            </CardFooter>
        </Form>
    );
}

export default RegisterStepCompany;
