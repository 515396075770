import React from 'react';
import moment from "moment";

import { useTranslation } from "react-i18next";
import * as commonFunc from 'scripts/common';

import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.css';

import CChart from 'components/CChart';

import { Button, Card, CardHeader, CardBody, CardTitle, Row } from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AGenerateModel from './generic/AGenerateModel';

var gridCb;

const CGridStack = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    const [grid, setGrid] = React.useState();
    const [state, setState] = React.useState(false);
    const [items, setItems] = React.useState();
    const [rerender, setRerender] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
        getGrid: () => { return grid; },
    }));


    React.useEffect(() => {
        if (gridCb) {
            gridCb();
        }
    }, [rerender]);

    React.useEffect(() => {
        console.debug("Init Data");
        var defaultGridStackData = JSON.parse(props.data);
        var optDataKey = localStorage.getItem(props.type);
        if (optDataKey === null) {
            localStorage.setItem(props.type, props.data);
        }
        else {
            defaultGridStackData = JSON.parse(optDataKey);
        }

        saveItems(defaultGridStackData);
    }, [props.data]);

    React.useEffect(() => {
        if (items) {
            console.debug("GO INIT GRIDSTACK");
            initGridStack();
        }
    }, [items]);

    function initGridStack() {
        var options = {
            animate: true,
            width: 12,
            column: 12,//1
            columnOpts: { breakpoints: [{ w: 700, c: 1 }] },//768
            heigth: 0,
            cellHeight: 70,
            placeholderClass: "grid-stack-placeholder",
            alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            resizable: { handles: "e,se,s,sw,w" },
            //disableDrag: true,
            //disableResize: true,
            // draggable: { handle: '.grid-stack-item-content', scroll: true, appendTo: 'body' },
            // staticGrid:true,
        };


        if (commonFunc.isMobileDevice()) {
            //options.ddPlugin = false;
            options.disableDrag = true;
            options.disableResize = true;
        }

        var newGrid = GridStack.init(options);
        newGrid.off('change').on('change', function (event, gridItems) {
            var flag = false;
            items.forEach((item, index) => {
                var changedItem = gridItems.filter((gridItem) => gridItem.id === item.id);
                if (changedItem.length) {
                    var curItem = changedItem[0];
                    item.w = curItem.w;
                    item.h = curItem.h;
                    item.x = curItem.x;
                    item.y = curItem.y;
                    //item.hidden = curItem.hidden;

                    flag |= (curItem.w !== 1);
                }
            });
            if (flag) saveItems(items);
        });

        if (!state) newGrid.disable();

        setGrid(newGrid);
    }

    function saveItems(newItems) {
        localStorage.setItem(props.type, JSON.stringify(newItems));
        setItems(newItems);
    }


    function addNewWidget(id) {
        var newItem;
        items.forEach((item) => {
            if (item.id === id) {
                item.hidden = false;
                newItem = item;
            }
        });

        saveItems(items);
        gridCb = (() => {
            gridCb = null;
            if (newItem) {
                grid.makeWidget('#' + newItem.id);
            }
            setRerender(!rerender);
        });
        setRerender(!rerender);
    }

    function removeItem(id) {
        grid.getGridItems().forEach((gridItem, index) => {
            if (gridItem.gridstackNode && gridItem.gridstackNode.id === id) {
                items.forEach((item) => {
                    if (item.id === gridItem.gridstackNode.id) {
                        item.hidden = true;
                    }
                });

                saveItems(items);
                gridCb = (() => {
                    gridCb = null;
                    grid.removeWidget(gridItem.gridstackNode.el, false);
                    setRerender(!rerender);
                });
                setRerender(!rerender);
            }
        });
    }

    function clearWidgets() {
        items.forEach((item) => {
            item.hidden = true;
        });

        saveItems(items);
        gridCb = (() => {
            gridCb = null;
            grid.removeAll(false);
            setRerender(!rerender);
        });
        setRerender(!rerender);
    }

    function resetOptions() {
        localStorage.removeItem(props.type);
        var newItems = JSON.parse(props.data);


        saveItems(newItems);
        gridCb = (() => {
            gridCb = null;
            grid.destroy(false);
            initGridStack();
            //setRerender(!rerender);
            //TODO : render ne mais pas a jour les sizes
        });
        setRerender(!rerender);
    }

    function changeState() {
        if (state) {
            grid.disable();
            setState(false);
        }
        else {
            grid.enable();
            setState(true);
        }
    }

    console.debug("GO RENDER !!!!!");

    //Voir pour gérer le gridstack dans un model + un model par item



    /*<div className="grid-stack-item border-dark" data-gs-width="4" data-gs-height="4">
                <div className="grid-stack-item-content">Item 1</div>
            </div>
            <div className="grid-stack-item border-dark" data-gs-width="4" data-gs-height="4">
                <div className="grid-stack-item-content">Item 2</div>
            </div>
            <div className="grid-stack-item border-dark" data-gs-width="4" data-gs-height="4">
                <div className="grid-stack-item-content">Item 3</div>
            </div> */

    return (<div className={`${props.className} ${state ? "edit-gridstack" : ""}`}>
        <Button className="btn btn-icon btn-round" onClick={() => { changeState(); } }><i className="fa fa-gear"></i></Button>
        <Button className={`btn btn-icon btn-round ${state ? "" : "d-none"}`} color="danger" onClick={() => { resetOptions(); } } title="Réinitialiser"><i className="fa fa-trash"></i></Button>
        <Select isMulti={true} className={`${state ? "" : "d-none"}`} onChange={(e, target) => {
            if (target.action === "remove-value") {
                removeItem(target.removedValue.value)
            }
            else if (target.action === "select-option") {
                addNewWidget(target.option.value);
            }
            else if (target.action === "clear") {
                clearWidgets();
            }
            else {
                //TODO : Create option
            }
        }} value={items ? items.map((item) => {
            if (!item.hidden) {
                return { value: item.id, label: item.title };
            }
            else {
                return "";
            }
        }) : []}
            options={items ? items.map((item) => { return { value: item.id, label: item.title } }) : []} />
        <div className={`grid-stack ${props.gridClass ? props.gridClass : ""}`}>
            {items ? items.map((item, index) => (item.hidden ? "" : <div key={item.id} id={ item.id} gs-id={item.id} className={`grid-stack-item border-dark`} gs-x={item.x} gs-y={item.y} gs-w={item.w} gs-h={item.h}>
                <div className="grid-stack-item-content">
                    <div class={`widget-actions ${state ? "" : "d-none"}`}>
                        <div class="widget-hide pull-right me-3" onClick={() => removeItem(item.id)}>
                            <a class="">
                                <i class={`fa fa-times ${props.actionsClass ? props.actionsClass : ""}`}></i>
                            </a>
                        </div>
                    </div>
                    {props.configModel && props.configModel[item.type] ? <AGenerateModel {...props} parentProps={{ type: "tdb" }} /*ref={parentContext.modalGenManager}*/ noButtons /*onSubmit={(data, isUpdate) => callback(data, isUpdate)} appProps={parentContext.props} checkRights={parentContext.props.checkRights}
                        getRoutes={parentContext.props.getRoutes}*/ configModel={props.configModel ? props.configModel[item.type] : {}} modelsList={props.configModel ? props.configModel : []} loadedData={item} />
                        : <CGridStackItem src={item} t={t} altT={altT} />}
                </div>
            </div>)) : ""}
        </div>
    </div>);
});

export default CGridStack;



function CGridStackItem({ icon, title, src, t, altT, children }) {
    const [data, setData] = React.useState(src.data);
    const [altData, setAltData] = React.useState(src.altData || { labels: [], datasets: [] });
    const [refreshData, setRefreshData] = React.useState(false);

    React.useEffect(() => {
        switch (src.type) {
            case "CHART_USERSCON":
                commonFunc.getMethod("../api/GetUserUsageStatement", { period: "Y" }).then((responseData) => {
                    var dataRes = responseData.data;
                    if (dataRes.isSuccess) {

                        console.debug(dataRes.result);

                        var userLabels = [];
                        var usersDataset = [];
                        var devicesDataset = [];
                        var dataUserCount = 0;
                        dataRes.result.forEach((userStatement, index) => {
                            //userLabels.push(userStatement.period);
                            userLabels.push(moment(userStatement.period, "YYYY-MM").format("MMM YYYY"));

                            usersDataset.push(userStatement.usersCount);
                            devicesDataset.push(userStatement.devicesCount);

                            console.debug(userStatement.usersCount);
                            dataUserCount = userStatement.usersCount;
                        });
                        setData(dataUserCount);

                        /*setAltData({
                            labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                            datasets: [
                                {
                                    label: "Mobile apps",
                                    color: "#1A73E8",
                                    data: [15, 85, 300, 220, 500, 250, 400, 230, 500],
                                },
                                {
                                    label: "Websites",
                                    color: "#D81B60",
                                    data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
                                },
                            ],
                        });*/
                        setAltData({
                            labels: userLabels,
                            datasets: [
                                {
                                    label: "Utilisateurs",
                                    color: "#1A73E8",
                                    data: usersDataset,
                                },
                                {
                                    label: "Appareils",
                                    color: "#D81B60",
                                    data: devicesDataset,
                                },
                            ],
                        });

                    } else {
                        /*Swal.fire({
                            icon: "error",
                            title: context.props.i18nManager.t("gal_fail"),
                            html: context.props.i18nManager.t("gal_failLoadModels") + " :<br>" + dataModels.errorMessage
                        });*/
                    }
                });

                break;
            default:
                break;
        }
    }, [refreshData]);

    var mValue = "";
    var mIcon = "";
    var mTitle = src.title;
    switch (src.type) {
        case "CHART_USERSCON":
            mValue = (<>
                <Button className="btn btn-simple btn-rotate btn-dark btn-sm px-2 mt-0 mb-0" onClick={() => { setRefreshData(!refreshData); }}><i className="fa fa-sync"></i>{t("gal_refresh")}</Button>
                <CChart type="line" redraw
                className="d-inline-block align-top"
                style={{ width: "100%", height: "250px" }}
                data={altData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: "index",
                    },
                    scales: {
                        y: {
                            grid: {
                                drawBorder: false,
                                display: true,
                                drawOnChartArea: true,
                                drawTicks: false,
                                borderDash: [5, 5],
                            },
                            ticks: {
                                display: true,
                                padding: 10,
                                color: "#b2b9bf",
                                font: {
                                    size: 11,
                                    family: "Roboto",
                                    style: "normal",
                                    lineHeight: 2,
                                },
                            },
                        },
                        x: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                                borderDash: [5, 5],
                            },
                            ticks: {
                                display: true,
                                color: "#b2b9bf",
                                padding: 20,
                                font: {
                                    size: 11,
                                    family: "Roboto",
                                    style: "normal",
                                    lineHeight: 2,
                                },
                            },
                        },
                    },
                    }} />
            </>);

            mIcon = <i className="fa fa-user" style={{ fontSize: "35px" }}></i>;
            mTitle = <><span className="me-2" style={{ fontSize: "20px" }}>{data}</span><span>{src.title}</span></>;
            break;
        default:
            break;
    }

    return (<Card className={`widget dash h-100`}>
        <CardHeader className="pt-0">
            <CardTitle tag="h6" className="mt-3">{mTitle}</CardTitle>
            <div className="widget-tools collapse-tool">
                {mIcon}
            </div>
            <div className='borderTheme'></div>
        </CardHeader>
        <CardBody>
            {mValue}
        </CardBody>
    </Card>);
}