import React from 'react';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import "cleave.js/dist/addons/cleave-phone.i18n";


import * as commonFunc from 'scripts/common';

import {
    Label, Badge, Col, Progress
} from "reactstrap";


export default function ProgressBarFrag({ data, formManager, modelFctManager, bindPropResult, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors, control } = formManager;

    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data, args.trigger === "setValue" ? { value: (data.hasOwnProperty("defaultValue") ? data.defaultValue : "") } : null).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }, []);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.setValue = (value, withRerender, src) => {
        return new Promise((resolve, reject) => {
            setValue(bindPropResult, value);
            if (withRerender) setShouldRender(!shouldRender);

            resolve({ isSuccess: true, result: value });
        });
    };


    const { ref: refElem, ...restElem } = register(bindPropResult, {
        value: (data.hasOwnProperty("defaultValue") ? data.defaultValue : ""),
        valueAsNumber: (data.subType === "integer" || data.subType === "float")
    });

    function passwordStrength(password) {
        var desc = {};


        /*desc[0] = { desc: "Très Faible", classInfo: 'danger', value: 16 };
        desc[1] = { desc: "Faible", classInfo: 'danger', value: 32 };
        desc[2] = { desc: "Médiocre", classInfo: 'warning', value: 48 };
        desc[3] = { desc: "Moyen", classInfo: 'warning', value: 64 };
        desc[4] = { desc: "Fort", classInfo: 'success', value: 80 };
        desc[5] = { desc: "Très Fort", classInfo: 'success', value: 100 };*/

        desc[0] = { desc: "Faible", classInfo: 'danger', value: 16 };
        desc[1] = { desc: "Faible", classInfo: 'danger', value: 32 };
        desc[2] = { desc: "Faible", classInfo: 'danger', value: 48 };
        desc[3] = { desc: "Faible", classInfo: 'danger', value: 64 };
        desc[4] = { desc: "Moyen", classInfo: 'warning', value: 72 };
        desc[5] = { desc: "Correct", classInfo: 'success', value: 80 };
        desc[6] = { desc: "Fort", classInfo: 'success', value: 100 };

        var score = 0;

        if (!password) {
            return { infos: desc[score], score: score };
        }

        //if password bigger than 6 give 1 point
        if (password.length >= 6) score++;

        //if password has both lower and uppercase characters give 1 point
        if (password.match(/[a-z]/)) score++;
        if (password.match(/[A-Z]/)) score++;

        //if password has at least one number give 1 point
        if (password.match(/\d+/)) score++;

        //if password has at least one special caracther give 1 point
        if (password.match(/.[!,@@,#,$,%,^,&,*,?,_,~,-,(,)]/)) score++;

        //if password bigger than 10 give another 1 point
        if (password.length >= 10 && score > 4) score++;


        return desc[score];
    }


    var frameClass = "d-inline-block mb-3";
    var frameStyle = {};
    var containerClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    var formClass = "form-group m-0 ";

    var icon = "";
    var titleClass = "";
    var titleStyle = {};
    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;
            if (design.alignment) {
                var split = design.alignment.split("|");
                split.forEach((part) => {
                    frameClass += " text-" + part.replace("left", "start").replace("right", "end");
                    frameClass = frameClass.replace("text-top", "align-self-start").replace("text-bottom", "align-self-end");
                    if (part === "center" && design.alignment.indexOf("top") < 0 && design.alignment.indexOf("bottom") < 0) frameClass += " align-self-center";
                    containerClass += " justify-content-" + part.replace("left", "start").replace("right", "end");
                });
            }
            if (design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(design.boxSize) * 100);
                //titleClass += " w-100";
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(design.boxSize) * 100);
                titleClass += modelFctManager.convertWidthToGrid(100);
            }
            if (design.size || design.width) frameStyle.width = (design.size || design.width) + "px";
            if (design.margin) {
                frameClass = frameClass.replace("mb-3", "");
                frameStyle.margin = design.margin;
            }
            if (design.marginStart) frameStyle.marginLeft = design.marginStart;
            if (design.marginTop) frameStyle.marginTop = design.marginTop;
            if (design.marginEnd) frameStyle.marginRight = design.marginEnd;
            if (design.marginBottom) {
                frameClass = frameClass.replace("mb-3", "");
                frameStyle.marginBottom = design.marginBottom;
            }
        }
        if (data.customDesign.designTitle) {
            var designTitle = data.customDesign.designTitle;
            if (designTitle.alignment) {
                var split = designTitle.alignment.split("|");
                split.forEach((part) => {
                    titleClass += " text-" + part.replace("left", "start").replace("right", "end");
                    titleClass = titleClass.replace("text-top", "align-self-start").replace("text-bottom", "align-self-end");
                });
            }
            if (designTitle.boxSize) {
                //titleWidthClass += " w-" + parseInt(parseFloat(designTitle.boxSize) * 100);
                titleClass = titleClass.replace(modelFctManager.convertWidthToGrid(100), "");
                titleClass += designTitle.boxSize === "auto" ? " col-auto" : modelFctManager.convertWidthToGrid(parseFloat(designTitle.boxSize) * 100);
            }
            if (designTitle.backColor) titleStyle.backgroundColor = modelFctManager.getRgbaFromHex(designTitle.backColor);
            if (designTitle.fontColor) titleStyle.color = modelFctManager.getRgbaFromHex(designTitle.fontColor);
            if (designTitle.fontSize) titleStyle.fontSize = designTitle.fontSize;
            if (designTitle.fontStyle) {
                if (designTitle.fontStyle.indexOf("bold") >= 0) titleStyle.fontWeight = "bold";
                if (designTitle.fontStyle.indexOf("italic") >= 0) titleStyle.fontStyle = "italic";
            }
            if (designTitle.margin) {
                titleClass = titleClass.replace("mb-0", "");
                titleStyle.margin = designTitle.margin;
            }
            if (designTitle.marginStart) titleStyle.marginLeft = designTitle.marginStart;
            if (designTitle.marginTop) titleStyle.marginTop = designTitle.marginTop;
            if (designTitle.marginEnd) titleStyle.marginRight = designTitle.marginEnd;
            if (designTitle.marginBottom) {
                titleClass = titleClass.replace("mb-0", "");
                titleStyle.marginBottom = designTitle.marginBottom;
            }
        }
        if (data.customDesign.designIcon) {
            var designIcon = data.customDesign.designIcon;
            var iconStyle = {};
            if (designIcon.fontColor) iconStyle.color = modelFctManager.getRgbaFromHex(designIcon.fontColor);
            if (designIcon.fontSize) iconStyle.fontSize = designIcon.fontSize;
            if (designIcon.margin) iconStyle.margin = designIcon.margin;
            if (designIcon.marginStart) iconStyle.marginLeft = designIcon.marginStart;
            if (designIcon.marginTop) iconStyle.marginTop = designIcon.marginTop;
            if (designIcon.marginEnd) iconStyle.marginRight = designIcon.marginEnd;
            if (designIcon.marginBottom) iconStyle.marginBottom = designIcon.marginBottom;

            icon = <i className={`fa ${designIcon.icon.replace(/_/g, "-") }`} style={iconStyle}></i>
        }

        if (!commonFunc.isBlank(titleClass)) containerClass += " row m-0 row-cols-lg-auto2 align-items-center";
    }

    var defaultVal = data.defaultValue;
    defaultVal = bindPropResult ? (getValues(bindPropResult) || data.defaultValue) : defaultVal;

    var title = defaultVal ? defaultVal.toString() : data.title;

    //Récupération des infos de validité du mot de passe
    var infosPwd = passwordStrength(getValues(commonFunc.camelize(data.isInfluencedBy, true)) || "");

    var baseForm = "";
    if (data.subType === "passwordStrength") {
        setValue(bindPropResult, infosPwd);
        baseForm = <Progress className="w-25 float-end" color={infosPwd.classInfo} value={infosPwd.value} style={{ height: '15px' }}><span className="position-absolute ms-4 fw-bold">{infosPwd.desc}</span></Progress>
    }
    else {
        baseForm = <div className={`${containerClass} ${formClass}`}>
            <Label className={`${titleClass} mb-0`} style={titleStyle}>{altT(title)}</Label>
            {data.subType === "divider" ? <hr className="hr-line-solid mb-0" /> : ""}
            {data.subType === "divider-dashed" ? <hr className="hr-line-dashed mb-0" /> : ""}
            {icon}
        </div>;
    }

    //console.debug("render progressbar");

    return <Col data-name="ProgressBarFrag" data-element={data.objType} xs="auto" className={`${frameClass} ${parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`} style={frameStyle}>{baseForm}</Col>;//align-self-center
}