import React, { Component } from 'react';
import { Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import * as commonFunc from 'scripts/common';

import moment from "moment";
import Swal from "sweetalert2";

import { withRouter } from 'components/withRouter';

class ShowContent extends Component {
    static displayName = ShowContent.name;

    constructor(props) {
        super(props);

        this.state = { content: "", files: [] };
    }

    componentDidMount() {
        //console.debug("mount");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function () {
            context.getContent();
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.searchParams !== prevProps.searchParams) {
            this.getContent();
        }
    }

    async getContent() {
        this.setState({ content: "", files: [] });
        var context = this;

        var currentID = parseInt(this.props.searchParams.get("ID")) || -1;
        var bindID = this.props.searchParams.get("bindID") || "";
        commonFunc.getMethod("../api/custom/GetCustomModels", { id: -1, categ: "PGE", modelId: currentID }).then((responseModel) => {
            var dataModel = responseModel.data;

            var currPage = null;
            if (dataModel.isSuccess && dataModel.result && dataModel.result.models && dataModel.result.models.length) {
                /*var resFilter = dataModel.result.models.filter((model) => model.extID === bindID);
                if (resFilter && resFilter.length) {
                    currPage = resFilter[0];
                }*/
                currPage = dataModel.result.models[0];
            }

            var currContent = "";
            var currFiles = [];
            if (currPage) {
                var currentPage = JSON.parse(currPage.value);

                //$("#contentsmenu-" + currentPage.ID).closest("li").addClass("active");

                currContent = '<div>' + currentPage.Details + '</div>';
                currFiles = currentPage.Files;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Echec',
                    html: 'Echec de chargement de la page ' + dataModel.errorMessage,
                });

                currContent = '<div id="page-content">Echec de chargement de la page</div>';
            }
            context.setState({ content: currContent, files: currFiles });

        });
    }

    render() {
        var manageFiles = "";
        console.debug(this.state.files);
        if (this.state.files) {
            var filesContent = this.state.files.map((e, i) => {
                if (e.categ !== "AVA") {
                    var dlLink = "../api/data/DownloadFileByUri?fileID=" + parseInt(e.idFile) + "&bindID=" + parseInt(e.bindID) + "&newWindow=true";//&filetypeID=8 //TODO : ajout les variables dans l'objet Fichier OU faire autrement

                    var idFilePopover = "file" + parseInt(e.idFile);

                    return (<Col key={i} className="p-1" data-fileid={parseInt(e.idFile) || 0} sm="6">
                        <div className="ri-files-box bg-secondary" id={idFilePopover}>
                            <a href={dlLink} title={e.name}>
                                {commonFunc.getFilesFaIcon(e.name)}
                                <span className="ms-1">{e.name}</span>
                            </a>
                        </div>
                        <UncontrolledPopover
                            fade
                            target={idFilePopover}
                            placement="left"
                            trigger="hover"
                            delay={{ show: 500, hide: 100 }}
                            noIncluded="html: true"//INFO
                        >
                            <PopoverHeader>Image</PopoverHeader>
                            <PopoverBody>
                                <img style={{ width: "480px" }} className='img-responsive' src={dlLink} alt='' />
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>);
                }
                else {
                    return "";
                }
            });


            manageFiles = (<div>
                <div className="cfiles-header">
                    Pièces Jointes :
                </div>
                <Row className="cfiles-container m-0 mt-3 fw-bold">{filesContent}</Row>
            </div>);
        }

        return this.props.checkRights("CUSTOM_PGE", "R") ? (
            <div>
                <div id="page-content" dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                {manageFiles}
            </div>
        ) : "Accès impossible";
    }
}

export default withRouter(ShowContent);