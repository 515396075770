/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { useTranslation } from "react-i18next";

import App from "App.js";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
    FormFeedback,
    CardImg
} from "reactstrap";

import Swal from "sweetalert2";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import Cookies from "js-cookie";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as commonFunc from 'scripts/common';

//Création du schéma pour le paramètrage du formulaire
const schema = yup.object().shape({
    login: yup.string().required("Ce champ est obligatoire").min(5, "Ce champ nécessite au moins 5 caractères."),
    password: yup.string().required("Ce champ est obligatoire").min(2, "Ce champ nécessite au moins 2 caractères."),
    rememberMe: yup.bool()
}).required();

export function proceedLogin(token, appProps) {
    // Récupération du token JWT depuis le localStorage
    // const token = localStorage.getItem('token');

    // Inclusion du token JWT dans les en-têtes de la requête

    //TODO : voir pour login2auth
    /*sessionStorage.setItem('token2', token);
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    navigate("../login2auth");*/


    localStorage.setItem('token', token);
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;

    // Faites votre appel API avec axios ou un autre client HTTP
    Swal.close();
    Swal.fire({
        title: "Récupération en cours...",
        didOpen: () => { Swal.showLoading() },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
    });

    return commonFunc.getMethod("../api/GetUser", null).then((response) => {
        console.debug("RESPONSE GetUser");
        console.debug(response);

        if (response.data) {
            if (response.data.isSuccess) {
                Swal.close();
                if (appProps) appProps.setLoggedStatus(true);
                return new Promise((resolve, reject) => {
                    resolve(response.data);
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la récupération de l'utilisateur :<br>" + response.data.errorMessage
                });
            }
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Erreur",
                html: "Erreur lors de la récupération de l'utilisateur"
            });
        }
    });
}

function Login(props) {
    const { t } = useTranslation();

    React.useEffect(() => {
        document.body.classList.toggle("login-page");
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };
    });

    const navigate = useNavigate();

    //Paramètrage du formulaire
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    const [pwdShown, setPwdShown] = React.useState(false);

    const [loginInfos, setLoginInfos] = useSearchParams();

    function togglePassword(e) {
        setPwdShown(!pwdShown);
    }

    function redirectExternalLogin(provider) {
        getCurrentBrowserFingerPrint().then((fingerprint) => {
            //navigate("../ExternalLogin/ExternalLogin");
            window.location.assign('../ExternalLogin/ExternalLogin?provider=' + provider + '&deviceId=' + fingerprint);
        });
    }

    async function triggerSubmit(data) {
        //e.preventDefault();

        //Utilisateur déjà connecté
        if (App.hasJWT()) {
            window.location.reload();
            return;
        }


        Swal.fire({
            title: "Connexion en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });



        getCurrentBrowserFingerPrint().then((fingerprint) => {
            // fingerprint is your unique browser id.
            // This is well tested

            // the result you receive here is the combination of Canvas fingerprint and audio fingerprint.
            console.debug(fingerprint);

            var loginObj = {};
            loginObj.Username = data.login;
            loginObj.Password = data.password;
            loginObj.RememberMe = data.rememberMe;
            loginObj.ReturnUrl = "";
            loginObj.DeviceID = "WEB" + fingerprint.toString();


            /*const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataUser)
            };
            const response = await fetch('../weatherforecast/UpdateUser', requestOptions);
            const data = await response.json();*/

            /*{
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Credentials': 'true'
                }
            }*/


            /*const headers = {
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Credentials': 'false'
                //'Authorization': `Bearer ${TOKEN}`
            };

            axios.defaults.headers = headers;
           // axios.defaults.withCredentials = false;*/

            commonFunc.postMethod("../api/login", loginObj).then((response) => {
                console.debug("RESPONSE LOGIN");
                console.debug(response);
                if (response.data.isSuccess) {
                    let result = response.data.result;

                    if (!commonFunc.isBlank(result.accessToken)) {
                        if (!result.requiresMfa) {
                            proceedLogin(result.accessToken, props);
                        } else {
                            sessionStorage.setItem('token2', token);
                            //axios.defaults.headers['Authorization'] = `Bearer ${token}`;
                            Swal.close();
                            loginObj.Password = "";
                            loginObj.Provider = result.tokenType;
                            console.debug(loginObj);

                            navigate("../login2auth", { replace: true, state: { login: loginObj } });
                        }

                    } else {
                        console.debug("LOGIN TOKEN ERR");
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur lors de l'authentification :<br>"
                        });
                    }
                } else {
                    console.debug("LOGIN ERR");
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de l'authentification :<br> " + response.data.errorMessage
                    });
                }


            });



        });
    }

    console.debug("render");


    if (loginInfos.get("isSuccess") === "true") {
        var cookieToken = Cookies.get("token");
        if (cookieToken) {
            setLoginInfos("");
            var token = JSON.parse(cookieToken).AccessToken;
            Cookies.remove("token");
            proceedLogin(token, props);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Erreur",
                html: "Erreur lors de la tentative de connexion : Informations introuvables"
            }).then((result) => {
                setLoginInfos("");
            });
        }
        return null;
    }
    else {
        if (loginInfos.get("isSuccess") === "false" && !Swal.isVisible()) {
            var errorMessage = loginInfos.get("errorMessage") || "";
            if (loginInfos.get("errorCode")) {
                errorMessage = t("login_" + errorMessage) || "";
            }
            Swal.fire({
                icon: "error",
                title: "Erreur",
                html: "Erreur lors de la tentative de connexion :<br>" + errorMessage
            }).then((result) => {
                setLoginInfos("");
            });
        }

        const { ref: refLogin, ...restLogin } = register("login");
        const { ref: refPwd, ...restPwd } = register("password");
        const { ref: refRememberMe, ...restRememberMe } = register("rememberMe");

        return (

            <div className="login-page">
                <Container>
                    <Row>
                        <Col className="ms-auto me-auto animate__animated animate__bounceInDown" lg="4" md="6">
                            <Card className="card-login text-center">
                                <Form action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerSubmit)}>
                                    <CardHeader>
                                        <CardImg
                                            className="img-fluid rounded mx-auto d-block"
                                            src={require('assets/img/bg/logooskala.png')}
                                            
                                        />
                                       <CardTitle className='header hidden' tag="h3">Connexion Oskala</CardTitle>
                                        <div className="social">
                                            <Button title="Identification via votre compte google" className="btn-icon btn-round" color="google" type="button" onClick={() => redirectExternalLogin("Google")}>
                                                <i className="fa-brands fa-google" />
                                            </Button>
                                            <Button title="Identification via votre compte microsoft" className="btn-icon btn-round " color="microsoft" type="button" onClick={() => redirectExternalLogin("Microsoft")}>
                                                <i className="fa-brands fa-microsoft" />
                                            </Button>
                                            <Button className="btn-icon btn-round hidden" color="facebook" type="button" onClick={() => redirectExternalLogin("Facebook")}>
                                                <i className="fa-brands fa-facebook-f" />
                                            </Button>
                                            <p className="card-description">OU</p>
                                        </div>

                                    </CardHeader>
                                    <CardBody>
                                        <div className="row mb-3">
                                        <InputGroup>
                                            <InputGroupText>
                                                <i className="fa fa-user" />
                                            </InputGroupText>
                                            <Input placeholder="Identifiant..." type="text"
                                                innerRef={refLogin} {...restLogin}
                                                invalid={errors.login ? true : false} />
                                            <FormFeedback className="text-start">{errors.login ? errors.login.message : "Ce champ est obligatoire"}</FormFeedback>
                                        </InputGroup>
                                        </div>
                                        <div className="row">
                                        <InputGroup>
                                            <InputGroupText>
                                                <i className="fa fa-lock" />
                                            </InputGroupText>
                                            <Input
                                                placeholder="Mot de passe"
                                                type={pwdShown ? "text" : "password"}
                                                autoComplete="no" aria-autocomplete="none"
                                                innerRef={refPwd} {...restPwd}
                                                invalid={errors.password ? true : false}
                                            />
                                                <span className="input-group-text bg-transparent m-0 " color="dark" onClick={togglePassword}>
                                                <i className={pwdShown ? "fa fa-eye-slash" : "fa fa-eye"} />
                                                </span>
                                            <FormFeedback className="text-start">{errors.password ? errors.password.message : "Ce champ est obligatoire"}</FormFeedback>
                                            </InputGroup>
                                        </div>
                                        <div className="text-end">
                                            <Link className="link-secondary" to="/pwdReset">
                                                Mot de passe oublié ?
                                            </Link>
                                        </div>
                                        <FormGroup check className='text-start fcheck-sm'>
                                            <Label check >
                                                <Input defaultValue="" type="checkbox"
                                                    innerRef={refRememberMe} {...restRememberMe} />
                                                <span className="form-check-sign" />
                                                Se souvenir de moi
                                            </Label>
                                        </FormGroup>

                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            block
                                            className="btn-round mb-3 bg-altLink-color"
                                            color="primary"
                                        >
                                            Se Connecter
                                        </Button>
                                        <Link className="link-secondary" /*to="/sign-up"*/ onClick={() => { window.location.href = "https://www.oskala.com/Prices"; }}>Pas de compte ? Créer un compte !</Link>
                                    </CardFooter>
                                </Form>
                            </Card>

                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/backblurt1.jpg")})`,
                    }}
                />
            </div >

        );
    }
}

export default Login;
