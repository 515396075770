import React, { useState } from 'react';

/*import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    LineController, DoughnutController, PieController
} from "chart.js";*/
import 'chart.js/auto';
import { Chart } from "react-chartjs-2";

import * as commonFunc from 'scripts/common';

//Déclaration pour optimisation taille bundle >> sinon utiliser chart.js/auto
//ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, LineController, DoughnutController, PieController);

const CChart = React.forwardRef((props, ref) => {
    const [chartData, setChartData] = useState(null);

    React.useEffect(() => {
        const chartElement = ref ? ref.current : null;
        if (chartElement && props.type === "line" && props.subtype === "gradient") {
            var componentProps = props.data;
            componentProps.datasets = componentProps.datasets ? componentProps.datasets.map((dataset) => ({
                ...dataset,
                tension: 0.4,//arrondi lignes
                pointRadius: 0,
                borderWidth: 4,
                borderColor: dataset.color,
                fill: true,
                maxBarThickness: 6,
                backgroundColor: gradientChartLine(
                    chartElement.ctx,
                    dataset.color
                ),
            })) : [];

            setChartData(componentProps);
        }
    }, [props.data]);

    return <div className={props.className} style={{ width: props.width, height: props.height, ...props.style }}>
        <Chart ref={ref} {...props} data={chartData || props.data} />
    </div>;
});

// Setting default values for the props of CChart
CChart.defaultProps = {
    width: "150px",
    height: "150px",
};

export default CChart;



function gradientChartLine(chart, color, opacity = 0.2) {
    const gradientStroke = chart.createLinearGradient(0, 230, 0, 50);
    const primaryColor = `rgba(${commonFunc.hexToRgb(color)}, ${opacity})`;

    gradientStroke.addColorStop(1, primaryColor);
    gradientStroke.addColorStop(0.2, "rgba(72, 72, 176, 0.0)");
    gradientStroke.addColorStop(0, "rgba(203, 12, 159, 0)");

    return gradientStroke;
}