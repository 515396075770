import React from 'react';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import * as commonFunc from 'scripts/common';

import {
    Input, Button,
    Label,
    FormGroup, FormFeedback,
    InputGroup, InputGroupText, Col
} from "reactstrap";


export default function ButtonFrag({ data, formManager, modelFctManager, bindPropResult, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, setValue, resetField, errors } = formManager;


    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }, []);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };

    const { ref: refElem, ...restElem } = register(bindPropResult);

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));

    var btnClass = "m-1";
    var btnSupClass = "";
    var btnStyle = {};
    var frameClass = "";
    var frameStyle = {};
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }
    if (commonFunc.isBlank(data.title) && (data.children == null || data.children.length <= 1)) btnSupClass += " btn-icon ";

    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;
            var altDesign = modelFctManager.rootData?.customDesign?.extrasDesign?.default_button;
            if (altDesign) design = Object.assign({}, altDesign, design);

            if (design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(design.boxSize) * 100);
                btnClass += modelFctManager.convertWidthToGrid(parseFloat(design.boxSize) * 100);
            }

            if (design.height) btnStyle.height = design.height;
            if (design.width) btnStyle.width = design.width;

            if (design.margin) {
                btnStyle.margin = design.margin;
                btnClass = btnClass.replace("m-1", "");
            }
            if (design.marginTop) {
                if (design.marginTop.indexOf("px") >= 0) {
                    btnStyle.marginTop = design.marginTop;
                }
                else {
                    btnSupClass += design.marginTop;
                }
            }

            if (design.fontColor) btnStyle.color = modelFctManager.getRgbaFromHex(design.fontColor);
            if (design.backColor) btnStyle.backgroundColor = modelFctManager.getRgbaFromHex(design.backColor);
            if (design.borderColor || design.borderSize) {
                if (design.borderColor) btnStyle.borderColor = modelFctManager.getRgbaFromHex(design.borderColor);

                if (design.borderSize) btnStyle.borderWidth = design.borderSize;
                else btnStyle.borderWidth = "1px";

                btnStyle.borderStyle = "solid";
            }
            if (design.borderRadius) btnStyle.borderRadius = design.borderRadius;
        }
    }

    var onClick = (e) => {
        e.preventDefault();
        if (!disabled) {
            switch (data.subType) {
                case "cloneGroup":
                    if (data.src) {
                        modelFctManager.onActionRequired(data.name, { trigger: "findFragment", target: data.src } , data).then(actionResponse => {
                            console.debug("RESPONSE");
                            console.debug(actionResponse);
                            if (actionResponse) {
                                if (!actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: actionResponse.errorMessage
                                    });
                                }
                                else if (actionResponse.result) {
                                    var target = actionResponse.result;
                                    var header = target.functionManager.getParent();

                                    var cloneElem = JSON.parse(JSON.stringify(target));
                                    cloneElem.key = null;
                                    cloneElem.isModel = false;
                                    cloneElem.isExcluded = false;
                                    cloneElem.clonable = false;
                                    cloneElem.hidden = false;
                                    cloneElem.temporary = true;//echo à isCloned mais défini si l'élément doit être supprimé au chargement

                                    header.groups.push(cloneElem);//TODO : voir pour ajotuer les éléments juste après le modele
                                    header.functionManager.rerender();
                                }
                            }
                        });
                    }
                    else {
                        var target = parentProps.parentData.functionManager.getParent();
                        index = target.groups.indexOf(parentProps.parentData);
                        if (index >= 0) {
                            target.groups.splice(index, 1);
                        }
                        target.functionManager.rerender();
                    }
                    break;
                case "removeItem":
                    //parentProps.childs = parentProps.childs.filter((elem) => elem !== data);
                    var index = parentProps.childs.indexOf(data);
                    if (index >= 0) {
                        parentProps.childs.splice(index, 1);
                    }
                    if (data.src) {
                        //TODO : supprimer la cible
                    }
                    break;
                case "removeGroup":
                    //parentProps.childs = parentProps.childs.filter((elem) => elem !== data);
                    var index = parentProps.childs.indexOf(data);
                    if (index >= 0) {
                        parentProps.childs.splice(index, 1);
                    }
                    if (data.src) {
                        //TODO : supprimer la cible
                    }
                    else {
                        resetField(commonFunc.camelize(parentProps.parentData.bindPropResult, true) + "[" + parentProps.parentData.key + "]");

                        var target = parentProps.parentData.functionManager.getParent();
                        index = target.groups.indexOf(parentProps.parentData);
                        if (index >= 0) {
                            target.groups.splice(index, 1);
                        }
                        target.functionManager.rerender();
                    }
                    break;
                default:
                    break;
            }

            if (data.onActionEvents && data.onActionEvents["onClick"]) {
                data.onActionEvents["onClick"].forEach((args, index) => {
                    //args.value / args.target
                    modelFctManager.onActionRequired(data.name, args, data, data.argumentsObj).then(actionResponse => {
                        console.debug("RESPONSE");
                        console.debug(actionResponse);
                        if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: actionResponse.errorMessage
                            });
                        }
                    });
                });
            }
        }
    };

    var baseForm;
    if (data.groupSubName === "header") {//TODO : check si conservation OU alternative
        baseForm = <a href="/" className={`float-start me-2 widget-action-icon ${btnSupClass} ${data.classSup}`} style={btnStyle} onClick={onClick}>{modelFctManager.componentTyped(data.children[0], parentProps)}</a>;
    }
    else {
        baseForm = <Button color="none" className={`btn-sm ${btnClass} ${btnSupClass} ${data.classSup}`} style={btnStyle} disabled={disabled} onClick={onClick}>{data.children ? modelFctManager.componentTyped(data.children[0], parentProps) : ""} {altT(data.title)}</Button>;
    }

    return <Col data-name="ButtonFrag" data-element={data.objType} xs="auto" className={` ${frameClass} ${parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`} style={frameStyle}>{baseForm}</Col>;//align-self-center
}