import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    FormFeedback,
    Container,
    Col,
    Row,
    Progress,
} from "reactstrap";

import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as commonFunc from 'scripts/common';
import { proceedLogin } from 'views/pages/login/Login';

//Création du schéma pour le paramètrage du formulaire
const schema = yup.object().shape({
    password: yup.string().required("Ce champ est obligatoire").min(6, "Ce champ nécessite au moins 6 caractères."),
    passwordConf: yup.string().required("Ce champ est obligatoire").min(6, "Ce champ nécessite au moins 6 caractères.").oneOf([yup.ref("password")], "Les 2 champs ne correspondent pas."),
}).required();

//pwdChange?a=ResetAccountPassword

function PwdChange(props) {
    const { t } = useTranslation();

    React.useEffect(() => {
        document.body.classList.toggle("login-page");
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };
    });

    const navigate = useNavigate();

    const recaptchaRef = React.useRef();

    //Paramètrage du formulaire
    const { register, getValues, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    //Configuration pour récupération des valeurs des boutons d'affichage des mots de passes
    const [pwdShown, setPwdShown] = React.useState(false);
    const [pwdShownConf, setPwdShownConf] = React.useState(false);
    const [triggerUpdate, setTriggerUpdate] = React.useState(false);

    //Fonctions d'affichage des mots de passes
    function togglePassword(e) {
        setPwdShown(!pwdShown);
    }
    function togglePasswordConf(e) {
        setPwdShownConf(!pwdShownConf);
    }

    function passwordStrength(password) {
        var desc = {};


        /*desc[0] = { desc: "Très Faible", classInfo: 'danger', value: 16 };
        desc[1] = { desc: "Faible", classInfo: 'danger', value: 32 };
        desc[2] = { desc: "Médiocre", classInfo: 'warning', value: 48 };
        desc[3] = { desc: "Moyen", classInfo: 'warning', value: 64 };
        desc[4] = { desc: "Fort", classInfo: 'success', value: 80 };
        desc[5] = { desc: "Très Fort", classInfo: 'success', value: 100 };*/

        desc[0] = { desc: "Faible", classInfo: 'danger', value: 16 };
        desc[1] = { desc: "Faible", classInfo: 'danger', value: 32 };
        desc[2] = { desc: "Faible", classInfo: 'danger', value: 48 };
        desc[3] = { desc: "Faible", classInfo: 'danger', value: 64 };
        desc[4] = { desc: "Moyen", classInfo: 'warning', value: 72 };
        desc[5] = { desc: "Correct", classInfo: 'success', value: 80 };
        desc[6] = { desc: "Fort", classInfo: 'success', value: 100 };

        var score = 0;

        if (!password) {
            return { infos: desc[score], score: score };
        }

        //if password bigger than 6 give 1 point
        if (password.length >= 6) score++;

        //if password has both lower and uppercase characters give 1 point
        if (password.match(/[a-z]/)) score++;
        if (password.match(/[A-Z]/)) score++;

        //if password has at least one number give 1 point
        if (password.match(/\d+/)) score++;

        //if password has at least one special caracther give 1 point
        if (password.match(/.[!,@@,#,$,%,^,&,*,?,_,~,-,(,)]/)) score++;

        //if password bigger than 10 give another 1 point
        if (password.length >= 10 && score > 4) score++;


        return desc[score];
    }

    async function triggerSubmit(data) {
        //e.preventDefault();

        var infosPwd = passwordStrength(getValues("password"));
        if (infosPwd.value >= 80) {
            //data.pwdScore = infosPwd.value;

            var email = pwdChangeInfos.get("uemail");

            var req = { email: email, userPwd: data.password };
            if (pwdChangeInfos && pwdChangeInfos.size > 0) {
                pwdChangeInfos.keys().forEach((e, i) => {
                    var value = pwdChangeInfos.get(e);
                    req[e] = value;
                });
            }
            req.recaptcha = await recaptchaRef.current.executeAsync();

            Swal.fire({
                title: "Changement en cours...",
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            getCurrentBrowserFingerPrint().then((fingerprint) => {
                req.deviceId = fingerprint;

                commonFunc.postMethod("../api/ChangePasswordFromResetJob", req).then((response) => {
                    if (response.data && response.data.isSuccess) {
                        Swal.fire({
                            icon: "success",
                            title: "Confirmation",
                            html: "Votre mot de passe a été changé avec succès.",
                            didOpen: () => { Swal.hideLoading() },
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        }).then((result) => {
                            //navigate("../login");
                            var data = response.data;
                            if (data.result && data.result.accessToken) {
                                Swal.close();
                                proceedLogin(data.result.accessToken/*, props.parentContext.props*/).then((data) => {
                                    window.location.reload();
                                });
                            }
                            else {
                                window.location.reload();
                            }
                        });
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur lors de l'authentification :<br>" + response.data.errorMessage
                        }).then(result => {
                            window.location.reload();
                        });
                    }

                });
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: t("gal_warning"),
                html: t("register_errorPwdStrength"),
            });
        }
    }

    const [pwdChangeInfos] = useSearchParams();

    //Vérification récupération des paramètres
    if (pwdChangeInfos.get("a") !== "ResetAccountPassword" || commonFunc.isBlank(pwdChangeInfos.get("uemail"))) {
        Swal.fire({
            icon: "error",
            title: "Erreur",
            html: "Paramètres non défini",
            didOpen: () => { Swal.hideLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then((result) => {
            navigate("../login");
        });
        return null;
    } else {
        //Déclaration des paramètres et référence pour gérer une compatibilitée avec reactstrap, autrement voici un exemple : <input {...register("password")} />
        const { ref: refPwd, ...restPwd } = register("password", {
            //Mise en place pour refresh en live le passwordStrength
            onChange: (e) => { setTriggerUpdate(!triggerUpdate) },
        });
        const { ref: refPwdConf, ...restPwdConf } = register("passwordConf");

        //Récupération des infos de validité du mot de passe
        var infosPwd = passwordStrength(getValues("password"));

        var email = pwdChangeInfos.get("uemail");

        return (
            <div className="login-page">
                <Container>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LfJ5KcpAAAAAFwA7DM-smKf4sfJmq6FHDF-Zt9q"
                    />
                    <Row>
                        <Col className="ms-auto me-auto" lg="4" md="6">
                            <Form action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerSubmit)}>
                                <Card className="card-login text-center">
                                    <CardHeader>
                                        <CardTitle className='header' tag="h3">Changement mot de passe</CardTitle>
                                        <div>
                                            Saisissez votre nouveau mot de passe pour votre compte Oskala
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <InputGroup className="mb-2">
                                            <InputGroupText>
                                                <i className="fa fa-envelope" />
                                            </InputGroupText>
                                            <Input placeholder="Email..." type="email"
                                                autoComplete="no" aria-autocomplete="none" disabled="disabled" defaultValue={email} />
                                        </InputGroup>
                                        <Progress className="w-25 float-end" color={infosPwd.classInfo} value={infosPwd.value} style={{ height: '15px' }}><span className="position-absolute ms-4 fw-bold">{infosPwd.desc}</span></Progress>
                                        <InputGroup>
                                            <InputGroupText>
                                                <i className="fa fa-lock" />
                                            </InputGroupText>
                                            <Input
                                                placeholder="Entrer le nouveau Mot de passe"
                                                type={pwdShown ? "text" : "password"}
                                                autoComplete="no" aria-autocomplete="none"
                                                innerRef={refPwd} {...restPwd}
                                                invalid={errors.password ? true : false}
                                            />
                                            <span className="input-group-text bg-transparent m-0 " color="dark" onClick={togglePassword}>
                                                <i className={pwdShown ? "fa fa-eye-slash" : "fa fa-eye"} />
                                            </span>
                                            <FormFeedback className="text-start">{errors.password ? errors.password.message : "Ce champ est obligatoire"}</FormFeedback>
                                        </InputGroup>
                                        <InputGroup className="mt-1">
                                            <InputGroupText>
                                                <i className="fa fa-lock" />
                                            </InputGroupText>
                                            <Input
                                                placeholder="Confirmer le Mot de passe"
                                                type={pwdShownConf ? "text" : "password"}
                                                autoComplete="no" aria-autocomplete="none"
                                                innerRef={refPwdConf} {...restPwdConf}
                                                invalid={errors.passwordConf ? true : false}
                                            />
                                            <span className="input-group-text bg-transparent m-0 " color="dark" onClick={togglePasswordConf}>
                                                <i className={pwdShownConf ? "fa fa-eye-slash" : "fa fa-eye"} />
                                            </span>
                                            <FormFeedback className="text-start">{errors.passwordConf ? errors.passwordConf.message : "Ce champ est obligatoire"}</FormFeedback>
                                        </InputGroup>

                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            block
                                            className="btn-round mb-3"
                                            color="warning"
                                        >
                                            Continuer
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>

                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/backblurt1.jpg")})`,
                    }}
                />
            </div >

        );
    }
}

export default PwdChange;
