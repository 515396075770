import { useSearchParams } from 'react-router-dom';

export const withRouter = Child => props => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <Child
            {...props}
            {...{ searchParams, setSearchParams }}
        />
    );
};