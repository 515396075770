import React from 'react';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import * as commonFunc from 'scripts/common';

import {
    Row, Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
} from "reactstrap";

export default function HeaderFrag({ data, modelFctManager, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        if(!data.isLoaded) data.groups = data.groups.filter((group) => !group.temporary);
    }, [data]);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.getParent = () => {
        return parentProps.parentData;
    };

    var label = data.title.startsWith("i18n:") ? t(data.title.replace("i18n:", "")) : data.title;

    var childs = "";
    var icons = [];
    if (data.groups) {
        childs = data.groups.map((child) => {
            if (child.renderLink === "header" || child.groupSubName === "header") {
                icons.push(modelFctManager.componentTyped(child, { parentData: data, childs: data.groups, parentDisabled: (parentDisabled || data.disabled ? true : false) }));
                return "";
            }
            else {
                return modelFctManager.componentTyped(child, { parentData: data, childs: data.groups, parentDisabled: (parentDisabled || data.disabled ? true : false) });
            }
        });
    }

    var frameClass = "";
    var frameStyle = {};
    var frameHiddenAlt = false;//TEMP
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;

            if (design.backColor) frameStyle.background = modelFctManager.getRgbaFromHex(design.backColor);
            //TEMP
            if (design.backColor === "transparent") {
                frameStyle.background = "transparent";
                frameStyle.border = "0";
                frameStyle.boxShadow = "none";
                if (commonFunc.isBlank(data.title)) {
                    frameHiddenAlt = true;
                }
            }
            if (design.borderColor || design.borderSize) {
                if (design.borderColor) frameStyle.borderColor = modelFctManager.getRgbaFromHex(design.borderColor);

                if (design.borderSize) frameStyle.borderWidth = design.borderSize;
                else frameStyle.borderWidth = "1px";

                frameStyle.borderStyle = "solid";
            }
            if (design.borderRadius) frameStyle.borderRadius = design.borderRadius;

            if (design.margin) {
                frameClass += " p-0";
                frameStyle.margin = design.margin;
            }
            if (design.marginStart) {
                frameClass += " ps-0";
                frameStyle.marginLeft = design.marginStart;
            }
            if (design.marginTop) frameStyle.marginTop = design.marginTop;
            if (design.marginEnd) {
                frameClass += " pe-0";
                frameStyle.marginRight = design.marginEnd;
            }
            if (design.marginBottom) frameStyle.marginBottom = design.marginBottom;
        }
    }

    data.isLoaded = true;

    return (<Card data-name="HeaderFrag" data-element={data.objType} className={`widget dash ${frameClass}`} style={frameStyle}>
        <CardHeader className="pt-0">
            {!commonFunc.isBlank(label) ? <CardTitle tag="h6" className="mt-3">{label}</CardTitle> : ""}
            <div className="widget-tools collapse-tool">
                {icons.map((i) => i)}
            </div>
            {!frameHiddenAlt ? <div className='borderTheme'></div> : ""}
        </CardHeader>
        <CardBody>
            <Row className={`m-0`}>{childs}</Row>
        </CardBody>
    </Card>);
}