import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
    Button,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    FormFeedback,
    Progress
} from "reactstrap";

import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as commonFunc from 'scripts/common';

function RegisterStepPwd(props) {
    const { t } = useTranslation();

    //Création du schéma pour le paramètrage du formulaire
    const schema = yup.object().shape({
        pwd: yup.string().required(t("gal_reqFields")),
        pwdConfirm: yup.string().required(t("gal_reqFields")).oneOf([yup.ref('pwd')], t("gal_reqSameFields")),
        //Score?
    }).required();

    React.useEffect(() => {
        if (props.triggerAnimate) props.triggerAnimate();
    }, [props.shouldRender]);

    //Paramètrage du formulaire
    const { register, getValues, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    //Configuration pour récupération des valeurs des boutons d'affichage des mots de passes
    const [pwdShown, setPwdShown] = React.useState(false);
    const [pwdShownConf, setPwdShownConf] = React.useState(false);
    const [triggerUpdate, setTriggerUpdate] = React.useState(false);

    //Fonctions d'affichage des mots de passes
    function togglePassword(e) {
        setPwdShown(!pwdShown);
    }
    function togglePasswordConf(e) {
        setPwdShownConf(!pwdShownConf);
    }

    function passwordStrength(password) {
        var desc = {};


        /*desc[0] = { desc: "Très Faible", classInfo: 'danger', value: 16 };
        desc[1] = { desc: "Faible", classInfo: 'danger', value: 32 };
        desc[2] = { desc: "Médiocre", classInfo: 'warning', value: 48 };
        desc[3] = { desc: "Moyen", classInfo: 'warning', value: 64 };
        desc[4] = { desc: "Fort", classInfo: 'success', value: 80 };
        desc[5] = { desc: "Très Fort", classInfo: 'success', value: 100 };*/

        desc[0] = { desc: "Faible", classInfo: 'danger', value: 16 };
        desc[1] = { desc: "Faible", classInfo: 'danger', value: 32 };
        desc[2] = { desc: "Faible", classInfo: 'danger', value: 48 };
        desc[3] = { desc: "Faible", classInfo: 'danger', value: 64 };
        desc[4] = { desc: "Moyen", classInfo: 'warning', value: 72 };
        desc[5] = { desc: "Correct", classInfo: 'success', value: 80 };
        desc[6] = { desc: "Fort", classInfo: 'success', value: 100 };

        var score = 0;

        if (!password) {
            return { infos: desc[score], score: score };
        }

        //if password bigger than 6 give 1 point
        if (password.length >= 6) score++;

        //if password has both lower and uppercase characters give 1 point
        if (password.match(/[a-z]/)) score++;
        if (password.match(/[A-Z]/)) score++;

        //if password has at least one number give 1 point
        if (password.match(/\d+/)) score++;

        //if password has at least one special character give 1 point
        if (password.match(/.[!,@@,#,$,%,^,&,*,?,_,~,-,(,)]/)) score++;

        //if password bigger than 10 give another 1 point
        if (password.length >= 10 && score > 4) score++;


        return desc[score];
    }

    async function triggerSubmit(data) {

        var infosPwd = passwordStrength(getValues("pwd"));
        if (infosPwd.value >= 80) {
            data.pwdScore = infosPwd.value;
            if (props.updateStep) props.updateStep(data);
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: t("gal_warning"),
                html: t("register_errorPwdStrength"),
            });
        }
    }

    //Déclaration des paramètres et référence pour gérer une compatibilitée avec reactstrap, autrement voici un exemple : <input {...register("password")} />
    const { ref: refPwd, ...restPwd } = register("pwd", {
        //Mise en place pour refresh en live le passwordStrength
        onChange: (e) => { setTriggerUpdate(!triggerUpdate) },
    });
    const { ref: refPwdConf, ...restPwdConf } = register("pwdConfirm");

    //Récupération des infos de validité du mot de passe
    var infosPwd = passwordStrength(getValues("pwd"));

    return (
        <Form action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerSubmit)}>
            <CardHeader className="bg-white">
                <CardTitle className='header my-0 py-0' tag="h5">{t("register_loginInfos")}</CardTitle>
            </CardHeader>
            <CardBody>
                <Progress className="w-25 float-end" color={infosPwd.classInfo} value={infosPwd.value} style={{ height: '15px' }}><span className="position-absolute ms-4 fw-bold">{infosPwd.desc}</span></Progress>
                <InputGroup className="single-input pb-4">
                    <InputGroupText className="mandatory">
                        <i className="fa fa-lock" />
                    </InputGroupText>
                    <div className="form-floating min-input">
                        <Input id="inputpwd" className="no-arrow" type={pwdShown ? "text" : "password"} autoComplete="no" aria-autocomplete="none" placeholder={t("register_pwd")} innerRef={refPwd} {...restPwd} invalid={errors.pwd ? true : false} />
                        <Label for="inputpwd">{t("register_pwd")}</Label>
                    </div>
                    <span className="input-group-text bg-transparent m-0 " color="dark" onClick={togglePassword}>
                        <i className={pwdShown ? "fa fa-eye-slash" : "fa fa-eye"} />
                    </span>
                    <FormFeedback className={`text-start ${errors.pwd ? "d-block" : ""}`}>{errors.pwd ? errors.pwd.message : t("gal_reqFields")}</FormFeedback>
                </InputGroup>
                <InputGroup className="single-input pb-4">
                    <InputGroupText className="mandatory">
                        <i className="fa fa-lock" />
                    </InputGroupText>
                    <div className="form-floating min-input">
                        <Input id="inputpwdConfirm" type={pwdShownConf ? "text" : "password"} autoComplete="no" aria-autocomplete="none" placeholder={t("register_confPwd")} innerRef={refPwdConf} {...restPwdConf} invalid={errors.pwdConfirm ? true : false} />
                        <Label for="inputpwdConfirm">{t("register_confPwd")}</Label>
                    </div>
                    <span className="input-group-text bg-transparent m-0 " color="dark" onClick={togglePasswordConf}>
                        <i className={pwdShownConf ? "fa fa-eye-slash" : "fa fa-eye"} />
                    </span>
                    <FormFeedback className={`text-start ${errors.pwdConfirm ? "d-block" : ""}`}>{errors.pwdConfirm ? errors.pwdConfirm.message : t("gal_reqFields")}</FormFeedback>
                </InputGroup>
            </CardBody>
            <CardFooter className="text-end mt-0">
                {props.prevStep ? <Button title={t("register_previous")} className="btn-icon rounded-circle bg-altLink-color btn btn-primary me-2" color="info" onClick={(e) => { e.preventDefault(); props.prevStep() }}><i className="fa fa-arrow-left" style={{ fontSize: "1.6em" }}></i></Button> : ""}
                <Button title={t("register_next")} className="btn-icon rounded-circle bg-altLink-color btn btn-primary" color="info"><i className="fa fa-arrow-right" style={{ fontSize: "1.6em" }}></i></Button>
            </CardFooter>
        </Form>
    );
}

export default RegisterStepPwd;
