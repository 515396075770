/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {
    return (
        <footer className={"footer py-1" + (props.default ? " footer-default" : "")} style={{ position: "absolute", right:"0", left:"0" }}>
            <Container fluid={props.fluid ? true : false}>
                <Row>
                    <nav className="footer-nav col-auto">
                        <ul>
                            <li>
                                <a href="https://www.softena.com/" target="_blank">Softena</a>
                            </li>
                            <li>
                                <a href="https://www.softena.com/#contactform" target="_blank">Contacts</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="credits ms-auto col-auto">
                        <span className="copyright">
                            Copyright &copy; {1900 + new Date().getYear()} Softena
                        </span>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
