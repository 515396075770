import React from 'react';
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Img from 'components/Img';
import * as commonFunc from 'scripts/common';

import {
    Col,
    UncontrolledPopover, PopoverHeader, PopoverBody
} from "reactstrap";


export default function PictureFrag({ data, formManager, modelFctManager, bindPropResult, parentProps, parentDisabled, srcFileManager }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors } = formManager;

    const [pictureFragId, setPictureFragId] = React.useState("file-" + Math.floor(Math.random() * 1000000000));
    const [shouldRender, setShouldRender] = React.useState(false);
    const [file, setFile] = React.useState();
    const [preview, setPreview] = React.useState(data.defaultValue || "");

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    if (!data.argumentsObj) {
        console.debug(data.arguments);
        if (!commonFunc.isBlank(data.arguments)) data.argumentsObj = JSON.parse(data.arguments);
        else data.arguments = "";
    }

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.setValue = (value, withRerender, src) => {
        return new Promise((resolve, reject) => {
            setValue(bindPropResult, value);
            //setValue(bindPropResult + "Object", value);
            setFile(value);
            setPreview("");
            if (withRerender) setShouldRender(!shouldRender);

            resolve({ isSuccess: true, result: value });
        });
    };
    data.functionManager.onSubmit = (dataResult, idResult) => {
        var asyncJobs = [];
        var configSubmit = data.onModelSubmited;
        configSubmit.forEach(async (eventSubmit, index) => {
            switch (eventSubmit.trigger) {
                case "upload":
                    uploadFile(null, dataResult, idResult, asyncJobs, index);

                    break;
                default:
                    break;
            }
        });

        if (asyncJobs.length) {
            Promise.all(asyncJobs).then(results => {
                console.debug(results);

                file.progress = 0;
                file.loaded = 0;
                file.total = 0;
                setFile(file);

                if (data.onActionEvents && data.onActionEvents["onSubmit"]) {
                    data.onActionEvents["onSubmit"].forEach((args, index) => {
                        //args.value / args.target
                        modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                            console.debug("RESPONSE");
                            console.debug(actionResponse);
                            if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: actionResponse.errorMessage
                                });
                            }
                        });
                    });
                }

                return;

            }, rej => {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur de l'exécution des évenements :<br>" + rej.message
                });
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur de l'exécution des évenements :<br>" + error.message
                });
            });
        }
    };

    async function uploadFile(curFile, dataResult, idResult, asyncJobs, index) {
        if (!dataResult) dataResult = getValues();

        var localFile = curFile ? curFile : file;
        if (localFile && !localFile.closed) {
            const formData = new FormData();

            var fileUpReq = {
                name: localFile.name,
                ftype: localFile.docType || data.argumentsObj.ftype || "AVA",
                bindType: data.argumentsObj.bindType || "",
                visibility: 1,
                language: "FR",
                //fbindId: props.fileTypeID, //Type
                bindId: commonFunc.getValue(dataResult, commonFunc.camelize(data.argumentsObj.target, true)) || idResult || -1,
                //bindSubId
                fileTypeID: localFile.docTypeId || data.argumentsObj.fileTypeID || 1,
                lastModified: localFile.lastModifiedDate,
                saveProperty: data.argumentsObj.saveProperty || data.bindPropResult,
                savePropertyAsUrl: data.argumentsObj.savePropertyAsUrl || false
            };

            formData.append('file', localFile);
            formData.append('args', JSON.stringify(fileUpReq));


            const onUploadProgress = (event) => {
                console.debug(event);

                localFile.loaded = event.loaded;
                localFile.total = event.total;
                localFile.progress = Math.round(100 * event.progress);
                setFile(localFile);
            };
            if (asyncJobs) {
                asyncJobs[index] = await axios.post(data.argumentsObj.url || "../api/data/UploadFiles", formData, { headers: { "Content-Type": "multipart/form-data", }, onUploadProgress }).then((response => {
                    localFile.closed = true;
                    if (response.data.isSuccess) {
                        localFile.validated = true;
                    }
                    else {
                        localFile.error = response.data.errorMessage;
                    }
                    setFile(file);
                    return new Promise(resolve => {
                        resolve(response);
                    });
                })).catch(error => {
                    return new Promise((resolve) => {
                        resolve({ isSuccess: false, errorMessage: error });
                    });
                });
            }
            else {
                await axios.post(data.argumentsObj.url || "../api/data/UploadFiles", formData, { headers: { "Content-Type": "multipart/form-data", }, onUploadProgress }).then((response => {
                    localFile.closed = true;
                    if (response.data.isSuccess) {
                        localFile.validated = true;
                    }
                    else {
                        localFile.error = response.data.errorMessage;
                    }
                    setFile(localFile);
                    return new Promise(resolve => {
                        resolve(response);
                    });
                })).catch(error => {
                    return new Promise((resolve) => {
                        resolve({ isSuccess: false, errorMessage: error });
                    });
                });
            }
        }
    }

    const fileInput = React.useRef();
    const { ref: refElem, ...restElem } = register(bindPropResult, { value: data.defaultValue || "" });

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));

    var frameClass = "mb-3";
    var iconStyle = {};
    var pictureStyle = {};
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }


    var id = 0;//TODO
    var fileType = data.argumentsObj.ftype || "AVA";
    var dt = file || data.value /*|| data.defaultValue*/ || {//TEMP : voir si je reçois un string ou un objet
        idFile: 0,
        name: preview || "NONE",
        prefix: "NONE",
        length: 0,
        dSysMod: "NONE",
        categ: "NONE"
    };//Objet Fichier

    var onClick = (e) => {
        e.preventDefault();

        if (!disabled) {
            fileInput.current.click();//Trigger click sur input type file

            var fileElem = document.querySelector("." + srcFileManager.className + " .file-box[data-fileid='" + dt.idFile + "'] .file");
            if (srcFileManager.hideAllMenu) srcFileManager.hideAllMenu();

            if (srcFileManager.clickCallback && typeof (srcFileManager.clickCallback) === "function") { srcFileManager.clickCallback(dt, fileElem); }


            if (data.onActionEvents && data.onActionEvents["onClick"]) {
                data.onActionEvents["onClick"].forEach((args, index) => {
                    //args.value / args.target
                    modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                        console.debug("RESPONSE");
                        console.debug(actionResponse);
                        if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: actionResponse.errorMessage
                            });
                        }
                    });
                });
            }
        }
    };

    var onChange = (e) => {
        console.debug("go change");
        e.preventDefault();
        let reader = new FileReader();
        let curFile = e.target.files[0];
        reader.onloadend = () => {
            const binaryStr = reader.result
            console.log(binaryStr);

            //TODO : limitation de taille de fichier
        };
        reader.onloadend = () => {
            //setFileState(file);
            //setImagePreviewUrl(reader.result);
        };
        if (curFile) {
            reader.readAsDataURL(curFile);
            curFile = Object.assign(curFile, {
                preview: URL.createObjectURL(curFile),
                docType: curFile.docType || fileType,
                docTypeId: curFile.docTypeId || data.argumentsObj.fileTypeID,
                dSysMod: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                length: curFile.size,
            });
            //setValue(bindPropResult, "avatar");
            //setValue(bindPropResult + "Object", file);
            setFile(curFile);
            setPreview(curFile.preview);

            if (modelFctManager && modelFctManager.extFileCb) modelFctManager.extFileCb(curFile);
            else if (data.argumentsObj && data.argumentsObj.cUploadInstant) uploadFile(curFile);
            else setValue("dSysMod", new Date());
        }
    };

    var detailedVisibility = (data.subType && data.subType.indexOf("detailed") >= 0);

    var filePath = preview;
    var filePathDirectDl = filePath ? (preview + "&newWindow=true") : "";
    var typeIcon = commonFunc.getFilesFaIcon(dt.name, dt.categ);
    var fileIcon = (detailedVisibility && dt.name !== "NONE") ? typeIcon : <i className="fa fa-camera w-100" style={{ fontSize: "50px" }} data-typef="NONE"></i>;
    var isPicture = data.argumentsObj.ftype === "AVA" || typeIcon.props["data-typef"] === "img";
    /*if (dt.idFile) {//TODO
        const file = {
            fileId: dt.idFile,
            fileName: dt.name,
            container: dt.rootContainer,
            etag: dt.etag,
            accessToken: dt.absoluteUri,
            newWindow: false
        };
        filePath = "../api/data/DownloadFileByUri?" + commonFunc.toParams(file);

        file.newWindow = true;
        filePathDirectDl = "../api/data/DownloadFileByUri?" + commonFunc.toParams(file);
    }*/
    if (!detailedVisibility) {
        pictureStyle.width = "148px";
        pictureStyle.margin = "0px";
        iconStyle.height = "147px";
        if (!isPicture) {
            iconStyle.display = "flex";
            iconStyle.alignItems = "center";
        }
    }
    else {

    }


    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;
            if (design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(design.boxSize) * 100);
            }

            if (design.alignment) pictureStyle.float = design.alignment;

            if (design.width) pictureStyle.width = design.width;
            if (design.height) iconStyle.height = design.height;
            if (design.margin) pictureStyle.margin = design.margin;
            if (design.backColor) pictureStyle.backgroundColor = modelFctManager.getRgbaFromHex(design.backColor);
            if (design.borderRadius) pictureStyle.borderRadius = design.borderRadius;
        }
    }
    //console.debug(isPicture);
    //console.debug(filePath);
    var baseForm = <div id={pictureFragId} className="file-box file-avatar medium animate__animated pulse" data-fileid={dt.idFile} data-bindid={id} data-filetypeid={(data.argumentsObj.fileTypeID || "0")} data-typef={fileIcon.props["data-typef"]} data-typec={dt.categ}
        onClick={onClick}
        onDoubleClick={e => {
            e.preventDefault();

            if (!commonFunc.isBlank(filePathDirectDl)) window.open(filePathDirectDl, '_newself');
        }}
        onContextMenu={e => {
            //var elem = document.querySelectorAll("." + srcFileManager.className + " .file-box[data-fileid='" + dt.idFile + "'] .file.selected");
            //if (elem.length) {
                if (srcFileManager.showContextMenu) {
                    srcFileManager.showContextMenu({
                        id: "contextMenu-" + srcFileManager.className,
                        event: e,
                        props: { file: dt, data: data },
                    });
                }
            //}
        }} style={detailedVisibility ? { float: "none" } : { float: "none", width: "auto" }}>
        <div className={`file ${disabled ? " file-disabled" : ""}`} style={pictureStyle}>
            <a href='#' data-trigger='focus'>
                <div className='file-marker'></div>
                <span className='corner'></span>
                <div className={isPicture ? 'icon file-fullsize  d-flex align-items-center justify-content-center' : 'icon'} style={iconStyle} title={altT(data.title)}>
                    {isPicture ? <Img className='img-fluid img-thumbnail2 mx-auto d-block ' src={filePath} alt='' data-typef='img' /> : ""}
                    {fileIcon}
                </div>
                {detailedVisibility ? <div className='file-name' title={commonFunc.cellFormatterFileType(dt.name)}>
                    <span name="file-info-name"><i title='Fichier source' className='fa fa-check-circle reportSpecFile-icon'></i>{dt.name}</span><br />
                    <small name="file-info-date" className='float-start'>{commonFunc.formatDateTime(commonFunc.restStringToDate(dt.dSysMod))}</small>
                    <small name="file-info-size" className='float-end'>{commonFunc.bytesToSize(dt.length, 1)}</small>
                </div> : ""}
            </a>
            {detailedVisibility ? <div className='side-corner-tag' data-typec={dt.prefix}><div><span>{dt.categ}</span></div></div> : ""}
        </div>
        {(isPicture && !commonFunc.isBlank(filePath)) ? <UncontrolledPopover
            className="pop-no-mw"
            fade
            target={pictureFragId}
            placement="auto"
            trigger="hover"
            container="body"
            delay={{ show: 500, hide: 50 }}
        //noIncluded="html: true"//INFO
        >
            <PopoverHeader className="text-dark">Image</PopoverHeader>
            <PopoverBody>
                <img style={{ width: "480px", maxWidth: "inherit" }} className='img-responsive' src={filePath} alt='' />
            </PopoverBody>
        </UncontrolledPopover> : ""}
    </div>;

    //TODO : check formatDate utc

    return <Col data-name="PictureFrag" data-element={data.objType} xs="auto" className={`align-self-center ${frameClass} ${parentProps && parentProps.parentData && parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`}>
        <input className="d-none" type="file" onChange={onChange} accept={data.argumentsObj ? data.argumentsObj.cAcceptTypes : ""} ref={fileInput} value="" />{baseForm}</Col>;
}