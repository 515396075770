import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

import { useTranslation } from "react-i18next";
import moment from "moment";
import Swal from "sweetalert2";

import {
    Button, Input, Label,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form, FormGroup,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import FilesCustom, { ExtUpload } from 'components/FilesCustom';
import EditorCustom from 'components/EditorCustom';
import ReportFinal from 'components/ReportFinal';
import ShowDetails from 'components/ShowDetails';
import Historical from 'components/Historical';

import CNavbar from 'components/CNavbar';

import * as commonFunc from 'scripts/common';
import CDataTable, { showDtPrevID, showDtSelID, showDtNextID, setDtNoSelect } from "components/CDataTable";

import AGenerateModel from 'components/generic/AGenerateModel';

import { withRouter } from 'components/withRouter';

class ManageReportsItv extends Component {
    static displayName = ManageReportsItv.name;

    static reportSchema;
    static reportModels;
    static refreshCb;
    static modalRoot;
    static dtFunctions;

    static loadReportCb;

    modalGenManager = React.createRef();

    constructor(props) {
        super(props);

        this.reportGen = React.createRef(null);

        this.state = { reports: [], curReport: null, curReportId: 0, status: [], defaultStatus: 212, curStatus: 212, historicalReports: true, filterReports: false, loading: true, dataStamp: 0, activeTab: "1", title: "Aucune Intervention sélectionnée", editionMode: false };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function (curUser) {

            commonFunc.getMethod("../api/report/GetReportModels", { id: -1 }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {

                    context.reportModels = {};
                    dataModels.result.models.forEach((model) => {
                        context.reportModels[model.extID] = JSON.parse(model.value);
                    });
                    
                    //client 1404008 -> AAD (SMS)
                    //client 1404007 -> (ADD/UPD ReportDraft)
                    //client 1404013 -> (ADD/UPD ReportDraft)
                    //client 1404020 -> (ADD ManualReport)
                    //client 1404024 -> (ADD ManualReport)
                    //OPT client 1404016 -> (Accès page MatOrders)

                    /*context.loadEmpOptgrp("reportLstEmp", 0, 0, function (dataEmp) {
                        loadEmpOptgrpByCache("select.mask-loading-emp", dataEmp, 0);
                    });

                    context.loadSvcOptgrp("reportLstSvc", 0, 0);

                    context.loadSubStatusBtnGrp("btnLstSubStatus", 350, 'SREPORT');

                    context.loadFlowsType("targetMsgType", 0, 2, false);*/

                    /*if (this.props.dataCfg && this.props.dataCfg.dlgSup_checkEnd_1) {
                        initNewManualReport(true);
                    }
                    else {
                        initNewManualReport();
                    }
 
                    initNewDraft();*/

                    /*commonFunc.getMethod("../api/report/GetSchemaModel", null).then((response) => {
                        var dataSchema = response.data
                        if (dataSchema.isSuccess) {

                            context.reportSchema = dataSchema.result;*/

                            //Chargement via url
                            var defReportLoadId = 0;
                            var defReportActionId = 0;
                            if (context.props.searchParams) {
                                defReportLoadId = parseInt(context.props.searchParams.get("reportId")) || 0;
                                defReportActionId = parseInt(context.props.searchParams.get("actionId")) || 0;
                            }
                            if (defReportLoadId > 0) {
                                var defReportStatusId = parseInt(context.props.searchParams.get("statusId")) || 212;
                                context.loadStatusBtnGrp(defReportStatusId);

                                context.refreshCb = () => { showDtSelID("tableReports", defReportLoadId); }
                                context.setState({ curStatus: defReportStatusId, curReportId: defReportLoadId });
                            } else {
                                context.loadStatusBtnGrp(212);

                                context.getReports();

                                /*if (defReportActionId == 1) {
                                    $(".action-addReport:visible").click();
                                }*/
                            }

                        /*}
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de la récupération du schéma intervention"
                            });
                        }
                    });*/
                } else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModels") + " :<br>" + dataModels.errorMessage
                    });
                }
            });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");
        if (prevState.historicalReports !== this.state.historicalReports || prevState.filterReports !== this.state.filterReports || prevState.curStatus !== this.state.curStatus) {
            this.getReports();
        }
        else {
            //if (this.dtFunctions && this.dtFunctions["tableReports"] && this.dtFunctions["tableReports"].resizeResponsive && prevState.curReportId !== this.state.curReportId) this.dtFunctions["tableReports"].resizeResponsive();
        }

        //Update Scroller Size
        if (prevState.isShowDetails && !this.state.isShowDetails) {
            if (this.props.getScroller) this.props.getScroller().update();
        }

        //Callback initCreateReport
        if (this.state.curReportId === 0 /*&& prevState.curReportId !== this.state.curReportId*/ && this.state.editionMode && !this.state.isShowDetails) {
            this.setState({ isShowDetails: true });
        }
        if (prevState.editionMode !== this.state.editionMode && this.dtFunctions && this.dtFunctions["tableReports"]) this.dtFunctions["tableReports"].setDisabled(this.state.editionMode);
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount(); 
            this.modalRoot = null;
        }
    }

    dtFunctionPanel(id, fctPanel) {
        if (!this.dtFunctions) this.dtFunctions = {};
        if (!this.dtFunctions[id]) this.dtFunctions[id] = {};
        this.dtFunctions[id] = fctPanel;
    }

    render() {
        var context = this;

        var listCb = function () {
            if (context.refreshCb) {
                if (context.refreshCb && typeof (context.refreshCb) === "function") { context.refreshCb(); }
                context.refreshCb = null;
            }
        };

        //TODO : createdCell >> dépendance du contexte pour éxecution de la fonction
        var dtConfig = {
            columnDefs: [
                {
                    createdCell: (td, cellData, rowData, row, col) => {
                        var currRoot = createRoot(td);
                        currRoot.render(<Button className='btn-icon btn-simple m-0' color="dark" size="sm" disabled={this.state.editionMode}
                            onClick={() => showDtSelID("tableReports", rowData.idReport)} title="Consulter"><i className='fa fa-eye'></i></Button>);
                    }, "targets": "renderingShow"
                },
            ],
            dtOnSelect: function (dataSel) {
                var id = dataSel.idReport;
                if (parseInt(id) > 0) {
                    if (!context.state.editionMode) context.loadReport(id, dataSel);
                }
            },
            dtOnUnselect: function (dataSel) {
                if (!context.state.editionMode) context.loadReport();
            },
            dtOnDblClick: function (event) {
                /*var id = parseInt(getDtSelID("lstActs"));
                var currId = parseInt($(this).closest("tr").prop("id"));
                if (currId != id) {
                    scrollToId("detailAct");
                    showDtSelID("lstActs", currId);
                }
                else {
                    if (currId > 0) {
                        scrollToId("detailAct");
                    }
                }*/
            },
            cbInitComplete: function (cRootList) {
                //Swal.close();

                /*cRootList.map((prop, elem) => {
                    console.debug(elem);
                    if (elem.classList.contains("cneedroot")) {
                        var rootElem = createRoot(elem);
                        switch (elem.getAttribute("cneedroot")) {
                            case "forceLogout":
                                rootElem.render(<button className="btn btn-primary btn-icon" onClick={() => context.forceLogout('hey!')}><i className='fa fa-edit'></i></button>);
                                break;
                            default:
                                break;
                        }
                    }
                    return null;
                });*/

                //$("#report_select_filters").appendTo($(".dt-buttons").parent().parent());
                //$("#report_select_filters").removeClass("d-none").addClass("d-inline-block");

                //updateSidebar();

                listCb();
            },
            cbCustom: function () {
                listCb();
            }
        };

        //console.debug("render again");

        let contents = this.state.loading ? <p><em>{context.props.i18nManager.t("gal_loading")}</em></p> :
            <div>
                {this.reportModels ? <CDataTable key="reports" dtId={"tableReports"} data={this.state.reports} localConfig={dtConfig} tag={"DT_RPTS"} config={this.reportModels["DT_RPTS"]} stamp={this.state.dataStamp} functionPanel={this.dtFunctionPanel.bind(this)}
                    className="table-striped table-hover nowrap" style={{ fontSize: "90%" }} /> : ""}
            </div>;


        return (
            <div className="tableDetails">
                <Card className={`tableContainer widget dash ${this.state.editionMode ? "module-disabled" : ""}`}>
                    <CardHeader>
                        <CardTitle tag="h6">Interventions</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-table-list fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <div className="float-start">
                            <Button className="btn-rotate px-2 btn-simple mt-0" disabled={this.state.editionMode} color="dark" size="sm" onClick={() => this.getReports()}>
                                <i className="fa fa-sync"></i>Actualiser
                            </Button>
                            {this.props.checkRights("REPORT", "C") ? <Button className="btn-magnify px-2 mt-0" disabled={this.state.editionMode} color="success" size="sm" onClick={() => this.showManualReport()}>
                                <i className="fa fa-plus-square"></i>Ajouter une intervention
                            </Button> : ""}
                            {this.props.checkRights("REPORT", "C") ? <Button className="btn-magnify px-2 mt-0 d-none" disabled={this.state.editionMode} color="success" size="sm" onClick={() => this.showDraft()}>
                                <i className="fa fa-plus-square"></i>Ajouter une intervention
                            </Button> : ""}
                            <FormGroup check inline className='fcheck-sm ms-2 d-none'>
                                <Label check className="fs-6 text-dark" >
                                    <Input checked={this.state.filterReports} type="checkbox" disabled={this.state.editionMode} onChange={() => this.setState({ filterReports: !this.state.filterReports })} />
                                    <span className="form-check-sign" />
                                    Filtrage Configuration
                                </Label>
                            </FormGroup>
                            <FormGroup check inline className='fcheck-sm ms-2'>
                                <Label check className="fs-6 text-dark" >
                                    <Input checked={this.state.historicalReports} type="checkbox" disabled={this.state.editionMode} onChange={() => this.setState({ historicalReports: !this.state.historicalReports })} />
                                    <span className="form-check-sign" />
                                    6 derniers mois
                                </Label>
                            </FormGroup>
                        </div>
                        <CNavbar className="float-end">
                            <div className="text-end">
                                {this.state.status.map((dt) => {

                                    //TODO : statusBtnAltProc

                                    var statusClass = "form-badge-radio mb-1 ";
                                    statusClass += dt.css ? dt.css.replace("fa-", "untrigger") : "";
                                    return dt.isSchedulable ? <div id={`st_${dt.idStatus}`} key={dt.idStatus} className={statusClass}>
                                        <div className='form-check-radio fcheck-sm mb-0'>
                                            <Label check >
                                                <Input id={`status_${dt.idStatus}`} name={"status"} disabled={this.state.editionMode} checked={dt.idStatus === context.state.curStatus} type="radio" data-lvlv={dt.step} value={dt.idStatus} onChange={(e) => {
                                                    var value = parseInt(e.target.value);
                                                    /*var last = parseInt(btnGrp.data("categ") || 0);
                                                    if ($(this).val() == 210 && last == 0) {
                                                        loadFilesType(64, true);
                                                        btnGrp.data("categ", 64);
                                
                                                    } else if ($(this).val() != 210 && last != 0) {
                                                        loadFilesType(0, true);
                                                        btnGrp.data("categ", 0);
                                                    }*/
                                                    context.setState({ curStatus: value, curReportId: 0, curReport: null, isShowDetails: false });
                                                }} />
                                                <span className="form-check-sign" />
                                                {(() => {
                                                    var classFa = "";
                                                    if (dt.css && dt.css.indexOf("fa-")) {
                                                        dt.css.split(" ").forEach((classElem) => {
                                                            //console.debug(classElem.startsWith("fa-") + classElem);
                                                            classFa += (classElem.startsWith("fa-") ? (classElem + " ") : "");
                                                        });
                                                    }
                                                    return <i className={`fa ${classFa} me-1`}></i>
                                                })()}
                                                {dt.idStatus === 210 ? dt.name.replace("fa-eur", "fa-euro-sign") : dt.name}
                                            </Label>
                                        </div>
                                    </div> : "";
                                })}
                            </div>
                        </CNavbar>
                        {contents}
                    </CardBody>
                </Card>

                {this.state.isShowDetails ? (<div className="detailsContainer">
                    <Card className="widget dash text-white" color="theme">
                        <CardHeader>
                            <Row>
                                <Col lg="10" className="offset-lg-1">
                                    <CardTitle tag="h6" className="text-center">
                                        {this.state.title}
                                    </CardTitle>
                                </Col>
                                <Col lg="1" className="d-flex text-end float-end justify-content-end">
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActPrev" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curReportId || 0;
                                        showDtPrevID("tableReports", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-left"></i></Button>
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActNext" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curReportId || 0;
                                        showDtNextID("tableReports", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-right"></i></Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Nav pills className={`${this.state.editionMode ? "invisible" : ""}`}>
                                {this.props.checkRights("REPORT", "R") ? <NavItem><NavLink className={`${this.state.activeTab === '1' ? "active" : ""}`} onClick={() => { this.switchTab("1") }}>Détails</NavLink></NavItem> : ""}
                                {this.props.checkRights("REPORT_CONTACTS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '2' ? "active" : ""}`} onClick={() => { this.switchTab("2") }}>Contacts</NavLink></NavItem> : ""}
                                {this.props.checkRights("REPORT_FILES", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '3' ? "active" : ""}`} onClick={() => { this.switchTab("3") }}>Documents</NavLink></NavItem> : ""}
                                {this.props.checkRights("REPORT", "U") ? <NavItem><NavLink className={`${this.state.activeTab === '4' ? "active" : ""}`} onClick={() => { this.switchTab("4") }}>Aperçu Rapport</NavLink></NavItem> : ""}
                                {this.props.checkRights("REPORT_FILES", "R") ? <NavItem><NavLink className={`${this.state.activeTab === '5' ? "active" : ""}`} onClick={() => { this.switchTab("5") }}>Rapport Finalisé</NavLink></NavItem> : ""}
                                {this.props.checkRights("REPORT_FLOWS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '6' ? "active" : ""}`} onClick={() => { this.switchTab("6") }}>Historique</NavLink></NavItem> : ""}
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.props.checkRights("REPORT", "R") ? <TabPane tabId="1">
                            <ShowDetails id={this.state.curReportId} type="report" genRef={this.reportGen} data={this.state.curReport} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.reportModels ? this.reportModels["REPORT"] : {}} modelsList={this.reportModels} loadList={this.loadReports.bind(this)} loadElem={this.loadReport.bind(this)} saveElem={this.saveReport.bind(this)} removeElem={this.removeReport.bind(this)} updateStatusElem={this.updateReportStatus.bind(this)} setEditingMode={this.setEditingMode.bind(this)} formEditing={this.state.editionMode} shouldRender={this.state.activeTab === '1'} />
                        </TabPane> : ""}
                        {this.props.checkRights("REPORT_CONTACTS", "L") ? <TabPane tabId="2">
                            <ShowDetails id={this.state.curReportId} type="reportContacts" data={this.state.curReport} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.reportModels ? this.reportModels["CONTACTS"] : {}} modelsList={this.reportModels} shouldRender={this.state.activeTab === '2'} />
                        </TabPane> : ""}
                        {this.props.checkRights("REPORT_FILES", "L") ? <TabPane id="aupdAct-3" tabId="3">
                            <Form id="frmaupdact3">
                                <FilesCustom className="report-files" bindID={this.state.curReportId} rightType="REPORT_FILES" checkRights={this.props.checkRights} fileTypeID="REPORT" src={this.state.curReport} srcName={this.state.curReport ? this.state.curReport.name : ""} withFilters={true} shouldRender={this.state.activeTab === '3'} altFilesTypes="../api/report/GetReportFilesType" altFilesUrl="../api/report/GetReportFiles" altRemoveFilesUrl="../api/report/RemoveReportFile" altUploadFilesUrl="../api/report/UploadReportFiles" />
                            </Form>
                        </TabPane> : ""}
                        {this.props.checkRights("REPORT", "U") ? <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    <EditorCustom report={context.state.curReport} reportId={context.state.curReportId} serviceId={context.state.curReport ? context.state.curReport.benefitID : 0} templateId={context.state.curReport ? context.state.curReport.templateID : 0} updateReportStatus={this.updateReportStatus.bind(this)} loadReport={this.loadReport.bind(this)}
                                        nextTab={() => this.setState({ activeTab: "5" })} refreshReport={(report) => this.setState({ curReport: report })} dataCfg={this.props.dataCfg} />
                                </Col>
                            </Row>
                        </TabPane> : ""}
                        {this.props.checkRights("REPORT_FILES", "R") ? <TabPane tabId="5">
                            <Row className="report-final">
                                <Col lg="12">
                                    <Card className="widget dash">
                                        <CardHeader>
                                            <CardTitle tag="h6">RAPPORT FINAL</CardTitle>
                                            <div className="widget-tools collapse-tool">
                                                <i className="fa fa-file-pdf fa-4x"></i>
                                            </div>
                                            <div className='borderTheme'></div>
                                        </CardHeader>
                                        <CardBody>
                                            <ReportFinal report={context.state.curReport} reportId={context.state.curReportId} checkRights={this.props.checkRights} shouldRender={context.state.activeTab === '5'} updateReportStatus={this.updateReportStatus.bind(this)}
                                                loadReport={this.loadReport.bind(this)} sendEmail={this.sendEmail.bind(this)} dataCfg={this.props.dataCfg}></ReportFinal>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane> : ""}
                        {this.props.checkRights("REPORT_FLOWS", "L") ? <TabPane tabId="6">
                            <Row className="report-history">
                                <Col lg="12">
                                    <Card className="widget dash">
                                        <CardHeader>
                                            <CardTitle tag="h6">HISTORIQUE</CardTitle>
                                            <div className="widget-tools collapse-tool">
                                                <i className="fa fa-history fa-4x"></i>
                                            </div>
                                            <div className='borderTheme'></div>
                                        </CardHeader>
                                        <CardBody>
                                            <Historical type="report" elemId={context.state.curReportId} url="../api/report/GetReportFlows" getRoutes={context.props.getRoutes} shouldRender={context.state.activeTab === '6'} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane> : ""}
                    </TabContent></div>) : ""}
            </div>
        );
    }

    loadReports(id, statusId, callback) {
        if (statusId) {
            this.refreshCb = (() => {
                if (id) showDtSelID("tableReports", id);
                if (callback && typeof (callback) === "function") { callback(); }
            });
            this.setState({ curStatus: statusId });
        }
        else {
            this.getReports(0, true, () => {
                if (id) showDtSelID("tableReports", id);
                if (callback && typeof (callback) === "function") { callback(); }
            });
        }
    }

    async getReports(statusId, forcedUpdate, callback) {
        if (!forcedUpdate && this.state.editionMode) return;
        this.setState({ reports: [], curReport: null, curReportId: 0/*, loading: true*/, dataStamp: 0, isShowDetails: false, editionMode: false });
        var context = this;

        if (callback) {
            context.refreshCb = callback;
        }

        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var args = {};
        if (this.state.historicalReports) {
            //args.startdate = new Date().toISOString();
            args.lastSync = new Date().toISOString();
            args.period = 'H6';
        }
        else {
            args.period = '*';
        }
        args.state = statusId || this.state.curStatus || 212;
        //args.period = "w";
        //args.sdate = stringToDate("31/05/2017 00:00").toISOString();
        args.showAll = true;
        args.withTemplate = false;

        commonFunc.getMethod("../api/report/GetReports", args).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {
                    if (context.state.filterReports) {
                        response.data.result = response.data.result.filter(function (elem, index) {
                            return !elem.startDate.startsWith("2099");
                        })
                    }

                    if (response.data.result) {
                        var filteredData = response.data.result.filter(function (dt, i) {
                            var flag = true;
                            var filter_reports_services = context.state.filter_reports_services;
                            var filter_reports_department = context.state.filter_reports_department;
                            var filter_reports_insurance = context.state.filter_reports_insurance;

                            flag &= dt.idReport != 0;

                            if (!commonFunc.isBlank(filter_reports_services)) {
                                flag &= dt.serviceID == filter_reports_services;//benefitID ???
                            }
                            if (!commonFunc.isBlank(filter_reports_department)) {
                                if (dt.Address) {
                                    var cp = dt.address.pc;//dt.Address.match(/\d{5}/g);
                                    if (!commonFunc.isBlank(cp)) {
                                        flag &= parseInt(cp[cp.length - 1].substr(0, 2)) == filter_reports_department;
                                    }
                                    else {
                                        flag &= false;
                                    }
                                }
                                else {
                                    flag &= false;
                                }
                            }
                            if (filter_reports_insurance != 0 && !commonFunc.isBlank(filter_reports_insurance)) {
                                flag &= dt.insurance == filter_reports_insurance;
                            }

                            return flag;
                        });

                        response.data.result = filteredData;
                    }
                    //console.debug(filteredData);

                    /*$('#lstActs thead th').each(function () {
                        var title = $(this).text();
                        if ($(this).hasClass("searchable")) {
                            $(this).append('<input type="text" class="form-control col-lg-12 form-control-sm" placeholder="' + title + '" />');
                        }
                    });*/

                    Swal.close();
                    context.setState({ reports: response.data.result, loading: false, dataStamp: new Date().getTime(), isShowDetails: false });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de la récupération des interventions :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la récupération des interventions"
                });
            }
        });

    }

    async getReportsTEST(statusId, callback) {//TEMP : test volume de données
        var context = this;

        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        commonFunc.getMethod("../api/data", null).then((response) => {
            console.debug(response.data);
            response.data = { isSuccess: true, result: response.data };

            if (response.data) {
                if (response.data.isSuccess) {
                    Swal.close();
                    context.setState({ reports: response.data.result, loading: false, dataStamp: new Date().getTime(), isShowDetails: false });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de la récupération des interventions :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la récupération des interventions"
                });
            }
        });

    }

    loadStatusBtnGrp(defaultID) {
        var context = this;

        commonFunc.getMethod("../api/report/GetStatus", null).then((response) => {
            var data = response.data;

            //Libelle = name + ForeColor = foreColor + Opacite >> removed + Niveau = step + Categ = categ + Actif = isActive + Planifiable = isSchedulable + css + valeur = value
            if (data.isSuccess) {

                context.setState({ status: data.result, defaultStatus: defaultID, curStatus: defaultID });

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de récupération des statuts<br/>" + response.data.errorMessage
                });
            }

        });
    }

    async loadReport(id) {
        var context = this;

        var callback = this.loadReportCb;
        this.loadReportCb = null;

        this.setState({ title: "Aucune Intervention sélectionnée", curReportId: 0, curReport: null, isShowDetails: (id > 0), editionMode: false });

        return new Promise((resolve, reject) => {

            var args = {};
            //args.state = 201;
            args.id = id;
            if (parseInt(id) > 0) {
                Swal.fire({
                    title: "Chargement en cours...",
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                commonFunc.getMethod("../api/report/GetReport", args).then((response) => {
                    var data = response.data;
                    if (data.isSuccess) {
                        console.log(data.result);

                        if (callback && typeof (callback) === "function") { callback(data); }

                        Swal.close();

                        if (context.state.isShowDetails) context.setState({ title: (data.result.client.name + "  •  " + data.result.refID + "  •  " + data.result.agency.name), curReportId: id, curReport: data.result, isShowDetails: true });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: "Echec de chargement de l'intervention :<br>" + data.errorMessage
                        });
                    }
                    resolve(data);
                });

            }
            else {
                resolve({ isSuccess: true, result: "" });
            }
        });
    }

    async saveReport(userReport, isUpdate) {
        var context = this;
        const dataReport = this.state.curReport || {};

        console.debug(userReport)
        /*userReport.employeeID = userReport.itv.employeeID;

        if (!userReport.itv.length) userReport.itv.length = dataReport.itv.length || 1.5;//TEMP

        //TODO : reprendre l'affichage heures variant en fonction du service
        //userReport.itv.startDate = new Date(userReport.itv.startDate);//TEMP : valeur mal stocké dans react hook form
        userReport.itv.endDate = commonFunc.addMinutes(userReport.itv.startDate, userReport.itv.length * 60);
        //userReport.itv.endDate = "2099-01-01T01:00:00";

        */

        //TODO : voir si on garde ce principe ou pas (>> création d'un nouveau rapport à chaque modification)
        /*var removeInfos;
        if (dataReport && dataReport.idReport) {
            removeInfos = {}
            var id = dataReport.idReport;
            if (id > 0) {//&& data.result.StatusID < 202
                removeInfos.id = id;
            }
            else {
                return;
            }
            removeInfos.isPermanent = false;

            userReport.dSysCreat = dataReport.DSysCreat;
        }
        else {
            userReport.dSysCreat = new Date();
        }*/



        if (!userReport.avoidMerge) {
            userReport = commonFunc.mergeDeep({}, dataReport, userReport);

            var startDate = userReport.itv.startDate;
            var endDate = userReport.itv.endDate;
            if (!(endDate instanceof Date)) {
                startDate = moment(startDate).utc().toDate();
                endDate = moment(endDate + "Z").toDate();
            }

            userReport.category = null;
            userReport.agency = null;
            userReport.client = null;//NEED
            userReport.service = null;
            userReport.employee = null;//NEED Mobile/Gsm OU passage par Itv.Employee
            userReport.status = null;//NEED Css/Name
            userReport.subStatus = null;
            //userReport.itv = null;//NEED StartDate/Length/EmployeeID/Employee
            userReport.finalFile = null;
            //userReport.address = null;
            //userReport.billAddress = null;

            //userReport.itv.employee = null;
        }

        console.debug(dataReport);
        console.debug(userReport);


        var flag = true;
        if (userReport.contacts) {
            userReport.contacts.forEach((contact) => {
                flag &= !(contact.isNotifiedSMS && commonFunc.isBlank(contact.gsm));
            });
        }
        //TODO : voir si possible de gérer les tests dans react hook form
        if (!(flag /*&& userReport.employeeID > 0*/ && commonFunc.isValidDate(startDate))) {
            console.debug(startDate);
            console.debug(commonFunc.isValidDate(startDate));
            if (!flag) {
                Swal.fire({
                    icon: 'warning',
                    title: "Veuillez renseigner un numéro de mobile pour Envoi Sms.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            }
            /*else if (userReport.employeeID <= 0) {
                Swal.fire({
                    icon: 'warning',
                    title: "Veuillez saisir un intervenant valide.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            }*/
            else {
                Swal.fire({
                    icon: 'warning',
                    title: "Veuillez saisir une date et/ou une heure valide.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            }
            return new Promise((resolve, reject) => {
                resolve({ isSuccess: false });
            });
        }
        if (!commonFunc.isValidDate(new Date(startDate)) || !commonFunc.isValidDate(new Date(endDate))) {
            Swal.fire({
                icon: 'error',
                title: 'Echec',
                html: "Veuillez renseigner des dates valides"
            });
            return new Promise((resolve, reject) => {
                resolve({ isSuccess: false });
            });
        }
        else if (startDate.getFullYear() > 1970 && moment(endDate).isBefore(startDate)) {
            Swal.fire({
                icon: 'error',
                title: 'Echec',
                html: "La date de fin d'intervention doit se situer après la date de début"
            });
            return new Promise((resolve, reject) => {
                resolve({ isSuccess: false });
            });
        }


        Swal.fire({
            title: "Sauvegarde en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        console.debug(userReport);
        //return new Promise((resolve, reject) => { resolve({ isSuccess: false }); });//TEMP
        return commonFunc.postMethod(isUpdate ? "../api/report/UpdateReport" : "../api/report/CreateReport", userReport).then((response) => {
            Swal.close();
            return new Promise((resolve, reject) => {
                var data = response.data;
                if (data && data.isSuccess) {

                    var assignAndSend = !commonFunc.isBlank(userReport.address.addressMain) && parseInt(userReport.itv.employeeID) > 0 && commonFunc.isValidDate(new Date(userReport.itv.startDate)) && commonFunc.isValidDate(new Date(userReport.itv.endDate));


                    /*var callbackSaveProc = async function (dataAssign) {

                        //TODO : Gestion des fichiers

                        //SUCCES GLOBAL
                        Swal.close();

                        if (assignAndSend && this.props.dataCfg && this.props.dataCfg.dlgSup_enableSend_1) {
                            Swal.fire({
                                icon: 'success',
                                title: "Chantier",
                                html: "L'intervention/Pochette a été ajoutée et préparée avec succès !<br><b>Voulez-vous envoyer le rapport au technicien ?</b>",
                                confirmButtonColor: "#5cb85c",
                                confirmButtonText: "Oui",
                                showCancelButton: true,
                                cancelButtonColor: "#d9534f",
                                cancelButtonText: "Non",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                didOpen: () => {
                                    Swal.hideLoading();

                                    if (dataAssign && !dataAssign.isSuccess) {
                                        $(".swal2-content").append("<br><b>Echec de l'assignation de l'intervenant :</b><br>" + dataAssign.errorMessage + "<br>");
                                    }
                                    if (errorFiles.length) {
                                        $(".swal2-content b").addClass("hidden");
                                        $(".swal2-confirm").addClass("hidden");
                                        $(".swal2-cancel").text("Fermer");
                                        $(".swal2-cancel").addClass("switch-btn");

                                        $(".swal2-content").append("<br><b>Certains fichiers n'ont pas pu être envoyés. Transférer les manuellement.</b>");
                                        $.each(errorFiles, function (i, e) {
                                            var div = $("<div class='text-danger' style='font-size:90%'>" + $(e).find(".file-name").text().replace($(e).find(".file-name small").text(), "") + "</div>");
                                            $(".swal2-content").append(div);
                                        });
                                    }
                                }
                            }).then((result) => {
                                if (result.value) {
                                    // handle confirm
                                    Swal.fire({
                                        title: 'Préparation du rapport...',
                                        onOpen: () => { Swal.showLoading() },
                                    });

                                    var args = { id: id, isSource: true, toStore: true };
                                    // getMethod("../api/reports/GetReportModel", args, function (data) {
                                    postMethod("../api/reports/AddReportModel", args, function (data) {
                                        if (data.isSuccess) {
                                            //Envoi direct
                                            Swal.close();
                                            Swal.fire({
                                                title: 'Envoi en cours...',
                                                onOpen: () => { Swal.showLoading() },
                                            });
                                            var req = { id: id, newStatus: 202, newStep: -1 };
                                            postMethod("../api/reports/UpdateStatus", req, function (data) {
                                                Swal.close();
                                                if (data && data.isSuccess) {
                                                    //loadReport(id);
                                                } else {
                                                    Swal.fire({
                                                        type: 'error',
                                                        title: "Echec de mise à jour du statut",
                                                        html: "<p>" + data.errorMessage + "</p>",
                                                    });
                                                }
                                                $("#btnLstItvsRefresh").click();

                                            });
                                        } else {
                                            Swal.close();
                                            Swal.fire({
                                                type: 'error',
                                                title: "Echec d'envoi sur la tablette. Procédez à son envoi manuellement.",
                                                html: "<p>" + data.errorMessage + "</p>",
                                            });
                                            $("#btnLstItvsRefresh").click();
                                        }

                                    });
                                }
                                else {
                                    $("#btnLstItvsRefresh").click();
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                type: 'success',
                                title: "Chantier",
                                html: "L'intervention/Pochette a été ajoutée et préparée avec succès !",
                                onOpen: () => {
                                    Swal.hideLoading();

                                    if (dataAssign && !dataAssign.isSuccess) {
                                        $(".swal2-content").append("<br><b>Echec de l'assignation de l'intervenant :</b><br>" + dataAssign.errorMessage + "<br>");
                                    }
                                    if (errorFiles.length) {
                                        $(".swal2-content").append("<br><b>Certains fichiers n'ont pas pu être envoyés. Transférer les manuellement.</b>");
                                        $.each(errorFiles, function (i, e) {
                                            var div = $("<div class='text-danger' style='font-size:90%'>" + $(e).find(".file-name").text().replace($(e).find(".file-name small").text(), "") + "</div>");
                                            $(".swal2-content").append(div);
                                        });
                                    }
                                }
                            }).then((result) => {
                                $("#btnLstItvsRefresh").click();
                            });
                        }
                    };

                    if (removeInfos) {
                        postMethod("../api/reports/RemoveReport", removeInfos, function (dataRemove) {
                            hideBsModal("progressModal");
                            if (dataRemove && dataRemove.isSuccess) {
                                var mFiles = { id: id, targetId: removeInfos.id, isPermanent: true };
                                postMethod("../api/reports/MigrateFiles", mFiles, function (dataFilesMigration) {
                                    if (dataFilesMigration.isSuccess) {

                                        if (assignAndSend) {
                                            //assignDlgItv(id, itv, ad2, callbackRemoveProc);
                                            assignDlgItv(id, itv, ad2, callbackSaveProc);
                                        }
                                        else {
                                            callbackSaveProc();
                                        }
                                    } else {
                                        Swal.close();
                                        Swal.fire({
                                            type: 'error',
                                            title: "Echec de migration des documents de la pochette d'origine",
                                            html: "<p>" + dataFilesMigration.errorMessage + "</p>",
                                        });
                                    }
                                });
                            } else {
                                Swal.close();
                                Swal.fire({
                                    type: 'error',
                                    title: "Echec de la suppression de la pochette d'origine",
                                    html: "<p>" + dataRemove.errorMessage + "</p>",
                                });
                            }
                        });

                    } else {
                        if (assignAndSend) {
                            //assignDlgItv(id, itv, ad2, callbackRemoveProc);
                            assignDlgItv(id, itv, ad2, callbackSaveProc);
                        }
                        else {
                            callbackSaveProc();
                        }
                    }*/


                    var callbackSaveProc = async function (dataAssign) {
                        return new Promise((resolve, reject) => {
                            Swal.fire({
                                icon: "success",
                                title: "Succès",
                                html: (isUpdate ? "Modification" : "Création") + " effectuée avec succès."
                                    + ((dataAssign && !dataAssign.isSuccess) ? "<br><b>Echec de l'assignation de l'intervenant :</b><br>" + dataAssign.errorMessage + "<br>" : ""),
                                didOpen: () => {
                                    Swal.hideLoading();

                                    /*if (errorFiles.length) {
                                        $(".swal2-content").append("<br><b>Certains fichiers n'ont pas pu être envoyés. Transférer les manuellement.</b>");
                                        $.each(errorFiles, function (i, e) {
                                            var div = $("<div class='text-danger' style='font-size:90%'>" + $(e).find(".file-name").text().replace($(e).find(".file-name small").text(), "") + "</div>");
                                            $(".swal2-content").append(div);
                                        });
                                    }*/
                                },
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                            }).then((result) => {
                                /*context.getReports(0, true, () => {
                                    showDtSelID("tableReports", data.result.idReport);
                                });*/
                                context.loadReport(data.result.idReport);//userReport.idReport
                            });

                            data.result.idResponse = data.result.idReport;
                            resolve(data.result);
                        });
                    };


                    callbackSaveProc();


                } else {
                    var supErrors = "";
                    if (data.errors && data.errors.length) {
                        supErrors = data.errors.map((error) => {
                            return "<br>" + error.desc + "(" + error.value + ")";
                        });
                    }
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Echec de la modification :<br>" + data.errorMessage + supErrors
                    });
                    resolve(data);
                }
            });
        });
    }

    async saveReport2(userReport, isUpdate) {
        var context = this;
        const dataReport = this.state.curReport || {};

        userReport.employeeID = userReport.itv.employeeID;
        console.debug(userReport)

        if (!userReport.itv.length) userReport.itv.length = dataReport.itv.length || 1.5;//TEMP

        //TODO : reprendre l'affichage heures variant en fonction du service
        //userReport.itv.startDate = new Date(userReport.itv.startDate);//TEMP : valeur mal stocké dans react hook form
        if (commonFunc.isValidDate(userReport.itv.startDate)) {
            userReport.itv.endDate = commonFunc.addMinutes(userReport.itv.startDate, userReport.itv.length * 60);
        }
        else {
            //userReport.itv.startDate = new Date(0);
            userReport.itv.startDate = moment("1970-01-01T00:00").toDate();
            userReport.itv.endDate = userReport.itv.startDate;
        }
        //userReport.itv.endDate = "2099-01-01T01:00:00";

        var flag = true;
        if (userReport.contacts) {
            userReport.contacts = userReport.contacts.filter(item => item !== null && item !== undefined);
            userReport.contacts.forEach((contact) => {
                flag &= !(contact.isNotifiedSMS && commonFunc.isBlank(contact.gsm));
            });
        }
        //TODO : voir si possible de gérer les tests dans react hook form
        if (!(flag /*&& userReport.employeeID > 0*/ && commonFunc.isValidDate(userReport.itv.startDate))) {
            console.debug(userReport.itv.startDate);
            console.debug(commonFunc.isValidDate(userReport.itv.startDate));
            if (!flag) {
                Swal.fire({
                    icon: 'warning',
                    title: "Veuillez renseigner un numéro de mobile pour Envoi Sms.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            }
            /*else if (userReport.employeeID <= 0) {
                Swal.fire({
                    icon: 'warning',
                    title: "Veuillez saisir un intervenant valide.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            }*/
            else {
                Swal.fire({
                    icon: 'warning',
                    title: "Veuillez saisir une date et/ou une heure valide.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            }
            return new Promise((resolve, reject) => {
                resolve({ isSuccess: false });
            });
        }
        if (!commonFunc.isValidDate(userReport.itv.startDate) || !commonFunc.isValidDate(userReport.itv.endDate)) {
            Swal.fire({
                icon: 'error',
                title: 'Echec',
                html: "Veuillez renseigner des dates valides"
            });
            return new Promise((resolve, reject) => {
                resolve({ isSuccess: false });
            });
        }
        else if (moment(userReport.itv.endDate).isBefore(userReport.itv.startDate)) {
            Swal.fire({
                icon: 'error',
                title: 'Echec',
                html: "La date de fin d'intervention doit se situer après la date de début"
            });
            return new Promise((resolve, reject) => {
                resolve({ isSuccess: false });
            });
        }


        Swal.fire({
            title: "Sauvegarde en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        //TODO : voir si on garde ce principe ou pas (>> création d'un nouveau rapport à chaque modification)
        var removeInfos;
        if (dataReport && dataReport.idReport) {
            removeInfos = {}
            var id = dataReport.idReport;
            if (id > 0 /*&& data.result.StatusID < 202*/) {
                removeInfos.id = id;
            }
            else {
                return;
            }
            removeInfos.isPermanent = false;

            userReport.dSysCreat = dataReport.DSysCreat;
        }
        else {
            userReport.dSysCreat = new Date();
        }


        userReport.idReport = isUpdate ? dataReport.idReport : 0;

        //TODO : voir si on reprend le système avec ID Temporaire pour les clients ou pas (configuration qui définissais si un module client était actif ou non ???)
        userReport.clientID = dataReport.clientID || userReport.clientID || 0;//PATCH POUR TEST SUIVANT : userReport.clientID === 0

        userReport.employee = null;

        userReport.siteAddressID = isUpdate ? dataReport.siteAddressID : 0;
        userReport.address.idAddress = userReport.siteAddressID;
        userReport.address.title = "Adresse Interv.";
        userReport.address.categ = "SITE";
        userReport.billAddressID = isUpdate ? dataReport.billAddressID : 0;
        userReport.billAddress.idAddress = userReport.billAddressID;
        userReport.billAddress.title = "Adresse de factu";
        userReport.billAddress.categ = "INVOICE";

        if (userReport.clientID) {
            userReport.client = null;
        }
        else if (userReport.clientID === 0 && !commonFunc.isBlank(userReport.client.name)) {
            if (!userReport.client) userReport.client = {};
            //userReport.client.siteID = -1;
            userReport.client.statusID = -1;
            userReport.client.stepID = -1;
            userReport.client.categoryID = -1;

            userReport.client.addressID = 0;
            userReport.client.address = userReport.address;
            userReport.client.billingAddressID = 0;
            userReport.client.billingAddress = userReport.billAddress;
        }


        userReport.sitePlaceType = "";

        //userReport.startDate = moment(userReport.itv.startDate).utc().format("YYYY-MM-DDTHH:mm:ss");//Date sinistre
        //userReport.assistantMail = "no@email.fr";
        //userReport.assistantPhone = "02 01 01 01 01";

        userReport.planItvID = isUpdate ? dataReport.planItvID : -1;
        userReport.itv.idEvent = isUpdate ? dataReport.itv.idEvent : 0;
        userReport.itv.clientID = userReport.clientID;
        userReport.itv.reportID = isUpdate ? dataReport.itv.reportID : -1;//PATCH Création ITV //0
        //userReport.itv.length = 1.0;
        userReport.itv.statusID = isUpdate ? dataReport.itv.statusID : 8;

        userReport.itv.employee = null;

        //INFO : changer le reportID et le siteID du planning_events pour racoller avec le rapport



        var tempReport = commonFunc.mergeDeep({}, dataReport, userReport);
        console.debug(dataReport);
        console.debug(tempReport);



        console.debug(userReport);
        //Swal.close();//TEMP
        //return new Promise((resolve, reject) => { resolve({ isSuccess: false }); });//TEMP
        return commonFunc.postMethod(isUpdate ? "../api/report/UpdateReport" : "../api/report/CreateReport", userReport).then((response) => {
            Swal.close();
                var data = response.data;
                if (data && data.isSuccess) {

                    var dlModal;

                    var callbackSaveProc = async function (finalFiles, errors) {
                        return new Promise((resolve, reject) => {

                            if (dlModal) {
                                dlModal.unmount();
                                dlModal = null;
                            }

                            Swal.fire({
                                icon: errors && errors.length ? "warning" : "success",
                                title: "Succès",
                                html: (isUpdate ? "Modification" : "Création") + " effectuée avec succès."
                                    + (errors && errors.length ? ("<br><b>Certains fichiers n'ont pas pu être mis en ligne :</b><br/>" + errors.map((errorFile) => errorFile.name + " : " + errorFile.error) + "<br>") : ""),
                                didOpen: () => {
                                    Swal.hideLoading();

                                    /*if (errorFiles.length) {
                                        $(".swal2-content").append("<br><b>Certains fichiers n'ont pas pu être envoyés. Transférer les manuellement.</b>");
                                        $.each(errorFiles, function (i, e) {
                                            var div = $("<div class='text-danger' style='font-size:90%'>" + $(e).find(".file-name").text().replace($(e).find(".file-name small").text(), "") + "</div>");
                                            $(".swal2-content").append(div);
                                        });
                                    }*/
                                },
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                            }).then((result) => {
                                /*context.getReports(0, true, () => {
                                    showDtSelID("tableReports", data.result.idReport);
                                });*/
                                if (context.modalRoot) {
                                    context.modalRoot.unmount();
                                    context.modalRoot = null;
                                }
                                context.loadReport(data.result.idReport);//userReport.idReport
                            });

                            data.result.idResponse = data.result.idReport;
                            resolve(data.result);
                        });
                    };


                    if (userReport.localFiles && userReport.localFiles.length) {
                        dlModal = createRoot(document.createElement('div'));
                        dlModal.render(<ExtUpload url={`../api/report/UploadReportFiles`} bindID={data.result.idReport} fileTypeID={"REPORT"} files={userReport.localFiles} callback={callbackSaveProc} />);
                    }
                    else {
                        callbackSaveProc();
                    }
                    
                } else {
                    var supErrors = "";
                    if (data.errors && data.errors.length) {
                        supErrors = data.errors.map((error) => {
                            return "<br>" + error.desc + "(" + error.value + ")";
                        });
                    }
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Echec de la modification :<br>" + data.errorMessage + supErrors
                    });

                    return new Promise((resolve, reject) => {
                        resolve(data);
                    });
                }
        });
    }

   assignDlgItv(report, callback) {
        var itvAssign = {};
       itvAssign.reportID = report.idReport;
       //itvAssign.reportItvID = report.idReport;
       //itvAssign.isSet = true;
       itvAssign.serviceID = report.serviceID;

        itvAssign.length = report.itv.length;

        /*var optionData = $("#aupdDlgRepLstSvc option:selected").data("cacheemp");
        switch (optionData.tag) {
            case "H":
                itvAssign.StartDate = stringToDate((isBlank($("#consDlg_site_sd").val()) ? "01/01/1970" : $("#consDlg_site_sd").val()) + " " + (isBlank($("#consDlg_site_sh").val()) ? "00:00" : $("#consDlg_site_sh").val()));
                itvAssign.EndDate = addMinutes(itvAssign.StartDate, itv.Length * 60);
                break;

            case "D", "DH":
                var valueSdDH = (isBlank($("#aupdItvSdDH").val()) ? "01/01/1970" : $("#aupdItvSdDH").val());
                switch (parseInt($("#aupdItvSdDHC").val())) {
                    case 0: //AM
                        itvAssign.StartDate = stringToDate(valueSdDH + ' 00:00');
                        itvAssign.EndDate = stringToDate(valueSdDH + ' 13:00');
                        break;
                    case 1:
                        itvAssign.StartDate = stringToDate(valueSdDH + ' 13:00');
                        itvAssign.EndDate = stringToDate(valueSdDH + ' 23:59');
                        break;
                    case 2:
                        itvAssign.StartDate = stringToDate(valueSdDH + ' 00:00');
                        itvAssign.EndDate = stringToDate(valueSdDH + ' 23:59');
                        break;

                }
                break;

            case "J":
                itvAssign.StartDate = stringToDate((isBlank($("#aupdItvSdJ").val()) ? "01/01/1970" : $("#aupdItvSdJ").val()) + ' 00:00');
                itvAssign.EndDate = stringToDate((isBlank($("#aupdItvEdJ").val()) ? "01/01/1970" : $("#aupdItvEdJ").val()) + ' 23:59');
                break;


            case "C", "DJ":
                var valueSdDJ = (isBlank($("#aupdItvSdDJ").val()) ? "01/01/1970" : $("#aupdItvSdDJ").val());
                switch (parseInt($("#aupdItvSdDJC").val())) {
                    case 0: //AM
                        itvAssign.StartDate = stringToDate(valueSdDJ + ' 00:00');
                        break;
                    case 1:
                        itvAssign.StartDate = stringToDate(valueSdDJ + ' 13:00');
                        break;
                    case 2:
                        itvAssign.StartDate = stringToDate(valueSdDJ + ' 00:00');
                        break;

                }
            
                var valueEdDJ = (isBlank($("#aupdItvEdDJ").val()) ? "01/01/1970" : $("#aupdItvEdDJ").val());
                switch (parseInt($("#aupdItvEdDJC").val())) {
                    case 0: //AM
                        itvAssign.EndDate = stringToDate(valueEdDJ + ' 13:00');
                        break;
                    case 1:
                        itvAssign.EndDate = stringToDate(valueEdDJ + ' 23:59');
                        break;
                    case 2:
                        itvAssign.EndDate = stringToDate(valueEdDJ + ' 23:59');
                        break;

                }
                break;


            case "JH":
                itvAssign.StartDate = stringToDate((isBlank($("#aupdItvSdJH").val()) ? "01/01/1970" : $("#aupdItvSdJH").val()) + ' ' + (isBlank($("#aupdItvShJH").val()) ? "00:00" : $("#aupdItvShJH").val()));
                itvAssign.EndDate = stringToDate((isBlank($("#aupdItvEdJH").val()) ? "01/01/1970" : $("#aupdItvEdJH").val()) + ' ' + (isBlank($("#aupdItvEhJH").val()) ? "00:00" : $("#aupdItvEhJH").val()));
                break;

        }*/
       itvAssign.startDate = report.itv.startDate;
       itvAssign.endDate = report.itv.endDate;


        itvAssign.employeeID = report.itv.employeeID;
       itvAssign.name = report.itv.employeeName;
       itvAssign.title = report.client.name + " [" + report.refID + "]" + "    " + report.address.city;
       itvAssign.comments = report.address.addressMain + " " + report.address.addressPlus + " " + report.address.addressPlus2 + " " + report.address.pc + " " + report.address.city;

       /*if (report.employee) {
           itvAssign.phone = report.employee.gsm;
           itvAssign.tel = report.employee.tel;
       }*/

       //itvAssign.email = "";
       //itvAssign.version = report.refIDVersion;


       //TODO //TEMP : waiting ScheduleItvReport implementation
       //if (callback && typeof (callback) === "function") { callback(null); }


       //Assignation
       return commonFunc.postMethod("../api/report/ScheduleItvReport", itvAssign).then((response) => {
           var data = response.data;
            if (data && data.isSuccess) {


                if (callback && typeof (callback) === "function") { callback(data); }

            } else {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: "Echec de l'assignation de l'intervenant",
                    html: "<p>" + data.errorMessage + "</p>",
                });
                if (callback && typeof (callback) === "function") { callback(data); }
            }
        });
    }

    removeReport(id, title) {
        var context = this;
        return new Promise((resolve, reject) => {
            var row = context.state.curReport;
            if ((!id && row) || (id && row && id === row.idReport)) {
                id = id || row.idReport;
                title = row.refID;
                var sd = commonFunc.restStringToDate(row.itv.startDate);
                if (commonFunc.isValidDate(sd) && sd.getFullYear() > 1970) {
                    title += " du " + commonFunc.formatDateTime(moment(sd).utc(true).toDate());
                }
            }
            if (parseInt(id) > 0) {
                Swal.fire({
                    icon: "question",
                    title: "Suppression",
                    html: "Etes-vous sûr de vouloir supprimer ce Rapport ?<br/>[" + id + "] " + title,
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Suppression du rapport en cours...',
                            didOpen: () => { Swal.showLoading() }
                        });
                        var req = { id: id, isPermanent: false };
                        commonFunc.postMethod("../api/report/RemoveReport", req).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data && data.isSuccess) {
                                context.getReports();//TODO : check si besoin de refresh supplémentaire
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: "Echec de suppression<br/>" + response.data.errorMessage
                                });
                            }
                            resolve(data);
                        });

                    }
                    else {
                        resolve({ isSuccess: true, result: "" });
                    }
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Aucune intervention n'est sélectionnée dans la liste"
                });
                resolve({ isSuccess: false, result: "Aucune intervention n'est sélectionnée dans la liste" });
            }
        });
    }


    updateReportStatus(statusId, stepId, customLabel) {
        var context = this;
        return new Promise((resolve, reject) => {
            var id = context.state.curReportId;
            if (parseInt(id) > 0) {
                if (statusId > 0) {
                    var label = "";
                    var reloadReport = true;

                    var curStatus = context.state.status.filter((status) => status.idStatus === statusId);
                    label = customLabel || (curStatus.length ? curStatus[0].name : "");

                    switch (statusId) {
                        case 206:
                            reloadReport = false;
                            break;
                        default:
                            break;
                    }


                    Swal.fire({
                        icon: "question",
                        title: "Statut Rapport",
                        html: "Voulez-vous marquer ce rapport comme " + label + " ?",
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Modification du statut du rapport...',
                                didOpen: () => { Swal.showLoading() },
                            });
                            var req = { id: id, newStatus: statusId, newStep: stepId || -1 };

                            commonFunc.postMethod("../api/report/UpdateStatus", req).then((response) => {

                                Swal.close();
                                if (response.data && response.data.isSuccess) {
                                    //if (reloadReport) context.loadReport(id);
                                    //context.getReports();//TODO : check si besoin de refresh supplémentaire
                                    /*context.getReports(0, true, () => {
                                        if (reloadReport) showDtSelID("tableReports", id);
                                    });*///TODO : check si besoin de refresh supplémentaire
                                    if (reloadReport) context.loadReport(id);
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de mise à jour du statut<br/>" + response.data.errorMessage
                                    });
                                }
                                resolve(response.data);
                            });
                        }
                        else {
                            resolve({ isSuccess: true, result: "" });
                        }
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Statut invalide"
                    });
                    resolve({ isSuccess: false, result: "Statut invalide" });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Aucune intervention n'est sélectionnée dans la liste"
                });
                resolve({ isSuccess: false, result: "Aucune intervention n'est sélectionnée dans la liste" });
            }
        });
    }

    initCreateReport() {
        if (this.state.editionMode) return;
        setDtNoSelect("tableReports");
        //this.reportGen.current.resetForm();
        this.setState({ title: "Création Intervention", curReportId: 0, curReport: null, isShowDetails: false, editionMode: true, activeTab: "1" });
    }

    switchTab(numTab) {
        if (this.state.editionMode) return;
        this.setState({ activeTab: numTab })
    }

    setEditingMode(value, isDuplicate) {
        var title = this.state.curReport ? (this.state.curReport.name + " " + this.state.curReport.firstName) : "";
        this.setState({ title: (isDuplicate ? "Duplication" : "Modification")  + " Intervention - " + title, editionMode: value });
    }

    showManualReport() {
        var context = this;

        var title = "Ajouter une Intervention/Pochette";

        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (data, isUpdate) {
            return new Promise((resolve, reject) => {
                if (data) {
                    context.addOrUpdateManualReport(data).then((dataResult) => {
                        if (dataResult && dataResult.idResponse) {
                            context.modalRoot.unmount();
                            resolve(dataResult);
                        }
                        else {
                            resolve(dataResult);
                        }
                    });
                }
                else {
                    context.modalRoot.unmount();
                    resolve();
                }
            });
        };
        if (this.reportModels["DLG_REPORT"]) {
            this.modalRoot = createRoot(document.createElement('div'));
            this.modalRoot.render(<ReportModal parentContext={this} title={title} configModel={this.reportModels["DLG_REPORT"]} modelsList={this.reportModels} callback={callbackModal}></ReportModal>);
        }
        else {
            Swal.fire({
                icon: "error",
                title: context.props.i18nManager.t("gal_error"),
                html: "Configuration introuvable"
            });
        }
    }

    showDraft() {
        
    }

    addOrUpdateManualReport(data, isUpdate) {

        //TODO : alimenter le sous-objet client avec les infos du 1er contact
        //name/firstname/tel/gsm/email/addresse

        return this.saveReport2(data, isUpdate).then((dataResult) => {
            if (dataResult && dataResult.idResponse) {
                if (this.modalRoot) {
                    this.modalRoot.unmount();
                    this.modalRoot = null;
                }
            }
        });

    }


    sendEmail() {
        var context = this;

        var id = this.state.curReportId;
        if (parseInt(id) > 0) {

            var args = {};
            var showProcessing = { flag: false };
            var shouldConfirmProcess = { flag: false };

            Swal.fire({
                icon: "question",
                title: context.props.i18nManager.t("gal_confirmTitle"),
                html: context.props.i18nManager.t("gal_confirmAction"),
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: context.props.i18nManager.t("gal_yes"),
                cancelButtonText: context.props.i18nManager.t("gal_no"),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: context.props.i18nManager.t("gal_processing", args),
                        html: "",
                        didOpen: () => { Swal.showLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });

                    args.bindID = parseInt(id);
                    commonFunc.postMethod("../api/report/SendReportEmail", args).then((response) => {
                        //commonFunc.getMethod("../api/employee/GetWithContractAsKeyItems", args).then((response) => {
                        if (showProcessing.flag) Swal.close();

                        var data = response.data;
                        if (data.isSuccess) {
                            Swal.fire({
                                icon: "success",
                                title: "Succès",
                                html: "Envoi de l'email effectuée avec succès.",
                                didOpen: () => {
                                    Swal.hideLoading();
                                },
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                            }).then((result) => {
                                //context.loadReport(data.result.idReport);//userReport.idReport
                            });
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de l'envoi du mail : " + data.errorMessage
                            });
                        }
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Echec",
                html: "Aucune intervention n'est sélectionnée dans la liste"
            });
        }
    }

    sendEmailDialog() {
        var context = this;

        var id = this.state.curReportId;
        if (parseInt(id) > 0) {
            var report = this.state.curReport || {};
            var title = "Dossier RFND • " + report.refID + report.refIDVersion + " • " + report.clientName + " • Réf ";// getDtValue("lstActs", id, "Name") + " " + getDtValue("lstActs", id, "FirstName");
            
            if (this.modalRoot) {
                this.modalRoot.unmount();
                this.modalRoot = null;
            }

            //Traitement des informations retourné par le Dialog
            var callbackModal = function () {
                //TODO
                /*Swal.fire({
                    title: 'Envoi de l\'Email en cours...',
                    onOpen: () => { Swal.showLoading() },
                });

                //targetMsgType
                var req = { target: id, targetType: 2, withCopy: $("#targetCopy").is(":checked"), emailTo: [], files: [], title: $("#targetSubject").val(), msg: $('#emailMsg').summernote('code') };

                $.each($("#targetContactsEmail").val(), function (index, dt) {
                    req.emailTo.push(dt);
                });

                $.each($("#targetFilesEmail").val(), function (index, dt) {
                    //req.files.push(dt);
                    //ADetailedBlobFile
                    console.debug($("#targetFilesEmail").find("option[value='" + dt + "']"));
                    var opt = $("#targetFilesEmail").find("option[value='" + dt + "']");
                    if (opt) {
                        var cache = opt.data("cachefile") || null;
                        if (cache) {
                            req.files.push(cache);
                        }
                    }


                });




                //emailTo: $("#targetEmail").val()

                $.ajax({
                    url: "../api/flows/SendCustomEmail",
                    type: "POST",
                    data: JSON.stringify(req),
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (data) {
                        Swal.close();
                        if (data && data.isSuccess) {
                            showBsDialogSwal("dlgModal", "Succès", "Email envoyé avec succès", { dialogInput: 5, dialogResult: 0 });
                        } else {
                            showBsDialogSwal("dlgModal", "Echec", "Echec de l'envoi de l'email <p>" + data.errorMessage + "</p>", { dialogInput: 4, dialogResult: 0 });
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        Swal.close();
                        showBsDialogSwal("dlgModal", "Echec", "Echec de l'envoi de l'Email<p>" + thrownError + "</p>", { dialogInput: 4, dialogResult: 0 });
                    }
                });*/

                context.modalRoot.unmount();
            };

            this.modalRoot = createRoot(document.createElement('div'));
            this.modalRoot.render(<EmailModal parentContext={this} title={"Envoyer un Email  " + title} reportId={id} callback={callbackModal}></EmailModal>);

        } else {
            Swal.fire({
                icon: "error",
                title: "Echec",
                html: "Aucune intervention n'est sélectionnée dans la liste"
            });
        }
    }
}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}


export default withRouter(withI18n(ManageReportsItv));





//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de ReportModal
function ReportModal({ title, parentContext, noScroll, configModel, modelsList, dataReport, callback }) {
    React.useEffect(() => {
        //console.debug("mount");

        //TODO : Check différence draft/ManualReport

        //if (parentContext.props.dataCfg && parentContext.props.dataCfg.dlgSup_enabledMClient) initClientList(null, 0);//TODO
        initReportModal();
    }, []);

    const [contacts, setContacts] = React.useState([]);
    const [files, setFiles] = React.useState([]);


    function initReportModal() {
        
    }

    //console.debug("render modal");


    var elemID = dataReport ? dataReport.idReport : 0;
    var elemName = "Rapport";
    var entity = "REPORT";

    //TODO : gérer le modal client en composant pour l'afficher dans ce modal
    configModel = JSON.parse(JSON.stringify(configModel));

    return (
        <Modal isOpen={true} className={`modal-report ${noScroll ? "modal-noscroll" : ""}`} size="xl" scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={{overflowX: "hidden"}}>
                <AGenerateModel parentProps={{ type: "report", dataCfg: parentContext.props.dataCfg }} ref={parentContext.modalGenManager} noButtons onSubmit={(data, isUpdate) => { if (files && files.length) { data.localFiles = files; } return callback(data, isUpdate) }} appProps={parentContext.props} checkRights={parentContext.props.checkRights} getRoutes={parentContext.props.getRoutes} configModel={configModel} modelsList={modelsList} loadedData={dataReport} />

                <FilesCustom className="reportDlg-files" title="FICHIERS JOINTS" bindID={elemID} checkRights={parentContext.props.checkRights} fileTypeID={entity} src={null} srcName={elemName}
                    files={files} setFiles={(compFiles) => { setFiles(compFiles) }}
                    noUploads={true} badgeRendering={false} withFilters={false} hideSync={true} shouldRender={true} onlyDelete={true} disabled={false} defaultFileType={"PJ"}
                    altFilesTypes={`../api/${entity}/Get${entity}FilesType`} altFilesUrl={`../api/${entity}/Get${entity}Files`}
                    altRemoveFilesUrl={`../api/${entity}/Remove${entity}File`} altUploadFilesUrl={`../api/${entity}/Upload${entity}Files`} />
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                {
                    dataReport ? <Button className="px-3" color="success" onClick={() => {
                        var generateModel = parentContext.modalGenManager.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm(true);
                        }
                    }}><i className="fa fa-edit"></i>Modifier</Button> : <Button className="px-3" color="success" onClick={() => {
                        var generateModel = parentContext.modalGenManager.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm();
                        }
                        }}><i className="fa fa-plus"></i>Ajouter</Button>
                }
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>Annuler</Button>
            </ModalFooter>
        </Modal>
    );
}







//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de EmailModal
function EmailModal({ title, reportId, callback }) {
    React.useEffect(() => {
        //console.debug("mount");

        initEmailModal();
    }, []);

    const [contacts, setContacts] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [emailStatus, setEmailStatus] = React.useState(<div className="col-lg-10 alert alert-sm wait" id="errorEmailAlert"><span className="input-icon">En Attente</span><span className="ms-3">Patientez SVP...</span></div>);
    const [template, setTemplate] = React.useState("");


    function initEmailModal() {
        var args = { id: reportId, isSource: false };

        commonFunc.getMethod("../weatherforecast/WFGetReportContacts", args).then((response) => {
            var data = response.data;

            //TEMP
            data = { "result": [{ "ID": 17482, "Title": " ", "Name": "Softena", "FirstName": "Test AG pour voir le rendu", "Address": "", "Address2": "", "Address3": "", "ContactType": "Contact Client", "Role": "CLIENT", "Phone": "20.02.20.02.02", "Gsm": "06.38.68.60.42", "Email": "test@test.tst", "RefExtID": "", "ClientID": 0, "ReportItvID": 9031033, "IsDefault": true, "IsNotified": false, "IsNotifiedSMS": false, "DSysMod": "2022-10-20T11:43:51", "DSysSync": "2022-10-20T11:43:39", "Note": "" }, { "ID": 17483, "Title": " ", "Name": "test", "FirstName": "test1", "Address": "2 rue des tests 53000 LAVAL", "Address2": "", "Address3": "", "ContactType": "Contact Client", "Role": "CLIENT", "Phone": "20.22.00.20.20", "Gsm": "06.66.00.66.00", "Email": "test@test.tst", "RefExtID": "", "ClientID": 0, "ReportItvID": 9031033, "IsDefault": false, "IsNotified": false, "IsNotifiedSMS": false, "DSysMod": "2022-10-20T11:43:51", "DSysSync": "2022-10-20T11:43:39", "Note": "" }, { "ID": 17484, "Title": " ", "Name": "test", "FirstName": "test2", "Address": "5 rue des test 35000 RENNES", "Address2": "", "Address3": "", "ContactType": "Voisin", "Role": "VOISIN", "Phone": "02.20.02.02.02", "Gsm": "06.06.60.06.06", "Email": "test2@tset.tst", "RefExtID": "", "ClientID": 0, "ReportItvID": 9031033, "IsDefault": false, "IsNotified": false, "IsNotifiedSMS": false, "DSysMod": "2022-10-20T11:43:51", "DSysSync": "2022-10-20T11:43:39", "Note": "" }], "count": 3, "queryTime": 138, "isSuccess": true, "comments": "", "errorCode": 0, "errorMessage": "", "timeticks": 601302258066 };


            if (data.isSuccess) {
                if (data.count > 0) {
                    setContacts(data.result);

                    /*var select = $("#targetContactsEmail");

                    var chosen = select.parent().find(".chosen-container");
                    chosen.find('input').prop("name", "emailrdi");
                    chosen.find('input').prop("autocomplete", "on");

                    //TODO
                    // Bind the keyup event to the search box input
                    chosen.find('input').on('keyup', function (e) {
                        // If we hit Enter and the results list is empty (no matches) add the option
                        //var reg = /[a-z]+@[a-z]+\.[a-z]+/;
                        if (e.which === 13 && chosen.find('li.no-results').length > 0) {
                            if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.value)) { // /[a-z]+@[a-z]+\.[a-z]+/
                                var option = $("<option>").val(this.value).text(this.value);
                                select.prepend(option);
                                select.find(option).prop('selected', true);
                                select.trigger("chosen:updated");
                            } else {

                            }
                        }
                    });
                    select.trigger("chosen:updated");*/

                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "Attention",
                        html: "Aucun contacts"
                    });
                }
            }
        });

        var args = { filetypeID: 0, bindID: args.id, fileID: -1 };//TEMP
        commonFunc.getMethod("../api/data/GetFiles", args).then((response) => {//GetReportFinalFiles
            var data = response.data;
            if (data.isSuccess) {
                if (data.count > 0) {
                    setFiles(data.result);
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: "Attention",
                        html: "Aucun fichiers"
                    });
                }
            }
        });

        commonFunc.getMethod("../weatherforecast/WFGetEmailStatus", null).then((response) => {
            if (response.data.isSuccess) {
                setEmailStatus (<div className="col-lg-10 alert alert-sm success alert-success" id="errorEmailAlert"><span className="input-icon">Actif</span><span className="ms-3"></span></div>);
            } else {
                setEmailStatus (<div className="col-lg-10 alert alert-sm failure alert-danger" id="errorEmailAlert"><span className="input-icon">InActif</span><span className="ms-3">{response.data.errorMessage}</span></div>);
            }
        });

        commonFunc.getMethod("../weatherforecast/WFLoadMailTemplate", { templateKey: 'EMAILSIG' }).then((response) => {
            if (response.data.isSuccess) {
                setTemplate("<br/><br/>" + response.data.result);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec du chargement du template l'email<br/>" + response.data.errorMessage
                });
            }
        });

        //TODO
        // summernote.paste
        /*$('#emailMsg').on('summernote.paste', function (e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
            e.preventDefault();
            if (bufferText && bufferText.length > 0) {
                bufferText = bufferText.replace(/\r?\n/g, '<br>');

                // Firefox fix
                setTimeout(function () {
                    document.execCommand('insertText', false, bufferText);
                }, 10);
            }

        });*/
    }

    //console.debug("render modal");

    return (
        <Modal isOpen={true} className="modal-email" size="xl" keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title || "Transfert de Fichiers"}</ModalHeader>
            <ModalBody className="px-2">
                <Row className="m-0 px-1">
                    <Col lg="12">

                    </Col>
                </Row>

                <div class="mail-box-header">
                    <div id="errorEmailAlertContainer" class="form-group row  fade show">
                        <label class="col-lg-2 align-self-center text-lg-right m-0">Etat du service:</label>
                        {emailStatus}
                    </div>

                    <div class="float-right tooltip-demo">
                    </div>
                </div>
                <div class="mail-box">
                    <form action="javascript:void(0)" id="frmsendmail" class="form-horizontal">
                        <div class="mail-body">


                            <div class="form-group row">
                                <label class="col-lg-2 align-self-center text-lg-right m-0">A:</label>
                                <div class="col-lg-8">
                                    <select name="targetContactsEmail" id="targetContactsEmail" multiple='multiple'
                                        data-placeholder='Choisissez un contact ou saisissez un Email' data-autocomplete="on" class="chosen-select-inherit chosen-minimized full-size-forced  email-target"
                                        required="required" aria-required="true">
                                        {contacts.map((contact) => {
                                            return contact.Email ? (<option value={contact.Email}>{contact.Name + " " + contact.FirstName + " (" + contact.ContactType + ")"}</option>) : "";
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-lg-2 align-self-center text-lg-right m-0">Objet:</label>
                                <div class="col-lg-10"><input type="text" id="targetSubject" placeholder="Objet" name="Objet" value="" class="form-control input-icon" minlength="2" required="required" aria-required="true" /></div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-2 align-self-center text-lg-right m-0">Fichiers:</label>
                                <div class="col-lg-10">
                                    <select name="targetFilesEmail" id="targetFilesEmail" multiple='multiple'
                                        data-placeholder='Choisissez les fichiers à joindre' class="chosen-select-inherit chosen-minimized full-size-forced  email-files">
                                        {files.map((file) => {
                                            return (<option value={file.idFile} selected={file.categ === "PDF"}>{file.name + " (" + file.prefix + ")"}</option>);
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-2 align-self-center text-lg-right m-0">Copie cachée:</label>
                                <div class="col-lg-1"><input type="checkbox" id="targetCopy" value="" class="form-control text-left" checked="checked" disabled="disabled" /></div>
                                <label class="col-lg-3 align-self-center text-lg-right m-0">Type de message:</label>
                                <div class="col-lg-6">
                                    <select id="targetMsgType" class="chosen-select-inherit chosen-minimized full-size-forced"></select>
                                </div>
                            </div>

                        </div>
                        <div class="mail-text mb-3 h-200">
                            <div id="emailMsg"><p></p></div>
                            <div class="clearfix2"></div>
                        </div>
                        <button type="button" id="btnSaveEmailSig" class="btn btn-xs btn-light  btn-ok "><i class="fa fa-pencil-alt"></i>&nbsp;Définir signature</button>
                    </form>
                </div>

            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <Button className="px-3" color="secondary" onClick={() => { callback(); }}>Fermer</Button>
            </ModalFooter>
        </Modal>
    );
}