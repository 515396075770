import React from "react";
import { useNavigate } from "react-router-dom";
//import { useForm, Form } from "react-hook-form";
import axios from "axios";
//import parse from 'html-react-parser';

import { useTranslation } from "react-i18next";

// reactstrap components
import {
    Card, CardBody, CardHeader, CardTitle, CardText,
    Form,
    TabContent, Button,
    TabPane,
    Container,
    Row,
    Col,
    Label,
    Nav, NavItem, NavLink
} from "reactstrap";

import Swal from "sweetalert2";

import * as commonFunc from 'scripts/common';

function Subscription(props) {
    const { t } = useTranslation();

    React.useEffect(() => {
        document.body.classList.toggle("register-page");
        return function cleanup() {
            document.body.classList.toggle("register-page");
        };
    });

    const [currentCycle, setCurrentCycle] = React.useState(1);
    const [products, setProducts] = React.useState();
    const [payment, setPayment] = React.useState("");
    const [paymentUrl, setPaymentUrl] = React.useState("#");

    //const parse = require('html-react-parser');

    /*const {
        control,

        register,
        handleSubmit, trigger,
        formState: { isSubmitSuccessful, errors },
    } = useForm({
        // progressive: true, optional prop for progressive enhancement
    })*/

    const redirectFormSubmit = React.createRef(0);
    const redirectForm = React.createRef(0);

    React.useEffect(() => {
        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        //TEMP
        var products = [
            {
                "idProduct": 2,
                "name": "N/A",
                "description": "Oskala Core Pro",
                "descriptionAlt": "",
                "lang": "FR",
                "url": "",
                "embedVideo": "",
                "avatar": "",
                "isSpecial": false,
                "specialDesc": "",
                "isActive": false,
                "isOffer": false,
                "isBundle": false,
                "productCycleID": 2,
                "productCycle": {
                    "idCycle": 2,
                    "name": "Annuel",
                    "categ": "",
                    "length": 0,
                    "lengthPerDay": 0,
                    "isActive": true,
                    "isVisible": true,
                    "prices": [],
                    "parentID": null,
                    "parent": null
                },
                "categ": "ABO",
                "items": [
                    {
                        "idItem": 100,
                        "name": "Client",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 101,
                        "name": "Collaborateurs",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 102,
                        "name": "Providers",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 103,
                        "name": "Sites",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 104,
                        "name": "Plannings",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 105,
                        "name": "TG",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 106,
                        "name": "Rapports",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 107,
                        "name": "Matériels",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 1000,
                        "name": "Stockage de Fichiers",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "FUN",
                        "tag": "STORE",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 1003,
                        "name": "Base de Données Dédiée",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "FUN",
                        "tag": "DB",
                        "parentID": null,
                        "parent": null,
                        "isOption": true,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    }
                ],
                "itemsAssigned": [],
                "themeClass": "",
                "subSiteID": null,
                "subSite": null,
                "prices": [],
                "siteID": -1,
                "site": null,
                "dSysCreat": "2023-11-15T09:40:30.234494",
                "dSysMod": "2023-11-15T13:38:42.752738"
            },
            {
                "idProduct": 2,
                "name": "N/A",
                "description": "Oskala Core Pro",
                "descriptionAlt": "",
                "lang": "FR",
                "url": "",
                "embedVideo": "",
                "avatar": "",
                "isSpecial": false,
                "specialDesc": "",
                "isActive": false,
                "isOffer": false,
                "isBundle": false,
                "productCycleID": 2,
                "productCycle": {
                    "idCycle": 2,
                    "name": "Annuel",
                    "categ": "",
                    "length": 0,
                    "lengthPerDay": 0,
                    "isActive": true,
                    "isVisible": true,
                    "prices": [],
                    "parentID": null,
                    "parent": null
                },
                "categ": "ABO",
                "items": [
                    {
                        "idItem": 100,
                        "name": "Client",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 101,
                        "name": "Collaborateurs",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 102,
                        "name": "Providers",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 103,
                        "name": "Sites",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 104,
                        "name": "Plannings",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 105,
                        "name": "TG",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 106,
                        "name": "Rapports",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 107,
                        "name": "Matériels",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 1000,
                        "name": "Stockage de Fichiers",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "FUN",
                        "tag": "STORE",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 1003,
                        "name": "Base de Données Dédiée",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "FUN",
                        "tag": "DB",
                        "parentID": null,
                        "parent": null,
                        "isOption": true,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    }
                ],
                "itemsAssigned": [],
                "themeClass": "",
                "subSiteID": null,
                "subSite": null,
                "prices": [],
                "siteID": -1,
                "site": null,
                "dSysCreat": "2023-11-15T09:40:30.234494",
                "dSysMod": "2023-11-15T13:38:42.752738"
            },
            {
                "idProduct": 2,
                "name": "N/A",
                "description": "Oskala Core Pro",
                "descriptionAlt": "",
                "lang": "FR",
                "url": "",
                "embedVideo": "",
                "avatar": "",
                "isSpecial": false,
                "specialDesc": "",
                "isActive": false,
                "isOffer": false,
                "isBundle": false,
                "productCycleID": 2,
                "productCycle": {
                    "idCycle": 2,
                    "name": "Annuel",
                    "categ": "",
                    "length": 0,
                    "lengthPerDay": 0,
                    "isActive": true,
                    "isVisible": true,
                    "prices": [],
                    "parentID": null,
                    "parent": null
                },
                "categ": "ABO",
                "items": [
                    {
                        "idItem": 100,
                        "name": "Client",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 101,
                        "name": "Collaborateurs",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 102,
                        "name": "Providers",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 103,
                        "name": "Sites",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 104,
                        "name": "Plannings",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 105,
                        "name": "TG",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 106,
                        "name": "Rapports",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 107,
                        "name": "Matériels",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "MOD",
                        "tag": "",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 1000,
                        "name": "Stockage de Fichiers",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "FUN",
                        "tag": "STORE",
                        "parentID": null,
                        "parent": null,
                        "isOption": false,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    },
                    {
                        "idItem": 1003,
                        "name": "Base de Données Dédiée",
                        "description": "",
                        "descriptionAlt": "",
                        "avatar": "",
                        "categ": "FUN",
                        "tag": "DB",
                        "parentID": null,
                        "parent": null,
                        "isOption": true,
                        "products": [],
                        "productsAssigned": [],
                        "isBundle": false,
                        "mainItemID": null,
                        "mainItem": null,
                        "indexDisplay": 0,
                        "isBillable": false,
                        "categoryID": null,
                        "category": null,
                        "prices": []
                    }
                ],
                "itemsAssigned": [],
                "themeClass": "",
                "subSiteID": null,
                "subSite": null,
                "prices": [],
                "siteID": -1,
                "site": null,
                "dSysCreat": "2023-11-15T09:40:30.234494",
                "dSysMod": "2023-11-15T13:38:42.752738"
            }
        ];
        setProducts(products);
        Swal.close();

        commonFunc.getMethod("../api/GetActiveSubscription", { level: 2 }).then((response) => {
            var data = response.data;
            if (data.isSuccess) {
                console.log(data.result);

                Swal.close();

                setProducts([data.result.product]);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de chargement de les abonnements :<br>" + data.errorMessage
                });
            }
        });
    }, [currentCycle]);

    React.useEffect(() => {
        if (paymentUrl != "#" && redirectFormSubmit && redirectFormSubmit.current) { redirectFormSubmit.current.click(); }
    }, [paymentUrl]);

    const navigate = useNavigate();
    // const submit = useSubmit();



    async function triggerSubmit(data) {
        //e.preventDefault();

        //TODO : redirection vers inscription si pas de compte
        //OU retour à la page ManageSubscription

    }

    /*const fetchData = async (e,formData) => {
        //setStatus("fetching");
        const url = "/ExternalLogin/RedirectToPaymentBackend";
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "text/html",
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + localStorage.getItem("token") 
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            //setData(data);
            //setStatus("fetched");
        } catch (err) {
            //setData(err);
            //setStatus("failed");
        }
    };

    const onSubmit = e => {
        //e.preventDefault();

        const formData = fields.reduce((formData, field) => ({
            ...formData,
            [field]: e.target[field].value,
        }), {});
        fetchData(e);
    }*/

    /*const onSubmit = async (e) => {
        // Trigger validations before submitting
        const isValid = await trigger();

        if (isValid) {
            handleSubmit(onSubmit)();
        }
    };*/

    function subscribeToOffer(e, product) {
        var provider = "";
        var fingerprint = "";

        Swal.fire({
            title: "Souscription au produit...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        //window.location.assign('../ExternalLogin/RedirectToPaymentBackend?test=' + provider + '&deviceId=' + fingerprint);
        //return;

        commonFunc.postMethod("../api/GenerateInvoiceFromSubscription", { id: product.idProduct }).then((response) => {
            var data = response.data;
            if (data.isSuccess) {
                console.log(data.result);//URL Stripe ???
                setPaymentUrl(data.result.redirectUrl);
                setPayment(data.result.redirectPayload);

                console.debug(redirectFormSubmit.current);
                //redirectFormSubmit.current.click();
                Swal.close();
                //window.location.assign('../AccessStripe/AccessStripe?provider=' + provider + '&deviceId=' + fingerprint + '&offerId=' + product.idProduct);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de chargement de les abonnements :<br>" + data.errorMessage
                });
            }
        });
    }

    return (
        <div className="register-page">
            <Container className="mt-0">
                {props.getLoggedInInfo ? <Button className="btn-icon btn-round m-0" onClick={(e) => navigate(-1)}><i className="fa fa-arrow-left"></i></Button> : ""}
                <Row className="justify-content-center mb-3">
                    <Nav pills justified className="p-0" style={{ maxWidth: "350px" }}>
                        <NavItem>
                            <NavLink
                                active={currentCycle === 1 ? true : false}
                                onClick={() => setCurrentCycle(1)}
                                href="#"
                                className={`${currentCycle === 1 ? "text-light" : "text-dark"}`} >
                                Mensuel
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                active={currentCycle === 2 ? true : false}
                                onClick={() => setCurrentCycle(2)}
                                className={`${currentCycle === 2 ? "text-light" : "text-dark"}`} >
                                Annuel
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Row>
                <Row className="align-items-center">
                    {products ? products.map((product, index) => {
                        var price = 0;
                        var includedItem = "";
                        var nbModules = 0;

                        if (product.items) {
                            includedItem = product.items.map((item, indexItems) => {
                                if (item.categ === "MOD") nbModules++;
                                if (item.tag === "SUPPORT" || item.tag === "STORE_COUNT" || (item.categoryID === 2 || item.categoryID === 3)) {
                                    var includedRow = item.prices.filter((price, indexPrices) => price.baseFrom === 1);
                                    if (includedRow.length) {
                                        price += includedRow[0].minAmount;
                                        product.price = price;
                                    }
                                    else if (item.tag === "SUPPORT" || item.tag === "STORE_COUNT") {
                                        includedRow = [item];
                                    }
                                    return <div key={"itemIncluded" + indexItems}><b><i className="fa fa-check me-2"></i>{includedRow[0].name + " inclus"}</b> {includedRow[0].included < item.maxQuantity ? <>- {(item.maxQuantity + " max")}</> : ""}</div>;

                                }
                                return "";
                            });
                            price += " €"
                        }
                        else {
                            price = "N/A";
                        }
                        product.cycleName = (product.productCycleID === 1 ? "Mois" : "Année");

                        return (<Col key={index} className={`subscription-card subscription-${product.themeClass} ${true ? "is-recommanded" : ""}`} md="4">
                            <Card className="widget dash overflow-hidden">
                                <CardHeader>
                                    <CardTitle tag="h6" className="m-0">{product.name + " " + product.description}</CardTitle>
                                    <div className="widget-tools collapse-tool d-none">
                                        <i className="fa fa-file-contract fa-4x"></i>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <p className="subscription-price-label">A partir de</p>
                                    <div className="subscription-price-container">
                                        <div className="subscription-price">{price}<div className="subscription-priceSup">HT/{product.cycleName}</div></div>
                                        {product.productCycleID === 1 ? <div className="subscription-cycle">Sans engagement - Résiliable en 1 clic</div>
                                            : <div className="subscription-cycle">Engagement sur 1 an</div>}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Card style={{ maxWidth: "250px" }}>
                                            <img
                                                className="img-fluid subscription-img"
                                                alt="..."
                                                src={product.avatar || require("assets/img/bg/logooskala.png")}
                                                style={{ borderRadius: "12px" }}
                                            />
                                        </Card>
                                    </div>
                                    <CardText>{product.descriptionAlt}</CardText>
                                    <Row>
                                        <Col md="12" className="text-center">
                                            <Button className={`btn-round btn-self-magnify ${product.themeClass}`} color="primary" onClick={(e) => subscribeToOffer(e, product)}>Souscrire</Button>
                                        </Col>
                                        <Form
                                            className="d-none"
                                            ref={redirectForm}
                                            action={paymentUrl}
                                            method="POST"
                                        >
                                            <div className="content" dangerouslySetInnerHTML={{ __html: payment }}></div>
                                            <button
                                                ref={redirectFormSubmit}
                                                className="active:bg-blue-600 hover:shadow-lg focus:outline-none px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none"
                                                type="submit"
                                            >
                                                Send a message
                                            </button>
                                        </Form>
                                    </Row>
                                    <hr className="hr-line-solid mb-0" />
                                    <Row>
                                        <div className="mt-2">
                                            <b><i className="fa fa-check me-2"></i>{nbModules + " Modules inclus"}</b>
                                            {includedItem}
                                        </div>
                                    </Row>
                                    <Row className="d-none">
                                        <div className="mt-2">
                                            Comprend :
                                            <ul>
                                                {product.items ? product.items.map((item, indexItems) => {
                                                    if (item.categ === "MOD" && !item.isOption) {
                                                        return <li key={"item" + indexItems}>{item.name}</li>
                                                    }
                                                    else {
                                                        return "";
                                                    }
                                                }) : ""}
                                            </ul>
                                        </div>
                                    </Row>
                                </CardBody>
                                <div className='subscription-tag'><div><span>RECOMMANDÉ</span></div></div>
                            </Card>
                        </Col>);
                    }) : ""}

                </Row>
                <Row>
                    <table className="table table-bordered text-center">
                        <thead>
                            <tr>
                                <th>Fonctionnalités</th>
                                {products ? products.map((product, index) => {
                                    return <th className={`subTab-${product.themeClass}`}>{product.name + " " + product.description}<br />{product.price} HT/{product.cycleName}<br /><Button className={`btn-round ${product.themeClass}`} color="primary">Souscrire</Button></th>;
                                }) : ""}
                                <th>Offre Team<br />15 € HT/Mois<br /><Button className="btn-round" color="primary">Souscrire</Button></th>
                                <th>Offre Pro<br />1000 € HT/Mois<br /><Button className="btn-round" color="primary">Souscrire</Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-start" colSpan="4">Modules</td>
                            </tr>
                            <tr>
                                {products ? products.map((product, index) => {
                                    return <td>{product.items ? product.items.map((item, indexItems) => {
                                        if (item.categ === "MOD" && !item.isOption) {
                                            return <li key={"item" + indexItems}>{item.name}</li>
                                        }
                                        else {
                                            return "";
                                        }
                                    }) : ""}</td>;
                                }) : ""}
                            </tr>
                            <tr>
                                <td className="text-start">Planning</td>
                                <td><i className="fa fa-check-circle"></i></td>
                                <td><i className="fa fa-check-circle"></i></td>
                                <td><i className="fa fa-check-circle"></i></td>
                            </tr>
                            <tr>
                                <td className="text-start">Clients</td>
                                <td></td>
                                <td><i className="fa fa-check-circle"></i></td>
                                <td><i className="fa fa-check-circle"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("assets/img/bg/backblurt3.jpg")})`,
                }}
            />
        </div>
    );
}

export default Subscription;
