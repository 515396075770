import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import {
    Button, Input, Label,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form
} from "reactstrap";

import FilesCustom from 'components/FilesCustom';
import ShowDetails from 'components/ShowDetails';
import Historical from 'components/Historical';

import CNavbar from 'components/CNavbar';

import * as commonFunc from 'scripts/common';
import CDataTable, { showDtPrevID, showDtSelID, showDtNextID, setDtNoSelect } from "components/CDataTable";

import { withRouter } from 'components/withRouter';

class ManageSites extends Component {
    static displayName = ManageSites.name;

    static siteSchema;
    static siteModels;
    static refreshCb;
    static modalRoot;

    constructor(props) {
        super(props);

        this.siteGen = React.createRef(null);

        this.state = { sites: [], curSite: null, curSiteId: 0, loading: true, dataStamp: 0, activeTab: "1", title: props.i18nManager.t("site.noSelected"), editionMode: false };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function (curUser) {

            commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: "SITES" }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {
                    commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: "CONTACT" }).then((responseModelsC) => {
                        var dataModelsC = responseModelsC.data;
                        if (dataModelsC.isSuccess) {

                            context.siteModels = {};
                            dataModels.result.models.forEach((model) => {
                                context.siteModels[model.extID] = JSON.parse(model.value);
                            });
                            dataModelsC.result.models.forEach((model) => {
                                context.siteModels[model.extID] = JSON.parse(model.value);
                            });
                            /*commonFunc.getMethod("../api/site/GetSchemaModel", null).then((response) => {
                                var dataSchema = response.data
                                if (dataSchema.isSuccess) {

                                    context.siteSchema = dataSchema.result;*/

                                    //Chargement via url
                                    var defSiteLoadId = 0;
                                    var defSiteActionId = 0;
                                    if (context.props.searchParams) {
                                        defSiteLoadId = parseInt(context.props.searchParams.get("siteId")) || 0;
                                        defSiteActionId = parseInt(context.props.searchParams.get("actionId")) || 0;
                                    }
                                    if (defSiteLoadId > 0) {
                                        context.getSites(0, false, function () {
                                            /*$("#lstActs_filter input[type='search']").val(defReportLoadId);
                                            showDtSelID("lstActs", defReportLoadId);
                                            $('html, body').animate({
                                                scrollTop: $("#detailAct").offset().top
                                            }, 500);

                                            if (defSiteActionId == 2) {
                                                $(".action-editReport:visible").click();
                                            }*/
                                        });
                                    } else {
                                        context.getSites();

                                        /*if (defSiteActionId == 1) {
                                            $(".action-addReport:visible").click();
                                        }*/
                                    }

                                /*}
                                else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Erreur",
                                        html: "Erreur lors de la récupération du schéma site"
                                    });
                                }
                            });*/
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: context.props.i18nManager.t("gal_fail"),
                                html: context.props.i18nManager.t("gal_failLoadModels") + ". :<br>" + dataModels.errorMessage
                            });
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModels") + " :<br>" + dataModels.errorMessage
                    });
                }
            });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");

        //Update Scroller Size
        if (prevState.isShowDetails && !this.state.isShowDetails) {
            if (this.props.getScroller) this.props.getScroller().update();
        }

        //Callback initCreateSite
        if (this.state.curSiteId === 0 /*&& prevState.curSiteId !== this.state.curSiteId*/ && this.state.editionMode && !this.state.isShowDetails) {
            this.setState({ isShowDetails: true });
        }
        if (prevState.editionMode !== this.state.editionMode && this.dtFunctions && this.dtFunctions["tableSites"]) this.dtFunctions["tableSites"].setDisabled(this.state.editionMode);
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount(); 
            this.modalRoot = null;
        }
    }

    dtFunctionPanel(id, fctPanel) {
        if (!this.dtFunctions) this.dtFunctions = {};
        if (!this.dtFunctions[id]) this.dtFunctions[id] = {};
        this.dtFunctions[id] = fctPanel;
    }

    render() {
        var context = this;

        var listCb = function () {
            if (context.refreshCb) {
                if (context.refreshCb && typeof (context.refreshCb) === "function") { context.refreshCb(); }
                context.refreshCb = null;
            }
        };

        //TODO : createdCell >> dépendance du contexte pour éxecution de la fonction
        //TEMP : doit dépendre d'une configuration (tout virer sauf createdCell et cbInitComplete)
        var dtConfig = {
            columnDefs: [
                {
                    createdCell: (td, cellData, rowData, row, col) => {
                        var currRoot = createRoot(td);
                        currRoot.render(<Button className='btn-icon btn-simple m-0' color="dark" size="sm" disabled={this.state.editionMode}
                            onClick={() => context.loadSite(rowData.idSite, rowData)} title={context.props.i18nManager.t("gal_show")} ><i className='fa fa-eye'></i></Button>);
                    }, "targets": "renderingShow"
                }
            ],
            dtOnSelect: function (dataSel) {
                var id = dataSel.idSite;
                if (parseInt(id) > 0) {
                    if (!context.state.editionMode) context.loadSite(id, dataSel);
                }
            },
            dtOnUnselect: function (dataSel) {
                if (!context.state.editionMode) context.loadSite();
            },
            dtOnDblClick: function (event) {
                /*var id = parseInt(getDtSelID("lstActs"));
                var currId = parseInt($(this).closest("tr").prop("id"));
                if (currId != id) {
                    scrollToId("detailAct");
                    showDtSelID("lstActs", currId);
                }
                else {
                    if (currId > 0) {
                        scrollToId("detailAct");
                    }
                }*/
            },
            cbInitComplete: function (cRootList) {
                //Swal.close();

                /*cRootList.map((prop, elem) => {
                    console.debug(elem);
                    if (elem.classList.contains("cneedroot")) {
                        var rootElem = createRoot(elem);
                        switch (elem.getAttribute("cneedroot")) {
                            case "forceLogout":
                                rootElem.render(<button className="btn btn-primary btn-icon" onClick={() => context.forceLogout('hey!')}><i className='fa fa-edit'></i></button>);
                                break;
                            default:
                                break;
                        }
                    }
                    return null;
                });*/

                //$("#report_select_filters").appendTo($(".dt-buttons").parent().parent());
                //$("#report_select_filters").removeClass("d-none").addClass("d-inline-block");

                //updateSidebar();

                listCb();
            },
            cbCustom: function () {
                listCb();
            }
        };

        //console.debug("render again");

        let contents = this.state.loading ? <p><em>{context.props.i18nManager.t("gal_loading")}</em></p> :
            <div>
                {this.siteModels ? <CDataTable key="sites" dtId={"tableSites"} data={this.state.sites} localConfig={dtConfig} tag={"DT_SITS"} config={this.siteModels["DT_SITS"]} stamp={this.state.dataStamp} functionPanel={this.dtFunctionPanel.bind(this)}
                    className="table-striped table-hover nowrap" style={{ fontSize: "90%" }} /> : ""}
            </div>;


        return (
            <div className="tableDetails">
                <Card className={`tableContainer widget dash ${this.state.editionMode ? "module-disabled" : ""}`}>
                    <CardHeader>
                        <CardTitle tag="h6">{context.props.i18nManager.t("site.title")}</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-table-list fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <div className="float-start">
                            <Button className="btn-rotate px-2 btn-simple mt-0" disabled={this.state.editionMode} color="dark" size="sm" onClick={() => this.getSites()}>
                                <i className="fa fa-sync"></i>{context.props.i18nManager.t("gal_refresh")}
                            </Button>
                            <Button className="btn-magnify px-2 mt-0 d-none" disabled={this.state.editionMode} color="success" size="sm" onClick={() => this.initCreateSite()}>
                                <i className="fa fa-plus-square"></i>{context.props.i18nManager.t("site.add")}
                            </Button>
                        </div>
                        {contents}
                    </CardBody>
                </Card>

                {this.state.isShowDetails ? (<div className="detailsContainer">
                    <Card className="widget dash text-white" color="theme">
                        <CardHeader>
                            <Row>
                                <Col lg="10" className="offset-lg-1">
                                    <CardTitle tag="h6" className="text-center">
                                        {this.state.title}
                                    </CardTitle>
                                </Col>
                                <Col lg="1" className="d-flex text-end float-end justify-content-end">
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActPrev" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curSiteId || 0;
                                        showDtPrevID("tableSites", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-left"></i></Button>
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActNext" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curSiteId || 0;
                                        showDtNextID("tableSites", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-right"></i></Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Nav pills className={`${this.state.editionMode ? "invisible" : ""}`}>
                                {this.props.checkRights("SITE", "R") ? <NavItem><NavLink className={`${this.state.activeTab === '1' ? "active" : ""}`} onClick={() => { this.switchTab("1") }}>{context.props.i18nManager.t("site.tabDetails")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("SITE_CONTACTS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '2' ? "active" : ""}`} onClick={() => { this.switchTab("2") }}>{context.props.i18nManager.t("site.tabContacts")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("SITE_FILES", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '3' ? "active" : ""}`} onClick={() => { this.switchTab("3") }}>{context.props.i18nManager.t("site.tabDocs")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("SITE_FLOWS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '4' ? "active" : ""}`} onClick={() => { this.switchTab("4") }}>{context.props.i18nManager.t("site.tabFlows")}</NavLink></NavItem> : ""}
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.props.checkRights("SITE", "R") ? <TabPane tabId="1">
                            <ShowDetails id={this.state.curSiteId} type="site" genRef={this.siteGen} data={this.state.curSite} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.siteModels ? this.siteModels["SITE"] : {}} modelsList={this.siteModels} loadElem={this.loadSite.bind(this)} saveElem={this.saveSite.bind(this)} removeElem={this.removeSite.bind(this)} setEditingMode={this.setEditingMode.bind(this)} formEditing={this.state.editionMode} shouldRender={this.state.activeTab === '1'} />
                        </TabPane> : ""}
                        {this.props.checkRights("SITE_CONTACTS", "L") ? <TabPane tabId="2">
                            <ShowDetails id={this.state.curSiteId} type="siteContacts" data={this.state.curSite} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.siteModels ? this.siteModels["CONTACTS"] : {}} modelsList={this.siteModels} shouldRender={this.state.activeTab === '2'} />
                        </TabPane> : ""}
                        {this.props.checkRights("SITE_FILES", "L") ? <TabPane id="aupdAct-3" tabId="3">
                            <Form id="frmaupdact3">
                                <FilesCustom className="site-files" bindID={this.state.curSiteId} rightType="SITE_FILES" checkRights={this.props.checkRights} fileTypeID="SITE" src={this.state.curSite} srcName={this.state.curSite ? this.state.curSite.name : ""} withFilters={true} shouldRender={this.state.activeTab === '3'} altFilesTypes="../api/site/GetSiteFilesType" altFilesUrl="../api/site/GetSiteFiles" altRemoveFilesUrl="../api/site/RemoveSiteFile" altUploadFilesUrl="../api/site/UploadSiteFiles" />
                            </Form>
                        </TabPane> : ""}
                        {this.props.checkRights("SITE_FLOWS", "L") ? <TabPane tabId="4">
                            <Row className="site-history">
                                <Col lg="12">
                                    <Card className="widget dash">
                                        <CardHeader>
                                            <CardTitle tag="h6">{context.props.i18nManager.t("site.flowsTitle")}</CardTitle>
                                            <div className="widget-tools collapse-tool">
                                                <i className="fa fa-history fa-4x"></i>
                                            </div>
                                            <div className='borderTheme'></div>
                                        </CardHeader>
                                        <CardBody>
                                            <Historical type="site" elemId={context.state.curSiteId} url="../api/site/GetSiteFlows" getRoutes={context.props.getRoutes} shouldRender={context.state.activeTab === '4'} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane> : ""}
                    </TabContent></div>) : ""}
            </div>
        );
    }

    async getSites(statusId, forcedUpdate, callback) {
        if (!forcedUpdate && this.state.editionMode) return;
        this.setState({ sites: [], curServiceId: 0, curService: {}/*, loading: true*/, dataStamp: 0, isShowDetails: false, editionMode: false });
        var context = this;

        if (callback) {
            context.refreshCb = callback;
        }

        Swal.fire({
            title: context.props.i18nManager.t("gal_loading"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var args = {};
        commonFunc.getMethod("../api/site/GetSites", args).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {

                    /*$('#lstActs thead th').each(function () {
                        var title = $(this).text();
                        if ($(this).hasClass("searchable")) {
                            $(this).append('<input type="text" class="form-control col-lg-12 form-control-sm" placeholder="' + title + '" />');
                        }
                    });*/

                    Swal.close();
                    context.setState({ sites: response.data.result, loading: false, dataStamp: new Date().getTime(), isShowDetails: false });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_error"),
                        html: context.props.i18nManager.t("site.failLoadSites") + " :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_error"),
                    html: context.props.i18nManager.t("site.failLoadSites")
                });
            }
        });

    }

    async loadSite(id) {
        var context = this;

        var callback = this.loadReportCb;
        this.loadReportCb = null;

        this.setState({ title: context.props.i18nManager.t("site.noSelected"), curSiteId: 0, curSite: null, isShowDetails: (id > 0), editionMode: false });

        return new Promise((resolve, reject) => {

            var args = {};
            //args.state = 201;
            args.id = id;
            if (parseInt(id) > 0) {
                Swal.fire({
                    title: context.props.i18nManager.t("gal_loading"),
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                commonFunc.getMethod("../api/site/GetSite", args).then((response) => {
                    var data = response.data;
                    if (data.isSuccess) {
                        console.log(data.result);

                        if (callback && typeof (callback) === "function") { callback(data); }

                        Swal.close();

                        if (context.state.isShowDetails) context.setState({ title: (data.result.name + " " + data.result.nameAlt + "  •  " + data.result.categ), curSiteId: id, curSite: data.result, isShowDetails: true });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: context.props.i18nManager.t("gal_error"),
                            html: context.props.i18nManager.t("site.failLoadSite") + " :<br>" + data.errorMessage
                        });
                    }
                    resolve(data);
                });

            }
            else {
                resolve({ isSuccess: true, result: "" });
            }
        });
    }

    async saveSite(userSite, isUpdate) {
        var context = this;
        const dataSite = this.state.curSite || {};

        Swal.fire({
            title: context.props.i18nManager.t("gal_saving"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        if (userSite.extras && userSite.extras.length) {
            userSite.extras.forEach((value, index) => {
                value.extraSiteID = userSite.idSite;
            });
        }


        if (!userSite.avoidMerge) {
            userSite = commonFunc.mergeDeep({}, dataSite, userSite);

            /*userSite.contacts = null;
            userSite.files = null;
            userSite.filesSite = null;
            userSite.groups = null;
            userSite.models = null;*/
            userSite.parent = null;
        }

        console.debug(userSite);
        //return new Promise((resolve, reject) => { resolve({ isSuccess: false }); });//TEMP
        return commonFunc.postMethod(isUpdate ? "../api/site/UpdateSite" : "../api/site/CreateSite", userSite).then((response) => {
            Swal.close();
            return new Promise((resolve, reject) => {
                var data = response.data;
                if (data && data.isSuccess) {
                    Swal.fire({
                        icon: "success",
                        title: context.props.i18nManager.t("gal_success"),
                        html: (isUpdate ? context.props.i18nManager.t("gal_successUpd") : context.props.i18nManager.t("gal_successAdd")),
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        /*context.getSites(0, true, () => {
                            showDtSelID("tableSites", data.result.idSite);
                        });*/
                        context.loadSite(data.result.idSite);//userSite.idSite
                    });

                    data.result.idResponse = data.result.idSite;
                    resolve(data.result);

                } else {
                    var supErrors = "";
                    if (data.errors && data.errors.length) {
                        supErrors = data.errors.map((error) => {
                            return "<br>" + error.desc + "(" + error.value + ")";
                        });
                    }
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_errorSave") + " :<br>" + data.errorMessage + supErrors
                    });
                    resolve(data);
                }
            });
        });
    }

    removeSite(id, title) {
        var context = this;
        return new Promise((resolve, reject) => {
            var row = context.state.curSite;
            if ((!id && row) || (id && row && id === row.idSite)) {
                id = id || row.idSite;
                title = row.name + " " + row.firstName;
            }
            if (parseInt(id) > 0) {
                Swal.fire({
                    icon: "question",
                    title: context.props.i18nManager.t("gal_removing"),
                    html: context.props.i18nManager.t("site.rmvTitle") + "<br/>[" + id + "] " + title,
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: context.props.i18nManager.t("gal_yes"),
                    cancelButtonText: context.props.i18nManager.t("gal_no"),
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: context.props.i18nManager.t("site.removing"),
                            didOpen: () => { Swal.showLoading() }
                        });
                        var req = { id: id, isPermanent: false };
                        commonFunc.postMethod("../api/site/RemoveSite", req).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data && data.isSuccess) {
                                context.getSites();//TODO : check si besoin de refresh supplémentaire
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: context.props.i18nManager.t("gal_fail"),
                                    html: context.props.i18nManager.t("gal_errorRmv") + "<br/>" + response.data.errorMessage
                                });
                            }
                            resolve(data);
                        });

                    }
                    else {
                        resolve({ isSuccess: true, result: "" });
                    }
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_fail"),
                    html: context.props.i18nManager.t("gal_noSelectedElem")
                });
                resolve({ isSuccess: false, result: context.props.i18nManager.t("gal_noSelectedElem") });
            }
        });
    }

    initCreateSite() {
        if (this.state.editionMode) return;
        setDtNoSelect("tableSites");
        //this.siteGen.current.resetForm();
        this.setState({ title: this.props.i18nManager.t("site.create"), curSiteId: 0, curSite: null, isShowDetails: false, editionMode: true, activeTab: "1" });
    }

    switchTab(numTab) {
        if (this.state.editionMode) return;
        this.setState({ activeTab: numTab })
    }

    setEditingMode(value, isDuplicate) {
        var title = this.state.curSite ? (this.state.curSite.name + " " + this.state.curSite.firstName) : "";
        this.setState({ title: (isDuplicate ? this.props.i18nManager.t("site.duplicate") : this.props.i18nManager.t("site.edit"))  + " - " + title, editionMode: value });
    }
}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}

export default withRouter(withI18n(ManageSites));
