import React from 'react';
import { Button } from 'reactstrap';

//import Datepicker from "react-datetime";
import Datepicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

function DPButton(props) {
    const CustomBtn = React.forwardRef(({ value, onClick }, ref) => (
        <Button {...props.btnProps} onClick={onClick} ref={ref}>{props.btnProps.children}</Button>
    ));

    function renderDay(props, currentDate, selectedDate) {
        return <>
            <td {...props}><span className="c-rdt-week">{currentDate.week()}</span>{currentDate.date()}</td>
        </>;
    }

    return <Datepicker {...props}
        customInput={<CustomBtn />} />;
}

export default DPButton;

