import React, { Component, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import * as commonFunc from 'scripts/common';
import { Editor } from 'components/TinyMCE-react';
//import AGenerateModel from './generic/AGenerateModel';
import Swal from "sweetalert2";
import axios from "axios";
import { GridStack } from 'gridstack';
import CGridstack from 'components/CGridstack';


export default class ModelEditor extends Component {
    static displayName = ModelEditor.name;

    static siteModels;

    static modalRoot;
    static user;

    constructor(props) {
        super(props);

        //var defaultGridStackData = [];

        var defaultGridStackData = [
            { id: "gs-storage", hidden: false, type: "HOME", title: "Fichiers Stockés", x: 0, y: 0, w: 3, h: 2 },

        ];
        this.state = { gridData: JSON.stringify(defaultGridStackData), render: false };
    }

    componentDidMount() {
        var context = this;
        if (this.props.updateUser) this.props.updateUser(function (curUser) {

            context.user = curUser;
            //"../api/report/GetReportModels", { id: -1, categ: "MOBILE", extId:"DTL_REPORT_NEW" }
            commonFunc.getMethod("../api/site/GetSiteModels", { id: -1 }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {

                    context.siteModels = {};
                    dataModels.result.models.forEach((model) => {
                        context.siteModels[model.extID] = JSON.parse(model.value);
                    });
                    context.setState({ render: !context.state.render });

                } else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModels") + " :<br>" + dataModels.errorMessage
                    });
                }
            });
        });
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }
    }

    render() {
        document.body.classList.add("sidebar-mini");

        return this.user ? (<>
            <h5>Editor Page</h5>
            <ModelEditorComponent {...this.props} type={"optionsEdit" + (this.user.groupSId || "")} data={this.state.gridData} configModel={this.siteModels} />
        </>
        ) : "";
    }

}


const ModelEditorComponent = React.forwardRef((props, ref) => {

    const gridRef = useRef(null);
    //const [grid, setGrid] = useState(null);
    //const [widgets, setWidgets] = useState([]);

    const widgetsData = [
        {
            id: "widget1",
            content: "<h3>Widget 1</h3><p>Contenu du widget 1</p>",
            x: 0,
            y: 0,
            width: 2,
            height: 2
        },
        {
            id: "widget2",
            content: "<h3>Widget 2</h3><p>Contenu du widget 2</p>",
            x: 2,
            y: 0,
            width: 2,
            height: 2
        }
    ];

    React.useEffect(() => {
        const gridInstance = GridStack.init({
            cellHeight: 80,
            float: true,
            resizable: { handles: 'e, se, s, sw, w' }
        }, gridRef.current);
        // setGrid(gridInstance);


        widgetsData.forEach(widget => {
            /*gridInstance.addWidget(`
        <div>
          <div class="grid-stack-item-content bg-primary">
            <Editor
              apiKey="YOUR_TINYMCE_API_KEY"
              initialValue="${widget.content}"
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
            />
          </div>
        </div>`,
                { x: widget.x, y: widget.y, width: widget.width, height: widget.height }
            );*/

            //gridInstance.addWidget(<CGridStack />);

        });

        return () => {
            gridInstance.destroy();
        };


    }, []);

    console.debug(props.data);
    console.debug(props.configModel);
    var gendata = null;
    if (props.configModel) {
        gendata = props.configModel["HOME"];
        if (gendata.templates) {
            gendata.templates.map((template) => {
                console.debug("**Template ");
                template.categories.map((category) => {
                    console.debug("****Category ");
                    category.headers.map((header) => {
                        console.debug("******Header ");
                        header.groups.map((group, index) => {
                            //Gestion des clé primaires/secondaires
                            //if (parentProps && parentProps.type && keyMode > 0 && index === 0) {
                            //}
                            console.debug("********Group ");
                            group.items.map((item) => {//children
                                console.debug("********@@Item ");
                            });
                        });
                    });
                });
            });
        }
    }


    return (
        <div>
            {gendata ? gendata.templates.map((template) => (
                //data={props.data}
                <CGridstack {...props} checkRights={props.checkRights} type={props.type}  configModel={props.configModel} className="" gridClass={""} actionsClass={"text-secondary"} />

            ))
                : ""}

            <div ref={gridRef} className="grid-stack2" />
        </div>
    );


    /*return (
        <div>
                <div>
                    {widgetsList.map(widget => (
                        <button key={widget.id} onClick={() => addWidget(widget)}>
                            Add {widget.id}
                        </button>
                    ))}
                </div>
                <div ref={gridRef} className="grid-stack" />
            </div>
            );*/

});