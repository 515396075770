import React from 'react';

import { useTranslation } from "react-i18next";


export default function ReportTabFrag({ data, modelFctManager }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    const [shouldRender, setShouldRender] = React.useState(false);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };

    var childs = "";
    if (data.templates) {
        childs = data.templates.map((child) => {
            return modelFctManager.componentTyped(child, { parentData: data, childs: data.templates, parentDisabled: data.disabled ? true : false });
        });
    }

    var frameClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    return <div data-name="ReportTabFrag" data-element={data.objType} className={`${frameClass}`}>{childs}</div>;
}