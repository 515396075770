import React, { useState, cloneElement } from 'react';
import { Navbar, NavbarToggler, Collapse, Button } from 'reactstrap';

import DataGrid, { RenderRowProps, Row as RDGRow, TreeDataGrid } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

import { groupBy as rowGrouper } from 'lodash-es';

import Select from 'react-select';
const CDataGrid = React.forwardRef((props, ref) => {
    const cptRef = ref || React.createRef();
    const gridRef = React.useRef();

    const [selectedGrouping, setSelectedGrouping] = useState(["none"]);
    const [expandedGroupIds, setExpandedGroupIds] = useState(new Set([]));
    const [sortedRows, setSortedRows] = useState(props.rows);
    const [sortedColumns, setSortedColumns] = useState(props.sortColumns || []);
    /*const [filters, setFilters] = useState(
        (): Filter => ({
            task: '',
            priority: 'Critical',
            issueType: 'All',
            developer: '',
            complete: undefined,
            enabled: true
        })
    );


    const filteredRows = () => {
        return props.rows;
    };

    function clearFilters() {
        setFilters({
            task: '',
            priority: 'All',
            issueType: 'All',
            developer: '',
            complete: undefined,
            enabled: true
        });
    }

    function toggleFilters() {
        setFilters((filters) => ({
            ...filters,
            enabled: !filters.enabled
        }));
    }*/

    React.useEffect(() => {
        setSortedRows(props.rows);
    }, [props.rows]);

    React.useImperativeHandle(cptRef, () => ({
        exportToCsvFromExt: (fileName) => { exportToCsv(treeGridContent, fileName); },
        getGridRef: () => { return gridRef; },
    }));


    //Fonction pour trier les lignes
    function getComparator(sortColumn, sampleRow) {
        var typeVar = sampleRow[sortColumn];
        if (typeof typeVar == "string") {
            return (a, b) => {
                return a[sortColumn].localeCompare(b[sortColumn]);
            };
        }
        else if (typeof typeVar == "string") {
            if (typeVar instanceof Date) {
                return (a, b) => {
                    return new Date(a).getTime() - new Date(b).getTime();
                };
            }
            else {
                return (a, b) => {
                    return a[sortColumn].localeCompare(b[sortColumn]);
                };
            }
        }
        else {
            return (a, b) => {
                return a[sortColumn] - b[sortColumn];
            };
        }
        /*case 'available':
            return (a, b) => {
                return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
            };*/
    }

    const sortRows = React.useMemo(() => {
        if (sortedColumns.length === 0) return sortedRows;

        return [...sortedRows].sort((a, b) => {
            for (const sort of sortedColumns) {
                const comparator = getComparator(sort.columnKey, a);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [sortedRows, sortedColumns]);


    var summaryRow = {};
    props.columns.forEach(col => {
        if (col.summaryType === "sum") { summaryRow[col.key] = props.rows.reduce((prev, row) => prev + row[col.key], 0); }
    });


    var treeGridContent = <TreeDataGrid {...props}
        ref={gridRef}
        groupBy={selectedGrouping}
        rowGrouper={rowGrouper}
        expandedGroupIds={expandedGroupIds}
        onExpandedGroupIdsChange={setExpandedGroupIds}
        defaultColumnOptions={{ resizable: true, ...props.cDefaultColumnArgs }}
        rows={sortRows}
        bottomSummaryRows={[summaryRow]}

        sortColumns={sortedColumns}
        onSortColumnsChange={setSortedColumns}

    //renderers={{ renderRow: myRowRenderer }}
    /*rowKeyGetter
    topSummaryRows
    bottomSummaryRows
    onRowsChange={setRows}
    rowHeight
    headerRowHeight
    summaryRowHeight
    selectedRows
    onSelectedRowsChange
    sortColumns
    onSortColumnsChange
    defaultColumnOptions
    groupBy
    rowGrouper
    expandedGroupIds
    onExpandedGroupIdsChange
    onFill
    onCopy
    onPaste
    onCellClick
    onCellDoubleClick
    onCellContextMenu
    onCellKeyDown
    onSelectedCellChange
    onScroll
    onColumnResize
    enableVirtualization
    className
    style
    aria-labelledby
    aria-describedby
    data-testid*/
    />;

    return (<>
        <Select
            defaultValue={props.cGrpOptions[0]}
            name="aupdItvSalMulti"
            className={`basic-single`}
            classNamePrefix="select"
            //placeholder="Tous"
            onChange={(values) => {
                console.debug(values);

                setSelectedGrouping(values.value);
            }}
            options={props.cGrpOptions}
        />
        {treeGridContent}
    </>);

    //<TextEditor />
    //<Row  />
    //<SortableHeaderCell onSort sortDirection priority tabIndex children />
    //<ValueFormatter />
    //<SelectCellFormatter value tabIndex disabled onChange onClick aria-label aria-labelledby />
    //<ToggleGroupFormatter />

    //useRowSelection
    //SelectColumn
    //SELECT_COLUMN_KEY 
});



//****** EXPORT ******/

async function exportToCsv(gridElement, fileName) {
    const { head, body, foot } = await getGridContent(gridElement);
    const content = [...head, ...body, ...foot]
        .map((cells) => cells.map(serialiseCellValue).join(';'))
        .join('\n');

    downloadFile(fileName, new Blob(["\ufeff", content], { type: 'text/csv;charset=utf-8;' }));//BOM doit être renseigné pour certaines compatibilités
}

async function getGridContent(gridElement) {
    const { renderToStaticMarkup } = await import('react-dom/server');
    const grid = document.createElement('div');
    grid.innerHTML = renderToStaticMarkup(
        cloneElement(gridElement, {
            enableVirtualization: false
        })
    );

    return {
        head: getRows('.rdg-header-row'),
        body: getRows('.rdg-row:not(.rdg-summary-row)'),
        foot: getRows('.rdg-summary-row')
    };

    function getRows(selector) {
        return Array.from(grid.querySelectorAll(selector)).map((gridRow) => {
            return Array.from(gridRow.querySelectorAll('.rdg-cell')).map(
                (gridCell) => gridCell.innerText
            );
        });
    }
}

function serialiseCellValue(value) {
    if (typeof value === 'string') {
        const formattedValue = value.replace(/"/g, '""');
        return formattedValue.includes(';') ? `"${formattedValue}"` : formattedValue;
    }
    return value;
}

function downloadFile(fileName, data) {
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
}


//****** CUSTOM ******/

function myRowRenderer(key, props) {
    return (
        <>
            <RDGRow key={key} {...props} />
        </>
    );
}

export default CDataGrid;

