import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
    Button,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Form,
    Input,
    FormGroup
} from "reactstrap";

import Swal from "sweetalert2";

import PayCards from 'components/PayCards';

import * as commonFunc from 'scripts/common';
function RegisterStepCompany(props) {
    const { t } = useTranslation();

    React.useEffect(() => {
        if (props.triggerAnimate) props.triggerAnimate();
    }, [props.shouldRender]);


    async function triggerSubmit(data) {
        if (props.updateStep) props.updateStep(data);
    }

    return (<>
        <CardHeader className="bg-white">
            <CardTitle className='header my-0 py-0' tag="h5">{t("register_payInfos")}</CardTitle>
        </CardHeader>
        <CardBody>
            <PayCards triggerSubmit={triggerSubmit} submitTitle={t("register_registerBtn")}>
                <FormGroup check className="fcheck-sm text-end">
                    <Label className="mandatory" check>
                        <Input defaultChecked type="checkbox" disabled />
                        <span className="form-check-sign" />{t("gal_acceptGtc") + " "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {t("gal_termsConditions")}
                        </a>
                        .
                    </Label>
                </FormGroup>
            </PayCards>
        </CardBody>
        <CardFooter className="text-end mt-0">
            {props.prevStep ? <Button className="btn-icon rounded-circle me-2" color="info" onClick={(e) => { e.preventDefault(); props.prevStep(); }}><i className="fa fa-arrow-left" style={{fontSize:"1.6em"}}></i></Button> : ""}
        </CardFooter>
    </>);
}

export default RegisterStepCompany;
