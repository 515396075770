/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { createRoot } from 'react-dom/client';
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Img from 'components/Img';

import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Form,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col,
} from "reactstrap";

import Swal from "sweetalert2";

import * as commonFunc from 'scripts/common';
import { proceedLogin } from 'views/pages/login/Login';

function NavMenu(props) {
    const { t } = useTranslation();

    var modalRoot;


    const [collapseOpen, setCollapseOpen] = React.useState(false);
    //const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [color, setColor] = React.useState("navbar-dark bg-theme");
    const location = useLocation();
    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
    });
    React.useEffect(() => {
        if (
            window.outerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }, [location]);


    //const [curSite, setCurSite] = React.useState(false);

    React.useEffect(() => {
        /*commonFunc.getMethod("../api/data/GetActiveSiteInfo", null).then((responseSite) => {
            var resultSite = responseSite.data;
            if (resultSite.isSuccess) {
                setCurSite(resultSite.result);

                //TODO : Chargement de l'image soit via css (en ajoutant un class en fonction du siteId du user connecté sur l'img + removeClass hidden >> Champeau), soit via data (appel à GetActiveSiteInfo pour récup l'Avatar >> MF)
                //+ gérer la gestion d'image pour le groupe + le site (check script mf sur navbar-brand-logo)
            }
        });*/

        return () => {
            //console.debug("unmount");
            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }
        }
    }, []);



    const navigate = useNavigate();

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setColor("bg-theme");
        } else {
            setColor("navbar-dark bg-theme");
        }
    };
    // this function opens and closes the sidebar on small devices
    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        //setSidebarOpen(!sidebarOpen);
    };
    // this function opens and closes the collapse on small devices
    // it also adds navbar-transparent class to the navbar when closed
    // ad bg-white when opened
    const toggleCollapse = () => {
        if (!collapseOpen) {
            setColor("bg-theme");
        } else {
            setColor("navbar-dark bg-theme");
        }
        setCollapseOpen(!collapseOpen);
    };

    const pathName = (views) => {
        var curViews = views;
        if (!curViews) curViews = props.routes;
        //console.debug(curViews);
        var route = curViews.filter(function (e) {
            if (e.views && e.views.length) {
                var obj = pathName(e.views);
                e.subObj = obj;
                return obj;
            }
            else return props.pathname == e.path;
        });
        if (route.length) {
            if (views) {
                return route.length ? route[0] : false;
            }
            else {
                var label;
                var icon = null;
                if (route[0].icon) icon = <i className={route[0].icon} />;
                if (route[0].subObj) {
                    label = route[0].subObj.name;
                }
                else {
                    label = route[0].name;
                }
                return <span>/  {icon} {label}</span>;
            }
        }
        else {
            return "";
        }
    };


    const loadSites = () => {
        if (props.checkRights("SITE", "A")) {
            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }

            //Traitement des informations retourné par le Dialog
            var callbackModal = ((data) => {
                modalRoot.unmount();
                if (data) window.location.reload();
            });

            modalRoot = createRoot(document.createElement('div'));
            modalRoot.render(<SitesModal parentContext={props} title={"Changement de structure"} callback={callbackModal}></SitesModal>);
        }
    }

    return (
        <>
            <Navbar
                className={`fixed-top p-0 text-white ${color}`}
                expand="lg"
                style={{
                    backgroundPosition: "bottom",
                    backgroundSize: "cover",
                    backgroundImage: `url(${require("assets/img/bg/backblurt1.jpg")})`,
                }}
            >
                <div className="navbar-wrapper">
                    <div className="navbar-minimize">
                        <Button
                            className="btn-icon btn-round"
                            color="secondary"
                            id="minimizeSidebar"
                            onClick={props.handleMiniClick}
                        >
                            <i className="fa fa-bars text-center visible-on-sidebar-mini" />
                            <i className="fa fa-align-left text-center visible-on-sidebar-regular" />
                        </Button>
                        <Button
                            className="btn-icon btn-round ms-2 d-none d-xxl-inline-block"
                            color="info"
                            id="altDisplay"
                            onClick={props.alternativeDisplay}
                        >
                            <i className="fa fa-table text-center" />
                        </Button>
                    </div>
                    <div
                        className={`navbar-toggle`}
                    >
                        <button
                            className="navbar-toggler border-0 shadow-none"
                            type="button"
                            onClick={toggleSidebar}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                        <span className="d-none d-md-block">
                            {t("gal_intranet")} {pathName()}
                        </span>
                        <span className="d-block d-md-none">{props.user.connection ? props.user.connection.siteName : ""}</span>
                    </NavbarBrand>
                </div>
                <button
                    aria-controls="navigation-index"
                    aria-expanded={collapseOpen}
                    aria-label="Toggle navigation"
                    className="navbar-toggler border-0 shadow-none"
                    // data-target="#navigation"
                    data-toggle="collapse"
                    type="button"
                    onClick={toggleCollapse}
                >
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </button>
                <Collapse
                    className="justify-content-end"
                    navbar
                    isOpen={collapseOpen}
                >
                    <Form className="d-none">
                        <InputGroup className="no-border">
                        <Input defaultValue="" placeholder="Recherche..." type="text" />
                        <Button className="m-0 px-3 text-dark" color="light" onClick={(e) => e.preventDefault()}>
                                <i className="fa fa-magnifying-glass" />
                            </Button>
                        </InputGroup>
                    </Form>
                    <Nav navbar className="align-items-center">
                        <NavItem>
                            <NavLink
                                className="btn-magnify"
                                href="../"
                            >
                                <i className="fa fa-home" />
                                <p>
                                    <span className="d-lg-none d-md-block">Tableau de bord</span>
                                </p>
                            </NavLink>
                        </NavItem>
                        <UncontrolledDropdown className="btn-rotate" nav>
                            <DropdownToggle
                                aria-haspopup={true}
                                caret
                                color="default"
                                data-toggle="dropdown"
                                id="navbarDropdownMenuLink"
                                nav
                                className="d-none"
                            >
                                <i className="fa fa-bell" />
                                <p>
                                    <span className="d-lg-none d-md-block">Some Actions</span>
                                </p>
                            </DropdownToggle>
                            <DropdownMenu
                                persist
                                aria-labelledby="navbarDropdownMenuLink"
                                end
                            >
                                <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Action
                                </DropdownItem>
                                <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Another action
                                </DropdownItem>
                                <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Something else here
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                            <NavLink
                                className="btn-rotate"
                                href="#pablo"
                                onClick={(e) => { e.preventDefault(); navigate("/userProfile"); }}
                            >
                                <i className="fa fa-gear" />
                                <p>
                                    <span className="d-lg-none d-md-block">Compte</span>
                                </p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <b className="text-light me-1">{props.user.connection ? props.user.connection.siteName : ""}</b>
                        </NavItem>
                        <NavbarBrand href="../Home/RedirectAlternativeSite" onClick={(e) => { e.preventDefault(); loadSites(); }}>
                            <img src={props.user.connection && !commonFunc.isBlank(props.user.connection.siteAvatar) ? props.user.connection.siteAvatar : require("assets/img/bg/logooskala.png")} height="52" className="d-inline-block align-top rounded hidden" alt="Logo" style={{ widthAuto: "auto", maxHeight:"52px", backgroundColor:"white" }} />
                        </NavbarBrand>
                        <NavItem>
                            <NavLink
                                className="btn-magnify"
                                href="#pablo"
                                onClick={(e) => { e.preventDefault(); props.logOut(e); }}
                            >
                                <i className="fa fa-right-from-bracket" />
                                <p>
                                    <span className="d-lg-none d-md-block">{t("gal_logout")}</span>
                                </p>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </>
    );
}

export default NavMenu;


//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de FileUploadModal
function SitesModal(props) {
    const [sites, setSites] = React.useState([]);
    const [selSite, setSelSite] = React.useState(props.parentContext.user.connection ? props.parentContext.user.connection.siteID : 0);

    React.useEffect(() => {
        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        commonFunc.getMethod("../api/Data/GetAlternativeSites", null).then((response) => {
            Swal.close();
            var data = response.data;
            if (data && data.isSuccess) {
                //console.debug(data);
                if (data.count > 0) {
                    setSites(data.result);
                }
            }
        });

    }, []);

    function selectSite() {
        Swal.fire({
            title: "Changement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var args = { id: selSite, value: selSite, tag:"selectSite"  };
        commonFunc.postMethod("../api/RenewTokenForAlernativeSite", args).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {
                    Swal.close();
                    if (response.data.result) {
                        proceedLogin(response.data.result.accessToken/*, props.parentContext.props*/).then((data) => {
                            //TODO : refresh location OU redirect Home ???
                            props.callback(true);
                        });
                    }
                    else {
                        props.callback();
                    }
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors du changement de structure :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors du changement de structure"
                });
            }
        });
    }

    //console.debug("render modal");

    return (
        <Modal isOpen={true} className="modal-sites" size="xl" keyboard={false} toggle={() => { props.callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { props.callback(); }}>{props.title}</ModalHeader>
            <ModalBody className="px-2">
                <p className="d-inline-block mt-1" style={{ fontSize: '15px', verticalAlign: "top" }}>Choisissez l'entité sur laquelle vous voulez vous connecter</p>

                <Row className="m-0 px-1">
                    <Col lg="12">
                        {sites.map((dt, index) => {
                            return <div key={index} className='file-box medium animate__animated animate__pulse' data-siteid={parseInt(dt.localSite.idSite)}>
                                <div className={`file ${selSite === parseInt(dt.localSite.idSite) ? "selected" : ""}`} >
                                    <a href={`../Home/RedirectAlternativeSite?siteId=${dt.localSite.idSite}`} data-trigger='focus' onClick={(e) => { e.preventDefault(); setSelSite(parseInt(dt.localSite.idSite)); }}>
                                        <div className='file-marker'></div>
                                        <span className='corner'></span>
                                        <div className='icon file-fullsize  d-flex align-items-center justify-content-center'>
                                            <Img className='img-fluid img-thumbnail2 mx-auto d-block ' src={dt.localSite.avatar || require("assets/img/bg/logooskala.png")} alt='' data-typef='img' />
                                            <i className="fa fa-building"></i>
                                        </div>
                                        <div className='file-name' title={dt.localSite.name}>{dt.localSite.name}<br /></div>
                                    </a>
                                </div>
                            </div>;
                        })}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <Button className="px-3" color="success" onClick={() => { selectSite(); }}>Changer</Button>
                <Button className="px-3" color="secondary" onClick={() => { props.callback(); }}>Fermer</Button>
            </ModalFooter>
        </Modal>
    );
}