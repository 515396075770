import React, { useState } from 'react'
import { createRoot } from 'react-dom/client';
import { UncontrolledPopover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Card, CardHeader, CardBody, Row, Col, Label, Input, FormFeedback, FormGroup, Spinner } from 'reactstrap';
import Select from 'react-select';
import * as commonFunc from 'scripts/common';

import ToolbarDate from 'components/ToolbarDate';
import DPCustom from 'components/DPCustom';
import OffCanvas from 'components/OffCanvas';

import FinderFrag from 'components/generic/FinderFrag';

import AGenerateModel from 'components/generic/AGenerateModel';

import { useTranslation } from "react-i18next";
import moment from "moment";
import Swal from "sweetalert2";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timegridPlugin from '@fullcalendar/timegrid'
import frLocale from '@fullcalendar/core/locales/fr'

import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import timelinePlugin from '@fullcalendar/timeline';
import resourcePlugin from '@fullcalendar/resource';
import resourceTGPlugin from '@fullcalendar/resource-timegrid';
import resourceTLPlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';

import { Menu, Item, Separator, useContextMenu } from "react-contexify"

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.i18n";

import "react-contexify/dist/ReactContexify.css";

import empty_avatar from "assets/img/faces/default_profile.png";
import { setWith } from 'lodash-es';
import { bool } from 'prop-types';

import { withRouter } from 'components/withRouter';

class FCPlanning extends React.Component {
    employees = [];
    status = [];
    services = [];
    config = null;

    settings = null;
    preventDataRefresh = 0;

    pendingList = [];
    sendAll = function () {
        this.pendingList.forEach(function (data) {
            if (!data.formData.cancel) {
                data.submit();
            }
        });
        this.pendingList = [];
        //jqXHR =
        //jqXHR.abort();
    };


    userConfigOptions = {
        configCalendar: {
            filterResourcesWithEvents: false,
            //timeZone: 'local', // the default (unnecessary to specify)
            useResourceColor: false, //custom
            displayEventTime: false,
            useEventGuiEffect: true,
            useEventGuiOverlay: true,
            useResAvatar: 0, //0: pour tous, 1 : si avatar, 2 : aucun
            useDefaultBaseView: "tline",
            useDefaultCycle: "L",  //5J
            useDisplayStretch: false,
            useDisplayMobileStretch: false,
        }
    };

    calendarRef = React.createRef();

    modalRoot = null;
    modalGenManager = React.createRef();

    showContextMenu = useContextMenu().show;
    hideAllMenu = useContextMenu().hideAll;

    constructor(props) {
        super(props);

        this.offCanvasReportRef = React.createRef(null);
        this.reportsExtList = React.createRef(null);

        this.state = { loading: true, baseview: "tline", period: "L", filterResourcesWithEvents: false, dispSynt: false };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (context.props.updateUser) context.props.updateUser(function (curUser) {
            context.loadServices(function () {
                context.loadPlanningConfig(function () {
                    context.loadStatus(function (data) {

                        var period = context.state.period;
                        var baseview = context.state.baseview;
                        var filterResourcesWithEvents = context.state.filterResourcesWithEvents;
                        var dispSynt = context.state.dispSynt;

                        var finalizeLoad = () => {
                            //MAJ du calendar après le re-render
                            context.callbackRender = function (newContext) {
                                context.callbackRender = null;

                                //Chargement des paramètres URL / Compte connecté
                                var spBaseview = null;
                                var curMoment = moment();
                                var resourceId = 0;
                                var resourceType = null;
                                if (context.props.searchParams && context.props.searchParams.size > 0) {
                                    resourceId = parseInt(context.props.searchParams.get("resourceId"));
                                    resourceType = context.props.searchParams.get("resourceType");
                                }
                                else {
                                    if (curUser.primaryGroupId === 1 && curUser.primarySId === 2) {//Employee / Intervenant
                                        resourceId = curUser.matchID;
                                        resourceType = "employee";
                                    }
                                    else if (curUser.primaryGroupId === 2) {//Client
                                        resourceId = curUser.matchID;
                                        resourceType = "client";
                                    }
                                }

                                if (resourceId > 0) {
                                    let calendarApi = context.calendarRef.current.getApi();

                                    curMoment = new moment(calendarApi.getDate());
                                    period = context.state.period;
                                    var resource = calendarApi.getResourceById(resourceId);
                                    if (!resource) {
                                        if (resourceType === "client" && context.clients && context.clients.length) resource = context.clients.filter(client => client.id === resourceId);
                                        else if (resourceType === "employee" && context.employees && context.employees.length) resource = context.employees.filter(employee => employee.id === resourceId);
                                        if (resource && resource.length) resource = resource[0];
                                    }
                                    if (resource) {
                                        if (context.config && context.config.resource) resource.type = context.config.resource.type;
                                        if (resourceType) resource.type = resourceType;
                                        spBaseview = context.state.dispSynt ? "stretchagenda" : "agenda";
                                        context.config = { view: period, sdate: curMoment, baseview: spBaseview, resource: resource, resGrp: 0 };
                                    }
                                }

                                context.redrawView(period, curMoment, spBaseview, true);
                            };
                            console.debug("GO LOAD 2");
                            //context.preventDataRefresh = 2;
                            context.setState({ loading: false, baseview: baseview, filterResourcesWithEvents: filterResourcesWithEvents, dispSynt: dispSynt, period: period });
                        };


                        if (context.userConfigOptions && context.userConfigOptions.configCalendar) {
                            var configCalendar = context.userConfigOptions.configCalendar;

                            period = configCalendar.useDefaultCycle || period;
                            baseview = configCalendar.useDefaultBaseView || baseview;
                            filterResourcesWithEvents = configCalendar.filterResourcesWithEvents || filterResourcesWithEvents;
                            if (commonFunc.isMobileDevice()) {
                                dispSynt = configCalendar.useDisplayMobileStretch || dispSynt;
                            }
                            else {
                                dispSynt = configCalendar.useDisplayStretch || dispSynt;
                            }
                            if (dispSynt) {
                                //$("#calendar .fc-view-container").addClass("nostretch");

                                if (baseview === 'tline' || baseview === 'agenda') {
                                    baseview = 'stretch' + baseview;
                                }
                            }

                            //TODO - InsertAdjacent/append etc ne fonctionne pas, tout est redessiner automatiquement...
                            //Ajout de css
                            /*if (configCalendar.useCss) {
                                document.body.insertAdjacentHTML('afterbegin', configCalendar.useCss);
                            }*/

                            //Gestion des rapports en external draggable
                            /*if (configCalendar.enableDragsReports && !commonFunc.isMobileDevice()) {
                                context.refreshReportsExtList(configCalendar, finalizeLoad);
                            }*/
                        }

                        finalizeLoad();
                    });
                });
            });
        });
    }

    componentWillUnmount() {
        //Fermeture des dialogs
        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }
    }

    render() {
        console.debug("Planning render " + this.state.loading + " " + this.state.baseview);

        //Fermeture des dialogs
        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }

        if (commonFunc.isMobileDevice()) document.body.classList.add("mobile-device");

        var defaults = {

            themeSystem: 'bootstrap5',
            //headerToolbar: false,
            /*footerToolbar: {
                start: 'title',
                center: '',
                end: 'today prev,next'
            },*/
            locale: 'fr',
            initialView: 'resourceTimelineWeek',
            height: "100%",
            contentHeight: "100%",
            //contentHeight: "auto",
            //aspectRatio: 2,//1.35,
            weekNumbers: true,
            weekNumberCalculation: 'ISO',
            weekText: "Sem.",
            selectMirror: true,
            eventMinWidth: 10,
            //weekNumberFormat

            eventOverlap: false, // will cause the event to take up entire resource height
            allDaySlot: false, //All day
            slotEventOverlap: false,

            editable: false, //LOCK

            selectable: true,
            selectMinDistance: 5,
            selectConstraint: { //a groupId for Events,
                start: '00:00', // a start time (10am in this example)
                end: '23:00', // an end time (6pm in this example)
                //dow: [1, 2, 3, 4, 5]
                // days of week. an array of zero-based day of week integers (0=Sunday)
                // (Monday-Thursday in this example)
            },
            unselectAuto: true,

            displayEventTime: true,
            displayEventEnd: false,
            eventTimeFormat: 'H:mm',

            scrollTime: "08:00:00",
            slotDuration: "06:00:00",//slotDuration: "00:30:00",// slotDuration: "00:15:00"
            slotLabelInterval: "06:00:00", //"01:00:00",
            slotLabelFormat: "HH[h]mm",
            snapDuration: "00:30:00",
            navLinks: false, //// can click day/week names to navigate views
            nowIndicator: true,
            lazyFetching: false,
            filterResourcesWithEvents: false,
            progressiveEventRendering: false,
            /*businessHours: {
                dow: [1, 2, 3, 4, 5], // Monday - Thursday
                start: '08:00', // a start time (10am in this example)
                end: '20:00', // an end time (6pm in this example)
            },*/


            resourceOrder: 'agency,title', // when occupancy tied, order by title -occupancy,title
            resourceGroupField: 'agency',
            resourceAreaWidth: '12%',
            resourcesInitiallyExpanded: true,
            resourceAreaColumns: [{ headerContent: 'Ressource', field: 'title', /* group: true,*/ }, /*{ labelText: 'Occupancy', field: 'agency' }*/],
            refetchResourcesOnNavigate: true,

            views: {


                /*AGENDA*/

                agenda: {
                    type: 'resourceTimeGridDay',
                    buttonText: 'Jour',
                    titleFormat: ' dddd DD MMMM YYYY',
                    //columnFormat: "ddd \n DD MMMM",
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',

                },


                agendaWeek: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 7 },
                    buttonText: 'Semaine 7J',
                    //slotDuration: '00:15',
                    // locale: 'fr',
                    // firstDay: 1,
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dddd') + "<br>" + currDate.format('DD MMMM') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "08:00:00",
                    //slotMaxTime: "18:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"

                },

                agenda5Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 5 },
                    buttonText: 'Semaine 5J',
                    // slotDuration: '00:15',
                    // locale: 'fr',
                    //  firstDay: 1,
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dddd') + "<br>" + currDate.format('DD MMMM') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "08:00:00",
                    //slotMaxTime: "18:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },




                agenda28Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 28 },
                    buttonText: 'Mois 28J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },


                agenda29Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 29 },
                    buttonText: 'Mois 29J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },

                agenda30Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 30 },
                    buttonText: 'Mois 30J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },

                agenda31Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 31 },
                    buttonText: 'Mois 31J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },


                /*AGENDA CONDENSE*/


                stretchagenda: {
                    type: 'resourceTimeGridDay',
                    buttonText: 'Jour',
                    titleFormat: ' dddd DD MMMM YYYY',
                    //columnFormat: "ddd \n DD MMMM",
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',

                },


                stretchagendaWeek: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 7 },
                    buttonText: 'Semaine 7J',
                    //slotDuration: '00:15',
                    // locale: 'fr',
                    // firstDay: 1,
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dddd') + "<br>" + currDate.format('DD MMMM') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    // slotMinTime: "08:00:00",
                    // slotMaxTime: "18:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"

                },

                stretchagenda5Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 5 },
                    buttonText: 'Semaine 5J',
                    // slotDuration: '00:15',
                    // locale: 'fr',
                    //  firstDay: 1,
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dddd') + "<br>" + currDate.format('DD MMMM') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    // slotMinTime: "08:00:00",
                    // slotMaxTime: "18:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },

                stretchagenda28Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 28 },
                    buttonText: 'Mois 28J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },
                stretchagenda29Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 29 },
                    buttonText: 'Mois 29J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },
                stretchagenda30Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 30 },
                    buttonText: 'Mois 30J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },
                stretchagenda31Week: {
                    type: 'resourceTimeGridWeek',
                    duration: { days: 31 },
                    buttonText: 'Mois 31J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    dayHeaderContent: (args) => {
                        var currDate = moment(args.date);
                        return { html: currDate.format('dd') + "<br>" + currDate.format('DD') }
                    },
                    //slotLabelFormat: ['HH:mm'],
                    //slotMinTime: "06:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotMinWidth: "60"
                },







                /*LISTE*/

                list: {
                    type: 'listDay',
                    duration: { days: 1 },
                    titleFormat: ' dddd DD MMMM YYYY',
                    columnFormat: "dddd \n DD MMMM",

                },

                list5Week: {
                    type: 'listWeek',
                    duration: { days: 5 },
                    buttonText: 'Liste 5J',
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                },

                listWeek: {
                    type: 'listWeek',
                    duration: { days: 7 },
                    buttonText: 'Liste 7J',
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                },


                list28Week: {
                    type: 'listWeek',
                    duration: { days: 28 },
                    buttonText: 'Liste 28J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                },

                list29Week: {
                    type: 'listWeek',
                    duration: { days: 29 },
                    buttonText: 'Liste 29J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                },

                list30Week: {
                    type: 'listWeek',
                    duration: { days: 30 },
                    buttonText: 'Liste 30J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                },

                list31Week: {
                    type: 'listWeek',
                    duration: { days: 31 },
                    buttonText: 'Liste 31J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                },




                agendaDay: {
                    buttonText: 'Ressources/Jour',

                },


                /*Jour*/

                tline: {
                    type: 'resourceTimeline',
                    duration: { days: 1 },
                    buttonText: 'TimeLine 1J',
                    titleFormat: 'dddd DD MMMM YYYY',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    //slotMinTime: "08:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotDuration: '00:30'
                },


                /*Semaine*/


                tlineWeek: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 7 },
                    buttonText: 'TimeLine 7J',
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    slotMinWidth: "30"//"60"
                    //slotDuration: '00:15'
                },


                tline5Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 5 },
                    buttonText: 'TimeLine 5J',
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    //slotLabelFormat: ['dddd DD MMMM'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    slotMinWidth: "30"
                },




                /*Mois*/


                tline28Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 28 },
                    buttonText: 'TimeLine 28J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotDuration: '06:00:00',
                    slotMinWidth: "125"

                },


                tline29Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 29 },
                    buttonText: 'TimeLine 29J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotDuration: '06:00:00',
                    slotMinWidth: "125"

                },

                tline30Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 30 },
                    buttonText: 'TimeLine 30J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotDuration: '06:00:00',
                    slotMinWidth: "125"

                },


                tline31Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 31 },
                    buttonText: 'TimeLine 31J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotDuration: '06:00:00',
                    slotMinWidth: "125"

                },



                /*Jour Condensé*/


                stretchtline: {
                    type: 'resourceTimeline',
                    duration: { days: 1 },
                    buttonText: 'TimeLine 1J',
                    titleFormat: 'dddd DD MMMM YYYY',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    //slotMinTime: "08:00:00",
                    //slotMaxTime: "20:00:00",
                    slotDuration: '00:30:00',
                    slotLabelInterval: '01:00:00',
                    //slotDuration: '00:30'
                },



                /*Semaine Condensée*/

                stretchtlineWeek: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 7 },
                    buttonText: 'TimeLine 7J',
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotMinWidth: "20%"
                    //slotDuration: '00:15'
                },

                stretchtline5Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 5 },
                    buttonText: 'TimeLine 5J',
                    titleFormat: 'ddd DD MMMM YYYY [(S]W[)]',
                    titleRangeSeparator: ' au ',
                    //slotLabelFormat: ['dddd DD MMMM', 'H:mm'],
                    //slotLabelFormat: ['dddd DD MMMM'],
                    slotLabelFormat: ['dddd DD MMMM', 'H[h]'],
                    slotMinTime: "08:00:00",
                    slotMaxTime: "18:00:00",
                    slotMinWidth: "5",
                    slotLabelInterval: '01:00',
                    slotDuration: '01:00'
                    /*slotDuration: '06:00:00',
                    slotLabelInterval:'06:00:00',*/
                },



                /*Mois Condensé*/


                stretchtline28Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 28 },
                    buttonText: 'TimeLine 28J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dd DD', '[-]'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotMinWidth: "20%",
                    slotLabelInterval: '12:00',
                    slotDuration: '12:00'
                },

                stretchtline29Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 29 },
                    buttonText: 'TimeLine 30J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dd DD', '[-]'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotMinWidth: "20%",
                    slotLabelInterval: '12:00',
                    slotDuration: '12:00'
                },

                stretchtline30Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 30 },
                    buttonText: 'TimeLine 30J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dd DD', '[-]'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotMinWidth: "20%",
                    slotLabelInterval: '12:00',
                    slotDuration: '12:00'
                },


                stretchtline31Week: {
                    type: 'resourceTimelineWeek',
                    duration: { days: 31 },
                    buttonText: 'TimeLine 31J',
                    titleFormat: 'ddd DD MMMM YYYY',
                    titleRangeSeparator: ' au ',
                    slotLabelFormat: ['dd DD', '[-]'],
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    slotMinWidth: "20%",
                    slotLabelInterval: '12:00',
                    slotDuration: '12:00'
                },



            },

        }

        this.settings = commonFunc.mergeDeep({}, defaults, this.userConfigOptions.configCalendar);

        this.settings.schedulerLicenseKey = 'CC-Attribution-NonCommercial-NoDerivatives';
        this.settings.progressiveEventRendering = this.settings.filterResourcesWithEvents;
        this.settings.resourceAreaWidth = (commonFunc.isMobileDevice() ? ((parseInt(this.settings.resourceAreaWidth.replace("%", "")) * 2) + "%") : this.settings.resourceAreaWidth);

        var resourceInfo = "";
        var config = this.config;
        if (config && config.resource) resourceInfo = config.resource.title;

        var context = this;

        let contents = this.state.loading ? <p><em>Chargement...</em></p> :
            <div id="calendar" className="h-100 d-flex flex-column">
                <ToolbarDate resourceInfo={resourceInfo} currentStyle={this.state.baseview} currentFilter={config && config.resource ? config.resource.type : "employee"}
                    selAll={!this.state.filterResourcesWithEvents} setSelAll={this.filterResources.bind(this)}
                    currentPeriod={this.state.period} updatePeriod={this.updatePeriod.bind(this)}
                    dispSynt={this.state.dispSynt} updateDispSynt={this.updateDispSynt.bind(this)}
                    updateStyle={this.updateStyle.bind(this)}
                    updateFilter={this.updateFilter.bind(this)}
                    refreshContent={this.refreshContent.bind(this)}
                    selPreviousPeriod={this.selPreviousPeriod.bind(this)}
                    datepickerChange={this.datepickerChange.bind(this)}
                    selNextPeriod={this.selNextPeriod.bind(this)}
                    datepickerOpt={{ showWeeks: true, initialValue: moment(new Date()).utc().toDate() }}
                    addCustomElement={this.addOrUpdateItv.bind(this)}
                    backToAllRes={this.findResourceChange.bind(this)}
                    printModule={this.printPlanning.bind(this)}
                    customAddBtn={this.props.checkRights("PLANNING", "C") ? "" : <div className="d-none"></div>}></ToolbarDate>
                <div id="embedActions">
                    <select name="findResource" id="findResource" data-filter='*'
                        className="chosen-select-inherit-search  chosen-minimized full-size-forced hidden " required="required" aria-required="true"
                        onChange={(event) => { this.findResourceChange(event.target.value); }}></select>
                </div>
                <FullCalendar
                    ref={this.calendarRef}
                    plugins={[dayGridPlugin, listPlugin, timegridPlugin, interactionPlugin, bootstrap5Plugin, timelinePlugin, resourcePlugin, resourceTGPlugin, resourceTLPlugin, momentPlugin]}
                    locales={[frLocale]}
                    {...this.settings}

                    filterResourcesWithEvents={this.state.filterResourcesWithEvents}
                    editable={this.props.checkRights("PLANNING", "U")}
                    datesSet={(info) => { document.getElementById("pplanSelPeriod").innerText = document.getElementsByClassName("fc-toolbar-title")[0].innerText; }}
                    resourceLabelContent={renderResourceContent.bind(this)}
                    resourceLabelDidMount={(info) => {
                        //console.debug("resourceLabelDidMount");
                        info.el.addEventListener("dblclick", function (event) {
                            var resourceId = document.getElementsByClassName("fc-col-header-cell fc-resource").length != 1 ? info.resource.id : 0;

                            document.getElementById("findResource").value = resourceId;
                            context.findResourceChange(resourceId);
                        });
                    }}

                    resources={(info, successCallback, failureCallback) => this.loadResources(info.start, info.end, info.timezone, successCallback)}
                    events={(info, successCallback, failureCallback) => this.loadEvents(info.start, info.end, info.timezone, successCallback)}
                    loading={(isLoading, view) => {
                        isLoading ? Swal.fire({ title: 'Chargement en cours...', didOpen: () => { Swal.showLoading(); } }) : Swal.close();
                        if (!isLoading && this.callbackRender && typeof this.callbackRender == "function") this.callbackRender(this);
                    }}
                    select={(selectionInfo) => {
                        //console.log("select");
                        //console.debug(selectionInfo);
                        var src = new aShortEvent();
                        src.employeeID = selectionInfo.resource ? selectionInfo.resource.id : 0;
                        src.startDate = moment(selectionInfo.start).toDate();
                        src.endDate = moment(selectionInfo.end).toDate();
                        this.addOrUpdateItv(src);
                    }}
                    selectOverlap={(event) => {
                        //console.log("selectOverlap")
                        //console.debug(event);
                        return event.rendering === 'background';
                    }}
                    windowResize={(view) => {
                        console.log("resize");
                    }}

                    eventContent={renderEventContent.bind(this)}

                    eventReceive={(eventDropInfo) => { // called when a proper external event is dropped
                        console.log('eventReceive', eventDropInfo);
                        var infoResources = eventDropInfo.event.getResources();
                        var infoEvent = eventDropInfo.event.extendedProps;

                        console.debug(infoResources);
                        console.debug(infoEvent);

                        var src = Object.assign({}, infoEvent.src);
                        //var src = Object.assign({}, infoEvent);
                        //src.id = 0;
                        //src.id = infoEvent.src.idEvent;
                        //src.title = eventDropInfo.event.title;

                        var config = this.config;
                        if (config) {
                            switch (config.resource.type) {
                                case "employee":
                                    src.employeeID = parseInt(infoResources[0]._resource.id);
                                    break;
                                case "client":
                                    src.clientID = parseInt(infoResources[0]._resource.id);
                                    break;
                                //case "product": /*args.resType = 256;*/ url = "../data/GetReportsActivities"; break;
                                //case "material": /*args.resType = 4;*/ url = "../data/GetReportsEmployees"; break;
                                default:
                                    src.employeeID = parseInt(infoResources[0]._resource.id);
                                    break;
                            }
                        }
                        else {
                            src.employeeID = parseInt(infoResources[0]._resource.id);
                        }

                        //src.serviceID = infoEvent.src.serviceID;
                        src.startDate = moment(eventDropInfo.event.start).utc().format();
                        src.endDate = moment(eventDropInfo.event.end).utc().format();
                        src.revertFunc = eventDropInfo.revert;
                        if (src.reportID > 0) {
                            //MAJ Rapport
                            //src.employeeName = infoResources[0]._resource.title;//value
                            //src.employeePhone = infoResources[0]._resource.comments;
                            this.updateReport(src, "drop");
                        }
                        else {
                            this.addOrUpdateItv(src);
                        }
                    }}
                    eventDrop={(eventDropInfo) => { // called when an event (already on the calendar) is moved
                        console.log('eventDrop');
                        var infoResources = eventDropInfo.event.getResources();
                        var infoEvent = eventDropInfo.event.extendedProps;

                        var src = Object.assign({}, infoEvent.src);
                        src.id = eventDropInfo.oldEvent.id;

                        var resId = parseInt(infoResources[0]._resource.id);
                        var flag = true;

                        var config = this.config;
                        if (config) {
                            switch (config.resource.type) {
                                case "employee":
                                    if (resId === src.employeeID) flag = false;
                                    src.employeeID = resId;
                                    break;
                                case "client":
                                    if (resId === src.clientID) flag = false;
                                    src.clientID = resId;
                                    break;
                                //case "product": /*args.resType = 256;*/ url = "../data/GetReportsActivities"; break;
                                //case "material": /*args.resType = 4;*/ url = "../data/GetReportsEmployees"; break;
                                default:
                                    if (resId === src.employeeID) flag = false;
                                    src.employeeID = resId;
                                    break;
                            }
                        }
                        else {
                            if (resId === src.employeeID) flag = false;
                            src.employeeID = resId;
                        }

                        flag = true; //Test buggé ne fonctionne pas si on change les dates/horaires uniquement

                        //src.serviceID = infoEvent.serviceId;
                        src.startDate = moment(eventDropInfo.event.start).utc().format();
                        src.endDate = moment(eventDropInfo.event.end).utc().format();
                        src.revertFunc = eventDropInfo.revert;
                        if (src.reportID > 0) {
                            if (!src.isValid || flag) {
                                //MAJ Rapport
                                //src.employeeName = infoResources[0]._resource.title;//value
                                //src.employeePhone = infoResources[0]._resource.comments;
                                this.updateReport(src, "change");
                            } else {
                                //Rejet!
                                if (src.revertFunc && typeof (src.revertFunc === "function")) {
                                    src.revertFunc();
                                }
                            }
                        }
                        else {
                            this.addOrUpdateItv(src);
                        }
                    }}
                    eventResize={(eventResizeInfo) => {
                        console.log('eventResize');
                        var infoResources = eventResizeInfo.event.getResources();
                        var infoEvent = eventResizeInfo.event.extendedProps;

                        var src = Object.assign({}, infoEvent.src);
                        src.startDate = moment(eventResizeInfo.event.start).utc().format();
                        src.endDate = moment(eventResizeInfo.event.end).utc().format();
                        src.revertFunc = eventResizeInfo.revert;
                        if (src.reportID > 0) {
                            //MAJ Rapport
                            //src.employeeID = infoResources[0]._resource.id;
                            //src.employeeName = infoResources[0]._resource.title;//value
                            //src.employeePhone = infoResources[0]._resource.comments;
                            this.updateReport(src, "resize");
                        }
                        else {
                            this.addOrUpdateItv(src);
                        }

                    }}
                    eventDragStart={(eventDragInfo) => {
                        console.log('eventDragStart');
                    }}
                    eventAllow={(dropLocation, draggedEvent) => {
                        if (draggedEvent.id === '999') {
                            return dropLocation.start.isAfter('2018-06-01'); // a boolean
                        }
                        else {
                            return true;
                        }
                    }}


                    eventDidMount={eventDidMount.bind(this)}
                    eventWillUnmount={(info) => {
                        console.log("eventWillUnmount");
                    }}

                    eventClick={(info) => {
                        //console.log("eventClick");
                        //console.debug(info);
                        //$(info.el).addClass("fc-selected");
                        // opens events in a popup window
                        //window.open(event.url, 'gcalevent', 'width=700,height=600');
                        info.jsEvent.preventDefault();
                    }}
                    eventMouseEnter={(info) => {
                        //event, jsEvent, view
                        info.jsEvent.preventDefault();
                        return false;
                    }}
                    eventMouseLeave={(info) => {
                        //event, jsEvent, view
                        info.jsEvent.preventDefault();
                        return false;
                    }}


                />
                <>{/*bottom*/}</>
                <OffCanvas className={`${this.settings.enableDragsReports && !commonFunc.isMobileDevice() ? "" : "d-none"}`} ref={this.offCanvasReportRef}
                    direction={"end"} title={"Panier d'interventions"} backdrop={false} scrollable={true} onOpened={() => { this.refreshReportsExtList(this.settings).then((results) => { this.reportsExtList.current.updateItems(results) }); }}>

                    <div className="d-none">TODO mix Accordion</div>

                    <ManageList ref={this.reportsExtList} parentRef={this.offCanvasReportRef} context={this} refreshItems={() => { this.refreshReportsExtList(this.settings).then((results) => { this.reportsExtList.current.updateItems(results); }) }}
                        services={this.services} />
                </OffCanvas>

                <Menu id="contextMenu">
                    {this.props.checkRights("PLANNING", "U") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvUpd" onClick={itvUpd.bind(this)}>
                        <i className="fa fa-edit2" aria-hidden="true"></i>&nbsp;Modifier
                    </Item> : ""}
                    {this.props.checkRights("REPORT", "R") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvReportAccess" onClick={itvReportAccess.bind(this)}>
                        <i className="fa fa-edit2" aria-hidden="true"></i>&nbsp;Accès Intervention
                    </Item> : ""}
                    {this.props.checkRights("PLANNING", "D") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvDel" onClick={itvDel.bind(this)}>
                        <i className="fa fa-trash-alt2" aria-hidden="true"></i>&nbsp;Supprimer
                    </Item> : ""}
                    {this.props.checkRights("PLANNING", "U") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvDpl" onClick={itvDpl.bind(this)}>
                        <i className="fa fa-copy2" aria-hidden="true"></i>&nbsp;Dupliquer
                    </Item> : ""}
                    <Separator />
                    {this.props.checkRights("PLANNING", "U") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvValid" onClick={itvValid.bind(this)}>
                        <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Valider
                    </Item> : ""}
                    {this.props.checkRights("PLANNING", "U") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvUnaffect" onClick={itvUnaffect.bind(this)}>
                        <i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;Désaffecter
                    </Item> : ""}
                    <Separator />
                    {this.props.checkRights("REPORT", "U") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvRptValid0" onClick={itvRptValid.bind(this)}>
                        <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Marquer Comme "Planifié non confirmé"
                    </Item> : ""}
                    {this.props.checkRights("REPORT", "U") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuItvRptValid1" onClick={itvRptValid.bind(this)}>
                        <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Marquer Comme "Planifié non envoyé"
                    </Item> : ""}
                    <Separator />
                    {this.props.checkRights("PLANNING", "R") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuAccessPlanClient" onClick={accessPlanClient.bind(this)}>
                        <i className="fa fa-circle-right" aria-hidden="true"></i>&nbsp;Atteindre planning client
                    </Item> : ""}
                    {this.props.checkRights("PLANNING", "R") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuAccessPlanEmployee" onClick={accessPlanEmployee.bind(this)}>
                        <i className="fa fa-circle-right" aria-hidden="true"></i>&nbsp;Atteindre planning employee
                    </Item> : ""}
                    {this.props.checkRights("CLIENT", "R") ? <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="mnuAccessDmdClient" onClick={accessDmdClient.bind(this)}>
                        <i className="fa fa-file-signature" aria-hidden="true"></i>&nbsp;Atteindre la demande client
                    </Item> : ""}
                    <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="showgeo" onClick={showGeo.bind(this)}>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Visualiser l'adresse
                    </Item>
                    <Item hidden={isItemHidden.bind(this)} disabled={isItemDisabled.bind(this)} data="showgeojourney" onClick={showGeoJourney.bind(this)}>
                        <i className="fa fa-code-fork" aria-hidden="true"></i>&nbsp;Visualiser la tournée
                    </Item>
                </Menu>
            </div>;

        document.body.classList.add("sidebar-mini");
        document.body.classList.add("planning-mode");

        return (
            contents
        )
    }

    refreshContent() {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.refetchEvents();
        calendarApi.refetchResources();
        //TODO : refresh liste de rapport à drag/drop
    }

    selPreviousPeriod() {
        let calendarApi = this.calendarRef.current.getApi();

        var curMoment = new moment(calendarApi.getDate());
        var view = this.state.period;
        var begin;

        var newView;
        switch (view) {
            case "D": //JOUR
                begin = curMoment.clone().subtract(1, 'days');
                break;

            case "L": //SEMAINE 5J
                begin = curMoment.clone().startOf('isoWeek').subtract(7, 'days');
                break;

            case "W": //SEMAINE
                begin = curMoment.clone().startOf('isoWeek').subtract(7, 'days');
                break;

            case "M": //MOIS /JOURS
                var baseview = this.state.baseview;
                if (!baseview) { baseview = 'agenda'; }
                if (baseview !== "month") {
                    newView = baseview + "31Week";
                    begin = curMoment.clone().startOf('month').subtract(1, 'days').startOf('month');
                    switch (begin.daysInMonth()) {
                        case 28: newView = baseview + "28Week"; break;
                        case 29: newView = baseview + "29Week"; break;
                        case 30: newView = baseview + "30Week"; break;
                        case 31: newView = baseview + "31Week"; break;
                        default: break;
                    }
                } else {
                    begin = curMoment.clone().startOf('month').subtract(1, 'days').startOf('month').add(5, 'days');
                }

                break;
            default: break;
        }
        if (begin) {
            var config = this.config;
            if (config) config.sdate = begin;

            if (newView) {
                calendarApi.changeView(newView, begin.format());
            }
            else {
                calendarApi.gotoDate(begin.format());
            }
        }
    }

    datepickerChange(date) {
        if (!date) date = moment(this.calendarRef.current.getApi().getDate());

        //console.debug(date);
        //var selectedWeek = $.datepicker.iso8601Week(new Date(dateText));


        var curMoment = date;//moment(date)
        var view = this.state.period;

        var config = this.config;
        if (config) config.sdate = curMoment;

        this.redrawView(view, curMoment, null);
    }

    selNextPeriod() {
        let calendarApi = this.calendarRef.current.getApi();

        var curMoment = new moment(calendarApi.getDate());
        var view = this.state.period;
        var begin;

        var newView;
        switch (view) {
            case "D": //JOUR
                begin = curMoment.clone().add(1, 'days')
                break;

            case "L": //SEMAINE 5J
                begin = curMoment.clone().endOf('isoWeek').add(1, 'days');
                break;

            case "W": //SEMAINE
                begin = curMoment.clone().endOf('isoWeek').add(1, 'days');
                break;

            case "M": //MOIS /JOURS
                var baseview = this.state.baseview;
                if (!baseview) { baseview = 'agenda'; }
                if (baseview !== "month") {
                    begin = curMoment.clone().endOf('month').add(1, 'days');
                    newView = baseview + "31Week";
                    switch (begin.daysInMonth()) {
                        case 28: newView = baseview + "28Week"; break;
                        case 29: newView = baseview + "29Week"; break;
                        case 30: newView = baseview + "30Week"; break;
                        case 31: newView = baseview + "31Week"; break;
                        default: break;
                    }
                } else {
                    begin = curMoment.clone().endOf('month').add(5, 'days');
                }
                break;
            default: break;
        }
        if (begin) {
            var config = this.config;
            if (config) config.sdate = begin;

            if (newView) {
                calendarApi.changeView(newView, begin.format());
            }
            else {
                calendarApi.gotoDate(begin.format());
            }
        }
    }

    updatePeriod(period) {
        var date = moment(this.calendarRef.current.getApi().getDate());

        var curMoment = date;//moment(date)
        var view = period;

        var config = this.config;
        if (config) config.view = view;

        this.redrawView(view, curMoment);
    }

    filterResources(selAll) {
        this.callbackRender = function () {
            this.callbackRender = null;


            var date = moment(this.calendarRef.current.getApi().getDate());

            var curMoment = date;//moment(date)
            var view = this.state.period;

            this.redrawView(view, curMoment);
        };

        this.preventDataRefresh = 2;
        this.setState({ filterResourcesWithEvents: !selAll })
    }

    updateStyle(style) {
        let calendarApi = this.calendarRef.current.getApi();

        var curMoment = new moment(calendarApi.getDate());
        var curView = calendarApi.view;
        //console.debug(curView);
        var view = style;
        view = (((view === "tline" || view === "agenda") && this.state.dispSynt) ? "stretch" : "") + view;
        var newview2 = this.state.period;

        if (curView.type.startsWith(view)) {

        } else {
            //alert(newview2);
            //$("#calendar").removeData("config");//???
            var config = this.config;
            if (config) config.baseview = view;

            this.redrawView(newview2, curMoment, view);
        }
    }

    updateDispSynt(dispSynt) {
        if (dispSynt === undefined) dispSynt = this.state.dispSynt;
        var date = moment(this.calendarRef.current.getApi().getDate());


        var curMoment = date;//moment(date)
        var view = this.state.period;

        var baseview = this.state.baseview;
        if (!baseview) { baseview = 'agenda'; }
        if (dispSynt) {
            //$("#calendar .fc-view-container").addClass("nostretch");

            if (baseview === 'tline' || baseview === 'agenda') {
                baseview = 'stretch' + baseview;
            }
        }
        else {
            //$("#calendar .fc-view-container").addClass("nostretch");

            if (baseview === 'stretchtline' || baseview === 'stretchagenda') {
                baseview = baseview.replace("stretch", "");
            }
        }

        var config = this.config;
        if (config) config.baseview = baseview;

        this.redrawView(view, curMoment, baseview);
    }

    updateFilter(filter) {
        let calendarApi = this.calendarRef.current.getApi();
        var config = this.config;
        if (!config || !config.resource || filter !== config.resource.type) {
            if (commonFunc.isBlank(filter)) filter = null;
            if (!config) config = { resource: {} };
            /*if (!config.resource)*/ config.resource = {};
            config.resource.type = filter;

            this.config = config;


            var date = moment(calendarApi.getDate());
            var curMoment = date;//moment(date)
            var view = this.state.period;
            var baseview = this.state.baseview;
            this.redrawView(view, curMoment, baseview);
        }
    }

    redrawView(view, curMoment, baseview, preventMultipleRefresh) {
        var updateBaseview = false;
        if (!baseview) {
            baseview = this.state.baseview;
        }
        if (!baseview) {
            baseview = "agenda";
        } else {
            updateBaseview = true;
        }
        var updatePeriod = (view !== this.state.period);

        console.debug("Loading view " + baseview)
        console.debug("Loading period " + view)
        console.debug(curMoment);

        let calendarApi = this.calendarRef.current.getApi();

        if (baseview === "month") {

            //MAJ du calendar après le re-render
            this.callbackRender = function () {
                this.callbackRender = null;
                calendarApi.changeView(baseview);
            };
            if (updateBaseview) this.setState({ baseview: baseview });
            return;
        }

        var newView = baseview;
        var currentDate = curMoment.format();
        var withoutDate = false;
        switch (view) {
            case "D": //JOUR
                // $("#calendar").fullCalendar("changeView", baseview, curMoment)/*, dateOrRange*/
                newView = baseview;
                currentDate = curMoment.format();
                break;

            case "L": //SEMAINE 5J
                var begin = curMoment.clone().startOf('isoWeek');
                //var end = curMoment.clone().endOf('isoWeek');

                newView = baseview + "5Week";
                currentDate = begin.format();
                break;

            case "W": //SEMAINE
                var beginW = curMoment.clone().startOf('isoWeek'); //moment.startOf('week').isoWeekday(1); //1
                //var endW = curMoment.clone().endOf('isoWeek');
                newView = baseview + "Week";
                currentDate = beginW.format();
                break;


            case "M": //MOIS /JOURS
                var beginM = curMoment.clone().startOf('month'); //moment.startOf('week').isoWeekday(1); //1
                //var endM = curMoment.clone().endOf('month');
                newView = baseview + "31Week";
                switch (beginM.daysInMonth()) {
                    case 28: newView = baseview + "28Week"; break;
                    case 29: newView = baseview + "29Week"; break;
                    case 30: newView = baseview + "30Week"; break;
                    case 31: newView = baseview + "31Week"; break;
                    default: break;
                }

                currentDate = beginM.format();
                break;


            default:
                alert("DEFAULT!" + view);
                newView = view;
                withoutDate = true;
        }
        // $(window).resize();
        if (updateBaseview || updatePeriod) {
            var newState = {};
            if (updateBaseview) {
                newState.baseview = baseview;
                newState.dispSynt = baseview.indexOf("stretch") >= 0;
            }
            if (updatePeriod) newState.period = view;

            //MAJ du calendar après le re-render
            var altCb = this.callbackRender;
            this.callbackRender = function () {
                this.callbackRender = altCb ? altCb : null;
                calendarApi.changeView(newView, (withoutDate ? null : currentDate));
            };
            console.debug("GO LOAD 1");
            if (preventMultipleRefresh) this.preventDataRefresh = 2;
            this.setState(newState);
        }
        else {
            calendarApi.changeView(newView, (withoutDate ? null : currentDate));
        }
    }


    loadPlanningConfig(callback) {
        var context = this;


        if (this.props.dataCfg) {
            var currentRemoteConfig = this.props.dataCfg;

            if (!currentRemoteConfig.configCalendar) {
                currentRemoteConfig.configCalendar = context.userConfigOptions.configCalendar;
            }

            context.userConfigOptions = currentRemoteConfig;
        } else {
            //Echec de config
            console.log("Pas de configuration spécifique");
        }

        //TODO : gestion liste de rapports sidebar
        /*if (context.userConfigOptions.customList && context.userConfigOptions.customList.length) {
            var insurances = $(".mask-insurances");
            var experts = $(".mask-experts");
            var templates = $(".mask-templates");
            insurances.find('option:not(.default-choice)').remove().end();
            experts.find('option:not(.default-choice)').remove().end();
            templates.find('option:not(.default-choice)').remove().end();
            $.each(context.userConfigOptions.customList, function (i, e) {
                switch (e.Type) {
                    case 1:
                        insurances.append(new Option(e.Name, e.Name));
                        insurances.find("option:last").data("cacheopt", e);
                        break;
                    case 2:
                        experts.append(new Option(e.Name, e.Name));
                        experts.find("option:last").data("cacheopt", e);
                        break;
                    case 3:
                        templates.append(new Option(e.Template, e.Name));
                        templates.find("option:last").data("cacheopt", e);
                        break;
                }
            });

            insurances.each(function (i, e) {
                if ($(e).is("datalist")) {
                    var input = $(e).prev();
                    input.change(function () {
                        var opt = $(e).find("option").filter(function () {
                            return $(this).html() == input.val();
                        });
                        if (opt && opt.length && opt.data("cacheopt")) {
                            input.attr("data-template", opt.data("cacheopt").Template || 0);
                        }
                    });
                }
            });
        }*/

        if (callback && typeof callback == "function") callback(context.userConfigOptions.configCalendar);
    }

    loadStatus(callback) {
        var context = this;

        var args = null;// { Categ: "" };
        return commonFunc.getMethod("../api/report/GetStatus", args).then((response) => {
            if (response.data.isSuccess) {
                response.data.result.forEach(dt => {
                    dt.css = dt.css ? dt.css.replaceAll('label', 'badge') : dt.css;
                });
                context.statuts = response.data.result;

            } else {
                alert("ko");
            }
            if (callback && typeof (callback) === "function") { callback(response.data); }
        });
    }

    loadServices(callback) {
        var context = this;

        var args = null;
        return commonFunc.getMethod("../api/site/GetSiteServices", args).then((response) => {
            if (response.data.isSuccess) {
                context.services = response.data.result.filter(svc => svc.idService > 0);

            } else {
                alert("ko");
            }
            if (callback && typeof (callback) === "function") { callback(response.data); }
        });
    }

    loadClients(callback) {
        var context = this;

        //var args = {};
        //args.state = 900;

        var date = moment(this.calendarRef.current.getApi().getDate());
        var curMoment = moment(date);
        var view = this.state.period.replace("L", "W");

        var args = { period: view, state: 900, startdate: curMoment.toISOString() };

        var url = "../api/client/GetWithAgreement";
        //Check Config NoContract
        var flagNoContract = false;
        var siteForClients = context.props.getSite();
        if (siteForClients) {
            var siteExtras = siteForClients.extras;
            if (!(siteExtras && siteExtras.length && siteExtras.filter(extra => extra.name === "withContract").length > 0)) {
                url = url.replace("client/GetWithAgreement", "client/GetClients");
            }
        }

        return commonFunc.getMethod(url, args).then((response) => {

            if (response.data.isSuccess) {
                context.clients = response.data.result;

            } else {
                alert("ko");
            }
            if (callback && typeof (callback) === "function") { callback(response.data); }
        });
    }

    loadEvents(start, end, timezone, callback) {
        if (this.state.loading) { callback([]); return; }
        if (this.preventEventsRefresh > 0) { this.preventEventsRefresh -= 1; callback([]); return; }
        if (this.preventDataRefresh > 0) { this.preventDataRefresh -= 1; callback([]); return; }

        var context = this;

        if (this.offCanvasReportRef.current) this.offCanvasReportRef.current.close();

        var curMoment = moment(start);
        var view = this.state.period.replace("L", "W");

        var argse = { period: view, state: 6 };
        //var argse = { period: view, state: 14 }; //Affichage des non affectés
        argse.startdate = curMoment.toISOString();

        var url = "../api/employee/GetEmployeesEvents";
        var config = this.config;
        if (config) {
            argse.id = config.resource.id;

            switch (config.resource.type) {
                case "employee": /*args.resType = 0;*/ url = "../api/employee/GetEmployeesEvents"; break;
                case "client": url = "../api/client/GetClientsEvents"; /*argse.state = 2;*/ break;
                //case "product": /*args.resType = 256;*/ url = "../data/GetReportsActivities"; break;
                //case "material": /*args.resType = 4;*/ url = "../data/GetReportsEmployees"; break;
                default: /*args.resType = 0;*/ url = "../api/employee/GetEmployeesEvents"; break;
            }
        }

        commonFunc.getMethod(url, argse).then((responseEvent) => {

            //console.debug(datae);
            if (responseEvent.data.isSuccess) {
                //var cpt = 0;

                var events = [];
                events.push({
                    title: 'Aucune',
                    start: start,
                    end: end,
                    id: 0,
                    resourceId: 0,
                    editable: false,
                    color: 'black',
                    description: 'n/a',
                    dsysmod: moment(new Date()),
                    clientId: 0,
                    isSpecialEvent: false,
                    state: 10,
                    serviceId: 0
                });

                //var curSvc = null;
                //var curReportStatus = null; 
                responseEvent.data.result.forEach(dt => {
                    //curSvc = getSvcById(context.services, dt.serviceID);
                    if (dt.reportID > 0 && dt.isValid) {
                        //curReportStatus = getStatusById(context.statuts, dt.reportStatus);
                        //dt.isValid = (curReportStatus.extId > 0 || curReportStatus.extId <= 0 && curReportStatus.value == "1");
                    }

                    //Gestion UTC
                    dt.startDate = dt.startDate + "Z";
                    dt.endDate = dt.endDate + "Z";

                    var resourceId = dt.employeeID;
                    if (config) {
                        switch (config.resource.type) {
                            case "employee": resourceId = dt.employeeID; break;
                            case "client": resourceId = dt.clientID; break;
                            //case "product": /*args.resType = 256;*/ url = "../data/GetReportsActivities"; break;
                            //case "material": /*args.resType = 4;*/ url = "../data/GetReportsEmployees"; break;
                            default: resourceId = dt.employeeID; break;
                        }
                    }
                    //console.debug(context.state.baseview);
                    events.push({
                        //title: decodeEntities(dt.title).substr(0, dt.title.indexOf(']') === -1 ? dt.title.length : dt.title.indexOf(']') + 1),
                        title: commonFunc.decodeEntities(dt.title).replace('[', '\n[').replace(']', ']\n'),
                        start: dt.startDate, // will be parsed
                        end: dt.endDate,
                        id: dt.idEvent,
                        resourceId: resourceId,
                        editable: context.props.checkRights("PLANNING", "U") && ((dt.reportID > 0 && dt.report.status.step < 1) || (dt.reportID <= 0 && !dt.isValid)), //Non Validé, Non Envoyé ou rapport =0
                        resourceEditable: context.props.checkRights("PLANNING", "U") && ((dt.reportID > 0 && dt.report.status.step < 1) || (dt.reportID <= 0 && !dt.isValid)),//Non A Corriger
                        type: dt.resourceType, //ADD
                        //className
                        backgroundColor: context.userConfigOptions.configCalendar.useResourceColor ? getColorById(context.employees, dt.employeeID) : dt.service.startColor,
                        textColor: "#FFF",//curSvc.lang, //ADD
                        borderColor: context.state.baseview.endsWith("list") ? dt.service.startColor : "#000",
                        //classNamesID
                        description: commonFunc.decodeEntities(dt.comments),
                        dsysmod: moment(dt.dateSysMod),
                        clientId: dt.clientID,
                        isSpecialEvent: false,
                        state: dt.statusID,
                        // d:dt.
                        employeeID: dt.employeeID,
                        serviceId: dt.serviceID,
                        length: dt.length,
                        validated: dt.isValid,
                        locked: dt.reportID > 0,
                        reportId: dt.reportID,
                        reportStatus: dt.reportID > 0 ? dt.report.statusID : 0,
                        /*sessionId: dt.sessionId,
                        sessionStatus: dt.sessionStatus,
                        bookingId: dt.bookingId,
                        bookingType: dt.bookingType,*/
                        src: dt
                    });
                    console.debug(events);
                    //cpt += 2;
                });

                //console.debug(events);
                callback(events);
                //$(window).resize();
                //this.updateDispSynt();




            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Interventions',
                    text: 'Echec de chargement des interventions \n ' + responseEvent.data.errorMessage,
                });
            }
        });
    }

    convertResources(list, type) {
        var results = [];
        var cpt = 0;

        list.forEach(dt => {

            switch (type) {
                /*case "employee":
                    results.push({
                        title: dt.value,
                        id: dt.id,
                        //eventColor: 'red',
                        // eventColor:rainbowRGBA(data.result.length*4,cpt*2,0.85),
                        //eventColor: "rgb(" + hexToRgb(rainbow(data.result.length * 2, cpt)) + ")",
                        eventClassName: "event-res-" + dt.id,
                        //businessHours
                        description: dt.comments,
                        agency: dt.desc,
                        avatar: dt.icon,//dt.icon.replace("../api/data/", "../home/").replace("../data/", "../home/"),
                        //icon: dt.icon
                        icon: commonFunc.rainbowRGBA(list.length, cpt + 1, 0.35),
                        amount: dt.amount
                        //icon: isBlank(dt.icon) ? rainbowRGBA(8, cpt + 1, 0.35) : dt.icon,
                    });
                    break;*/
                case "employee":
                    if (dt.idEmployee > 0) {
                        results.push({
                            title: dt.name + " " + dt.firstName,
                            id: dt.idEmployee,
                            //eventColor: 'red',
                            // eventColor:rainbowRGBA(data.result.length*4,cpt*2,0.85),
                            //eventColor: "rgb(" + hexToRgb(rainbow(data.result.length * 2, cpt)) + ")",
                            eventClassName: "event-res-" + dt.idEmployee,
                            //businessHours
                            description: dt.gsm,
                            agency: dt.group ? dt.group.title : "",
                            avatar: dt.avatar,//dt.icon.replace("../api/data/", "../home/").replace("../data/", "../home/"),
                            //icon: dt.icon
                            icon: commonFunc.rainbowRGBA(list.length, cpt + 1, 0.35),
                            amount: ""
                            //icon: isBlank(dt.icon) ? rainbowRGBA(8, cpt + 1, 0.35) : dt.icon,
                        });
                    }
                    break;
                case "client":
                    if (dt.idClient > 0) {
                        results.push({
                            title: dt.name + " " + dt.firstName,
                            id: dt.idClient,
                            eventClassName: "event-res-" + dt.id,
                            description: dt.gsm,
                            agency: "",
                            avatar: dt.avatar,
                            icon: commonFunc.rainbowRGBA(list.length, cpt + 1, 0.35),
                            amount: ""
                        });
                    }
                    break;
                case "product": break;
                case "material": break;
                default:
                    if (dt.idEmployee > 0) {
                        results.push({
                            title: dt.name + " " + dt.firstName,
                            id: dt.idEmployee,
                            //eventColor: 'red',
                            // eventColor:rainbowRGBA(data.result.length*4,cpt*2,0.85),
                            //eventColor: "rgb(" + hexToRgb(rainbow(data.result.length * 2, cpt)) + ")",
                            eventClassName: "event-res-" + dt.idEmployee,
                            //businessHours
                            description: dt.gsm,
                            agency: dt.group ? dt.group.title : "",
                            avatar: dt.avatar,//dt.icon.replace("../api/data/", "../home/").replace("../data/", "../home/"),
                            //icon: dt.icon
                            icon: commonFunc.rainbowRGBA(list.length, cpt + 1, 0.35),
                            amount: ""
                            //icon: isBlank(dt.icon) ? rainbowRGBA(8, cpt + 1, 0.35) : dt.icon,
                        });
                    }
                    break;
            }

            cpt += 2;
        });

        return results;
    }

    loadResources(start, end, timezone, callback) {
        if (this.state.loading) { callback([]); return; }
        if (this.preventDataRefresh > 0) { this.preventDataRefresh -= 1; callback([]); return; }

        var context = this;

        var curMoment = start;
        var view = this.state.period.replace("L", "W");

        var url = "../api/employee/GetWithContract";
        var args = { period: view, state: 208, startdate: curMoment.toISOString() };
        var config = this.config;
        if (config) {
            // args = { id: config.resource.id, resType: config.resGrp };
            args.id = config.resource.id;
            switch (config.resource.type) {
                case "employee": /*args.resType = 0;*/ url = "../api/employee/GetWithContract"; break;
                case "client": /*args.resType = 2;*/ url = "../api/client/GetWithAgreement"; break;
                case "product": /*args.resType = 256;*/ url = "../data/GetReportsActivities"; break;
                case "material": /*args.resType = 4;*/ url = "../data/GetReportsEmployees"; break;
                default: /*args.resType = 0;*/ url = "../api/employee/GetWithContract"; break;
            }
        }
        //console.debug(config && config.resource ? config.resource.type : "");


        //Check Config NoContract
        var flagNoContract = false;
        var siteForResources = context.props.getSite();
        if (siteForResources) {
            var siteExtras = siteForResources.extras;
            if (!(siteExtras && siteExtras.length && siteExtras.filter(extra => extra.name === "withContract").length > 0)) {
                url = url.replace("employee/GetWithContract", "employee/GetEmployees").replace("client/GetWithAgreement", "client/GetClients");
                flagNoContract = true;
                delete args.state;
            }
        }

        //TODO: C'est pas possible de laisser ca!

        commonFunc.getMethod(flagNoContract ? "../api/employee/GetEmployees" : "../api/employee/GetWithContract", args).then((responseEmployee) => {
            commonFunc.getMethod(flagNoContract ? "../api/client/GetClients" : "../api/client/GetWithAgreement", args).then((responseClient) => {
                var data = responseEmployee.data;
                if (config) {
                    switch (config.resource.type) {
                        case "employee": /*args.resType = 0;*/ data = responseEmployee.data; break;
                        case "client": /*args.resType = 2;*/ data = responseClient.data; break;
                        case "product": /*args.resType = 256;*/ data = {}; break;
                        case "material": /*args.resType = 4;*/ data = {}; break;
                        default: /*args.resType = 0;*/ data = responseEmployee.data; break;
                    }
                }

                //console.debug(datae);
                if (data.isSuccess) {
                    // var data = getResources();
                    var results = [];
                    var cpt = 0;


                    if (true) {
                        results = context.convertResources(data.result, (config ? config.resource.type : ""));

                        if (responseEmployee.data.isSuccess && (!args.id || context.employees.length <= 0)) context.employees = context.convertResources(responseEmployee.data.result, "employee");
                        if (responseClient.data.isSuccess && (!args.id || context.clients.length <= 0)) context.clients = context.convertResources(responseClient.data.result, "client");
                    } else {
                        //Tri par Agence
                        var current = null;
                        data.result.forEach(dt => {
                            if (commonFunc.isBlank(dt.desc)) {
                                dt.desc = 'N/A';
                            }

                            if (current == null || current.title !== dt.desc) {
                                //Nouvelle Agence
                                if (current != null) {
                                    results.push(current);
                                }

                                current = {
                                    title: dt.desc,
                                    id: results.length + 1,
                                    icon: dt.icon,
                                    children: [{
                                        title: dt.value,
                                        id: dt.id,
                                        eventClassName: "event-res-" + dt.id,
                                        description: dt.comments,
                                        agency: dt.desc,
                                        icon: dt.icon
                                    }],
                                    eventClassName: "section-" + cpt,
                                    isSpecialSection: true,
                                };
                            } else {
                                current.children.push({
                                    title: dt.value,
                                    id: dt.id,
                                    eventClassName: "event-res-" + results.length,
                                    description: dt.comments,
                                    agency: dt.desc,
                                    icon: dt.icon
                                });
                            }
                            cpt += 2;
                        });

                        if (current != null) {
                            results.push(current);
                        }
                    }

                    callback(results, data);
                    if (config) {//TODO : Check si besoin d'appeler ça ou non
                        //this.redrawView(config.view, config.sdate, config.baseview);
                    } else {
                        console.log("No Config");
                    }

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ressources',
                        text: 'Echec de chargement des resources \n ' + data.errorMessage,
                    });
                }
            });
        });
    }

    bindPlanningServicesOpt() {
        var groupedOptions = {};
        this.services.forEach((dt) => {
            if (dt.categ !== "ACT0" && !dt.isContract) {
                if (!groupedOptions[dt.categ]) {
                    groupedOptions[dt.categ] = [];
                }
                groupedOptions[dt.categ].push(dt);
            }
        });

        return Object.keys(groupedOptions).map((dt, index) => {
            return (<optgroup key={index} label={groupedOptions[dt][0].categ}>
                {groupedOptions[dt].map((e, i) => {
                    return (<option key={i} value={e.idService}>{e.name}</option>)
                })}
            </optgroup>);
        });
    }

    bindClients() {
        return this.clients.map((client, i) => (<option key={i} value={client.id}>{client.title}</option>));
    }

    findResourceChange(value) {
        let calendarApi = this.calendarRef.current.getApi();

        var curMoment = new moment(calendarApi.getDate());
        var view = this.state.period;
        var resource = calendarApi.getResourceById(value);
        var baseview = "groupDayRes";
        if (resource) {
            if (this.config && this.config.resource) resource.type = this.config.resource.type;
            baseview = this.state.dispSynt ? "stretchagenda" : "agenda";
            this.config = { view: view, sdate: curMoment, baseview: baseview, resource: resource, resGrp: 0 };
            this.redrawView(view, curMoment, baseview);
        } else {
            if (this.config && this.config.resource && this.config.resource.type) this.config = { "resource": { "type": this.config.resource.type } }
            else this.config = null;

            //MAJ du calendar après le re-render
            this.callbackRender = function (newContext) {
                this.callbackRender = null;
                this.updateDispSynt();
            };
            this.setState({ baseview: (this.userConfigOptions && this.userConfigOptions.configCalendar && this.userConfigOptions.configCalendar.useDefaultBaseView) ? this.userConfigOptions.configCalendar.useDefaultBaseView : "tline" });
        }
    }

    addOrUpdateItv(src) {
        var context = this;
        let calendarApi = this.calendarRef.current.getApi();

        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }

        var itvId = 0;
        var multiSalDefault = -1;
        if (src == null) {
            multiSalDefault = 0;
        }
        else {
            //console.debug(src);
            if (parseInt(src.idEvent) > 0) {
                //Mise à jour
                itvId = src.idEvent;
            } else {
                //Ajout via planning
                multiSalDefault = src.employeeID;
            }
        }

        var indexDefault = 0;
        var employeesWithDefault = this.employees.map((e, i) => {
            if (e.id == multiSalDefault) indexDefault = i + 1;//+1 pour unshift
            return { value: e.id, label: e.title };
        });
        employeesWithDefault.unshift({ value: -1, label: "Non Défini" });

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (data) {
            console.debug(data);

            if (data) {
                var empList = [];
                if (multiSalDefault >= 0) {
                    empList = data.aupdItvSalMulti.map((e) => {
                        return e.value;
                    });
                    //Boucle pour récupérer tout les intervenant de la liste
                    if (empList.length == 0) {// || $('#aupdItvSalMulti').val().length > 1
                        empList = employeesWithDefault.map((e) => {
                            return e.value;
                        });
                    }
                }
                else {
                    empList.push(data.aupdItvSal);
                }
                var svcEvt = context.services.filter((svc) => { return svc.idService == data.aupdItvSvc });

                var evt = new aShortEvent();
                evt.clientID = data.aupdItvCli;
                evt.serviceID = data.aupdItvSvc;
                if (evt.clientID > 0) {
                    console.debug(data);
                    var cliEvt = context.clients.filter((client) => { return client.id == parseInt(evt.clientID) });
                    evt.title = (cliEvt.length ? (cliEvt[0].title) : "") + (svcEvt.length ? (" - " + svcEvt[0].name) : "");
                } else {
                    evt.title = svcEvt.length ? svcEvt[0].name : "";
                }
                evt.isValid = data.aupdItvVal;
                evt.comments = data.aupdItvCmt;
                evt.value = isNaN(data.aupdItvValue) ? 0 : data.aupdItvValue;
                //evt.statusID = 1;


                var dt = svcEvt[0];


                switch (dt.unit) {
                    case "H":
                        var dateH = commonFunc.formatDateDMY(new Date(data.aupdItvSd));
                        var startH = moment(new Date(data.aupdItvSh)).format("HH:mm");
                        var endH = moment(new Date(data.aupdItvEh)).format("HH:mm");
                        evt.startDate = commonFunc.stringToDateRaw(dateH + ' ' + startH);
                        evt.endDate = commonFunc.stringToDateRaw(dateH + ' ' + endH);
                        break;

                    case "D":
                    case "DH":
                        var dateD = commonFunc.formatDateDMY(new Date(data.aupdItvSdDH));
                        switch (parseInt(data.aupdItvSdDHC)) {
                            case 0: //AM
                                evt.startDate = commonFunc.stringToDateRaw(dateD + ' 00:00');
                                evt.endDate = commonFunc.stringToDateRaw(dateD + ' 13:00');
                                break;
                            case 1:
                                evt.startDate = commonFunc.stringToDateRaw(dateD + ' 13:00');
                                evt.endDate = commonFunc.stringToDateRaw(dateD + ' 23:59');
                                break;
                            case 2:
                                evt.startDate = commonFunc.stringToDateRaw(dateD + ' 00:00');
                                evt.endDate = commonFunc.stringToDateRaw(dateD + ' 23:59');
                                break;
                            default: break;
                        }
                        break;

                    case "J":
                        evt.startDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvSdJ)) + ' 00:00');
                        evt.endDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvEdJ)) + ' 23:59');
                        break;


                    case "C":
                    case "DJ":
                        var sdateDJ = commonFunc.formatDateDMY(new Date(data.aupdItvSdDJ));
                        var edateDJ = commonFunc.formatDateDMY(new Date(data.aupdItvEdDJ));
                        switch (parseInt(data.aupdItvSdDJC)) {
                            case 0: //AM
                                evt.startDate = commonFunc.stringToDateRaw(sdateDJ + ' 00:00');
                                break;
                            case 1:
                                evt.startDate = commonFunc.stringToDateRaw(sdateDJ + ' 13:00');
                                break;
                            case 2:
                                evt.startDate = commonFunc.stringToDateRaw(sdateDJ + ' 00:00');
                                break;
                            default: break;
                        }

                        switch (parseInt(data.aupdItvEdDJC)) {
                            case 0: //AM
                                evt.endDate = commonFunc.stringToDateRaw(edateDJ + ' 13:00');
                                break;
                            case 1:
                                evt.endDate = commonFunc.stringToDateRaw(edateDJ + ' 23:59');
                                break;
                            case 2:
                                evt.endDate = commonFunc.stringToDateRaw(edateDJ + ' 23:59');
                                break;
                            default: break;
                        }
                        break;


                    case "JH":
                        var startJH = moment(new Date(data.aupdItvShJH)).format("HH:mm");
                        var endJH = moment(new Date(data.aupdItvEhJH)).format("HH:mm");
                        evt.startDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvSdJH)) + ' ' + startJH);
                        evt.endDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvEdJH)) + ' ' + endJH);
                        break;
                    default: break;

                }


                var diff = evt.endDate - evt.startDate;
                var diffdt = commonFunc.makeTimeFromMs(diff);
                evt.length = parseFloat(commonFunc.makeDecimal(commonFunc.makeShortTime(diffdt)));

                empList.forEach(function (e, i) {
                    var empId = e;//$('#aupdItvSalMulti').val()
                    evt.employeeID = empId;

                    //console.debug(evt);
                    if (multiSalDefault >= 0) {

                        Swal.fire({
                            title: 'Enregistrement en cours..',
                            // html: 'I will close in <strong></strong> seconds.',
                            didOpen: () => { Swal.showLoading(); },
                        });

                        commonFunc.postMethod("../api/employee/AddEvent", evt).then((response) => {
                            var data = response.data;
                            Swal.close();
                            if (data && data.isSuccess) {
                                //$('#calendar').fullCalendar('refetchEvents');
                                if (empList.length == (i + 1)) {
                                    context.refreshContent();
                                }


                            } else {
                                var supErrors = "";
                                if (data.errors && data.errors.length) {
                                    supErrors = data.errors.map((error) => {
                                        return "<br>" + error.desc + "(" + error.value + ")";
                                    });
                                }
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Echec',
                                    html: "Echec de création de l'intervention<br><b>" + data.errorMessage + supErrors + "</b>"
                                });
                            }

                        });

                    } else {
                        evt.idEvent = itvId;
                        if (evt.idEvent > 0) {


                            Swal.fire({
                                title: 'Mise à jour en cours..',
                                // html: 'I will close in <strong></strong> seconds.',
                                didOpen: () => { Swal.showLoading(); },
                            });

                            commonFunc.postMethod("../api/employee/UpdateEvent", evt).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    //$('#calendar').fullCalendar('refetchEvents');
                                    context.refreshContent();


                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Echec',
                                        html: "Echec de création de l'intervention<br><b>" + data.errorMessage + "</b>",
                                        didOpen: () => { Swal.hideLoading() }
                                    });
                                }

                            });


                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Echec',
                                html: "Echec de mise à jour , ID de l'intervention manquante"
                            });

                        }


                    }
                });
                context.modalRoot.unmount();
            }
            else {
                context.modalRoot.unmount();

                if (src != null) {
                    if (src.revertFunc && typeof (src.revertFunc === "function")) {
                        src.revertFunc();
                    } else {
                        //$('#calendar').fullCalendar('refetchEvents');
                        calendarApi.refetchEvents();
                    }
                }
            }
        };

        this.modalRoot = createRoot(document.createElement('div'));
        this.modalRoot.render(<PlanItvModal parentContext={this} itvId={itvId} multiSalDefault={multiSalDefault} indexDefault={indexDefault} employeesWithDefault={employeesWithDefault} src={src}
            callback={callbackModal}></PlanItvModal>);
    }


    updateReport(data, tagEvent) {
        let calendarApi = this.calendarRef.current.getApi();

        var context = this;

        switch (tagEvent) {
            case "resize":
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Impossible de changer la durée d'une intervention directement depuis le planning"
                });
                if (data.revertFunc && typeof (data.revertFunc === "function")) {
                    data.revertFunc();
                }
                break;
            case "update":
            case "change":
            case "drop":
                /*Swal.fire({
                    icon: 'question',
                    title: 'Modification',
                    html: 'Veuillez confirmer la modification sur cette intervention ? [' + data.idEvent + '] ' + (!commonFunc.isBlank(data.title) ? data.title : (data.report ? data.report.refID : "")),
                    showCancelButton: true,
                    confirmButtonColor: '#28a745',
                    cancelButtonColor: '#dc3545',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Modification',
                            html: 'Modification en cours...',
                            didOpen: () => { Swal.showLoading() }
                        });

                        data.client = null;
                        data.employee = null;
                        data.material = null;
                        data.report = null;
                        data.service = null;

                        data.allowReassign = true;
                        //TODO : Voir pour statusID=2 Pour planifié et pas seulement Brouillon
                        commonFunc.postMethod("../api/report/ScheduleItvReport", data).then((response) => {
                            var updateResult = response.data;
                            if (updateResult && updateResult.isSuccess) {
                                //Aucune demande associé
                                Swal.close();
                                Swal.hideLoading();
                                calendarApi.refetchEvents();

                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Echec',
                                    html: "Echec de modification de l'intervention planning<p>" + updateResult.errorMessage + "</p>",
                                    didOpen: () => { Swal.hideLoading() }
                                });
                                if (data.revertFunc && typeof (data.revertFunc === "function")) {
                                    data.revertFunc();
                                }
                            }

                        });
                    }
                    else {
                        if (data.revertFunc && typeof (data.revertFunc === "function")) {
                                    data.revertFunc();
                                }
                    }
                });*/

                Swal.fire({ title: 'Chargement en cours...', didOpen: () => { Swal.showLoading(); } })

                commonFunc.getMethod("../api/report/GetReport", { id: data.reportID }).then((response) => {
                    if (response.data && response.data.isSuccess) {
                        var callback = function (dataCb, isUpdate) {
                            return new Promise((resolve, reject) => {
                                if (data.revertFunc && typeof (data.revertFunc === "function")) {
                                    data.revertFunc();
                                }
                                if (isUpdate === "success") {
                                    calendarApi.refetchEvents();
                                }
                                context.modalRoot.unmount();
                                resolve();
                            });
                        };

                        //EMPLOYEE_EVENTS U
                        var configModel = {
                            objType: "model",
                            templates: [{
                                objType: "template",
                                categories: [{
                                    objType: "category",
                                    headers: [{
                                        objType: "header",
                                        groups: [{
                                            objType: "group",
                                            items: [
                                                {
                                                    "objType": "textview",
                                                    "arguments": "{ \"dialogConfig\": \"DLG_ITV\", \"urlDlgConfig\": \"api/report/GetReportModels\", \"dialogType\": \"report\", \"dialogSubType\": \"itv\", \"dialogIDLabel\": \"IDReport\", \"dialogSize\": \"lg\", \"allowReassign\": true }",
                                                    "onActionEvents": {
                                                        "onLoad": [{ "value": "confirmItvReport", "target": "", "trigger": "dialogModel" }],
                                                        "extrasActionEvent": { "confirmItvReport": [{ "value": 1, "target": "", "trigger": "confirmPlanItv" }] }
                                                    }
                                                }
                                            ]
                                        }]
                                    }]
                                }]
                            }]
                        }

                        var report = response.data.result;
                        report.itv = data;
                        report.itv.startDate = report.itv.startDate.replace("Z", "");
                        report.itv.endDate = report.itv.endDate.replace("Z", "");

                        context.modalRoot = createRoot(document.createElement('div'));
                        context.modalRoot.render(<AGenerateModel parentProps={{ type: "report", dataCfg: context.props.dataCfg, autoClose: true }} ref={context.modalGenManager} noButtons
                            onSubmit={(dataSubmit, isUpdate) => { return callback(dataSubmit, isUpdate) }} appProps={context.props} checkRights={context.props.checkRights}
                            getRoutes={context.props.getRoutes} configModel={configModel} modelsList={null} loadedData={report} />);

                        Swal.close();
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: "Echec de chargement de l'intervention :<br>" + data.errorMessage
                        });
                        if (data.revertFunc && typeof (data.revertFunc === "function")) {
                            data.revertFunc();
                        }
                    }
                });

                break;
            default:
                if (data.revertFunc && typeof (data.revertFunc === "function")) {
                    data.revertFunc();
                }
                break;
        }
    }



    printPlanning() {
        //TODO
    }

    refreshReportsExtList(configCalendar, withDeleted, callback) {
        return new Promise((resolve, reject) => {
            var context = this;

            var date = moment(this.calendarRef.current.getApi().getDate());
            var curMoment = moment(date);
            var view = this.state.period.replace("L", "W");

            var argse = { period: view, state: (withDeleted ? 24 : 8) };
            argse.startdate = curMoment.toISOString();

            var url = "../api/employee/GetEmployeesEvents";
            var config = this.config;
            if (config) {
                argse.id = config.resource.id;

                switch (config.resource.type) {
                    case "employee": /*args.resType = 0;*/ url = "../api/employee/GetEmployeesEvents"; break;
                    case "client": /*args.resType = 2;*/ url = "../api/client/GetClientsEvents"; break;
                    //case "product": /*args.resType = 256;*/ url = "../data/GetReportsActivities"; break;
                    //case "material": /*args.resType = 4;*/ url = "../data/GetReportsEmployees"; break;
                    default: /*args.resType = 0;*/ url = "../api/employee/GetEmployeesEvents"; break;
                }
            }

            commonFunc.getMethod(url, argse).then((responseEvent) => {
                var data = responseEvent.data;
                if (data.isSuccess) {
                    resolve(data.result);
                }
                else {
                    resolve([]);
                }
            });
        });
    }

    displayIframe(title, children, options, callback) {
        var context = this;
        //let calendarApi = this.calendarRef.current.getApi();

        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }

        var settings = Object.assign({}, {
            iframeId: '',
            iframeUrl: '',
            cancelable: false,
            dialogSize: 'lg', //xs,sm,m,lg
            dialogType: '', //success,info,warning,danger,striped
            dialogInput: 1, //0,1,2,3,4
            dialogResult: 0, //0,1,2
            iconClass: '', //fa fa-spinner fa-spin
            autoClose: 0, //10000

            noScroll: false
        }, options);

        if (!callback) {
            callback = (data) => {
                console.debug(context.modalRoot);
                console.debug(data);
                if (data) {
                }
                else {
                    context.modalRoot.unmount();
                }
            }
        }


        this.modalRoot = createRoot(document.createElement('div'));
        this.modalRoot.render(<Modal className={`${settings.noScroll ? "modal-noscroll" : ""} p-0`} isOpen={true} size="lg" scrollable keyboard={false} toggle={() => callback()} backdrop="static">
            <ModalHeader className="d-flex" toggle={() => callback()}>{title}</ModalHeader>
            <ModalBody className="px-2 py-0">
                {children}

                <iframe id="geoIframe" title="geoIframe" src={settings.iframeUrl} scrolling="no" height="550" width="750" style={{ border: 0 }} data-srcinit="false" onLoad={() => {
                    /*var fd = $(this)[0].contentWindow || $(this)[0].document;
                    if (fd && typeof (fd.initialize == "function")) {
                        if (settings.args) {
                            result = fd.initialize(settings.args, false);
                        } else {
                            result = fd.initialize(null, true);
                        }
                    }*/
                }}></iframe>
            </ModalBody>
            <ModalFooter className="px-2">
                <Button className="px-3" color="danger" onClick={() => callback()}>
                    <i className="fa fa-times"></i>Fermer
                </Button>
            </ModalFooter>
        </Modal>);
    }
}


const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}


export default withRouter(withI18n(FCPlanning));


//TODO
//Intégration de bindControls ??

//bindPlanningServicesOpt >> va pas être implémenté car chargement des liste via render

//recreateReport
//sendReport
//replanReport
//refreshReportsExtList
//removeReportFast


//Création du schéma pour le paramètrage du formulaire
const itvModalSchema = yup.object().shape({
    aupdItvSal: yup.number().required("Ce champ est obligatoire"),
    aupdItvCli: yup.number(),//.required("Ce champ est obligatoire")
    aupdItvSvc: yup.number().required("Ce champ est obligatoire"),
    aupdItvVal: yup.bool(),
    aupdItvValue: yup.string(),
    aupdItvCmt: yup.string(),

    currentDateMode: yup.string(),
    aupdItvSd: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSh: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//time
    aupdItvEh: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//time
    aupdItvSdDHC: yup.number().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DH" ? schema.required("Ce champ est obligatoire") : schema;
    }),
    aupdItvSdDH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DH" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSdJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "J" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvEdJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "J" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSdDJC: yup.number().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),
    aupdItvSdDJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvEdDJC: yup.number().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),
    aupdItvEdDJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSdJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//date
    aupdItvShJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//time
    aupdItvEdJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//date
    aupdItvEhJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//time
}).required();

//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de PlanItvModal
function PlanItvModal(props) {
    //Equivalent à componentDidMount ( OU componentDidUpdate etc... en fonction des paramètres)
    React.useEffect(() => {
        if (props.src) {
            var sd = commonFunc.isValidDate(props.src.startDate) ? props.src.startDate : commonFunc.restStringToDate(props.src.startDate);
            var ed = commonFunc.isValidDate(props.src.endDate) ? props.src.endDate : commonFunc.restStringToDate(props.src.endDate);

            let defaultValues = {};
            if (commonFunc.isValidDate(sd) && sd.getFullYear() > 1970) {
                //var ash = commonFunc.makeShortTime(sd);
                //var asd = commonFunc.formatDateDMY(sd, true);
                var ash = sd;
                var asd = sd;

                defaultValues.aupdItvSh = ash;
                defaultValues.aupdItvSd = asd;
                defaultValues.aupdItvSdDH = asd;
                defaultValues.aupdItvShJH = ash;
                defaultValues.aupdItvSdJH = asd;
                defaultValues.aupdItvSdDJ = asd;
                defaultValues.aupdItvSdJ = asd;
            }

            if (commonFunc.isValidDate(ed) && ed.getFullYear() > 1970) {

                //var aeh = commonFunc.makeShortTime(ed);
                //var aed = commonFunc.formatDateDMY(ed, true);
                var aeh = ed;
                var aed = ed;

                defaultValues.aupdItvEh = aeh;
                defaultValues.aupdItvEd = aed;
                defaultValues.aupdItvEdJ = aed;
                defaultValues.aupdItvEhJH = aeh;
                defaultValues.aupdItvEdJH = aed;
                defaultValues.aupdItvEdDJ = aed;


            }

            defaultValues.aupdItvValue = props.src.value;
            defaultValues.aupdItvCmt = props.src.comments;
            defaultValues.aupdItvVal = props.src.isValid;

            console.debug(defaultValues);
            reset({ ...defaultValues });
        }

        modalUpdateSvc(null);
    }, []);

    //Fonctions utilitaires du formulaire
    const { control, register, getValues, setValue, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(itvModalSchema) });
    const formManager = {
        control: control,
        register: register,
        getValues: getValues,
        setValue: setValue,
        handleSubmit: handleSubmit,
        reset: reset,
        errors: errors
    };

    //Gestion de donénes utilile au Dialog
    const [itvSalMulti, setItvSalMulti] = React.useState([props.multiSalDefault >= 0 ? props.employeesWithDefault[props.indexDefault] : null]);
    const [itvSalMultiValid, setItvSalMultiValid] = React.useState(true);
    const [rdvPointID, setRdvPointID] = React.useState(-1);
    const [rerender, setRerender] = React.useState(false);

    //Process effectué lors du changement de service (changement d'affichage des dates)
    function modalUpdateSvc(e) {
        var value;
        if (!e) {
            if (props.src && props.src.serviceID > 0) {
                value = props.src.serviceID;
            }
            else {
                if (props.parentContext.services.length) {
                    var filteredServices = props.parentContext.services.filter(svc => svc.categ !== "ACT0" && !svc.isContract);
                    value = filteredServices.length ? filteredServices[0].idService : 0;
                }
                else {
                    value = 0;
                }
            }
        }
        else {
            value = e.target.value;
        }
        var svcTarget = props.parentContext.services.filter((svc) => { return svc.idService == value; });
        if (svcTarget.length) {
            var dt = svcTarget[0];
            var curTag = dt.unit;
            switch (dt.unit) {
                case "H": break;
                case "D": curTag = "DH"; break;
                case "C": curTag = "DJ"; break;
                default: break;
            }

            if (dt.categ !== "ACT") {
                setValue("aupdItvCli", -1);
            }

            setValue("currentDateMode", curTag);
            setRerender(!rerender);//équivalent à forceUpdate

        } else {
            alert("no tag");
        }
        if (e) restItvSvc.onChange(e);
    }

    //Sélection d'un point de rdv
    function selectRdvPoint(e) {

    }

    //Fonction appeler par le formulaire si valide
    function triggerItvModalSubmit(data) {
        var flag = true;
        switch (true) {
            case getValues("currentDateMode") == "PR":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdPR));
                if (!flag) document.getElementsByName("aupdItvSdPR")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvShPR));
                if (!flag) document.getElementsByName("aupdItvShPR")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdPR));
                if (!flag) document.getElementsByName("aupdItvEdPR")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEhPR));
                if (!flag) document.getElementsByName("aupdItvEhPR")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "H":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSd));
                if (!flag) document.getElementsByName("aupdItvSd")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSh));
                if (!flag) document.getElementsByName("aupdItvSh")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSd));
                if (!flag) document.getElementsByName("aupdItvSd")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEh));
                if (!flag) document.getElementsByName("aupdItvEh")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "DH":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdDH));
                if (!flag) document.getElementsByName("aupdItvSdDH")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "J":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdJ));
                if (!flag) document.getElementsByName("aupdItvSdJ")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdJ));
                if (!flag) document.getElementsByName("aupdItvEdJ")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "DJ":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdDJ));
                if (!flag) document.getElementsByName("aupdItvSdDJ")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdDJ));
                if (!flag) document.getElementsByName("aupdItvEdDJ")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "JH":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdJH));
                if (!flag) document.getElementsByName("aupdItvSdJH")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvShJH));
                if (!flag) document.getElementsByName("aupdItvShJH")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdJH));
                if (!flag) document.getElementsByName("aupdItvEdJH")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEhJH));
                if (!flag) document.getElementsByName("aupdItvEhJH")[0].classList.add("is-invalid");
                break;
            default: break;

        }
        if (!flag) {
            Swal.fire({
                icon: 'error',
                title: 'Echec',
                html: "Veuillez renseigner des dates valides"
            });
            return;
        }

        if (itvSalMulti.length == 1 && itvSalMulti[0] != null && itvSalMulti[0].value <= 0) {
            Swal.fire({
                icon: "warning",
                title: "Intervenant invalide",
                html: "Veuillez renseigner une valeur différente de 'Non défini'"
            });
            setItvSalMultiValid(false);
        }
        else {
            data.aupdItvSalMulti = itvSalMulti;
            props.callback(data);
        }
    }

    //Déclaration des paramètres et référence pour gérer une compatibilitée avec reactstrap, autrement voici un exemple : <input {...register("password")} />
    const { ref: refItvSal, ...restItvSal } = register("aupdItvSal");
    const { ref: refItvCli, ...restItvCli } = register("aupdItvCli");
    const { ref: refItvSvc, ...restItvSvc } = register("aupdItvSvc");
    const { ref: refItvVal, ...restItvVal } = register("aupdItvVal");
    const { ref: refItvValue, ...restItvValue } = register("aupdItvValue", { value: 0, valueAsNumber: true });
    const { ref: refItvCmt, ...restItvCmt } = register("aupdItvCmt");

    const { ref: refCDateMode, ...restCDateMode } = register("currentDateMode");
    const { ref: refItvSdDHC, ...restItvSdDHC } = register("aupdItvSdDHC");
    const { ref: refItvSdDJC, ...restItvSdDJC } = register("aupdItvSdDJC");
    const { ref: refItvEdDJC, ...restItvEdDJC } = register("aupdItvEdDJC");
    //TODO : gérer le cas PR (système de saisie de date dans le dialog)

    var curSvc = null;
    if (props.parentContext.services) {
        var value = getValues("aupdItvSvc") || (props.src ? props.src.serviceID : null);
        var filterSvc = props.parentContext.services.filter((svc) => svc.idService == value);
        if (filterSvc.length) {
            curSvc = filterSvc[0];
        }
    }

    var renderDateMode = getValues("currentDateMode");
    return (
        <Modal className="p-0" isOpen={true} size="lg" scrollable keyboard={false} toggle={() => { props.callback(); }} backdrop="static">
            <ModalHeader className="d-flex" toggle={() => { props.callback(); }}>{props.itvId > 0 ? "Modifier une intervention" : "Ajouter une intervention"}</ModalHeader>
            <ModalBody className="px-2 py-0">
                <Form id="frmaupditv" action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerItvModalSubmit)}>
                    <Card className="mb-2">
                        <CardHeader className="d-flex">
                            <h5 className="title mb-0">Intervention</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="borderTheme m-0"></div>
                            <Row className="align-items-center mt-2">
                                <Col sm="2"><Label for="aupdItvSal">Intervenant :</Label></Col>
                                <Col sm="10" className={props.itvId > 0 ? "" : "hidden"}>
                                    <Input id="aupdItvSal" name="aupdItvSal" placeholder="Choix Intervenant" type="select"
                                        defaultValue={props.src ? props.src.employeeID : null}
                                        innerRef={refItvSal} {...restItvSal}
                                        invalid={errors.aupdItvSal ? true : false}>
                                        {props.parentContext.employees.map((e, i) => {
                                            return <option key={i} value={e.id}>{e.title}</option>;
                                        })}
                                    </Input>
                                    <FormFeedback className="text-start">{errors.aupdItvSal ? errors.aupdItvSal.message : "Ce champ est obligatoire"}</FormFeedback>
                                </Col>
                                <Col sm="10" className={props.itvId > 0 ? "hidden" : ""}>
                                    <FormGroup>
                                        <Select
                                            value={itvSalMulti}
                                            isMulti
                                            name="aupdItvSalMulti"
                                            options={props.employeesWithDefault}
                                            className={`basic-multi-select ${itvSalMultiValid ? "" : "is-invalid"}`}
                                            classNamePrefix="select"
                                            placeholder="Tous"
                                            onChange={(values) => {
                                                console.debug(values);
                                                if (values.length == 0 || values.length > 1 || values[0].value != -1) {
                                                    if (values.length > 0) {
                                                        var noDef = values.filter((e) => { return e.value >= 0 });
                                                        if (values.length != noDef.length) {
                                                            values = noDef;
                                                        }
                                                    }
                                                    setItvSalMultiValid(true);
                                                }
                                                setItvSalMulti(values);
                                            }}
                                        />
                                        <FormFeedback className="text-start">Veuillez choisir un élement</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2">
                                <Col sm="2"><Label for="aupdItvSvc">Service :</Label></Col>
                                <Col sm="10">
                                    <Input id="aupdItvSvc" name="aupdItvSvc" placeholder="Service" type="select"
                                        defaultValue={props.src ? props.src.serviceID : null}
                                        innerRef={refItvSvc} {...restItvSvc}
                                        onChange={modalUpdateSvc}
                                        invalid={errors.aupdItvSvc ? true : false}>
                                        {props.parentContext.bindPlanningServicesOpt()}
                                    </Input>
                                    <FormFeedback className="text-start">{errors.aupdItvSvc ? errors.aupdItvSvc.message : "Ce champ est obligatoire"}</FormFeedback>
                                </Col>
                            </Row>
                            <div className={`hr-line-dashed siActAAfficher ${(!curSvc || curSvc.categ !== "ACT") ? "hidden" : ""}`}></div>
                            <Row className={`align-items-center mt-2 siActAAfficher ${(!curSvc || curSvc.categ !== "ACT") ? "hidden" : ""}`}>
                                <Col sm="2"><Label for="aupdItvCli">Client :</Label></Col>
                                <Col sm="10">
                                    <Input id="aupdItvCli" name="aupdItvCli" placeholder="Client" type="select" /*disabled={(props.src && props.src.clientID > 0) ? true : false}*/
                                        defaultValue={props.src ? props.src.clientID : null}
                                        innerRef={refItvCli} {...restItvCli}
                                        invalid={errors.aupdItvCli ? true : false}>
                                        <option key={0} value="-1">Aucun</option>
                                        {props.parentContext.bindClients()}
                                    </Input>
                                    <FormFeedback className="text-start">{errors.aupdItvCli ? errors.aupdItvCli.message : "Ce champ est obligatoire"}</FormFeedback>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mb-2">
                        <CardHeader className="d-flex">
                            <h5 className="title mb-0">Date et Heures</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="borderTheme m-0"></div>
                            <Input id="currentDateMode" name="currentDateMode"
                                innerRef={refCDateMode} {...restCDateMode}
                                invalid={errors.currentDateMode ? true : false} className="hidden" />
                            <div id="dlg-addUpdateItv-2" className={renderDateMode == "H" ? "filter-period" : "filter-period hidden"} data-bindtag="H">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSd">Date :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSd'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={`form-control`}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSd ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSd ? "d-block" : ""}`}>{errors.aupdItvSd ? errors.aupdItvSd.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="5"><Label>{" "}</Label></Col>
                                </Row>
                                <div className="hr-line-dashed"></div>
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSh">De :</Label></Col>
                                    <Col sm="3">
                                        <Controller
                                            control={control}
                                            name='aupdItvSh'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSh ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSh ? "d-block" : ""}`}>{errors.aupdItvSh ? errors.aupdItvSh.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvEh">{" à "}</Label></Col>
                                    <Col sm="3">
                                        <Controller
                                            control={control}
                                            name='aupdItvEh'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEh ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEh ? "d-block" : ""}`}>{errors.aupdItvEh ? errors.aupdItvEh.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                            <div id="dlg-addUpdateItv-3" className={renderDateMode == "DH" ? "filter-period" : "filter-period hidden"} data-bindtag="DH">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdDH">Date :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdDH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={`form-control`}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdDH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdDH ? "d-block" : ""}`}>{errors.aupdItvSdDH ? errors.aupdItvSdDH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                    <Col sm="5">
                                        <Input id="aupdItvSdDHC" name="aupdItvSdDHC" className="filter-period-trigger" type="select"
                                            innerRef={refItvSdDHC} {...restItvSdDHC}
                                            invalid={errors.aupdItvSdDHC ? true : false}>
                                            <option value="0">Matin</option>
                                            <option value="1">Après-Midi</option>
                                            <option value="2">Journée Complète</option>

                                        </Input>
                                        <FormFeedback className="text-start">{errors.aupdItvSdDHC ? errors.aupdItvSdDHC.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                            <div id="dlg-addUpdateItv-4" className={renderDateMode == "J" ? "filter-period" : "filter-period hidden"} data-bindtag="J">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdJ">Du :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={`form-control`}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdJ ? "d-block" : ""}`}>{errors.aupdItvSdJ ? errors.aupdItvSdJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvEdJ">Au :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvEdJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEdJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEdJ ? "d-block" : ""}`}>{errors.aupdItvEdJ ? errors.aupdItvEdJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                </Row>
                            </div>

                            {/*TODO : PR*/}

                            <div id="dlg-addUpdateItv-5" className={renderDateMode == "DJ" ? "filter-period" : "filter-period hidden"} data-bindtag="DJ">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdDJ">Du :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdDJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdDJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdDJ ? "d-block" : ""}`}>{errors.aupdItvSdDJ ? errors.aupdItvSdDJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                    <Col sm="5">
                                        <Input id="aupdItvSdDJC" name="aupdItvSdDJC" className="filter-period-trigger" type="select"
                                            innerRef={refItvSdDJC} {...restItvSdDJC}
                                            invalid={errors.aupdItvSdDJC ? true : false}>
                                            <option value="0">Matin</option>
                                            <option value="1">Après-Midi</option>
                                            <option value="2" selected="selected">Journée Complète</option>

                                        </Input>
                                        <FormFeedback className="text-start">{errors.aupdItvSdDJC ? errors.aupdItvSdDJC.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                                <div className="hr-line-dashed"></div>
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvEdDJ">Au :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvEdDJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEdDJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEdDJ ? "d-block" : ""}`}>{errors.aupdItvEdDJ ? errors.aupdItvEdDJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                    <Col sm="5">
                                        <Input id="aupdItvEdDJC" name="aupdItvEdDJC" className="filter-period-trigger" type="select"
                                            innerRef={refItvEdDJC} {...restItvEdDJC}
                                            invalid={errors.aupdItvEdDJC ? true : false}>
                                            <option value="0">Matin</option>
                                            <option value="1">Après-Midi</option>
                                            <option value="2" selected="selected">Journée Complète</option>

                                        </Input>
                                        <FormFeedback className="text-start">{errors.aupdItvEdDJC ? errors.aupdItvEdDJC.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                            <div id="dlg-addUpdateItv-7" className={renderDateMode == "JH" ? "filter-period" : "filter-period hidden"} data-bindtag="JH">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdJH">Du :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdJH ? "d-block" : ""}`}>{errors.aupdItvSdJH ? errors.aupdItvSdJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvShJH"></Label></Col>
                                    <Col sm="5">
                                        <Controller
                                            control={control}
                                            name='aupdItvShJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvShJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvShJH ? "d-block" : ""}`}>{errors.aupdItvShJH ? errors.aupdItvShJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                                <div className="hr-line-dashed"></div>
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvEdJH">Au :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvEdJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEdJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEdDJ ? "d-block" : ""}`}>{errors.aupdItvEdJH ? errors.aupdItvEdJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvEhJH"></Label></Col>
                                    <Col sm="5">
                                        <Controller
                                            control={control}
                                            name='aupdItvEhJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEhJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEhJH ? "d-block" : ""}`}>{errors.aupdItvEhJH ? errors.aupdItvEhJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                        </CardBody>
                    </Card>
                    {true ? "" : <Card className={`${props.src && props.src.reportID > 0 ? "hidden" : ""}`}>
                        <CardHeader className="d-flex">
                            <h5 className="title mb-0">Adresse</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="borderTheme m-0"></div>
                            <Row className="align-items-center mt-2">
                                <Col sm="2"><Label className="mb-0" for="aupdItvSal">Point de RDV :</Label></Col>
                                <Col sm="5">
                                    <i><b>Par défaut</b></i>
                                    <FinderFrag data={{}} bindPropResult={"rdvPoint"} formManager={formManager} />
                                </Col>
                                <Col sm="5">
                                    <Button className="btn btn-simple btn-dark" onClick={selectRdvPoint}>Choisir</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>}

                    <FormGroup className="mx-3" style={{ width: "200px" }}>
                        <Label for="exampleText">
                            Montant
                        </Label>
                        <Input
                            id="itvValue"
                            name="textValue"
                            className="p-1"
                            type="number"
                            innerRef={refItvValue} {...restItvValue}
                        />
                    </FormGroup>

                    <FormGroup className="mx-3">
                        <Label for="exampleText">
                            Commentaires
                        </Label>
                        <Input
                            id="exampleText"
                            name="text"
                            className="textarea p-1"
                            type="textarea"
                            cols="5"
                            rows="3"
                            style={{ resize: "auto", maxHeight: "none" }}
                            innerRef={refItvCmt} {...restItvCmt}
                        />
                    </FormGroup>

                    <FormGroup check className='fcheck-sm text-start mx-3'>
                        <Label check >
                            <Input type="checkbox" innerRef={refItvVal} {...restItvVal} />
                            <span className="form-check-sign" />
                            Validé
                        </Label>
                    </FormGroup>

                </Form>
            </ModalBody>
            <ModalFooter className="px-2">
                <Button className="px-3" color={props.itvId > 0 ? "primary" : "success"} form="frmaupditv">
                    <i className="fa fa-save"></i>{props.itvId > 0 ? "Enregistrer" : "Ajouter"}
                </Button>{' '}
                <Button className="px-3" color="danger" onClick={() => { props.callback(); }}>
                    <i className="fa fa-times"></i>Annuler
                </Button>
            </ModalFooter>
        </Modal>
    );
}


const ManageList = React.forwardRef(({ data, context, ...props }, ref) => {
    const [items, setItems] = React.useState(data);
    const [selected, setSelected] = React.useState();
    const [withDeleted, setWithDeleted] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
        updateItems: (data) => { setItems(data) }
    }));

    React.useEffect(() => {
        if (items && items.length) {
            let draggableEl = document.getElementById('external-events');
            let draggable = new Draggable(draggableEl, {
                itemSelector: '.fc-event',
                eventData: function (eventEl) {
                    var eventData = {};

                    //Gestion du OffCanvas
                    setSelected(0);
                    props.parentRef.current.manageVisibility(false);
                    var upEvent = () => {
                        document.removeEventListener("mouseup", upEvent);
                        props.parentRef.current.close();
                    };
                    document.addEventListener("mouseup", upEvent);

                    var eventID = eventEl.getAttribute("id");
                    var currentEvent = items.filter((event) => ("event" + event.idEvent) === eventID);
                    if (currentEvent.length) {
                        eventData = currentEvent[0];
                    }

                    return {
                        tooltip: true,
                        title: commonFunc.decodeEntities(eventData.title).replace('[', '\n[').replace(']', ']\n'),
                        //start: eventData.startDate, // will be parsed
                        //end: eventData.endDate,
                        id: eventData.idEvent,
                        resourceId: -1,
                        editable: eventData.reportID == 0 || !eventData.isValid, //Non Validé, Non Envoyé ou rapport =0
                        type: eventData.resourceType, //ADD
                        backgroundColor: context.userConfigOptions.configCalendar.useResourceColor ? getColorById(context.employees, eventData.employeeID) : eventData.service.startColor,
                        textColor: "#FFF",//curSvc.lang, //ADD
                        borderColor: context.state.baseview.endsWith("list") ? eventData.service.startColor : "#000",

                        duration: (Math.floor(eventData.length) + ":" + (eventData.length * 60 % 60)) || "1:30",

                        description: commonFunc.decodeEntities(eventData.comments),
                        dsysmod: moment(eventData.dateSysMod),
                        clientId: eventData.clientID,
                        isSpecialEvent: false,
                        state: eventData.statusID,
                        employeeID: eventData.employeeID,
                        serviceId: eventData.serviceID,
                        length: eventData.length,
                        validated: eventData.isValid,
                        locked: eventData.reportID > 0,
                        reportId: eventData.reportID,
                        reportStatus: eventData.reportID > 0 ? eventData.report.statusID : 0,
                        src: eventData
                    };
                }
            });
            return () => {
                //console.debug("unmount");
                if (draggable) {
                    draggable.destroy();
                }
            }
        }
    }, [items]);

    React.useEffect(() => {
        if (data && data.length) {
            setItems(data);
        }
    }, [data]);

    function updateLocalItems(checkedVal) {
        setItems(null);
        context.refreshReportsExtList(context.settings, (checkedVal === true || checkedVal === false) ? checkedVal : withDeleted).then((results) => { setItems(results) });
    }

    function restoreEvent(event) {
        let calendarApi = context.calendarRef.current.getApi();

        Swal.fire({
            icon: 'question',
            title: 'Restauration',
            html: 'Souhaitez-vous restaurer cette intervention ? [' + event.id + '] ' + (!commonFunc.isBlank(event.title) ? event.title : (event.src.report ? event.src.report.refID : "")),
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Restauration',
                    html: 'Restauration en cours...',
                    didOpen: () => { Swal.showLoading() }
                });

                var data = event.src;

                data.client = null;
                data.employee = null;
                data.material = null;
                data.report = null;
                data.service = null;

                //data.allowReassign = true;
                commonFunc.postMethod("../api/employee/UpdateEvent", data).then((response) => {
                    var updateResult = response.data;
                    if (updateResult && updateResult.isSuccess) {
                        //Aucune demande associé
                        Swal.close();
                        Swal.hideLoading();
                        calendarApi.refetchEvents();

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec',
                            html: "Echec de restauration de l'intervention planning<p>" + updateResult.errorMessage + "</p>",
                            didOpen: () => { Swal.hideLoading() }
                        });
                    }
                });
            }
        });
    }


    return (<>
        <Button className="d-none" onClick={() => props.parentRef.current.close()}>Fermer</Button>
        <Button className="btn btn-simple btn-dark btn-icon m-0" onClick={() => { updateLocalItems(); }} disabled={items ? false : true} title="Actualiser"><i className="fa fa-sync"></i></Button>

        <FormGroup check className='text-start form-check-inline fcheck-sm ms-2'>
            <Label check >
                <Input defaultValue="" type="checkbox" onChange={(e) => { setWithDeleted(e.target.checked); updateLocalItems(e.target.checked); }} />
                <span className="form-check-sign" />
                Afficher les éléments supprimés
            </Label>
        </FormGroup>

        <div id="external-events" className="text-center">
            {items ? (items.length ? items.map((dt) => {
                var event = {
                    //title: decodeEntities(dt.title).substr(0, dt.title.indexOf(']') === -1 ? dt.title.length : dt.title.indexOf(']') + 1),
                    title: commonFunc.decodeEntities(dt.title).replace('[', '\n[').replace(']', ']\n'),
                    start: dt.startDate, // will be parsed
                    end: dt.endDate,
                    id: dt.idEvent,
                    resourceId: -1,
                    editable: dt.reportID == 0 || !dt.isValid, //Non Validé, Non Envoyé ou rapport =0
                    //resourceEditable: dt.reportId == 0 || (curReportStatus.extId < 2),//Non A Corriger
                    type: dt.resourceType, //ADD
                    //className
                    backgroundColor: context.userConfigOptions.configCalendar.useResourceColor ? getColorById(context.employees, dt.employeeID) : dt.service.startColor,
                    textColor: "#FFF",//curSvc.lang, //ADD
                    borderColor: context.state.baseview.endsWith("list") ? dt.service.startColor : "#000",
                    //classNamesID
                    description: commonFunc.decodeEntities(dt.comments),
                    dsysmod: moment(dt.dateSysMod),
                    clientId: dt.clientID,
                    isSpecialEvent: false,
                    state: dt.statusID,
                    // d:dt.
                    employeeID: dt.employeeID,
                    serviceId: dt.serviceID,
                    length: dt.length,
                    validated: dt.isValid,
                    locked: dt.reportID > 0 || dt.statusID === 16,
                    reportId: dt.reportID,
                    reportStatus: dt.reportID > 0 ? dt.report.statusID : 0,
                    /*sessionId: dt.sessionId,
                    sessionStatus: dt.sessionStatus,
                    bookingId: dt.bookingId,
                    bookingType: dt.bookingType,*/
                    src: dt
                };

                var eventId = "event" + dt.idEvent;

                return <div key={eventId} id={eventId} className={`${event.state !== 16 ? "fc-event" : ""} fc-h-event fc-daygrid-event fc-daygrid-block-event ms-1 my-1 me-0 ${selected === dt.idEvent ? "event-select" : ""}`} onClick={(e) => { selected === dt.idEvent ? setSelected(0) : setSelected(dt.idEvent) }}
                    onDoubleClick={() => {
                        if (event.reportId > 0) {
                            var win = window.open("/ManageReportsItv?reportId=" + event.reportId + "&statusId=" + event.reportStatus, "_blank");
                            win.focus();
                        }
                    }}
                    onContextMenu={() => {
                        if (event.state === 16) {
                            restoreEvent(event);
                        }
                    }}>
                    <div className="fc-event-main">
                        <i className="fas fa-tag fa-border float-start cs-event-svc-icon" title={dt.service ? dt.service.name : ""} style={{ color: getColorById(props.services, dt.serviceID) }}></i>
                        {!commonFunc.isBlank(event.title) ? event.title : (dt.report ? dt.report.refID : "")}
                        {event.state === 16 ? <i className="fas fa-square text-danger float-end"></i> : ""}
                    </div>
                    {/*!info.isDragging ?*/ <UncontrolledPopover isOpen={selected === dt.idEvent}
                        fade
                        target={eventId}
                        placement="auto"
                        container="body"
                        delay={{ show: 500, hide: 50 }}
                    //noIncluded="html: true"//INFO
                    >
                        <PopoverHeader>{event.title}</PopoverHeader>
                        <PopoverBody>
                            {generateTooltip(event, context.services)}
                        </PopoverBody>
                    </UncontrolledPopover> /*: ""*/}
                </div>;
            }) : <div>Aucun élément</div>) : <Spinner />}
        </div>
    </>);
});

function aShortEvent() {
    this.idEvent = 0;
    this.clientID = -1;
    this.employeeID = -1;
    //this.orgID = 0;
    this.serviceID = -1;
    this.materialID = -1;
    this.reportID = -1;
    this.demandID = -1;

    this.statusID = 2;
    this.startDate = null;
    this.endDate = null;
    this.length = 0.0;
    this.title = ""

    //this.clientName As String = ""
    //this.salName As String = ""
    //this.svcName As String = ""
    //this.isWaitingConfirm As Boolean = False
    this.isValid = false;
    this.isGenerated = false;
    this.isSandBox = false;
    this.comments = "";
    this.dateSysconf = new Date();
    this.dateSysMod = new Date();

    //allowCrossEvents
    //allowSplit
    //value
}


function aPrintOption() {
    this.IsSourceEmployee = true;
    this.MainComments = "";
    this.Model = 4;
    this.UseSiteLogo = true;
    this.UseEcoMode = false;
    this.UseSourceComments = true;
    this.UseCloudStorage = true;
    //this.StartPeriod = '';
    this.StartDate = '';
    this.Period = 'w';
    this.SourceIDs = [];
    this.UseFilters = false;
    this.IsPrinting = true;
    this.UseSplit = false;

}

function renderEventContent(info) {
    console.debug(info.event.extendedProps);
    var calendarConfig = this.userConfigOptions.configCalendar || {};
    if (calendarConfig.useEventTemplate) {
        var nodes = [];
        var template = document.createElement("div");
        template.innerHTML = calendarConfig.useEventTemplate;

        template.querySelectorAll("[data-bind]").forEach((e, i) => {
            if (info.event.extendedProps && info.event.extendedProps.src) {
                e.innerText = info.event.extendedProps.src[e.getAttribute("data-bind")];
            }
        });

        var timesElem = template.getElementsByClassName("fc-time");
        if (timesElem.length) timesElem[0].innerText = info.timeText;

        if (!this.settings.displayEventTime && template.getElementsByClassName("fc-title").length) {
            template.getElementsByClassName("fc-title")[0].classList.add("ml-0");
        }

        template.querySelectorAll("[data-info]").forEach((e, i) => {
            e.innerText = info[e.getAttribute("data-info")];
        });


        if (this.settings.useEventGuiOverlay && template.getElementsByClassName("event-bg").length) {
            template.getElementsByClassName("event-bg")[0].classList.remove("d-none");
        }


        if (info.event.extendedProps && info.event.extendedProps.reportStatus > 0) {

            var resultStatus = this.statuts.filter(function (a) {
                return a.idStatus == info.event.extendedProps.reportStatus;
            });
            if (resultStatus.length > 0 /*&& resultStatus[0].extId <= 4*/ && template.getElementsByClassName("event-reportstatus").length) {
                template.getElementsByClassName("event-reportstatus")[0].classList.add(...resultStatus[0].css.split(" ").filter(item => item));
                template.getElementsByClassName("event-reportstatus")[0].classList.remove("d-none");
            }
        }

        nodes = template.children[0].childNodes;
        return { domNodes: nodes };//TODO : gestion du popover dans le cas d'un modèle source..
    }
    else {
        if (!info.event.extendedProps.src) return "";

        var eventId = "event0";
        var reportStatusClass = "event-status event-reportstatus d-none";
        if (info.event.extendedProps) {
            if (info.event.extendedProps.reportStatus > 0) {
                var resultStatus = this.statuts.filter(function (a) {
                    return a.idStatus == info.event.extendedProps.reportStatus;
                });
                if (resultStatus.length > 0 /*&& resultStatus[0].extId <= 4*/) {
                    reportStatusClass = "event-status event-reportstatus " + resultStatus[0].css;
                }
            }
            eventId = "event" + info.event.extendedProps.src.idEvent;
        }

        var fcTitleClass = "fc-title " + (!this.settings.displayEventTime ? "ml-0" : "");
        var eventBgClass = this.settings.useEventGuiOverlay ? "event-bg" : "event-bg d-none";

        var startDate;
        if (info.event.extendedProps && info.event.extendedProps.src) startDate = moment(info.event.extendedProps.src.startDate).format("DD/MM/YYYY HH:mm");
        var endDate;
        if (info.event.extendedProps && info.event.extendedProps.src) endDate = moment(info.event.extendedProps.src.endDate).format("DD/MM/YYYY HH:mm");

        var textSup = "";
        if (this.state.baseview === "list") {
            var resource = this.calendarRef.current.getApi().getResourceById(info.event.extendedProps.employeeID);
            if (resource != null) {
                textSup = resource.title + " • ";
            }
        }
        if (!info.event.extendedProps.isSpecialEvent) {
            var supClass = "";
            if (!info.event.extendedProps.validated) supClass += " event-unvalid";
            if (info.event.extendedProps.id <= 0) supClass += " event-empty";
            supClass += " event-state-" + (info.event.extendedProps.state | 0);
        }
        console.debug(info);
        console.debug(info.event.extendedProps);

        if (info.event.extendedProps.src.employeeID > 0 && info.event.extendedProps.src.reportID <= 0) {

            return (
                <div id={eventId} className={supClass} >
                    <span className={reportStatusClass}>&nbsp;</span>
                    <span className='fc-time'>{textSup} {info.timeText}</span>
                    <span className={fcTitleClass} data-bind="address.PC">{startDate ? startDate : ""}{this.state.baseview === "list" ? (" à " + endDate) : ""}</span>
                    {(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.client) ? <span className='event-alt-title fw-bold' data-bind="address.City">{info.event.extendedProps.src.client.name}</span> : ""}
                    <span className='event-description fw-bold' data-bind="title">{(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.service) ? info.event.extendedProps.src.service.name : ""}</span>
                    <span className='event-qty' data-bind="reportRef">{(info.event.extendedProps && info.event.extendedProps.src) ? info.event.extendedProps.src.length : ""}</span>
                    <div className={eventBgClass}></div>
                    {!info.isDragging ? <UncontrolledPopover
                        fade
                        target={eventId}
                        placement="auto"
                        trigger="hover"
                        container="body"
                        delay={{ show: 500, hide: 50 }}
                    //noIncluded="html: true"//INFO
                    >
                        <PopoverHeader>{(info.event.extendedProps && info.event.extendedProps.src) ? info.event.extendedProps.src.title : "Vide"}</PopoverHeader>
                        <PopoverBody>
                            {generateTooltip(info.event.extendedProps, this.services)}
                        </PopoverBody>
                    </UncontrolledPopover> : ""}

                </div>
            );
        }
        else if (info.event.extendedProps.src.reportID > 0) {

            return (
                <div id={eventId}>
                    <span className={reportStatusClass}>&nbsp;</span>
                    <span className='fc-time'>{textSup} {info.timeText}</span>
                    <span className={fcTitleClass} data-bind="address.PC">{(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.report && info.event.extendedProps.src.report.address) ? info.event.extendedProps.src.report.address.pc : 0}</span>
                    <span className='event-alt-title fw-bold' data-bind="address.City">{(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.report && info.event.extendedProps.src.report.address) ? info.event.extendedProps.src.report.address.city : ""}</span>
                    <span className='event-description fw-bold' data-bind="title">{(info.event.extendedProps && info.event.extendedProps.src) ? info.event.extendedProps.src.title : ""}</span>
                    <span className='event-qty' data-bind="reportRef">{(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.report) ? info.event.extendedProps.src.report.refID : ""}</span>
                    <div className={eventBgClass}></div>
                    {!info.isDragging ? <UncontrolledPopover
                        fade
                        target={eventId}
                        placement="auto"
                        trigger="hover"
                        container="body"
                        delay={{ show: 500, hide: 50 }}
                    //noIncluded="html: true"//INFO
                    >
                        <PopoverHeader>{(info.event.extendedProps && info.event.extendedProps.src) ? info.event.extendedProps.src.title : "Vide"}</PopoverHeader>
                        <PopoverBody>
                            {generateTooltip(info.event.extendedProps, this.services)}
                        </PopoverBody>
                    </UncontrolledPopover> : ""}
                </div>
            );
        }
        else {
            return (
                <div id={eventId}>
                    <span className={reportStatusClass}>&nbsp;</span>
                    <span className='fc-time'>{textSup} {info.timeText}</span>
                    <span className={fcTitleClass} data-bind="address.PC">{(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.report && info.event.extendedProps.src.report.address) ? info.event.extendedProps.src.report.address.pc : 0}</span>
                    <span className='event-alt-title fw-bold' data-bind="address.City">{(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.report) ? info.event.extendedProps.src.report.address.city : ""}</span>
                    <span className='event-description fw-bold' data-bind="title">{(info.event.extendedProps && info.event.extendedProps.src) ? info.event.extendedProps.src.title : ""}</span>
                    <span className='event-qty' data-bind="reportRef">{(info.event.extendedProps && info.event.extendedProps.src && info.event.extendedProps.src.report) ? info.event.extendedProps.src.report.refID : ""}</span>
                    <div className={eventBgClass}></div>
                    {!info.isDragging ? <UncontrolledPopover
                        fade
                        target={eventId}
                        placement="auto"
                        trigger="hover"
                        container="body"
                        delay={{ show: 500, hide: 50 }}
                    //noIncluded="html: true"//INFO
                    >
                        <PopoverHeader>{(info.event.extendedProps && info.event.extendedProps.src) ? info.event.extendedProps.src.title : "Vide"}</PopoverHeader>
                        <PopoverBody>
                            {generateTooltip(info.event.extendedProps, this.services)}
                        </PopoverBody>
                    </UncontrolledPopover> : ""}
                </div>
            );
        }
    }
}

function renderResourceContent(info) {
    //console.debug("resourceLabelContent");
    //console.debug(info);


    var resource = Object.assign({}, info.resource.extendedProps);
    resource.id = info.resource.id;

    //console.debug(resource);
    var nodes = [];

    var calendarConfig = this.userConfigOptions.configCalendar || {};
    if (calendarConfig.useResTemplate) {
        var clone = document.createElement("div");
        clone.innerHTML = calendarConfig.useResTemplate;

        var img = clone.getElementsByTagName("img")[0];
        if (img) {
            if (calendarConfig && calendarConfig.useResourceColor) {
                var color = getColorById(this.employees, resource.id);
                img.style.borderColor = color;
                if (clone.getElementsByTagName("p")[0]) clone.getElementsByTagName("p")[0].style.backgroundColor = color;
            }
            if (!commonFunc.isBlank(resource.avatar)) {
                img.setAttribute("src", resource.avatar);
            }
            img.setAttribute("title", info.resource.title);
            if (img.getAttribute("src").indexOf("default_profile") >= 0) img.setAttribute("src", empty_avatar);
        }
        else {
            if (calendarConfig && calendarConfig.useResourceColor && clone.getElementsByTagName("p")[0]) {
                var color2 = getColorById(this.employees, resource.id);
                clone.getElementsByTagName("p")[0].style.backgroundColor = color2;
            }
        }
        //clone.find("p").html(info.resource.title + ' [' + resource.agency + ']');
        //clone.find("p").append("<p>" + resource.amount + "</p>");

        nodes.push(clone.innerHTML);
    }
    else {

        var color = "";
        if (calendarConfig && calendarConfig.useResourceColor) {
            color = "style=\"border-color:" + getColorById(this.employees, resource.id) + "\"";
        }

        //useResAvatar : Affichage des photos de ressources
        //0 : Tous, 1 : Si défini, 2 : Aucune
        if (!calendarConfig || !calendarConfig.useResAvatar || calendarConfig.useResAvatar == 1) {
            if (!commonFunc.isBlank(resource.avatar)) {
                //nodes.push($("<div class='fc-resAvatar' style='background: url(" + resource.avatar + ") no-repeat'></div>")[0]);
                nodes.push("<div class='float-start event-res-avatar sharp rounded-circle align-content-center'><img class='w-100' title='" + info.resource.title + "'  src='" + resource.avatar + "' " + color + " /></div>");
            }
            else {
                if (calendarConfig.useResAvatar != 1) nodes.push("<img class='float-start event-res-avatar sharp rounded-circle' title='" + info.resource.title + "'  src='" + empty_avatar + "' " + color + " />");
            }
        }
        //console.debug(resource);

        color = color.replace("border", "background");
        if (!calendarConfig || !calendarConfig.useResourceColor) {
            color = "style=\"border:0px\"";
        }
        nodes.push("<p class='badge d-flex text-center fw-normal fc-resTitle' " + color + "> " + info.resource.title + ' [' + resource.agency + ']' + "</p>");
        nodes.push("<p class='badge d-flex text-center fw-normal fc-resTitle' " + color + "> " + resource.amount + "€</p>");
    }
    var frag = document.createRange().createContextualFragment(nodes.join(""));
    return { domNodes: frag.childNodes };
}



function generateTooltip(event, services) {
    //INFO : gestion du getFastReport via les infos contenu dans tooltipReport

    //console.debug(event);
    if (!event) return '';
    var svc = services.filter(function (a) {
        return a.idService === event.serviceId;
    });

    var tooltipReport = {};
    var startPeriod = "De ??? à ???";
    var address = "";
    var city = "";
    var amount = "";


    if (event.src) {
        tooltipReport = ((event.reportId && event.src.report) ? event.src.report : {});
        startPeriod = "De " + moment(event.src.startDate).format('HH[h]mm') + " à " + moment(event.src.endDate).format("HH[h]mm");
        if (event.src.address) {
            address = event.src.address.addressMain + " "; // + "(" + commonFunc.pad(commonFunc.trim(event.src.address.pc), 5) +")";
            city = event.src.address.city;
            amount = "Montant HT: " + event.src.value + " €";
        }
    }

    console.debug(event.src);
    var tel = "";
    var gsm = "";

    /*if (event.src.reportID > 0) {
        tel = event.src.report ? event.src.report.tel : "";
        gsm = event.src.report ? event.src.report.gsm : "";
    }
    else */if (event.src.clientID > 0) {
        tel = event.src.client ? event.src.client.tel : "";
        gsm = event.src.client ? event.src.client.gsm : "";
    }

    return <div className="tooltip-event">
        <div className="popover-content-data fc-scroller overflow-auto">
            <strong className="tooltip-start">{event.src ? moment(event.src.startDate).format("dddd DD MMMM") : ""}</strong>
            <br />
            <strong className="tooltip-startperiod">{startPeriod}</strong>&nbsp;<span className='tooltip-startlength text-muted hidden'>{event.length + "h"}</span>
            <div className="tooltip-svcname">{svc.length > 0 ? <><i>{svc[0].name}</i><br /></> : ""}</div>
            <div className="tooltip-rappstatut"></div>
            <hr className="tooltip-divider" />
            <div className="tooltip-bind tooltip-tel ">{gsm}</div>
            <div className="tooltip-bind tooltip-mobile ">{tel}</div>
            <div className="tooltip-bind tooltip-address ">{address}</div>
            <div className="tooltip-bind tooltip-address ">{city}</div>
            <hr className="tooltip-divider" />

            <div className="tooltip-desc popover-content2">{event.description}</div>

            <div className="tooltip-bind tooltip-assurance ">{tooltipReport.insurance}</div>
            <div className="tooltip-bind tooltip-assuranceref ">{tooltipReport.insuranceID}</div>
            <div className="tooltip-bind tooltip-franchise ">{parseFloat(tooltipReport.insuranceInfo)>=0 ? ("Franchise: " + tooltipReport.insuranceInfo + " €") : ""}</div>
            <div className="tooltip-bind tooltip-ca ">{amount}</div>
            <hr className="tooltip-divider" />
            <div className="">
                <div className="tooltip-bind tooltip-rappcommentaire">{tooltipReport.comments}</div>
            </div>
            <br /><br /><h6><small className='tooltip-dsysmod small text-muted'>{moment(event.dsysmod).format("DD/MM/YYYY HH:mm")}</small></h6>
        </div>
    </div>;
}

function eventDidMount(info) {
    //console.debug("eventDidMount");

    var context = this;

    var event = Object.assign({}, info.event.extendedProps);
    event.id = info.event.id;
    event.title = info.event.title;

    var element = info.el;//$(info.el);
    if (!event.tooltip) {
        //element.setAttribute("id", "event" + event.id);
        //INFO : /!\ impossible d'accèder au contenu de eventContent depuis ici ET impossible d'accèder à info.el dans eventContent dans le cas où l'id serait situé sur element (pour cibler le popover)

        //TEST...
        /*element.addEventListener("mouseenter", () => {
            console.debug("showpopover");
        });*/
        /*element.addEventListener('show.bs.popover', () => {
            //$(".popover").popover('hide');
            console.debug("showpopover");
            alert("showpopover");
        });*/

        /*element.on('inserted.bs.popover', function (e) {
            console.debug(e);

            if (event.reportId > 0) {
                console.debug(event);
                if (true) { //!event.tooltipReport {
                    getMethod("../api/report/GetFastReport", { id: event.reportId }).then((result) => {
                        if (result.isSuccess) {
                            event.tooltipReport = result.result;
                            //$(".popover-content").find(".reportContactName").text(event.tooltipReport.telMobile);

                            $(".popover").find(".tooltip-tel").text(event.tooltipReport.telMobile);
                            $(".popover").find(".tooltip-mobile").text(event.tooltipReport.telFixe);
                            $(".popover").find(".tooltip-assurance").text(event.tooltipReport.Assurance);
                            $(".popover").find(".tooltip-assuranceref").text(event.tooltipReport.AssuranceRef);

                            $(".popover").find(".tooltip-rappcommentaire").text(event.tooltipReport.commentaire);

                            if (event.tooltipReport.AssuranceInfo !== '-1') {
                                $(".popover").find(".tooltip-franchise").text(event.tooltipReport.AssuranceInfo + "€");
                            }
                            if (event.tooltipReport.MontantHT) { $(".popover").find(".tooltip-ca").text("Montant HT:" + event.tooltipReport.MontantHT + "€"); }
                        }
                    });
                } else {
                    $(".popover-content").find(".tooltip-tel").text(event.tooltipReport.telMobile);
                }
            }
        });



        element.on('hide.bs.popover', function () {
            if ($(".popover:hover").length) {
                return false;
            }
        });
        
        */
        //INFO : utilisation de UncontrolledPopover

        element.addEventListener("mousedown", function (ev) {
            var eventsSelected = document.getElementsByClassName("event-select");
            if (eventsSelected.length) {
                Array.from(eventsSelected).forEach((e) => {
                    e.classList.remove("event-select");
                });
            }
            element.classList.add("event-select");
        });

        element.addEventListener("dblclick", function (e) {
            if (event.reportId > 0) {
                var win = window.open("/ManageReportsItv?reportId=" + event.reportId + "&statusId=" + event.reportStatus, "_blank");
                win.focus();
            }
        });

        if (true) {
            element.addEventListener("contextmenu", (e) => {
                context.showContextMenu({
                    id: "contextMenu",
                    event: e,
                    props: event
                });
            });
        }

    }

    if (!event.isSpecialEvent) {
        if (this.settings.useEventGuiEffect) { element.classList.add("event-gui-effect"); }
        if (!event.validated) element.classList.add("event-unvalid");
        if (event.id <= 0) element.classList.add("event-empty");
        //element.find(".fc-event-main").removeClass("event-test").addClass("event-test");
        element.classList.add("event-state-" + (event.state | 0));
    }
}

function isItemHidden({ props, data, triggerEvent }) {
    var event = props;
    var dataItem = data;
    var hidden = false;

    if (event.reportId <= 0) {
        hidden |= (dataItem === "mnuItvRptValid0" || dataItem === "mnuItvRptValid1" || dataItem === "mnuItvReportAccess");
    }
    else {
        hidden |= (dataItem === "mnuItvDpl");
    }

    if (event.clientId <= 0 && event.reportId <= 0) {
        hidden |= (dataItem === "mnuItvUnaffect");
    }
    if (event.clientId <= 0 || event.src.demandID <= 0) {
        hidden |= (dataItem === "mnuAccessDmdClient");
    }
    if (event.clientId <= 0 || (this.config && this.config.resource && this.config.resource.type === "client")) {
        hidden |= (dataItem === "mnuAccessPlanClient");
    }
    if (event.employeeID <= 0 || (!this.config || !this.config.resource || this.config.resource.type === "employee")) {
        hidden |= (dataItem === "mnuAccessPlanEmployee");
    }

    return hidden;
}

function isItemDisabled({ props, data, triggerEvent }) {
    var event = props;
    var dataItem = data;
    var disabled = false;

    if (event.reportId > 0) {
        var curReportStatus = getStatusById(this.statuts, event.reportStatus);
        if (curReportStatus && curReportStatus.step < 1) {//<= 1
            if (curReportStatus.step == 0 && curReportStatus.value == "1") {
                disabled |= (dataItem === "mnuItvRptValid1");
            }
            else if (curReportStatus.step == 0 && curReportStatus.value == "0") {
                disabled |= (dataItem === "mnuItvRptValid0");
            }
        }
        else {
            disabled |= (dataItem === "mnuItvRptValid0" || dataItem === "mnuItvRptValid1");
        }

        if (event.src && event.src.report && event.src.report.status && event.src.report.status.step >= 1) {
            disabled |= (dataItem === "mnuItvUpd");
        }
    }
    else {
        disabled |= (dataItem === "mnuItvRptValid0" || dataItem === "mnuItvRptValid1");
    }

    if (!event.locked) {
        //disabled |= (dataItem === "mnuItvRptUnaffect");
    }
    else {
        disabled |= (dataItem === "mnuItvDel");
        //if (!curReportStatus || curReportStatus.step != 0) {
        //    disabled |= (dataItem === "mnuItvRptUnaffect");
        //}
    }

    if (event.validated) {
        disabled |= (dataItem === "mnuItvValid");
    }


    /*var evtService = $("#dlg-abstype option[value='" + event.serviceId + "']");
    if (evtService && evtService.data("cachesvc") && evtService.data("cachesvc").filter == "ABS") {
        disabled = (dataItem === "mnuItvUpd");
    }*/

    return disabled;
}

function itvReportAccess({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    console.debug(event);
    if (event.reportId > 0) {
        var win = window.open("/ManageReportsItv?reportId=" + event.reportId + "&statusId=" + event.reportStatus, "_blank");
        win.focus();
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Echec',
            html: "Impossible d'accèder à l'intervention"
        });
    }

}

function itvUpd({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    console.debug(event);
    if (event.reportId > 0) {
        //var win = window.open("/ManageReportsItv?reportId=" + event.reportId + "&statusId=" + event.reportStatus, "_blank");
        //win.focus();
        this.updateReport(event.src, "update");
    } else if (!event.locked) {
        this.addOrUpdateItv(event.src);
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Echec',
            html: "Impossible de modifier un évènement verrouillé"
        });
    }

}

function itvDel({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    let calendarApi = this.calendarRef.current.getApi();

    console.debug(event);
    if (!event.locked) { // && $("select[name='IP-0']").data("curuser").accessManager
        Swal.fire({
            icon: 'warning',
            title: 'Suppression',
            html: 'Etes-vous sûr de vouloir supprimer cet évènement ? [' + event.id + '] ' + event.title,
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Suppression',
                    html: 'Opération en cours...',
                    didOpen: () => { Swal.showLoading() }
                });
                var evt = {};
                evt.id = event.id;
                console.debug(event);
                evt.bindId = event.employeeID;
                evt.isPermanent = false;
                evt.active = true;
                evt.comments = "Suppression via planning";
                //evt.filter = "employee";

                commonFunc.postMethod("../api/employee/RemoveEvent", evt).then((response) => {
                    var removeResult = response.data;
                    if (removeResult && removeResult.isSuccess) {
                        //Aucune demande associé
                        Swal.close();
                        Swal.hideLoading();
                        calendarApi.refetchEvents();

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec',
                            html: "Echec de suppression de l'évènement planning<p>" + removeResult.errorMessage + "</p>",
                            didOpen: () => { Swal.hideLoading() }
                        });
                    }

                });
            }
        });
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Echec',
            html: "Impossible de supprimer un évènement  verrouillé"
        });
    }
}

function itvDpl({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    var context = this;

    var newEvent = Object.assign({}, event.src);
    newEvent.idEvent = 0;

    this.addOrUpdateItv(newEvent);
}

function itvValid({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    var context = this;

    var args = {};
    args.ID = event.id;
    args.Value = true;

    commonFunc.postMethod("../api/employee/ValidateEvent", args).then((response) => {
        var data = response.data;
        if (!data.isSuccess) {
            Swal.fire({
                icon: 'error',
                title: 'Echec',
                html: "Impossible de valider l'intervention " + data.errorMessage
            });
        } else {
            context.refreshContent();
        }
    });
}

function itvUnaffect({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    let calendarApi = this.calendarRef.current.getApi();

    console.debug(event);
    Swal.fire({
        icon: 'warning',
        title: 'Désaffecter',
        html: 'Etes-vous sûr de vouloir désaffecter cette intervention ? [' + event.id + '] ' + event.title,
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
    }).then((result) => {
        if (result.value) {
            Swal.fire({
                title: 'Désaffectation',
                html: 'Opération en cours...',
                didOpen: () => { Swal.showLoading() }
            });
            var evt = {};
            var url = "../api/employee/UnscheduleEvent";
            if (event.reportId) {
                url = "../api/report/UnscheduleItvReport";
                evt.bindId = event.reportId;
            }
            else {
                evt.bindId = event.employeeID;
            }
            evt.id = event.id;
            //evt.filter = "employee";

            //{ id: id, targetId: $(this).data("targetId") || 0, empId: $("#reportLstEmp").val() || 0 };

            commonFunc.postMethod(url, evt).then((response) => {
                var removeResult = response.data;
                if (removeResult && removeResult.isSuccess) {
                    //Aucune demande associé
                    Swal.close();
                    Swal.hideLoading();
                    calendarApi.refetchEvents();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Echec',
                        html: "Echec de désaffectation de l'intervention planning<p>" + removeResult.errorMessage + "</p>",
                        didOpen: () => { Swal.hideLoading() }
                    });
                }

            });
        }
    });
}

function itvRptUnaffect({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    let calendarApi = this.calendarRef.current.getApi();

    console.debug(event);
    if (event.reportId > 0) { // && $("select[name='IP-0']").data("curuser").accessManager
        Swal.fire({
            icon: 'warning',
            title: 'Désaffecter',
            html: 'Etes-vous sûr de vouloir désaffecter cet évènement ? [' + event.id + '] ' + event.title,
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Désaffectation',
                    html: 'Opération en cours...',
                    didOpen: () => { Swal.showLoading() }
                });
                var evt = {};
                evt.bindId = event.reportId;
                evt.id = event.id;
                evt.empId = event.employeeID;
                //evt.filter = "employee";

                //{ id: id, targetId: $(this).data("targetId") || 0, empId: $("#reportLstEmp").val() || 0 };

                commonFunc.postMethod("../weatherforecast/UnscheduleItvReport", evt).then((response) => {
                    var removeResult = response.data;
                    if (removeResult && removeResult.isSuccess) {
                        //Aucune demande associé
                        Swal.close();
                        Swal.hideLoading();
                        calendarApi.refetchEvents();

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec',
                            html: "Echec de désaffectation de l'évènement planning<p>" + removeResult.errorMessage + "</p>",
                            didOpen: () => { Swal.hideLoading() }
                        });
                    }

                });
            }
        });
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Echec',
            html: "Impossible de supprimer un évènement  verrouillé"
        });
    }
}

function itvRptValid({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    var context = this;

    var args = {};
    args.id = event.reportId;
    args.newStep = -1;
    if (true) { //curReportStatus.extId <= 0
        var compareValue;
        switch (dataItem) {
            case "mnuItvRptValid0":
                compareValue = "0";
                break;
            case "mnuItvRptValid1":
                compareValue = "1";
                break;
            default: break;
        }
        if (compareValue) {
            var curReportStatus = this.statuts.filter(function (a) {
                return a.idStatus != event.reportStatus && a.step <= 0 && a.value == compareValue && a.categ == "REPORT";
            });
            if (curReportStatus != null && curReportStatus.length > 0) {
                args.newStatus = curReportStatus[0].idStatus;

                commonFunc.postMethod("../api/report/UpdateStatus", args).then((response) => {
                    var data = response.data;
                    if (!data.isSuccess) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec',
                            html: "Impossible de modifier le statut " + data.errorMessage
                        });
                    } else {
                        context.refreshContent();
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Echec',
                    html: "Impossible de modifier le statut:  nouveau statut incompatible"
                });
            }
        }
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Echec',
            html: "Impossible de modifier le statut: statut incompatible"
        });
    }
}

function accessPlanClient({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    var context = this;

    setTimeout(() => {
        var config = context.config;
        if (!config) config = { resource: {} };
        if (!config.resource) config.resource = {};
        //config.resource.id = event.clientId;
        context.callbackRender = function () {
            context.callbackRender = null;
            context.preventEventsRefresh = 1;
            context.findResourceChange(event.clientId);
        };
        context.preventEventsRefresh = 1;
        context.updateFilter("client");
    }, 500);
}

function accessDmdClient({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    var context = this;

    var win = window.open("/ManageClients?clientId=" + event.clientId + "&demandId=" + event.src.demandID, "_blank");
    win.focus();
}

function accessPlanEmployee({ sysEvent, props, triggerEvent, data }) {
    var event = props;
    var dataItem = data;

    var context = this;

    setTimeout(() => {
        var config = context.config;
        if (!config) config = { resource: {} };
        if (!config.resource) config.resource = {};
        //config.resource.id = event.clientId;
        context.callbackRender = function () {
            context.callbackRender = null;
            context.preventEventsRefresh = 1;
            context.findResourceChange(event.employeeID);
        };
        context.preventEventsRefresh = 1;
        context.updateFilter("employee");
    }, 500);
}

function showGeo({ sysEvent, props, triggerEvent, data }) {
    var context = this;

    var event = props;
    var dataItem = data;

    //$("#geoIframe").attr("src", "");
    /*commonFunc.getMethod("../api/report/GetReport", { id: event.reportId }).then((response) => {
        var data = response.data;
        console.debug(data);
        if (data.isSuccess) {*/
    var url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCR3ctua5pvn5v-_AO0kuv1Ei6fD7U7lt0&zoom=13&q=";

    var place = "";

    var dt = event.src.address;

    console.debug(event.src);

    if (!dt) {
        if (event.src.reportID > 0) {
            dt = event.src.report.address;
        }
        else if (event.src.clientID > 0) {
            dt = event.src.client.address;
        }
    }

    if (dt) {
        place = dt.addressMain + " " + commonFunc.pad(commonFunc.trim(dt.pc), 5) + " " + dt.city;

        if (place) {
            url += place;
        }

        console.debug(place);
        context.displayIframe("Visualiser l'adresse " + event.title, <div className="widget dash">
            <h5 className="my-3 mx-2">{place}</h5>
            <div className="widget-tools collapse-tool">
                <i className="fa fa-user fa-3x"></i>
            </div>
        </div>, { iframeUrl: url, noScroll: true });
    }
    else {
        Swal.fire({
            icon: 'error',
            title: 'Echec',
            html: "Aucune adresse à visualiser"
        });
    }
    /*}
});*/
}

function showGeoJourney({ sysEvent, props, triggerEvent, data }) {
    var context = this;

    var event = props;
    var dataItem = data;



    var resId = event.employeeID;
    var url = "../api/employee/GetEmployeesEvents";
    var config = context.config;
    if (config) {
        switch (config.resource.type) {
            case "employee":
                resId = event.employeeID;
                url = "../api/employee/GetEmployeesEvents";
                break;
            case "client":
                resId = event.clientID;
                url = "../api/client/GetClientsEvents";
                break;
            //case "product": /*args.resType = 256;*/ url = "../data/GetReportsActivities"; break;
            //case "material": /*args.resType = 4;*/ url = "../data/GetReportsEmployees"; break;
            default:
                resId = event.employeeID;
                url = "../api/employee/GetEmployeesEvents";
                break;
        }
    }

    console.debug(event.src);
    //$("#geoIframe").attr("src", "");
    //commonFunc.getMethod("../weatherforecast/WFGetReportsAddressesFromEvent", { sdate: moment(event.src.startDate).toISOString(), period: "D", idsal: event.employeeID }).then((response) => {
    commonFunc.getMethod(url, { startdate: moment(event.src.startDate).utc()/*.startOf("week")*/.startOf("day").toISOString(), period: "D", id: resId }).then((response) => {

        var data = response.data;
        console.debug(data);


        if (data.isSuccess) {

            var url = "https://www.google.com/maps/embed/v1/directions?key=AIzaSyCR3ctua5pvn5v-_AO0kuv1Ei6fD7U7lt0&zoom=8";

            var place = "";
            var finalPlace = "";


            if (data.result != null && data.result.length > 0) {

                var cpt = 1;
                var size = data.result.length - 1;
                var op = "";
                var flag = false;
                data.result.forEach(function (event, index) {

                    var dt;
                    if (event.reportID > 0) {
                        dt = event.report.address;
                    }
                    else if (event.clientID > 0) {
                        dt = event.client.address;
                    }

                    if (dt) {
                        var ads = encodeURIComponent(commonFunc.trim(dt.addressMain) + "+" + commonFunc.pad(commonFunc.trim(dt.pc), 5) + "+" + commonFunc.trim(dt.city));
                        if (!place) {
                            place = "&origin=" + ads;
                        }
                        else if (index !== size) {
                            if (!flag) {
                                place += "&waypoints=";
                                flag = true;
                            } else {
                                place += "\|";
                            }
                            place += ads;
                        }
                        finalPlace = "&destination=" + ads;
                    }
                });

                if (place) {
                    url += place + finalPlace;

                    context.displayIframe("Visualiser l'adresse " + event.title, <div className="widget dash">
                        <h5 className="my-3 mx-2">{place}</h5>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-user fa-3x"></i>
                        </div>
                    </div>, { iframeUrl: url, noScroll: true });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Echec',
                        html: "Aucune adresse à visualiser"
                    });
                }

            }

        }

    });
}

function getColorById(src, id) {
    if (!src || src.length === 0 || !id) return "#FFFFFF";
    var result = src.filter(function (a) {
        return a.id == id;
    });

    if (result.length > 0) {
        return result[0].icon;
    } else {
        return "#FFFFFF";
    }
}
/*function getSvcById(src, id, useAltColor) {
    if (!src || src.length === 0 || !id) return {};
    var result = src.filter(function (a) {
        return a.id == id;
    });

    if (result.length > 0) {
        return result[0];
    } else {
        return {};
    }
}*/
function getStatusById(src, id) {
    if (!src || src.length === 0 || !id) return {};
    var result = src.filter(function (a) {
        return a.idStatus == id;
    });

    if (result.length > 0) {
        return result[0];
    } else {
        return {};
    }
}