import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import {
    Button, Input, Label,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form
} from "reactstrap";

import FilesCustom from 'components/FilesCustom';
import ShowDetails from 'components/ShowDetails';
import Historical from 'components/Historical';

import CNavbar from 'components/CNavbar';

import * as commonFunc from 'scripts/common';
import CDataTable, { showDtPrevID, showDtSelID, showDtNextID, setDtNoSelect } from "components/CDataTable";

import { withRouter } from 'components/withRouter';

class ManageJobs extends Component {
    static displayName = ManageJobs.name;

    static jobModels;
    static refreshCb;
    static modalRoot;

    constructor(props) {
        super(props);

        this.jobGen = React.createRef(null);

        this.state = { jobs: [], curJob: null, curJobId: 0, status: [], defaultStatus: 6, curStatus: 6, loading: true, dataStamp: 0, activeTab: "1", title: props.i18nManager.t("jobs.noSelected"), editionMode: false };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function (curUser) {

            commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: "JOBS" }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {

                    context.jobModels = {};
                    dataModels.result.models.forEach((model) => {
                        context.jobModels[model.extID] = JSON.parse(model.value);
                    });

                    //Chargement via url
                    var defJobLoadId = 0;
                    var defJobActionId = 0;
                    if (context.props.searchParams) {
                        defJobLoadId = parseInt(context.props.searchParams.get("jobId")) || 0;
                        defJobActionId = parseInt(context.props.searchParams.get("actionId")) || 0;
                    }
                    if (defJobLoadId > 0) {
                        var defJobStatusId = parseInt(context.props.searchParams.get("statusId")) || -1;

                        //context.loadStatusBtnGrp(defJobStatusId);

                        context.getJobs(defJobStatusId, false, function () {
                            /*$("#lstActs_filter input[type='search']").val(defReportLoadId);
                            showDtSelID("lstActs", defReportLoadId);
                            $('html, body').animate({
                                scrollTop: $("#detailAct").offset().top
                            }, 500);

                            if (defJobActionId == 2) {
                                $(".action-editReport:visible").click();
                            }*/
                        });
                    } else {
                        //context.loadStatusBtnGrp(-1);

                        context.getJobs();

                        /*if (defJobActionId == 1) {
                            $(".action-addReport:visible").click();
                        }*/
                    }

                } else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModels") + " :<br>" + dataModels.errorMessage
                    });
                }
            });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");
        if (/*prevState.historicalReports !== this.state.historicalReports || */prevState.curStatus !== this.state.curStatus) {
            this.getJobs();
        }

        //Update Scroller Size
        if (prevState.isShowDetails && !this.state.isShowDetails) {
            if (this.props.getScroller) this.props.getScroller().update();
        }

        //Callback initCreateJob
        if (this.state.curJobId === 0 /*&& prevState.curJobId !== this.state.curJobId*/ && this.state.editionMode && !this.state.isShowDetails) {
            this.setState({ isShowDetails: true });
        }
        if (prevState.editionMode !== this.state.editionMode && this.dtFunctions && this.dtFunctions["tableJobs"]) this.dtFunctions["tableJobs"].setDisabled(this.state.editionMode);
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount(); 
            this.modalRoot = null;
        }
    }

    dtFunctionPanel(id, fctPanel) {
        if (!this.dtFunctions) this.dtFunctions = {};
        if (!this.dtFunctions[id]) this.dtFunctions[id] = {};
        this.dtFunctions[id] = fctPanel;
    }

    render() {
        var context = this;

        var listCb = function () {
            if (context.refreshCb) {
                if (context.refreshCb && typeof (context.refreshCb) === "function") { context.refreshCb(); }
                context.refreshCb = null;
            }
        };

        //TODO : createdCell >> dépendance du contexte pour éxecution de la fonction
        //TEMP : doit dépendre d'une configuration (tout virer sauf createdCell et cbInitComplete)
        var dtConfig = {
            columnDefs: [
                {
                    createdCell: (td, cellData, rowData, row, col) => {
                        var currRoot = createRoot(td);
                        currRoot.render(<>{!rowData.isActive ? <Button className='btn-icon m-0 me-1' disabled={this.state.editionMode} color="primary" size="sm"
                            onClick={() => { context.startJob(rowData) }} title={context.props.i18nManager.t("jobs.start")}><i className='fa fa-circle-play'></i></Button> : "" }
                            {rowData.isActive ? <Button className='btn-icon m-0' disabled={this.state.editionMode} color="danger" size="sm"
                                onClick={() => context.stopJob(rowData)} title={context.props.i18nManager.t("jobs.stop")}><i className='fa fa-circle-stop'></i></Button> : ""}
                            {(rowData.progressValue !== 100 && rowData.progressValue !== -1) ? <Button className='btn-icon m-0' disabled={this.state.editionMode} color="danger" size="sm"
                                onClick={() => context.resetJob(rowData)} title={context.props.i18nManager.t("jobs.reset")}><i className='fa fa-times-circle'></i></Button> : ""}</>);
                    }, "targets": "renderingJobsAction"
                },
            ],
            dtOnSelect: function (dataSel) {
                var id = dataSel.idJob;
                if (parseInt(id) > 0) {
                    //if (!context.state.editionMode) context.loadJob(id, dataSel);
                }
            },
            dtOnUnselect: function (dataSel) {
                if (!context.state.editionMode) context.loadJob();
            },
            dtOnDblClick: function (event) {
                /*var id = parseInt(getDtSelID("lstActs"));
                var currId = parseInt($(this).closest("tr").prop("id"));
                if (currId != id) {
                    scrollToId("detailAct");
                    showDtSelID("lstActs", currId);
                }
                else {
                    if (currId > 0) {
                        scrollToId("detailAct");
                    }
                }*/
            },
            cbInitComplete: function (cRootList) {
                //Swal.close();

                /*cRootList.map((prop, elem) => {
                    console.debug(elem);
                    if (elem.classList.contains("cneedroot")) {
                        var rootElem = createRoot(elem);
                        switch (elem.getAttribute("cneedroot")) {
                            case "forceLogout":
                                rootElem.render(<button className="btn btn-primary btn-icon" onClick={() => context.forceLogout('hey!')}><i className='fa fa-edit'></i></button>);
                                break;
                            default:
                                break;
                        }
                    }
                    return null;
                });*/

                //$("#report_select_filters").appendTo($(".dt-buttons").parent().parent());
                //$("#report_select_filters").removeClass("d-none").addClass("d-inline-block");

                //updateSidebar();

                listCb();
            },
            cbCustom: function () {
                listCb();
            }
        };

        //console.debug("render again");

        let contents = this.state.loading ? <p><em>{context.props.i18nManager.t("gal_loading")}</em></p> :
            <div>
                {this.jobModels ? <CDataTable key="jobs" dtId={"tableJobs"} data={this.state.jobs} localConfig={dtConfig} tag={"DT_JOBS"} config={this.jobModels["DT_JOBS"]} stamp={this.state.dataStamp} functionPanel={this.dtFunctionPanel.bind(this)}
                    className="table-striped table-hover nowrap" style={{ fontSize: "90%" }} /> : ""}
            </div>;

        return (
            <div className="tableDetails">
                <Card className={`tableContainer widget dash ${this.state.editionMode ? "module-disabled" : ""}`}>
                    <CardHeader>
                        <CardTitle tag="h6">{context.props.i18nManager.t("jobs.title")}</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-table-list fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <div className="float-start">
                            <Button className="btn-rotate px-2 btn-simple mt-0" disabled={this.state.editionMode} color="dark" size="sm" onClick={() => this.getJobs()}>
                                <i className="fa fa-sync"></i>{context.props.i18nManager.t("gal_refresh")}
                            </Button>
                            {this.props.checkRights("JOBS", "C") ? <Button className="btn-magnify px-2 mt-0 d-none" disabled={this.state.editionMode} color="success" size="sm" onClick={() => this.initCreateJob()}>
                                <i className="fa fa-plus-square"></i>{context.props.i18nManager.t("jobs.add")}
                            </Button> : ""}
                            {this.props.checkRights("JOBS", "A") ? <Button className="btn-magnify px-2 mt-0" disabled={this.state.editionMode} color="primary" size="sm" onClick={() => this.startJob()}>
                                <i className="fa fa-circle-play"></i>{context.props.i18nManager.t("jobs.startAll")}
                            </Button> : ""}
                            {this.props.checkRights("JOBS", "A") ? <Button className="btn-magnify px-2 mt-0" disabled={this.state.editionMode} color="danger" size="sm" onClick={() => this.stopJob()}>
                                <i className="fa fa-circle-stop"></i>{context.props.i18nManager.t("jobs.stopAll")}
                            </Button> : ""}
                        </div>
                        <CNavbar className="float-end">
                            <div className="text-end">
                                {this.state.status.map((dt) => {

                                    //TODO : statusBtnAltProc

                                    var statusClass = "form-badge-radio mb-1 ";
                                    statusClass += dt.css ? dt.css.replace("fa-", "untrigger") : "";
                                    return dt.isSchedulable ? <div id={`st_${dt.idStatus}`} key={dt.idStatus} className={statusClass}>
                                        <div className='form-check-radio fcheck-sm mb-0'>
                                            <Label check >
                                                <Input id={`status_${dt.idStatus}`} name={"status"} disabled={this.state.editionMode} checked={dt.idStatus === context.state.curStatus} type="radio" data-lvlv={dt.step} value={dt.idStatus} onChange={(e) => {
                                                    var value = parseInt(e.target.value);

                                                    context.setState({ curStatus: value, curJobId: 0, curJob: null, isShowDetails: false });
                                                }} />
                                                <span className="form-check-sign" />
                                                {(() => {
                                                    var classFa = "";
                                                    if (dt.css && dt.css.indexOf("fa-")) {
                                                        dt.css.split(" ").forEach((classElem) => {
                                                            //console.debug(classElem.startsWith("fa-") + classElem);
                                                            classFa += (classElem.startsWith("fa-") ? (classElem + " ") : "");
                                                        });
                                                    }
                                                    return <i className={`fa ${classFa} me-1`}></i>
                                                })()}
                                                {dt.name}
                                            </Label>
                                        </div>
                                    </div> : "";
                                })}
                            </div>
                        </CNavbar>
                        {contents}
                    </CardBody>
                </Card>

                {this.state.isShowDetails ? (<div className="detailsContainer">
                    <Card className="widget dash text-white" color="theme">
                        <CardHeader>
                            <Row>
                                <Col lg="10" className="offset-lg-1">
                                    <CardTitle tag="h6" className="text-center">
                                        {this.state.title}
                                    </CardTitle>
                                </Col>
                                <Col lg="1" className="d-flex text-end float-end justify-content-end">
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActPrev" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curJobId || 0;
                                        showDtPrevID("tableJobs", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-left"></i></Button>
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActNext" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curJobId || 0;
                                        showDtNextID("tableJobs", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-right"></i></Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Nav pills className={`${this.state.editionMode ? "invisible" : ""}`}>
                                {this.props.checkRights("JOBS", "R") ? <NavItem><NavLink className={`${this.state.activeTab === '1' ? "active" : ""}`} onClick={() => { this.switchTab("1") }}>{context.props.i18nManager.t("jobs.tabDetails")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("JOBS_FILES", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '3' ? "active" : ""}`} onClick={() => { this.switchTab("3") }}>{context.props.i18nManager.t("jobs.tabDocs")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("JOBS_FLOWS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '5' ? "active" : ""}`} onClick={() => { this.switchTab("5") }}>{context.props.i18nManager.t("jobs.tabFlows")}</NavLink></NavItem> : ""}
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.props.checkRights("JOBS", "R") ? <TabPane tabId="1">
                            <ShowDetails id={this.state.curJobId} type="job" genRef={this.jobGen} data={this.state.curJob} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.jobModels ? this.jobModels["JOBS"] : {}} modelsList={this.jobModels} loadElem={this.loadJob.bind(this)} saveElem={this.saveJob.bind(this)} removeElem={this.removeJob.bind(this)} updateStatusElem={this.updateJobStatus.bind(this)} setEditingMode={this.setEditingMode.bind(this)} formEditing={this.state.editionMode} shouldRender={this.state.activeTab === '1'} />
                        </TabPane> : ""}
                        {this.props.checkRights("JOBS_FILES", "L") ? <TabPane id="aupdAct-3" tabId="3">
                            <Form id="frmaupdact3">
                                <FilesCustom className="job-files" bindID={this.state.curJobId} rightType="JOBS_FILES" checkRights={this.props.checkRights} fileTypeID="JOBS" src={this.state.curJob} srcName={this.state.curJob ? this.state.curJob.name : ""} withFilters={true} shouldRender={this.state.activeTab === '3'} altFilesTypes="../api/data/GetFilesType" altFilesUrl="../api/data/GetFiles" altRemoveFilesUrl="../api/data/RemoveFile" altUploadFilesUrl="../api/data/UploadFiles" />
                            </Form>
                        </TabPane> : ""}
                        {this.props.checkRights("JOBS_FLOWS", "L") ? <TabPane tabId="5">
                            <Row className="job-history">
                                <Col lg="12">
                                    <Card className="widget dash">
                                        <CardHeader>
                                            <CardTitle tag="h6">{context.props.i18nManager.t("site.flowsTitle")}</CardTitle>
                                            <div className="widget-tools collapse-tool">
                                                <i className="fa fa-history fa-4x"></i>
                                            </div>
                                            <div className='borderTheme'></div>
                                        </CardHeader>
                                        <CardBody>
                                            <Historical type="job" elemId={context.state.curJobId} url="../api/data/GetJobFlows" getRoutes={context.props.getRoutes} shouldRender={context.state.activeTab === '5'} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane> : ""}
                    </TabContent></div>) : ""}
            </div>
        );
    }

    async getJobs(statusId, forcedUpdate, callback) {
        if (!forcedUpdate && this.state.editionMode) return;
        this.setState({ jobs: [], curJobId: 0, curJob: null/*, loading: true*/, dataStamp: 0, isShowDetails: false, editionMode: false });
        var context = this;

        if (callback) {
            context.refreshCb = callback;
        }

        Swal.fire({
            title: context.props.i18nManager.t("gal_loading"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var args = {};
        args.state = statusId || this.state.curStatus || 0;

        commonFunc.getMethod("../api/manage/GetJobsList", args).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {

                    //TEMP
                    //response.data.result = [{ idJob: 1, name: "jsonTest", isActive: true }, { idJob: 2, name: "jsonTest2", isActive: false }];

                    /*$('#lstActs thead th').each(function () {
                        var title = $(this).text();
                        if ($(this).hasClass("searchable")) {
                            $(this).append('<input type="text" class="form-control col-lg-12 form-control-sm" placeholder="' + title + '" />');
                        }
                    });*/

                    Swal.close();
                    context.setState({ jobs: response.data.result, loading: false, dataStamp: new Date().getTime(), isShowDetails: false });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_error"),
                        html: context.props.i18nManager.t("jobs.failLoadJobs") + " :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_error"),
                    html: context.props.i18nManager.t("jobs.failLoadJobs")
                });
            }
        });

    }

    /*loadStatusBtnGrp(defaultID) {
        var context = this;

        commonFunc.getMethod("../weatherforecast/WFGetStatus", null).then((response) => {
            var data = response.data;

            //Libelle = name + ForeColor = foreColor + Opacite >> removed + Niveau = step + Categ = categ + Actif = isActive + Planifiable = isSchedulable + css + valeur = value
            if (data.isSuccess) {

                context.setState({ status: data.result, defaultStatus: defaultID });

            } else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_fail"),
                    html: context.props.i18nManager.t("gal_failLoadStatus") + "<br/>" + response.data.errorMessage
                });
            }
        });
    }*/

    async loadJob(id) {
        var context = this;

        var callback = this.loadReportCb;
        this.loadReportCb = null;

        this.setState({ title: context.props.i18nManager.t("jobs.noSelected"), curJobId: 0, curJob: null, isShowDetails: (id > 0), editionMode: false });

        return new Promise((resolve, reject) => {

            var args = {};
            //args.state = 201;
            args.id = id;
            if (parseInt(id) > 0) {
                Swal.fire({
                    title: context.props.i18nManager.t("gal_loading"),
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                commonFunc.getMethod("../api/data/GetJob", args).then((response) => {
                    var data = response.data;
                    if (data.isSuccess) {
                        console.log(data.result);

                        if (callback && typeof (callback) === "function") { callback(data); }

                        Swal.close();

                        if (context.state.isShowDetails) context.setState({ title: ("[" + data.result.firm + "] " + data.result.name + " " + data.result.firstName), curJobId: id, curJob: data.result, isShowDetails: true });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: context.props.i18nManager.t("gal_error"),
                            html: context.props.i18nManager.t("jobs.failLoadJob") + " :<br>" + data.errorMessage
                        });
                    }
                    resolve(data);
                });

            }
            else {
                resolve({ isSuccess: true, result: "" });
            }
        });
    }

    async saveJob(userJob, isUpdate) {
        var context = this;

        Swal.fire({
            title: context.props.i18nManager.t("gal_saving"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        const dataJob = this.state.curJob || {};
        console.debug(dataJob)
        userJob.idJob = isUpdate ? dataJob.idJob : 0;

        console.debug(userJob);
        //return new Promise((resolve, reject) => { resolve({ isSuccess: false }); });//TEMP
        return commonFunc.postMethod(isUpdate ? "../api/data/UpdateJob" : "../api/data/CreateJob", userJob).then((response) => {
            Swal.close();
            return new Promise((resolve, reject) => {
                var data = response.data;
                if (data && data.isSuccess) {
                    Swal.fire({
                        icon: "success",
                        title: context.props.i18nManager.t("gal_success"),
                        html: (isUpdate ? context.props.i18nManager.t("gal_successUpd") : context.props.i18nManager.t("gal_successAdd")),
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        /*context.getJobs(0, true, () => {
                            showDtSelID("tableJobs", data.result.idJob);
                        });*/
                        context.loadJob(data.result.idJob);//userJob.idJob
                    });

                    data.result.idResponse = data.result.idJob;
                    resolve(data.result);

                } else {
                    var supErrors = "";
                    if (data.errors && data.errors.length) {
                        supErrors = data.errors.map((error) => {
                            return "<br>" + error.desc + "(" + error.value + ")";
                        });
                    }
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_errorSave") + " :<br>" + data.errorMessage + supErrors
                    });
                    resolve(data);
                }
            });
        });
    }

    removeJob(id, title) {
        var context = this;
        return new Promise((resolve, reject) => {
            var row = context.state.curJob;
            if ((!id && row) || (id && row && id === row.idJob)) {
                id = id || row.idJob;
                title = row.name + " " + row.firstName;
            }
            if (parseInt(id) > 0) {
                Swal.fire({
                    icon: "question",
                    title: context.props.i18nManager.t("gal_removing"),
                    html: context.props.i18nManager.t("jobs.rmvTitle") + "<br/>[" + id + "] " + title,
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: context.props.i18nManager.t("gal_yes"),
                    cancelButtonText: context.props.i18nManager.t("gal_no"),
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: context.props.i18nManager.t("jobs.removing"),
                            didOpen: () => { Swal.showLoading() }
                        });
                        var req = { id: id, isPermanent: false };
                        commonFunc.postMethod("../api/data/RemoveJob", req).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data && data.isSuccess) {
                                context.getJobs();//TODO : check si besoin de refresh supplémentaire
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: context.props.i18nManager.t("gal_fail"),
                                    html: context.props.i18nManager.t("gal_errorRmv") + "<br/>" + response.data.errorMessage
                                });
                            }
                            resolve(data);
                        });

                    }
                    else {
                        resolve({ isSuccess: true, result: "" });
                    }
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_fail"),
                    html: context.props.i18nManager.t("gal_noSelectedElem")
                });
                resolve({ isSuccess: false, result: context.props.i18nManager.t("gal_noSelectedElem") });
            }
        });
    }


    updateJobStatus(statusId, stepId, customLabel) {
        var context = this;
        return new Promise((resolve, reject) => {
            var id = context.state.curJobId;
            if (parseInt(id) > 0) {
                if (statusId > 0) {
                    var label = "";
                    var reloadJob = true;

                    var curStatus = context.state.status.filter((status) => status.idStatus === statusId);
                    label = customLabel || (curStatus.length ? curStatus[0].name : "");


                    Swal.fire({
                        icon: "question",
                        title: context.props.i18nManager.t("jobs.updStatus"),
                        html: context.props.i18nManager.t("jobs.statusAs", [label]),
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: context.props.i18nManager.t("gal_yes"),
                        cancelButtonText: context.props.i18nManager.t("gal_no"),
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: context.props.i18nManager.t("jobs.updatingStatus"),
                                didOpen: () => { Swal.showLoading() },
                            });
                            var req = { id: id, newStatus: statusId, newStep: stepId || -1 };

                            commonFunc.postMethod("../api/data/UpdateStatus", req).then((response) => {

                                Swal.close();
                                if (response.data && response.data.isSuccess) {
                                    /*context.getJobs(0, true, () => {
                                        if (reloadJob) showDtSelID("tableJobs", id);
                                    });*///TODO : check si besoin de refresh supplémentaire
                                    if (reloadJob) context.loadJob(id);
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: context.props.i18nManager.t("gal_fail"),
                                        html: context.props.i18nManager.t("gal_failUpdStatus") + "<br/>" + response.data.errorMessage
                                    });
                                }
                                resolve(response.data);
                            });
                        }
                        else {
                            resolve({ isSuccess: true, result: "" });
                        }
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_invalidStatus")
                    });
                    resolve({ isSuccess: false, result: context.props.i18nManager.t("gal_invalidStatus") });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_fail"),
                    html: context.props.i18nManager.t("gal_noSelectedElem")
                });
                resolve({ isSuccess: false, result: context.props.i18nManager.t("gal_noSelectedElem") });
            }
        });
    }

    startJob(row) {
        var context = this;
        return new Promise((resolve, reject) => {
            var title = "";
            var id = 0;
            if (row) {
                id = row.idJob;
                title = row.title;
            }

            Swal.fire({
                icon: "question",
                title: context.props.i18nManager.t("jobs.startTitle"),
                html: (id ? (context.props.i18nManager.t("jobs.startingTitle") + "<br/>[" + id + "] " + title) : context.props.i18nManager.t("jobs.startingTitleAll")),
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: context.props.i18nManager.t("gal_yes"),
                cancelButtonText: context.props.i18nManager.t("gal_no"),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: (id ? context.props.i18nManager.t("jobs.starting") : context.props.i18nManager.t("jobs.startingAll")),
                        didOpen: () => { Swal.showLoading() }
                    });
                    var req = { id: id, skipTasks: false };
                    commonFunc.postMethod("../api/manage/StartJob", req).then((response) => {
                        Swal.close();
                        var data = response.data;
                        if (data && data.isSuccess) {
                            context.getJobs();//TODO : check si besoin de refresh supplémentaire
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: context.props.i18nManager.t("gal_fail"),
                                html: context.props.i18nManager.t("jobs.failStart") + "<br/>" + response.data.errorMessage
                            });
                        }
                        resolve(data);
                    });

                }
                else {
                    resolve({ isSuccess: true, result: "" });
                }
            });
        });
    }

    stopJob(row) {
        var context = this;
        return new Promise((resolve, reject) => {
            var title = "";
            var id = 0;
            if (row) {
                id = row.idJob;
                title = row.name + " " + row.firstName;
            }

            Swal.fire({
                icon: "warning",
                title: context.props.i18nManager.t("jobs.stopTitle"),
                html: (id ? (context.props.i18nManager.t("jobs.stopingTitle") + "<br/>[" + id + "] " + title) : context.props.i18nManager.t("jobs.stopingTitleAll")),
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: context.props.i18nManager.t("gal_yes"),
                cancelButtonText: context.props.i18nManager.t("gal_no"),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: (id ? context.props.i18nManager.t("jobs.stoping") : context.props.i18nManager.t("jobs.stopingAll")),
                        didOpen: () => { Swal.showLoading() }
                    });
                    var req = { id: id, isPermanent: false };
                    commonFunc.postMethod("../api/data/StopJob", req).then((response) => {
                        Swal.close();
                        var data = response.data;
                        if (data && data.isSuccess) {
                            context.getJobs();//TODO : check si besoin de refresh supplémentaire
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: context.props.i18nManager.t("gal_fail"),
                                html: context.props.i18nManager.t("jobs.failStop") + "<br/>" + response.data.errorMessage
                            });
                        }
                        resolve(data);
                    });

                }
                else {
                    resolve({ isSuccess: true, result: "" });
                }
            });
        });
    }

    resetJob(row) {
        var context = this;
        return new Promise((resolve, reject) => {
            var title = "";
            var id = 0;
            if (row) {
                id = row.idJob;
                title = row.name + " " + row.firstName;
            }

            Swal.fire({
                icon: "warning",
                title: context.props.i18nManager.t("jobs.resetTitle"),
                html: (id ? (context.props.i18nManager.t("jobs.resetingTitle") + "<br/>[" + id + "] " + title) : context.props.i18nManager.t("jobs.resetingTitleAll")),
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: context.props.i18nManager.t("gal_yes"),
                cancelButtonText: context.props.i18nManager.t("gal_no"),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: (id ? context.props.i18nManager.t("jobs.reseting") : context.props.i18nManager.t("jobs.resetingAll")),
                        didOpen: () => { Swal.showLoading() }
                    });
                    var req = { id: id, isPermanent: false };
                    commonFunc.postMethod("../api/data/ResetJob", req).then((response) => {
                        Swal.close();
                        var data = response.data;
                        if (data && data.isSuccess) {
                            context.getJobs();//TODO : check si besoin de refresh supplémentaire
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: context.props.i18nManager.t("gal_fail"),
                                html: context.props.i18nManager.t("jobs.failReset") + "<br/>" + response.data.errorMessage
                            });
                        }
                        resolve(data);
                    });

                }
                else {
                    resolve({ isSuccess: true, result: "" });
                }
            });
        });
    }

    initCreateJob() {
        if (this.state.editionMode) return;
        setDtNoSelect("tableJobs");
        //this.jobGen.current.resetForm();
        this.setState({ title: this.props.i18nManager.t("site.create"), curJobId: 0, curJob: null, isShowDetails: false, editionMode: true, activeTab: "1" });
    }

    switchTab(numTab) {
        if (this.state.editionMode) return;
        this.setState({ activeTab: numTab })
    }

    setEditingMode(value, isDuplicate) {
        var title = this.state.curJob ? (this.state.curJob.name + " " + this.state.curJob.firstName) : "";
        this.setState({ title: (isDuplicate ? this.props.i18nManager.t("jobs.duplicate") : this.props.i18nManager.t("jobs.edit")) + " - " + title, editionMode: value });
    }
}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}

export default withRouter(withI18n(ManageJobs));