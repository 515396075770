import React, { forwardRef } from 'react';

//import Datepicker from "react-datetime";
import Datepicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

function DPCustom(props) {
    function renderDay(props, currentDate, selectedDate) {
        return <>
            <td {...props}><span className="c-rdt-week">{currentDate.week()}</span>{currentDate.date()}</td>
        </>;
    }

    return <div id={props.parentId} className={props.parentClassName}><Datepicker {...props} className={`${props.invalid ? "is-invalid" : ""} ${props.className ? props.className : ""}`} /></div>;
}

export default DPCustom;

