import React from 'react';
import { createRoot } from 'react-dom/client';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import * as commonFunc from 'scripts/common';
import CDataTable, { showDtPrevID, showDtNextID, getDtSelRowID, getDtSelData } from "components/CDataTable";

import {
    Input,
    Label, Button,
    FormGroup, FormFeedback,
    InputGroup, InputGroupText, Col,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

var modalRoot;

export default function FinderFrag({ data, formManager, modelFctManager, bindPropResult, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors, control } = formManager;

    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }

        return () => {
            //console.debug("unmount");
            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }
        }
    }, []);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.setValue = (value, withRerender, src) => {
        return new Promise((resolve, reject) => {
            setValue(bindPropResult, value);
            if (withRerender) setShouldRender(!shouldRender);

            resolve({ isSuccess: true, result: value });
        });
    };

    if (typeof data.defaultValue === "object") {
        var textValue = "";
        if (data.argumentsObj && data.argumentsObj.inputValues) {
            var splitValues = data.argumentsObj.inputValues.split(";");
            splitValues.forEach((e, i) => {
                if (data.defaultValue[e] !== undefined && data.defaultValue[e] !== null) {
                    textValue += data.defaultValue[e] + " ";
                }
            });
        }
        data.defaultValue = textValue;
    }


    const { ref: refElem, ...restElem } = register(bindPropResult, {
        value: data.value || ((data.defaultValue || data.defaultValue === 0) ? data.defaultValue : ""),
        valueAsNumber: (data.subType === "integer" || data.subType === "float"),
        onChange: (e) => {
            onChange();
        }
    });

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));


    function onChange() {
        console.debug("onChange");
        if (data.onActionEvents && data.onActionEvents["onChange"]) {
            data.onActionEvents["onChange"].forEach((args, index) => {
                //var val = bindPropResult ? getValues(bindPropResult) : "";
                var val = data.textValue;
                modelFctManager.onActionRequired(data.name, args, data, { type: data.subType, value: val, secondValue: data.value }).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }

    function performClick() {
        if (disabled) return;
        if (modalRoot) {
            modalRoot.unmount();
            modalRoot = null;
        }

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (id, dataRow) {
            console.debug(id);
            if (id) {
                var textValue = "";
                if (data.argumentsObj && data.argumentsObj.inputValues) {
                    var splitValues = data.argumentsObj.inputValues.split(";");
                    splitValues.forEach((e, i) => {
                        if (dataRow[e] !== undefined && dataRow[e] !== null) {
                            textValue += dataRow[e] +" ";
                        }
                    });
                }


                //textValue = dataRow.name + " " + (dataRow.firstName || "");//TODO : déterminé quoi récup pour l'affichage input
                data.value = (data.subType === "text") ? textValue : id;
                data.textValue = (data.subType === "text") ? id : textValue;
                setValue(bindPropResult, data.value);
                //setShouldRender(!shouldRender);
                onChange();

                modalRoot.unmount();
            }
            else {
                if (id === 0) {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        html: t("gal_noSelectedElem")
                    });
                }
                else {
                    modalRoot.unmount();
                }
            }
        };

        if (data.argumentsObj && data.argumentsObj.dialogConfig) {

            modelFctManager.onActionRequired(data.name, { trigger: "getModel", value: data.argumentsObj.dialogConfig }, data).then(actionResponse => {
                if (actionResponse.isSuccess) {

                    if ((!data.options || data.options.length === 0) && data.argumentsObj.trigger) {
                        modelFctManager.onActionRequired(data.name, { trigger: data.argumentsObj.trigger, url: data.argumentsObj.url, value: data.argumentsObj.value }, data, data.argumentsObj).then(actionDataRes => {
                            if (actionDataRes.isSuccess) {
                                modalRoot = createRoot(document.createElement('div'));
                                modalRoot.render(<FinderModal t={t} configModel={actionResponse.result} rows={actionDataRes.result} title={"Sélection " + data.title} tag={data.argumentsObj.dialogConfig} callback={callbackModal} />);
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: t("gal_error"),
                                    html: actionDataRes.errorMessage
                                });
                            }
                        });
                    }
                    else {
                        modalRoot = createRoot(document.createElement('div'));
                        modalRoot.render(<FinderModal t={t} configModel={actionResponse.result} rows={data.options} title={"Sélection " + data.title} tag={data.argumentsObj.dialogConfig} callback={callbackModal} />);
                    }
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        html: actionResponse.errorMessage
                    });
                }
            });
        }
        else {
            Swal.fire({
                icon: "error",
                title: t("gal_error"),
                html: t("gal_invalidArgs")
            });
        }
    }

    function renderInput(inputClass) {
        //TODO : formattage chargement valeurs

        return <div className="d-flex align-items-center">
            <Controller
                control={control}
                name={bindPropResult}
                render={({ field: { ref, ...field } }) => (
                    <Input type="text" id={"input" + bindPropResult} className={`${inputClass}`} disabled={true} placeholder={altT(data.placeHolder)} readOnly={data.readOnly} autoComplete="no" aria-autocomplete="none"
                        innerRef={ref} {...field} value={field.value} invalid={commonFunc.getValue(errors, bindPropResult) ? true : false} />
                )}
            />
            <Button className="ms-1 btn-sm btn-icon btn-round m-0" color="primary" disabled={disabled} onClick={() => { performClick() }}><i className="fa fa-search"></i></Button>
        </div>;

    }

    function renderTitle(positions, titleWidthClass) {
        var design = data.customDesign ? data.customDesign.designTitle : null;
        if (!design) design = {}
        if (!design.position) design.position = "left";

        var titleModel = <Label className={`${titleWidthClass} ${(design.position === "left" || design.position === "right") ? "m-0" : ""} ${(!commonFunc.isBlank(data.title) && data.required) ? "mandatory" : ""}`} for={"input" + bindPropResult}>{altT(data.title)}</Label>;

        if (!commonFunc.isBlank(data.title) && positions.indexOf(design.position) >= 0) return titleModel;
        else return "";
    }

    var frameClass = "mb-3";
    var containerClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    var formClass = "form-group m-0 ";

    var inputClass = "";
    var widthClass = "";
    var titleWidthClass = "px-0 ";
    if (data.customDesign) {
        if (data.customDesign.design) {
            if (data.customDesign.design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.design.boxSize) * 100);
            }
        }
        if (data.customDesign.designInput) {
            if (data.customDesign.designInput.boxSize) {
                //widthClass += " w-" + parseInt(parseFloat(data.customDesign.designInput.boxSize) * 100);
                widthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designInput.boxSize) * 100);
            }
        }
        if (data.customDesign.designTitle) {
            if (data.customDesign.designTitle.alignment) {
                var split = data.customDesign.designTitle.alignment.split("|");
                split.forEach((part) => {
                    titleWidthClass += " text-" + part.replace("left", "start").replace("right", "end");
                    titleWidthClass = titleWidthClass.replace("text-top", "align-self-start").replace("text-bottom", "align-self-end");
                });
            }
            if (data.customDesign.designTitle.position === "floating") {
                data.placeHolder = data.title;
                formClass += " form-floating min-input";
            }
            if (data.customDesign.designTitle.boxSize) {
                //titleWidthClass += " w-" + parseInt(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass = titleWidthClass.replace("px-0", "ps-3 px-md-0").replace("text-end", "text-md-end");
            }
        }

        if (!commonFunc.isBlank(widthClass) || titleWidthClass !== "px-0 ") containerClass += " row m-0 row-cols-lg-auto2 align-items-center";
    }



    var baseForm = <div className={`${containerClass} ${formClass}`}>
        {renderTitle(["top", "left"], titleWidthClass)}
        <div className={`${widthClass}`}>
            {renderInput(inputClass)}
            <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
        </div>
        {renderTitle(["bottom", "right"], titleWidthClass)}
    </div>;

    if (data.customDesign && data.customDesign.designTitle && data.customDesign.designTitle.position === "floating") {
        baseForm = <div className={`${containerClass}`}>
            <div className={`${widthClass} ${formClass}`}>
                <div className={`${formClass}`}>
                    {renderInput(inputClass)}
                    {renderTitle(["floating"])}
                </div>
            </div>
            <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
        </div>;
    }

    if (data.customDesign && data.customDesign.designIcon) {
        var iconDesign = data.customDesign.designIcon;

        baseForm = <div className={`${containerClass}`}>
            {renderTitle(["top", "left"], titleWidthClass)}
            <InputGroup className={`single-input ${widthClass}`}>
                <InputGroupText className={`${(data.attributes && data.attributes.required !== undefined) ? "mandatory" : ""}`} title={data.customDesign && data.customDesign.designTitle && data.customDesign.designTitle.position === "title" ? altT(data.title) : ""} >
                    <i className={`fa ${iconDesign.icon.replace(/_/g, "-") }`} />
                </InputGroupText>
                <div className={`${formClass}`} style={{flex:1}}>
                    {renderInput(inputClass)}
                    {renderTitle(["floating"])}
                </div>
                <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
            </InputGroup>
            {renderTitle(["bottom", "right"], titleWidthClass)}
        </div>;
    }
    //console.debug("render input");

    return <Col data-name="FinderFrag" data-element={data.objType} xs="auto" className={`${frameClass} ${parentProps && parentProps.parentData && parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`}>
        {baseForm}
    </Col>;//align-self-center
}


function FinderModal({ t, title, configModel, noScroll, rows, tag, callback }) {

    const [dataStamp, setDataStamp] = React.useState(new Date().getTime());
    const [dtFunctions, setDtFunctions] = React.useState();

    function dtFunctionPanel(id, fctPanel) {
        var newFcts = dtFunctions || {};
        if (!newFcts[id]) newFcts[id] = {};
        newFcts[id] = fctPanel;
        setDtFunctions(newFcts);
    }

    var dtConfig = {};

    return (
        <Modal isOpen={true} className={`modal-finder ${noScroll ? "modal-noscroll" : ""}`} size="xxl" scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={{ overflowX: "hidden" }}>
                {configModel ? <CDataTable key={"tableFinder"} dtId={"tableFinder"} data={rows} localConfig={dtConfig} tag={tag} config={configModel} stamp={dataStamp} allowEmpty
                    functionPanel={dtFunctionPanel} className="table-striped table-hover nowrap" style={{ fontSize: "90%" }} /> : ""}
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <Button className="px-3" color="success" onClick={() => {
                    var valueSel = getDtSelRowID("tableFinder") || 0;
                    var dataSel = getDtSelData("tableFinder");
                    callback(valueSel, dataSel);
                }}><i className="fa fa-check"></i>{t("gal_validate")}</Button>
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>{t("gal_cancel")}</Button>
            </ModalFooter>
        </Modal>
    );
}