/// <reference path="../paycards.js" />
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import moment from "moment";
import Swal from "sweetalert2";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import * as commonFunc from 'scripts/common';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
    Form,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import { Menu, Item, Separator, useContextMenu } from "react-contexify"
import "react-contexify/dist/ReactContexify.css";

import TakePhoto from "components/TakePhoto"

import ReportTabFrag from "components/generic/ReportTabFrag"
import TabFrag from "components/generic/TabFrag"
import CategoryFrag from "components/generic/CategoryFrag"
import HeaderFrag from "components/generic/HeaderFrag"
import GroupFrag from "components/generic/GroupFrag"
import ItemsContainerFrag from "components/generic/ItemsContainerFrag"
import EditTextFrag from "components/generic/EditTextFrag"
import SpinnerFrag from "components/generic/SpinnerFrag"
import ButtonFrag from "components/generic/ButtonFrag"
import CheckboxFrag from "components/generic/CheckboxFrag"
import RadioButtonsFrag from "components/generic/RadioButtonsFrag"
import PictureFrag from "components/generic/PictureFrag"
import TextViewFrag from "components/generic/TextViewFrag"
import FinderFrag from "components/generic/FinderFrag"
import ProgressBarFrag from "components/generic/ProgressBarFrag"

import SimpleListGeneric from "components/generic/alt/SimpleListGeneric"


//Config avec squelette des catégorie
// + data avec attribut=Catégorie
// >> Composant dédié pour afficher le html via config + data
//Voir pour gérer les fonctions du parent dans un objet pour qu'elles soit accessible via string

//Gérer un système de droit (information qui sera récupéré dans le Get de l'objet >> Voir avec Sébastien pour la gueule de l'objet (droit + catégories))


/*
baseValue = defaultValue
propNameType = type
attributes = regex
group = classParent
template = class OU classCollection = class
valueType = type front (Input, Select, TextArea etc…)
required = required
important = champs historique
*/

const tabRules = {
    "String": yup.string(),
    "Int32": yup.number(),
    "Object": yup.object(),
    "object": yup.object(),
};
const inputTypes = {
    "string": "text",
    "number": "number",
    "email": "email",
};

var dataCache = {};
var forcedCreate = false;
var isUpdateGen = false;

const AGenerateModel = React.forwardRef(({ onSubmit, noButtons, appProps, checkRights, getRoutes, getUser, modelsList, parentProps, keyMode, configModel, loadedData, children, formEditing }, ref) => {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };


    var curGenerateTag = "generate-" + Math.floor(Math.random() * 1000000000);

    if (loadedData) {
        if (loadedData.custom && loadedData.custom.extras && !loadedData.custom.orgExtras) {
            loadedData = JSON.parse(JSON.stringify(loadedData));
            loadedData.custom.orgExtras = loadedData.custom.extras;
            loadedData.custom.extras = {};
            console.debug(loadedData.custom.orgExtras);
            loadedData.custom.orgExtras.forEach((extra) => {
                loadedData.custom.extras[extra.name] = extra.value;
            });
        }
        else if (loadedData.extras && !loadedData.orgExtras) {
            loadedData = JSON.parse(JSON.stringify(loadedData));
            loadedData.orgExtras = loadedData.extras;
            loadedData.extras = {};
            console.debug(loadedData.orgExtras);
            loadedData.orgExtras.forEach((extra) => {
                loadedData.extras[extra.name] = extra.value;
            });
        }
    }

    var curElementList = {};
    const [elementList, setElementList] = React.useState({});
    const [genModel, setGenModel] = React.useState(configModel);

    if (formEditing === true || formEditing === false) {
        genModel.disabled = !formEditing;
    }
    isUpdateGen = (loadedData ? !forcedCreate : false);
    forcedCreate = false;

    var modalRoot;
    const showContextMenu = useContextMenu().show;
    const hideAllMenu = useContextMenu().hideAll;

    const modalGenManager = React.createRef();

    const modelFctManager = {
        getRoutes: getRoutes,
        appProps: appProps,
        rootData: genModel,
        checkRights: checkRights,
        onActionRequired: onActionRequired,
        componentTyped: componentTyped,
        getRgbaFromHex: getRgbaFromHex,
        convertWidthToGrid: convertWidthToGrid,
        chainEvents: chainEvents,
        commonRenderProc: commonRenderProc,
        dataCfg: parentProps ? parentProps.dataCfg : {}
    };

    var yupLocale = t("validation", { returnObjects: true });//TODO : fusion avec un json fourni par GetSchemaModel
    yup.setLocale(yupLocale);

    var keyArgs = ["min", "max", "length", "moreThan", "lessThan"];
    var customYup = {};
    customYup["phoneFr"] = function (yup, regex, excludeEmptyString, customMsg) {
        var phoneRegExp = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/u;
        return yup.matches((regex || phoneRegExp), { message: customMsg || t("validation.string.phone"), excludeEmptyString: excludeEmptyString });
    };
    customYup["phone"] = function (yup, regex, excludeEmptyString, customMsg) {
        var phoneRegExp = /^(?:(?:\+|00)[1-9]{0,3}[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/u;
        return yup.matches((regex || phoneRegExp), { message: customMsg || t("validation.string.phone"), excludeEmptyString: excludeEmptyString });
    };


    React.useEffect(() => {
        setElementList(curElementList);
        return () => {
            //console.debug("unmount");
            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }
        }
    }, []);

    React.useImperativeHandle(ref, () => ({
        submitForm: (isUpdate) => { handleSubmit((data) => { triggerOnSubmit(data, isUpdate) })() },
        resetForm: () => { reset(); },
    }));

    function triggerOnSubmit(data, isUpdate) {
        //Gestion des extras
        var extras = data.extras;
        data.extras = isUpdate ? (loadedData.custom ? (loadedData.custom.orgExtras || []) : (loadedData.orgExtras || [])) : [];

        var extrasType = parentProps.subType || parentProps.type;
        var primaryId = commonFunc.camelize("ID" + commonFunc.capitalize(extrasType), true);
        var secondaryId = commonFunc.camelize(extrasType + "ID", true);

        if (extras && Object.keys(extras).length) {
            var flagModifElem = false;
            Object.keys(extras).forEach((key) => {
                if (isUpdate) {
                    var existingExtra = data.extras.filter((extra) => extra.name === key);
                    if (existingExtra.length > 0) {
                        existingExtra.forEach((extra) => {
                            flagModifElem |= (extra.value !== extras[key]);
                            extra.value = extras[key];
                        });
                    }
                    else {
                        var extra = { name: key, value: extras[key], idExtra: 0 };
                        extra[secondaryId] = data[primaryId];
                        data.extras.push(extra);
                        flagModifElem = true;
                    }
                }
                else {
                    var extraAdd = { name: key, value: extras[key], idExtra: 0 };
                    extraAdd[secondaryId] = data[primaryId];
                    data.extras.push(extraAdd);
                }
            });
            if (flagModifElem) data.dSysMod = new Date();
        }

        var dataList = Object.keys(elementList).length > 0 ? elementList : curElementList;

        //Vérification validité adresse
        Object.keys(data).forEach((key) => {
            var field = data[key];
            //Reset des valeurs par défaut pour Adresse à -1
            if (field && field.idAddress && field.idAddress === -1) {
                if (!field.avoidReset) {
                    Object.keys(field).forEach((addressKey) => {
                        if (!commonFunc.isBlank(addressKey)) {
                            var child = dataList[commonFunc.camelize(key + "." + addressKey, true)];
                            if (child) {
                                var systemValue = "";
                                data[key][addressKey] = child.modalDefaultValue === undefined ? systemValue : child.modalDefaultValue;
                                //TODoO : check pour pb adresse
                            }
                        }
                    });
                }
                else {
                    Object.keys(field).forEach((addressKey) => {
                        if (!commonFunc.isBlank(addressKey)) {
                            var child = dataList[commonFunc.camelize(key + "." + addressKey, true)];
                            if (child && commonFunc.isBlank(data[key][addressKey]) && data[key][addressKey] !== 0) {
                                var systemValue = "";
                                data[key][addressKey] = child.modalDefaultValue === undefined ? systemValue : child.modalDefaultValue;
                                //TODoO : check pour pb adresse
                            }
                        }
                    });
                    field.idAddress = 0;
                }

                data[key + "ID"] = 0;
            }
            if (field && field.idAddress !== null && field.idAddress !== undefined && field.idAddress !== data[key + "ID"]) {
                data[key + "ID"] = field.idAddress;
            }
        });


        onSubmit(data, isUpdate).then((dataResult) => {
            if (dataResult && dataResult.idResponse) {
                Object.keys(dataList).forEach((key) => {
                    var dataElem = dataList[key];
                    if (dataElem.onModelSubmited) {
                        dataElem.functionManager.onSubmit(dataResult, dataResult.idResponse);
                    }
                });
            }
        });
    }


    //i18n.addResource('fr', 'translation', "tryit.label", 'hello from namespace 1');

    function createNestedObject(base, names, value) {
        // On récupère le dernier argument
        var lastName = arguments.length >= 3 ? names.pop() : false;
        // parcours de l'arbo, création si besoin
        for (var i = 0; i < names.length; i++) {
            base = base[names[i]] = base[names[i]] || {};
        }
        //on affecte la valeur  
        if (lastName) base = base[lastName] = value;
        return base;
    }

    function getValueFromNestedObject(object, value) {
        //récupère la valeur demandée
        return value.split('.').reduce((acc, curr) => acc[curr], object);
    }

    function setYupToNestedObject(obj) {
        //Appel récursif, a check sur plusieurs niveaux
        for (var propertyName in obj) {
            //console.debug(propertyName);
            //console.debug(typeof (obj[propertyName]));
            if (!(obj[propertyName].conditions)) {
                setYupToNestedObject(obj[propertyName]);
                obj[propertyName] = yup.object(obj[propertyName]).notRequired();
            }

        }
    }


    var nestedObj = {};
    var schemaObject = {};
    if (genModel.templates) {
        genModel.templates.forEach((template) => {
            template.categories.forEach((category) => {
                category.headers.forEach((header) => {
                    header.groups.forEach((group, index) => {
                        //Gestion des clé primaires/secondaires
                        if (parentProps && parentProps.type && keyMode > 0 && index === 0) {
                            var bindProp = "";
                            switch (keyMode) {
                                case 1: bindProp = "ID" + commonFunc.capitalize(parentProps.type); break;
                                case 2: bindProp = parentProps.type + "ID"; break;
                                default: bindProp = "ID" + parentProps.type; break;
                            }
                            group.items.push({ hidden: true, bindPropResult: bindProp, defaultValue: parentProps.id, objType: "textview" });
                        }

                        group.items.forEach((item) => {//children
                            var type = item.propNameJsType || "string";
                            if (item.bindPropResult && yup[type] && typeof (yup[type]) === "function" && !item.bindPropResult.startsWith("{array}")) {//TEMP : test sur array en attendant solution pour les contacts de rapport
                                var elemYup = yup[type]();
                                if (item.propNameType) elemYup = tabRules[item.propNameType] || elemYup;
                                elemYup = elemYup.nullable();
                                if (item.attributes) {
                                    var errorMessage;
                                    if (item.attributes["typeName"] === "FormAttribute") errorMessage = altT(item.attributes["error"]);
                                    Object.keys(item.attributes).forEach((key, value) => {
                                        if (elemYup[key] && typeof (elemYup[key]) === "function") {
                                            try {
                                                if (errorMessage) {
                                                    if (keyArgs.indexOf(key) >= 0 && item.attributes[key]) {
                                                        elemYup = elemYup[key](item.attributes[key], errorMessage);
                                                    }
                                                    else if (key === "oneOf" && item.attributes[key]) {
                                                        elemYup = elemYup[key]([yup.ref(commonFunc.camelize(item.attributes[key], true))], errorMessage);
                                                    }
                                                    else if (item.attributes[key]) {
                                                        elemYup = elemYup.matches(item.attributes[key], { message: errorMessage, excludeEmptyString: (item.attributes["required"] ? false : true) });
                                                    }
                                                    else {
                                                        elemYup = elemYup[key](errorMessage);
                                                    }
                                                }
                                                else {
                                                    if (keyArgs.indexOf(key) >= 0 && item.attributes[key]) {
                                                        elemYup = elemYup[key](item.attributes[key]);
                                                    }
                                                    else if (key === "oneOf" && item.attributes[key]) {
                                                        elemYup = elemYup[key]([yup.ref(commonFunc.camelize(item.attributes[key], true))]);
                                                    }
                                                    else if (item.attributes[key]) {
                                                        elemYup = elemYup.matches(item.attributes[key], { excludeEmptyString: (item.attributes["required"] ? false : true) });
                                                    }
                                                    else {
                                                        elemYup = elemYup[key]();
                                                    }
                                                }
                                            }
                                            catch (e) {
                                                console.debug("Error manipulating yup attributes");
                                                console.error(e);
                                            }
                                        }
                                        else if (customYup[key] && typeof (customYup[key]) === "function") {
                                            elemYup = customYup[key](elemYup, item.attributes[key], (item.attributes["required"] ? false : true), errorMessage);
                                        } else {

                                            //console.debug("yup function type " + key + " Ignored");
                                        }
                                    });
                                }

                                elemYup = elemYup.typeError(t("validation." + type + ".notType"));

                                if (item.bindPropResult.indexOf(".") >= 0) {
                                    if (!item.isExcluded) {
                                        //On met de côté les objets sous-jacents pour traitement final (propriétés non consécutives)
                                        createNestedObject(nestedObj, commonFunc.camelize(item.bindPropResult, true).split("."), elemYup);
                                    }
                                } else {

                                    schemaObject[commonFunc.camelize(item.bindPropResult, true)] = elemYup;//.required()
                                }
                                /*if (item.bindPropResult === "Address.AddressMain" && item.bindPropResult.indexOf(".") >= 0) {
                                    schemaObject["address"] = {};
                                    schemaObject["address"]["addressMain"] = elemYup;
                                }*/


                                //localDataConfig.push({ label: "i18n:" + key + "Label", type: "input", target: key, className: "" });//TEMP
                                if (!item.baseConfig) item.baseConfig = {};
                                //localDataConfig.push({ title: "i18n:report_" + elem.propName + ".label", objType: "text", bindPropResult: elem.propName, defaultValue: elem.baseValue, subType: (inputTypes[elem.propNameJsType] || "text"), readOnly: elem.baseConfig.readOnly, hidden: elem.baseConfig.hidden, isInput: elem.baseConfig.isInput });//TEMP
                            } else {

                                //console.debug("yup base type " + type + " Ignored");
                            }
                        });
                    });
                });
            });
        });
    }
    //TEMP
    /*schemaObject.contacts = yup.array().of(yup.object().shape({
        name: yup.string().required().min(5),
        firstName: yup.string().required().min(5)
    }));*/

    //On genere le yup pour les objets sous-jacents
    setYupToNestedObject(nestedObj);
    Object.assign(schemaObject, nestedObj);

    //console.debug(nestedObj);
    //console.debug(schemaObject);
    //console.debug("....*****");

    const currSchema = yup.object(schemaObject).required();
    const { control, register, getValues, trigger, setValue, resetField, handleSubmit, reset, formState: { errors }, clearErrors } = useForm({ resolver: yupResolver(currSchema) });//{ resolver: yupResolver(currSchema), defaultValues: { ClientName: "toto" } }
    const formManager = {
        control: control,
        register: register,
        getValues: getValues,
        setValue: setValue,
        resetField: resetField,
        handleSubmit: handleSubmit,
        reset: reset,
        errors: errors
    };

    var cpt = 0;

    //console.debug("ALERTE RENDER !!");
    //console.debug(elementList);


    function isItemHidden({ props, data, triggerEvent }) {

        //TODO : return true;

        return false;
    }

    function filePhoto({ sysEvent, props, triggerEvent, data }) {
        var dataList = Object.keys(elementList).length > 0 ? elementList : curElementList;
        var dataElem = (props.data.bindPropResult ? dataList[commonFunc.camelize(props.data.bindPropResult, true)] : null);

        if (dataElem) {

            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }

            //Traitement des informations retourné par le Dialog
            var callbackModal = function (data) {
                if (data) {
                    dataElem.functionManager.setValue(data, true);
                }
                else {
                    modalRoot.unmount();
                }
            };

            modalRoot = createRoot(document.createElement('div'));
            modalRoot.render(<TakePhotoModal parentContext={this} title={"Prise de photo"} callback={callbackModal}></TakePhotoModal>);

        }
    }
    function fileDel({ sysEvent, props, triggerEvent, data }) {
        var dataList = Object.keys(elementList).length > 0 ? elementList : curElementList;
        var dataElem = (props.data.bindPropResult ? dataList[commonFunc.camelize(props.data.bindPropResult, true)] : null);

        if (dataElem) {

            dataElem.functionManager.setValue(null, true);
        }
    }


    function onActionRequired(tag, { id, trigger: actionKey, target, value, useCache, url, targetCache, min, max, operatorTest, altValue, targetValue, invalidToHide }, componentData, componentArgs) {
        var dataList = Object.keys(elementList).length > 0 ? elementList : curElementList;

        var responseAction = { isSuccess: false };
        console.debug("log onActionRequired " + actionKey);
        var targetData = !commonFunc.isBlank(target) ? dataList[commonFunc.camelize(target, true)] : (!commonFunc.isBlank(componentData.bindPropResult) ? dataList[commonFunc.camelize(componentData.bindPropResult, true)] : componentData);
        if (!targetData) targetData = componentData;
        //console.debug(target);
        //console.debug(componentData.bindPropResult);
        //console.debug(commonFunc.camelize(componentData.bindPropResult, true));
        //console.debug(Object.keys(dataList).length);
        //console.debug(targetData);
        return new Promise((resolve, reject) => {
            switch (actionKey) {

                //Priority

                case "toggleButtonVisibility":
                    var cFlagV = false;
                    var preventAction = false;

                    var valueTest;
                    switch (targetValue) {
                        case "modelDisabled": valueTest = genModel.disabled; break;
                        case "modelEdition": preventAction = genModel.disabled; valueTest = (isUpdateGen ? true : false); break;
                        default: valueTest = 0; break;
                    }

                    switch (operatorTest) {
                        case "=": cFlagV = (valueTest === value); break;
                        case "<": cFlagV = (valueTest < value); break;
                        case "<=": cFlagV = (valueTest <= value); break;
                        case ">": cFlagV = (valueTest > value); break;
                        case ">=": cFlagV = (valueTest >= value); break;
                        default:
                            break;
                    }
                    if (!preventAction) {
                        if (cFlagV) {
                            targetData.hidden = true;
                            //targetData.functionManager.rerender();
                        }
                        else {
                            targetData.hidden = false;
                            //targetData.functionManager.rerender();
                        }
                    }
                    break;
                case "showUsrBtn":
                    var valueTest2;
                    switch (targetValue) {
                        case "modelDisabled": valueTest2 = genModel.disabled; break;
                        case "modelEdition": /*preventAction = genModel.disabled;*/ valueTest2 = (isUpdateGen ? true : false); break;
                        default: valueTest2 = 0; break;
                    }

                    if (loadedData && valueTest2 === true) {
                        switch (value) {
                            case "infoCreateAccess":
                                targetData.hidden = loadedData.accountID > 0 || !commonFunc.isBlank(loadedData.email);
                                targetData.disabled = targetData.hidden;
                                break;
                            case "createAccess":
                                targetData.hidden = loadedData.accountID > 0 || commonFunc.isBlank(loadedData.email);
                                targetData.disabled = targetData.hidden;
                                break;
                            case "disableAccount":
                            case "generateAccess":
                            case "changePassword":
                            case "resetPassword":
                                targetData.hidden = loadedData.accountID <= 0;
                                targetData.disabled = targetData.hidden;
                                break;
                            default: break;
                        }
                    }
                    break;

                case "findFragment":
                    responseAction.isSuccess = true;
                    responseAction.result = targetData;
                    resolve(responseAction);
                    break;

                case "enableElement":
                case "disableElement":
                    var cNewValue5 = !(actionKey === "enableElement");

                    if (altValue === "currValue") {
                        if (componentArgs && componentArgs.value) {
                            altValue = componentArgs.value;
                        }
                        else {
                            //altValue = componentData.defaultValue;
                            altValue = getValues(commonFunc.camelize((componentData.isExcluded ? excludeFrag(componentData.bindPropResult, componentData.subType) : componentData.bindPropResult), true));
                        }
                    }

                    var cFlag5 = false;
                    switch (operatorTest) {
                        case "=": cFlag5 = (value === altValue); break;
                        case "!=": cFlag5 = (value !== altValue); break;
                        case "<": cFlag5 = (value < altValue); break;
                        case "<=": cFlag5 = (value <= altValue); break;
                        case ">": cFlag5 = (value > altValue); break;
                        case ">=": cFlag5 = (value >= altValue); break;
                        default:
                            break;
                    }

                    var orgDisabled = targetData.disabled;
                    if (cFlag5) {
                        targetData.disabled = cNewValue5;
                        if (orgDisabled !== targetData.disabled && targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();
                    }
                    else {
                        targetData.disabled = !cNewValue5;
                        if (orgDisabled !== targetData.disabled && targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();
                    }
                    break;

                case "showActionStatus":
                case "hideActionStatus":

                    //hideOnAction: [{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "!=", altValue: curStep, targetValue: (loadedData ? loadedData.statusID : 0) },
                    //{ id: status.idStatus, trigger: "showActionStatus", target: "", value: status.step, operatorTest: "=", altValue: (curStep <= 1 ? -1 : null), targetValue: (loadedData ? loadedData.statusID : 0) }]


                    //{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "=", altValue: curStep, targetValue: (loadedData ? loadedData.statusId : 0) }
                    var newValue = !(actionKey === "showActionStatus");
                    console.debug(targetValue);
                    if (id === targetValue) {
                        targetData.hidden = true;
                        //targetData.functionManager.rerender();
                    }
                    else {
                        var flag = false;
                        switch (operatorTest) {
                            case "=": flag = (value === altValue); break;
                            case "!=": flag = (value !== altValue); break;
                            case "<": flag = (value < altValue); break;
                            case "<=": flag = (value <= altValue); break;
                            case ">": flag = (value > altValue); break;
                            case ">=": flag = (value >= altValue); break;
                            default:
                                break;
                        }
                        if (flag) {
                            targetData.hidden = newValue;
                            //targetData.functionManager.rerender();
                        }
                    }
                    break;

                case "submitElem":
                    ref.current.submitForm(value === true);
                    break;
                case "refreshElem":
                    if (parentProps && parentProps.loadElem) {
                        parentProps.loadElem(parentProps.id).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'actualiser l'élément";
                        resolve(responseAction);
                    }
                    break;
                case "removeElem":
                    if (parentProps && parentProps.removeElem) {
                        parentProps.removeElem(parentProps.id).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'actualiser l'élément";
                        resolve(responseAction);
                    }
                    break;
                case "updateStatusElem":
                    if (parentProps && parentProps.updateStatusElem) {
                        var statusId;
                        if (value.startsWith && value.startsWith("prop:")) statusId = (dataList[commonFunc.camelize(value.replace("prop:", ""), true)] || {}).defaultValue;
                        else statusId = value;
                        var stepId;
                        var label = "";
                        if (componentArgs && !altValue) {
                            stepId = componentArgs.value;
                            label = componentArgs.label;
                        }
                        else {
                            if (altValue && altValue.startsWith && altValue.startsWith("prop:")) stepId = (dataList[commonFunc.camelize(altValue.replace("prop:", ""), true)] || {}).defaultValue;
                            else stepId = altValue;
                        }

                        parentProps.updateStatusElem(statusId, stepId, label).then((responseLoad) => {
                            if (!commonFunc.isBlank(stepId)) {
                                if (responseLoad.isSuccess) {
                                    onActionRequired(tag, { trigger: "refreshElem" }, componentData, componentArgs).then((responseLoad) => {
                                        responseAction = responseLoad;
                                        resolve(responseAction);
                                    });
                                }
                                else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: responseLoad.errorMessage
                                    }).then((result) => {
                                        onActionRequired(tag, { trigger: "refreshElem" }, componentData, componentArgs).then((responseLoad) => {
                                            responseAction = responseLoad;
                                            resolve(responseAction);
                                        });
                                    });
                                }
                            }
                            else {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "MAJ impossible";
                        resolve(responseAction);
                    }
                    break;
                case "duplicateFormElem":
                case "editFormElem":
                    if (parentProps && parentProps.setEditingMode) parentProps.setEditingMode(!formEditing, actionKey === "duplicateFormElem");
                    if (actionKey === "duplicateFormElem") {
                        if (targetData && targetData.functionManager && targetData.functionManager.setValue) targetData.functionManager.setValue(value);
                        Object.keys(dataList).forEach((key) => {
                            var dataElem = dataList[key];
                            if (dataElem && dataElem.hasOwnProperty("resetOnDuplicate") && dataElem.functionManager && dataElem.functionManager.setValue) dataElem.functionManager.setValue(dataElem.resetOnDuplicate, true);
                        });
                        forcedCreate = true;
                    }
                    break;


                case "getModel":
                    var copyConfig;
                    if (altValue) {
                        commonFunc.getMethod("../" + altValue, { id: -1 }).then((response) => {
                            var data = response.data;
                            if (data.isSuccess) {
                                if (data.result && data.result.models && data.result.models.length) {
                                    //var targetConfig = data.result.models[value];
                                    var targetConfig = data.result.models.filter(model => model.extID === value);
                                    copyConfig = targetConfig && targetConfig.length ? JSON.parse(targetConfig[0].value) : null;
                                }
                            }

                            if (copyConfig) {
                                responseAction.isSuccess = true;
                                responseAction.result = copyConfig;
                            }
                            else {
                                responseAction.isSuccess = false;
                                responseAction.errorMessage = "Aucun modèle disponible du type : " + value;
                                responseAction.result = "";
                            }
                            resolve(responseAction);
                        });
                        return;
                    }
                    else if (modelsList && Object.keys(modelsList).length) {
                        var targetConfig = modelsList[value];
                        copyConfig = targetConfig ? JSON.parse(JSON.stringify(targetConfig)) : null;
                    }
                    console.debug(copyConfig);
                    if (copyConfig) {
                        responseAction.isSuccess = true;
                        responseAction.result = copyConfig;
                    }
                    else {
                        responseAction.isSuccess = false;
                        responseAction.errorMessage = "Aucun modèle disponible du type : " + value;
                        responseAction.result = "";
                    }
                    resolve(responseAction);
                    break;
                case "dialogModel":

                    var callbackModal = function (dataElem, valueProc) {
                        return new Promise((resolve, reject) => {
                            if (dataElem && value) {

                                //dataElem correspond aux données du modal > voir si merge à faire
                                
                                if (!componentData.argumentsObj.externalUnmount) {
                                    if (modalRoot) {
                                        modalRoot.unmount();
                                        modalRoot = null;
                                    }
                                }
                                resolve();

                                //valueProc permet d'appeler un processus alternatif au submit paramètré
                                if (valueProc) {
                                    chainEvents(componentData, valueProc, dataElem);
                                }
                                else {
                                    chainEvents(componentData, value, dataElem);
                                }
                            }
                            else {
                                if (modalRoot) {
                                    modalRoot.unmount();
                                    modalRoot = null;
                                }
                                resolve();
                                if (parentProps.autoClose) ref.current.submitForm("cancel");
                            }
                        });
                    };

                    modelFctManager.onActionRequired(componentData.name, { trigger: "getModel", value: componentData.argumentsObj.dialogConfig, altValue: componentData.argumentsObj.urlDlgConfig }, componentData).then(actionResponse => {
                        if (actionResponse.isSuccess) {
                            var type = componentData.argumentsObj.dialogType;
                            var subType = componentData.argumentsObj.dialogSubType;
                            var genId = componentData.argumentsObj.dialogIDLabel;
                            var size = componentData.argumentsObj.dialogSize;

                            var currData = loadedData;

                            console.debug(loadedData);

                            /*if (systemItem["@@URL_GET@@"] && idElem) {
                                getData(idElem).then((responseData) => {
                                    if (responseData.isSuccess) {
                                        currData = responseData.result;

                                        modalRoot = createRoot(document.createElement('div'));
                                        modalRoot.render(<SubGenModal t={t} configModel={actionResponse.result} checkRights={modelFctManager.checkRights} getRoutes={modelFctManager.getRoutes} genRef={modalGenManager} genId={genId} genType={type} subType={subType} itemData={componentData} title={currData ? t(componentData.subType + "_updTitle") : t(componentData.subType + "_addTitle")} dataElem={currData} callback={callbackModal} />);
                                    }
                                });
                            }
                            else {*/
                                modalRoot = createRoot(document.createElement('div'));
                                modalRoot.render(<SubGenModal t={t} configModel={actionResponse.result} appProps={appProps} checkRights={modelFctManager.checkRights} dataCfg={parentProps.dataCfg} getRoutes={modelFctManager.getRoutes} genRef={modalGenManager} genId={genId} genType={type} subType={subType} itemData={componentData} size={size} title={currData ? t(subType + "_updTitle") : t(subType + "_addTitle")} dataElem={currData} callback={callbackModal} />);
                            //}
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: t("gal_error"),
                                html: actionResponse.errorMessage
                            });
                        }
                    });
                    break;
                case "closeDialog":
                    if (modalRoot) {
                        modalRoot.unmount();
                        modalRoot = null;
                    }
                    break;
                case "getGeneric":
                    if (url) {
                        var args = {};
                        if (componentArgs && Object.keys(componentArgs).length) {
                            Object.keys(componentArgs).forEach((key) => {
                                var valueArgs = componentArgs[key];
                                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                                else args[key] = valueArgs;
                            });
                        }
                        else if (componentData.argumentsObj && Object.keys(componentData.argumentsObj).length) {
                            Object.keys(componentData.argumentsObj).forEach((key) => {
                                var valueArgs = componentData.argumentsObj[key];
                                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                                else args[key] = valueArgs;
                            });
                        }

                        commonFunc.getMethod("../" + url, args).then((response) => {
                            //commonFunc.getMethod("../api/employee/GetWithContractAsKeyItems", args).then((response) => {
                            var data = response.data;
                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                /*var newOptions = data.result.map((employee) => {
                                    return { title: employee.value, value: employee.id };
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });*/
                                responseAction = data;
                                resolve(responseAction);

                                chainEvents(componentData, value, data.result);
                            }
                            else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = t("gal_invalidArgs");
                        resolve(responseAction);
                    }
                    break;
                case "postGeneric":
                    if (url) {
                        var args = {};
                        var showProcessing = { flag: false };
                        var shouldConfirmProcess = { flag: false };
                        var showResult = { flag: false };
                        if (componentArgs && Object.keys(componentArgs).length) {
                            Object.keys(componentArgs).forEach((key) => {
                                var valueArgs = componentArgs[key];
                                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                                else args[key] = valueArgs;
                            });
                            showProcessing = { title: componentArgs.processingTitle || "", html: componentArgs.processingHtml || "", flag: (componentArgs.showProcessing ? true : false) };
                            shouldConfirmProcess = { title: componentArgs.confirmTitle || "", html: componentArgs.confirmHtml || "", flag :(componentArgs.confirmProcess ? true : false) };
                            showResult = { title: componentArgs.resultTitle || "", html: componentArgs.resultHtml || "", flag: (componentArgs.showResult ? true : false), errorTitle: componentArgs.resultErrorTitle || "", errorHtml: componentArgs.resultErrorHtml || "" };
                        }
                        var genericProcess = function () {
                            if (showProcessing.flag) {
                                Swal.fire({
                                    title: altT(showProcessing.title, args) || t("gal_processing", args),
                                    html: altT(showProcessing.html, args) || "",
                                    didOpen: () => { Swal.showLoading() },
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                });
                            }


                            commonFunc.postMethod("../" + url, args).then((response) => {
                                //commonFunc.getMethod("../api/employee/GetWithContractAsKeyItems", args).then((response) => {
                                if (showProcessing.flag) Swal.close();

                                var data = response.data;
                                if (data.isSuccess) {

                                    /*var newOptions = data.result.map((employee) => {
                                        return { title: employee.value, value: employee.id };
                                    });
                                    targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                        responseAction = responseLoad;
                                        resolve(responseAction);
                                    });*/
                                    responseAction = data;
                                    resolve(responseAction);

                                    if (showResult.flag) {
                                        Swal.fire({
                                            icon: "success",
                                            title: altT(showResult.title, args) || t("gal_success", args),
                                            html: altT(showResult.html, args) || "",
                                            didOpen: () => { Swal.hideLoading() },
                                        });
                                    }

                                    chainEvents(componentData, value);
                                }
                                else {
                                    responseAction = data;
                                    responseAction.forceMessage = true;
                                    resolve(responseAction);

                                    if (showResult.flag) {
                                        Swal.fire({
                                            icon: "error",
                                            title: altT(showResult.errorTitle, args) || t("gal_error", args),
                                            html: (altT(showResult.errorHtml, args) || "") + ":<br/>" + response.data.errorMessage
                                        });
                                    }
                                }
                            });
                        };

                        if (shouldConfirmProcess.flag) {
                            Swal.fire({
                                icon: "question",
                                title: altT(shouldConfirmProcess.title, args) || t("gal_confirmTitle", args),
                                html: altT(shouldConfirmProcess.html, args) || t("gal_confirmAction", args),
                                showCancelButton: true,
                                confirmButtonColor: "#28a745",
                                cancelButtonColor: "#dc3545",
                                confirmButtonText: t("gal_yes"),
                                cancelButtonText: t("gal_no"),
                            }).then((result) => {
                                if (result.value) {
                                    genericProcess();
                                }
                            });
                        }
                        else {
                            genericProcess();
                        }


                    }
                    else {
                        responseAction.errorMessage = t("gal_invalidArgs");
                        resolve(responseAction);
                    }
                    break;



                case "loadActionStatus":
                    if (url) {
                        var asArgs = {};
                        if (componentArgs && Object.keys(componentArgs).length) {
                            Object.keys(componentArgs).forEach((key) => {
                                var valueArgs = componentArgs[key];
                                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) asArgs[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                                else asArgs[key] = valueArgs;
                            });
                        }

                        commonFunc.getMethod("../" + url, asArgs).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newItems = [];
                                var curStep = (loadedData ? data.result.filter((st) => st.idStatus === loadedData.statusID) : []);
                                if (curStep.length) curStep = curStep[0].step;
                                data.result.forEach((status) => {
                                    if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                        newItems.push({
                                            name: "ReportItem",
                                            title: "Passer en <" + status.name + ">",
                                            objType: "button",
                                            ignoreParentDisabled: true,
                                            classSup: status.css.replace(/badge/g, "btn") + " text-white",
                                            onActionEvents: { onClick: [{ trigger: "updateStatusElem", target: "", value: status.idStatus }] },
                                            hideOnAction: [{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "=", altValue: curStep, targetValue: (loadedData ? loadedData.statusID : 0) }]
                                        });
                                    }
                                });
                                targetData.items = newItems;
                                if (targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();

                                responseAction = data;
                                resolve(responseAction);

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = t("gal_invalidArgs");
                        resolve(responseAction);
                    }
                    break;
                case "loadStatus":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData && !commonFunc.isBlank(url)) {
                        commonFunc.getMethod("../" + url, null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((status) => {
                                    if (!status.isAutomatic && ((!max || status.step < parseInt(max)) || invalidToHide)) {
                                        newOptions.push({ customDesign: { design: { icon: "fa-circle" } }, title: status.name, value: status.idStatus, hidden: !(!max || status.step < parseInt(max)) });
                                    }
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;

                case "loadEmployees":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        var args = { period: "D" };
                        if (componentArgs) {
                            if (componentArgs.type === "date" || componentArgs.type === "dateonly" || componentArgs.type === "time") {
                                args.startdate = moment(componentArgs.value).format("YYYY-MM-DDTHH:mmZ");//TEMP : voir pour utc
                            }
                            else if (componentArgs.type) {
                                var valueSd = getValues(commonFunc.camelize("Itv.StartDate", true)) ? moment(getValues(commonFunc.camelize("Itv.StartDate", true))) : moment();
                                args.startdate = moment(valueSd).format("YYYY-MM-DDTHH:mmZ");//TEMP : voir pour utc
                            }
                        }
                        else {
                            args.startdate = moment().format("YYYY-MM-DDTHH:mmZ");
                        }

                        var flagNoContract = false;
                        var urlEmp = "api/report/GetReportEmployees";
                        var argsUrl = targetData.argumentsObj && targetData.argumentsObj.url ? targetData.argumentsObj : (componentData.argumentsObj && componentData.argumentsObj.url ? componentData.argumentsObj : null);
                        if (argsUrl && argsUrl.url && !argsUrl.immutableUrl) {
                            urlEmp = argsUrl.url;

                            //Check Config NoContract
                            if (appProps) {
                                var siteLE = appProps.getSite();
                                if (siteLE) {
                                    var siteExtras = siteLE.extras;
                                    if (!(siteExtras && siteExtras.length && siteExtras.filter(extra => extra.name === "withContract").length > 0)) {
                                        urlEmp = urlEmp.replace("employee/GetWithContractAsKeyItems", "employee/GetEmployees");
                                        urlEmp = urlEmp.replace("report/GetReportEmployees", "employee/GetEmployees");
                                        flagNoContract = true;
                                    }
                                }
                            }
                        }

                        commonFunc.getMethod("../" + urlEmp, args).then((response) => {
                            //commonFunc.getMethod("../api/employee/GetWithContractAsKeyItems", args).then((response) => {
                            var data = response.data;
                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }
                                var employeesAlt = data.result;
                                if (flagNoContract) {
                                    employeesAlt = employeesAlt.map(emp => { return { id: emp.idEmployee, value: emp.name + " " + emp.firstName} })
                                }

                                var newOptions = employeesAlt.map((employee) => {
                                    return { title: employee.value, value: employee.id };
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });
                            }
                            else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadServices":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {

                        var urlSvc = "api/report/GetReportServices";
                        if (componentData.argumentsObj && componentData.argumentsObj.url) {
                            urlSvc = componentData.argumentsObj.url;
                        }

                        var filterContract = (componentData.argumentsObj && componentData.argumentsObj.onlyContract);
                        var filterNoContract = (componentData.argumentsObj && componentData.argumentsObj.noContract);

                        var args = null;
                        commonFunc.getMethod("../" + urlSvc, args).then((response) => {
                            var data = response.data;
                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var servicesFiltered = data.result;
                                if (filterContract) servicesFiltered = servicesFiltered.filter(svc => svc.isContract);
                                else if (filterNoContract) servicesFiltered = servicesFiltered.filter(svc => !svc.isContract);

                                var newOptions = servicesFiltered.map((service) => {//TODO : iscontract
                                    return { title: service.name, value: service.idService, srcData: service };
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadClients":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        var args = { period: "D" };
                        if (componentArgs) {
                            if (componentArgs.type === "date" || componentArgs.type === "dateonly" || componentArgs.type === "time") {
                                args.startdate = moment(componentArgs.value).format("YYYY-MM-DDTHH:mmZ");//TEMP : voir pour utc
                            }
                            else if (componentArgs.type) {
                                var valueSd = getValues(commonFunc.camelize("StartDate", true)) ? moment(getValues(commonFunc.camelize("StartDate", true))) : moment();
                                args.startdate = moment(valueSd).format("YYYY-MM-DDTHH:mmZ");//TEMP : voir pour utc
                            }
                        }
                        else {
                            args.startdate = moment().format("YYYY-MM-DDTHH:mmZ");
                        }

                        var flagNoContractLC = false;
                        var urlCli = "../api/client/GetWithAgreementAsKeyItems";
                        //Check Config NoContract
                        if (appProps && (componentData.argumentsObj == null || !componentData.argumentsObj.immutableUrl)) {
                            var siteLC = appProps.getSite();
                            if (siteLC) {
                                var siteExtrasLC = siteLC.extras;
                                if (!(siteExtrasLC && siteExtrasLC.length && siteExtrasLC.filter(extra => extra.name === "withContract").length > 0)) {
                                    urlCli = urlCli.replace("client/GetWithAgreementAsKeyItems", "client/GetClients");
                                    flagNoContractLC = true;
                                }
                            }
                        }

                        commonFunc.getMethod(urlCli, args).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }
                                var clientsAlt = data.result;
                                if (flagNoContractLC) {
                                    clientsAlt = clientsAlt.map(emp => { return { id: emp.idClient, value: emp.name + " " + emp.firstName, address: emp.address, billingAddress: emp.billingAddress } })
                                }

                                var newOptions = clientsAlt.filter(cli => cli.id > 0).map((client) => {
                                    return { title: client.value, value: client.id, srcData: client };
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadSites":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        var args = null;
                        commonFunc.getMethod("../api/Data/GetAlternativeSites", args).then((response) => {
                            var data = response.data;
                            
                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = data.result.map((objSite) => {
                                    return { title: objSite.localSite.name, value: objSite.localSite.idSite };
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadReportTemplates":
                    if (dataCache[targetCache] && targetData && targetData.functionManager && targetData.functionManager.populateData) {

                        var service = dataCache[targetCache].filter((svc) => {
                            return svc.idService === parseInt(componentArgs.value);
                        });

                        if (service.length && service[0].templates) {
                            var newOptions = [];
                            service[0].templates.forEach((template) => {
                                if (template.isActive && ((!altValue && !template.isHtml) || (altValue && template.isHtml))) {
                                    newOptions.push({ title: template.name, value: template.idTemplate });
                                }
                            });
                            if (newOptions.length === 0) newOptions.push({ title: "Aucun", value: -1 });
                            targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        }
                        else {
                            responseAction.errorMessage = "Impossible de charger les données : Aucun modèle";
                            resolve(responseAction);
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;

                case "loadReportGroupTemplates":
                    if (dataCache[targetCache] && targetData && targetData.functionManager && targetData.functionManager.populateData) {

                        var service = dataCache[targetCache].filter((svc) => {
                            return svc.idService === parseInt(componentArgs.value);
                        });

                        if (service.length && service[0].templates) {
                            var newOptionsRGT = [];
                            var selectedValue;

                            var extDef = service[0].templates.reduce((extList, template) => {
                                if (template.isActive && ((!altValue && !template.isHtml) || (altValue && template.isHtml))) {
                                    (extList[template.extID] = extList[template.extID] || []).push(template);
                                }
                                return extList;
                            }, {});

                            //Alimentation de la liste de template
                            Object.keys(extDef).forEach((key) => {
                                var templates = extDef[key];
                                var title = key;
                                if (templates && templates.length) {
                                    title = templates[0].name.replace(" - Mobile", "");
                                    if (loadedData && loadedData.appTemplateID && templates.filter(template => template.idTemplate === loadedData.appTemplateID).length) {
                                        selectedValue = key;
                                    }
                                }
                                newOptionsRGT.push({ title: title, value: key });
                            });
                            //Alimentation de la liste de template (valeur rapport)
                            if (!selectedValue && loadedData && loadedData.appTemplate) {
                                newOptionsRGT.push({ title: loadedData.appTemplate.name.replace(" - Mobile", ""), value: loadedData.appTemplate.extID });
                                selectedValue = loadedData.appTemplate.extID;
                            }


                            if (newOptionsRGT.length === 0) newOptionsRGT.push({ title: "Aucun", value: -1 });
                            targetData.functionManager.populateData(newOptionsRGT, true).then((responseLoad) => {
                                if (selectedValue) {
                                    targetData.functionManager.setValue(selectedValue).then((responseLoad) => {
                                        targetData.functionManager.triggerChange(selectedValue);
                                        responseAction = responseLoad;
                                        resolve(responseAction);
                                    });
                                }
                                else {
                                    targetData.functionManager.triggerChange();
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                }
                            });
                        }
                        else {
                            responseAction.errorMessage = "Impossible de charger les données : Aucun modèle";
                            resolve(responseAction);
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;

                case "setTemplateValue":
                    if (dataCache[targetCache] && targetData && targetData.functionManager && targetData.functionManager.setValue) {

                        var currServiceComp = dataList[commonFunc.camelize("BenefitID", true)] || dataList[commonFunc.camelize("Itv.ServiceID", true)];
                        var currServiceID = parseInt(currServiceComp.functionManager.getValue()) || parseInt(getValues(commonFunc.camelize("BenefitID", true))) || parseInt(currServiceComp.defaultValue);
                        var serviceSV = dataCache[targetCache].filter((svc) => {
                            return svc.idService === parseInt(currServiceID);
                        });

                        if (currServiceID && serviceSV.length && serviceSV[0].templates) {
                            var newOptions = [];

                            var groupValue = componentArgs.value || componentData.functionManager.getValue() || getValues(commonFunc.camelize(componentData.bindPropResult, true)) || componentData.defaultValue;
                            var templateFiltered = serviceSV[0].templates.filter(template => template.extID === groupValue && (altValue ? template.isHtml : !template.isHtml));

                            if (templateFiltered.length) {
                                targetData.functionManager.setValue(templateFiltered[0].idTemplate).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });
                            }
                        }
                        else {
                            //responseAction.errorMessage = "Impossible de charger les données : Aucun modèle";
                            //resolve(responseAction);
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;


                case "loadReportStatus":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/report/GetStatus", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((status) => {
                                    if (!status.isAutomatic && (!min || status.step > parseInt(min)) && (!max || status.step < parseInt(max))) {
                                        newOptions.push({ customDesign: { design: { icon: "fa-circle" } }, title: status.name, value: status.idStatus, srcData: status });
                                    }
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadClientStatus":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/client/GetStatus", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((status) => {
                                    if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                        newOptions.push({ customDesign: { design: { icon: "fa-circle" } }, title: status.name, value: status.idStatus });
                                    }
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadEmployeeStatus":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/employee/GetStatus", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((status) => {
                                    if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                        newOptions.push({ customDesign: { design: { icon: "fa-circle" } }, title: status.name, value: status.idStatus });
                                    }
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;

                case "loadClientCategories":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/client/GetClientCategories", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((category) => {
                                    newOptions.push({ title: category.name, value: category.idCateg });
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadEmployeeCategories":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/employee/GetEmployeeCategories", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((category) => {
                                    newOptions.push({ title: category.name, value: category.idCateg });
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadLeadCategories":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/lead/GetLeadCategories", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((category) => {
                                    newOptions.push({ title: category.name, value: category.idCateg });
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadMaterialCategories":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/material/GetMaterialCategories", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((category) => {
                                    newOptions.push({ title: category.name, value: category.idCateg });
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;

                case "loadEmployeeGroups":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        commonFunc.getMethod("../api/employee/GetEmployeeGroups", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var newOptions = [];
                                data.result.forEach((group) => {
                                    var flagEG = true;
                                    if (operatorTest) {
                                        flagEG = false;
                                        switch (operatorTest) {
                                            case "=": flagEG = (group.idSegment === altValue); break;
                                            case "!=": flagEG = (group.idSegment !== altValue); break;
                                            case "<": flagEG = (group.idSegment < altValue); break;
                                            case "<=": flagEG = (group.idSegment <= altValue); break;
                                            case ">": flagEG = (group.idSegment > altValue); break;
                                            case ">=": flagEG = (group.idSegment >= altValue); break;
                                            default:
                                                break;
                                        }
                                    }
                                    if (flagEG) newOptions.push({ title: group.title, value: group.idSegment });
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;

                case "loadElementGroups":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData && componentData.argumentsObj) {
                        commonFunc.getMethod("../api/" + value + "/Get" + value + "Groups", null).then((response) => {
                            var data = response.data;

                            if (data.isSuccess) {
                                if (useCache) {
                                    dataCache[targetCache] = data.result;
                                }

                                var idTarget = componentData.argumentsObj.loadIdTarget;
                                var nameTarget = componentData.argumentsObj.loadNameTarget;


                                var newOptions = [];
                                data.result.forEach((group) => {
                                    var flagEG2 = true;
                                    if (operatorTest) {
                                        flagEG2 = false;
                                        switch (operatorTest) {
                                            case "=": flagEG2 = (group[idTarget] === altValue); break;
                                            case "!=": flagEG2 = (group[idTarget] !== altValue); break;
                                            case "<": flagEG2 = (group[idTarget] < altValue); break;
                                            case "<=": flagEG2 = (group[idTarget] <= altValue); break;
                                            case ">": flagEG2 = (group[idTarget] > altValue); break;
                                            case ">=": flagEG2 = (group[idTarget] >= altValue); break;
                                            default:
                                                break;
                                        }
                                    }
                                    if (flagEG2) newOptions.push({ title: group[nameTarget], value: group[idTarget] });
                                });
                                targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });

                            } else {
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;

                case "populateData":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData && componentData.argumentsObj) {

                        var populateProc = (data) => {
                            var idTarget = componentData.argumentsObj.loadIdTarget;
                            var titleTarget = componentData.argumentsObj.loadTitleTarget;


                            var newOptions = [];
                            data.forEach((group) => {
                                var flagEG2 = true;
                                if (operatorTest) {
                                    flagEG2 = false;
                                    switch (operatorTest) {
                                        case "=": flagEG2 = (group[idTarget] === targetValue); break;
                                        case "!=": flagEG2 = (group[idTarget] !== targetValue); break;
                                        case "<": flagEG2 = (group[idTarget] < targetValue); break;
                                        case "<=": flagEG2 = (group[idTarget] <= targetValue); break;
                                        case ">": flagEG2 = (group[idTarget] > targetValue); break;
                                        case ">=": flagEG2 = (group[idTarget] >= targetValue); break;
                                        default:
                                            break;
                                    }
                                }
                                if (flagEG2) newOptions.push({ title: group[titleTarget], value: group[idTarget] });
                            });
                            targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        };


                        if (componentData.argumentsObj.url) {
                            commonFunc.getMethod("../" + componentData.argumentsObj.url, null).then((response) => {
                                var data = response.data;

                                if (data.isSuccess) {
                                    if (useCache) {
                                        dataCache[targetCache] = data.result;
                                    }

                                    populateProc(data.result);

                                } else {
                                    responseAction = data;
                                    resolve(responseAction);
                                }
                            });
                        }
                        else {
                            populateProc(altValue);
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;



                case "loadCountry":
                    var countries = [];
                    var newOptions = [];
                    countries.forEach((country) => {
                        if (country.isActive) {
                            newOptions.push({ title: country.name, value: country.id });
                        }
                    });
                    if (newOptions.length === 0) newOptions.push({ title: "France", value: "FRANCE" });
                    targetData.functionManager.populateData(newOptions, true).then((responseLoad) => {
                        responseAction = responseLoad;
                        resolve(responseAction);
                    });
                    break;


                case "rerender":
                    if (targetData && targetData.functionManager && targetData.functionManager.rerender) {
                        targetData.functionManager.rerender();

                        responseAction.isSuccess = true;
                        responseAction.result = true;
                        resolve(responseAction);
                    }
                    break;
                case "updValue":
                    if (targetData && targetData.functionManager && (targetData.functionManager.triggerChange || targetData.functionManager.setValue) && componentArgs) {
                        var cFlag3 = true;
                        if (operatorTest) {
                            cFlag3 = false;
                            switch (operatorTest) {
                                case "=": cFlag3 = (value === componentArgs.value); break;
                                case "!=": cFlag3 = (value !== componentArgs.value); break;
                                case "<": cFlag3 = (value < componentArgs.value); break;
                                case "<=": cFlag3 = (value <= componentArgs.value); break;
                                case ">": cFlag3 = (value > componentArgs.value); break;
                                case ">=": cFlag3 = (value >= componentArgs.value); break;
                                default:
                                    break;
                            }
                        }

                        var cNewValue3 = getValues(commonFunc.camelize(targetData.bindPropResult, true));
                        if (cFlag3) {
                            if (targetData.functionManager.triggerChange) {
                                targetData.functionManager.triggerChange().then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });
                            }
                            else {
                                targetData.functionManager.setValue(cNewValue3).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });
                            }
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "setValue":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue && componentArgs) {
                        var cNewValue = componentArgs.value;
                        if (altValue !== null && altValue !== undefined) cNewValue = altValue

                        var cFlag = true;
                        if (operatorTest) {
                            cFlag = false;
                            switch (operatorTest) {
                                case "=": cFlag = (value === componentArgs.value); break;
                                case "!=": cFlag = (value !== componentArgs.value); break;
                                case "<": cFlag = (value < componentArgs.value); break;
                                case "<=": cFlag = (value <= componentArgs.value); break;
                                case ">": cFlag = (value > componentArgs.value); break;
                                case ">=": cFlag = (value >= componentArgs.value); break;
                                default:
                                    break;
                            }
                        }
                        if (cFlag) {
                            targetData.functionManager.setValue(cNewValue).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "setValueFromSrcData":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue && componentArgs && componentArgs.srcData) {
                        targetData.functionManager.setValue(componentArgs.srcData[value]).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "setStatsValue":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue && componentArgs && value) {
                        var targetRow = altValue.filter((dataRow) => dataRow.tag === value);
                        if (targetRow.length) {
                            var statsValue = targetRow[0].amount;
                            console.debug("TOTO " + value);
                            console.debug(statsValue);
                            targetData.functionManager.setValue(statsValue, true).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        }
                        else {
                            //responseAction.errorMessage = "Champ introuvable";
                            //resolve(responseAction);
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "setDateonly":
                    console.debug("ok");
                    //elementList[target].defaultValue = new Date();
                    //elementList[element.data.bindPropResult].defaultValue = new Date("2001-05-05");
                    if (targetData && targetData.functionManager && targetData.functionManager.setDateonly && componentArgs) {
                        targetData.functionManager.setDateonly(componentArgs.value).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    //setValue(commonFunc.camelize(target, true), new Date());
                    break;
                case "updateGetListArgs":

                    //TODO : CHECK VALIDATE
                    trigger("veilleCustomFilter").then(result => {
                        console.debug(result);
                        console.debug(errors);
                    });
                    console.debug(errors);

                    if (targetData && targetData.functionManager && targetData.functionManager.getGetListCpt) {
                        var selValue = componentArgs ? componentArgs.value : "";
                        var isBoolean = false;
                        if (typeof selValue == "boolean") {
                            selValue = value ? (selValue ? value : "") : selValue;
                            isBoolean = true;
                        }
                        var getlistItem = targetData.functionManager.getGetListCpt();
                        if (getlistItem && getlistItem.argumentsObj && altValue) {
                            if (Array.isArray(getlistItem.argumentsObj[altValue])) {
                                if (isBoolean) {
                                    if (selValue) {
                                        getlistItem.argumentsObj[altValue].push({ id: 0, value: selValue, tag: "" });
                                    }
                                    else {
                                        /*var index = getlistItem.argumentsObj[altValue].indexOf(value);
                                        if (index >= 0) {
                                            getlistItem.argumentsObj[altValue].splice(index, 1);
                                        }*/
                                        getlistItem.argumentsObj[altValue] = getlistItem.argumentsObj[altValue].filter(keyItem => keyItem.value !== value);
                                    }
                                }
                                else {
                                    getlistItem.argumentsObj[altValue] = getlistItem.argumentsObj[altValue].filter(keyItem => keyItem.tag !== "S");
                                    getlistItem.argumentsObj[altValue].push({ id: 0, value: selValue, tag: "S" });
                                }
                            }
                            else {
                                getlistItem.argumentsObj[altValue] = selValue;
                            }
                        }
                        if (targetValue && targetData.functionManager.refreshList) {
                            targetData.functionManager.refreshList();
                        }
                    }
                    break;
                case "updateAgreementsFilter":

                    /*App.getUser((user) => {
                        if (user.isSuccess) {*/
                            var user = getUser();//primaryGroupId
                            if (user.primaryGroupId === 2) {
                                commonFunc.getMethod("../api/client/GetClient", { id: user.matchID, level: 2 }).then((response) => {
                                    var data = response.data;
                                    if (data.isSuccess) {
                                        console.debug(data);

                                        var agreementSubscriptions = "";//"QSA;ENV;SST;NAL;SPE;INF";//TEMP
                                        if (data.result.agreements && data.result.agreements.length) {
                                            agreementSubscriptions = data.result.agreements[0].comments;
                                        }

                                        var splitSubs = agreementSubscriptions.split(";");
                                        splitSubs.forEach((e, i) => {
                                            onActionRequired(tag, { trigger: "showElement", target: (target + e + "Filter"), operatorTest: "=", value: 0, altValue: 0 }, componentData, componentArgs).then((responseLoad) => {
                                                //responseLoad
                                            });
                                        });
                                        responseAction.isSuccess = true;
                                        responseAction.result = true;
                                    }
                                    else {
                                        responseAction.isSuccess = false;
                                        responseAction.errorMessage = "Echec de récupération des informations client : " + value;
                                        responseAction.result = "";
                                    }
                                    resolve(responseAction);
                                });
                            }
                            else {
                                var agreementSubscriptions = "QSA;ENV;SST;NAL;SPE;INF";//TEMP
                                var splitSubs = agreementSubscriptions.split(";");
                                splitSubs.forEach((e, i) => {
                                    onActionRequired(tag, { trigger: "showElement", target: (target + e + "Filter"), operatorTest: "=", value: 0, altValue: 0 }, componentData, componentArgs).then((responseLoad) => {
                                        //responseLoad
                                    });
                                });

                                responseAction.isSuccess = true;
                                responseAction.result = true;
                                resolve(responseAction);
                            }
                        /*}
                        else {
                            responseAction.isSuccess = false;
                            responseAction.errorMessage = "Impossible de récupérer le user";
                            responseAction.result = "";
                            resolve(responseAction);
                        }
                    })*/





                    break;
                case "moveEndDate":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue) {
                        var startDateComponent = !commonFunc.isBlank(value) ? dataList[commonFunc.camelize(value, true)] : componentData;
                        var lengthComponent = !commonFunc.isBlank(altValue) ? dataList[commonFunc.camelize(altValue, true)] : null;

                        var startDate = getValues(commonFunc.camelize(startDateComponent.bindPropResult, true));
                        var length = getValues(commonFunc.camelize(lengthComponent.bindPropResult, true));

                        var endDate = commonFunc.addMinutes(startDate, length * 60);
                        var formatEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");

                        targetData.functionManager.setValue(formatEndDate).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "calcEndHour":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue) {
                        var startDateComponent2 = !commonFunc.isBlank(value) ? dataList[commonFunc.camelize(value, true)] : componentData;
                        var lengthComponent2 = !commonFunc.isBlank(altValue) ? dataList[commonFunc.camelize(altValue, true)] : null;

                        //var startDate = getValues(commonFunc.camelize(startDateComponent2.bindPropResult, true));
                        var startDate2 = startDateComponent2.rawValue;
                        var length2 = getValues(commonFunc.camelize(lengthComponent2.bindPropResult, true));

                        if (startDate2 && (length2 || length2 === 0)) {
                            var endDate2 = commonFunc.addMinutes(startDate2, length2 * 60);
                            //var formatEndDate2 = moment(endDate2).format("YYYY-MM-DDTHH:mm:ss");

                            targetData.functionManager.setValue(endDate2).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "loadConcatValue":
                    var currTargetValue = getValues(commonFunc.camelize(targetData.bindPropResult, true)) || 0;

                    if (loadedData && loadedData[commonFunc.camelize(targetData.bindPropResult, true)]) currTargetValue = loadedData[commonFunc.camelize(targetData.bindPropResult, true)];

                    var childsList = [];
                    componentData.children.forEach((child) => {
                        if (child.onActionEvents && child.onActionEvents.onChange) {
                            var concatChild = child.onActionEvents.onChange.filter((event) => event.trigger === "concatValue");
                            if (concatChild.length) {
                                concatChild[0].bindPropResult = child.bindPropResult;
                                childsList.push(concatChild[0]);
                            }
                        }
                    });
                    if (childsList.length) {
                        childsList.sort((a, b) => b.value - a.value);

                        var localValue = currTargetValue;
                        childsList.forEach((child) => {
                            var modValue = (localValue % child.value) || 0;
                            if (modValue !== localValue) {
                                localValue = modValue;
                                var childTarget = dataList[commonFunc.camelize(child.bindPropResult, true)];
                                if (childTarget && childTarget.functionManager) {
                                    childTarget.functionManager.setValue(true).then((responseLoad) => {

                                        //Load OK
                                    });
                                }
                                else {
                                    childTarget.defaultValue = true;
                                }
                            }
                        });
                    }
                    break;
                case "updConcatValue":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue) {

                        var concatValue = 0;
                        componentData.children.forEach((child) => {
                            if (child.onActionEvents && child.onActionEvents.onChange) {
                                var concatChild = child.onActionEvents.onChange.filter((event) => event.trigger === "concatValue");
                                if (concatChild.length) {
                                    var childValue = getValues(commonFunc.camelize(child.bindPropResult, true)) || 0;
                                    if (childValue) {
                                        concatValue += concatChild[0].value;
                                    }
                                }
                            }
                        });
                        targetData.functionManager.setValue(concatValue).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "concatValue":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue) {

                        var currTargetValue2 = getValues(commonFunc.camelize(targetData.bindPropResult, true)) || 0;
                        if (componentArgs.value === true) {
                            currTargetValue2 += value;
                        }
                        else {
                            currTargetValue2 -= value;
                        }
                        targetData.functionManager.setValue(currTargetValue2).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;
                case "clearModal":
                    if (targetData && targetData.functionManager && targetData.functionManager.clearModal) {
                        targetData.functionManager.clearModal().then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'effectuer cette action";
                        resolve(responseAction);
                    }
                    break;

                case "manageRadioChild":
                    if (targetData) {
                        var cBind = commonFunc.camelize(targetData.bindPropResult, true);
                        //var cCurrValue = componentData.functionManager.getValue();
                        var cCurrValueR = componentArgs.value;
                        if (cCurrValueR === parseInt(value)) {
                            var cTargetValue = targetData.functionManager.getValue();
                            setValue(cBind, cTargetValue);
                        }
                        else {
                            setValue(cBind, 0);
                        }
                    }
                    break;

                case "manageClientID":
                    if (targetData) {
                        var cSubTargetName = dataList[commonFunc.camelize("Client.Name", true)];
                        var cSubTargetFN = dataList[commonFunc.camelize("Client.FirstName", true)];

                        var cBind = commonFunc.camelize(targetData.bindPropResult, true);
                        //var cCurrValue = componentData.functionManager.getValue();
                        var cCurrValue = componentArgs.value;
                        console.debug(cCurrValue);
                        console.debug(value);
                        if (cCurrValue === parseInt(value)) {
                            var cTargetValue = targetData.functionManager.getValue();
                            var selectedOption = targetData.functionManager.getSelectedOption ? targetData.functionManager.getSelectedOption(cTargetValue) : null;
                            setValue(cBind, cTargetValue);
                            if (cSubTargetName) {
                                cSubTargetName.hidden = true;
                                var name = selectedOption ? selectedOption.title.split(" ")[0] : "none";
                                setValue(commonFunc.camelize("Client.Name", true), !commonFunc.isBlank(name) ? name : "none");
                                cSubTargetName.functionManager.rerender();
                            }
                            if (cSubTargetFN) {
                                cSubTargetFN.hidden = true;
                                var fName = selectedOption ? selectedOption.title.split(" ")[1] : "none";
                                setValue(commonFunc.camelize("Client.FirstName", true), !commonFunc.isBlank(fName) ? fName : "none");
                                cSubTargetFN.functionManager.rerender();
                            }

                            if (selectedOption && selectedOption.srcData && selectedOption.srcData.address) {//Il manque des informations pour les charger dans adresse (ex actuellement : location = LAVAL 53)
                                setValue(commonFunc.camelize("Address.AddressMain", true), selectedOption.srcData.address.addressMain);
                                setValue(commonFunc.camelize("Address.AddressPlus", true), selectedOption.srcData.address.addressPlus);
                                setValue(commonFunc.camelize("Address.AddressPlus2", true), selectedOption.srcData.address.addressPlus2);
                                setValue(commonFunc.camelize("Address.Country", true), selectedOption.srcData.address.country);
                                setValue(commonFunc.camelize("Address.PC", true), selectedOption.srcData.address.pc);
                                setValue(commonFunc.camelize("Address.City", true), selectedOption.srcData.address.city);

                                setValue(commonFunc.camelize("BillAddress.AddressMain", true), selectedOption.srcData.billingAddress.addressMain || selectedOption.srcData.address.addressMain);
                                setValue(commonFunc.camelize("BillAddress.AddressPlus", true), selectedOption.srcData.billingAddress.addressPlus || selectedOption.srcData.address.addressPlus);
                                setValue(commonFunc.camelize("BillAddress.AddressPlus2", true), selectedOption.srcData.billingAddress.addressPlus2 || selectedOption.srcData.address.addressPlus2);
                                setValue(commonFunc.camelize("BillAddress.Country", true), selectedOption.srcData.billingAddress.country || selectedOption.srcData.address.country);
                                setValue(commonFunc.camelize("BillAddress.PC", true), selectedOption.srcData.billingAddress.pc || selectedOption.srcData.address.pc);
                                setValue(commonFunc.camelize("BillAddress.City", true), selectedOption.srcData.billingAddress.city || selectedOption.srcData.address.city);

                                dataList[commonFunc.camelize("Address.AddressMain", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("Address.AddressPlus", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("Address.AddressPlus2", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("Address.Country", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("Address.PC", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("Address.City", true)]?.functionManager.rerender();

                                dataList[commonFunc.camelize("BillAddress.AddressMain", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("BillAddress.AddressPlus", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("BillAddress.AddressPlus2", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("BillAddress.Country", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("BillAddress.PC", true)]?.functionManager.rerender();
                                dataList[commonFunc.camelize("BillAddress.City", true)]?.functionManager.rerender();
                            }
                        }
                        else {
                            setValue(cBind, 0);
                            if (cSubTargetName) {
                                cSubTargetName.hidden = false;
                                setValue(commonFunc.camelize("Client.Name", true), "");
                                cSubTargetName.functionManager.rerender();
                            }
                            if (cSubTargetFN) {
                                cSubTargetFN.hidden = false;
                                setValue(commonFunc.camelize("Client.FirstName", true), "");
                                cSubTargetFN.functionManager.rerender();
                            }
                        }
                    }
                    break;
                case "updateClientID":
                    if (targetData) {
                        var cSubTargetName2 = dataList[commonFunc.camelize("Client.Name", true)];
                        var cSubTargetFN2 = dataList[commonFunc.camelize("Client.FirstName", true)];

                        var cCurrValue2 = componentArgs.value || targetData.functionManager.getValue();
                        var selectedOption2 = targetData.functionManager.getSelectedOption ? targetData.functionManager.getSelectedOption(cCurrValue2) : null;
                        if (cSubTargetName2 && cSubTargetFN2.hidden) {
                            var name2 = selectedOption2 ? selectedOption2.title.split(" ")[0] : "";
                            setValue(commonFunc.camelize("Client.Name", true), name2);
                        }
                        if (cSubTargetFN2 && cSubTargetFN2.hidden) {
                            var fName2 = selectedOption2 ? selectedOption2.title.split(" ")[1] : "";
                            setValue(commonFunc.camelize("Client.FirstName", true), !commonFunc.isBlank(fName2) ? fName2 : "none");//Foireux
                        }


                        if (selectedOption2 && selectedOption2.srcData && selectedOption2.srcData.address) {//Il manque des informations pour les charger dans adresse (ex actuellement : location = LAVAL 53)
                            setValue(commonFunc.camelize("Address.AddressMain", true), selectedOption2.srcData.address.addressMain);
                            setValue(commonFunc.camelize("Address.AddressPlus", true), selectedOption2.srcData.address.addressPlus);
                            setValue(commonFunc.camelize("Address.AddressPlus2", true), selectedOption2.srcData.address.addressPlus2);
                            setValue(commonFunc.camelize("Address.Country", true), selectedOption2.srcData.address.country);
                            setValue(commonFunc.camelize("Address.PC", true), selectedOption2.srcData.address.pc);
                            setValue(commonFunc.camelize("Address.City", true), selectedOption2.srcData.address.city);

                            setValue(commonFunc.camelize("BillAddress.AddressMain", true), selectedOption2.srcData.billingAddress.addressMain || selectedOption2.srcData.address.addressMain);
                            setValue(commonFunc.camelize("BillAddress.AddressPlus", true), selectedOption2.srcData.billingAddress.addressPlus || selectedOption2.srcData.address.addressPlus);
                            setValue(commonFunc.camelize("BillAddress.AddressPlus2", true), selectedOption2.srcData.billingAddress.addressPlus2 || selectedOption2.srcData.address.addressPlus2);
                            setValue(commonFunc.camelize("BillAddress.Country", true), selectedOption2.srcData.billingAddress.country || selectedOption2.srcData.address.country);
                            setValue(commonFunc.camelize("BillAddress.PC", true), selectedOption2.srcData.billingAddress.pc || selectedOption2.srcData.address.pc);
                            setValue(commonFunc.camelize("BillAddress.City", true), selectedOption2.srcData.billingAddress.city || selectedOption2.srcData.address.city);

                            dataList[commonFunc.camelize("Address.AddressMain", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("Address.AddressPlus", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("Address.AddressPlus2", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("Address.Country", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("Address.PC", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("Address.City", true)]?.functionManager.rerender();

                            dataList[commonFunc.camelize("BillAddress.AddressMain", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("BillAddress.AddressPlus", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("BillAddress.AddressPlus2", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("BillAddress.Country", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("BillAddress.PC", true)]?.functionManager.rerender();
                            dataList[commonFunc.camelize("BillAddress.City", true)]?.functionManager.rerender();
                        }
                    }
                    break;


                case "loadInsurances":
                    //TODO : charger via la table de partenaires (Providers)
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        if (parentProps.dataCfg && parentProps.dataCfg.customList) {
                            var insurancesList = parentProps.dataCfg.customList.filter((item) => item.Type === 1);
                            if (insurancesList.length) {


                                var newOptionsInsu = insurancesList.map((item) => {
                                    return { title: item.Name, value: item.Name, srcData: item };
                                });
                                targetData.functionManager.populateData(newOptionsInsu, false).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });
                            }
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "loadFilePackages":
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        if (modelsList && Object.keys(modelsList).length) {
                            var newOptionsFP = [{ title: "Aucun", value: -1 }];
                            Object.keys(modelsList).forEach((key) => {
                                var item = modelsList[key];
                                if (item && item.Category && item.Category.endsWith("GENDOC") && item.Name.startsWith("HTML_GENDOC_")) {//PATCH (item.Name.startsWith("HTML_GENDOC_")) pour exclure HTML_BASE
                                    if (!item.Name) item.Name = "";
                                    newOptionsFP.push({ title: item.Name, value: item.Name.startsWith("HTML_GENDOC_") ? item.Name.replace("HTML_GENDOC_", "") : 0, srcData: item });
                                }
                            });
                            targetData.functionManager.populateData(newOptionsFP, false).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;
                case "changeReportFilePackage":
                    if (componentData && componentData.functionManager && componentData.functionManager.findItem && targetData && targetData.functionManager && targetData.functionManager.setValue) {

                        var option = componentData.functionManager.findItem(componentArgs.value);
                        if (option && option.length && option[0].srcData) {
                            targetData.functionManager.setValue(option[0].srcData.Template).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;


                case "loadContactTypes":
                    //TODO : charger différemment ??
                    if (targetData && targetData.functionManager && targetData.functionManager.populateData) {
                        if (parentProps.dataCfg && parentProps.dataCfg.contactTypes) {
                            var newOptionsCT = parentProps.dataCfg.contactTypes.map((item) => {
                                return { title: item.ContactType, value: item.ContactType, srcData: item };
                            });
                            targetData.functionManager.populateData(newOptionsCT, false).then((responseLoad) => {
                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible de charger les données";
                        resolve(responseAction);
                    }
                    break;


                case "loadMCycleID"://DEMAND Client
                    if (loadedData) {
                        var cNewValue2 = loadedData.value;

                        if (loadedData.constraintDmd === 126) {
                            if (loadedData.includeSpecDays) cNewValue2 = 3;
                            else cNewValue2 = 2;
                        }
                        else {
                            cNewValue2 = 1;
                        }


                        targetData.functionManager.setValue(cNewValue2).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    break;
                case "loadFromStep"://DEMAND Client
                    if (loadedData) {
                        targetData.functionManager.setValue(loadedData.stepDmd).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    break;
                case "loadMConstraint"://DEMAND Client
                    if (loadedData && loadedData.constraintDmd !== 16384 && loadedData.typeDmd === "M") {
                        targetData.functionManager.setValue(2, true).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    break;
                case "loadFromConstraintDmd"://DEMAND Client
                    if (loadedData && loadedData.typeDmd === "M") {
                        console.debug(loadedData.constraintDmd);
                        targetData.functionManager.setValue(loadedData.constraintDmd).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    break;
                case "loadCycleIdx"://DEMAND Client
                    if (loadedData && loadedData.constraintIdx && loadedData.constraintDmd !== 16384 && loadedData.typeDmd === "M") {
                        console.debug(loadedData.constraintIdx);
                        targetData.functionManager.setValue(loadedData.constraintIdx).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    break;
                case "loadIdx"://DEMAND Client
                    if (loadedData && loadedData.constraintIdx && loadedData.constraintDmd === 16384 && loadedData.typeDmd === "M") {
                        targetData.functionManager.setValue(loadedData.constraintIdx).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    break;

                case "diffBillAddress":
                    //value = Address
                    //altValue = BillAddress

                    var mainData = dataList[commonFunc.camelize(value, true) + ".addressMain"];
                    var ads2Data = dataList[commonFunc.camelize(value, true) + ".addressPlus"];
                    var ads3Data = dataList[commonFunc.camelize(value, true) + ".addressPlus2"];
                    var countryData = dataList[commonFunc.camelize(value, true) + ".country"];
                    var pcData = dataList[commonFunc.camelize(value, true) + ".pc"];
                    var cityData = dataList[commonFunc.camelize(value, true) + ".city"];

                    if (mainData) mainData.onActionEvents.onChange = mainData.onActionEvents.onChange.filter((actionEvent) => actionEvent.trigger !== "setValue");
                    if (ads2Data) ads2Data.onActionEvents.onChange = ads2Data.onActionEvents.onChange.filter((actionEvent) => actionEvent.trigger !== "setValue");
                    if (ads3Data) ads3Data.onActionEvents.onChange = ads3Data.onActionEvents.onChange.filter((actionEvent) => actionEvent.trigger !== "setValue");
                    if (countryData) countryData.onActionEvents.onChange = countryData.onActionEvents.onChange.filter((actionEvent) => actionEvent.trigger !== "setValue");
                    if (pcData) pcData.onActionEvents.onChange = pcData.onActionEvents.onChange.filter((actionEvent) => actionEvent.trigger !== "setValue");
                    if (cityData) cityData.onActionEvents.onChange = cityData.onActionEvents.onChange.filter((actionEvent) => actionEvent.trigger !== "setValue");

                    var grpDataAlt = dataList[commonFunc.camelize(altValue, true)];
                    if (grpDataAlt) {
                        componentData.hidden = true;
                        if (componentData.functionManager && componentData.functionManager.rerender) componentData.functionManager.rerender();

                        grpDataAlt.hidden = false;
                        if (grpDataAlt.functionManager && grpDataAlt.functionManager.rerender) grpDataAlt.functionManager.rerender();
                    }
                    break;
                case "refAuto":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue) {
                        var valueRef = "";

                        if (targetData.onActionEvents && targetData.onActionEvents.extrasActionEvent && targetData.onActionEvents.extrasActionEvent[value]) {
                            targetData.onActionEvents.extrasActionEvent[value].forEach((actionTargetEvent) => {
                                var altData = dataList[commonFunc.camelize(actionTargetEvent.target, true)];
                                if (altData && altData.functionManager) {
                                    var valueTarget = actionTargetEvent.target === componentData.bindPropResult ? componentArgs.value : altData.functionManager.getValue();
                                    if (valueTarget && actionTargetEvent.max > 0) {
                                        valueTarget = valueTarget.toString();
                                        if (actionTargetEvent.target.endsWith("PC")) {
                                            valueRef += valueTarget.substr(0, (actionTargetEvent.max || 2));
                                        }
                                        else if (actionTargetEvent.target.endsWith("City")) {
                                            valueRef += valueTarget.substr(0, (actionTargetEvent.max || 8)) + "-";
                                        }
                                        else {
                                            valueRef += valueTarget.substr(0, (actionTargetEvent.max || 8));
                                        }
                                    }
                                }
                            });
                            valueRef = valueRef.replace(/\//g, "").replace(/\'/g, "").replace(/\"/g, "");
                        }

                        targetData.functionManager.setValue(valueRef.toUpperCase(), componentArgs.withRerender).then((responseLoad) => {
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur : Erreur configuration";
                        resolve(responseAction);
                    }
                    break;
                case "lockRefAuto":
                    if (!commonFunc.isBlank(value) && componentData.onActionEvents && componentData.onActionEvents.extrasActionEvent && componentData.onActionEvents.extrasActionEvent[value] && componentData.onActionEvents.extrasActionEvent[value].length > 0) {
                        componentData.onActionEvents.extrasActionEvent[value].forEach((actionTargetEvent) => {
                            var altData = dataList[commonFunc.camelize(actionTargetEvent.target, true)];
                            if (altData) altData.onActionEvents.onChange = altData.onActionEvents.onChange.filter((actionEvent) => actionEvent.trigger !== actionTargetEvent.value);
                        });
                    }
                    break;

                case "changeReportTemplate"://TODO ???
                    break;
                case "changeReportCategory":
                    if (dataCache[targetCache]) {

                        var service = dataCache[targetCache].filter((svc) => {
                            return svc.idService === parseInt(componentArgs.value);
                        });
                        if (service.length /*&& service[0].templates*/) {
                            if (targetData && targetData.functionManager && targetData.functionManager.setValue) {
                                targetData.functionManager.setValue(service[0].reportCategoryID, componentArgs.withRerender).then((responseLoad) => {
                                    responseAction = responseLoad;
                                    resolve(responseAction);
                                });
                            }
                            else {
                                responseAction.errorMessage = "Impossible d'affecter la valeur : Erreur configuration";
                                resolve(responseAction);
                            }
                        }
                        else {
                            responseAction.errorMessage = "Impossible d'affecter la valeur : Aucun modèle";
                            resolve(responseAction);
                        }

                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur : Erreur cache";
                        resolve(responseAction);
                    }
                    break;
                case "changeForm":
                    //TODO : gestion country des adresses

                    break;

                case "sendPlanItv":
                    var argsSend = {};
                    if (componentArgs && Object.keys(componentArgs).length) {
                        Object.keys(componentArgs).forEach((key) => {
                            var valueArgs = componentArgs[key];
                            if (valueArgs.startsWith && valueArgs.startsWith("prop:")) argsSend[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                            else argsSend[key] = valueArgs;
                        });
                    }

                    var sendProcess = () => {
                        Swal.fire({
                            title: 'Envoi en cours...',
                            didOpen: () => { Swal.showLoading() },
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        });

                        //commonFunc.postMethod("../api/report/UpdateStatus", argsSend).then((response) => {
                        commonFunc.postMethod("../api/report/PrepareReport", argsSend).then((response) => {
                            Swal.close();
                            if (response.data && response.data.isSuccess) {
                                Swal.fire({
                                    icon: "success",
                                    title: t("gal_success"),
                                    html: "Envoi effectué avec succès",
                                    didOpen: () => { Swal.hideLoading() },
                                }).then((result) => {
                                    if (parentProps.loadList) {
                                        //Si besoin d'afficher le rapport après envoi
                                        if (componentArgs.showReport) {
                                            parentProps.loadList(argsSend.id, argsSend.newStatus);
                                        }
                                        else {
                                            parentProps.loadList(argsSend.id);
                                        }
                                    }
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: "Echec de mise à jour du statut<br/>" + response.data.errorMessage
                                });
                            }
                            responseAction = response.data;
                            resolve(responseAction);
                        });
                    };

                    if (argsSend.id) {
                        Swal.fire({
                            title: 'Préparation...',
                            didOpen: () => { Swal.showLoading() },
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        });

                        sendProcess();
                        /*commonFunc.getMethod("../api/report/GetReportModel", argsSend).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data.isSuccess) {
                                sendProcess();
                            }
                            else if (data.errorCode === 404) {
                                Swal.fire({
                                    title: 'Enregistrement en cours...',
                                    didOpen: () => { Swal.showLoading() },
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                });
                                commonFunc.postMethod("../api/report/AddReportModel", argsSend).then((response) => {
                                    Swal.close();
                                    if (response.data && response.data.isSuccess) {
                                        sendProcess();
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Echec",
                                            html: "Echec de préparation<br/>" + response.data.errorMessage
                                        });
                                    }
                                    responseAction = response.data;
                                    resolve(responseAction);
                                });
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: "Echec d'envoi sur la tablette<br/>" + data.errorMessage
                                });
                                responseAction = data;
                                resolve(responseAction);
                            }
                        });*/
                    }
                    else {
                        responseAction.errorMessage = "Arguments invalides";
                        resolve(responseAction);
                    }
                    break;


                case "updateDlgItvStatus":
                    //var cDItvTargetValue = targetData.functionManager.getValue();
                    //var cDItvSelectedSrc = targetData.functionManager.getSrcData ? targetData.functionManager.getSrcData(cDItvTargetValue) : null;
                    var cDItvSelectedSrc = componentArgs ? componentArgs.srcData : null;
                    if (cDItvSelectedSrc) {
                        setValue("cItvStep", cDItvSelectedSrc.step);
                        setValue("cItvValue", cDItvSelectedSrc.value);
                    }

                    break;
                case "confirmPlanItv":
                    var currentData = altValue || getValues();
                    var newArgs = Object.assign({}, componentArgs);
                    if (value) {
                        /*var cDItvStatus = dataList[commonFunc.camelize("StatusID", true)];
                        if (cDItvStatus && cDItvStatus.functionManager) {
                            console.debug(cDItvStatus);

                            var cDItvTargetValue = cDItvStatus.functionManager.getValue();
                            console.debug(cDItvTargetValue);
                            var cDItvSelectedSrc = cDItvStatus.functionManager.getSrcData ? cDItvStatus.functionManager.getSrcData(cDItvTargetValue) : null;
                            if (cDItvSelectedSrc) {
                                if (cDItvSelectedSrc.step < 0) {
                                    newArgs.statusItv = 8;
                                }
                                else {
                                    newArgs.statusItv = 2;
                                }
                                if (cDItvSelectedSrc.step === 1 && cDItvSelectedSrc.value === 1) {
                                    newArgs.isValid = true;
                                }
                                else {
                                    newArgs.isValid = false;
                                }
                            }
                        }*/
                        if (currentData.cItvStep >= 0) {
                            newArgs.statusItv = 2;
                        }
                        else {
                            newArgs.statusItv = 8;
                        }
                        if (currentData.cItvStep === 0 && currentData.cItvValue === 1) {
                            newArgs.isValid = true;
                        }
                        else {
                            newArgs.isValid = false;
                        }

                    }
                    else {
                        newArgs.statusItv = 2;
                    }
                    onActionRequired(tag, { trigger: "savePlanItv", altValue: currentData }, componentData, newArgs).then((responseLoad) => {
                        responseAction = responseLoad;
                        resolve(responseAction);
                    });
                    break;
                case "savePlanItv":

                    var currentData = altValue || getValues();
                    var statusID = ((componentArgs && componentArgs.statusItv) ? componentArgs.statusItv : loadedData.itv.statusID);

                    console.debug(currentData);

                    var itv = {};
                    //Récup data
                    /*itv.ReportItvID = loadedData.idReport;
                    itv.IsSet = true;
                    itv.ServiceID = loadedData.benefitID;
                    itv.StartDate = moment((loadedData.itv.startDate instanceof Date) ? loadedData.itv.startDate : commonFunc.restStringToDate(loadedData.itv.startDate)).utc(true).toDate();
                    itv.EndDate = commonFunc.addMinutes(itv.StartDate, 90);
                    itv.EmployeeID = loadedData.itv.employeeID;
                    itv.Name = loadedData.itv.employee.name + " " + loadedData.itv.employee.firstName;
                    itv.Title = loadedData.client.name + " [" + loadedData.refID + "]    " + loadedData.address.pc;
                    itv.Comments = loadedData.address.addressMain + " " + loadedData.address.addressPlus + " " + loadedData.address.addressPlus2 + " " + loadedData.address.pc + " " + loadedData.address.city;

                    itv.Phone = loadedData.employee.gsm;
                    itv.Email = "";
                    itv.Length = 1.5;
                    itv.Tel = loadedData.employee.tel;
                    itv.Email = "";
                    itv.Version = loadedData.refIDVersion;*/

                    //Récup partiel front (getValues)
                    //itv.reportItvID = loadedData.idReport;
                    itv.idEvent = loadedData.itv.idEvent;
                    itv.reportID = loadedData.idReport;
                    //itv.isSet = true;
                    itv.serviceID = currentData.itv.serviceID;
                    var sd = currentData.itv.startDate;
                    itv.startDate = (sd instanceof Date) ? sd : commonFunc.restStringToDate(sd);
                    itv.endDate = commonFunc.addMinutes(itv.startDate, currentData.itv.length * 60);
                    itv.employeeID = currentData.itv.employeeID;
                    //itv.name = loadedData.itv.employee.name + " " + loadedData.itv.employee.firstName;
                    itv.title = loadedData.client.name + " [" + loadedData.refID + "]    " + loadedData.address.pc;//TODO : voir pour concatener les champs
                    itv.comments = loadedData.address.addressMain + " " + loadedData.address.addressPlus + " " + loadedData.address.addressPlus2 + " " + loadedData.address.pc + " " + loadedData.address.city;

                    //itv.phone = getValues("employee.gsm");//TODO ?
                    //itv.email = "";
                    itv.length = currentData.itv.length;
                    //itv.tel = loadedData.employee.tel;
                    //itv.version = loadedData.refIDVersion;
                    itv.statusID = statusID;

                    if (currentData.statusID) {
                        itv.statusRequest = {};
                        itv.statusRequest.id = itv.reportID;
                        itv.statusRequest.newStatus = currentData.statusID;
                        itv.statusRequest.newStep = currentData.stepID;
                    }

                    
                    if (componentArgs && Object.keys(componentArgs).length) {
                        Object.keys(componentArgs).forEach((key) => {
                            var valueArgs = componentArgs[key];
                            if (valueArgs.startsWith && valueArgs.startsWith("prop:")) itv[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                            else itv[key] = valueArgs;
                        });
                    }


                    if (itv.employeeID > 0 && commonFunc.isValidDate(itv.startDate)) {
                        Swal.fire({
                            title: 'Opération en cours...',
                            didOpen: () => { Swal.showLoading() },
                        });

                        commonFunc.postMethod("../api/report/ScheduleItvReport", itv).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data && data.isSuccess) {
                                Swal.fire({
                                    icon: "success",
                                    title: t("gal_success"),
                                    html: "Assignation effectuée avec succès",
                                    didOpen: () => { Swal.hideLoading() },
                                }).then((result) => {
                                    onActionRequired(tag, { trigger: "refreshElem" }, componentData, componentArgs).then((responseLoad) => {
                                        responseAction = responseLoad;
                                        resolve(responseAction);
                                    });

                                    chainEvents(componentData, value);
                                });
                            } else {
                                responseAction.errorMessage = "Echec de l'assignation : " + data.errorMessage;
                                responseAction.forceMessage = true;
                                resolve(responseAction);
                            }
                            if (parentProps.autoClose) ref.current.submitForm("success");
                        });


                    } else {
                        responseAction.errorMessage = "MAJ impossible. Données invalides";
                        resolve(responseAction);
                        if (parentProps.autoClose) ref.current.submitForm("cancel");
                    }
                    break;

                //TODO : test la fonction
                case "reportDlgEnableDates":
                    var active = value;
                    /*$("#consDlg_site_sd").prop("disabled", active);//startDate
                    $("#consDlg_site_sh").prop("disabled", active);
                    $("#aupdDlgRepLstEmp").prop("disabled", active);//itv.employeeId
                    $("#consDlg_site_drt").prop("disabled", active);//itv.length
                    $("#aupdDlgRepLstSvc").prop("disabled", active);//benefitID

                    $(".filter-period-trigger").prop("disabled", active);//variations startDate

                    if (!componentArgs.noButtons) $("#consDlg_site_dsfct, #consDlg_site_chgasgn").addClass("disabled hidden d-none").prop("disabled", !active);

                    if (componentArgs.resetFields) {
                        $("#aupdDlgRepLstEmp").val(0);//itv.employeeId
                        $("#consDlg_site_dsfct").data("targetId", -1);//itv.idEvent
                    }*/
                    dataList["startDate"].disabled = active;
                    //dataList["startDate_dateonly"].disabled = active;
                    dataList["itv.employeeId"].disabled = active;
                    dataList["itv.length"].disabled = active;
                    dataList["benefitID"].disabled = active;

                    //$(".filter-period-trigger").prop("disabled", active);//variations startDate

                    if (!componentArgs.noButtons) {
                        dataList["unassignButton"].hidden = !active;
                        dataList["unassignButton"].disabled = !active;
                        dataList["unassignButton"].functionManager.rerender();
                        dataList["chgassignButton"].hidden = !active;
                        dataList["chgassignButton"].disabled = !active;
                        dataList["chgassignButton"].functionManager.rerender();
                    }

                    if (componentArgs.resetFields) {
                        onActionRequired(tag, { trigger: "postGeneric", url: "api/report/UnscheduleItvReport" }, componentData, componentArgs).then((responseLoad) => {
                            if (responseLoad.isSuccess) {
                                var empData = dataList["itv.employeeId"];
                                if (empData && empData.functionManager && empData.functionManager.setValue) {
                                    empData.functionManager.setValue(-1, true);
                                }
                                var eventData = dataList["itv.idEvent"];
                                if (eventData && eventData.functionManager && eventData.functionManager.setValue) {
                                    eventData.functionManager.setValue(-1, true);
                                }
                            }
                            responseAction = responseLoad;
                            resolve(responseAction);
                        });
                    }
                    else {
                        dataList["itv.employeeId"].functionManager.rerender();
                    }

                    dataList["startDate"].functionManager.rerender();
                    //dataList["startDate_dateonly"].functionManager.rerender();
                    dataList["itv.length"].functionManager.rerender();
                    dataList["benefitID"].functionManager.rerender();
                    //$(".filter-period-trigger").prop("disabled", active);//variations startDate

                    break;

                case "updateReportStatus":
                    if (parentProps && parentProps.updateStatusElem && loadedData) {
                        var updStatusProcess = () => {
                            parentProps.updateStatusElem(value).then((responseLoad) => {
                                if (responseLoad.isSuccess && responseLoad.result !== "") {
                                    Swal.fire({
                                        icon: 'success',
                                        title: "Statut modifié avec Succès",
                                        confirmButtonText: "Fermer",
                                    });
                                }

                                responseAction = responseLoad;
                                resolve(responseAction);
                            });
                        };

                        var rptStatusStep = loadedData.status.step;

                        //Si on était en Envoyé, recréation du rapport et suppression de l'ancien
                        if (rptStatusStep === 1) {
                            //Recreate report
                            /*updateManualReport(function () {
                                //$("#frmDlgManualAdditv").submit();
                                $("#btnDlgAddItv").click();
                                //hideBsModal("dlgAddManualItvModal");

                                responseAction.isSuccess = true;
                                resolve(responseAction);
                            });*/

                            //TODO : recreate REPORT
                            Swal.fire({
                                icon: 'error',
                                title: 'Echec',
                                html: "TODO",
                            });
                            resolve(responseAction);
                        }
                        //Si passage en En attente et que planifié, Unassign
                        else if (parseInt(altValue) < 0 && loadedData.itv != null && loadedData.planItvID > 0) {
                            var args = { id: loadedData.itv.idEvent || 0, bindId: loadedData.idReport };
                            commonFunc.postMethod("../api/report/UnscheduleItvReport", args).then((response) => {
                                var data = response.data;
                                if (data && data.isSuccess) {
                                    updStatusProcess();
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de désaffection de l'intervenant<br/>" + data.errorMessage
                                    });
                                    responseAction = data;
                                    resolve(responseAction);
                                }
                            });
                        }
                        //Si passage en Planifié Non Envoyé et que pas de plannification
                        else if (parseInt(altValue) <= 0 && parseInt(targetValue) === 1 && !(loadedData.itv != null && loadedData.planItvID > 0)) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Echec',
                                html: "MAJ impossible<br>Une planification est nécessaire",
                            });
                            responseAction.errorMessage = "MAJ impossible<br>Une planification est nécessaire";
                            resolve(responseAction);
                        }
                        else {
                            updStatusProcess();
                        }
                    }
                    else {
                        responseAction.errorMessage = "MAJ impossible";
                        resolve(responseAction);
                    }
                    break;



                case "loadReportActionStatus":
                    commonFunc.getMethod("../api/report/GetStatus", null).then((response) => {
                        var data = response.data;

                        if (data.isSuccess) {
                            if (useCache) {
                                dataCache[targetCache] = data.result;
                            }

                            var newItems = [];
                            var curStep = (loadedData ? data.result.filter((st) => st.idStatus === loadedData.statusID) : []);
                            if (curStep.length) curStep = curStep[0].step;
                            data.result.forEach((status) => {
                                if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                    newItems.push({
                                        name: "ReportItem",
                                        title: "Passer en <" + status.name + ">",
                                        objType: "button",
                                        ignoreParentDisabled: true,
                                        classSup: status.css.replace(/badge/g, "btn") + " text-white",
                                        onActionEvents: { onClick: [{ trigger: "updateReportStatus", target: "", value: status.idStatus, altValue: status.step, targetValue: status.value }] },//updateStatusElem
                                        hideOnAction: [{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "!=", altValue: curStep, targetValue: (loadedData ? loadedData.statusID : 0) },//Cache tout les boutons de step différent du status actuel
                                            { id: status.idStatus, trigger: "showActionStatus", target: "", value: status.step, operatorTest: "=", altValue: (curStep < 1 ? -1 : null), targetValue: (loadedData ? loadedData.statusID : 0) },//Affiche tout les boutons step=-1 si status actuel à un step < 1
                                            { id: status.idStatus, trigger: "showActionStatus", target: "", value: status.step, operatorTest: "=", altValue: curStep + 1, targetValue: (loadedData ? loadedData.statusID : 0) }]//Affiche tout les boutons step+1
                                    });
                                }
                            });
                            targetData.items = newItems;
                            if (targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();


                            responseAction = data;
                            resolve(responseAction);

                        } else {
                            responseAction = data;
                            resolve(responseAction);
                        }
                    });
                    break;
                case "loadClientActionStatus":
                    commonFunc.getMethod("../api/client/GetStatus", null).then((response) => {
                        var data = response.data;

                        if (data.isSuccess) {
                            if (useCache) {
                                dataCache[targetCache] = data.result;
                            }

                            var newItems = [];
                            var curStep = (loadedData ? data.result.filter((st) => st.idStatus === loadedData.statusID) : []);
                            if (curStep.length) curStep = curStep[0].step;
                            data.result.forEach((status) => {
                                if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                    newItems.push({
                                        name: "ReportItem",
                                        title: "Passer en <" + status.name + ">",
                                        objType: "button",
                                        ignoreParentDisabled: true,
                                        classSup: status.css.replace(/badge/g, "btn") + " text-white",
                                        onActionEvents: { onClick: [{ trigger: "updateStatusElem", target: "", value: status.idStatus }] },
                                        hideOnAction: [{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "=", altValue: curStep, targetValue: (loadedData ? loadedData.statusID : 0) }]
                                    });
                                }
                            });
                            targetData.items = newItems;
                            if (targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();

                            responseAction = data;
                            resolve(responseAction);

                        } else {
                            responseAction = data;
                            resolve(responseAction);
                        }
                    });
                    break;
                case "loadLeadActionStatus":
                    commonFunc.getMethod("../api/lead/GetStatus", null).then((response) => {
                        var data = response.data;

                        if (data.isSuccess) {
                            if (useCache) {
                                dataCache[targetCache] = data.result;
                            }

                            var newItems = [];
                            var curStep = (loadedData ? data.result.filter((st) => st.idStatus === loadedData.statusID) : []);
                            if (curStep.length) curStep = curStep[0].step;
                            data.result.forEach((status) => {
                                if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                    newItems.push({
                                        name: "ReportItem",
                                        title: "Passer en <" + status.name + ">",
                                        objType: "button",
                                        ignoreParentDisabled: true,
                                        classSup: status.css.replace(/badge/g, "btn") + " text-white",
                                        onActionEvents: { onClick: [{ trigger: "updateStatusElem", target: "", value: status.idStatus }] },
                                        hideOnAction: [{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "!=", altValue: curStep+1, targetValue: (loadedData ? loadedData.statusID : 0) }]
                                    });
                                }
                            });
                            targetData.items = newItems;
                            if (targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();

                            responseAction = data;
                            resolve(responseAction);

                        } else {
                            responseAction = data;
                            resolve(responseAction);
                        }
                    });
                    break;
                case "loadMaterialActionStatus":
                    commonFunc.getMethod("../api/material/GetStatus", null).then((response) => {
                        var data = response.data;

                        if (data.isSuccess) {
                            if (useCache) {
                                dataCache[targetCache] = data.result;
                            }

                            var newItems = [];
                            var curStep = (loadedData ? data.result.filter((st) => st.idStatus === loadedData.statusID) : []);
                            if (curStep.length) curStep = curStep[0].step;
                            data.result.forEach((status) => {
                                if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                    newItems.push({
                                        name: "ReportItem",
                                        title: "Passer en <" + status.name + ">",
                                        objType: "button",
                                        ignoreParentDisabled: true,
                                        classSup: status.css.replace(/badge/g, "btn") + " text-white",
                                        onActionEvents: { onClick: [{ trigger: "updateStatusElem", target: "", value: status.idStatus }] },
                                        hideOnAction: [{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "=", altValue: curStep, targetValue: (loadedData ? loadedData.statusID : 0) }]
                                    });
                                }
                            });
                            targetData.items = newItems;
                            if (targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();

                            responseAction = data;
                            resolve(responseAction);

                        } else {
                            responseAction = data;
                            resolve(responseAction);
                        }
                    });
                    break;
                case "loadLeadRadioSubStatus":
                    commonFunc.getMethod("../api/lead/GetStatus", { isSubStatus: true/*, level: curStep*/ }).then((response) => {//TODO : test avec level
                        var data = response.data;

                        if (data.isSuccess) {
                            if (useCache) {
                                dataCache[targetCache] = data.result;
                            }

                            var newItems = [];
                            var itemOptions = [];
                            var loadedStepID = loadedData ? loadedData.stepID : 0;
                            var defaultStepID = 0;
                            var curStep = (loadedData && loadedData.status ? loadedData.status.step : []);
                            data.result.forEach((status) => {
                                if (!status.isAutomatic && (!max || status.step < parseInt(max))) {
                                    if (loadedStepID === status.idStatus) defaultStepID = status.idStatus;
                                    itemOptions.push({
                                        title: status.name, value: status.idStatus,
                                        hideOnAction: [{ id: status.idStatus, trigger: "hideActionStatus", target: "", value: status.step, operatorTest: "!=", altValue: curStep, targetValue: loadedStepID },
                                            { id: status.idStatus, trigger: "showActionStatus", target: "", value: status.step, operatorTest: "=", altValue: 0, targetValue: loadedStepID }]
                                    });//status.css
                                }
                            });
                            newItems.push({
                                name: "ReportItem",
                                title: "",
                                defaultValue: (defaultStepID || itemOptions[0].value),
                                bindPropResult: "cSubStatus",
                                objType: "radio",
                                isInput: true,
                                ignoreParentDisabled: true,
                                options: itemOptions,
                                customDesign: {
                                    design: { boxSize: "1" },
                                    designInput: { alignment: "horizontal" }
                                },
                                onActionEvents: { onChange: [{ trigger: "updateStatusElem", target: "", value: (loadedData ? loadedData.statusID : 0) }] }
                            });

                            targetData.items = newItems;
                            if (targetData.functionManager && targetData.functionManager.rerender) targetData.functionManager.rerender();

                            responseAction = data;
                            resolve(responseAction);

                        } else {
                            responseAction = data;
                            resolve(responseAction);
                        }
                    });
                    break;


                case "showRptBtn":
                    var valueTest1;
                    switch (targetValue) {
                        case "modelDisabled": valueTest1 = genModel.disabled; break;
                        case "modelEdition": /*preventAction = genModel.disabled;*/ valueTest1 = (isUpdateGen ? true : false); break;
                        default: valueTest1 = 0; break;
                    }

                    if (loadedData && valueTest1 === true) {
                        switch (value) {
                            case "sendPlanItv":
                                targetData.hidden = !(loadedData.itv != null && loadedData.planItvID > 0 && loadedData.status.step < 3 && loadedData.status.step !== 1 && loadedData.itv.employeeID > 0 && loadedData.itv.statusID <= 6);
                                targetData.disabled = targetData.hidden;
                                break;
                            case "savePlanItv":
                                targetData.hidden = !(loadedData.itv == null || (/*loadedData.planItvID <= 0 &&*/ loadedData.status.step < 1 && loadedData.itv.statusID === 8));
                                targetData.disabled = targetData.hidden;
                                break;
                            case "removePlanItv":
                                targetData.hidden = !(loadedData.itv != null && loadedData.planItvID > 0 && loadedData.status.step < 3 && loadedData.itv.employeeID > 0 && loadedData.itv.statusID <= 6);//statusID <= Créé+Modifier
                                targetData.disabled = !(loadedData.itv != null && loadedData.planItvID > 0 && loadedData.status.step < 1);
                                break;
                            case "cancelReport":
                                targetData.hidden = !(loadedData.itv != null && loadedData.planItvID > 0 && loadedData.status.step === 1 && loadedData.itv.employeeID > 0 && loadedData.itv.statusID <= 6);//statusID <= Créé+Modifier
                                targetData.disabled = targetData.hidden;
                                break;
                            case "generatePackFiles":
                                targetData.hidden = !(loadedData.itv != null && loadedData.planItvID > 0 && loadedData.status.step < 3 && loadedData.filePackage);
                                targetData.disabled = !(loadedData.itv != null && loadedData.planItvID > 0 && loadedData.status.step < 1 && loadedData.filePackage);
                                break;

                            case "editFormElem":
                                targetData.hidden = (loadedData.status.step > 1);
                                targetData.disabled = targetData.hidden;
                                break;
                            case "sendSms":
                                targetData.hidden = (loadedData.status.step > 1);
                                targetData.disabled = targetData.hidden;
                                break;
                            default: break;
                        }
                    }
                    break;
                case "showElement":
                case "hideElement":
                    var cNewValue4 = !(actionKey === "showElement");

                    var cptTarget = targetData;
                    var flagNoRerender = false;
                    if (altValue === "currValue") {
                        if (componentArgs && componentArgs.value) {
                            altValue = componentArgs.value;
                        }
                        else {
                            altValue = componentData.defaultValue;
                        }
                    }
                    else if (altValue === "targetValue") {
                        altValue = targetData.defaultValue;
                        cptTarget = componentData;
                        flagNoRerender = true;
                    }
                    var cFlag4 = false;
                    switch (operatorTest) {
                        case "=": cFlag4 = (value === altValue); break;
                        case "!=": cFlag4 = (value !== altValue); break;
                        case "<": cFlag4 = (value < altValue); break;
                        case "<=": cFlag4 = (value <= altValue); break;
                        case ">": cFlag4 = (value > altValue); break;
                        case ">=": cFlag4 = (value >= altValue); break;
                        default:
                            break;
                    }

                    if (cFlag4) {
                        /*if (cNewValue4)*/ cptTarget.hidden = cNewValue4;
                        if (!flagNoRerender) cptTarget.functionManager.rerender();
                    }
                    else {
                        if (!cNewValue4) cptTarget.hidden = !cNewValue4;
                        if (!flagNoRerender) cptTarget.functionManager.rerender();
                    }
                    break;



                    /*********** GESTION COMPTES ***********/

                case "loadAccessLst":

                    //TODO : Chargement de la liste de profils d'accès

                    break;
                case "loadTeamsLst":

                    //TODO : Chargement de la liste des équipes

                    break;

                case "togglePassword":
                    var isPassword = targetData.subType === "password";
                    if (targetData.functionManager) {
                        targetData.subType = (isPassword ? "" : "password");
                        if (targetData.functionManager.rerender) targetData.functionManager.rerender();
                    }

                    if (altValue) {
                        var iconBtnData = dataList[commonFunc.camelize(altValue, true)];
                        if (iconBtnData && iconBtnData.customDesign && iconBtnData.customDesign.designIcon) {
                            iconBtnData.customDesign.designIcon.icon = isPassword ? "fa_eye_slash" : "fa_eye";
                        }
                    }

                    break;
                case "createUserAccess":
                    var currentDataUA = altValue || getValues();

                    var infosPwd = currentDataUA.pwdStrength;
                    if (infosPwd.value >= 80) {

                        if (modalRoot) {
                            modalRoot.unmount();
                            modalRoot = null;
                        }

                        Swal.fire({
                            title: 'Création en cours...',
                            didOpen: () => { Swal.showLoading() },
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        });

                        var newArgsUA = Object.assign({}, componentArgs);

                        var userName = loadedData.name.toLowerCase() + "." + loadedData.firstName.toLowerCase();
                        var userEmail = currentDataUA.email;


                        //TEMP
                        var access = "";
                        var sites = {};
                        sites = sites.split ? sites : loadedData.siteID.toString();


                        //TODO : vérification sur le contenu de sites/access/teams + Email saisie
                        if (!commonFunc.isBlank(userEmail) && !commonFunc.isBlank(sites)) {

                            var asArgs = {};
                            if (componentArgs && Object.keys(componentArgs).length && componentArgs.url && componentArgs.id) {
                                Object.keys(componentArgs).forEach((key) => {
                                    var valueArgs = componentArgs[key];
                                    if (valueArgs.startsWith && valueArgs.startsWith("prop:")) asArgs[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                                    else asArgs[key] = valueArgs;
                                });

                                //aspnetusers >> ApplicationUser >> Compte principal
                                var accountUserObj = {};
                                accountUserObj.IDUser = 0;
                                accountUserObj.UserLogin = userEmail;//userName;
                                //accountUserObj.NormalizedUserName = userName;
                                accountUserObj.UserEmail = userEmail;
                                accountUserObj.UserPassword = commonFunc.parseBool(currentDataUA.useInvitation) ? "" : currentDataUA.userPwd;
                                accountUserObj.UseInvitation = commonFunc.parseBool(currentDataUA.useInvitation);
                                accountUserObj.EmailConfirmation = commonFunc.parseBool(currentDataUA.emailConfirmation);
                                //accountUserObj.NormalizedEmail = userEmail;
                                //EmailConfirmed
                                //PasswordHash
                                //SecurityStamp
                                //ConcurrencyStamp
                                //PhoneNumber
                                //PhoneNumberConfirmed
                                //accountUserObj.TwoFactorEnabled = false;
                                //accountUserObj.LockoutEnd = "";
                                //accountUserObj.LockoutEnabled = false;

                                accountUserObj.Status = true;
                                //accountUserObj.Connection = {};
                                //accountUserObj.UserLogin = "";
                                //accountUserObj.Provider = "";
                                //accountUserObj.ProviderKey = "";
                                //accountUserObj.ClientID = loadedData.siteID;
                                accountUserObj.UserPrimarySid = currentDataUA.customRightType;
                                accountUserObj.UserPrimaryGroupId = currentDataUA.customAccountType;
                                //accountUserObj.RegisteredOn = "";
                                //accountUserObj.Avatar = "";
                                //accountUserObj.TenantId = "";
                                //accountUserObj.TenantUserID = 0;
                                //accountUserObj.SubScriptionID = 1;
                                //accountUserObj.QuotaLimit = 1;
                                //accountUserObj.Telemetry = 1;
                                //accountUserObj.GroupSId = 0;
                                accountUserObj.MatchID = asArgs.id;
                                accountUserObj.Roles = access.split(";");
                                //accountUserObj.Rights = {}; //flags/userID/siteID

                                if (true) {
                                    accountUserObj.UserAccessList = [];
                                    sites.split(";").forEach((siteValue) => {

                                        //gal_users >> TenantUser
                                        var employeeUserObj = {};
                                        employeeUserObj.IDTenantUser = 0;
                                        employeeUserObj.Name = userName;
                                        employeeUserObj.Acl = commonFunc.isBlank(currentDataUA.customGroupsRights) ? "%" : currentDataUA.customGroupsRights;//access //$("#aupd_accessList").val().join(';') + ';';
                                        //employeeUserObj.StartDate = "";
                                        //employeeUserObj.EndDate = "";
                                        employeeUserObj.EmployeeID = asArgs.id;
                                        //employeeUserObj.ClientID = -1;
                                        //employeeUserObj.ProviderID = -1;
                                        employeeUserObj.PrimarySid = currentDataUA.customRightType;
                                        employeeUserObj.PrimaryGroupId = currentDataUA.customAccountType;
                                        employeeUserObj.IsActive = true;
                                        employeeUserObj.IsDefault = (loadedData.siteID === parseInt(siteValue));
                                        //employeeUserObj.IsReadOnly = false;
                                        //employeeUserObj.SubscriptionID = 1;

                                        //TenantID
                                        employeeUserObj.LocalUserID = 0;
                                        employeeUserObj.LocalSiteID = siteValue;
                                        //TenantUserAccessID

                                        console.debug(employeeUserObj);
                                        accountUserObj.UserAccessList.push(employeeUserObj);
                                        /*commonFunc.postMethod("../api/employee/CreateEmployeeAccess", args).then((response) => {
                                            var data = response.data;
                                            if (data && data.isSuccess) {
            
            
                                            } else {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Echec',
                                                    html: "Echec de la création des accès <p>" + data.errorMessage + "</p>",
                                                    didOpen: () => { Swal.hideLoading() }
                                                });
                                            }
                                        });*/
                                    });
                                }
                                commonFunc.postMethod("../" + asArgs.url, accountUserObj).then((response) => {
                                    var data = response.data;
                                    if (data && data.isSuccess) {

                                        var infos = data.comments.split(';');
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Création du compte effectuée',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            //html: "Utilisateur : " + infos[1] + "<br>Mot de passe : " + infos[2] + "<br>Email : " + infos[3],
                                            html: "Utilisateur : " + userName + "<br>Mot de passe : " + currentDataUA.userPwd + "<br>Email : " + userEmail,
                                            didOpen: () => { Swal.hideLoading() }
                                        }).then(function () {
                                            onActionRequired(tag, { trigger: "refreshElem" }, componentData, componentArgs).then((responseLoad) => {
                                                responseAction = responseLoad;
                                                resolve(responseAction);
                                            });

                                            chainEvents(componentData, value);
                                        });

                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Echec',
                                            html: "Echec de la création du compte <p>" + data.errorMessage + "</p>",
                                            didOpen: () => { Swal.hideLoading() }
                                        });
                                    }
                                });

                            }
                            else {
                                responseAction.errorMessage = "Impossible d'effectuer cette action : Erreur configuration";
                                resolve(responseAction);
                            }
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Echec',
                                html: "Mail/Sites non saisie",
                                didOpen: () => { Swal.hideLoading() }
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: t("gal_warning"),
                            html: t("register_errorPwdStrength"),
                        });
                    }
                    break;
                case "createAccess":

                    Swal.fire({
                        title: 'Création en cours...',
                        didOpen: () => { Swal.showLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });

                    var sites = getValues("sites");
                    var access = getValues("access");
                    var teams = getValues("teams");

                    var userName = loadedData.name.toLowerCase() + "." + loadedData.firstName.toLowerCase();
                    var userEmail = loadedData.email;


                    //TEMP
                    sites = sites.split ? sites : loadedData.siteID.toString();


                    //TODO : vérification sur le contenu de sites/access/teams + Email saisie
                    if (!commonFunc.isBlank(userEmail) && !commonFunc.isBlank(sites)) {

                        var asArgs = {};
                        if (componentArgs && Object.keys(componentArgs).length && componentArgs.url && componentArgs.id) {
                            Object.keys(componentArgs).forEach((key) => {
                                var valueArgs = componentArgs[key];
                                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) asArgs[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                                else asArgs[key] = valueArgs;
                            });

                            //aspnetusers >> ApplicationUser >> Compte principal
                            var accountUserObj = {};
                            accountUserObj.IDUser = 0;
                            accountUserObj.UserLogin = userName;
                            //accountUserObj.NormalizedUserName = userName;
                            accountUserObj.UserEmail = userEmail;
                            //accountUserObj.NormalizedEmail = userEmail;
                            //EmailConfirmed
                            //PasswordHash
                            //SecurityStamp
                            //ConcurrencyStamp
                            //PhoneNumber
                            //PhoneNumberConfirmed
                            //accountUserObj.TwoFactorEnabled = false;
                            //accountUserObj.LockoutEnd = "";
                            //accountUserObj.LockoutEnabled = false;

                            accountUserObj.Status = true;
                            //accountUserObj.Connection = {};
                            //accountUserObj.UserLogin = "";
                            //accountUserObj.Provider = "";
                            //accountUserObj.ProviderKey = "";
                            //accountUserObj.ClientID = loadedData.siteID;
                            accountUserObj.UserPrimarySid = 2;//rightsType.classic
                            accountUserObj.UserPrimaryGroupId = 1;//accountType.employee
                            //accountUserObj.RegisteredOn = "";
                            //accountUserObj.Avatar = "";
                            //accountUserObj.TenantId = "";
                            //accountUserObj.TenantUserID = 0;
                            //accountUserObj.SubScriptionID = 1;
                            accountUserObj.QuotaLimit = 1;
                            accountUserObj.Telemetry = 1;
                            //accountUserObj.GroupSId = 0;
                            accountUserObj.MatchID = asArgs.id;
                            accountUserObj.Roles = access.split(";");
                            //accountUserObj.Rights = {}; //flags/userID/siteID

                            if (true) {
                                accountUserObj.UserAccessList = [];
                                sites.split(";").forEach((siteValue) => {

                                    //gal_users >> TenantUser
                                    var employeeUserObj = {};
                                    employeeUserObj.IDTenantUser = 0;
                                    employeeUserObj.Name = userName;
                                    employeeUserObj.Acl = teams || "%";//access //$("#aupd_accessList").val().join(';') + ';';
                                    //employeeUserObj.StartDate = "";
                                    //employeeUserObj.EndDate = "";
                                    employeeUserObj.EmployeeID = asArgs.id;
                                    //employeeUserObj.ClientID = -1;
                                    //employeeUserObj.ProviderID = -1;
                                    employeeUserObj.PrimarySid = 2;//rightsType.classic
                                    employeeUserObj.PrimaryGroupId = 1;//accountType.employee
                                    employeeUserObj.IsActive = true;
                                    employeeUserObj.IsDefault = (loadedData.siteID === parseInt(siteValue));
                                    //employeeUserObj.IsReadOnly = false;
                                    //employeeUserObj.SubscriptionID = 1;

                                    //TenantID
                                    employeeUserObj.LocalUserID = 0;
                                    employeeUserObj.LocalSiteID = siteValue;
                                    //TenantUserAccessID

                                    console.debug(employeeUserObj);
                                    accountUserObj.UserAccessList.push(employeeUserObj);
                                    /*commonFunc.postMethod("../api/employee/CreateEmployeeAccess", args).then((response) => {
                                        var data = response.data;
                                        if (data && data.isSuccess) {
        
        
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Echec',
                                                html: "Echec de la création des accès <p>" + data.errorMessage + "</p>",
                                                didOpen: () => { Swal.hideLoading() }
                                            });
                                        }
                                    });*/
                                });
                            }
                            commonFunc.postMethod("../" + asArgs.url, accountUserObj).then((response) => {
                                var data = response.data;
                                if (data && data.isSuccess) {

                                    var infos = data.comments.split(';');
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Création du compte effectuée',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        html: "Utilisateur : " + infos[1] + "<br>Mot de passe : " + infos[2] + "<br>Email : " + infos[3],
                                        didOpen: () => { Swal.hideLoading() }
                                    }).then(function () {
                                        onActionRequired(tag, { trigger: "refreshElem" }, componentData, componentArgs).then((responseLoad) => {
                                            responseAction = responseLoad;
                                            resolve(responseAction);
                                        });

                                        chainEvents(componentData, value);
                                    });

                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Echec',
                                        html: "Echec de la création du compte <p>" + data.errorMessage + "</p>",
                                        didOpen: () => { Swal.hideLoading() }
                                    });
                                }
                            });

                        }
                        else {
                            responseAction.errorMessage = "Impossible d'effectuer cette action : Erreur configuration";
                            resolve(responseAction);
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec',
                            html: "Mail/Sites non saisie",
                            didOpen: () => { Swal.hideLoading() }
                        });
                    }




                    /*var id = getDtSelID("lstData");
                    Swal.fire({
                        title: 'Création en cours...',
                        onOpen: () => { Swal.showLoading() },
                    });

                    var employeeUserObj = {};
                    employeeUserObj.AccessLst = $("#aupd_accessList").val().join(';') + ';';
                    employeeUserObj.userAccount = {};
                    employeeUserObj.userAccount.MatchID = id;

                    postMethod("../login/CreateEmployeeUser", employeeUserObj, function (data) {
                        if (data.isSuccess) {
                            var infos = data.comments.split(';');
                            Swal.fire({
                                type: 'success',
                                title: 'Création des accès effectuée',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                html: "Utilisateur : " + infos[1] + "<br>Mot de passe : " + infos[2] + "<br>Email : " + infos[3],
                                onBeforeOpen: () => { Swal.hideLoading() }
                            }).then(function () {
                                $("#btnActDetailsRefresh").click();
                            });
                        }
                        else {
                            Swal.fire({
                                type: 'error',
                                title: 'Echec',
                                html: "Echec de la création des accès <p>" + data.errorMessage + "</p>",
                                onBeforeOpen: () => { Swal.hideLoading() }
                            });
                        }
                    });*/

                    break;
                case "generateAccess":

                    //TODO : Update des accès Sites / Roles / Teams

                    var sites = getValues("sites");
                    var access = getValues("access");
                    var teams = getValues("teams");



                    /*Swal.fire({
                        title: 'Mise à jour en cours...',
                        onOpen: () => { Swal.showLoading() },
                    });
                    var employeeUserObj = $("#btnClbUpdateAcc").data("accountObj");
                    employeeUserObj.AccessLst = $("#aupd_accessList").val().join(';') + ';';
                    var sites = $("#aupd_sitesList").val();
                    var newContracts = [];
                    $.each(sites, function (i, e) {
                        //TODO : récup ID contrat ?
                        newContracts.push({ id: 0, extId: parseInt(e) });
                    });
                    employeeUserObj.contracts = newContracts;

                    postMethod("../login/UpdateEmployeeUser", employeeUserObj, function (data) {
                        if (data.isSuccess) {
                            Swal.fire({
                                type: 'success',
                                title: 'Succès',
                                html: "Mise à jour des accès effectuée",
                                onBeforeOpen: () => { Swal.hideLoading() }
                            }).then(function () {
                                $("#btnActDetailsRefresh").click();
                            });
                        }
                        else {
                            Swal.fire({
                                type: 'error',
                                title: 'Echec',
                                html: "Echec de mise à jour des accès <p>" + data.errorMessage + "</p>",
                                onBeforeOpen: () => { Swal.hideLoading() }
                            });
                        }
                    });*/

                    break;
                case "resetPassword":
                    var asArgs = {};
                    if (componentArgs && Object.keys(componentArgs).length && componentArgs.url && componentArgs.id) {
                        Object.keys(componentArgs).forEach((key) => {
                            var valueArgs = componentArgs[key];
                            if (valueArgs.startsWith && valueArgs.startsWith("prop:")) asArgs[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                            else asArgs[key] = valueArgs;
                        });

                        var cId = asArgs.bindID;
                        if (parseInt(cId) > 0) {
                            var title = loadedData.firstName + " " + loadedData.name;

                            Swal.fire({
                                icon: 'question',
                                title: 'Réinitialisation de mot de passe',
                                html: "Souhaitez-vous réinitialiser le mot de passe [" + title + "] ?",
                                showCancelButton: true,
                                confirmButtonColor: '#28a745',
                                cancelButtonColor: '#dc3545',
                                confirmButtonText: 'Confirmer',
                                cancelButtonText: 'Annuler'
                            }).then((result) => {
                                if (result.value) {
                                    Swal.fire({
                                        title: 'Réinitialisation en cours...',
                                        didOpen: () => { Swal.showLoading() },
                                    });

                                    commonFunc.postMethod("../" + asArgs.url, asArgs).then((response) => {
                                        var data = response.data;
                                        if (data && data.isSuccess) {
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Réinitialisation Mot de passe',
                                                html: "Mot de passe réinitialisé avec succès<p>[" + title + "] " + data.result + "</p>",
                                                allowOutsideClick: false,
                                                allowEscapeKey: false,
                                            }).then((result) => {
                                                onActionRequired(tag, { trigger: "refreshElem" }, componentData, componentArgs).then((responseLoad) => {
                                                    responseAction = responseLoad;
                                                    resolve(responseAction);
                                                });

                                                chainEvents(componentData, value);
                                            });
                                        } else {
                                            if (data.errorCode == 0) {
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention",
                                                    text: data.errorMessage,
                                                    didOpen: () => { Swal.hideLoading() }
                                                });
                                            }
                                            else {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Echec",
                                                    html: "Echec de réinitialisation,<p>" + data.errorMessage + "</p>",
                                                    didOpen: () => { Swal.hideLoading() }
                                                });
                                            }
                                        }
                                    });
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                text: "Aucun élement valide"
                            });
                        }
                    
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'effectuer cette action : Erreur configuration";
                        resolve(responseAction);
                    }

                    /*var id = getDtSelID("lstData");
                                if (parseInt(id) > 0) {
                                    var title = getDtValue("lstData", id, "Prenom") + " " + getDtValue("lstData", id, "Nom");

                                    Swal.fire({
                                        type: 'question',
                                        title: 'Changement de mot de passe',
                                        html: "Souhaitez-vous changer le mot de passe de ce collaborateur [" + title + "] ?",
                                        showCancelButton: true,
                                        confirmButtonColor: '#28a745',
                                        cancelButtonColor: '#dc3545',
                                        confirmButtonText: 'Confirmer',
                                        cancelButtonText: 'Annuler'
                                    }).then((result) => {
                                        if (result.value) {
                                            Swal.fire({
                                                title: 'Modification en cours...',
                                                onOpen: () => { swal.showLoading() },
                                            });

                                            var uid = $("#btnChangePwd").data("bindid");
                                            //alert(uid);
                                            postMethod("../login/ResetAccountPwd", { value: uid, id: id }, function (data) {
                                                if (data && data.isSuccess) {
                                                    loadTech(id);
                                                    Swal.fire({
                                                        type: 'success',
                                                        title: 'Reset Compte Utilisateur',
                                                        html: "Compte Reset avec succès<p>[" + title + "] " + data.result + "</p>",
                                                        allowOutsideClick: false,
                                                        allowEscapeKey: false,
                                                    });

                                                } else {
                                                    if (data.errorCode == 0) {
                                                        Swal.fire({
                                                            type: "warning",
                                                            title: "Attention",
                                                            text: data.errorMessage
                                                        });
                                                    }
                                                    else {
                                                        Swal.fire({
                                                            type: "error",
                                                            title: "Echec",
                                                            html: "Echec d'enregistrement,<p>" + data.errorMessage + "</p>"
                                                        });
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                                else {
                                    Swal.fire({
                                        type: "error",
                                        title: "Echec",
                                        text: "Aucun collaborateur n'est sélectionné"
                                    });
                                }*/
                    break;
                case "changePassword":
                    var currentDataCPWD = altValue || getValues();

                    var infosPwdCPWD = currentDataCPWD.pwdStrength;
                    if (infosPwdCPWD.value >= 80) {

                        currentDataCPWD.id = loadedData.accountUser.idTenantUser;
                        currentDataCPWD.matchID = loadedData.accountUser.employeeID;
                        currentDataCPWD.primaryGroupId = loadedData.accountUser.primaryGroupId;
                        currentDataCPWD.primarySId = loadedData.accountUser.primarySid;

                        commonFunc.postMethod("../api/ChangePassword", currentDataCPWD).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data && data.isSuccess) {
                                Swal.fire({
                                    icon: "success",
                                    title: t("gal_success"),
                                    html: t("gal_successUpd"),
                                    didOpen: () => { Swal.hideLoading() },
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                }).then((result) => {
                                    //Refresh ???
                                });

                                data.result.idResponse = data.result.idClient;
                                //resolve(data.result);

                                responseAction.isSuccess = true;
                                responseAction.result = true;
                            } else {
                                var supErrors = "";
                                if (data.errors && data.errors.length) {
                                    supErrors = data.errors.map((error) => {
                                        return "<br>" + error.desc + "(" + error.value + ")";
                                    });
                                }
                                Swal.fire({
                                    icon: "error",
                                    title: t("gal_fail"),
                                    html: t("gal_errorSave") + " :<br>" + data.errorMessage + supErrors
                                });
                                //resolve(data);
                            }
                            if (modalRoot) {
                                modalRoot.unmount();
                                modalRoot = null;
                            }
                            resolve(responseAction);
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: t("gal_warning"),
                            html: t("register_errorPwdStrength"),
                        });
                    }
                    break;

                case "disableAccount":

                    var asArgs = {};
                    if (componentArgs && Object.keys(componentArgs).length && componentArgs.url && componentArgs.id) {
                        Object.keys(componentArgs).forEach((key) => {
                            var valueArgs = componentArgs[key];
                            if (valueArgs.startsWith && valueArgs.startsWith("prop:")) asArgs[key] = (dataList[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                            else asArgs[key] = valueArgs;
                        });
                    

                        var cId = asArgs.bindID;

                        if (parseInt(cId) > 0) {
                            Swal.fire({
                                icon: 'warning',
                                title: 'Désactivation du compte',
                                html: "Voulez-vous désactiver les accès pour ce compte ?",
                                showCancelButton: true,
                                confirmButtonColor: '#28a745',
                                cancelButtonColor: '#dc3545',
                                confirmButtonText: 'Confirmer',
                                cancelButtonText: 'Annuler'
                            }).then((result) => {
                                if (result.value) {
                                    Swal.fire({
                                        title: 'Désactivation en cours...',
                                        didOpen: () => { Swal.showLoading() },
                                    });

                                    commonFunc.postMethod("../" + asArgs.url, asArgs).then((response) => {
                                        var data = response.data;
                                        if (data && data.isSuccess) {
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Succès',
                                                html: "Désactivation effectuée avec succès",
                                                didOpen: () => { Swal.hideLoading() }
                                            }).then((result) => {
                                                onActionRequired(tag, { trigger: "refreshElem" }, componentData, componentArgs).then((responseLoad) => {
                                                    responseAction = responseLoad;
                                                    resolve(responseAction);
                                                });

                                                chainEvents(componentData, value);
                                            });
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Echec',
                                                html: "Echec de désactivation du compte <p>" + data.errorMessage + "</p>",
                                                didOpen: () => { Swal.hideLoading() }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                text: "Aucun élément valide"
                            });
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'effectuer cette action : Erreur configuration";
                        resolve(responseAction);
                    }


                    /*var id = getDtSelID("lstData");
                    var uid = $("#btnChangePwd").data("bindid");

                    Swal.fire({
                        type: 'warning',
                        title: 'Désactivation du compte',
                        html: "Voulez-vous désactiver les accès pour ce compte ?",
                        showCancelButton: true,
                        confirmButtonColor: '#28a745',
                        cancelButtonColor: '#dc3545',
                        confirmButtonText: 'Confirmer',
                        cancelButtonText: 'Annuler'
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Désactivation en cours...',
                                onOpen: () => { Swal.showLoading() },
                            });
                            //TODO
                            postMethod("../api/account/DisableAccount", { userID: uid, bindID: id }, function (data) {
                                Swal.close();
                                if (data && data.isSuccess) {
                                    Swal.fire({
                                        type: 'success',
                                        title: 'Succès',
                                        html: "Désactivation effectuée avec succès",
                                    });
                                    $("#btnActDetailsRefresh").click();
                                } else {
                                    Swal.fire({
                                        type: 'error',
                                        title: 'Echec',
                                        html: "Echec de désactivation du compte <p>" + data.errorMessage + "</p>",
                                    });
                                }
                            });
                        }
                    });*/
                    break;

                case "loadAccountStatus":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue) {
                        //if (!data.result.UserMatchAccountID > 0 || data.result.UserLocalDisable) {
                        if (loadedData && loadedData.accountID > 0 && loadedData.accountUser != null) {
                            var emailTarget = dataList[commonFunc.camelize("CUserEmail", true)];
                            if (emailTarget && emailTarget.functionManager && emailTarget.functionManager.setValue) emailTarget.functionManager.setValue(loadedData.email);

                            if (!loadedData.accountUser.isActive || loadedData.accountUser.employeeID <= 0) {
                                targetData.functionManager.setValue("Inactif");
                                if (targetData.functionManager.setBackground) targetData.functionManager.setBackground(altValue);
                            }
                            else {
                                targetData.functionManager.setValue("Actif");
                                if (targetData.functionManager.setBackground) targetData.functionManager.setBackground(value);
                            }
                        }
                    }
                    else {
                        responseAction.errorMessage = "Impossible d'affecter la valeur";
                        resolve(responseAction);
                    }
                    break;

                case "generatePwd":
                    if (targetData && targetData.functionManager && targetData.functionManager.setValue) {
                        var confirmPwdData;
                        if (altValue) {
                            confirmPwdData = dataList[commonFunc.camelize(altValue, true)];
                        }

                        clearErrors(commonFunc.camelize(targetData.bindPropResult, true));
                        if (confirmPwdData && confirmPwdData.functionManager) {
                            clearErrors(commonFunc.camelize(confirmPwdData.bindPropResult, true));
                        }
                        if (componentArgs.value) {
                            targetData.subType = "";
                            targetData.disabled = true;
                            targetData.functionManager.setValue("", true);
                            if (confirmPwdData) {
                                confirmPwdData.subType = "";
                                confirmPwdData.disabled = true;
                                if (confirmPwdData.functionManager) confirmPwdData.functionManager.setValue("", true);
                            }

                            //TEMP
                            /*targetData.functionManager.setValue("genPassword");
                            if (confirmPwdData && confirmPwdData.functionManager) {
                                confirmPwdData.functionManager.setValue("genPassword");
                            }*/


                            commonFunc.postMethod("../api/GeneratePassword", null).then((response) => {
                                var data = response.data;
                                if (data && data.isSuccess) {
                                    var genPwd = data.result.replace('\0', '');
                                    targetData.functionManager.setValue(genPwd);
                                    if (confirmPwdData && confirmPwdData.functionManager) {
                                        confirmPwdData.functionManager.setValue(genPwd);
                                    }
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Echec',
                                        html: "Echec de récupération d'un mot de passe généré <p>" + data.errorMessage + "</p>",
                                        didOpen: () => { Swal.hideLoading() }
                                    });
                                }
                            });
                        }
                        else {
                            targetData.subType = "password";
                            targetData.disabled = false;
                            targetData.functionManager.setValue("", true);
                            if (confirmPwdData) {
                                confirmPwdData.subType = "password";
                                confirmPwdData.disabled = false;
                                if (confirmPwdData.functionManager) confirmPwdData.functionManager.setValue("", true);
                            }
                        }
                    }
                    break;

                /*********** FIN GESTION COMPTES ***********/

                case "badgeStatus":
                    break;
                default:
                    break;
            }
        });
    }

    function chainEvents(data, chainKey, cptValue) {
        if (!commonFunc.isBlank(chainKey)) {
            if (data.onActionEvents && data.onActionEvents.extrasActionEvent) {
                var args = data.onActionEvents.extrasActionEvent[chainKey];
                if (args) {
                    if (!Array.isArray(args)) args = [args]; //PATCH TEMP
                    args.forEach((triggerItem) => {
                        triggerItem.altValue = cptValue;
                        onActionRequired(data.name, triggerItem, data, data.argumentsObj).then(actionResponse => {
                            console.debug("RESPONSE");
                            console.debug(actionResponse);
                            if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: actionResponse.errorMessage
                                });
                            }
                            else if (triggerItem.trigger !== chainKey) {
                                modelFctManager.chainEvents(data, triggerItem.trigger);
                            }
                        });
                    });
                }
            }
        }
    }

    function commonRenderProc(data) {
        if (data.hideOnAction && data.hideOnAction.length) {
            data.hideOnAction.forEach((args, index) => {
                onActionRequired(data.name, args, data).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }


    }

    function excludeFrag(bindProp, type) {
        if (bindProp.indexOf(".") >= 0) {
            var exParent = excludeFrag(bindProp.split(".")[0]);
            return bindProp.replace(bindProp.split(".")[0], exParent) + "_" + (type || "temp");
        }
        else {
            return bindProp + "_" + (type || "temp");
        }
    }

    function componentTyped(element, parentElemProps) {
        if (!element.title) element.title = "";
        if (!element.baseConfig) element.baseConfig = {};

        element.key = element.key || cpt;
        element.required = element.attributes && (element.attributes.required !== undefined || (element.attributes.min && parseInt(element.attributes.min) > 0));
        if (!element.argumentsObj && element.arguments) {
            if (typeof element.arguments == "string") element.argumentsObj = JSON.parse(element.arguments);
            else { element.argumentsObj = element.arguments; }
        }

        //Préparation du bindPropResult + stockage dans la liste Composant
        var camelBindProp;
        var preventLoadedData = false;
        if (!commonFunc.isBlank(element.bindPropResult)) {
            if (element.bindPropResult.indexOf("{array}") >= 0) {
                camelBindProp = commonFunc.camelize(element.bindPropResult.replace("{array}", commonFunc.camelize(parentElemProps.parentData.isInfluencedBy || parentElemProps.parentData.bindPropResult, true) + (parentElemProps.parentData.isExcluded ? "_temp" : "") + "[" + parentElemProps.parentData.key + "]"), true);
                preventLoadedData = true;
            }
            else {
                camelBindProp = commonFunc.camelize(element.bindPropResult, true);
            }
            curElementList[camelBindProp] = element;
        }
        else {
            camelBindProp = element.bindPropResult;
        }

        cpt++;

        if (!preventLoadedData && (loadedData && !commonFunc.isBlank(camelBindProp))) {
            if (!element.modalDefault) {
                element.modalDefaultValue = element.defaultValue;
                element.modalDefault = true;
            }
            //console.debug(loadedData);
            var srcValue = commonFunc.getValue(loadedData, camelBindProp);
            if (element.objType === "picture") {
                if (typeof srcValue === 'object' && !Array.isArray(srcValue) && srcValue !== null) {
                    element.defaultValue = "";//TODO : Downloadfile valide
                }
                else {
                    element.defaultValue = ((srcValue !== null && srcValue !== undefined) ? srcValue : element.defaultValue);
                }
            }
            else {
                element.defaultValue = ((srcValue !== null && srcValue !== undefined) ? srcValue : element.defaultValue);
            }
        }

        var args = {
            key: element.key,
            data: element,
            formManager: formManager,
            parentDisabled: (parentElemProps ? parentElemProps.parentDisabled : false),
            parentProps: parentElemProps,
            modelFctManager: modelFctManager,
            bindPropResult: (element.isExcluded ? excludeFrag(camelBindProp, element.subType) : camelBindProp)
        };
        switch (element.objType) {
            case "model": return <ReportTabFrag {...args} />;
            case "template": return <TabFrag {...args} />;
            case "category": return <CategoryFrag {...args} />;
            case "header": return <HeaderFrag {...args} />;
            case "group": return <GroupFrag {...args} />;
            case "itemsContainer": return <ItemsContainerFrag {...args} />;

            case "button": return <ButtonFrag {...args} />;
            case "text": return <EditTextFrag {...args} />;
            case "select": return <SpinnerFrag {...args} />;
            case "checkbox": return <CheckboxFrag {...args} />;
            case "radio": return <RadioButtonsFrag {...args} />;
            case "picture": return <PictureFrag {...args} srcFileManager={{ className: curGenerateTag, showContextMenu: showContextMenu, hideAllMenu: hideAllMenu }} />;
            case "textview": return <TextViewFrag {...args} />;
            case "finder": return <FinderFrag {...args} />;
            case "progressBar": return <ProgressBarFrag {...args} />;

            case "simpleList": return <SimpleListGeneric {...args} accessList={Object.keys(elementList).length > 0 ? elementList : curElementList} />;
            default: return "";
        }
    }


    function renderData(cfg) {
        curElementList = {};

        var content = componentTyped(cfg);

        return <Form action="" className={`form needs-validation ${curGenerateTag}`} method="" onSubmit={handleSubmit((data) => { triggerOnSubmit(data, false) })}>
            {content}
            {children}
            {noButtons ? "" :
                (<><Button className="btn-round mb-3 float-sm-start" color="primary">Créer</Button>
                {isUpdateGen ? <Button className="btn-round mb-3 float-sm-start" color="primary" onClick={() => { handleSubmit((data) => { triggerOnSubmit(data, true) })() }}>Modifier</Button> : ""}</>)
            }
            <Menu id={`contextMenu-${curGenerateTag}`} onVisibilityChange={() => {/*console.debug(document.querySelector(".contexify") ? document.querySelector(".contexify").outerHTML : "")*/ }}>

                <Item hidden={isItemHidden.bind(this)} data="genFilePhoto" onClick={filePhoto.bind(this)}>
                    <i className="fa fa-camera" aria-hidden="true"></i>&nbsp;Prendre Photo
                </Item>
                <Item hidden={isItemHidden.bind(this)} data="genFileDel" onClick={fileDel.bind(this)}>
                    <i className="fa fa-times" aria-hidden="true"></i>&nbsp;Supprimer
                </Item>
            </Menu>
        </Form>;
    }

    /*Notes :
        ReportItem :
            //Dictionnaire d'action (onChange/onLoad : évènement où l'appel va se faire | trigger : action à effectuer | target : cible de l'action | value : valeur a communiquer pour l'action)
            onActionEvents: { onChange: [{ trigger: "duplicateManualReport", target: "target1", value: "value" }], onLoad: [{ trigger: "setValue", target: "", value: "options" }] },
    
            
            defaultValue: "10",//Valeur initial du rapport
            rawValue: "2.1",//valeur brut avant formattage
            value: "2",//Valeur finale
            
            rightAccess: "MUPD",//Gestion des drotis d'accès
            isExcluded: true,//Exlusino de la récupération pour sauvegarde
            dataCache : {} //sous objet de gestion de chargement de l'item
                shouldLoadDefaultValue: true, //défini si il faut charger la valeur par défaut dans le ReportItem
                loadFromCache: true//défini si on doit charger les données depuis le cache
                loadWhenGenerating: true//défini si on doit charger les données à la génération du rapport
            schema: {} //objet basé sur GetSchemaModel


            temporary : true //Défini si il s'agit d'un ReportItem qui doit être supprimé si réinitialisation (un peu comme isCloned)
            isLoaded : true //Défini si les traitement ont déjà été effectué sur un fragment

            Design :
                Gérer une option no padding sur parent
                spacing : 1 //Gestion d'un espacement sur les enfants

    */



    const content = renderData(genModel);

    console.debug(errors);
    console.debug(getValues());


    return (content);

});

export default AGenerateModel;

export function getRgbaFromHex(hex) {
    if (hex.length == 9) {
        var alpha = parseInt(hex.slice(1, 3), 16) / 255;
        var hex = commonFunc.hexToRgb(hex.substring(3));
        var rgba = "rgba(" + hex + "," + alpha.toFixed(2) + ")";

        return rgba;
    } else {
        return commonFunc.hexToRgb(hex);
    }
}

export function convertWidthToGrid(widthPercent, withoutClass) {
    var gridValue = Math.round(widthPercent / 100 * 12);
    if (withoutClass) return gridValue;
    return " col-12 col-md-" + gridValue;
}

function SubGenModal({ t, title, configModel, dataCfg, appProps, checkRights, getRoutes, genRef, genId, genType, subType, itemData, noScroll, size, dataElem, callback }) {
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    return (
        <Modal isOpen={true} className={`modal-${itemData.subType} ${noScroll ? "modal-noscroll" : ""}`} size={size ? size : "xxl"} scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={{ overflowX: "hidden" }}>
                <AGenerateModel ref={genRef} noButtons onSubmit={(data, isUpdate, test) => callback(data, isUpdate, test)} appProps={appProps} checkRights={checkRights} getRoutes={getRoutes} parentProps={{ id: genId, type: genType, subType: subType, dataCfg: dataCfg }} keyMode={2} configModel={configModel} loadedData={dataElem} />
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                {
                    Object.keys(itemData.argumentsObj).map((key) => {
                        var button = "";
                        if (key.startsWith("button:")) {
                            button = <Button className="px-3" color="primary" onClick={() => {
                                var generateModel = genRef.current;
                                if (generateModel && generateModel.submitForm) {
                                    generateModel.submitForm(itemData.argumentsObj[key]); 
                                }
                            }}><i className="fa fa-edit"></i>{altT(key.replace("button:", ""))}</Button>;
                        }
                        return button;
                    })
                }
                {
                    dataElem ? <Button className="px-3" color="primary" onClick={() => {
                        var generateModel = genRef.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm();
                        }
                    }}><i className="fa fa-edit"></i>{t(subType + "_save")}</Button> : <Button className="px-3" color="success" onClick={() => {
                        var generateModel = genRef.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm();
                        }
                    }}><i className="fa fa-plus"></i>{t(subType + "_add")}</Button>
                }
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>{t(subType + "_cancel")}</Button>
            </ModalFooter>
        </Modal>
    );
}

function TakePhotoModal({ title, parentContext, callback }) {

    //console.debug("render modal");

    return (
        <Modal isOpen={true} className="modal-report" size="xl" keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2">
                <TakePhoto callback={callback} />
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <Button className="px-3" color="secondary" onClick={() => { callback(); }}>Fermer</Button>
            </ModalFooter>
        </Modal>
    );
}