import React, { useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody, CardFooter,
    CardTitle, CardText,
    Row,
    Col, Badge, Progress, Input,
    UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody
} from "reactstrap";

import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CChart from 'components/CChart';

import * as commonFunc from 'scripts/common';
import { proceedLogin } from 'views/pages/login/Login';

//Création du schéma pour le paramètrage du formulaire
const schema = yup.object().shape({
    login: yup.string(),
    email: yup.string(),
    refName: yup.string(),
    refFName: yup.string(),
}).required();

const emptySubs = { product: {}, status: {} };

function ManageSubscriptions(props) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    React.useEffect(() => {
        //console.debug("mount");
        if (props.updateUser) props.updateUser(function (data) {
            loadSubscription();
        });
    }, []);

    //Paramètrage du formulaire
    const { register, getValues, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    const [subscription, setSubscription] = React.useState(emptySubs);
    const [dataStamp, setDataStamp] = React.useState(Date.now());
    const [shouldRender, setShouldRender] = React.useState(false);
    const [subscriptionConsumed, setSubscriptionConsumed] = React.useState(false);
    const [subscriptionRunning, setSubscriptionRunning] = React.useState(false);
    const [userUsageInfos, setUserUsageInfos] = React.useState();

    const [moduleIsOpen, setModuleIsOpen] = React.useState(false);

    const [subscriptionUrlInfos, setSubscriptionUrlInfos] = useSearchParams();

    const navigate = useNavigate();
    const accordion = useRef();

    const lineGradientChartRef = React.useRef(null);

    function loadSubscription() {
        setSubscription(emptySubs);

        return new Promise((resolve, reject) => {
            Swal.fire({
                title: "Chargement en cours...",
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            commonFunc.getMethod("../api/GetActiveSubscription", { level: 2 }).then((response) => {
                var data = response.data;
                if (data.isSuccess) {

                    //TEMP
                    if (data.result.statusID <= 0) {
                        data.result.status = { step: 3 };
                    }

                    if ((data.result && data.result.status && data.result.status.step < 2)) {
                        refreshSubscription(data.result);
                    }
                    else {
                        commonFunc.getMethod("../api/data/GetConsumptionsStatement", null).then((responseCons) => {
                            var dataCons = responseCons.data;
                            if (dataCons.isSuccess) {
                                //console.log(data.result);

                                Swal.close();

                                if (!data.result.product) data.result.product = {};
                                if (!data.result.status) data.result.status = {};


                                if (data.result.product.items) {
                                    data.result.product.items = data.result.product.items.map((item) => {
                                        var cons = dataCons.result.filter((consItem) => consItem.tag === item.tag)
                                        if (cons.length) item.quantity = cons[0].amount;
                                        //console.debug(item.quantity);
                                        return item;
                                    });
                                }
                                //console.debug(data.result.product.items);


                                getUserUsageInfos().then((resultInfos) => {
                                    setUserUsageInfos(resultInfos);
                                });

                                setDataStamp(Date.now());
                                setSubscription(data.result);
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: "Echec de chargement de la consommation :<br>" + dataCons.errorMessage
                                });
                            }
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Echec de chargement de l'abonnement :<br>" + data.errorMessage
                    });
                }
                resolve(data);
            });
        });
    }

    function getUserUsageInfos() {
        return commonFunc.getMethod("../api/GetUserUsageStatement", { period: "Y" }).then((responseData) => {
            return new Promise(resolve => {
                var dataRes = responseData.data;
                if (dataRes.isSuccess) {

                    console.debug(dataRes.result);

                    var userLabels = [];
                    var usersDataset = [];
                    var devicesDataset = [];
                    dataRes.result.forEach((userStatement, index) => {
                        //userLabels.push(userStatement.period);
                        userLabels.push(moment(userStatement.period, "YYYY-MM").format("MMM YYYY"));

                        usersDataset.push(userStatement.usersCount);
                        devicesDataset.push(userStatement.devicesCount);
                    });


                    resolve({ userLabels: userLabels, usersDataset: usersDataset, devicesDataset: devicesDataset });
                } else {
                    /*Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModels") + " :<br>" + dataModels.errorMessage
                    });*/
                    resolve();
                }
            });
        });
    }

    async function saveUser(data) {
        //e.preventDefault();
        //console.debug(data);

        Swal.fire({
            title: "Sauvegarde en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var req = { login: data.login };
        commonFunc.postMethod("../api/UpdateSubscription", req).then((response) => {
            if (response.data.isSuccess) {
                Swal.fire({
                    icon: "success",
                    title: "Confirmation",
                    html: "Modifications apportées avec succès",
                    didOpen: () => { Swal.hideLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).then((result) => {
                    loadSubscription();
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la modification de l'abonnement :<br>" + response.data.errorMessage
                });
            }
        });
    }

    function updateOffer(e, id) {
        //navigate("/Subscription");

        window.location.href = subscription.product.url + "?chooseProduct=1&srcUrl=" + window.location.href + "&destUrl=" + window.location.origin + "/ConfirmSubscription";
        //var win = window.open(subscription.product.url + "?chooseProduct=1", "_blank");
        //win.focus();
    }

    function refreshSubscription(localSubscription) {
        if (!localSubscription) localSubscription = subscription;
        navigate("/ConfirmSubscription?productId=" + subscription.productID);
    }


    function contactSupport() {
        //TODO
    }


    function renderUsersChart(gestValue, itvValue, extValue, apiValue) {
        if (gestValue > 0 || itvValue > 0 || extValue > 0 || apiValue > 0) {
            return (<CChart type="doughnut" redraw
                className="d-inline-block align-top"
                width={"300px"}
                height={"200px"}
                data={(() => {
                    return {
                        labels: ["Gestionnaire", "Classique", "Externe", "API"],
                        datasets: [
                            {
                                label: "Comptes",
                                weight: 9,
                                cutout: 20,
                                tension: 0.9,
                                pointRadius: 2,
                                borderWidth: 2,
                                backgroundColor: ["#FB8C00", "#1A73E8", "#D81B60", "#43A047"],
                                fill: false,
                                data: [gestValue, itvValue, extValue, apiValue],
                            },
                        ],
                    };
                })()}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: "right"
                        }
                    },
                    interaction: {
                        intersect: false,
                        mode: "index",
                    },
                }} />);
        }
        else {
            return "";
        }
    }

    function renderUsersConnectionsChart() {
        var infos = userUsageInfos;

        if (infos) {
            return (<CChart ref={lineGradientChartRef} type="line" redraw
                className="d-inline-block align-top"
                style={{ width: "600px", height: "250px" }}
                data={{
                    labels: infos.userLabels,
                    datasets: [
                        {
                            label: "Utilisateurs",
                            color: "#1A73E8",
                            data: infos.usersDataset,
                        },
                        {
                            label: "Appareils",
                            color: "#D81B60",
                            data: infos.devicesDataset,
                        },
                    ],
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: "index",
                    },
                    scales: {
                        y: {
                            grid: {
                                drawBorder: false,
                                display: true,
                                drawOnChartArea: true,
                                drawTicks: false,
                                borderDash: [5, 5],
                            },
                            ticks: {
                                display: true,
                                padding: 10,
                                color: "#b2b9bf",
                                font: {
                                    size: 11,
                                    family: "Roboto",
                                    style: "normal",
                                    lineHeight: 2,
                                },
                            },
                        },
                        x: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                                borderDash: [5, 5],
                            },
                            ticks: {
                                display: true,
                                color: "#b2b9bf",
                                padding: 20,
                                font: {
                                    size: 11,
                                    family: "Roboto",
                                    style: "normal",
                                    lineHeight: 2,
                                },
                            },
                        },
                    },
                }} />);
        }
        else {
            return "";
        }
    }

    async function waitForCondition(conditionObj) {
        var start_time = new Date().getTime();
        var timeout = conditionObj.timeOut || 60000;
        var errorMessage = "";

        while (true) {
            if (conditionObj.arg == conditionObj.test || conditionObj.arg === false) {
                console.log('met');
                break; // or return
            }
            if (new Date() > start_time + timeout) {
                console.log('not met, time out');
                break; // or throw
            }

            await new Promise(resolve => setTimeout(async () => {
                //await commonFunc.postMethod("../api/report/UpdateReport", conditionObj).then((responsePay) => {
                await commonFunc.getMethod("../api/GetPaymentStatementFromInvoice", conditionObj).then((responsePay) => {
                    var data = responsePay.data;
                    if (data.isSuccess) {
                        conditionObj.arg = true;
                    }
                    else {
                        //TODO : Check errorCode pour mettre fin à la boucle
                        //conditionObj.arg = false;
                        //errorMessage = data.errorMessage;
                    }
                    resolve();
                });
            }, 2000));
        }

        if (conditionObj.arg == true) {
            Swal.fire({
                icon: "success",
                title: "Paiement effectué",
                html: "Merci pour votre paiement<br/>Votre paiement de " + conditionObj.amount + "€ a bien été pris en compte",
                didOpen: () => { Swal.hideLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            }).then((result) => {
                setSubscriptionConsumed(true);
            });
        }
        else {
            var supText = (new Date() > start_time + timeout) ? " : Timeout" : errorMessage;
            Swal.fire({
                icon: "error",
                title: "Paiement refusé",
                html: "Votre paiement a été refusé" + supText,
                didOpen: () => { Swal.hideLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            }).then((result) => {
                setSubscriptionConsumed(true);
            });
        }
    }

    function checkPayment(startTime) {
        if (!startTime) startTime = new Date().getTime();

        var paymentId = subscriptionUrlInfos.get("transId");
        var paymentUuId = subscriptionUrlInfos.get("transUuId");
        var invoiceId = subscriptionUrlInfos.get("invoiceId");
        var amount = subscriptionUrlInfos.get("amount");

        var args = { transId: paymentId, transUuId: paymentUuId, invoiceId: invoiceId, amount: amount };

        var getparams = {
            crossDomain: true,
            timeout: 0,
            headers: { }
        };
        getparams.headers["Cache-Control"] = 'no-cache';
        getparams.headers["Pragma"] = 'no-cache';
        getparams.params = args;
        axios.get("../api/CheckSubscription", getparams).then((responsePay) => {
        //commonFunc.getMethod("../api/GetPaymentStatementFromInvoice", { transId: paymentId, transUuId: paymentUuId, invoiceId: invoiceId, amount: amount }).then((responsePay) => {
            var data = responsePay.data;
            if (data.isSuccess) {
                Swal.fire({
                    icon: "success",
                    title: "Paiement effectué",
                    html: "Merci pour votre paiement<br/>Votre paiement de " + amount + "€ a bien été pris en compte",
                    didOpen: () => { Swal.hideLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).then((result) => { 
                    setSubscriptionConsumed(true);

                    Swal.fire({
                        title: "Chargement en cours...",
                        didOpen: () => { Swal.showLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });

                    if (data.result && data.result.accessToken) {
                        Swal.close();
                        proceedLogin(data.result.accessToken/*, props.parentContext.props*/).then((data) => {
                            setSubscriptionUrlInfos("");
                            window.location.reload();
                        });
                    }
                    else {
                        setSubscriptionUrlInfos("");
                        window.location.reload();
                    }
                });
            }
            else {
                //TODO : Check errorCode pour mettre fin à la boucle
                //conditionObj.arg = false;
                //errorMessage = data.errorMessage;
                if (new Date() > startTime + 60000) {
                    Swal.fire({
                        icon: "error",
                        title: "Paiement refusé",
                        html: "Votre paiement a été refusé : Timeout",
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        setSubscriptionConsumed(true);
                    });
                }
                else {
                    //setTimeout(() => { checkPayment(startTime) }, 1000);//2000
                }
            }
        });
    }

    //OK
    //transId=584550&transUuId=2401e142c34649bcba778c076db040c1&invoiceId=74&clientId=8&subId=0&userId=0&amount=360&isSuccess=True&errorMessage=Action%20réalisée%20avec%20succès.&errorCode=0&comments=contact@softena.com

    //Annulation
    //transId=0&transUuId=&invoiceId=0&clientId=0&subId=0&userId=0&amount=0&isSuccess=False&errorMessage=Annulation%20de%20l%27acheteur&errorCode=0&comments=contact@softena.com

    console.debug(subscriptionUrlInfos.get("transId"));
    if (subscription.name && subscriptionUrlInfos.get("transId") && (!Swal.isVisible() || Swal.isLoading()) && !subscriptionConsumed && !subscriptionRunning) {//TODO : voir pour le test subscription.name
        //setSubscriptionConsumed(true);
        setSubscriptionRunning(true);
        if (subscriptionUrlInfos.get("isSuccess") === "True") {
            Swal.fire({
                title: "Paiement en cours",
                html: "Merci pour votre paiement<br/>Nous traitons actuellement votre commande...",
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            /*var paymentId = subscriptionUrlInfos.get("transId");
            var paymentUuId = subscriptionUrlInfos.get("transUuId");
            var invoiceId = subscriptionUrlInfos.get("invoiceId");
            var amount = subscriptionUrlInfos.get("amount");

            waitForCondition({ transId: paymentId, transUuId: paymentUuId, invoiceId: invoiceId, amount: amount, timeOut: 60000, test: true });*/
            checkPayment();

            /*commonFunc.getMethod("../api/data/GetPaymentStatement", { id: paymentId, uuid: paymentUuId, invoiceId: invoiceId }).then((responsePay) => {
                var data = responsePay.data;
                if (data.isSuccess) {
                    Swal.fire({
                        icon: "success",
                        title: "Paiement effectué",
                        html: "Merci pour votre paiement<br/>Votre paiement de " + + "€ a bien été pris en compte",
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        //loadSubscription();
                    });
                }
                else {

                    //refaire un appel tant que isSuccess false
                    //setTimeout + annulation si isSuccess

                    //Check le code d'attente d'upload de fichier de MF

                    Swal.fire({
                        icon: "error",
                        title: "Paiement refusé",
                        html: "Votre paiement a été refusé : <br/>" + data.errorMessage,
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        //loadSubscription();
                    });
                }
            });*/
        }
        else if (subscriptionUrlInfos.get("transId") <= 0) {
            Swal.fire({
                icon: "error",
                title: subscriptionUrlInfos.get("errorMessage"),
                html: "Votre paiement a été annulé",
                didOpen: () => { Swal.hideLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            }).then((result) => {
                setSubscriptionConsumed(true); 
            });
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Erreur lors du paiement",
                html: subscriptionUrlInfos.get("errorMessage"),
                didOpen: () => { Swal.hideLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            }).then((result) => {
                setSubscriptionConsumed(true);
            });
        }
    }


    //console.debug("render");

    if (!subscription) {
        //loading
        return null;
    }
    else {
        //Convertion des dates
        var signDate = "N/A";
        if (subscription.signDate) {
            var mDate = commonFunc.restStringToDate(subscription.signDate);
            if (commonFunc.isValidDate(mDate) && mDate.getFullYear() > 1970) {
                signDate = commonFunc.formatDateDMY(moment(mDate).utc(true).toDate());
            }
        }
        var startDate = "N/A";
        if (subscription.startDate) {
            mDate = commonFunc.restStringToDate(subscription.startDate);
            if (commonFunc.isValidDate(mDate)) {
                if (mDate.getFullYear() > 1970) {
                    startDate = commonFunc.formatDateDMY(moment(mDate).utc(true).toDate());
                }
                else {
                    startDate = signDate;
                }
            }
        }
        var nearEndPeriod = false;
        var endDate = "N/A";
        if (subscription.endDate) {
            mDate = commonFunc.restStringToDate(subscription.endDate);
            if (commonFunc.isValidDate(mDate) && mDate.getFullYear() > 1970) {
                nearEndPeriod = moment(mDate).subtract(1, "weeks").isSameOrBefore(moment());
                endDate = commonFunc.formatDateDMY(moment(mDate).utc(true).toDate());
            }
        }
        var trialEndDate = "N/A";
        if (subscription.trialEndDate) {
            mDate = commonFunc.restStringToDate(subscription.trialEndDate);
            if (commonFunc.isValidDate(mDate)) {
                if (mDate.getFullYear() > 1970) {
                    trialEndDate = commonFunc.formatDateDMY(moment(mDate).utc(true).toDate());
                }
                else {
                    trialEndDate = endDate;
                }
            }
        }

        //TEMP
        //subscription.isTrial = true;

        //Gestion configuration groupes
        //backgroundCSS, foregroundCSS
        var jsonConfig = [
            { isActive: true, name: "Licenses", icon: "fa-id-badge", childs: ["CHART_USERS", "CHART_USERSCNT", "USER_COUNT", "USER0_COUNT", "USER1_COUNT", "USER2_COUNT", "USER3_COUNT", "USER5_COUNT", "DEVICE_COUNT"] },
            { isActive: true, name: "Consommation", icon: "fa-bars-progress", childs: ["CLIENT_COUNT", "SITE_COUNT", "EVENT_COUNT", "REPORT_COUNT", "MATERIAL_COUNT", "LEADS_COUNT", "EMPLOYEE_COUNT", "WEVENT_COUNT", "API_COUNT"] },
            { isActive: true, name: "Stockage", icon: "fa-hard-drive", childs: ["STORE_COUNT", "FILE_COUNT", "BLOB", "BLOBMUT", "SHARE"] },
            { isActive: true, name: "Base de données", icon: "fa-database", childs: ["DB", "DBMUT", "DBEXT", "SRV", "SRVMUT"] },
            { isActive: true, name: "Communication", icon: "fa-tower-broadcast", childs: ["EMAIL", "SMS", "NOTIF"] },
            { isActive: true, name: "Général", icon: "fa-gears", childs: ["ANDROID", "WEB", "SUPPORT", "CUSTOM", "RIGHTS", "LOG"] }
        ];

        //Gestion des valeurs pour l'alimentation des CCharts
        var gestValue = 0;
        var itvValue = 0;
        var extValue = 0;
        var apiValue = 0;
        var subProductItems = subscription.product.items;
        if (subProductItems) {
            subProductItems.sort((a, b) => a.indexDisplay - b.indexDisplay);

            var gest = subProductItems.filter((item) => item.tag === "USER3_COUNT");
            if (gest.length) {
                gestValue = gest[0].quantity;
            }
            var itv = subProductItems.filter((item) => item.tag === "USER2_COUNT");
            if (itv.length) {
                itvValue = itv[0].quantity;
            }
            var ext = subProductItems.filter((item) => item.tag === "USER1_COUNT");
            if (ext.length) {
                extValue = ext[0].quantity;
            }
            var api = subProductItems.filter((item) => item.tag === "USER5_COUNT");
            if (api.length) {
                apiValue = api[0].quantity;
            }

            //Gestion affichage
            var mutUsers = subProductItems.filter((item) => item.tag === "USER0_COUNT");
            if (mutUsers.length && gest.length) {
                gest[0].maxQuantity = 0;
            }
            if (mutUsers.length && itv.length) {
                itv[0].maxQuantity = 0;
            }
        }
        //console.debug(subProductItems);

        return (
            <>
                <div className="content">
                    <Card className="widget dash">
                        <CardHeader>
                            <CardTitle tag="h6">Abonnement</CardTitle>
                            <div className="widget-tools collapse-tool">
                                <i className="fa fa-file-contract fa-4x"></i>
                            </div>
                            <div className='borderTheme'></div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="5">
                                    <Card className="p-3">
                                        <h6 className="title">Abonnement et Facturation</h6>
                                        <div>
                                            <h5 className="title mb-2">{subscription.name}</h5>
                                            <Badge className={`w-auto`} color="secondary">{subscription.customer ? subscription.customer.email : ""}</Badge>
                                            <div>Téléphone : {subscription.customer ? ((subscription.customer.tel || subscription.customer.gsm) ? (subscription.customer.tel || subscription.customer.gsm) : "Non renseigné") : "Non renseigné"}</div>
                                            <hr className="hr-line-solid" />
                                            <div className="">{subscription.customer && subscription.customer.billingAddress ? (<>
                                                <b>Adresse de facturation :</b><br/>
                                                {!commonFunc.isBlank(subscription.customer.billingAddress.addressMain) ? (<>{subscription.customer.billingAddress.addressMain}<br/></>) : ""}
                                                {!commonFunc.isBlank(subscription.customer.billingAddress.addressPlus) ? (<>{subscription.customer.billingAddress.addressPlus}<br/></>) : ""}
                                                {!commonFunc.isBlank(subscription.customer.billingAddress.addressPlus2) ? (<>{subscription.customer.billingAddress.addressPlus2}<br/></>) : ""}
                                                {subscription.customer.billingAddress.pc + " " + subscription.customer.billingAddress.city}
                                            </>) : ""}</div>
                                            <hr className="hr-line-solid" />
                                            <div className="d-none">Prochaine facturation le : {"???"}</div>

                                            {subscription.isTrial ? <Button className="btn-round mt-4" color="success" onClick={(e) => updateOffer(e)}>Activer mon abonnement</Button>
                                                : ((subscription.status && subscription.status.step > 2) ? <Button className="btn-round mt-4" color="success" onClick={(e) => updateOffer(e)}>Changer d'offre</Button> : "")}
                                        </div>
                                    </Card>
                                </Col>
                                <Col md="1"></Col>
                                <Col md="6">
                                    <Row>
                                        <Col>
                                            <Card className=" p-3">
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <h5 className="title mb-2">{subscription.product.name || "N/A"} {subscription.product.description || "N/A"} &bull; {subscription.product.productCycle ? subscription.product.productCycle.name : "N/A"}</h5>
                                                            <Badge className={` ${subscription.status.css || ""}`} color="none">{subscription.status.name || "Aucun"}</Badge>
                                                            {subscription.isTrial ? <Badge className="purple-500" color="secondary">Démo</Badge> : ""}
                                                            {(subscription.status && subscription.status.step > 2) ?
                                                            <><CardText className="mb-1">Souscrit le : {startDate/*signDate*/}</CardText>
                                                            <CardText className="mb-1 d-none">
                                                                <span className="me-2">Actif du : {startDate || "N/A"}</span>
                                                                <span>au : {endDate || "N/A"}</span>
                                                            </CardText>
                                                            <CardText className="mb-1">
                                                                <span className={`${nearEndPeriod ? "fw-bold" : ""}`}>Expire le : {endDate || "N/A"}</span>
                                                                {nearEndPeriod ? <i className="fa fa-triangle-exclamation text-warning fa-xl ms-2"></i> : ""}
                                                            </CardText></> : ""}
                                                            {subscription.product.name ?
                                                                (subscription.status && subscription.status.step > 2) ?
                                                                    (subscription.isTrial ? <CardText>Fin de démo le : {trialEndDate}</CardText> :
                                                                        (nearEndPeriod ? <Button className="btn-round" color="danger" onClick={(e) => refreshSubscription(e)}>Renouveler mon abonnement</Button>
                                                                                : <Button className="btn-round" size="sm" color="warning" onClick={(e) => refreshSubscription(e)}>Prolonger mon abonnement</Button>))
                                                                : <Button className="btn-round" size="sm" color="warning" onClick={(e) => refreshSubscription(e)}>Finaliser mon abonnement</Button>
                                                            : ""}
                                                        </Col>
                                                        <Col className="d-flex justify-content-center">
                                                            <Card style={{ maxWidth: "250px" }}>
                                                                <img
                                                                    className="img-fluid subscription-img"
                                                                    alt="..."
                                                                    src={subscription.product.avatar || require("assets/img/bg/logooskala.png")}
                                                                    style={{ borderRadius: "12px" }}
                                                                />
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <hr className="hr-line-solid" />
                                                    <CardText>
                                                        Cycle de paiement : {subscription.cycle ? subscription.cycle.name : "N/A"}
                                                        {subscription.isAutomatic ? <Badge className="ms-2" color="secondary">Renouvellement Auto.</Badge> : ""}
                                                    </CardText>
                                                    <CardText>{subscription.product.descriptionAlt || ""}</CardText>
                                                </div>
                                            </Card>
                                            <Card className=" p-3">
                                                <h6 className="title">Modules actifs</h6>
                                                <div className="widget-tools collapse-tool" onClick={() => { setModuleIsOpen(!moduleIsOpen) }} style={{cursor:"pointer"}}>
                                                    <i className={`fa ${moduleIsOpen ? "fa-chevron-up" : "fa-chevron-down"} fa-lg mt-3`}></i>
                                                </div>
                                                <div className={`${moduleIsOpen ? "" : "d-none"}`}>
                                                    <ul>
                                                        {subProductItems ? subProductItems.map((item) => {
                                                            if (item.categ === "MOD" && !item.isOption) {
                                                                //TEMP
                                                                item.icon = "fa-book text-secondary";


                                                                return <li key={item.idItem}>{item.icon ? <i className={`fa ${item.icon}`}></i> : ""} {item.name}</li>
                                                            }
                                                            else {
                                                                return "";
                                                            }
                                                        }) : ""}
                                                    </ul>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="d-none">
                                <Col md="5">
                                    <Card>
                                        <img
                                            className="img-fluid"
                                            alt="..."
                                            src={subscription.product.avatar || require("assets/img/bg/backblurt3.jpg")}
                                            style={{borderRadius: "12px"}}
                                            />
                                    </Card>
                                    <CardText><i>Infos : isActive/isDedicated/isInternal/customer/Site/tenant | pas : quantity</i></CardText>
                                    <CardText><i>Infos Product : embedVideo/isActive/isBundle/isOffer/isSpecial/itemsAssigned/prices/name/specialDesc/themeClass/url</i></CardText>
                                    <CardText><i>Infos Items : avatar/category/description/descriptionAlt/indexDisplay/isBillable/isBundle/mainItem/parent/prices/products/productsAssigned</i></CardText>
                                </Col>
                                <Col md="1"></Col>
                                <Col md="6">
                                    <Row>
                                        <Col>
                                            <h5 className="title">{subscription.name}</h5>
                                            <Badge className={` ${subscription.status.css || ""}`} color="none">{subscription.status.name || "Aucun"}</Badge>
                                            {subscription.isTrial ? <Badge className="purple-500" color="secondary">Démo</Badge> : ""}
                                            <CardText>{subscription.product.description || "N/A"} &bull; {subscription.product.productCycle ? subscription.product.productCycle.name : "N/A"}</CardText>
                                            <CardText>Souscrit le : {signDate}</CardText>
                                            <CardText>
                                                <span className="me-2">Actif du : {startDate || "N/A"}</span>
                                                <span>au : {endDate || "N/A"}</span>
                                            </CardText>
                                            {subscription.isTrial ? <CardText>Fin de démo le : {trialEndDate}</CardText> : ""}
                                            <hr className="hr-line-solid" />
                                            <CardText>
                                                Cycle de paiement : {subscription.cycle ? subscription.cycle.name : "N/A"}
                                                {subscription.isAutomatic ? <Badge className="ms-2" color="secondary">Renouvelement Auto.</Badge> : ""}
                                            </CardText>
                                            <CardText>{subscription.product.descriptionAlt || ""}</CardText>
                                            Modules :
                                            <ul>
                                                {subProductItems ? subProductItems.map((item) => {
                                                    if (item.categ === "MOD" && !item.isOption) {
                                                        //TEMP
                                                        item.icon = "fa-book text-secondary";


                                                        return <li key={item.idItem}>{item.icon ? <i className={`fa ${item.icon}`}></i> : ""} {item.name}</li>
                                                    }
                                                    else {
                                                        return "";
                                                    }
                                                }) : ""}
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr className="hr-line-solid" />

                            {//Rendering des groupes
                            (subscription && subscription.status && subscription.status.step >= 2) ?
                                jsonConfig.map((grp, index) => {
                                    return grp.isActive ? (<Col key={index} lg="12">
                                        <Card className={`card-stats ${grp.backgroundCSS} ${grp.foregroundCSS}`} style={{ background: "url(" + grp.backgroundCSS + ")", backgroundSize: "cover" }}>
                                            <CardBody>
                                                <Row>
                                                    <Col className="" lg="12">
                                                        <div className="numbers text-start">
                                                            <p className="card-category2"><i className={`fa ${grp.icon} me-2`} />{grp.name}</p>
                                                        </div>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {//Rendering des items (maxQuantity > 0)
                                                        subProductItems ? subProductItems.map((funItem, index) => {
                                                            if (grp.childs.indexOf(funItem.tag) >= 0 && funItem.maxQuantity > 0) {
                                                                if (funItem.maxQuantity > 0) funItem.progress = (funItem.quantity * 100 / funItem.maxQuantity);
                                                                else funItem.progress = 0;

                                                                funItem.isActive = funItem.configItems.filter((cfgItem) => cfgItem.isActive).length > 0;
                                                                var pricesItems = funItem.prices ? funItem.prices.filter((price) => price.categ === "FIXED") : [];
                                                                funItem.price = pricesItems.length ? pricesItems[0].upet : 0;

                                                                //funItem.description = funItem.price ? ((funItem.price + " " + (pricesItems.length ? pricesItems[0].currency : "€")) + " " + funItem.description) : funItem.description;
                                                                funItem.count = funItem.quantity || 0;//count = quantity ???

                                                                var switchActive = (e) => {
                                                                    funItem.isActive = !funItem.isActive;
                                                                    setShouldRender(!shouldRender);
                                                                };

                                                                return (<Col data-name={funItem.tag} key={funItem.idItem} xl="3" lg="3" md="6" sm="6" className={`${funItem.isOption ? "item-is-option" : ""}`}>
                                                                    <Card className={`card-stats ${funItem.backgroundCSS} ${funItem.foregroundCSS}`} style={{ background: "url(" + funItem.backgroundCSS + ")", backgroundSize: "cover" }}>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col md="3" xs="4">
                                                                                    <div className="icon-big text-start ps-3">
                                                                                        <i className={`fa ${funItem.icon}`} />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md="9" xs="8">
                                                                                    <div className="numbers">
                                                                                        <p className="card-category">
                                                                                            {funItem.isOption ? <i className={`fa fa-circle-check text-secondary`} title="Option" /> : ""}
                                                                                            {funItem.progress > 100 ? <i className="fa fa-triangle-exclamation text-warning" title="Dépassement"></i> : ""}
                                                                                            {funItem.name}
                                                                                        </p>
                                                                                        <CardTitle className={`${funItem.count === 0 ? "invisible" : ""}`} tag="p">{funItem.count}</CardTitle>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Progress className={`w-100 float-end ${funItem.maxQuantity <= 0 ? "invisible" : ""}`} striped={funItem.progress >= 100} color={commonFunc.inverseCreateProgressBar(funItem.progress)} style={{ height: '15px' }} value={funItem.progress} />
                                                                        </CardBody>
                                                                        <CardFooter>
                                                                            <hr className={`${funItem.description ? "" : "invisible"}`} />
                                                                            <span className={`stats ${funItem.description ? "" : "invisible"}`}>
                                                                                {funItem.maxQuantity > 0 ? (funItem.maxQuantity + " MAX - ") : ""}{funItem.description}
                                                                            </span>
                                                                            <div className={`d-inline-block float-end ${/*funItem.price ? "" :*/ "invisible"}`}>
                                                                                {/*funItem.canChangeOnline ?
                                                                                        <><Button className="btn-icon btn-simple btn-round btn-sm" color="dark"><i className="fa fa-minus"></i></Button>
                                                                                            <Input key={dataStamp} className="input-quantity" defaultValue={0} />
                                                                                            <Button className="btn-icon btn-simple btn-round btn-sm" color="dark"><i className="fa fa-plus"></i></Button></>
                                                                                        : ""*/}
                                                                                {funItem.canChangeOnline ? (funItem.isActive ? <Button className="btn-sm" color="danger" onClick={switchActive}>Inactif</Button> : <Button className="btn-sm" color="success" onClick={switchActive}>Actif</Button>)
                                                                                    : <Button className="btn-sm" color="primary" onClick={contactSupport}>Contacter support</Button>}
                                                                            </div>
                                                                        </CardFooter>
                                                                    </Card>
                                                                </Col>);
                                                            }
                                                            else {
                                                                return "";
                                                            }
                                                        }) : ""}
                                                </Row>
                                                {//Rendering des CCharts
                                                    (grp.childs.indexOf("CHART_USERS") >= 0 || grp.childs.indexOf("CHART_USERSCNT") >= 0) ?
                                                        (<Row>
                                                            {grp.childs.indexOf("CHART_USERS") >= 0 ? renderUsersChart(gestValue, itvValue, extValue, apiValue) : ""}
                                                            {grp.childs.indexOf("CHART_USERSCNT") >= 0 ? renderUsersConnectionsChart() : ""}
                                                        </Row>) : ""
                                                }
                                            </CardBody>
                                            <CardFooter>
                                                <Row className="flex-column">
                                                    {//Rendering des items (maxQuantity <= 0)
                                                        subProductItems ? subProductItems.map((funItem, index) => {
                                                            if (grp.childs.indexOf(funItem.tag) >= 0 && funItem.maxQuantity <= 0) {
                                                                if (funItem.maxQuantity > 0) funItem.progress = (funItem.quantity * 100 / funItem.maxQuantity);
                                                                else funItem.progress = 0;

                                                                funItem.isActive = funItem.configItems.filter((cfgItem) => cfgItem.isActive).length > 0;
                                                                var pricesItems = funItem.prices ? funItem.prices.filter((price) => price.categ === "FIXED") : [];
                                                                funItem.price = pricesItems.length ? pricesItems[0].upet : 0;

                                                                //funItem.description = funItem.price ? ((funItem.price + " " + (pricesItems.length ? pricesItems[0].currency : "€")) + " " + funItem.description) : funItem.description;
                                                                funItem.count = funItem.quantity || 0;//count = quantity ???


                                                                var switchActive = (e) => {
                                                                    funItem.isActive = !funItem.isActive;
                                                                    setShouldRender(!shouldRender);
                                                                };

                                                                return (<Col key={funItem.idItem} lg="3" className={`${funItem.isOption ? "item-is-option" : ""}`}>
                                                                    <Card className={`mb-2 ${funItem.backgroundCSS} ${funItem.foregroundCSS}`} style={{ background: "url(" + funItem.backgroundCSS + ")", backgroundSize: "cover" }}>
                                                                        <CardBody className="d-flex align-items-center p-1">
                                                                            <div className="ps-2 me-auto">
                                                                                <p className="m-0">
                                                                                    <i className={`fa ${funItem.icon} me-2`} />
                                                                                    {funItem.isOption ? <i className={`fa fa-circle-check text-secondary`} title="Option" /> : ""}
                                                                                    {funItem.progress > 100 ? <i className="fa fa-triangle-exclamation text-warning" title="Dépassement"></i> : ""}
                                                                                    {funItem.name}
                                                                                </p>
                                                                                <p className={`m-0 ${funItem.count === 0 ? "text-white" : ""}`} tag="p">{funItem.count}</p>
                                                                            </div>
                                                                            <div className={`${/*funItem.price ? "" :*/ "invisible"}`}>
                                                                                {funItem.canChangeOnline ? (funItem.isActive ? <Button className="btn-sm m-0" color="danger" onClick={switchActive}>Inactif</Button> : <Button className="btn-sm m-0" color="success" onClick={switchActive}>Actif</Button>)
                                                                                    : <Button className="btn-sm m-0" color="primary" onClick={contactSupport}>Contacter support</Button>}
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>);
                                                            }
                                                            else {
                                                                return "";
                                                            }
                                                        }) : ""}
                                                </Row>
                                                <hr className={`${grp.description ? "" : "d-none"}`} />
                                                <span className={`stats ${grp.description ? "" : "d-none"}`}>{grp.description}</span>
                                            </CardFooter>
                                        </Card>
                                    </Col>) : "";
                                })
                            : ""}
                        </CardBody>
                    </Card>
                    <Card className="floating-group p-2 d-none" style={{ backgroundColor:"rgba(255,255,255,0.7)"}}>
                        <div>
                            <h5 className="text-end">0 €</h5>
                            <Button className="btn-round" color="success" onClick={(e) => updateOffer(e)}>Changer mon abonnement</Button>
                            <Button className="btn-round" color="primary">Mettre à jour mon abonnement</Button>
                        </div>
                    </Card>
                </div>
            </>
        );
    }
}

export default ManageSubscriptions;
