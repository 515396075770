import React from 'react';
import { useLocation } from "react-router-dom";
import { Container } from 'reactstrap';

import moment from "moment";
import Swal from "sweetalert2";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import NavMenu from './NavMenu';
import AuthMenu from './AuthMenu';

import Footer from "components/layout/Footer.js";
import Sidebar from "components/layout/Sidebar.js";

var ps;

const Layout = React.forwardRef((props, ref) => {
    const location = useLocation();


    const [scroller, setScroller] = React.useState();

    const mainPanel = React.useRef();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanel.current, { suppressScrollX: true, useBothWheelAxes: false, wheelPropagation: true });
            setScroller(ps);
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.className += " perfect-scrollbar-off";
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        };
    }, []);
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainPanel.current.scrollTop = 0;
    }, [location]);
    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };


    React.useImperativeHandle(ref, () => ({
        getScroller: () => { return scroller/*mainPanel.current*/ },//getScroller().element pour acc�der au innerHtml
    }));


    if (props.isLoggedIn) {
        return (
            <div className="wrapper">
                <Sidebar
                    {...props}
                    bgColor="black"
                    activeColor="info"
                    logOut={props.logOut}
                />
                <div className="main-panel" ref={mainPanel}>
                    <NavMenu {...props} {...location} handleMiniClick={handleMiniClick} />
                    <div className="content px-3">
                        <Container tag="main" fluid="true">
                            {props.children}
                        </Container>
                    </div>
                    {
                        // we don't want the Footer to be rendered on full screen maps page
                        location.pathname.indexOf("full-screen-map") !== -1 ? null : (
                            <Footer fluid default={true} />
                        )
                    }
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="wrapper wrapper-full-page">
                <Sidebar
                    {...props}
                    bgColor="black"
                    activeColor="info"
                />
                <div className="main-panel2" ref={mainPanel}>
                    <AuthMenu {...props} {...location} handleMiniClick={handleMiniClick} />
                    <Container tag="main" fluid="true">
                        <div className="wrapper wrapper-full-page" style={{ overflow: "hidden" }}>
                            <div className="full-page section-image">
                                {props.children}
                                {
                                    // we don't want the Footer to be rendered on full screen maps page
                                    location.pathname.indexOf("full-screen-map") !== -1 ? null : (
                                        <Footer fluid default={true} />
                                    )
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
});

export default Layout;

