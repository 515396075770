import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { useTranslation } from "react-i18next";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

import ReCAPTCHA from "react-google-recaptcha";


// reactstrap components
import {
    Card, CardHeader,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
    Label,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

import StepUser from "views/pages/register/steps/RegisterStepUser.js";
import StepCompany from "views/pages/register/steps/RegisterStepCompany.js";
import StepPayment from "views/pages/register/steps/RegisterStepPayment.js";
import StepPwd from "views/pages/register/steps/RegisterStepPwd.js";
import StepEmail from "views/pages/register/steps/RegisterStepEmail.js";

import * as commonFunc from 'scripts/common';

function Register(props) {
    const { t } = useTranslation();

    React.useEffect(() => {
        document.body.classList.toggle("register-page");
        return function cleanup() {
            document.body.classList.toggle("register-page");
        };
    });

    const recaptchaRef = React.useRef();

    const [currStep, setCurrStep] = React.useState("1");
    const [step1, setStep1] = React.useState({});
    const [step2, setStep2] = React.useState({});
    const [step3, setStep3] = React.useState({});
    const [step4, setStep4] = React.useState({});
    const [triggerAnimate, setTriggerAnimate] = React.useState(true);

    const [currentSubscription, setCurrentSubscription] = React.useState();
    const [signupUrlInfos, setSignupUrlInfos] = useSearchParams();

    const navigate = useNavigate();

    /*function verifyCallback(response) {
        if (response) {
            console.log("g-recaptcha-response verifyCallback Success ");
            var userCaptcha = {};
            userCaptcha.captchaID = response;
            userCaptcha.captchaType = "Google";
            userCaptcha.captchaKey = "g-recaptcha-response";
            userCaptcha.captchaID = response;
            var ctrl = $("#gCaptcha");
            ctrl.removeData("response");
            ctrl.data("response", userCaptcha);
            ctrl.removeClass("error").addClass("success");
            $("#submitAccount").tooltip('hide');
        } else {
            console.error("g-recaptcha-response verifyCallback Failure");
        }
    };

    function expiredCallback(response) {
        console.log("Google Captcha expiredCallback");
        $("#gCaptcha").removeData("response");
        $("#gCaptcha").removeClass("success").addClass("error");
    }*/

    React.useEffect(() => {
        Swal.fire({
            title: "Récupération en cours...   ",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        var productId = parseInt(signupUrlInfos.get("productId")) || 10;//10 >> Correspond à la version trial
        commonFunc.getMethod("../api/GetProductOffers", { id: productId }).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {
                    Swal.close();

                    if (response.data.result && response.data.result.length) {
                        setCurrentSubscription(response.data.result[0]);
                    }
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de la récupération de l'offre sélectionné :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la récupération de l'offre sélectionné"
                });
            }
        });

    }, []);

    function prevStep(targetStep) {
        setTriggerAnimate(false);
        setTimeout(() => {
            setCurrStep(targetStep);
        }, 900);
    }

    function updateStep(data, step) {
        setTriggerAnimate(false);
        setTimeout(async () => {
            switch (step) {
                case 1:
                    setStep1(data);
                    setCurrStep("2");
                    break;
                case 2:
                    setStep2(data);
                    setCurrStep("3");
                    break;
                case 3:
                    setStep3(data);
                    setCurrStep("4");
                    break;
                case 4:
                    setStep4(data);

                    data.recaptcha = await recaptchaRef.current.executeAsync();
                    getCurrentBrowserFingerPrint().then((fingerprint) => {
                        data.deviceId = fingerprint;
                        data.productId = parseInt(signupUrlInfos.get("productId")) || 10;//-1 //10 >> Correspond à la version trial
                        triggerSubmit(Object.assign({}, step1, step2, step3, data));
                    });
                    break;
                default: break;
            }
        }, 900);
    }

    /*function validateFormCreaContact(form) {
        if (model && model.ID > 0) {

            let address = {
                Address: $("#adresseEntreprise").val().trim(),
                AddressPlus: ($("#complementAdresseEntreprise").val() || " "),
                PC: parseInt($("#codePostalEntreprise").val()),
                City: $("#villeEntreprise").val().trim(),
                Country: $("#paysEntreprise").val().trim(),
                Categ: "INVOICE"
            }

            console.debug(address);

            let contact = {
                Title: $(form).find("input[name=titre]:checked").val().trim(),
                Name: $("#contactNom").val().trim(),
                FirstName: $("#contactPrenom").val().trim(),
                Gsm: $("#telephonePortable").val().trim(),
                Email: $("#mail").val().trim(),
                ContactType: "Contact Client",
                Role: "CLIENT",
                //password: $("#password").val().trim(),
                //companyName: $("#entrepriseNom").val().trim(),
                Address: address,
                IsDefault: true,
            }

            console.debug(contact);

            var tabContact = new Array();
            tabContact.push(contact);

            var tabAbonnement = new Array();
            tabAbonnement.push($("#aboId").attr("value"));

            let company = {
                Name: $("#entrepriseNom").val().trim(),
                FirstName: ".",
                Address: address,
                //legalStatus: $("#formeJuridique").val(),
                CustomSiret: $("#numeroSiret").val().trim(),
                //CContacts: tabContact,
                //CAbonnement: tabAbonnement,
                MainContact: contact
            };

            console.debug(company);


            Swal.fire('Enregistrement de votre compte...');

            company.SubScriptionID = model.ID;

            //RegisterClient
            return postMethod("../api/SubScription/RegisterAndSubscribe", company, function (result) {

                if (result.isSuccess) {
                    Swal.fire(
                        'Votre compte est enregistré.',
                        '',
                        'success'
                    );
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: result.errorMessage,
                        text: '',
                    });
                }

            });
        }

    }*/

    async function triggerSubmit(data) {
        //e.preventDefault();

        if (false) {
            //CAPTCHA
        } else {
            Swal.fire({
                title: "Enregistrement de votre inscription...",
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });


            //TODO : use data
            var userAccountReq = data;

            /*var userAccountReq = {
                userFstName: data.firstName,
                userLastName: data.lastName,
                userEmail: data.email,
                userPwd: data.password,
                //userCaptcha: $("#gCaptcha").data("response");
            };*/

            //userAccountReq.userAvatar = '';
            //userAccountReq.userAuthProvider = 'Softena';

            await commonFunc.postMethod("../api/RegisterSubscription", userAccountReq).then((response) => {
                if (response.data && response.data.isSuccess) {
                    // Récupération du token JWT depuis le localStorage
                    // const token = localStorage.getItem('token');

                    // Inclusion du token JWT dans les en-têtes de la requête
                    const token = response.data.result.accessToken;
                    localStorage.setItem('token', token);
                    axios.defaults.headers['Authorization'] = `Bearer ${token}`;

                    Swal.close();
                    Swal.fire({
                        title: "Récupération en cours...   ",
                        didOpen: () => { Swal.showLoading() },
                    });

                    commonFunc.getMethod("../api/GetUser", null).then((response) => {
                        if (response.data) {
                            if (response.data.isSuccess) {
                                Swal.close();
                                props.setLoggedStatus(true);

                                if (!(currentSubscription.isSpecial && currentSubscription.categ === "TRIAL")) {
                                    navigate("/ConfirmSubscription?productId=" + currentSubscription.idProduct);
                                }
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Erreur",
                                    html: "Erreur lors de la récupération de l'utilisateur :<br>" + response.data.errorMessage
                                });
                            }
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de la récupération de l'utilisateur"
                            });
                        }
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Echec d'enregistrement :<br>" + response.data.errorMessage
                    }).then(() => {
                        window.location.reload();
                    });
                    //grecaptcha.reset();
                    //expiredCallback();
                }
            });

        }

    }
    console.debug(Object.assign({}, step1, step2, step3, step4));
    console.debug(currentSubscription);

    var renderingInfos = (currentSubscription ? <>
        <div className="info-area info-horizontal mt-4">
            <div className="icon icon-primary d-none">
                <i className="fa fa-tv" />
            </div>
            <div className={`description subscription-${currentSubscription.themeClass}`}>
                <h5 className="info-title">{currentSubscription.name}&nbsp;{currentSubscription.description}</h5>
                <h6 className="info-title">{currentSubscription.productCycle ? currentSubscription.productCycle.name : ""}</h6>
                <Card style={{ maxWidth: "200px" }}>
                    <img
                        className="img-fluid subscription-img"
                        alt="..."
                        src={currentSubscription.avatar || require("assets/img/bg/logooskala.png")}
                        style={{ borderRadius: "12px" }}
                    />
                </Card>
                <p className="description">{currentSubscription.descriptionAlt}</p>
                <div className="ms-3">
                    <Button className={`btn-round btn-self-magnify ${currentSubscription.themeClass}`} color="primary" onClick={() => {
                        var win = window.open(currentSubscription.url, "_blank");
                        win.focus();
                    }}>{t("register_checkOffers")}</Button>
                </div>
            </div>
        </div>
    </>
    : <div className="d-none">
        <div className="info-area info-horizontal mt-5">
            <div className="icon icon-primary">
                <i className="fa fa-tv" />
            </div>
            <div className="description">
                <h5 className="info-title">{t("register_title1")}</h5>
                <p className="description">{t("register_text1")}</p>
            </div>
        </div>
        <div className="info-area info-horizontal">
            <div className="icon icon-primary">
                <i className="fa-brands fa-html5" />
            </div>
            <div className="description">
                <h5 className="info-title">{t("register_title2")}</h5>
                <p className="description">{t("register_text2")}</p>
            </div>
        </div>
        <div className="info-area info-horizontal">
            <div className="icon icon-info">
                <i className="fa fa-atom" />
            </div>
            <div className="description">
                <h5 className="info-title">{t("register_title3")}</h5>
                <p className="description">{t("register_text3")}</p>
            </div>
        </div>
    </div>);


    return (
        <div className="register-page">
            <Container className="mt-0">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LfJ5KcpAAAAAFwA7DM-smKf4sfJmq6FHDF-Zt9q"
                />
                <Row className="align-items-center">
                    <Col className="ms-auto d-none d-lg-block" lg="5">
                        {renderingInfos}
                    </Col>
                    <Col className={`mx-auto ${triggerAnimate ? "animate__animated animate__bounceInDown" : "animate__animated animate__bounceOut"}`} lg="5" md="12">
                        <Card className={`card-signup text-center`}>
                            <CardHeader className="mb-2 pt-2" style={{ backgroundColor:"#00000008", borderBottom: "1px solid rgba(0, 0, 0, .125)" }}><h3 className="mb-1">{t("register_reg")}</h3></CardHeader>
                            <Row>
                                <div className="col-8 mx-auto d-flex justify-content-between">
                                    <div className={`${currStep === "1" ? "bg-step-progress text-light" : "border border-step-progress text-step-progress"} rounded-circle rond align-items-center d-flex justify-content-center fw-bold`}><span className="sizing">1</span></div>

                                    <div className={`${currStep === "2" ? "bg-step-progress text-light" : "border border-step-progress text-step-progress"} rounded-circle rond align-items-center d-flex justify-content-center fw-bold`}><span className="sizing">2</span></div>

                                    <div className={`${currStep === "3" ? "bg-step-progress text-light" : "border border-step-progress text-step-progress"} rounded-circle rond align-items-center d-flex justify-content-center fw-bold`}><span className="sizing">3</span></div>

                                    <div className={`${currStep === "4" ? "bg-step-progress text-light" : "border border-step-progress text-step-progress"} rounded-circle rond align-items-center d-flex justify-content-center fw-bold`}><span className="sizing">4</span></div>
                                </div>
                            </Row>
                            <TabContent activeTab={currStep}>
                                <TabPane tabId="1">
                                    <StepEmail shouldRender={currStep === "1"} defaultData={step1} updateStep={(data) => updateStep(data, 1)} triggerAnimate={() => setTriggerAnimate(true)} />
                                </TabPane>
                                <TabPane tabId="2">
                                    <StepPwd shouldRender={currStep === "2"} defaultData={step2} isFrench={step1.country === "France"} updateStep={(data) => updateStep(data, 2)} prevStep={() => { prevStep("1") }} triggerAnimate={() => setTriggerAnimate(true)} />
                                </TabPane>
                                <TabPane tabId="3">
                                    <StepUser shouldRender={currStep === "3"} defaultData={step3} isFrench={step1.country === "France"} updateStep={(data) => updateStep(data, 3)} prevStep={() => { prevStep("2") }} triggerAnimate={() => setTriggerAnimate(true)} />
                                </TabPane>
                                <TabPane tabId="4">
                                    <StepCompany shouldRender={currStep === "4"} defaultData={step4} isFrench={step1.country === "France"} updateStep={(data) => updateStep(data, 4)} prevStep={() => { prevStep("3") }} triggerAnimate={() => setTriggerAnimate(true)} />
                                </TabPane>
                            </TabContent>
                            <Label className="text-mandatory text-end fw-bold pe-3 h6 mb-2">{t("register_reqFields")}</Label>
                            {currStep === "1" ? <Link className="link-secondary text-start ms-2 mb-2" to="/login">{t("register_accessLogin")}</Link> : ""}
                        </Card>
                    </Col>
                    <Col className="d-block d-lg-none" md="12">
                        {renderingInfos}
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("assets/img/bg/backblur22.jpg")})`,
                }}
            />
        </div>
    );
}

export default Register;
