import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from "axios";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

import {
    Button,
    Input, Label,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Modal, ModalHeader, ModalBody, ModalFooter,
    InputGroup, InputGroupText,
    Progress,
} from "reactstrap";

import { Menu, Item, Separator, useContextMenu } from "react-contexify"
import "react-contexify/dist/ReactContexify.css";

import Dropzone from 'react-dropzone'

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Img from 'components/Img';
import * as commonFunc from 'scripts/common';
import * as filesFunc from 'scripts/DTAFiles';


var filesType = [];
var modalRoot;

function FilesCustom(globalProps) {
    React.useEffect(() => {
        console.debug("mount");
        //loadFilesType
        if (globalProps.altFilesTypes) {
            commonFunc.getMethod(globalProps.altFilesTypes, null).then((response) => {
                if (response.data && response.data.isSuccess) {
                    filesType = response.data.result;
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Echec de chargement des Types de Fichier :<br>" + response.data.errorMessage
                    });
                }
            });
        }
        else {
            filesType = [];
        }

        return () => {
            //console.debug("unmount");
            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }
        }
    }, []);

    React.useEffect(() => {
        if (globalProps.shouldRender) {
            loadFiles();
        }
        else {
            setFiles([]);
            setTotLength(0);
            setTotFiles(0);
            hideAllMenu();
        }
    }, [globalProps]);

    const [files, setFiles] = React.useState([]);
    const [totLength, setTotLength] = React.useState(0);
    const [totFiles, setTotFiles] = React.useState(0);
    const [fileType, setFileType] = React.useState("*");
    const [folderType, setFolderType] = React.useState("*");

    const showContextMenu = useContextMenu().show;
    const hideAllMenu = useContextMenu().hideAll;


    React.useEffect(() => {
        searchFiles();
    }, [fileType, folderType]);

    function loadFiles(bindID, fileTypeID, src) {
        setFiles([]);
        setTotLength(0);
        setTotFiles(0);

        if (!bindID) bindID = globalProps.bindID;
        if (!fileTypeID) fileTypeID = globalProps.fileTypeID;
        if (!src) src = globalProps.src;
        hideAllMenu();
        if (!globalProps.files) {
            if (bindID > 0) {
                filesFunc.loadFilesLst(bindID, fileTypeID, { srcElem: src, className: globalProps.className, showContextMenu: showContextMenu, hideAllMenu: hideAllMenu, altFilesUrl: globalProps.altFilesUrl, renderingBadge: globalProps.badgeRendering })
                    .then((result) => {
                        if (result) {
                            setFiles(result.files);
                            setTotLength(result.length);
                            setTotFiles(result.nbFiles);
                        }
                    });
            }
        }
        else {
            var removeFileProc = (file) => {
                if (file.instanceFile) {
                    if (globalProps.files) {
                        var newFiles = globalProps.files;
                        var filteredFiles = newFiles.filter((cfile) => {
                            return cfile.idFile !== parseInt(id);
                        });
                        globalProps.setFiles(filteredFiles);
                    }

                    loadFiles();
                }
                else {
                    var id = file.idFile || 0;
                    var title = commonFunc.cellFormatterFileType(file.name);
                    if (id > 0) {
                        Swal.fire({
                            icon: "question",
                            title: "Suppression",
                            html: "Etes-vous sûr de vouloir supprimer ce fichier ?<br/>[" + id + "] " + title,
                            showCancelButton: true,
                            confirmButtonColor: "#28a745",
                            cancelButtonColor: "#dc3545",
                            confirmButtonText: "Oui",
                            cancelButtonText: "Non",
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Suppression du Fichier en cours...',
                                    didOpen: () => { Swal.showLoading() }
                                });
                                removeFile(id, parseInt(file.fileTypeID), parseInt(file.bindID)).then((response) => {
                                    var data = response.data;
                                    Swal.close();
                                    if (data && data.isSuccess) {

                                        if (globalProps.files) {
                                            var newFiles = globalProps.files;
                                            var filteredFiles = newFiles.filter((cfile) => {
                                                return cfile.idFile !== parseInt(id);
                                            });
                                            globalProps.setFiles(filteredFiles);
                                        }

                                        loadFiles();
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Echec",
                                            html: "Echec de Suppression :<br>" + data.errorMessage
                                        });
                                    }
                                });
                            }
                        });
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: "Suppression impossible"
                        });
                    }
                }
            };

            filesFunc.convertFiles(globalProps.files, bindID, fileTypeID, { srcElem: src, className: globalProps.className, showContextMenu: showContextMenu, hideAllMenu: hideAllMenu, altFilesUrl: globalProps.altFilesUrl, renderingBadge: globalProps.badgeRendering, removeFile: removeFileProc })
                .then((result) => {
                    if (result) {
                        setFiles(result.files);
                        setTotLength(result.length);
                        setTotFiles(result.nbFiles);
                    }
                });
        }
    }

    function removeFile(fileID, fileTypeID, bindID) {
        var req = { fileID: fileID, fileTypeID: fileTypeID, bindID: bindID, bindType: globalProps.fileTypeID, OpName: "REMOVE_" + globalProps.fileTypeID + "FILE", isPermanent:true };
        if (req.fileID && req.bindID) {
            var url = globalProps.altRemoveFilesUrl || "../api/data/RemoveFile";
            return commonFunc.postMethod(url, req);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Echec",
                html: "Echec de Suppression : Arguments invalides"
            });
        }
        return false;
    }

    function isItemHidden({ props, data, triggerEvent }) {
        var file = props;

        var files = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = files.length;

        if (cptf > 1) {
            //if (data === "aupdActFRes") return true;
            if (data !== "aupdActFDel") return true;
        }
        else {
            if (file.categ === "SAV") {
                if (data === "aupdActFModRpt") return true;
                if (data === "aupdActFModHtml") return true;
                if (data === "aupdActFRpiDef") return true;
                if (data === "aupdActFRpiReplay") return true;
            }
            else if (file.categ === "RDI" || file.categ === "RPT") {
                if (data === "aupdActFModRpt") return true;
                if (data === "aupdActFModHtml") return true;
                if (data === "aupdActFRes") return true;
                if (data === "aupdActFDefault") return true;
            }
            else if (file.categ === "MOD") {
                if (data === "aupdActFRpiDef") return true;
                if (data === "aupdActFRpiReplay") return true;
                if (data === "aupdActFRes") return true;
                if (data === "aupdActFDefault") return true;
            }
            else {
                if (data === "aupdActFModRpt") return true;
                if (data === "aupdActFModHtml") return true;
                if (data === "aupdActFRpiDef") return true;
                if (data === "aupdActFRpiReplay") return true;
                if (data === "aupdActFRes") return true;
                if (data === "aupdActFDefault") return true;
            }
        }

        return false;
    }

    function fileDel({ sysEvent, props, triggerEvent, data }) {
        var file = props;
        var dataItem = data;

        var filesDOM = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = filesDOM.length;

        if (cptf == 1) {

            var id = file.idFile || 0;
            var title = commonFunc.cellFormatterFileType(file.name);
            hideAllMenu();
            if (id > 0) {
                //Suppression fichier local
                if (file.instanceFile) {
                    if (globalProps.files) {
                        var newFiles = globalProps.files;
                        var filteredFiles = newFiles.filter((cfile) => {
                            return cfile.idFile !== parseInt(id);
                        });
                        globalProps.setFiles(filteredFiles);
                    }

                    loadFiles();
                }
                //Suppression fichier stocké
                else {

                    Swal.fire({
                        icon: "question",
                        title: "Suppression",
                        html: "Etes-vous sûr de vouloir supprimer ce fichier ?<br/>[" + id + "] " + title,
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Suppression du Fichier en cours...',
                                didOpen: () => { Swal.showLoading() }
                            });
                            removeFile(id, parseInt(file.fileTypeID), parseInt(file.bindID)).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    loadFiles();
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de Suppression :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                    });
                }
            } else {

            }
        } else if (cptf > 1) {
            //Multiple delete


            //Suppression fichier local
            if (file.instanceFile) {
                if (globalProps.files) {
                    var ids = [];
                    filesDOM.forEach((elem, index) => {
                        var id = parseInt(elem.parentNode.getAttribute("data-fileid")) || 0;
                        if (id > 0) {
                            ids.push(id);
                        }
                    });
                    console.debug(ids);
                    var newFilesMD = globalProps.files;
                    var filteredFilesMD = newFilesMD.filter((cfile) => {
                        return ids.indexOf(cfile.idFile) < 0;
                    });
                    globalProps.setFiles(filteredFilesMD);
                }

                loadFiles();
            }
            //Suppression fichier stocké
            else {
                Swal.fire({
                    icon: "question",
                    title: "Suppression",
                    html: "Etes-vous sûr de vouloir supprimer ces " + cptf + " fichiers ?",
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Suppression des Fichiers en cours...',
                            didOpen: () => { Swal.showLoading() }
                        });


                        //TODO


                        /*var req = { fileID: id, filetypeID: parseInt(file.FileTypeID), bindID: parseInt(file.BindID) };//TODO : ajout les variables dans l'objet Fichier OU faire autrement
                        if (req.fileID && req.bindID) {
                            commonFunc.postMethod("../api/data/RemoveFile", req).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    loadFiles();
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de Suppression :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: "Echec de Suppression : Arguments invalides"
                            });
                        }*/

                        var item;
                        var id;
                        //var title;
                        //var cpt = 0;
                        //var flag = true;
                        //var desc = "";

                        var asyncJobs = [];
                        var dataAll = [];
                        filesDOM.forEach((elem, index) => {
                            //cpt += 1;
                            item = elem.parentNode;
                            id = parseInt(item.getAttribute("data-fileid")) || 0;
                            //title = item.querySelector(".file-name").getAttribute("title");
                            if (id > 0) {
                                asyncJobs[index] = removeFile(id, parseInt(item.getAttribute("data-filetypeid")) || 0, parseInt(item.getAttribute("data-bindid")));//TODO : ajout les variables dans l'objet Fichier OU faire autrement
                            }
                        });

                        Promise.all(asyncJobs).then(results => {
                            console.debug(results);
                            if (results.length === cptf) {
                                var err = 0;
                                var sum = 0;
                                var msgErr = "";
                                var resStr = "";
                                for (var i = 0; i < results.length; i++) {
                                    if (results[i] && results[i].data && results[i].data.isSuccess) {
                                        sum += 1;
                                        // resStr += dataAll[i].result.first_last_name + " " + dataAll[i].result.last_name + '/';
                                    } else {
                                        err += 1;
                                        if (results[i] && results[i].data) {
                                            msgErr += i + " " + results[i].data.errorMessage + "\n";
                                        } else {
                                            msgErr += i + "\n";
                                        }
                                    }
                                }
                                Swal.close();

                                if (err === 0) {
                                    loadFiles();
                                } else {
                                    var tot = 0;
                                    tot = err + sum;
                                    if (tot === cptf) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Echec",
                                            html: "Echec de suppression de certains fichiers (" + err + "/" + sum + ") :<br>" + msgErr
                                        });
                                    }
                                }
                            } else {
                                //updateBsProgress("progressModal", "Suppression multiple Fichier", "Suppression des Fichiers en cours...", { progressValue: (totfiles * 100) / dataAll.length });
                                Swal.close();
                                Swal.fire({
                                    title: 'Suppression des Fichiers en cours...',
                                    onOpen: () => { Swal.showLoading() },
                                });
                            }
                            return;
                            if (dataAll.length === cptf) {
                                var err = 0;
                                var sum = 0;
                                var msgErr = "";
                                var resStr = "";
                                for (var i = 0; i < dataAll.length; i++) {
                                    if (dataAll[i] && dataAll[i].isSuccess) {
                                        sum += 1;
                                        // resStr += dataAll[i].result.first_last_name + " " + dataAll[i].result.last_name + '/';
                                    } else {
                                        err += 1;
                                        if (dataAll[i]) {
                                            msgErr += i + " " + dataAll[i].errorMessage + "\n";
                                        } else {
                                            msgErr += i + "\n";
                                        }
                                    }
                                }
                                Swal.close();

                                if (err === 0) {
                                    loadFiles();
                                } else {
                                    var tot = 0;
                                    tot = err + sum;
                                    if (tot === cptf) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Echec",
                                            html: "Echec d'enregistrement de certains fichiers (" + err + "/" + sum + ") :<br>" + msgErr
                                        });
                                    }
                                }
                            } else {
                                //updateBsProgress("progressModal", "Suppression multiple Fichier", "Suppression des Fichiers en cours...", { progressValue: (totfiles * 100) / dataAll.length });
                                Swal.close();
                                Swal.fire({
                                    title: 'Suppression des Fichiers en cours...',
                                    onOpen: () => { Swal.showLoading() },
                                });
                            }
                        }, rej => {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur de suppression des fichiers :<br>" + rej.message
                            });
                        })
                            .catch(error => {
                                Swal.fire({
                                    icon: "error",
                                    title: "Erreur",
                                    html: "Erreur de suppression des fichiers :<br>" + error.message
                                });
                            });

                    }
                });
            }
        }
    }

    function fileSas({ sysEvent, props, triggerEvent, data }) {
        var file = props;
        var dataItem = data;

        var filesDOM = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = filesDOM.length;

        if (cptf == 1) {

            var id = file.idFile || 0;
            var title = commonFunc.cellFormatterFileType(file.name);
            hideAllMenu();
            if (id > 0) {

                Swal.fire({
                    icon: "question",
                    title: "Création d'un lien",
                    html: "Etes-vous sûr de vouloir créer un lien pour ce fichier ?<br/>[" + id + "] " + title,
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Création du lien en cours...',
                            didOpen: () => { Swal.showLoading() }
                        });


                        var req = { fileID: id, fileTypeID: parseInt(file.fileTypeID), bindID: parseInt(file.bindID), bindType: globalProps.fileTypeID, OpName: "CREATESAS_" + globalProps.fileTypeID + "FILE" };
                        if (req.fileID && req.bindID) {
                            var url = "../api/" + globalProps.fileTypeID + "/CreateFileSasUrl";
                            commonFunc.postMethod(url, req).then(response => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    loadFiles();
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de création :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: "Echec de création : Arguments invalides"
                            });
                        }
                    }
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Aucun Fichier de sélectionné"
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Erreur",
                html: "Impossible créer un lien pour plusieurs fichiers"
            });
        }
    }
    function fileRes({ sysEvent, props, triggerEvent, data }) {
        var file = props;
        var dataItem = data;

        var filesDOM = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = filesDOM.length;
        console.debug(cptf);
        var repId = globalProps.bindID;
        if (parseInt(repId) > 0) {
            if (cptf == 1) {

                var id = file.idFile || 0;
                var title = commonFunc.cellFormatterFileType(file.name);
                hideAllMenu();
                if (id > 0) {

                    Swal.fire({
                        icon: "question",
                        title: "Suppression",
                        html: "Etes-vous sûr de vouloir restaurer cette sauvegarde ?<br/>[" + id + "] " + title,
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Restauration du rapport en cours...',
                                didOpen: () => { Swal.showLoading() }
                            });
                            var req = { id: id, bindId: repId, value: false };
                            commonFunc.postMethod("../api/report/RestoreReport", req).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    Swal.fire({
                                        icon: "success",
                                        title: "Succès",
                                        html: "Sauvegarde restaurée avec succès.",
                                        didOpen: () => { Swal.hideLoading() },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    }).then((result) => {
                                        loadFiles();
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de restauration :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                    });

                }
            }
        }
    }
    function fileDefComplete({ sysEvent, props, triggerEvent, data }) {
        var file = props;
        var dataItem = data;

        var filesDOM = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = filesDOM.length;
        console.debug(cptf);
        var repId = globalProps.bindID;
        if (parseInt(repId) > 0) {
            if (cptf == 1) {

                var id = file.idFile || 0;
                var title = commonFunc.cellFormatterFileType(file.name);
                hideAllMenu();
                if (id > 0) {

                    Swal.fire({
                        icon: "question",
                        title: "Rapport par défaut",
                        html: "Etes-vous sûr de vouloir utiliser ce fichier comme rapport Completé ?<br/>[" + id + "] " + title,
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Modification du rapport en cours...',
                                didOpen: () => { Swal.showLoading() }
                            });
                            var req = { id: id, bindId: repId , value:true};
                            commonFunc.postMethod("../api/report/RestoreAndMarkReport", req).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    Swal.fire({
                                        icon: "success",
                                        title: "Succès",
                                        html: "Modification effectuée avec succès.",
                                        didOpen: () => { Swal.hideLoading() },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    }).then((result) => {
                                        loadFiles();
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de Rapport par défaut :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                    });

                }
            }
        }
    }
    function fileDefFinal({ sysEvent, props, triggerEvent, data }) {
        var file = props;
        var dataItem = data;

        var filesDOM = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = filesDOM.length;
        console.debug(cptf);
        var repId = globalProps.bindID;
        if (parseInt(repId) > 0) {
            if (cptf == 1) {

                var id = file.idFile || 0;
                var title = commonFunc.cellFormatterFileType(file.name);
                hideAllMenu();
                if (id > 0) {

                    Swal.fire({
                        icon: "question",
                        title: "Rapport par défaut",
                        html: "Etes-vous sûr de vouloir utiliser ce fichier comme rapport Completé ?<br/>[" + id + "] " + title,
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Modification du rapport en cours...',
                                didOpen: () => { Swal.showLoading() }
                            });
                            var req = { id: id, targetId: repId, isPermanent: true };
                            commonFunc.postMethod("../api/report/SetReportFileDefault", req).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    Swal.fire({
                                        icon: "success",
                                        title: "Succès",
                                        html: "Modification effectuée avec succès.",
                                        didOpen: () => { Swal.hideLoading() },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    }).then((result) => {
                                        loadFiles();
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de Rapport par défaut :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                    });

                }
            }
        }
    }
    function fileReplay({ sysEvent, props, triggerEvent, data }) {
        var file = props;
        var dataItem = data;

        var filesDOM = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = filesDOM.length;
        console.debug(cptf);
        var repId = globalProps.bindID;
        if (parseInt(repId) > 0) {
            if (cptf == 1) {

                var id = file.idFile || 0;
                var title = commonFunc.cellFormatterFileType(file.name);
                hideAllMenu();
                if (id > 0) {

                    Swal.fire({
                        icon: "question",
                        title: "Evénements Rapports",
                        html: "Etes-vous sûr de vouloir rejouer les événements pour ce rapport Completé ?<br/>[" + id + "] " + title,
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Déclenchements des évenements en cours...',
                                didOpen: () => { Swal.showLoading() }
                            });
                            var req = { id: id, filetypeID: file.filetypeID, bindID: repId, fileOp: "UPLOADFILE" };
                            commonFunc.postMethod("../api/report/ReplayFileEvents", req).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    Swal.fire({
                                        icon: "success",
                                        title: "Succès",
                                        html: "Modification effectuée avec succès.",
                                        didOpen: () => { Swal.hideLoading() },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    }).then((result) => {
                                        loadFiles();
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec du déclenchement des évenements :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                    });

                }
            }
        }
    }
    function fileModel({ sysEvent, props, triggerEvent, data }) {
        var file = props;
        var dataItem = data;

        var filesDOM = document.querySelectorAll("." + globalProps.className + " .file.selected");
        var cptf = filesDOM.length;
        console.debug(cptf);
        var repId = globalProps.bindID;
        if (parseInt(repId) > 0) {
            if (cptf == 1) {

                var id = file.idFile || 0;
                var title = commonFunc.cellFormatterFileType(file.name);
                hideAllMenu();
                if (id > 0) {
                    var isHtml = (dataItem === "aupdActFModHtml");
                    Swal.fire({
                        icon: "question",
                        title: "Rapport par défaut",
                        html: "Etes-vous sûr de vouloir utiliser ce fichier comme modèle " + (isHtml ? "HTML" : "JSON") + " ?<br/>[" + id + "] " + title,
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: "Oui",
                        cancelButtonText: "Non",
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Modification du modèle en cours...',
                                didOpen: () => { Swal.showLoading() }
                            });
                            var req = { id: id, targetId: repId, isPermanent: !isHtml };
                            commonFunc.postMethod("../api/report/SetReportFileDefault", req).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    Swal.fire({
                                        icon: "success",
                                        title: "Succès",
                                        html: "Modification effectuée avec succès.",
                                        didOpen: () => { Swal.hideLoading() },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    }).then((result) => {
                                        loadFiles();
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de la modification du modèle :<br>" + data.errorMessage
                                    });
                                }
                            });
                        }
                    });

                }
            }
        }
    }

    function dlgFileUpload() {
        var context = this;

        if (modalRoot) {
            modalRoot.unmount();
            modalRoot = null;
        }

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (modalFiles) {
            console.debug(modalFiles);

            //setFiles(modalFiles);

            if (globalProps.files) {
                var newFiles = globalProps.files;
                modalFiles.forEach(file => {
                    console.debug(file);
                    var tempID = parseInt(Math.floor(Date.now() + Math.random() * 10000000000000001).toString().substr(6));
                    if (globalProps.noUploads) newFiles.push({ idFile: tempID, name: file.name, instanceFile: file, dSysMod: file.lastModifiedDate, prefix: file.docType, length: file.size, categ: file.docType });
                    else if (file.responseData) newFiles.push(file.responseData.result);
                });
                globalProps.setFiles(newFiles);
            }

            loadFiles();

            modalRoot.unmount();
        };

        modalRoot = createRoot(document.createElement('div'));
        modalRoot.render(<FileUploadModal parentProps={globalProps} title={"Transfert de Fichiers " + (globalProps.srcName || "")} defaultFileType={globalProps.defaultFileType} fileTypeID={globalProps.fileTypeID} bindID={globalProps.bindID}
            callback={callbackModal}></FileUploadModal>);
    }

    function cloneCanvas(oldCanvas) {

        //create a new canvas
        var newCanvas = document.createElement('canvas');
        var context = newCanvas.getContext('2d');

        //set dimensions
        newCanvas.width = oldCanvas.width;
        newCanvas.height = oldCanvas.height;

        //apply the old canvas to the new one
        context.drawImage(oldCanvas, 0, 0);

        //return the new canvas
        return newCanvas;
    }

    function searchFiles(e) {
        var currElem = document.querySelector("#filesearch");
        var search = e ? e.target.value : (currElem ? currElem.value : "");

        var allFiles = document.querySelectorAll("#aupdActFilesLst .file");
        allFiles.forEach(function (elem) {
            elem.classList.remove("selected");
        });
        var searchFiles;
        if (search === '*') {
            searchFiles = document.querySelectorAll("#aupdActFilesLst .file-box:not(.hidden) .file");
            searchFiles.forEach(function (elem) {
                elem.classList.add("selected");
            });
        }
        else {
            searchFiles = document.querySelectorAll("#aupdActFilesLst .file-box:not(.hidden) .file-name[title*='" + search + "']");
            searchFiles.forEach(function (elem) {
                elem.parentNode.parentNode.classList.add("selected");
            });
        }
    }

    function fileControl(e) {
        var activeElem = document.querySelector(".file-manager .file-control.active");
        var filterf = activeElem ? activeElem.getAttribute("data-typef") : "*";
        if (e) {
            e.preventDefault();
            var targetElem = e.target;
            if (!targetElem.classList.contains("file-control")) targetElem = targetElem.parentNode;
            filterf = targetElem.getAttribute("data-typef");
        }
        setFileType(filterf);
    }
    function folderControl(e) {
        var activeElem = document.querySelector(".folder-list li.active");
        var filterc = activeElem ? activeElem.getAttribute("data-tag") : "*";
        if (e) {
            e.preventDefault();
            filterc = e.target.parentNode.getAttribute("data-tag");
        }
        setFolderType(filterc);
    }

    var filteredFiles = files.filter((file) => {
        return ((fileType === "*" && file.props["data-typef"] !== "sys") || file.props["data-typef"] === fileType) && (folderType === "*" || file.props["data-typec"] === folderType);
    });

    //console.debug("renderFCustom");

    if (globalProps.badgeRendering) {
        return <Row className={globalProps.className}>
            <div>
                <div className="cfiles-container pb-3">
                    <div className="">
                        {globalProps.checkRights(globalProps.rightType, "C") ? <Button id="btnActFilesUpload" className={`btn-magnify px-2 my-0 ${globalProps.disabled ? "disabled" : ""}`} color="success" size="sm" onClick={() => { dlgFileUpload() }}><i className="fa fa-plus-circle"></i> Ajouter des Fichiers</Button> : ""}

                        <div className="hr-line-dashed"></div>
                    </div>

                    <div id="aupdActFilesLst" className="row px-2">
                        {filteredFiles}
                    </div>
                </div>
            </div>
        </Row>;
    }
    else {
        return (<><Row className={globalProps.className}>
            <Col className={globalProps.withFilters ? "" : "d-none"} md="4" lg="3">
                <Card className="widget dash">
                    <CardHeader>
                        <CardTitle tag="h6">VISUALISER</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-filter fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <div className="file-manager files-container mt-1">
                            <h5>Rechercher</h5>
                            <InputGroup>
                                <InputGroupText>
                                    <i aria-hidden="true" className="fa fa-magnifying-glass"></i>
                                </InputGroupText>
                                <Input type="text" autoComplete="no" aria-autocomplete="none" className="form-control input-icon" name="file-search" id="filesearch" onInput={(e) => searchFiles(e)} />
                            </InputGroup>
                            <div className="hr-line-dashed"></div>
                            <h5>Afficher:</h5>
                            <a className={fileType === "*" ? "file-control active" : "file-control"} href="#" data-typef='*' onClick={(e) => fileControl(e)}>Tout</a>
                            <a className={fileType === "doc" ? "file-control active" : "file-control"} href="#" data-typef='doc' onClick={(e) => fileControl(e)}>Documents</a>
                            <a className={fileType === "aud" ? "file-control active" : "file-control"} href="#" data-typef='aud' onClick={(e) => fileControl(e)}>Audio</a>
                            <a className={fileType === "img" ? "file-control active" : "file-control"} href="#" data-typef='img' onClick={(e) => fileControl(e)}>Images</a>
                            <a className={fileType === "vid" ? "file-control active" : "file-control"} href="#" data-typef='vid' onClick={(e) => fileControl(e)}>Vidéos</a>
                            <a className={fileType === "sys" ? "file-control active" : "file-control"} href="#" data-typef='sys' onClick={(e) => fileControl(e)}><i className="fa fa-cog" aria-hidden="true"></i></a>
                            <div className="hr-line-dashed"></div>

                            <h5>Catégories</h5>
                            <ul className="folder-list p-0">
                                {(() => {
                                    var noneElem = <li data-bindid='0' data-tag='*' className={folderType === "*" ? "active" : ""}><a href='#' onClick={(e) => folderControl(e)}><i className='fa fa-folder'></i>&nbsp;Toutes</a></li>;
                                    var filters = filesType.map((type, index) => {
                                        var sup = "";
                                        if (type.idFileType > 0) {
                                            return <li key={index} data-bindid={type.idFileType} data-tag={type.refExtID} className={folderType === type.refExtID ? "active" : ""}><a href='#' onClick={(e) => folderControl(e)}><i className='fa fa-folder'></i>&nbsp;{type.name}</a></li>;
                                        }
                                        return "";
                                    });
                                    return <>{noneElem}{filters}</>;
                                })()}
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={globalProps.withFilters ? "5" : "12"} lg={globalProps.withFilters ? "9" : "12"} className="h-100">
                <Card className="widget dash">
                    <CardHeader>
                        <CardTitle tag="h6">{globalProps.title ? globalProps.title : "FICHIERS"}</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-copy fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <div className="files-container pb-3">
                                <div className="">
                                    {!globalProps.hideSync ? <Button id="btnActFilesRefresh" className="btn-rotate px-2 my-0 btn-simple" color="dark" size="sm" onClick={() => loadFiles()}>
                                        <i className="fa fa-sync"></i>Actualiser
                                    </Button> : ""}
                                    {globalProps.checkRights(globalProps.rightType, "C") ? <Button id="btnActFilesUpload" className="btn-magnify px-2 my-0" color="success" size="sm" onClick={() => { dlgFileUpload() }}><i className="fa fa-plus-circle"></i> Ajouter des Fichiers</Button> : ""}

                                    <div className="hr-line-dashed"></div>
                                </div>

                                <div id="aupdActFilesLst" className="">
                                    {filteredFiles}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className="float-end">
                            <span id="aupdActFilesCount" className="badge bg-primary me-1">{totFiles} Fichier(s)</span>
                            <span id="aupdActFilesSize" className="badge bg-info me-1">{commonFunc.bytesToSize(totLength, 1)}</span>
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
            <Menu id="contextMenu" onVisibilityChange={() => {/*console.debug(document.querySelector(".contexify") ? document.querySelector(".contexify").outerHTML : "")*/ }}>

                {!globalProps.onlyDelete && globalProps.checkRights(globalProps.rightType, "U") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFSas" onClick={fileSas.bind(this)}>
                    <i className="fa fa-link" aria-hidden="true"></i>&nbsp;Créer un lien public
                </Item> : ""}
                {globalProps.checkRights(globalProps.rightType, "D") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFDel" onClick={fileDel.bind(this)}>
                    <i className="fa fa-times" aria-hidden="true"></i>&nbsp;Supprimer
                </Item> : ""}
                {!globalProps.onlyDelete && globalProps.checkRights(globalProps.rightType, "U") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFRes" onClick={fileRes.bind(this)}>
                    <i className="fa fa-tasks" aria-hidden="true"></i>&nbsp;Restaurer
                </Item> : ""}
                <Separator />
                {!globalProps.onlyDelete && globalProps.checkRights(globalProps.rightType, "U") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFDefault" onClick={fileDefComplete.bind(this)}>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Marquer comme Rapport Complété
                </Item> : ""}
                {!globalProps.onlyDelete && globalProps.checkRights(globalProps.rightType, "U") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFRpiDef" onClick={fileDefFinal.bind(this)}>
                    <i className="fa fa-tasks" aria-hidden="true"></i>&nbsp;Définir comme Rapport Final
                </Item> : ""}
                {!globalProps.onlyDelete && globalProps.checkRights(globalProps.rightType, "U") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFRpiReplay" onClick={fileReplay.bind(this)}>
                    <i className="fa fa-history " aria-hidden="true"></i>&nbsp;Rejouer les evénements
                </Item> : ""}
                <Separator />
                {!globalProps.onlyDelete && globalProps.checkRights(globalProps.rightType, "U") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFModRpt" onClick={fileModel.bind(this)}>
                    <i className="fa fa-tasks " aria-hidden="true"></i>&nbsp;Définir comme modèle de rapport
                </Item> : ""}
                {!globalProps.onlyDelete && globalProps.checkRights(globalProps.rightType, "U") ? <Item hidden={isItemHidden.bind(this)} data="aupdActFModHtml" onClick={fileModel.bind(this)}>
                    <i className="fa fa-tasks " aria-hidden="true"></i>&nbsp;Définir comme modèle d’aperçu HTML
                </Item> : ""}
            </Menu></>);
    }
};

FilesCustom.propTypes = {
    bindID: PropTypes.number.isRequired,
    fileTypeID: PropTypes.string.isRequired,//number
    shouldRender: PropTypes.bool,
    src: PropTypes.object,
    srcName: PropTypes.string,
    className: PropTypes.string,
    withFilters: PropTypes.bool,
};

export default FilesCustom;


export function ExtUpload(globalProps) {
    var { url, bindID, fileType, fileTypeID, files, setFiles, callback } = globalProps;

    const [progressUpload, setProgressUpload] = React.useState(false);
    //const [timestamp, setTimestamp] = React.useState(true);

    const [controllers, setControllers] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            if (files && files.length) {
                console.debug(files);

                var asyncJobs = [new Promise(resolve => {resolve();})];
                var index = 1;
                //files.forEach(async (file, index) => {
                for (const objFile of files) {
                    var file = objFile.instanceFile;
                    if (file && !file.closed && (controllers.length === 0 || !controllers[0].signal.aborted)) {
                        const formData = new FormData();
                        /*var fileUpReq = {
                            name: "AVA_" + Date.now() + "_" + file.name,
                            ftype: "AVA", //cat Photo
                            fvisible: 1,
                            fcntry: "FR",
                            fbindId: 2, //Type
                            factId: -1,
                        }; */
                        var fileUpReq = {
                            name: file.name,
                            ftype: file.docType || fileType || "AVA",
                            bindType: fileTypeID || "",
                            visibility: 1,
                            language: "FR",
                            //fbindId: props.fileTypeID, //Type
                            bindId: bindID || -1,
                            //bindSubId
                            fileTypeID: file.docTypeId || 1,
                            lastModified: file.lastModifiedDate,
                            //useTimestamp: timestamp
                        };

                        formData.append('file', file);
                        formData.append('args', JSON.stringify(fileUpReq));


                        const onUploadProgress = (event) => {
                            console.debug(event);

                            file.loaded = event.loaded;
                            file.total = event.total;
                            file.progress = Math.round(100 * event.progress);
                            //setLocalFiles(files);
                            if (setFiles) {
                                setFiles(files.map(el => {
                                    console.debug(el.instanceFile === file);
                                    el.instanceFile = el.instanceFile === file ? file : el.instanceFile;
                                    return el;
                                }));
                            }
                            setProgressUpload(!progressUpload);
                        };

                        const controller = new AbortController();
                        controllers.push(controller);
                        setControllers(controllers);

                        asyncJobs[index] = await axios.post(url || "../api/data/UploadFiles", formData, { signal: controller.signal, headers: { "Content-Type": "multipart/form-data", }, onUploadProgress }).then((response => {
                            file.closed = true;
                            if (response.data.isSuccess) {
                                file.validated = true;
                                file.responseData = response.data;
                            }
                            else {
                                file.error = response.data.errorMessage;
                            }
                            if (setFiles) {
                                setFiles(files.map(el => {
                                    el.instanceFile = el.instanceFile === file ? file : el.instanceFile;
                                    return el;
                                }));
                            }
                            setProgressUpload(!progressUpload);
                            return new Promise(resolve => {
                                resolve(response);
                            });
                        })).catch(error => {
                            return new Promise((resolve) => {
                                resolve({ isSuccess: false, errorMessage: error });
                            });
                        });
                    }
                    index++;
                }

                if (asyncJobs.length) {
                    Promise.all(asyncJobs).then(results => {
                        console.debug(results);
                        setControllers([]);
                        //files.forEach(file => { file.instanceFile = null; });

                        var finalFiles = [];
                        var errors = [];
                        files.forEach((file) => {
                            if (file.instanceFile) {
                                if (file.instanceFile.responseData) {
                                    finalFiles.push(file.instanceFile.responseData.result);
                                }
                                else {
                                    //Error - file.instanceFile.error
                                    errors.push(file.instanceFile);

                                    //file.instanceFile = null;
                                    //finalFiles.push(file);
                                }
                            }
                            //Conservation des fichiers déjà uploads
                            else {
                                //PATCH : conservation name dut au removeTimestamp
                                if (!commonFunc.isBlank(file.orgName)) file.name = file.orgName;
                                file.orgName = null;

                                finalFiles.push(file);
                            }
                        })

                        if (setFiles) setFiles(finalFiles);

                        callback(finalFiles, errors);
                        //setLocalFiles(files);
                        //setProgressUpload(!progressUpload);
                        return;

                    }, rej => {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur de l'upload des fichiers :<br>" + rej.message
                        });
                    })
                        .catch(error => {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur de l'upload des fichiers :<br>" + error.message
                            });
                        });
                }
            }
            else {
                Swal.fire({
                    icon: "warning",
                    title: "Attention",
                    html: "Aucun fichier à télécharger"
                });
            }
        }
        fetchData();
    }, []);
    

    return <Modal isOpen={true} className="modal-files" size="sm" keyboard={false} backdrop="static" style={{ paddingTop: "0px" }}>
        <ModalHeader className="d-flex"></ModalHeader>
        <ModalBody>Upload des fichiers en cours...</ModalBody>
        <ModalFooter className="px-2 flex-nowrap">
            <Progress className="w-100 float-end" color="success" style={{ height: '15px' }} value={(() => {
                var valueLoaded = 0;
                var valueTotal = 0;
                files.forEach(orgFile => {
                    var file = orgFile.instanceFile;
                    if (file && !file.closed) {
                        valueLoaded += (file.loaded || 0);
                        valueTotal += (file.size || 0);//total avait besoin qu'on passe une fois dans onUploadProgress pour chaque fichier (impossible en sequentiel)
                    }
                });
                return (valueLoaded / valueTotal * 100);
            })()}></Progress>
        </ModalFooter>
    </Modal>;
}


//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de FileUploadModal
function FileUploadModal(props) {
    //Equivalent à componentDidMount ( OU componentDidUpdate etc... en fonction des paramètres)
    React.useEffect(() => {

        return () => {
            //console.debug("unmount");
            if (localFiles && localFiles.length) {
                localFiles.forEach((e, i) => {
                    URL.revokeObjectURL(e.preview);
                });
            }
        }
    }, []);

    //Gestion de données utile au Dialog
    //const [rerender, setRerender] = React.useState(false);
    const [fileType, setFileType] = React.useState(props.defaultFileType || "");
    //const [fileTypeId, setFileTypeId] = React.useState(0);
    //const [fileTypeId, setFileTypeId] = React.useState((filesType && props.defaultFileType) ? (filesType.filter(type => type.refExtID === props.defaultFileType)[0].idFileType) : 0);
    const [fileTypeId, setFileTypeId] = React.useState((filesType && props.defaultFileType) ? () => {
        var typesFiltered = filesType.filter(type => type.refExtID === props.defaultFileType);
        if (typesFiltered.length) {
            return typesFiltered[0].idFileType || 0;
        }
        else {
            return 0;
        }
    } : 0);
    const [localFiles, setLocalFiles] = React.useState(props.files || []);
    const [progressUpload, setProgressUpload] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [timestamp, setTimestamp] = React.useState(true);

    const [controllers, setControllers] = React.useState([]);

    async function uploadFiles() {
        var files = localFiles;
        if (files && files.length) {
            console.debug(files);

            var asyncJobs = [];
            var index = 0;
            //files.forEach(async (file, index) => {
            for (const file of files) {
                if (!file.closed && (controllers.length === 0 || !controllers[0].signal.aborted)) {
                    const formData = new FormData();
                    /*var fileUpReq = {
                        name: "AVA_" + Date.now() + "_" + file.name,
                        ftype: "AVA", //cat Photo
                        fvisible: 1,
                        fcntry: "FR",
                        fbindId: 2, //Type
                        factId: -1,
                    }; */
                    var fileUpReq = {
                        name: file.name,
                        ftype: file.docType || fileType || "AVA",
                        bindType: props.fileTypeID || "",
                        visibility: 1,
                        language: "FR",
                        //fbindId: props.fileTypeID, //Type
                        bindId: props.bindID || -1,
                        //bindSubId
                        fileTypeID: file.docTypeId || 1,
                        lastModified: file.lastModifiedDate,
                        useTimestamp: timestamp
                    };

                    formData.append('file', file);
                    formData.append('args', JSON.stringify(fileUpReq));


                    const onUploadProgress = (event) => {
                        console.debug(event);

                        file.loaded = event.loaded;
                        file.total = event.total;
                        file.progress = Math.round(100 * event.progress);
                        //setLocalFiles(files);
                        setLocalFiles(files.map(el => {
                            return (el === file ? file : el)
                        }));
                        setProgressUpload(!progressUpload);
                        /*let progressBarElement = document.getElementById("progress-bar");
                        progressBarElement.innerHTML = percentage + "%";
                        progressBarElement.setAttribute("aria-valuenow", percentage);
                        progressBarElement.style.width = percentage + "%"; */
                    };
                    setProcessing(true);

                    const controller = new AbortController();
                    controllers.push(controller);
                    setControllers(controllers);

                    asyncJobs[index] = await axios.post(props.parentProps.altUploadFilesUrl || "../api/data/UploadFiles", formData, { signal: controller.signal, headers: { "Content-Type": "multipart/form-data", }, onUploadProgress }).then((response => {
                        file.closed = true;
                        if (response.data.isSuccess) {
                            file.validated = true;
                            file.responseData = response.data;
                        }
                        else {
                            file.error = response.data.errorMessage;
                        }
                        setLocalFiles(files.map(el => {
                            return (el === file ? file : el)
                        }));
                        setProgressUpload(!progressUpload);
                        return new Promise(resolve => {
                            resolve(response);
                        });
                    })).catch(error => {
                        return new Promise((resolve) => {
                            resolve({ isSuccess: false, errorMessage: error });
                        });
                    });
                }
                index++;
            }

            if (asyncJobs.length) {
                Promise.all(asyncJobs).then(results => {
                    console.debug(results);
                    setProcessing(false);
                    setControllers([]);
                    files.forEach(file => {
                        file.progress = 0;
                        file.loaded = 0;
                        file.total = 0;
                    });
                    setLocalFiles(files);

                    //setLocalFiles(files);
                    //setProgressUpload(!progressUpload);
                    return;

                }, rej => {
                    setProcessing(false);
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur de l'upload des fichiers :<br>" + rej.message
                    });
                })
                    .catch(error => {
                        setProcessing(false);
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur de l'upload des fichiers :<br>" + error.message
                        });
                    });
            }
        }
        else {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Aucun fichier à télécharger"
            });
        }
    }

    function cancelUpload() {
        console.debug("Abort Requests");
        controllers.forEach((controller) => {
            controller.abort();
        });
        setControllers([]);
    }

    //console.debug("render modal");

    var uploadTypes = props.accept || {};
    /* /(\.|\/)(gif|jpe?g|png|avi|mp4)$/i */
    /*{
        'image/*': [".png", ".gif", ".jpeg", ".jpg"],
        'text/html': ['.html', '.htm'],
    }*/


    return (
        <Modal isOpen={true} className="modal-files" size="xl" keyboard={false} toggle={() => { props.callback(localFiles); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { props.callback(localFiles); }}>{props.title || "Transfert de Fichiers"}</ModalHeader>
            <ModalBody className="px-2">
                <div className="d-inline-block" style={{ fontSize: '48px' }} >
                    <i className="fa fa-cloud-upload-alt"></i>
                </div>

                <p className="d-inline-block mt-1" style={{ fontSize: '15px', verticalAlign: "top" }}>Vous pouvez charger les documents et les associer à une catégorie</p>

                <Row className="m-0 px-1">
                    <Col lg="12">
                        <Dropzone accept={uploadTypes}
                            //maxSize={9990000}
                            noClick
                            noKeyboard
                            noDragEventsBubbling
                            preventDropOnDocument={false}
                            onDrop={(acceptedFiles, fileRejections, e) => {
                                if (e) e.target.classList.remove("hover");

                                acceptedFiles.forEach((file) => {
                                    const reader = new FileReader()

                                    reader.onabort = () => console.log('file reading was aborted')
                                    reader.onerror = () => console.log('file reading has failed')
                                    reader.onload = () => {
                                        // Do whatever you want with the file contents
                                        const binaryStr = reader.result
                                        console.log(binaryStr)
                                    }
                                    reader.readAsArrayBuffer(file)
                                });

                                if (fileRejections && fileRejections.length) {
                                    Swal.fire({
                                        icon: "warning",
                                        title: "Attention",
                                        html: "Certains fichiers ne peuvent pas être uploadé :<br/>" + fileRejections.map(file => "<div title='" + file.errors.map(error => error.message + "\n") + "'>" + file.file.name + "<i class='fa fa-2xs fa-circle-info'></i></div>")
                                    });
                                }

                                var newFiles = acceptedFiles.map(file => Object.assign(file, {
                                    preview: URL.createObjectURL(file),
                                    docType: file.docType || fileType,
                                    docTypeId: file.docTypeId || fileTypeId,
                                }));
                                newFiles = newFiles.concat(localFiles);
                                setLocalFiles(newFiles);
                                //setRerender(!rerender);
                            }}
                            onDragEnter={(e) => {
                                e.target.classList.add("hover");
                            }}
                            onDragLeave={(e) => {
                                e.target.classList.remove("hover");
                            }}
                        >
                            {({ acceptedFiles, getRootProps, getInputProps, open, rootRef, inputRef, isFocused }) => {

                                return (
                                    <section>

                                        {filesType.length ? <>
                                            <label className="text-start control-label fw-bold ms-3">Type de Doc :</label>
                                            <Input className="w-auto d-inline-block mx-1" name="categorie" type="select"
                                                defaultValue={fileType} onChange={(e) => {
                                                    var value = e.target.value;
                                                    var option = e.target.querySelector("option[value='" + value + "']");
                                                    setFileType(value);
                                                    setFileTypeId(parseInt(option.getAttribute("data-id")) || 0);
                                                }}>
                                                {filesType.map((dt, index) => {
                                                    return (<option key={index} data-id={dt.idFileType} value={dt.refExtID}>{dt.name}</option>);
                                                })}
                                            </Input>
                                        </> : ""}

                                        <button className={`btn btn-success my-0 ${processing ? "disabled" : ""}`} type="button" onClick={open}><i className="fa fa-plus"></i> Ajouter des Fichiers</button>
                                        {props.parentProps.noUploads ? "" : <button className={`btn btn-primary my-0 ${processing ? "disabled" : ""}`} type="button" onClick={uploadFiles}><i className="fa fa-upload"></i> Démarrer le transfert</button>}
                                        {props.parentProps.noUploads ? "" : <button className={`btn btn-warning my-0 ${processing ? "" : "disabled"}`} type="reset" onClick={cancelUpload}><i className="fa fa-ban"></i> Annuler le transfert</button>}
                                        <div className={`form-check form-check-inline fcheck-sm mt-2`}>
                                            <Label check for={"contentActive"} className={`d-inline-block px-0`} style={{ minWidth: "16px" }}>
                                                <div className={`d-inline-block`}>
                                                    <Input type="checkbox" id={"contentActive"} checked={timestamp} onChange={() => { setTimestamp(!timestamp); }} />
                                                    <span className={`form-check-sign`} />
                                                    <div className={`d-inline-block ms-4`}>Utiliser l'horodatage</div>
                                                </div>
                                            </Label>
                                        </div>

                                        <div className="hr-line-dashed"></div>

                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="thumbsContainer">
                                                {localFiles.length ? localFiles.map((file, index) => {

                                                    /*<div className="thumb" key={file.name}>
                                                        <div className="thumbInner">
                                                            <img alt=""
                                                                src={file.preview}
                                                                className="thumbImg"
                                                                // Revoke data uri after image is loaded (/!\ Empêche la récupération des images si fermeture du modal)
                                                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                            />
                                                        </div>
                                                    </div>*/
                                                    console.debug(file.preview);
                                                    var dateM = commonFunc.formatDateTime(new Date(file.lastModified));//Patch 28/06/2018

                                                    var currFileType = file.docType || fileType;
                                                    var tempId = "ftmp-" + index;
                                                    return (<div key={index} id={tempId} className={`file-box medium ${file.validated ? "upload-valid" : (file.error ? "upload-error" : "")} ${file.closed ? "file-closed" : "file-open"}`} onClick={(e) => {
                                                        e.preventDefault();
                                                        var fileElem = document.querySelector(".modal-files #" + tempId + " .file");
                                                        var allFiles = fileElem.parentNode.parentNode.querySelectorAll(".modal-files .file");
                                                        allFiles.forEach(function (elem) {
                                                            elem.classList.remove("selected");
                                                        });
                                                        fileElem.classList.add("selected");
                                                    }}>
                                                        <div className='file'>
                                                            <a href='#'>
                                                                <span className='corner'></span>
                                                                <div className='preview'>
                                                                    <Img alt="" draggable="false"
                                                                        src={file.preview}
                                                                        className="thumbImg" />
                                                                    <br />{commonFunc.getFilesFaIcon(file.name, currFileType)}
                                                                    {props.parentProps.noUploads ? "" : <div className='float-end me-2' style={{ width: 40, height: 40 }}>
                                                                        <CircularProgressbarWithChildren value={(!file.validated && !file.error) ? (file.progress || 0) : 100} text={(!file.validated && !file.error) ? ((file.progress || 0) + "%") : ""}
                                                                            styles={{ path: { stroke: ((!file.validated && !file.error) ? `rgba(62, 152, 199, ${file.progress / 100})` : (file.validated ? "#6bd098" : "#dc3545")) } }}>
                                                                            {file.validated ? <i className="fa fa-check fw-bold text-success" style={{ fontSize: "18px" }}></i> : ""}
                                                                            {file.error ? <i className="fa fa-times fw-bold text-danger" style={{ fontSize: "18px" }}></i> : ""}
                                                                        </CircularProgressbarWithChildren>
                                                                    </div>}
                                                                    {!file.validated ? <button type='button' className={`btn btn-link btn-icon float-end m-0 ${processing ? "invisible" : ""}`} onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        var newList = localFiles.filter(function (e, i) {
                                                                            var flag = i !== index;
                                                                            if (!flag) file = e;
                                                                            return flag;
                                                                        });
                                                                        if (file) URL.revokeObjectURL(file.preview);
                                                                        setLocalFiles(newList);
                                                                    }}>
                                                                        <i className='fa fa-trash-alt' style={{ fontSize: "24px" }}></i>
                                                                    </button> : ""}
                                                                </div>
                                                                <div className='file-name'>{file.name}<br /><small className='float-start'>{dateM}</small><small className='float-end'>{commonFunc.bytesToSize(file.size, 1)}</small></div>
                                                                <div className='side-corner-tag alt-filetype' data-typec={currFileType}><div><span>{currFileType}</span></div></div>
                                                            </a>
                                                        </div>
                                                        <p className={`text-danger ${file.error ? "" : "d-none"}`}>{file.error}</p>
                                                    </div>);

                                                }
                                                ) : ""}
                                            </div>
                                        </div>
                                    </section>
                                );
                            }}
                        </Dropzone>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <Progress className="w-100 float-end" color="success" style={{ height: '15px' }} value={(() => {
                    var valueLoaded = 0;
                    var valueTotal = 0;
                    localFiles.forEach(file => {
                        if (!file.closed) {
                            valueLoaded += (file.loaded || 0);
                            valueTotal += (file.size || 0);//total avait besoin qu'on passe une fois dans onUploadProgress pour chaque fichier (impossible en sequentiel)
                        }
                    });
                    return (valueLoaded / valueTotal * 100);
                })()}></Progress>

                <Button className="px-3" color="secondary" onClick={() => { props.callback(localFiles); }}>Fermer</Button>
            </ModalFooter>
        </Modal>
    );
}