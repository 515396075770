import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

import moment from "moment";
import Swal from "sweetalert2";

import {
    Button, Label, Input, Form, FormGroup, FormFeedback,
    Card,
    CardBody, CardHeader, Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Select from 'react-select';

import { Menu, Item, Separator, useContextMenu } from "react-contexify"
import "react-contexify/dist/ReactContexify.css";

import CDataGrid from 'components/CDataGrid';
import  { ToggleGroup } from 'react-data-grid';

import ToolbarDate from 'components/ToolbarDate';
import DPCustom from 'components/DPCustom';

import * as commonFunc from 'scripts/common';

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.i18n";

import { withRouter } from 'components/withRouter';


class ManageTG extends Component {
    static displayName = ManageTG.name;

    //static tgModels;
    static refreshCb;
    static modalRoot;
    lastDate;

    showContextMenu = useContextMenu().show;
    hideAllMenu = useContextMenu().hideAll;

    dataGrid = React.createRef();


    modalRoot = null;
    materials = [];
    employees = [];
    clients = [];
    status = [];
    services = [];

    constructor(props) {
        super(props);

        this.clientGen = React.createRef(null);

        this.state = { tgList: [], curDate: new Date(), status: [], defaultStatus: 5000, curStatus: 5000/*, historicalReports: true*/, loading: true, dataStamp: 0 };//900
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function (curUser) {

            /*commonFunc.getMethod("../api/site/GetSiteModels", { id: -1 }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {

                    context.tgModels = {};
                    dataModels.result.models.forEach((model) => {
                        context.tgModels[model.extID] = JSON.parse(model.value);
                    });*/


                    //Chargement via url
                    var defTGLoadId = 0;
                    var defTGActionId = 0;
                    if (context.props.searchParams) {
                        defTGLoadId = parseInt(context.props.searchParams.get("tgId")) || 0;
                        defTGActionId = parseInt(context.props.searchParams.get("actionId")) || 0;
                    }
                    if (defTGLoadId > 0) {
                        var defTGStatusId = parseInt(context.props.searchParams.get("statusId")) || -1;//900

                        context.loadStatusBtnGrp(defTGStatusId);

                        context.loadTgEvents(defTGStatusId, false, function () {
                            /*$("#lstActs_filter input[type='search']").val(defReportLoadId);
                            showDtSelID("lstActs", defReportLoadId);
                            $('html, body').animate({
                                scrollTop: $("#detailAct").offset().top
                            }, 500);

                            if (defTGActionId == 2) {
                                $(".action-editReport:visible").click();
                            }*/
                        });
                    } else {
                        context.loadStatusBtnGrp(5000);//900

                        context.loadTgEvents();

                        /*if (defTGActionId == 1) {
                            $(".action-addReport:visible").click();
                        }*/
                    }


                /*} else {
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Echec de chargement des modèles TG :<br>" + dataModels.errorMessage
                    });
                }
            });*/
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");

        //Update Scroller Size
        if (prevState.isShowDetails && !this.state.isShowDetails) {
            if (this.props.getScroller) this.props.getScroller().update();
        }

        if (prevState.curDate !== this.state.curDate || prevState.curStatus !== this.state.curStatus) {
            this.loadTgEvents();
        }
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount(); 
            this.modalRoot = null;
        }
    }

    dtFunctionPanel(id, fctPanel) {
        if (!this.dtFunctions) this.dtFunctions = {};
        if (!this.dtFunctions[id]) this.dtFunctions[id] = {};
        this.dtFunctions[id] = fctPanel;
    }

    render() {
        var context = this;

        //console.debug("render again");

        //TEMP : solution pour éviter l'apparition d'erreur
        //iframe#webpack-dev-server-client-overlay{display:none!important}

        //columns : frozen = fixedColumns

        //TODO : gérer le contextMenu
        // + filters / i18n / boutons actions top


        /*,
        renderGroupCell({ childRows }) {
            return childRows.reduce((prev, { gold }) => prev + gold, 0);
        }*/
        //renderSummaryCell

        var commonColumnArgs = { headerCellClass: "text-center" };
        var defaultColumnArgs = { sortable: true };

        let contents = this.state.loading ? <p><em>Chargement...</em></p> :
            <div className="h-100">
                {<CDataGrid ref={context.dataGrid} cDefaultColumnArgs={defaultColumnArgs}
                    style={{minHeight:"calc(100% - 38px)"}} columns={[
                    {
                        key: "idWorkEvent", name: "ID", width: 60, ...commonColumnArgs,
                        renderSummaryCell({ row }) {
                            return <strong>Total</strong>;
                        }
                    },
                    { key: "employeeName", name: "Salarié", width: 160, ...commonColumnArgs },
                    {
                        key: "clientName", name: "Client", width: 160, ...commonColumnArgs, cHideOnGrouping:true,
                        renderGroupCell({ childRows, column, isExpanded }) {
                            return (!column.frozen && childRows && column.cHideOnGrouping) ? "" : <><ToggleGroup isExpanded={isExpanded} /><span className="ps-1">{childRows[0].clientName} ({childRows.length})</span></>;
                        }
                    },
                    { key: "materialName", name: "Matériel", width: 160, ...commonColumnArgs },
                    //{ key: "accountType", name: "Type", width: 50, ...commonColumnArgs, cellClass: "text-center", renderCell: cellFormatterAccountType },
                    { key: "serviceName", name: "Service", width: 150, ...commonColumnArgs },
                    {
                        key: "startDate", name: "Début", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    {
                        key: "endDate", name: "Fin", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                        { key: "length", name: "Durée", width: 60, ...commonColumnArgs, renderCell: ({ row, column, childRows }) => { return row[column.key].toFixed(2) } },
                    {
                        key: "calcKmsLength", name: "Durée Réelle", width: 60, ...commonColumnArgs, customOpt: { formatoptions: { decimalSeparator: ",", decimalPlaces: 2 } },
                        renderCell: cellFormatterNumFormat,
                        renderGroupCell({ childRows, column }) {
                            /*readonly childRows: readonly TRow[];
                            readonly id: string;
                            readonly parentId: unknown;
                            readonly groupKey: unknown;
                            readonly isExpanded: boolean;
                            readonly level: number;
                            readonly posInSet: number;
                            readonly setSize: number;
                            readonly startRowIndex: number; */

                            return childRows.reduce((prev, { calcKmsLength }) => prev + calcKmsLength, 0);
                        },
                        summaryType: "sum",
                        renderSummaryCell({ row }) {
                            console.debug(row);
                            return `${row.calcKmsLength}`;
                        }
                    },
                    { key: "typeKmsName", name: "Type Kms", width: 110, ...commonColumnArgs },
                    { key: "kms", name: "Kms", width: 50, ...commonColumnArgs, cellClass: "text-center" },
                    { key: "signMode", name: "Mode Signature", width: 90, ...commonColumnArgs, cellClass: "text-center", renderCell: cellFormatterSignItvDesc },
                    { key: "statusID", name: "Etat", width: 50, ...commonColumnArgs, cellClass: "text-center", renderCell: cellFormatterTypeItv },
                    { key: "validationMode", name: "Mode Validation", width: 50, ...commonColumnArgs, cellClass: "text-center", renderCell: cellFormatterValidItvDesc },
                    {
                        key: "isValid", name: "Validé", width: 70, ...commonColumnArgs, cellClass: "text-center", customOpt: { formatoptions: { disabled: true } },
                        renderCell: cellFormatterCheckbox
                    },
                    {
                        key: "isSent", name: "Envoyé", width: 70, ...commonColumnArgs, cellClass: "text-center", customOpt: { formatoptions: { disabled: true } },
                        renderCell: cellFormatterCheckbox
                    },
                    {
                        key: "isExport", name: "Exporté", width: 70, ...commonColumnArgs, cellClass: "text-center", customOpt: { formatoptions: { disabled: true } },
                        renderCell: cellFormatterCheckbox
                    },
                    {
                        key: "signStartDate", name: "Signature1", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    {
                        key: "signEndDate", name: "Signature2", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    { key: "gsmID", name: "GsmID", width: 50, ...commonColumnArgs, cellClass: "text-center", renderCell: cellFormatterDeviceItv },
                    { key: "cardID", name: "CardID", width: 50, ...commonColumnArgs, cellClass: "text-center", renderCell: cellFormatterTagItv },
                    { key: "parentID", name: "ParentID", width: 60, ...commonColumnArgs },
                    {
                        key: "dSysCreat", name: "Créé le", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    {
                        key: "dSysMod", name: "Modifié le", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    {
                        key: "receiveDate", name: "Reçu le", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    {
                        key: "validationDate", name: "Validé le", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    {
                        key: "exportDate", name: "Exporté le", width: 130, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'ddd DD MMM HH:mm' } },
                        renderCell: cellFormatterDate
                    },
                    { key: "decalage", name: "Décalage", width: 50, ...commonColumnArgs },
                    { key: "errorCode", name: "Code Erreur", width: 70, ...commonColumnArgs },
                    { key: "errorMsg", name: "Message Erreur", width: 170, ...commonColumnArgs },
                    { key: "comments", name: "Commentaires", width: 170, ...commonColumnArgs },
                    {
                        key: "refDate", name: "Jour", width: 60, ...commonColumnArgs, customOpt: { formatoptions: { srcformat: 'Y-M-DTH:i', newformat: 'DD/MM/YYYY' } },
                        renderCell: cellFormatterDate, cHideOnGrouping: true,
                        renderGroupCell(props) {
                            var { childRows, column, isExpanded } = props;
                            return (!column.frozen && childRows && column.cHideOnGrouping) ? "" : <><ToggleGroup isExpanded={isExpanded} /><span className="ps-1">{cellFormatterDate(props)} ({childRows.length})</span></>;
                        }
                    },
                    { key: "monthID", name: "Mois", width: 60 },
                    {
                        key: "weekID", name: "Semaine", width: 60, cHideOnGrouping: true,
                        renderGroupCell({ childRows, column, isExpanded }) {
                            return (!column.frozen && childRows && column.cHideOnGrouping) ? "" : <><ToggleGroup isExpanded={isExpanded} /><span className="ps-1">{childRows[0].weekID} ({childRows.length})</span></>;
                        }
                    },
                    {
                        key: "profCliID", name: "Profil", width: 60, cHideOnGrouping: true,
                        renderGroupCell({ childRows, column, isExpanded }) {
                            return (!column.frozen && childRows && column.cHideOnGrouping) ? "" : <><ToggleGroup isExpanded={isExpanded} /><span className="ps-1">{childRows[0].profCliID} ({childRows.length})</span></>;
                        }
                    },
                    {
                        key: "employeeID", name: "SalariéID", width: 160, minWidth: 0, cellClass: "hidden2", summaryCellClass: "hidden2", cHideOnGrouping: true,
                        renderGroupCell({ childRows, column, isExpanded }) {
                            // style={{ display: "table", width: "160px" }}
                            return (!column.frozen && childRows && column.cHideOnGrouping) ? "" : <><ToggleGroup isExpanded={isExpanded} /><span className="ps-1">{childRows[0].employeeName} ({childRows.length})</span></>;
                        }
                    },
                    /*{
                        key: "clientID", name: "ClientID", width: 160, minWidth: 0, headerCellClass: "text-center hidden2", cellClass: "hidden2", summaryCellClass: "hidden2",
                        renderGroupCell({ childRows }) {
                            return <span>{childRows[0].clientName}({childRows.length})</span>;
                        }
                    },
                    {
                        key: "serviceID", name: "ServiceID", width: 150, minWidth: 0, headerCellClass: "text-center hidden2", cellClass: "hidden2", summaryCellClass: "hidden2",
                        renderGroupCell({ childRows }) {
                            return <span>{childRows[0].serviceName}({childRows.length})</span>;
                        }
                    },
                    {
                        key: "typeKms", name: "KmsID", width: 110, minWidth: 0, headerCellClass: "text-center hidden2", cellClass: "hidden2", summaryCellClass: "hidden2",
                        renderGroupCell({ childRows }) {
                            return <span>{childRows[0].typeKmsName}({childRows.length})</span>;
                        }
                    },
                    {
                        key: "curStep", name: "Etape", width: 60, minWidth: 0, headerCellClass: "text-center hidden2", cellClass: "hidden2", summaryCellClass: "hidden2",
                        renderGroupCell({ childRows }) {
                            return <span>{childRows[0].typeKmsName}({childRows.length})</span>;
                        }
                    },
                    {
                        key: "scheduleEventID/srcEventID", name: "PlanningID", width: 60, minWidth: 0, headerCellClass: "text-center hidden2", cellClass: "hidden2", summaryCellClass: "hidden2",
                        renderGroupCell({ childRows }) {
                            return <span>{childRows[0].typeKmsName}({childRows.length})</span>;
                        }
                    },*/
                    ]}
                    rows={context.state.tgList}
                    rowKeyGetter={(row) => row.idWorkEvent}
                    cGrpOptions={[{ label: "Aucun", value: ["none"] }, { label: "Par Salarié", value: ["employeeID"] }, { label: "Par Client", value: ["clientName"] }, { label: "Par Profil", value: ["profCliID"] },
                        { label: "Par Salarié et Jour", value: ["employeeID", "refDate"] }, { label: "Par Salarié et Semaine", value: ["employeeID", "weekID"] }, { label: "Par Client et Jour", value: ["clientName", "refDate"] },
                        { label: "Par Client et Semaine", value: ["clientName", "weekID"] }, { label: "Par Profil,Client, Jour", value: ["profCliID", "clientName", "refDate"] },
                        { label: "Par Profil,Client et Semaine", value: ["profCliID", "clientName", "weekID"] }]}
                    onCellContextMenu={(args, event) => {
                        context.showContextMenu({
                            id: "contextMenu",
                            event: event,
                            props: args
                        });
                    }}
                />}
            </div>;

        document.body.classList.add("planning-mode");

        return (
            <div className="tableDetails h-100">
                <Card className={`tableContainer h-100 widget dash ${this.state.editionMode ? "module-disabled" : ""}`}>
                    <CardBody className="d-flex flex-column">
                        <ToolbarDate refreshContent={this.loadTgEvents.bind(this)} customTitle={"Télégestion - " + moment(this.state.curDate).format("MMMM")/*this.state.curDate.toLocaleString("fr-FR", { month: "long" })*/}
                            selPreviousPeriod={this.selPreviousPeriod.bind(this)}
                            datepickerChange={this.datepickerChange.bind(this)}
                            selNextPeriod={this.selNextPeriod.bind(this)}
                            datepickerOpt={{ showWeeks: true, initialValue: moment(new Date()).utc().toDate() }}
                            customAddBtn={<>
                                {(this.props.checkRights("WORKEVENT", "C") ? <Button type="button" id="pplanSelAdd" color="success" className="px-3" onClick={() => this.addOrUpdateTG()}><i className="fa fa-square-plus"></i>&nbsp;Ajouter</Button> : <div className="d-none"></div>)}
                                {(<Button type="button" color="primary" className="px-3" onClick={() => {
                                    console.debug(context.dataGrid.current);
                                    context.dataGrid.current.exportToCsvFromExt("ExportTable");
                                }}><i className="fa fa-file-csv"></i>&nbsp;Export CSV</Button>)}</>
                            }
                            statusContainer={
                                <>
                                    <div>{this.state.status.map((dt) => {
                                        var statusClass = "form-badge-radio mb-1 ";
                                        statusClass += dt.css ? dt.css.replace("fa-", "untrigger") : "";
                                        return /*dt.isSchedulable ?*/ <div id={`st_${dt.idStatus}`} key={dt.idStatus} className={statusClass}>
                                            <div className='form-check-radio fcheck-sm mb-0'>
                                                <Label check >
                                                    <Input id={`status_${dt.idStatus}`} name={"status"} disabled={this.state.editionMode} checked={dt.idStatus === context.state.curStatus} type="radio" data-lvlv={dt.step} value={dt.idStatus} onChange={(e) => {
                                                        var value = parseInt(e.target.value);
                                                        context.setState({ curStatus: value, curClientId: 0, curClient: null, isShowDetails: false });
                                                    }} />
                                                    <span className="form-check-sign" />
                                                    {(() => {
                                                        var classFa = "";
                                                        if (dt.css && dt.css.indexOf("fa-")) {
                                                            dt.css.split(" ").forEach((classElem) => {
                                                                //console.debug(classElem.startsWith("fa-") + classElem);
                                                                classFa += (classElem.startsWith("fa-") ? (classElem + " ") : "");
                                                            });
                                                        }
                                                        return <i className={`fa ${classFa} me-1`}></i>
                                                    })()}
                                                    {dt.name}
                                                </Label>
                                            </div>
                                        </div> /*: ""*/;
                                    })}</div>
                                    <div className="custom-control custom-radio custom-check-color custom-control-inline badge bg-success tgevent-active" id="st_2">
                                        <input type="radio" id="status_2" value="2" name="status" className="custom-control-input" data-lvlv="0" />
                                        <label className="custom-control-label" htmlFor="status_2">Actif uniquement</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-check-color custom-control-inline badge bg-secondary tgevent-all" id="st_9">
                                        <input type="radio" id="status_9" value="9" name="status" className="custom-control-input" data-lvlv="0" />
                                        <label className="custom-control-label" htmlFor="status_9">Tous</label>
                                    </div>
                                </>
                            }></ToolbarDate>
                        {contents}
                    </CardBody>
                </Card>

                <Menu id="contextMenu" onVisibilityChange={() => { /*setSelectedRow()*/ /*$("#lstTGItv").jqGrid("setSelection", $(this).attr("id"), true);*/ /*TODO : récup la ligne active */ }}>
                    <Item disabled={isItemDisabled.bind(this)} data="mnuTgItvAct" onClick={mnuTgItvAct.bind(this)}>
                        <i className="fa fa-sync" aria-hidden="true"></i>&nbsp;Actualiser
                    </Item>
                    <Item disabled={isItemDisabled.bind(this)} data="mnuTgItvGeo" onClick={mnuTgItvGeo.bind(this)}>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Géolocalisation
                    </Item>
                    <Separator />
                    {this.props.checkRights("WORKEVENT", "U") ? <Item disabled={isItemDisabled.bind(this)} data="mnuTgItvUpd" onClick={mnuTgItvUpd.bind(this)}>
                        <i className="fa fa-edit" aria-hidden="true"></i>&nbsp;Modifier
                    </Item> : ""}
                    {this.props.checkRights("WORKEVENT", "U") ? <Item disabled={isItemDisabled.bind(this)} data="mnuTgItvVal" onClick={mnuTgItvVal.bind(this)}>
                        <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Valider
                    </Item> : ""}
                    <Separator />
                    <Item disabled={true} data="mnuTgItvFps" onClick={mnuTgItvFps.bind(this)}>
                        Fiche de présence
                    </Item>
                    <Item disabled={true} data="mnuTgItvFpc" onClick={mnuTgItvFpc.bind(this)}>
                        Fiche de présence Compte
                    </Item>
                    <Separator />
                    {this.props.checkRights("WORKEVENT", "D") ? <Item disabled={isItemDisabled.bind(this)} data="mnuTgItvDel" onClick={mnuTgItvDel.bind(this)}>
                        <i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;Supprimer
                    </Item> : ""}
                </Menu>
            </div>
        );
    }

    getStartDate(view) {
        var curMoment = moment(this.state.curDate);

        var sd;
        switch (view) {
            case "01MC":
            case "M":
                sd = curMoment.clone().startOf('month').startOf('day');//.utc();
                break;

            case "03MC":
                sd = curMoment.clone().startOf('quarter').startOf('day'); //Trimestre
                break;


            case "04MC":
                sd = curMoment.clone().startOf('quarter').subtract(1, 'days').startOf('month').startOf('day'); //Quadrimetre Précédent
                break;

            case "06MC":
                sd = curMoment.clone().endOf('month').add(1, 'days').startOf('year').startOf('day'); //Année Précédent
                break;

            case "Y":
                sd = curMoment.clone().startOf('year').startOf('month').startOf('day'); //année Précédent
                break;
            default: break;

        }
        return sd;
    }


    async loadTgEvents(statusId, callback) {
        this.setState({ tgList: [], dataStamp: 0 });
        var context = this;

        if (callback) {
            context.refreshCb = callback;
        }

        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        this.hideAllMenu();

        var period = "M";
        var sdPeriod = this.getStartDate(period);

        var args = {};
        args.state = this.state.curStatus;// 2;//statusId || $('input[name=status]:checked', '#btnLstStatus').val() || 2;
        args.period = period;
        args.startdate = sdPeriod.format('YYYY-MM-DDT') + '00:00Z';
        //args.showAll = true;
        //args.withTemplate = false;

        /*if (this.lastDate && !moment(sdPeriod).isSame(this.lastDate)) {
            if ($(".ui-search-toolbar").is(":visible")) $("#lstTGTbRec").click();
            var argsEmp = { period: args.period, state: 208, startdate: args.sdate };
            loadTypeEmployees("aupdItvSal", $("aupdItvSal").val() || 0, argsEmp);
        }*/

        commonFunc.getMethod("../api/workevent/GetWorkEvents", args).then((response) => {//GetTgEvents
            var data = response.data;
            if (data.isSuccess) {

                if (data.result && data.result.length) {
                    data.result.forEach((elem) => {
                        elem.clientName = elem.client.name + " " + elem.client.firstName;
                        elem.employeeName = elem.employee.name + " " + elem.employee.firstName;
                        elem.materialName = elem.material.name;
                        elem.serviceName = elem.service.name;
                    });
                }

                this.lastDate = sdPeriod;

                context.setState({ tgList: data.result, loading: false, dataStamp: new Date().getTime() });

                Swal.close();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de chargement des Interventions<br/>" + data.errorMessage
                });
            }
        });
    }

    loadStatusBtnGrp(defaultID) {
        var context = this;

        commonFunc.getMethod("../api/workevent/GetStatus", null).then((response) => {
            var data = response.data;

            //Libelle = name + ForeColor = foreColor + Opacite >> removed + Niveau = step + Categ = categ + Actif = isActive + Planifiable = isSchedulable + css + valeur = value
            if (data.isSuccess) {

                context.setState({ status: data.result, defaultStatus: defaultID, curStatus: defaultID });

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de récupération des statuts<br/>" + response.data.errorMessage
                });
            }

        });
    }

    selPreviousPeriod() {
        var curMoment = new moment(this.state.curDate);
        var begin = curMoment.clone().startOf('month').subtract(1, 'days').startOf('month');
        if (begin) {
            this.setState({ curDate: begin.format() });
        }
    }

    datepickerChange(date) {
        if (!date) date = moment(this.state.curDate);

        this.setState({ curDate: date.format() });
    }

    selNextPeriod() {
        var curMoment = new moment(this.state.curDate);
        var begin  = curMoment.clone().endOf('month').add(1, 'days');
        if (begin) {
            this.setState({ curDate: begin.format() });
        }
    }

    //TODO
    addOrUpdateTG(src) {
        var context = this;

        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }

        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var curMoment = new moment(this.state.curDate);
        var view = "M";

        var url = "../api/material/GetMaterials";//GetWithContract
        var args = { period: view, state: 4000, startdate: curMoment.toISOString() };

        //Check Config NoContract
        var flagNoContract = false;
        var siteForResources = context.props.getSite();
        if (siteForResources) {
            var siteExtras = siteForResources.extras;
            if (!(siteExtras && siteExtras.length && siteExtras.filter(extra => extra.name === "withContract").length > 0)) {
                url = url.replace("material/GetWithContract", "material/GetMaterials");
                flagNoContract = true;
                //delete args.state;
            }
        }

        var loadRes = (responseMaterial, responseEmployee, responseClient) => {
            var data = responseMaterial.data;

            //console.debug(datae);
            if (data.isSuccess) {
                if (responseMaterial.data.isSuccess && (!args.id || context.materials.length <= 0)) context.materials = responseMaterial.data.result.filter(cli => cli.idMaterial > 0).map(mat => { return { title: mat.name, id: mat.idMaterial, src: mat }; });
                if (responseEmployee.data.isSuccess && (!args.id || context.employees.length <= 0)) context.employees = responseEmployee.data.result.filter(cli => cli.idEmployee > 0).map(emp => { return { title: emp.name + " " + emp.firstName, id: emp.idEmployee, src: emp }; });
                if (responseClient.data.isSuccess && (!args.id || context.clients.length <= 0)) context.clients = responseClient.data.result.filter(cli => cli.idClient > 0).map(cli => { return { title: cli.name + " " + cli.firstName, id: cli.idClient, src: cli }; });



                var itvId = 0;
                var multiSalDefault = -1;
                if (src == null) {
                    multiSalDefault = 0;
                }
                else {
                    //console.debug(src);
                    if (parseInt(src.idWorkEvent) > 0) {
                        //Mise à jour
                        itvId = src.idWorkEvent;
                    } else {
                        //Ajout via planning
                        multiSalDefault = src.employeeID;
                    }
                }

                var indexDefault = 0;

                //Traitement des informations retourné par le Dialog
                var callbackModal = function (data) {
                    console.debug(data);

                    if (data) {
                        var svcEvt = context.services.filter((svc) => { return svc.idWorkService == data.aupdItvSvc });

                        var evt;
                        if (src) {
                            evt = src;
                            evt.category = null;
                            evt.parent = null;
                            evt.site = null;
                            evt.client = null;
                            evt.employee = null;
                            evt.report = null;
                            evt.scheduleEvent = null;
                            evt.service = null;
                            evt.serviceKm = null;
                            evt.status = null;
                            evt.subStatus = null;
                            evt.tenantUserAccess = null;
                            evt.startGeo = null;
                            evt.endGeo = null;
                        }
                        else {
                            evt = new aWorkEvent();
                        }


                        evt.clientID = data.aupdItvCli;
                        evt.serviceID = data.aupdItvSvc;
                        /*if (evt.clientID > 0) {
                            console.debug(data);
                            var cliEvt = context.clients.filter((client) => { return client.id == parseInt(evt.clientID) });
                            evt.title = (cliEvt.length ? (cliEvt[0].title) : "") + (svcEvt.length ? (" - " + svcEvt[0].name) : "");
                        } else {
                            evt.title = svcEvt.length ? svcEvt[0].name : "";
                        }*/
                        //evt.isValid = data.aupdItvVal;
                        evt.comments = data.aupdItvCmt;
                        evt.kmsCount = isNaN(data.aupdItvValue) ? 0 : parseFloat(data.aupdItvValue);


                        var dt = svcEvt[0];
                        dt.unit = "JH";//TEMP

                        switch (dt.unit) {
                            case "H":
                                var dateH = commonFunc.formatDateDMY(new Date(data.aupdItvSd));
                                var startH = moment(new Date(data.aupdItvSh)).format("HH:mm");
                                var endH = moment(new Date(data.aupdItvEh)).format("HH:mm");
                                evt.startDate = commonFunc.stringToDateRaw(dateH + ' ' + startH);
                                evt.endDate = commonFunc.stringToDateRaw(dateH + ' ' + endH);
                                break;

                            case "D":
                            case "DH":
                                var dateD = commonFunc.formatDateDMY(new Date(data.aupdItvSdDH));
                                switch (parseInt(data.aupdItvSdDHC)) {
                                    case 0: //AM
                                        evt.startDate = commonFunc.stringToDateRaw(dateD + ' 00:00');
                                        evt.endDate = commonFunc.stringToDateRaw(dateD + ' 13:00');
                                        break;
                                    case 1:
                                        evt.startDate = commonFunc.stringToDateRaw(dateD + ' 13:00');
                                        evt.endDate = commonFunc.stringToDateRaw(dateD + ' 23:59');
                                        break;
                                    case 2:
                                        evt.startDate = commonFunc.stringToDateRaw(dateD + ' 00:00');
                                        evt.endDate = commonFunc.stringToDateRaw(dateD + ' 23:59');
                                        break;
                                    default: break;
                                }
                                break;

                            case "J":
                                evt.startDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvSdJ)) + ' 00:00');
                                evt.endDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvEdJ)) + ' 23:59');
                                break;


                            case "C":
                            case "DJ":
                                var sdateDJ = commonFunc.formatDateDMY(new Date(data.aupdItvSdDJ));
                                var edateDJ = commonFunc.formatDateDMY(new Date(data.aupdItvEdDJ));
                                switch (parseInt(data.aupdItvSdDJC)) {
                                    case 0: //AM
                                        evt.startDate = commonFunc.stringToDateRaw(sdateDJ + ' 00:00');
                                        break;
                                    case 1:
                                        evt.startDate = commonFunc.stringToDateRaw(sdateDJ + ' 13:00');
                                        break;
                                    case 2:
                                        evt.startDate = commonFunc.stringToDateRaw(sdateDJ + ' 00:00');
                                        break;
                                    default: break;
                                }

                                switch (parseInt(data.aupdItvEdDJC)) {
                                    case 0: //AM
                                        evt.endDate = commonFunc.stringToDateRaw(edateDJ + ' 13:00');
                                        break;
                                    case 1:
                                        evt.endDate = commonFunc.stringToDateRaw(edateDJ + ' 23:59');
                                        break;
                                    case 2:
                                        evt.endDate = commonFunc.stringToDateRaw(edateDJ + ' 23:59');
                                        break;
                                    default: break;
                                }
                                break;


                            case "JH":
                                var startJH = moment(new Date(data.aupdItvShJH)).format("HH:mm");
                                var endJH = moment(new Date(data.aupdItvEhJH)).format("HH:mm");
                                evt.startDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvSdJH)) + ' ' + startJH);
                                evt.endDate = commonFunc.stringToDateRaw(commonFunc.formatDateDMY(new Date(data.aupdItvEdJH)) + ' ' + endJH);
                                break;
                            default: break;

                        }


                        var diff = evt.endDate - evt.startDate;
                        var diffdt = commonFunc.makeTimeFromMs(diff);
                        evt.length = parseFloat(commonFunc.makeDecimal(commonFunc.makeShortTime(diffdt)));
                        //evt.calcLength = evt.length;


                        //evt.inputMode = 4;
                        //evt.signMode = 1;
                        evt.materialID = data.aupdItvMat;
                        evt.employeeID = data.aupdItvSal;
                        evt.fromDevice = false;

                        //console.debug(evt);
                        if (multiSalDefault >= 0) {

                            Swal.fire({
                                title: 'Enregistrement en cours..',
                                // html: 'I will close in <strong></strong> seconds.',
                                didOpen: () => { Swal.showLoading(); },
                            });

                            commonFunc.postMethod("../api/workevent/CreateWorkEvent", evt).then((response) => {
                                var data = response.data;
                                Swal.close();
                                if (data && data.isSuccess) {
                                    //$('#calendar').fullCalendar('refetchEvents');
                                    Swal.fire({
                                        icon: "success",
                                        title: "Succès",
                                        html: "Création de la ligne de télégestion avec succès.",
                                        didOpen: () => {
                                            Swal.hideLoading();
                                        },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    }).then((result) => {
                                        context.loadTgEvents();
                                    });


                                } else {
                                    var supErrors = "";
                                    if (data.errors && data.errors.length) {
                                        supErrors = data.errors.map((error) => {
                                            return "<br>" + error.desc + "(" + error.value + ")";
                                        });
                                    }
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Echec',
                                        html: "Echec de création de la ligne de télégestion<br><b>" + data.errorMessage + supErrors + "</b>"
                                    });
                                }

                            });

                        } else {
                            evt.idWorkEvent = itvId;
                            if (evt.idWorkEvent > 0) {


                                Swal.fire({
                                    title: 'Mise à jour en cours..',
                                    // html: 'I will close in <strong></strong> seconds.',
                                    didOpen: () => { Swal.showLoading(); },
                                });

                                commonFunc.postMethod("../api/workevent/UpdateWorkEvent", evt).then((response) => {
                                    var data = response.data;
                                    Swal.close();
                                    if (data && data.isSuccess) {
                                        //$('#calendar').fullCalendar('refetchEvents');
                                        Swal.fire({
                                            icon: "success",
                                            title: "Succès",
                                            html: "Mise à jour de la ligne de télégestion avec succès.",
                                            didOpen: () => {
                                                Swal.hideLoading();
                                            },
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            allowEnterKey: false,
                                        }).then((result) => {
                                            context.loadTgEvents();
                                        });


                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Echec',
                                            html: "Echec de mise à jour de la ligne de télégestion<br><b>" + data.errorMessage + "</b>",
                                            didOpen: () => { Swal.hideLoading() }
                                        });
                                    }

                                });


                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Echec',
                                    html: "Echec de mise à jour , ID de ligne de télégestion manquante"
                                });

                            }
                        }
                        context.modalRoot.unmount();
                    }
                    else {
                        context.modalRoot.unmount();
                    }
                };

                context.modalRoot = createRoot(document.createElement('div'));
                context.modalRoot.render(<WorkEventModal parentContext={context} itvId={itvId} multiSalDefault={multiSalDefault} indexDefault={indexDefault} src={src}
                    callback={callbackModal}></WorkEventModal>);

                Swal.close();

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ressources',
                    text: 'Echec de chargement des resources \n ' + data.errorMessage,
                });
            }
        };

        commonFunc.getMethod("../api/site/GetSiteWorkServices", args).then((response) => {
            if (response.data.isSuccess) {
                context.services = response.data.result.filter(svc => svc.idWorkService > 0);
                commonFunc.getMethod(flagNoContract ? "../api/material/GetMaterials" : "../api/material/GetMaterials", args).then((responseMaterial) => {//GetWithContract
                    args.state = 1000;
                    if (flagNoContract && context.employees.length <= 0) commonFunc.getMethod(flagNoContract ? "../api/employee/GetEmployees" : "../api/employee/GetWithContract", args).then((responseEmployee) => {
                        args.state = 900;
                        if (flagNoContract && context.clients.length <= 0) commonFunc.getMethod(flagNoContract ? "../api/client/GetClients" : "../api/client/GetWithAgreement", args).then((responseClient) => {
                            loadRes(responseMaterial, responseEmployee, responseClient);
                        });
                        else loadRes(responseMaterial, responseEmployee, { data: { isSuccess: false } });
                    });
                    else loadRes(responseMaterial, { data: { isSuccess: false } }, { data: { isSuccess: false } });
                });

            } else {
                alert("ko");
            }
        });
    }

    bindPlanningServicesOpt() {
        var groupedOptions = {};
        this.services.forEach((dt) => { 
            if (dt.categ !== "ACT0" && !dt.isContract) {
                if (!groupedOptions[dt.categ]) {
                    groupedOptions[dt.categ] = [];
                }
                groupedOptions[dt.categ].push(dt);
            }
        });

        return Object.keys(groupedOptions).map((dt, index) => {
            return (<optgroup key={index} label={groupedOptions[dt][0].categ}>
                {groupedOptions[dt].map((e, i) => {
                    return (<option key={i} value={e.idWorkService}>{e.name}</option>)
                })}
            </optgroup>);
        });
    }

    displayIframe(title, children, options, callback) {
        var context = this;

        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }

        var settings = Object.assign({}, {
            iframeId: '',
            iframeUrl: '',
            cancelable: false,
            dialogSize: 'lg', //xs,sm,m,lg
            dialogType: '', //success,info,warning,danger,striped
            dialogInput: 1, //0,1,2,3,4
            dialogResult: 0, //0,1,2
            iconClass: '', //fa fa-spinner fa-spin
            autoClose: 0, //10000

            noScroll: false
        }, options);

        if (!callback) {
            callback = (data) => {
                console.debug(context.modalRoot);
                console.debug(data);
                if (data) {
                }
                else {
                    context.modalRoot.unmount();
                }
            }
        }


        this.modalRoot = createRoot(document.createElement('div'));
        this.modalRoot.render(<Modal className={`${settings.noScroll ? "modal-noscroll" : ""} p-0`} isOpen={true} size="lg" scrollable keyboard={false} toggle={() => callback()} backdrop="static">
            <ModalHeader className="d-flex" toggle={() => callback()}>{title}</ModalHeader>
            <ModalBody className="px-2 py-0">
                {children}

                <iframe id="geoIframe" title="geoIframe" src={settings.iframeUrl} scrolling="no" height="550" width="750" style={{ border: 0 }} data-srcinit="false" onLoad={() => {
                    /*var fd = $(this)[0].contentWindow || $(this)[0].document;
                    if (fd && typeof (fd.initialize == "function")) {
                        if (settings.args) {
                            result = fd.initialize(settings.args, false);
                        } else {
                            result = fd.initialize(null, true);
                        }
                    }*/
                }}></iframe>
            </ModalBody>
            <ModalFooter className="px-2">
                <Button className="px-3" color="danger" onClick={() => callback()}>
                    <i className="fa fa-times"></i>Fermer
                </Button>
            </ModalFooter>
        </Modal>);
    }
}


function cellFormatterAccountType({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];

    var cellValueInt = parseInt(cellvalue);
    var img;
    var desc = "";
    desc = cellValueInt;
    switch (cellValueInt) {
        case 0: img = "fa-user-circle text-primary tgevent-emp"; desc = 'Salarié'; break;
        case 2: img = "fa-user-circle text-danger tgevent-cli"; desc = 'Client'; break;
        case 4: img = "fa-box-open text-success tgevent-mat"; desc = 'Matériel'; break;
        default: img = ""; desc = cellValueInt;
    }
    return <i className={`fa ${img}`} export-data={cellValueInt} title={desc} ></i>;
}

function cellFormatterDate({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key] + "Z";
    var formatOptions = column.customOpt ? column.customOpt.formatoptions : null;

    var momentVal = moment(cellvalue);
    if (formatOptions && momentVal.year() > 1970) {
        var value = momentVal.format(formatOptions.newformat);
        return commonFunc.capitalize(value.replace(/\./g, ""));
    }
    return "";
}

function cellFormatterNumFormat({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];
    var formatOptions = column.customOpt ? column.customOpt.formatoptions : null;
    if (formatOptions) {
        var formatValue = commonFunc.formatNumber(cellvalue, formatOptions.decimalPlaces, true);
        if (formatOptions.decimalSeparator) formatValue = formatValue.toString().replace(".", formatOptions.decimalSeparator);
        return formatValue;
    }
    else {
        return "";
    }
}

function cellFormatterSignItvDesc({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];

    var cellValueInt = parseInt(cellvalue);
    switch (cellValueInt) {
        case 1: return "E/S";
        case 2: return "S";
        default: return "";
    }
}

function cellFormatterTypeItv({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];

    var cellValueInt = parseInt(cellvalue);
    var desc, img;
    switch (cellValueInt) {
        case 5099: img = "text-danger tgevent-defremoved"; desc = "Supprimé Déf."; break;//12
        case 10: img = "text-warning tgevent-noaffect"; desc = "Non Affecté"; break;//10
        case 5098: img = "text-danger tgevent-removed"; desc = "Supprimé"; break;//9
        case 5001: img = "text-info tgevent-added"; desc = "Créé"; break;//1
        case 5002: img = "text-primary tgevent-edited"; desc = "Modifié"; break;//2
        case 5003: img = "text-success tgevent-added"; desc = "Généré"; break;//?
        default: img = "text-secondary tgevent-na"; desc = "N/A"; break;
    }
    return <i className={`fa fa-circle ${img}`} export-data={cellValueInt} title={desc} data-stateID={cellValueInt}></i>;
}

function cellFormatterValidItvDesc({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];

    var cellValueInt = parseInt(cellvalue);
    switch (cellValueInt) {
        case 0: return "";
        case 1: return "manuel";
        case 2: return "auto.";
        default: return "N/A";
    }
}

function cellFormatterCheckbox({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];
    var formatOptions = column.customOpt ? column.customOpt.formatoptions : null;

    var disabled = false;
    if (formatOptions) {
        disabled = formatOptions.disabled;
    }
    return (<div className={`form-check form-check-inline fcheck-sm w-100`}>
        <Label check htmlFor={"input"} className={`d-inline-block px-0 w-100`} style={{ minWidth: "16px" }}>
            <div className={`d-inline-block`} style={{ position: "relative", top: "-12px", left: "-8px" }}>
                <Input type="checkbox" id={"input"} checked={cellvalue} disabled={disabled} />
                <span className={`form-check-sign`} />
            </div>
        </Label>
    </div>);
}


function cellFormatterDeviceItv({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];

    var img, desc;
    if (cellvalue) {
        //img = 'media/planning/th1_tg_small.png'; desc = cellvalue;
        //return "<img height='16' width='16' src='" + img + "' alt='" + cellvalue + "'title='" + desc + "' className='centerImg'/>";
        img = 'fa-mobile-alt text-info'; desc = cellvalue;
        return <i className={`fa ${img}`} export-data={cellvalue} title={desc} ></i>;
    }
    return "";
}

function cellFormatterTagItv({ row, column, childRows }) {
    if (!column.frozen && childRows && column.cHideOnGrouping) return "";
    var cellvalue = row[column.key];

    var img, desc;
    if (cellvalue) {
        //img = 'media/planning/th1_badge_small_lst.png'; desc = cellvalue;
        //return "<img height='16' width='16' src='" + img + "' alt='" + cellvalue + "'title='" + desc + "' className='centerImg'/>";
        img = 'fa-credit-card text-info'; desc = cellvalue;
        return <i className={`fa ${img}`} export-data={cellvalue} title={desc} ></i>;
    }
    return "";
}


function isItemDisabled({ props, data, triggerEvent }) {
    var dataItem = data;
    var disabled = false;

    var evtService = null;// $("#dlg-abstype option[value='" + event.serviceId + "']");
    if (evtService && evtService.data("cachesvc") && evtService.data("cachesvc").filter === "ABS") {
        //abs
        disabled |= (dataItem === "mnuItvUpd");
    }

    return disabled;
}

function mnuTgItvAct({ sysEvent, props: args, triggerEvent, data: mnuItem }) {
    this.loadTgEvents();
}

function mnuTgItvUpd({ sysEvent, props: args, triggerEvent, data: mnuItem }) {
    var context = this;

    var dataRow = args.row;
    var selRowId = dataRow.idWorkEvent;
    if (!commonFunc.parseBool(dataRow.isValid)) {
        if (parseInt(dataRow.statusID) < 5098) {//state < 9
            var argsTg = { id: selRowId };
            commonFunc.getMethod("../api/workevent/GetWorkEvent", argsTg).then((response) => {
                var event = response.data;
                if (event.isSuccess && event.result) {
                    context.addOrUpdateTG(event.result);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Mise à jour Intervention",
                        html: "Impossible d'accéder aux détails de l'intervention<p>" + event.errorMessage + "</p>",
                        didOpen: () => { Swal.hideLoading() }
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Mise à jour Intervention",
                html: "Impossible de mettre à jour une Intervention Supprimée.<p>Commencez par la restaurer.</p>",
                didOpen: () => { Swal.hideLoading() }
            });
        }
    } else {
        Swal.fire({
            icon: "error",
            title: "Mise à jour Intervention",
            html: "Impossible de mettre à jour une Intervention Validée.<p>Commencez par la dévalider</p>",
            didOpen: () => { Swal.hideLoading() }
        });
    }
}

function mnuTgItvVal({ sysEvent, props: args, triggerEvent, data: mnuItem }) {
    var context = this;

    var dataRow = args.row;
    var selRowId = dataRow.idWorkEvent;
    if (!commonFunc.parseBool(dataRow.isValid)) {
        Swal.fire({
            title: 'Chargement du statut de validation...',
            didOpen: () => { Swal.showLoading() },
        });
        var flag = dataRow.isValid;
        commonFunc.postMethod("../api/telegestion/ValidateTgEvent", { id: selRowId, isValid: !commonFunc.parseBool(flag) }).then((response) => {
            Swal.close();
            if (response.data && response.data.isSuccess) {
                context.loadTgEvents();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Validation",
                    html: "Echec de mise à jour de la validation<p>" + response.data.errorMessage + "</p>",
                    didOpen: () => { Swal.hideLoading() }
                });
            }
        });
    } else {
        Swal.fire({
            icon: "error",
            title: "Validation Intervention",
            html: "Impossible de valider une Intervention Validée.",
            didOpen: () => { Swal.hideLoading() }
        });
    }
}

function mnuTgItvFps({ sysEvent, props: args, triggerEvent, data: mnuItem }) {

}

function mnuTgItvFpc({ sysEvent, props: args, triggerEvent, data: mnuItem }) {

}

function mnuTgItvDel({ sysEvent, props: args, triggerEvent, data: mnuItem }) {
    var context = this;

    var dataRow = args.row;
    var selRowId = dataRow.idWorkEvent;
    if (!commonFunc.parseBool(dataRow.isValid)) {
        var desc = "<span>" + dataRow.employeeName;
        if (dataRow.clientID > 0) desc += " / " + dataRow.clientName;
        desc += "<br/>" + moment(dataRow.startDate).format("DD/MM/YYYY HH:mm");
        if (dataRow.endDate && (new Date(dataRow.endDate).getFullYear() > 1970 && new Date(dataRow.endDate).getFullYear() < 2099)) desc += " au " + moment(dataRow.endDate).format("DD/MM/YYYY HH:mm");
        if (dataRow.kms > 0) desc += "<br/>" + dataRow.typeKmsName + " " + dataRow.kms + "km";

        Swal.fire({
            icon: 'question',
            title: 'Suppression Intervention',
            html: "Etes-vous sûr de vouloir supprimer cette intervention [" + selRowId + "] ?<p>" + desc + "</p>",
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: "Suppression de l'intervention",
                    didOpen: () => { Swal.showLoading() },
                });
                commonFunc.postMethod("../api/workevent/RemoveWorkEvent", { id: selRowId/*, garbage: true, reason: "Suppression"*/ }).then((response) => {
                    Swal.close();
                    if (response.data && response.data.isSuccess) {
                        context.loadTgEvents();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Suppression d'intervention",
                            html: "Echec de Suppression.<p>" + response.data.errorMessage + "</p>",
                            didOpen: () => { Swal.hideLoading() }
                        });
                    }
                });

                //Dim reply = myMobSvc.RemoveTgEvent(curToken, ID, True, "Suppression")
                //removeEmployeeBusyDay(salRowId, id);
            }
        });
    } else {
        Swal.fire({
            icon: "error",
            title: "Suppression d'intervention",
            html: "Impossible de supprimer une Intervention Validée.<p>Commencez par la dévalider</p>",
            didOpen: () => { Swal.hideLoading() }
        });
    }
}

//TODO
function mnuTgItvGeo({ sysEvent, props: args, triggerEvent, data: mnuItem }) {
    var context = this;

    var dataRow = args.row;
    var selRowId = dataRow.idWorkEvent;
    commonFunc.getMethod("../weatherforecast/WFGetShortGeoTg", { id: selRowId }).then((response) => {
        if (response.data.isSuccess && response.data.result) {
            //TEMP
            dataRow = { idGeo: 0, name: "", hash: "", score: 93.8536, matchSrc: "", country: "FRANCE", precisionSrc: "fused", geoSrc: "Gps", isConfirmed: true, isLocked: false, geoLat: 48.1021065, geoLon: -0.7324475, isManual: false, dSysMod: null, dSysCreat: null, lastSyncDate: null, targetID: 0 };

            var url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCR3ctua5pvn5v-_AO0kuv1Ei6fD7U7lt0&zoom=13&q=";

            var place = dataRow.name || "Emplacement";

            /*if (dataRow.address != null) {
                place = dataRow.address.address + " " + commonFunc.pad(commonFunc.trim(dataRow.address.pc), 5) + " " + dataRow.address.city;
            }
            if (place) {
                url += place;
            }*/
            url += dataRow.geoLat + "," + dataRow.geoLon;

            context.displayIframe("Visualisation des coordonnées géographiques", <div className="widget dash">
                <h5 className="my-3 mx-2">{place}</h5>
                <div className="widget-tools collapse-tool">
                    <i className="fa fa-home fa-3x"></i>
                </div>
            </div>, { iframeUrl: url, noScroll: true });

        } else {
            Swal.fire({
                icon: "error",
                title: "Géolocalisation",
                html: "Impossible d'accéder aux détails de la ligne télégestion<p>" + response.data.errorMessage + "</p>",
                didOpen: () => { Swal.hideLoading() }
            });
        }

    });
}

export default withRouter(ManageTG);



//Création du schéma pour le paramètrage du formulaire
const itvModalSchema = yup.object().shape({
    aupdItvMat: yup.number(),//.required("Ce champ est obligatoire"),
    aupdItvSal: yup.number().required("Ce champ est obligatoire"),
    aupdItvCli: yup.number(),//.required("Ce champ est obligatoire")
    aupdItvSvc: yup.number().required("Ce champ est obligatoire"),
    aupdItvVal: yup.bool(),
    aupdItvValue: yup.string(),
    aupdItvCmt: yup.string(),

    currentDateMode: yup.string(),
    aupdItvSd: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSh: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//time
    aupdItvEh: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//time
    aupdItvSdDHC: yup.number().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DH" ? schema.required("Ce champ est obligatoire") : schema;
    }),
    aupdItvSdDH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DH" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSdJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "J" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvEdJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "J" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSdDJC: yup.number().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),
    aupdItvSdDJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvEdDJC: yup.number().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),
    aupdItvEdDJ: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "DJ" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSdJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//date
    aupdItvShJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//time
    aupdItvEdJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//date
    aupdItvEhJH: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode == "JH" ? schema.min(2, "Ce champ nécessite au moins 2 caractères.") : schema;
    }),//time
}).required();

//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de WorkEventModal
function WorkEventModal(props) {
    //Equivalent à componentDidMount ( OU componentDidUpdate etc... en fonction des paramètres)
    React.useEffect(() => {
        if (props.src) {
            var sd = commonFunc.isValidDate(props.src.startDate) ? props.src.startDate : commonFunc.restStringToDate(props.src.startDate + "Z");
            var ed = commonFunc.isValidDate(props.src.endDate) ? props.src.endDate : commonFunc.restStringToDate(props.src.endDate + "Z");

            let defaultValues = {};
            if (commonFunc.isValidDate(sd) && sd.getFullYear() > 1970) {
                //var ash = commonFunc.makeShortTime(sd);
                //var asd = commonFunc.formatDateDMY(sd, true);
                var ash = sd;
                var asd = sd;

                defaultValues.aupdItvSh = ash;
                defaultValues.aupdItvSd = asd;
                defaultValues.aupdItvSdDH = asd;
                defaultValues.aupdItvShJH = ash;
                defaultValues.aupdItvSdJH = asd;
                defaultValues.aupdItvSdDJ = asd;
                defaultValues.aupdItvSdJ = asd;
            }

            if (commonFunc.isValidDate(ed) && ed.getFullYear() > 1970) {

                //var aeh = commonFunc.makeShortTime(ed);
                //var aed = commonFunc.formatDateDMY(ed, true);
                var aeh = ed;
                var aed = ed;

                defaultValues.aupdItvEh = aeh;
                defaultValues.aupdItvEd = aed;
                defaultValues.aupdItvEdJ = aed;
                defaultValues.aupdItvEhJH = aeh;
                defaultValues.aupdItvEdJH = aed;
                defaultValues.aupdItvEdDJ = aed;


            }

            defaultValues.aupdItvValue = props.src.kmsCount;
            defaultValues.aupdItvCmt = props.src.comments;
            defaultValues.aupdItvVal = props.src.isValid;

            console.debug(defaultValues);
            reset({ ...defaultValues });
        }

        modalUpdateSvc(null);
    }, []);

    //Fonctions utilitaires du formulaire
    const { control, register, getValues, setValue, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(itvModalSchema) });
    const formManager = {
        control: control,
        register: register,
        getValues: getValues,
        setValue: setValue,
        handleSubmit: handleSubmit,
        reset: reset,
        errors: errors
    };

    //Gestion de données utile au Dialog
    const [rdvPointID, setRdvPointID] = React.useState(-1);
    const [rerender, setRerender] = React.useState(false);

    //Process effectué lors du changement de service (changement d'affichage des dates)
    function modalUpdateSvc(e) {
        var value;
        if (!e) {
            if (props.src && props.src.serviceID > 0) {
                value = props.src.serviceID;
            }
            else {
                if (props.parentContext.services.length) {
                    var filteredServices = props.parentContext.services;//.filter(svc => svc.categ !== "ACT0" && !svc.isContract);
                    value = filteredServices.length ? filteredServices[0].idWorkService : 0;
                }
                else {
                    value = 0;
                }
            }
        }
        else {
            value = e.target.value;
        }

        var svcTarget = props.parentContext.services.filter((svc) => { return svc.idWorkService == value; });
        if (svcTarget.length) {
            var dt = svcTarget[0];
            var curTag = dt.unit;
            switch (dt.unit) {
                case "H": break;
                case "D": curTag = "DH"; break;
                case "C": curTag = "DJ"; break;
                default: curTag = "JH"; break;//TEMP curTag = "JH";
            }

            //if (dt.categ !== "ACT") {
            //    setValue("aupdItvCli", -1);
            //}

            setValue("currentDateMode", curTag);
            setRerender(!rerender);//équivalent à forceUpdate

        } else {
            alert("no tag");
        }
        if (e) restItvSvc.onChange(e);
    }

    //Sélection d'un point de rdv
    function selectRdvPoint(e) {

    }

    //Fonction appeler par le formulaire si valide
    function triggerItvModalSubmit(data) {
        var flag = true;
        switch (true) {
            case getValues("currentDateMode") == "PR":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdPR));
                if (!flag) document.getElementsByName("aupdItvSdPR")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvShPR));
                if (!flag) document.getElementsByName("aupdItvShPR")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdPR));
                if (!flag) document.getElementsByName("aupdItvEdPR")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEhPR));
                if (!flag) document.getElementsByName("aupdItvEhPR")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "H":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSd));
                if (!flag) document.getElementsByName("aupdItvSd")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSh));
                if (!flag) document.getElementsByName("aupdItvSh")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSd));
                if (!flag) document.getElementsByName("aupdItvSd")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEh));
                if (!flag) document.getElementsByName("aupdItvEh")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "DH":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdDH));
                if (!flag) document.getElementsByName("aupdItvSdDH")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "J":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdJ));
                if (!flag) document.getElementsByName("aupdItvSdJ")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdJ));
                if (!flag) document.getElementsByName("aupdItvEdJ")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "DJ":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdDJ));
                if (!flag) document.getElementsByName("aupdItvSdDJ")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdDJ));
                if (!flag) document.getElementsByName("aupdItvEdDJ")[0].classList.add("is-invalid");
                break;
            case getValues("currentDateMode") == "JH":
                flag &= commonFunc.isValidDate(new Date(data.aupdItvSdJH));
                if (!flag) document.getElementsByName("aupdItvSdJH")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvShJH));
                if (!flag) document.getElementsByName("aupdItvShJH")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEdJH));
                if (!flag) document.getElementsByName("aupdItvEdJH")[0].classList.add("is-invalid");
                flag &= commonFunc.isValidDate(new Date(data.aupdItvEhJH));
                if (!flag) document.getElementsByName("aupdItvEhJH")[0].classList.add("is-invalid");
                break;
            default: break;

        }
        if (!flag) {
            Swal.fire({
                icon: 'error',
                title: 'Echec',
                html: "Veuillez renseigner des dates valides"
            });
            return;
        }

        props.callback(data);
    }

    //Déclaration des paramètres et référence pour gérer une compatibilitée avec reactstrap, autrement voici un exemple : <input {...register("password")} />
    const { ref: refItvMat, ...restItvMat } = register("aupdItvMat");
    const { ref: refItvSal, ...restItvSal } = register("aupdItvSal");
    const { ref: refItvCli, ...restItvCli } = register("aupdItvCli");
    const { ref: refItvSvc, ...restItvSvc } = register("aupdItvSvc");
    const { ref: refItvVal, ...restItvVal } = register("aupdItvVal");
    const { ref: refItvValue, ...restItvValue } = register("aupdItvValue", { value: 0, valueAsNumber: true });
    const { ref: refItvCmt, ...restItvCmt } = register("aupdItvCmt");

    const { ref: refCDateMode, ...restCDateMode } = register("currentDateMode");
    const { ref: refItvSdDHC, ...restItvSdDHC } = register("aupdItvSdDHC");
    const { ref: refItvSdDJC, ...restItvSdDJC } = register("aupdItvSdDJC");
    const { ref: refItvEdDJC, ...restItvEdDJC } = register("aupdItvEdDJC");
    //TODO : gérer le cas PR (système de saisie de date dans le dialog)

    var curSvc = null;
    if (props.parentContext.services) {
        var value = getValues("aupdItvSvc") || (props.src ? props.src.serviceID : null);
        var filterSvc = props.parentContext.services.filter((svc) => svc.idWorkService == value);
        if (filterSvc.length) {
            curSvc = filterSvc[0];
        }
    }

    var renderDateMode = getValues("currentDateMode");
    return (
        <Modal className="p-0" isOpen={true} size="lg" scrollable keyboard={false} toggle={() => { props.callback(); }} backdrop="static">
            <ModalHeader className="d-flex" toggle={() => { props.callback(); }}>{props.itvId > 0 ? "Modifier une intervention" : "Ajouter une intervention"}</ModalHeader>
            <ModalBody className="px-2 py-0">
                <Form id="frmaupditv" action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerItvModalSubmit)}>
                    <Card className="mb-2">
                        <CardHeader className="d-flex">
                            <h5 className="title mb-0">Intervention</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="borderTheme m-0"></div>
                            <Row className="align-items-center mt-2">
                                <Col sm="2"><Label for="aupdItvSal">Intervenant :</Label></Col>
                                <Col sm="10">
                                    <Input id="aupdItvSal" name="aupdItvSal" placeholder="Choix Intervenant" type="select"
                                        defaultValue={props.src ? props.src.employeeID : null}
                                        innerRef={refItvSal} {...restItvSal}
                                        invalid={errors.aupdItvSal ? true : false}>
                                        {props.parentContext.employees.map((e, i) => {
                                            return <option key={i} value={e.id}>{e.title}</option>;
                                        })}
                                    </Input>
                                    <FormFeedback className="text-start">{errors.aupdItvSal ? errors.aupdItvSal.message : "Ce champ est obligatoire"}</FormFeedback>
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2">
                                <Col sm="2"><Label for="aupdItvSvc">Service :</Label></Col>
                                <Col sm="10">
                                    <Input id="aupdItvSvc" name="aupdItvSvc" placeholder="Service" type="select"
                                        defaultValue={props.src ? props.src.serviceID : null}
                                        innerRef={refItvSvc} {...restItvSvc}
                                        onChange={modalUpdateSvc}
                                        invalid={errors.aupdItvSvc ? true : false}>
                                        {props.parentContext.bindPlanningServicesOpt()}
                                    </Input>
                                    <FormFeedback className="text-start">{errors.aupdItvSvc ? errors.aupdItvSvc.message : "Ce champ est obligatoire"}</FormFeedback>
                                </Col>
                            </Row>
                            <div className={`hr-line-dashed siActAAfficher`}></div>
                            <Row>
                                <Col sm="2"><Label for="aupdItvMat">Matériel :</Label></Col>
                                <Col sm="10">
                                    <Input id="aupdItvMat" name="aupdItvMat" placeholder="Choix Matériel" type="select"
                                        defaultValue={props.src ? props.src.materialID : null}
                                        innerRef={refItvMat} {...restItvMat}
                                        invalid={errors.aupdItvMat ? true : false}>
                                        <option key={0} value="-1">Aucun</option>
                                        {props.parentContext.materials.map((e, i) => {
                                            return <option key={i} value={e.id}>{e.title}</option>;
                                        })}
                                    </Input>
                                    <FormFeedback className="text-start">{errors.aupdItvMat ? errors.aupdItvMat.message : "Ce champ est obligatoire"}</FormFeedback>
                                </Col>
                            </Row>
                            <Row className={`align-items-center mt-2 siActAAfficher`}>
                                <Col sm="2"><Label for="aupdItvCli">Client :</Label></Col>
                                <Col sm="10">
                                    <Input id="aupdItvCli" name="aupdItvCli" placeholder="Client" type="select" /*disabled={(props.src && props.src.clientID > 0) ? true : false}*/
                                        defaultValue={props.src ? props.src.clientID : null}
                                        innerRef={refItvCli} {...restItvCli}
                                        invalid={errors.aupdItvCli ? true : false}>
                                        <option key={0} value="-1">Aucun</option>
                                        {props.parentContext.clients.map((client, i) => (<option key={i} value={client.id}>{client.title}</option>))}
                                    </Input>
                                    <FormFeedback className="text-start">{errors.aupdItvCli ? errors.aupdItvCli.message : "Ce champ est obligatoire"}</FormFeedback>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mb-2">
                        <CardHeader className="d-flex">
                            <h5 className="title mb-0">Date et Heures</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="borderTheme m-0"></div>
                            <Input id="currentDateMode" name="currentDateMode"
                                innerRef={refCDateMode} {...restCDateMode}
                                invalid={errors.currentDateMode ? true : false} className="hidden" />
                            <div id="dlg-addUpdateItv-2" className={renderDateMode == "H" ? "filter-period" : "filter-period hidden"} data-bindtag="H">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSd">Date :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSd'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={`form-control`}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSd ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSd ? "d-block" : ""}`}>{errors.aupdItvSd ? errors.aupdItvSd.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="5"><Label>{" "}</Label></Col>
                                </Row>
                                <div className="hr-line-dashed"></div>
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSh">De :</Label></Col>
                                    <Col sm="3">
                                        <Controller
                                            control={control}
                                            name='aupdItvSh'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSh ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSh ? "d-block" : ""}`}>{errors.aupdItvSh ? errors.aupdItvSh.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvEh">{" à "}</Label></Col>
                                    <Col sm="3">
                                        <Controller
                                            control={control}
                                            name='aupdItvEh'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEh ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEh ? "d-block" : ""}`}>{errors.aupdItvEh ? errors.aupdItvEh.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                            <div id="dlg-addUpdateItv-3" className={renderDateMode == "DH" ? "filter-period" : "filter-period hidden"} data-bindtag="DH">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdDH">Date :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdDH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={`form-control`}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdDH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdDH ? "d-block" : ""}`}>{errors.aupdItvSdDH ? errors.aupdItvSdDH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                    <Col sm="5">
                                        <Input id="aupdItvSdDHC" name="aupdItvSdDHC" className="filter-period-trigger" type="select"
                                            innerRef={refItvSdDHC} {...restItvSdDHC}
                                            invalid={errors.aupdItvSdDHC ? true : false}>
                                            <option value="0">Matin</option>
                                            <option value="1">Après-Midi</option>
                                            <option value="2">Journée Complète</option>

                                        </Input>
                                        <FormFeedback className="text-start">{errors.aupdItvSdDHC ? errors.aupdItvSdDHC.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                            <div id="dlg-addUpdateItv-4" className={renderDateMode == "J" ? "filter-period" : "filter-period hidden"} data-bindtag="J">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdJ">Du :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={`form-control`}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdJ ? "d-block" : ""}`}>{errors.aupdItvSdJ ? errors.aupdItvSdJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvEdJ">Au :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvEdJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEdJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEdJ ? "d-block" : ""}`}>{errors.aupdItvEdJ ? errors.aupdItvEdJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                </Row>
                            </div>

                            {/*TODO : PR*/}

                            <div id="dlg-addUpdateItv-5" className={renderDateMode == "DJ" ? "filter-period" : "filter-period hidden"} data-bindtag="DJ">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdDJ">Du :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdDJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdDJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdDJ ? "d-block" : ""}`}>{errors.aupdItvSdDJ ? errors.aupdItvSdDJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                    <Col sm="5">
                                        <Input id="aupdItvSdDJC" name="aupdItvSdDJC" className="filter-period-trigger" type="select"
                                            innerRef={refItvSdDJC} {...restItvSdDJC}
                                            invalid={errors.aupdItvSdDJC ? true : false}>
                                            <option value="0">Matin</option>
                                            <option value="1">Après-Midi</option>
                                            <option value="2" selected="selected">Journée Complète</option>

                                        </Input>
                                        <FormFeedback className="text-start">{errors.aupdItvSdDJC ? errors.aupdItvSdDJC.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                                <div className="hr-line-dashed"></div>
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvEdDJ">Au :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvEdDJ'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEdDJ ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEdDJ ? "d-block" : ""}`}>{errors.aupdItvEdDJ ? errors.aupdItvEdDJ.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label>{" "}</Label></Col>
                                    <Col sm="5">
                                        <Input id="aupdItvEdDJC" name="aupdItvEdDJC" className="filter-period-trigger" type="select"
                                            innerRef={refItvEdDJC} {...restItvEdDJC}
                                            invalid={errors.aupdItvEdDJC ? true : false}>
                                            <option value="0">Matin</option>
                                            <option value="1">Après-Midi</option>
                                            <option value="2" selected="selected">Journée Complète</option>

                                        </Input>
                                        <FormFeedback className="text-start">{errors.aupdItvEdDJC ? errors.aupdItvEdDJC.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                            <div id="dlg-addUpdateItv-7" className={renderDateMode == "JH" ? "filter-period" : "filter-period hidden"} data-bindtag="JH">
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvSdJH">Du :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvSdJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvSdJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvSdJH ? "d-block" : ""}`}>{errors.aupdItvSdJH ? errors.aupdItvSdJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvShJH"></Label></Col>
                                    <Col sm="5">
                                        <Controller
                                            control={control}
                                            name='aupdItvShJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvShJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvShJH ? "d-block" : ""}`}>{errors.aupdItvShJH ? errors.aupdItvShJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                                <div className="hr-line-dashed"></div>
                                <Row className="align-items-center mt-2">
                                    <Col sm="2"><Label for="aupdItvEdJH">Au :</Label></Col>
                                    <Col sm="4">
                                        <Controller
                                            control={control}
                                            name='aupdItvEdJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat={false}
                                                    locale="fr"
                                                    placeholderText="__/__/____"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}
                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEdJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEdDJ ? "d-block" : ""}`}>{errors.aupdItvEdJH ? errors.aupdItvEdJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                    <Col sm="1"><Label for="aupdItvEhJH"></Label></Col>
                                    <Col sm="5">
                                        <Controller
                                            control={control}
                                            name='aupdItvEhJH'
                                            render={({ field: { ref, ...field } }) => (
                                                <DPCustom
                                                    className={"form-control"}
                                                    showWeekNumbers={false}
                                                    dateFormat={"HH:mm"}
                                                    timeFormat="HH:mm"
                                                    locale="fr"
                                                    placeholderText="__:__"
                                                    autoComplete="no" aria-autocomplete="none"
                                                    selected={field.value ? field.value : ""}

                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Heure"

                                                    innerRef={ref} {...field}
                                                    invalid={errors.aupdItvEhJH ? true : false}

                                                    customInput={
                                                        <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} />
                                                    }
                                                />
                                            )}
                                        />
                                        <FormFeedback className={`text-start ${errors.aupdItvEhJH ? "d-block" : ""}`}>{errors.aupdItvEhJH ? errors.aupdItvEhJH.message : "Ce champ est obligatoire"}</FormFeedback>
                                    </Col>
                                </Row>
                            </div>

                        </CardBody>
                    </Card>
                    {/*true ? "" : <Card className={`${props.src && props.src.reportID > 0 ? "hidden" : ""}`}>
                        <CardHeader className="d-flex">
                            <h5 className="title mb-0">Adresse</h5>
                        </CardHeader>
                        <CardBody>  
                            <div className="borderTheme m-0"></div>
                            <Row className="align-items-center mt-2">
                                <Col sm="2"><Label className="mb-0" for="aupdItvSal">Point de RDV :</Label></Col>
                                <Col sm="5">
                                    <i><b>Par défaut</b></i>
                                    <FinderFrag data={{}} bindPropResult={"rdvPoint"} formManager={formManager} />
                                </Col>
                                <Col sm="5">
                                    <Button className="btn btn-simple btn-dark" onClick={selectRdvPoint}>Choisir</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>*/}

                    <FormGroup className="mx-3" style={{ width: "200px" }}>
                        <Label for="exampleText">
                            Kilomètres
                        </Label>
                        <Input
                            id="itvValue"
                            name="textValue"
                            className="p-1"
                            type="number"
                            innerRef={refItvValue} {...restItvValue}
                        />
                    </FormGroup>

                    <FormGroup className="mx-3">
                        <Label for="exampleText">
                            Commentaires
                        </Label>
                        <Input
                            id="exampleText"
                            name="text"
                            className="textarea p-1"
                            type="textarea"
                            cols="5"
                            rows="3"
                            style={{ resize: "auto", maxHeight: "none" }}
                            innerRef={refItvCmt} {...restItvCmt}
                        />
                    </FormGroup>

                    <FormGroup check className='fcheck-sm text-start mx-3 d-none'>
                        <Label check >
                            <Input type="checkbox" innerRef={refItvVal} {...restItvVal} />
                            <span className="form-check-sign" />
                            Validé
                        </Label>
                    </FormGroup>

                </Form>
            </ModalBody>
            <ModalFooter className="px-2">
                <Button className="px-3" color={props.itvId > 0 ? "primary" : "success"} form="frmaupditv">
                    <i className="fa fa-save"></i>{props.itvId > 0 ? "Enregistrer" : "Ajouter"}
                </Button>{' '}
                <Button className="px-3" color="danger" onClick={() => { props.callback(); }}>
                    <i className="fa fa-times"></i>Annuler
                </Button>
            </ModalFooter>
        </Modal>
    );
}


function aWorkEvent() {
    this.idWorkEvent = 0;
    this.clientID = -1;
    this.employeeID = -1;
    //this.orgID = 0;
    this.serviceID = -1;
    this.materialID = -1;
    this.reportID = -1;
    /*this.demandID = -1;

    this.statusID = 2;
    this.startDate = null;
    this.endDate = null;
    this.length = 0.0;
    this.title = ""

    //this.clientName As String = ""
    //this.salName As String = ""
    //this.svcName As String = ""
    //this.isWaitingConfirm As Boolean = False
    this.isValid = false;
    this.isGenerated = false;
    this.isSandBox = false;
    this.comments = "";
    this.dateSysconf = new Date();
    this.dateSysMod = new Date();*/

    //allowCrossEvents
    //allowSplit
    //value
}