import React from 'react';
import Swal from "sweetalert2";
import moment from 'moment';

import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.i18n";


import DPCustom from 'components/DPCustom';

import * as commonFunc from 'scripts/common';

import {
    Input,
    Label,
    FormFeedback,
    InputGroup, InputGroupText, Col
} from "reactstrap";


export default function EditTextFrag({ data, formManager, modelFctManager, bindPropResult, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors, control } = formManager;

    const [options, setOptions] = React.useState(data.options || []);
    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        if ((data.subType === "date" || data.subType === "dateonly" || data.subType === "time")) {//TODO : check si autre solution pour ne pas faire apparaitre de date via defaultValue
            var defaultValue = data.defaultValue || "";
            var dDateValue = getInitDate(defaultValue);
            formatValue(dDateValue, true);

        }
        if (data.subType === "phone") setValue(bindPropResult, data.defaultValue || "");

        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }, []);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    function getInitDate(dateValue) {
        var initValue = dateValue;
        if (dateValue === "{true}") dateValue = new Date();
        else if (dateValue.startsWith && dateValue.startsWith("{M-")) {
            var modif = dateValue.replace("{M-", "").replace("}", "");
            dateValue = moment().subtract(parseFloat(modif), 'months').toDate();
        }
        else if (dateValue.startsWith && dateValue.startsWith("{W-")) {
            var modifWeeks = dateValue.replace("{W-", "").replace("}", "");
            dateValue = moment().subtract(parseFloat(modifWeeks), 'weeks').toDate();
        }
        else if (dateValue.startsWith && dateValue.startsWith("{D-")) {
            var modifDays = dateValue.replace("{D-", "").replace("}", "");
            dateValue = moment().subtract(parseFloat(modifDays), 'days').toDate();
        }
        var dDateValue = ((dateValue instanceof Date) ? dateValue : commonFunc.restStringToDate(dateValue));
        //Si date invalide (pas de type Date OU pas équivalent à 2023-12-12T00:00)
        if (!commonFunc.isValidDate(dDateValue)) {
            //dDateValue = (data.propNameJsType === "date" ? new Date() : initValue);
            dDateValue = (data.propNameJsType === "date" ? null : initValue);
            if (data.subType === "time") {
                var split = dateValue.split(":");
                //Si la date colle avec HH:mm
                if (split[0] && split[1]) dDateValue = moment().set({ 'hour': parseInt(split[0]), 'minute': parseInt(split[1]) }).toDate();
            }
        }
        else dDateValue = moment(dDateValue).utc(true).toDate();
        return dDateValue;
    }

    function formatValue(valueInput, forceSet) {
        if (data.subType === "integer" || data.subType === "float") {
            valueInput = valueInput.replace ? valueInput.replace(",", ".") : valueInput;

            var defaultSysValue = data.propNameJsType === "number" ? 0 : "";/*(data.attributes && data.attributes.required !== undefined) ? 0 : ""*/;

            if (!forceSet) forceSet = isNaN(valueInput);
            valueInput = (!commonFunc.isBlank(valueInput) && !isNaN(valueInput) ? valueInput : defaultSysValue);

            if (data.subType === "integer") {
                if (!forceSet) forceSet = commonFunc.isBlank(valueInput) || (!(typeof valueInput === "number") && getValues(bindPropResult) !== parseInt(valueInput).toString());
                valueInput = parseInt(valueInput) || defaultSysValue;
            }
            else if (data.subType === "float") {
                if (!forceSet) forceSet = commonFunc.isBlank(valueInput) || (!(typeof valueInput === "number") && getValues(bindPropResult) === parseFloat(valueInput).toString());
                valueInput = parseFloat(valueInput) || defaultSysValue;
            }
        }
        if (((data.subType === "date" || data.subType === "dateonly" || data.subType === "time") && data.propNameJsType === "date") && valueInput === null) valueInput = moment("1970-01-01T00:00").toDate();

        var formatValue = valueInput;
        if (data.format) {
            data.rawValue = valueInput;
            if (data.subType === "date" || data.subType === "dateonly" || data.subType === "time") {
                formatValue = moment(valueInput).format(data.format);
                if (commonFunc.isValidDate(moment(valueInput).toDate())) {
                    setValue(bindPropResult, formatValue);
                }
                else {
                    setValue(bindPropResult, valueInput);
                }
            }
        }
        else if (forceSet) {
            setValue(bindPropResult, valueInput);
        }
        return formatValue;
    }

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.setValue = (value, withRerender, src) => {
        return new Promise((resolve, reject) => {
            //setValue(bindPropResult, value);
            formatValue(value, true);
            if (withRerender) setShouldRender(!shouldRender);

            resolve({ isSuccess: true, result: value });
        });
    };
    data.functionManager.setDateonly = (value) => {
        return new Promise((resolve, reject) => {
            var val = bindPropResult ? getValues(bindPropResult) : "";
            var valueDate = data.rawValue || val;
            var dateValue = commonFunc.restStringToDate(moment(value).format("YYYY-MM-DDT") + (moment(valueDate).isValid() ? moment(valueDate).format("HH:mm") : "00:00"));
            //setValue(bindPropResult, dateValue);
            if (commonFunc.isValidDate(dateValue)) {
                formatValue(dateValue, true);

                onChange();


                resolve({ isSuccess: true, result: dateValue });
            }
        });
    };
    data.functionManager.getValue = (src) => {
        return bindPropResult ? getValues(bindPropResult) : "";
    };
    data.functionManager.populateData = populateData;
    data.functionManager.findItem = (value) => {
        return options.filter(item => item.value === value);
    };

    //console.debug("test value " + bindPropResult + " " + data.defaultValue);

    var defaultValue = data.defaultValue !== undefined ? data.defaultValue : "";

    const { ref: refElem, ...restElem } = register(bindPropResult, {
        value: (data.subType === "integer" || data.subType === "float") ? (getValues(bindPropResult) ? formatValue(getValues(bindPropResult)) : formatValue(defaultValue)) : defaultValue,
        //valueAsNumber: (data.subType === "integer" || data.subType === "float"),
        valueAsDate: ((data.subType === "date" || data.subType === "dateonly" || data.subType === "time") && data.propNameJsType === "date"),
        onChange: (e) => {
            onChange();
        }   
    });

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));


    function onChange() {
        console.debug("onChange");
        var val = bindPropResult ? getValues(bindPropResult) : "";
        formatValue(val);
        if (((data.subType === "date" || data.subType === "dateonly" || data.subType === "time") && data.propNameJsType === "date") && val === null) setValue(bindPropResult, moment("1970-01-01T00:00").toDate());

        if (data.onActionEvents && data.onActionEvents["onChange"]) {
            data.onActionEvents["onChange"].forEach((args, index) => {
                var val = bindPropResult ? getValues(bindPropResult) : "";
                modelFctManager.onActionRequired(data.name, args, data, { type: data.subType, value: (data.rawValue || val) }).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }

    function populateData(dataOptions, triggerOnChange) {
        return new Promise((resolve, reject) => {
            //console.debug("spinnerFrag populateOptions " + bindPropResult);
            //data.options = dataOptions;
            var defaultOptions = data.options ? data.options.filter((opt) => opt.subType === "default") : [];
            var componentVal = bindPropResult ? getValues(bindPropResult) : "";
            if (dataOptions && dataOptions.length) {
                var newOptions = [...defaultOptions, ...dataOptions];
                setOptions(newOptions);

                if (triggerOnChange) onChange();

                resolve({ isSuccess: true, result: componentVal });
            }
            else {
                setOptions(defaultOptions);

                if (triggerOnChange) onChange(componentVal);

                resolve({ isSuccess: true, result: componentVal });
            }
        });
    }

    function renderInput(inputClass) {
        //TODO : formattage chargement valeurs

        if (data.subType === "date" || data.subType === "dateonly" || data.subType === "time") {
            var minDate = (data.attributes && data.attributes) ? data.attributes.min : undefined;
            if (minDate) minDate = getInitDate(minDate);
            var minTime = (data.attributes && data.attributes) ? data.attributes.min : undefined;
            if (minTime) minTime = getInitDate(minTime);
        }

        switch (data.subType) {
            case "date":
                //var dDateValue = commonFunc.restStringToDate(data.defaultValue);
                var dDateValue = "";
                if (data.defaultValue) {
                    dDateValue = getInitDate(data.defaultValue);
                }
                return <Controller
                    control={control}
                    name={bindPropResult}
                    render={({ field: { ref, ...field } }) => (
                        <DPCustom
                            id={"input" + bindPropResult}
                            parentClassName={`p-0 ${inputClass}`}
                            className={`form-control`}
                            defaultValue={data.defaultValue} readOnly={data.readOnly}
                            showWeekNumbers={false}
                            dateFormat="dd/MM/yyyy"
                            timeFormat="HH:mm"
                            minDate={minDate}
                            minTime={minTime}
                            locale="fr"
                            placeholderText={!commonFunc.isBlank(data.placeHolder) ? altT(data.placeHolder) : "__/__/____ __:__"}
                            autoComplete="no" aria-autocomplete="none"
                            selected={(data.rawValue || ((field.value instanceof Date) ? field.value : dDateValue))}
                            disabled={disabled}

                            showTimeSelect
                            timeIntervals={15}
                            timeCaption="Heure"

                            innerRef={ref} {...field}
                            value={data.rawValue || ((field.value instanceof Date) ? field.value : dDateOValue)}
                            invalid={commonFunc.getValue(errors, bindPropResult) ? true : false}

                            customInput={
                                <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} list={"inputList" + bindPropResult} />
                                //<Cleave options={{ delimiters: ['/', '/', ' ', ':'], blocks: [2, 2, 4, 2, 2] }} />
                            }
                        />
                    )}
                />;
            case "dateonly":
                //var dDateOValue = commonFunc.restStringToDate(data.defaultValue);
                var dDateOValue = "";
                if (data.defaultValue) {
                    dDateOValue = getInitDate(data.defaultValue);
                }
                return <Controller
                    control={control}
                    name={bindPropResult}
                    render={({ field: { ref, ...field } }) => {
                        //console.debug(data.rawValue);
                        //console.debug(field.value);

                        var displayDate = data.rawValue || ((field.value instanceof Date) ? field.value : dDateOValue);
                        if ((!data.argumentsObj || !data.argumentsObj.allowEpoch) && (new Date(displayDate).getFullYear() <= 1970 || new Date(displayDate).getFullYear() >= 2099)) displayDate = "";
                        return (
                            <DPCustom
                                id={"input" + bindPropResult}
                                parentClassName={`p-0 ${inputClass}`}
                                className={`form-control`}
                                defaultValue={data.defaultValue} readOnly={data.readOnly}
                                showWeekNumbers={false}
                                dateFormat="dd/MM/yyyy"
                                timeFormat={false}
                                minDate={minDate}
                                minTime={minTime}
                                locale="fr"
                                placeholderText={!commonFunc.isBlank(data.placeHolder) ? altT(data.placeHolder) : "__/__/____"}
                                autoComplete="no" aria-autocomplete="none"
                                selected={commonFunc.isBlank(displayDate) ? new Date() : displayDate}
                                disabled={disabled}
                                innerRef={ref} {...field}
                                value={displayDate}
                                invalid={commonFunc.getValue(errors, bindPropResult) ? true : false}

                                customInput={
                                    <Cleave autoComplete="no" aria-autocomplete="none" options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }} list={"inputList" + bindPropResult} />
                                }
                            />
                        );
                    }}
                />;
            case "time":
                //var dTimeValue = commonFunc.restStringToDate(data.defaultValue);
                var dTimeValue = "";
                if (data.defaultValue) {
                    dTimeValue = getInitDate(data.defaultValue);
                }
                return <Controller
                    control={control}
                    name={bindPropResult}
                    render={({ field: { ref, ...field } }) => {
                        //console.debug(data.rawValue);
                        //console.debug(field.value);

                        var displayDate = data.rawValue || ((field.value instanceof Date) ? field.value : dDateOValue);
                        if ((!data.argumentsObj || !data.argumentsObj.allowEpoch) && (new Date(displayDate).getHours() <= 0 && new Date(displayDate).getMinutes() <= 0)) displayDate = "";
                        return (
                            <DPCustom
                                id={"input" + bindPropResult}
                                parentClassName={`p-0 ${inputClass}`}
                                className={`form-control`}
                                readOnly={data.readOnly}
                                showWeekNumbers={false}
                                dateFormat={"HH:mm"}
                                timeFormat="HH:mm"
                                minDate={minDate}
                                minTime={minTime}
                                locale="fr"
                                placeholderText={!commonFunc.isBlank(data.placeHolder) ? altT(data.placeHolder) : "__:__"}
                                autoComplete="no" aria-autocomplete="none"
                                selected={(data.rawValue || ((field.value instanceof Date) ? field.value : dTimeValue))}
                                disabled={disabled}

                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Heure"

                                innerRef={ref} {...field}
                                value={displayDate}
                                invalid={commonFunc.getValue(errors, bindPropResult) ? true : false}

                                customInput={
                                    <Cleave autoComplete="no" aria-autocomplete="none" options={{ time: true, timePattern: ['h', 'm'] }} list={"inputList" + bindPropResult} />
                                }
                            />
                        );
                    }}
                />;
            case "text_5":
                return <Input type="textarea" id={"input" + bindPropResult} className={`${inputClass}`} placeholder={altT(data.placeHolder)} defaultValue={data.defaultValue || ""} readOnly={data.readOnly} autoComplete="no" aria-autocomplete="none"
                    cols="5" rows="3" style={{ resize: "auto", maxHeight: "none" }} disabled={disabled} list={"inputList" + bindPropResult}
                    innerRef={refElem} {...restElem} invalid={commonFunc.getValue(errors, bindPropResult) ? true : false} />;
            case "integer":
            case "float"://TODO : check si autre solution pour ne pas faire apparaitre le zéro via defaultValue
                return <Input type="text" id={"input" + bindPropResult} className={`${inputClass}`} placeholder={altT(data.placeHolder)} defaultValue={data.defaultValue || 0} readOnly={data.readOnly} autoComplete="no" aria-autocomplete="none"
                    disabled={disabled} list={"inputList" + bindPropResult}
                    innerRef={refElem} {...restElem} invalid={commonFunc.getValue(errors, bindPropResult) ? true : false} />;
            case "phone":
                return <Controller
                    control={control}
                    name={bindPropResult}
                    render={({ field: { ref, ...field } }) => (
                        <Cleave id={"input" + bindPropResult} className={`form-control ${inputClass} ${commonFunc.getValue(errors, bindPropResult) ? "is-invalid" : ""}`} placeholder={altT(data.placeHolder)} readOnly={data.readOnly} autoComplete="no" aria-autocomplete="none"
                            options={{ phone: true, phoneRegionCode:"FR" }}//TODO : phoneRegionCode gestion pays
                            value={field.value} disabled={disabled} list={"inputList" + bindPropResult}
                            htmlRef={ref} {...field} />
                    )}
                />;
            case "password":
                return <Input type="password" id={"input" + bindPropResult} className={`${inputClass}`} placeholder={altT(data.placeHolder)} defaultValue={data.defaultValue || ""} readOnly={data.readOnly} autoComplete="new-password" aria-autocomplete="none"
                    disabled={disabled} list={"inputList" + bindPropResult}
                    innerRef={refElem} {...restElem} invalid={commonFunc.getValue(errors, bindPropResult) ? true : false} />;
            case "default"://TEMP
                return <Input type="text" id={"input" + bindPropResult} className={`${inputClass} ${data.format}`} placeholder={altT(data.placeHolder)} defaultValue={data.defaultValue || ""} readOnly={data.readOnly} autoComplete="no" aria-autocomplete="none"
                    disabled={disabled} list={"inputList" + bindPropResult}
                    innerRef={refElem} {...restElem} /*valid={commonFunc.getValue(errors, bindPropResult) ? false : true}*/ invalid={commonFunc.getValue(errors, bindPropResult) ? true : false} />;
            case "email":
            default:
                return <Controller
                    control={control}
                    name={bindPropResult}
                    render={({ field: { ref, ...field } }) => (
                        <Cleave id={"input" + bindPropResult} className={`form-control ${inputClass} ${data.format} ${commonFunc.getValue(errors, bindPropResult) ? "is-invalid" : ""}`} placeholder={altT(data.placeHolder)} defaultValue={data.defaultValue || ""} readOnly={data.readOnly} autoComplete="no" aria-autocomplete="none"
                            options={{ delimiter: "@#/*", lowercase: (data.argumentsObj && data.argumentsObj.lowercase ? data.argumentsObj.lowercase : false), uppercase: (data.argumentsObj && data.argumentsObj.uppercase ? data.argumentsObj.uppercase : false) }}
                            value={field.value} disabled={disabled} list={"inputList" + bindPropResult}
                            htmlRef={ref} {...field} />
                    )}
                />;

        }
    }

    function renderTitle(positions, titleWidthClass) {
        var design = data.customDesign ? data.customDesign.designTitle : null;
        if (!design) design = {}
        if (!design.position) design.position = "left";

        var titleModel = <div className={`${titleWidthClass}`}><Label className={` ${(design.position === "left" || design.position === "right") ? "m-0" : ""} ${(!commonFunc.isBlank(data.title) && data.required) ? "mandatory" : ""}`} for={"input" + bindPropResult}>{altT(data.title)}</Label></div>;

        if (!commonFunc.isBlank(data.title) && positions.indexOf(design.position) >= 0) return titleModel;
        else return "";
    }

    var frameClass = "mb-3";
    var frameStyle = {};
    var containerClass = "";
    var containerStyle = {};
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    var formClass = "form-group m-0 ";

    var widthClass = "";
    var inputClass = "";
    var titleWidthClass = "px-0 ";
    if (data.customDesign) {
        if (data.customDesign.design) {
            if (data.customDesign.design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.design.boxSize) * 100);
            }
            if (data.customDesign.design.margin) {
                frameStyle.margin = data.customDesign.design.margin;
                frameClass = frameClass.replace("mb-3", "");
            }
            if (data.customDesign.design.width) containerStyle.width = data.customDesign.design.width;
        }
        if (data.customDesign.designInput) {
            if (data.customDesign.designInput.boxSize) {
                //widthClass += " w-" + parseInt(parseFloat(data.customDesign.designInput.boxSize) * 100);
                widthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designInput.boxSize) * 100);
            }
        }
        if (data.customDesign.designTitle) {
            if (data.customDesign.designTitle.alignment) {
                var split = data.customDesign.designTitle.alignment.split("|");
                split.forEach((part) => {
                    titleWidthClass += " text-" + part.replace("left", "start").replace("right", "end");
                    titleWidthClass = titleWidthClass.replace("text-top", "align-self-start").replace("text-bottom", "align-self-end");
                });
            }
            if (data.customDesign.designTitle.position === "floating") {
                data.placeHolder = data.title;
                formClass += " form-floating min-input";
            }
            if (data.customDesign.designTitle.boxSize) {
                //titleWidthClass += " w-" + parseInt(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass = titleWidthClass.replace("px-0", "ps-3 px-md-0").replace("text-end", "text-md-end");
            }
        }

        if (!commonFunc.isBlank(widthClass) || titleWidthClass !== "px-0 ") containerClass += " row m-0 row-cols-lg-auto2 align-items-center";
    }

    var inputContainer = renderInput(inputClass);
    if (options && options.length) {
        inputContainer = <>{inputContainer}<datalist id={"inputList" + bindPropResult}>{options.map((option, optIndex) => <option key={optIndex} value={option.value}>{option.title}</option>)}</datalist></>;
    }
    if (data.children) {
        inputContainer = <div className="d-flex">{inputContainer}{data.children.map((child) => modelFctManager.componentTyped(child, parentProps))}</div>
    }


    var baseForm = <div className={`${containerClass} ${formClass}`} style={containerStyle}>
        {renderTitle(["top", "left"], titleWidthClass)}
        <div className={`${widthClass}`}>
            {inputContainer}
            <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
        </div>
        {renderTitle(["bottom", "right", "floating"], titleWidthClass)}
    </div>;

    if (data.customDesign && data.customDesign.designTitle && data.customDesign.designTitle.position === "floating") {
        baseForm = <div className={`${containerClass}`} style={containerStyle}>
            <div className={`${widthClass} ${formClass}`}>
                <div className={`${formClass}`}>
                    {inputContainer}
                    {renderTitle(["floating"])}
                </div>
            </div>
            <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
        </div>;
    }

    if (data.customDesign && data.customDesign.designIcon) {
        var iconDesign = data.customDesign.designIcon;

        baseForm = <div className={`${containerClass}`} style={containerStyle}>
            {renderTitle(["top", "left"], titleWidthClass)}
            <InputGroup className={`single-input ${widthClass}`}>
                <InputGroupText className={`${(data.attributes && data.attributes.required !== undefined) ? "mandatory" : ""}`} title={data.customDesign && data.customDesign.designTitle && data.customDesign.designTitle.position === "title" ? altT(data.title) : ""} >
                    <i className={`fa ${iconDesign.icon.replace(/_/g, "-") }`} />
                </InputGroupText>
                <div className={`${formClass}`} style={{flex:1}}>
                    {inputContainer}
                    {renderTitle(["floating"])}
                </div>
                <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
            </InputGroup>
            {renderTitle(["bottom", "right"], titleWidthClass)}
        </div>;
    }
    //console.debug("render input");

    return <Col data-name="EditTextFrag" data-element={data.objType} xs="auto" className={`${frameClass} ${parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`} style={frameStyle}>{baseForm}</Col>;//align-self-center
}