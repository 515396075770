import * as commonFunc from 'scripts/common';
import Swal from "sweetalert2";
import moment from "moment";

import {
    UncontrolledPopover, PopoverHeader, PopoverBody,
} from "reactstrap";

import Img from 'components/Img';

export function loadFilesLst(bindID, fileTypeID, srcManager) {
    if (!srcManager) srcManager = {};
    return new Promise((resolve, reject) => {
        var id = bindID;
        if (parseInt(id) > 0) {
            Swal.fire({
                title: 'Chargement en cours...',
                didOpen: () => { Swal.showLoading() },
            });

            /*Fichiers*/
            var args = { filetypeID: (fileTypeID || 0), bindID: id, fileID: -1, finalFilesOnly: srcManager.finalFilesOnly };
            var url = srcManager.altFilesUrl || "../api/data/GetFiles";
            commonFunc.getMethod(url, args).then((response) => {
                var data = response.data;


                if (data.isSuccess) {
                    convertFiles(data.result, bindID, fileTypeID, srcManager).then((resolveConvert) => {
                        Swal.close();
                        resolve(resolveConvert);
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de la récupération des fichiers :<br>" + response.data.errorMessage
                    }).then((result) => {
                        resolve({ files: [], length: 0, nbFiles: 0 });
                    });

                }
            });
        } else {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Aucun élément de sélectionné"
            });

            resolve({ files: [], length: 0, nbFiles: 0 });
        }
    });
}

export function convertFiles(srcFiles, bindID, fileTypeID, srcManager) {
    if (!srcManager) srcManager = {};
    return new Promise((resolve, reject) => {
        var currTotLength = 0;
        var currTotFiles = 0;
        //currTotLength = data.totalSize;
        //currTotFiles = data.countFiles;
        var curFiles = [];
        var id = bindID;

        if (srcFiles.length > 0) {

            var srcFileID = 0;
            var resultFileID = 0;
            var finalFileID = 0;
            if (srcManager.srcElem) {
                srcFileID = srcManager.srcElem.srcFileID;
                resultFileID = srcManager.srcElem.resultFileID;
                finalFileID = srcManager.srcElem.finalFileID;
            }

            var fileSelected = false;
            curFiles = srcFiles.map(function (dt, index) {
                currTotFiles += 1;
                currTotLength += dt.length;

                //Conservation du name original
                if (commonFunc.isBlank(dt.orgName)) dt.orgName = dt.name.toString();

                dt.name = removeTimestamp(dt.name);
                dt.bindID = bindID;

                var supClass = "";
                if (dt.idFile == srcFileID || dt.idFile == resultFileID || dt.idFile == finalFileID) supClass = "reportSpecFile";
                if (dt.idFile == resultFileID) supClass += " final-pdf";

                var fileClass = "file-box medium animate__animated animate__pulse " + supClass;
                var fileElemId = "file-" + dt.idFile;

                const file = {
                    fileId: dt.idFile,
                    fileName: dt.name,
                    container: dt.rootContainer,
                    etag: dt.etag,
                    accessToken: dt.absoluteUri,
                    newWindow: false
                };
                var filePath = "../api/data/DownloadFileByUri?" + commonFunc.toParams(file);
                if (dt.instanceFile) filePath = dt.instanceFile.preview;

                file.newWindow = true;
                var filePathDirectDl = "../api/data/DownloadFileByUri?" + commonFunc.toParams(file);


                var fileIcon = commonFunc.getFilesFaIcon(dt.name, dt.categ);
                var isPicture = fileIcon.props["data-typef"] === "img";

                var isSelected = false;
                if (!fileSelected && srcManager.selectedFileCond) {
                    isSelected = srcManager.selectedFileCond(dt);
                    fileSelected = isSelected;
                }

                if (srcManager.renderingBadge) {
                    return <div key={dt.idFile} className="col-lg-6 col-xl-4 mt-1"><div id={fileElemId} className={`ri-files-box bg-secondary animate__animated animate__pulse`} data-fileid={dt.idFile} data-bindid={id} data-filetypeid={(fileTypeID || "0")} data-typef={fileIcon.props["data-typef"]} data-typec={dt.categ}>
                        <a href={dt.instanceFile ? "javascript:void(0)" : filePathDirectDl} title={dt.name} data-trigger='focus'>
                            {fileIcon}
                            <span className='ms-1' title={commonFunc.cellFormatterFileType(dt.name)}>{dt.name}</span>
                        </a>
                        <a href="#" className="remove-pj" tabindex="" onClick={(e) => {
                            e.preventDefault();
                            var fileID = dt.idFile;

                            var removeFile = srcFiles.filter((cfile) => {
                                return cfile.idFile == parseInt(fileID);
                            });

                            var index = srcFiles.indexOf(removeFile[0]);
                            srcFiles.splice(index, 1);

                            //file.remove();
                            if (srcManager.removeFile) srcManager.removeFile(removeFile[0]);
                        }}>
                            <i className="fas fa-times float-end"></i>
                        </a>
                        {isPicture ? <UncontrolledPopover
                            className="pop-no-mw"
                            fade
                            target={fileElemId}
                            placement="auto"
                            trigger="hover"
                            container="body"
                            delay={{ show: 500, hide: 50 }}
                        //noIncluded="html: true"//INFO
                        >
                            <PopoverHeader className="text-dark">Image</PopoverHeader>
                            <PopoverBody>
                                <Img style={{ width: "240px", maxWidth: "inherit" }} className='img-responsive' src={dt.instanceFile ? dt.instanceFile.preview : filePath} alt='' />
                            </PopoverBody>
                        </UncontrolledPopover> : ""}
                    </div></div>;
                }
                else {
                    return (<div key={dt.idFile} id={fileElemId} className={fileClass} data-fileid={dt.idFile} data-bindid={id} data-filetypeid={(fileTypeID || "0")} data-typef={fileIcon.props["data-typef"]} data-typec={dt.categ}
                        onClick={e => {
                            e.preventDefault();

                            var fileElem = document.querySelector("." + srcManager.className + " .file-box[data-fileid='" + dt.idFile + "'] .file");
                            var allFiles = fileElem.parentNode.parentNode.querySelectorAll("." + srcManager.className + " .file");
                            if (!e.altKey) {
                                allFiles.forEach(function (elem) {
                                    elem.classList.remove("selected");
                                });
                            }
                            fileElem.classList.add("selected");
                            if (srcManager.hideAllMenu) srcManager.hideAllMenu();

                            if (srcManager.clickCallback && typeof (srcManager.clickCallback) === "function") { srcManager.clickCallback(dt, fileElem); }
                        }}
                        onDoubleClick={e => {
                            e.preventDefault();

                            //Cookies.set("token", localStorage.getItem('token'), { path: "/api/data", /*expires ,*/ secure: true, sameSite: "strict"});
                            //window.open("../api/data/DownloadFileByUri?" + commonFunc.toParams(dt), '_newself');

                            if(!dt.instanceFile) window.open(filePathDirectDl, '_newself');


                            /*var dlRoot = createRoot(document.createElement('div'));
                            dlRoot.render(<DownloadFile url={"../api/data/DownloadFileByUri?" + commonFunc.toParams(dt)} fileName={dt.name} withErrors={true} callback={(url) => {
                                console.debug(url);
     
                                dlRoot.unmount();
                            }}></DownloadFile>);*/
                        }}
                        onContextMenu={e => {
                            var elem = document.querySelectorAll("." + srcManager.className + " .file-box[data-fileid='" + dt.idFile + "'] .file.selected");
                            if (elem.length) {
                                if (srcManager.showContextMenu) {
                                    srcManager.showContextMenu({
                                        id: "contextMenu",
                                        event: e,
                                        props: dt
                                    });
                                }
                            }
                        }}>
                        <div className={isSelected ? "file selected" : "file"}>
                            <a href='#' data-trigger='focus'>
                                <div className='file-marker'></div>
                                <span className='corner'></span>
                                <div className={isPicture ? 'icon file-fullsize  d-flex align-items-center justify-content-center' : 'icon'}>
                                    {isPicture ? <Img className='img-fluid img-thumbnail2 mx-auto d-block ' src={filePath} alt='' data-typef='img' /> : ""}
                                    {fileIcon}
                                </div>
                                <div className='file-name' title={commonFunc.cellFormatterFileType(dt.name)}><i title='Fichier source' className='fa fa-check-circle reportSpecFile-icon'></i>{dt.name}<br /><small className='float-start'>{commonFunc.formatDateTime(dt.dSysMod instanceof Date ? dt.dSysMod : moment(commonFunc.restStringToDate(dt.dSysMod)).utc(true).toDate())}</small><small className='float-end'>{commonFunc.bytesToSize(dt.length, 1)}</small></div>
                            </a>
                            <div className='side-corner-tag' data-typec={dt.prefix}><div><span>{dt.categ}</span></div></div>
                        </div>
                        {isPicture ? <UncontrolledPopover
                            className="pop-no-mw"
                            fade
                            target={fileElemId}
                            placement="auto"
                            trigger="hover"
                            container="body"
                            delay={{ show: 500, hide: 50 }}
                        //noIncluded="html: true"//INFO
                        >
                            <PopoverHeader className="text-dark">Image</PopoverHeader>
                            <PopoverBody>
                                <img style={{ width: "480px", maxWidth: "inherit" }} className='img-responsive' src={filePath} alt='' />
                            </PopoverBody>
                        </UncontrolledPopover> : ""}
                    </div>);
                }

            });
            //console.debug(curFiles);
        }
        //console.debug(currTotLength);
        //console.debug(currTotFiles);

        resolve({ dataFiles: srcFiles, files: curFiles, length: currTotLength, nbFiles: currTotFiles });
    });
}


function removeTimestamp(name) {
    var value = name;
    var splitName = value.split("_");
    if (commonFunc.isNumeric(splitName[0]) && (splitName[0].length == 14 || splitName[0].length == 13)) {//TODO : correspond au timestamp OU Date.now() (13 caractères pour dateNow)
        splitName.shift();
        value = splitName.join("_");
    }
    return value;
}