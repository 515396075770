/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { useTranslation } from "react-i18next";

import * as commonFunc from '../../scripts/common';
import { Link } from "react-router-dom";
import { Nav, Collapse, Row, Col, Button } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

var ps;

function Sidebar(props) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    const [openAvatar, setOpenAvatar] = React.useState(false);
    const [collapseStates, setCollapseStates] = React.useState({});
    const sidebar = React.useRef();
    // this creates the intial state of this component based on the collapse routes
    // that it gets through props.routes
    const getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
    const getCollapseInitialState = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.pathname.indexOf(routes[i].path) !== -1 || (window.location.pathname + window.location.search).indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    };
    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.entity) {
                var hasRights = props.checkRights(prop.entity, prop.rights);
                if (!hasRights) return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !collapseStates[prop.state];
                return (
                    <li
                        className={getCollapseInitialState(prop.views) ? "active" : ""}
                        key={key}
                    >
                        <a
                            href="#pablo"
                            data-toggle="collapse"
                            aria-expanded={collapseStates[prop.state]}
                            onClick={(e) => {
                                e.preventDefault();
                                setCollapseStates(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                <>
                                    <i className={prop.icon} />
                                    <p>
                                        {altT(prop.name)}
                                        <b className="caret" />
                                    </p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{prop.mini}</span>
                                    <span className="sidebar-normal">
                                        {altT(prop.name)}
                                        <b className="caret" />
                                    </span>
                                </>
                            )}
                        </a>
                        <Collapse isOpen={collapseStates[prop.state]}>
                            <ul className="nav">{createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            var currentPath = commonFunc.isBlank(prop.path) ? "home" : prop.path;
            return (
                <li className={activeRoute(prop.layout + currentPath)} key={key}>
                    <Link to={prop.layout + prop.path}>
                        {prop.icon !== undefined ? (
                            <>
                                <i className={prop.icon} />
                                <p>{altT(prop.name)}</p>
                            </>
                        ) : (
                            <>
                                <span className="sidebar-mini-icon">{prop.mini}</span>
                                <span className="sidebar-normal d-flex">{altT(prop.name)}</span>
                            </>
                        )}
                    </Link>
                </li>
            );
        });
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return (window.location.pathname.indexOf(routeName) > -1 || (window.location.pathname + window.location.search).indexOf(routeName) > -1) ? "active" : "";
    };
    React.useEffect(() => {
        if (props.isLoggedIn) {
            // if you are using a Windows Machine, the scrollbars will have a Mac look
            if (navigator.platform.indexOf("Win") > -1) {
                ps = new PerfectScrollbar(sidebar.current, {
                    suppressScrollX: true,
                    suppressScrollY: false,
                    useBothWheelAxes: true,
                    wheelPropagation: true
                });
            }
            return function cleanup() {
                // we need to destroy the false scrollbar when we navigate
                // to a page that doesn't have this component rendered
                if (navigator.platform.indexOf("Win") > -1) {
                    ps.destroy();
                }
            };
        }
    });
    React.useEffect(() => {
        setCollapseStates(getCollapseStates(props.routes));
    }, []);

    if (!props.isLoggedIn) return null;

    return (
        <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
            <div className="sidebar-wrapper" ref={sidebar}>
                <div className="user mt-0 pt-3">
                    <Button
                        className="btn-icon btn-round text-white d-block d-lg-none"
                        color="transprent"
                        id="minimizeSidebar2"
                        onClick={() => { document.documentElement.classList.toggle("nav-open"); } }
                    >
                        <i className="fa fa-times fa-xl text-center" />
                    </Button>
                    <Row className="justify-content-center">
                        <Col xs="auto">
                            <div className="photo ui-icon-64 m-0 p-0 float-none d-flex align-items-center justify-content-center bg-white">
                                <img src={props.user.avatar} alt="Avatar" />
                            </div>
                        </Col>
                    </Row>
                    <div className="info">
                        <a href="#pablo" data-toggle="collapse" className="text-center"
                            aria-expanded={openAvatar}
                            onClick={(e) => { e.preventDefault(); setOpenAvatar(!openAvatar) }}>
                            <span className="text-wrap" style={{opacity:1,transform:"inherit"}}>
                                <b>{props.user.userName}</b><div className="d-none">{props.user.provider}</div><br />{props.user.connection ? props.user.connection.siteName : ""}
                                <b className="caret" />
                            </span>
                        </a>
                        <Collapse isOpen={openAvatar}>
                            <ul className="nav">
                                <li>
                                    <Link to="/userProfile">
                                        <span className="sidebar-mini-icon"><i className="fas fa-user ms-1"></i></span>
                                        <span className="sidebar-normal text-nowrap">{t("mnu_profil")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" onClick={(e) => { e.preventDefault(); props.logOut(e); }}>
                                        <span className="sidebar-mini-icon"><i className="fas fa-right-from-bracket ms-1"></i></span>
                                        <span className="sidebar-normal text-nowrap">{t("mnu_logout")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </div>
                </div>
                <Nav>{createLinks(props.routes)}</Nav>
            </div>
        </div>
    );
}

export default Sidebar;
