import React from 'react';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import {
    Col
} from "reactstrap";

export default function CategoryFrag({ data, modelFctManager, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    const [shouldRender, setShouldRender] = React.useState(false);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);


    var childs = "";
    if (data.headers) {
        childs = data.headers.map((child) => {
            return modelFctManager.componentTyped(child, { parentData: data, childs: data.headers, parentDisabled: (parentDisabled || data.disabled ? true : false) });
        });
    }

    var frameClass = "w-100";
    var frameStyle = {};
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;
            if (design.boxSize) {
                frameClass = frameClass.replace("w-100", "");
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(design.boxSize) * 100);
            }

            if (design.backColor) frameStyle.background = modelFctManager.getRgbaFromHex(design.backColor);
            if (design.borderColor || design.borderSize) {
                if (design.borderColor) frameStyle.borderColor = modelFctManager.getRgbaFromHex(design.borderColor);

                if (design.borderSize) frameStyle.borderWidth = design.borderSize;
                else frameStyle.borderWidth = "1px";

                frameStyle.borderStyle = "solid";
            }
            if (design.borderRadius) frameStyle.borderRadius = design.borderRadius;

            if (design.margin) {
                frameClass += " p-0";
                frameStyle.margin = design.margin;
            }
            if (design.marginStart) {
                frameClass += " ps-0";
                frameStyle.marginLeft = design.marginStart;
            }
            if (design.marginTop) frameStyle.marginTop = design.marginTop;
            if (design.marginEnd) {
                frameClass += " pe-0";
                frameStyle.marginRight = design.marginEnd;
            }
            if (design.marginBottom) frameStyle.marginBottom = design.marginBottom;
        }
    }

    return <Col data-name="CategoryFrag" data-element={data.objType} xs="auto" className={`${frameClass}`} style={frameStyle}>{childs}</Col>;
}