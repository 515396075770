import React, { Component } from 'react';
import Swal from "sweetalert2";
import moment from "moment";
import PropTypes from "prop-types";

import sanitizeHtml from 'sanitize-html';

import i18n from "i18next";
import { useTranslation } from "react-i18next";

// reactstrap components
import { Button } from "reactstrap";

import 'assets/css/breadcrumbs.css';
import 'assets/css/breadcrumbs-custom.css';

import * as commonFunc from 'scripts/common';

class Historical extends Component {

    constructor(props) {
        super(props);
        this.state = { historicals: this.props.rows || [] };
    }

    componentDidMount() {
        this.loadHistorical();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.shouldRender && (prevProps.shouldRender !== this.props.shouldRender || prevProps.elemId !== this.props.elemId)) {
            this.loadHistorical();
        }
    }

    /*shouldComponentUpdate(nextProps, nextState) {

    }*/

    render() {
        //console.debug("render " + this.props.shouldRender);


        //console.debug(sanitizeHtml("<div class='gotest' style='height:6px' onClick=function() {alert('go');}><script>alert('go')</script></div><div>test</div>"));


        return this.props.shouldRender ? (<div>
            <div>
                <Button id="btnActHistoDataRefresh" className="btn-rotate px-2 my-0 btn-simple" color="dark" size="sm" onClick={() => this.loadHistorical()}>
                    <i className="fa fa-sync"></i>Actualiser
                </Button>
                <div className="d-inline-block">{this.props.children}</div>
                <div className="hr-line-dashed"></div>
            </div>

            <div className="row col-lg-12">

                <div className="col-lg-12 hidden">
                    <nav id={`${this.props.type || "elem"}TimeLine`}>
                        <ol className="cd-multi-steps text-center">

                        </ol>
                    </nav>
                </div>

                <div className="col-lg-12">
                    <section className="cd-timeline js-cd-timeline">
                        <div className="cd-timeline__container">
                            {(() => {
                                var last = null;
                                return this.state.historicals.map((dt, index) => {
                                    var mDate = commonFunc.restStringToDate(dt.dSysMod);
                                    //if (mDate.getFullYear() != 1970 && (last == null || commonFunc.dateDiffSec(commonFunc.restStringToDate(last.dSysMod), mDate) > 5 || last.sort !== dt.sort)) {

                                        var sanitizeOptions = {
                                            allowedAttributes: {
                                                '*': ['class', 'style']
                                            }
                                        };

                                        var title = "";
                                        var icon;

                                        if (/<\/?[^>]*>/.test(dt.name)) {//dt.desc
                                            title = sanitizeHtml(dt.name, sanitizeOptions);//dt.desc

                                            var div = document.createElement('div');
                                            div.innerHTML = sanitizeHtml(dt.name, sanitizeOptions);//dt.desc
                                            //icon = div.firstChild.innerHTML.replace(title, '');
                                            dt.icon = "";

                                        } else {
                                            title = sanitizeHtml(dt.name, sanitizeOptions);//dt.desc
                                            if (dt.tag) {//tag
                                                //TEMP
                                                dt.tag = dt.tag.replace("fa-refresh", "fa-sync").replace("fa-calendar-check-o", "fa-calendar-check").replace("fa-commenting-o", "fa-comment-dots").replace("fa-pencil-square-o", "fa-edit").replace("fa-file-pdf-o", "fa-file-pdf");

                                                icon = dt.tag;
                                            }
                                        }


                                        var statusCss = dt.statutCSS ? (dt.statutCSS.replace(/label-/g, "bg-").replace(/label/g, "badge").replace("bg-default", "bg-secondary") + " text-white")
                                            : (dt.status && !commonFunc.isBlank(dt.status.css) ? (dt.status.css.replace(/label-/g, "bg-").replace(/label/g, "badge").replace("bg-default", "bg-secondary") + " text-white") : "badge bg-secondary");
                                        var textInfo = (dt.comments ? dt.comments : "");//dt.sort
                                        if (!dt.isSuccess) {
                                            textInfo = dt.errorMessage;
                                            statusCss = "badge badge-danger";
                                        }
                                        if (dt.startDate) {
                                            var sd = moment(dt.startDate).toDate();
                                            if (commonFunc.isValidDate(sd) && sd.getFullYear() > 1970) {
                                                var startDate = commonFunc.formatDateTime(sd);
                                                textInfo += (commonFunc.isBlank(textInfo) ? "" : "<br>") + startDate
                                            }
                                        }

                                        if (!commonFunc.isBlank(dt.title)) {
                                            var label = "flows." + dt.categ + dt.title;
                                            var iconFlex = this.props.i18nManager.t(label + "_icon");
                                            var colorFlex = this.props.i18nManager.t(label + "_color");

                                            if (iconFlex && !commonFunc.isBlank(iconFlex) && iconFlex !== (label + "_icon")) {
                                                icon = '<i class="' + iconFlex + '" aria-hidden="true"></i>';
                                            }
                                            if (colorFlex && !commonFunc.isBlank(colorFlex) && colorFlex !== (label + "_color")) {
                                                statusCss = colorFlex;
                                            }
                                        }

                                        last = dt;
                                        return (<div key={index} className="cd-timeline__block js-cd-block">
                                            <div className="cd-timeline__img cd-timeline__img--picture js-cd-img cd-timeline-point">
                                                <div className={`cd-timeline-item ${statusCss}`}>
                                                    <span>{icon ? <span dangerouslySetInnerHTML={{ __html: icon }}></span> : <i className="fa fa-cog" aria-hidden="true"></i>}</span>
                                                </div>
                                            </div>
                                            <div className={`cd-timeline__content js-cd-content ${dt.isSuccess ? "" : " bg-danger"}`}>
                                                <h2 className="mb-2" dangerouslySetInnerHTML={{ __html: sanitizeHtml(title, sanitizeOptions) }}></h2>
                                                <p className="text-muted" dangerouslySetInnerHTML={{ __html: sanitizeHtml(textInfo + (dt.tenantUserAccess && dt.tenantUserAccess.name ? (commonFunc.isBlank(textInfo) ? "" : "<br>") + "<i>" + dt.tenantUserAccess.name + "</i>" : ""), sanitizeOptions)}}></p>
                                                <a href="#0" className="cd-timeline__read-more hidden d-none">Read more</a>
                                                <span className="cd-timeline__date pt-2">{commonFunc.formatDateTime(moment(mDate).utc(true).toDate())}</span>
                                            </div>
                                        </div>);
                                    //}
                                    last = dt;
                                    return "";
                                });
                            })()
                            }
                        </div>
                    </section>


                </div>


            </div>

        </div>) : <></>;
    }

    loadHistorical() {
        this.setState({ historicals: [] });

        const { elemId, shouldRender, loadExternalParent } = this.props;

        if (loadExternalParent) {
            loadExternalParent().then((rows) => {
                if (rows && rows.length > 0) {
                    context.setState({ historicals: rows });
                }
            });
        }
        else {
            var id = elemId;
            if (parseInt(id) > 0) {

                var context = this;
                if (shouldRender) {
                    var args = { id: id };
                    commonFunc.getMethod(context.props.url, args).then((response) => {
                        var data2 = response.data;

                        if (data2.isSuccess && data2.result) {
                            if (Array.isArray(data2.result)) {
                                context.setState({ historicals: data2.result });
                            }
                            else {
                                context.setState({ historicals: data2.result.flows });
                            }
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: "Echec de chargement de l'historique :<br>" + data2.errorMessage
                            });
                        }
                    });

                }
            }
        }
    }
}

Historical.propTypes = {
    url: PropTypes.string.isRequired,
    elemId: PropTypes.number.isRequired,
    shouldRender: PropTypes.bool.isRequired,
};

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}

export default withI18n(Historical);