import { Navigate } from "react-router-dom";

import Home from "./views/pages/Home";
import ManageUsers from "./views/pages/ManageUsers";
import UserProfile from "./views/pages/UserProfile";
import FCPlanning from "./views/pages/Planning";
import FCPlanningMat from "./views/pages/PlanningMaterial";
import ShowContent from "./views/pages/ShowContent";
import ManageReportsItv from "./views/pages/ManageReportsItv";
import ManageClients from "./views/pages/ManageClients";
import ManageEmployees from "./views/pages/ManageEmployees";
import ManageSites from "./views/pages/ManageSites";
import ManageCustom from "./views/pages/ManageCustom";
import ManageJobs from "./views/pages/ManageJobs";
import ManageParameters from "./views/pages/ManageParameters";
import ManageContent from "./views/pages/ManageContent";
import ManageSubscriptions from "./views/pages/ManageSubscriptions";
import ManageTG from "./views/pages/ManageTG";
import ManageLeads from "./views/pages/ManageLeads";
import ManageMaterials from "./views/pages/ManageMaterials";

import SignUp from "./views/pages/register/Signup";
import SignIn from "./views/pages/login/Login";
import PwdReset from "./views/pages/PasswordReset";
import PwdChange from "./views/pages/PasswordChange";
import Auth2 from "./views/pages/login/Login2Auth";
import Subscription from "./views/pages/register/Subscription";
import Debug from "./views/pages/Debug";
import ConfirmSubscription from "./views/pages/ConfirmSubscription";

import Editor from "./views/pages/ModelEditor";

export const appRoutes = [
    {
        index: true,
        name: "i18n:mnu_home",
        icon: "fa fa-building-columns",
        component: <Home />,
        layout: "",
        redirect: true,
    },
    {
        path: "*",
        name: "i18n:mnu_home",
        icon: "fa fa-building-columns",
        component: <Navigate to="/" replace />,
        layout: "",
        redirect: true,
    },
    {
        path: "/debug",
        name: "Debug",
        icon: "fa fa-file",
        component: <Debug />,
        layout: "",
        redirect: true,
    },
    {
        path: "/editor",
        name: "Editor",
        icon: "fa fa-file",
        component: <Editor />,
        layout: "",
        redirect: true,
    },
    {
        path: "/ConfirmSubscription",
        name: "Confirmer Abonnement",
        icon: "fa fa-check-circle",
        component: <ConfirmSubscription />,
        layout: "",
        redirect: true,
    },
    {
        collapse: true,
        name: "Authentification",
        icon: "fa fa-book-bookmark",
        state: "authCollapse",
        redirect: true,
        views: [
            {
                login: true,
                path: '/sign-up',
                name: "SignUp",
                mini: "S",
                component: <SignUp />,
                layout: "",
            },
            {
                login: true,
                path: "/login",
                name: "Login",
                mini: "L",
                component: <SignIn />,
                layout: "",
            },
            {
                login: true,
                path: "/pwdReset",
                name: "Réinitialiser le mot de passe",
                mini: "R",
                component: <PwdReset />,
                layout: "",
            },
            {
                login: true,
                path: "/pwdChange",
                name: "Changement de mot de passe",
                mini: "C",
                component: <PwdChange />,
                layout: "",
            },
            {
                login: true,
                path: "/login2auth",
                name: "2-Authentification",
                mini: "2A",
                component: <Auth2 />,
                layout: "",
            },
            {
                all: true,
                path: '/subscription',
                name: "Subscription",
                mini: "S",
                component: <Subscription />,
                layout: "",
            },
            {
                path: '/userProfile',
                name: "Profil",
                mini: "P",
                component: <UserProfile />,
                layout: "",
            },
        ],
    },
];

export const keysToComponentMap = {
    "home": <Home />,
    "fcPlanning": <FCPlanning />,
    "fcPlanningMat": <FCPlanningMat />,
    "manageUsers": <ManageUsers />,
    "showContent": <ShowContent />,
    "ManageReportsItv": <ManageReportsItv />,
    "ManageClients": <ManageClients />,
    "ManageEmployees": <ManageEmployees />,
    "ManageSites": <ManageSites />,
    "ManageCustom": <ManageCustom />,
    "ManageJobs": <ManageJobs />,
    "ManageParameters": <ManageParameters />,
    "ManageContent": <ManageContent />,
    "ManageSubscriptions": <ManageSubscriptions />,
    "ManageTG": <ManageTG />,
    "ManageLeads": <ManageLeads />,
    "ManageMaterials": <ManageMaterials />,
};