import React from 'react';

import { useTranslation } from "react-i18next";


export default function TabFrag({ data, modelFctManager, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    const [shouldRender, setShouldRender] = React.useState(false);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    var childs = "";
    if (data.categories) {
        childs = data.categories.map((child) => {
            return modelFctManager.componentTyped(child, { parentData: data, childs: data.categories, parentDisabled: (parentDisabled || data.disabled ? true : false) });
        });
    }

    var frameClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }
    if (data.disabled) frameClass += " form-disabled";

    return <div data-name="TabFrag" data-element={data.objType} className={`row ${frameClass}`}>{childs}</div>;
}