import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

import moment from "moment";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import {
    Button, Input, Label,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Form, FormFeedback
} from "reactstrap";

import { Editor } from 'components/TinyMCE-react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import * as commonFunc from 'scripts/common';

import { withRouter } from 'components/withRouter';

import FilesCustom, { ExtUpload } from 'components/FilesCustom';

class ManageContent extends Component {
    static displayName = ManageContent.name;

    static refreshCb;
    static modalRoot;

    sites = [];
    groups = [];
    categories = [
        { label: "Page", value: "PGE", showOnEntity: "CUSTOM" },
        { label: "Modèle Email", value: "EML" }
    ];

    editorRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = { entities: [], entity: "", subCateg: "", contentModels: [], curContentId: 0, curContent: null, content: "", files: [], editionMode: false };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        Swal.fire({
            title: context.props.i18nManager.t("gal_loading"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        if (this.props.updateUser) this.props.updateUser(function (curUser) {
            commonFunc.getMethod("../api/Data/GetAlternativeSites", null).then((response) => {
                Swal.close();
                var data = response.data;
                if (data && data.isSuccess) {
                    if (data.count > 0) {
                        context.sites = data.result;
                    }
                }

                context.groups = context.props.getRoutes().filter(route => route.pgeContainer);

                //Chargement via url
                var defParamLoadId = 0;
                var defParamActionId = 0;
                if (context.props.searchParams) {
                    defParamLoadId = parseInt(context.props.searchParams.get("paramId")) || 0;
                    defParamActionId = parseInt(context.props.searchParams.get("actionId")) || 0;
                }
                if (defParamLoadId > 0) {
                    context.getContentEntities(curUser, defParamLoadId, defParamActionId);
                } else {
                    context.getContentEntities(curUser);

                    /*if (defParamActionId == 1) {
                        $(".action-addReport:visible").click();
                    }*/
                }
            });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");
        /*if (prevState.forceUpdate !== this.state.forceUpdate) {
            this.getContentEntities();
        }*/
        if (prevState.entity !== this.state.entity || prevState.subCateg !== this.state.subCateg) {
            this.getContentList();
        }
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }
    }

    async getContentEntities(user) {
        this.setState({ entity: "", subCateg: "", entities: [] });
        var context = this;

        if (user.rights && user.rights.flags) {
            var selectEntities = user.rights.flags.filter((flag) => flag.entity.endsWith("_MODELS") && this.props.checkRights(flag.entity, "R"));
            if (selectEntities) {
                //selectEntities = selectEntities.map((flag) => { return { label: flag.desc, value: flag.entity.split("_")[0], subCateg: (flag.entity.startsWith("CUSTOM_") ? flag.entity.replace("CUSTOM_", "").replace("_MODELS", "") : "") } });
                selectEntities = selectEntities.map((flag) => { return { label: flag.entity.replace("_MODELS", ""), value: flag.entity.split("_")[0], subCateg: (flag.entity.startsWith("CUSTOM_") ? flag.entity.replace("CUSTOM_", "").replace("_MODELS", "") : "") } });
                context.setState({ entities: selectEntities, entity: selectEntities[0].value, subCateg: selectEntities[0].subCateg, entityLabel: selectEntities[0].label });
            }
        }
    }


    async getContentList(currentID, actionID) {
        this.setState({ contentModels: [], curContentId: 0, curContent: null, content: "", files: [], editionMode: false });
        this.resetForm();

        var context = this;

        Swal.fire({
            title: context.props.i18nManager.t("gal_loading"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        
        commonFunc.getMethod("../api/" + this.state.entity + "/GetModelsAsKeyItems", { id: -1, categ: this.state.subCateg }).then((responseModel) => {
            var dataModel = responseModel.data;
            Swal.close();

            var currPage = null;
            var filteredModels = dataModel.result || [];
            if (dataModel.isSuccess && dataModel.result && dataModel.result.length) {
                filteredModels = dataModel.result.filter((model) => model.isHtml);

                if (currentID) {
                    var resFilter = filteredModels.filter((model) => model.id === currentID/*model.extID === bindID*/);
                    if (resFilter && resFilter.length) {
                        currPage = resFilter[0];
                    }
                }
                else {
                    currPage = filteredModels[0];
                }
            }
            context.setState({ contentModels: filteredModels });

            if (currPage) this.setContent(currPage.id, filteredModels);
        });
    }

    initCreateContent() {
        this.resetForm();
        this.setState({ curContentId: 0, curContent: { isActive: true }, content: "", files: [], editionMode: true });

        var formManager = this.props.formManager;
        formManager.setValue("Active", true);
    }

    resetForm() {
        var formManager = this.props.formManager;
        formManager.setValue("SaveAsFile", false);
        formManager.setValue("Active", false);
        formManager.setValue("Name", "");
        formManager.setValue("OrgID", -1);
        formManager.setValue("Category", "");
        formManager.setValue("Lang", "");
        formManager.setValue("Group", "");
        formManager.clearErrors();

        this.categories = this.categories.filter((categ) => !categ.isTemp && (!categ.showOnEntity || categ.showOnEntity === this.state.entity));
    }

    setContent(contentId, models) {
        var context = this;

        this.resetForm();
        this.setState({ curContentId: 0, curContent: null, content: "", files: [], editionMode: false });

        if (contentId) {
            Swal.fire({
                title: context.props.i18nManager.t("gal_loading"),
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            commonFunc.getMethod("../api/" + this.state.entity + "/Get" + this.state.entity + "Models", { id: -1, categ: this.state.subCateg, modelId: contentId }).then((responseModel) => {
                var dataModel = responseModel.data;
                if (dataModel.isSuccess && dataModel.result && dataModel.result.models && dataModel.result.models.length) {
                    var content = dataModel.result.models[0];
                    var currentPage = JSON.parse(content.value);

                    //$("#contentsmenu-" + currentPage.ID).closest("li").addClass("active");

                    var currContent = currentPage.Details;
                    var currFiles = currentPage.Files;


                    Swal.close();
                    if (currentPage.hasOwnProperty("Details")) {
                        //content.idModel = currentPage.ID;
                        //content.extID = currentPage.BindID;
                        content.group = currentPage.Group || currentPage.Category;//PATCH : récup ancienne page
                        context.setState({ curContentId: contentId, curContent: content, content: currContent, files: currFiles, editionMode: true });
                        var formManager = context.props.formManager;
                        formManager.setValue("SaveAsFile", content.saveAsFile || false);
                        formManager.setValue("Active", true);
                        formManager.setValue("Name", content.title || "");
                        formManager.setValue("OrgID", content.siteID || -1);
                        formManager.setValue("Category", content.categ || "");
                        formManager.setValue("Lang", content.categ || "FR");
                        formManager.setValue("Group", content.group || "NONE");

                        var containCateg = context.categories.filter((categ) => categ.value === content.categ);
                        if (containCateg.length <= 0) {
                            context.categories.push({ label: content.categ.replace("#", ""), value: content.categ, isTemp: true });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "warning",
                            title: context.props.i18nManager.t("gal_warning"),
                            html: context.props.i18nManager.t("manageContent.extconfig"),
                            didOpen: () => { Swal.hideLoading() },
                        });
                    }
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("manageContent.failmodel") + " <br>" + dataModel.errorMessage,
                    });
                }
            });
        }
    }

    removeContent() {
        var context = this;

        if (context.state.curContentId) {
            Swal.fire({
                icon: 'warning',
                title: context.props.i18nManager.t("gal_warning"),
                html: context.props.i18nManager.t("manageContent.rmvConfig"),
                confirmButtonText: context.props.i18nManager.t("gal_confirm"),
                cancelButtonText: context.props.i18nManager.t("gal_cancel"),
                cancelButtonColor: '#d33',
                showCloseButton: true,
                showCancelButton: true,
            }).then((result) => {
                if (result.dismiss) {
                    //Annulé
                }
                else {
                    var args = { "id": context.state.curContentId, "sd": moment("1970-01-01").format('YYYY-MM-DDT') + '00:00Z', "period": "D", "categ": context.state.curContent.categ, isPublic: false, siteId: context.state.curContent.siteID, bindId: context.state.curContent.extID, data: "{}" };

                    commonFunc.postMethod("../api/data/RemoveConfigValue", args).then((response) => {
                        var data = response.data;
                        if (data.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                title: context.props.i18nManager.t("gal_success"),
                                html: context.props.i18nManager.t("manageContent.successRmvConfig"),
                            }).then((result) => {
                                context.getContentList();
                            });
                        }
                        else {
                            Swal.close();
                            Swal.fire({
                                icon: 'error',
                                title: context.props.i18nManager.t("gal_fail"),
                                html: context.props.i18nManager.t("manageContent.failRmvConfig")  + ' <br><br>' + data.errorMessage,
                            });
                        }
                    });
                }
            });
        }
        else {
            Swal.fire({
                icon: 'error',
                title: context.props.i18nManager.t("gal_fail"),
                html: context.props.i18nManager.t("manageContent.noSelectedConfig"),
            });
        }
    }

    saveContent(data) {
        var context = this;



        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }

        //Traitement des informations retourné par le Dialog Upload
        var callbackModal = function (files, errors) {
            return new Promise((resolve, reject) => {

                Swal.fire({
                    title: context.props.i18nManager.t("gal_saving"),
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                if (files) {



                    var finalProcess = (idNewModel) => {

                        Swal.close();
                        Swal.fire({
                            icon: errors && errors.length ? "warning" : "success",
                            title: context.props.i18nManager.t("gal_success"),
                            html: (isUpdate ? context.props.i18nManager.t("gal_successUpd") : context.props.i18nManager.t("gal_successAdd"))
                                + (errors && errors.length ? ("<br><b>Certains fichiers n'ont pas pu être mis en ligne :</b><br/>" + errors.map((errorFile) => errorFile.name + " : " + errorFile.error) + "<br>") : ""),
                            didOpen: () => { Swal.hideLoading() },
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        }).then((result) => {
                            //getData(newPage.BindID);
                            context.getContentList(idNewModel);
                        });
                    };

                    var isUpdate = context.state.curContentId > 0;

                    var tempID = parseInt(Math.floor(Date.now() + Math.random() * 10000000000000001).toString().substr(6));

                    var newPage = data;

                    newPage.Group = newPage.Group || "NONE";
                    var srcCateg = context.categories.filter(categ => categ.value === newPage.Category);
                    if (srcCateg.length <= 0 && !newPage.Category.startsWith("#")) newPage.Category = "#" + newPage.Category

                    newPage.ID = (context.state.curContent && context.state.curContent.id) ? context.state.curContent.id : tempID;
                    newPage.BindID = (context.state.curContent && context.state.curContent.extID) ? context.state.curContent.extID : (newPage.Category + "-" + newPage.Group + "-" + tempID + "-" + (newPage.Active ? "1" : "0"));
                    newPage.Details = context.editorRef.current ? context.editorRef.current.getContent() : "";
                    newPage.Files = files;//context.state.files;


                    var argsPost;
                    var model = { title: newPage.Name, categ: newPage.Category, siteID: newPage.OrgID || -1, extID: newPage.BindID, lang: newPage.Lang, value: JSON.stringify(newPage) };
                    model.idModel = isUpdate ? context.state.curContentId : 0;
                    model.isHtml = true;
                    model.saveAsFile = data.SaveAsFile;
                    model[context.state.entity.toLowerCase() + "ID"] = -1;

                    argsPost = model;

                    //console.debug(argsPost); Swal.close(); return;//TEMP
                    commonFunc.postMethod(isUpdate ? "../api/" + context.state.entity + "/Update" + context.state.entity + "Model" : "../api/" + context.state.entity + "/Add" + context.state.entity + "Model", argsPost).then((response) => {
                        var dataSave = response.data;
                        if (dataSave.isSuccess) {
                            var toRemove = isUpdate && context.state.curContent.extID !== newPage.BindID;
                            var argsDel = {};
                            if (toRemove) {
                                argsDel = { "sd": moment("1970-01-01").format('YYYY-MM-DDT') + '00:00Z', "period": "D", "categ": context.state.curContent.categ, isPublic: false, siteId: context.state.curContent.siteID, bindId: context.state.curContent.extID, data: "{}" };
                            }


                            if (toRemove) {
                                commonFunc.postMethod("../api/data/RemoveConfigValue", argsDel).then((responseDel) => {
                                    Swal.close();
                                    var data = responseDel.data;
                                    if (data.isSuccess) {
                                        finalProcess(dataSave.result.idModel);
                                    }
                                    else {
                                        Swal.close();
                                        Swal.fire({
                                            icon: 'error',
                                            title: context.props.i18nManager.t("gal_fail"),
                                            html: context.props.i18nManager.t("manageContent.failRmvConfig") + ' <br><br>' + data.errorMessage,
                                        });
                                    }
                                });

                            }
                            else {
                                finalProcess(dataSave.result.idModel);
                            }
                        } else {
                            Swal.close();
                            Swal.fire({
                                icon: 'error',
                                title: context.props.i18nManager.t("gal_fail"),
                                html: context.props.i18nManager.t("gal_errorSave") + ' <br><br>' + dataSave.errorMessage,
                            });
                        }

                    });




                    if (context.modalRoot) {
                        context.modalRoot.unmount();
                        context.modalRoot = null;
                    }
                }
                else {
                    if (context.modalRoot) {
                        context.modalRoot.unmount();
                        context.modalRoot = null;
                    }
                    resolve();
                }
            });
        };

        if (context.state.files && context.state.files.length) {
            this.modalRoot = createRoot(document.createElement('div'));
            this.modalRoot.render(<ExtUpload url={`../api/${context.state.entity}/Upload${context.state.entity}Files`} bindID={-1} fileType={"CFG"} fileTypeID={context.state.entity} files={context.state.files} setFiles={(files) => { context.setState({ files: files }) }} callback={callbackModal} />);
        }
        else {
            callbackModal([]);
        }

    }

    render() {
        //console.debug("render again");

        var context = this;

        var { control, register, setValue, getValues, handleSubmit, formState: { errors } } = this.props.formManager;

        const { ref: refSaveAsFile, ...restSaveAsFile } = register("SaveAsFile");
        const { ref: refActive, ...restActive } = register("Active");
        const { ref: refName, ...restName } = register("Name", { required: true });
        const { ref: refOrgID, ...restOrgID } = register("OrgID");
        const { ref: refCategory, ...restCategory } = register("Category", { required: true });
        const { ref: refLang, ...restLang } = register("Lang");
        const { ref: refGroup, ...restGroup } = register("Group");

        var selectSites = this.sites.map((site) => { return { label: site.localSite.name, value: site.siteID } });
        var selectGroups = this.groups.map((group) => { return { label: group.name, value: group.tag } });
        selectGroups = [{ label: context.props.i18nManager.t("gal_none"), value: "NONE" }, ...selectGroups];
        var defaultGroup = { label: context.props.i18nManager.t("gal_none"), value: "NONE" };
        if (this.state.curContent) {
            console.debug(selectGroups);
            console.debug(this.state.curContent.group);
            console.debug(this.state.curContent.categ);
            var filterGrp = selectGroups.filter((group) => group.value === this.state.curContent.group);//group
            if (filterGrp.length) defaultGroup = filterGrp[0];
        }

        var rightsTag = this.state.entity + (this.state.subCateg ? ("_" + this.state.subCateg) : "") + "_MODELS";

        return (
            <div className="tableDetails">
                <Card className={`tableContainer widget dash`}>
                    <CardHeader>
                        <CardTitle tag="h6">{context.props.i18nManager.t("manageContent.title")}</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-wrench fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <div className="float-start">
                                <Button className="btn-rotate px-2 btn-simple mt-0" color="dark" size="sm" onClick={() => this.getContentList(this.state.curContentId)}>
                                    <i className="fa fa-sync"></i>{context.props.i18nManager.t("gal_refresh")}
                                </Button>
                                {this.props.checkRights(rightsTag, "C") ? <Button className="btn-magnify px-2 mt-0" color="success" size="sm" onClick={() => this.initCreateContent()}>
                                    <i className="fa fa-plus-square"></i>{context.props.i18nManager.t("gal_add")}
                                </Button> : ""}
                            </div>
                            <div className="float-end d-flex">
                                <Select
                                    value={this.state.entities.length ? { label: this.state.entityLabel, value: this.state.entity } : ""}
                                    name="contentList"
                                    className={`basic-single me-2`}
                                    classNamePrefix="select"
                                    //placeholder="Tous"
                                    onChange={(values) => {
                                        context.setState({ entity: values.value, subCateg: values.subCateg, entityLabel: values.label });
                                    }}
                                    //options={this.state.entities.map((entity) => { return { label: entity.description, value: entity.refExtID } })}
                                    options={this.state.entities}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            minWidth: "150px"
                                        }),
                                    }}
                                />
                                <Select
                                    value={this.state.curContent ? { label: this.state.curContent.title, value: this.state.curContentId } : ""}
                                    name="contentList"
                                    className={`basic-single`}
                                    classNamePrefix="select"
                                    //placeholder="Tous"
                                    onChange={(values) => {
                                        context.setContent(values.value);
                                    }}
                                    options={this.state.contentModels.length ? this.state.contentModels.map((model) => { return { label: model.tag + " (" + model.lang + ")", value: model.id } }) : []}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            minWidth: "150px"
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        {
                            this.props.checkRights(rightsTag, "R") ? (
                                <div key={this.state.curContentId} className="d-flex w-100">
                                    <div className="flex-fill">
                                        <hr className="hr-line-dashed w-100" />
                                        <Form action="" className="form needs-validation" method="" onSubmit={handleSubmit(context.saveContent.bind(this))}>
                                            <Row>
                                                <Col lg="6">
                                                    <div className={`form-check form-check-inline fcheck-sm mt-2 d-none`}>
                                                        <Label check for={"contentActive"} className={`d-inline-block px-0`} style={{ minWidth: "16px" }}>
                                                            <div className={`d-inline-block`} style={{ position: "relative", top: "-12px" }}>
                                                                <Input type="checkbox" id={"contentActive"} defaultChecked={this.state.curContent ? this.state.curContent.isActive : false} disabled={!this.state.editionMode} innerRef={refActive} {...restActive} />
                                                                <span className={`form-check-sign`} />
                                                                <div className={`d-inline-block ms-4`}>{context.props.i18nManager.t("gal_active")}</div>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    <div className={`form-check form-check-inline fcheck-sm mt-2`}>
                                                        <Label check for={"saveAsFile"} className={`d-inline-block px-0`} style={{ minWidth: "16px" }}>
                                                            <div className={`d-inline-block`} style={{ position: "relative", top: "-12px" }}>
                                                                <Input type="checkbox" id={"saveAsFile"} defaultChecked={this.state.curContent ? this.state.curContent.saveAsFile : false} disabled={!this.state.editionMode} innerRef={refSaveAsFile} {...restSaveAsFile} />
                                                                <span className={`form-check-sign`} />
                                                                <div className={`d-inline-block ms-4`}>{context.props.i18nManager.t("manageContent.saveAsFile")}</div>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                </Col>
                                                {context.state.curContentId && this.props.checkRights(rightsTag, "D") ? <Col lg="6" className="text-end">
                                                    <Button className={`btn-magnify px-2 mt-0`} color="danger" size="sm" onClick={() => { this.removeContent() }} disabled={!this.state.editionMode}>
                                                        <i className="fa fa-ban"></i>{context.props.i18nManager.t("gal_remove")}
                                                    </Button>
                                                </Col> : ""}
                                            </Row>
                                            <Row className="mb-2">
                                                <Col lg="4">
                                                    <div className="form-group">
                                                        <Label for="contentName">{context.props.i18nManager.t("manageContent.configName")}</Label>
                                                        <Input id="contentName" type="text" autoComplete="no" aria-autocomplete="none" placeholder={context.props.i18nManager.t("manageContent.configName")} defaultValue={this.state.curContent ? this.state.curContent.title : ""}
                                                            disabled={!this.state.editionMode} innerRef={refName} {...restName} invalid={errors.Name ? true : false} />
                                                    </div>
                                                    <FormFeedback className={`text-start ${errors.Name ? "d-block" : ""}`}>{context.props.i18nManager.t("gal_reqFields")}</FormFeedback>
                                                </Col>
                                                <Col lg="4" className="d-none">
                                                    <Label for="contentOrgID">{context.props.i18nManager.t("manageContent.siteLabel")}</Label>
                                                    <Select
                                                        defaultValue={this.state.curContent ? selectSites.filter((site) => site.value === this.state.curContent.siteID) : ""}
                                                        isDisabled={!this.state.editionMode}
                                                        name="contentOrgID"
                                                        options={selectSites}
                                                        className={`basic-single`}
                                                        classNamePrefix="select"
                                                        placeholder="Actuel"
                                                        innerRef={refOrgID} {...restOrgID}
                                                        onChange={(values) => {
                                                            setValue("OrgID", values.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col lg="4">
                                                    <Label for="contentCateg">{context.props.i18nManager.t("manageContent.categLabel")}</Label>
                                                    <CreatableSelect
                                                        defaultValue={this.state.curContent ? this.categories.filter((categ) => categ.value === this.state.curContent.categ) : ""}
                                                        isDisabled={!this.state.editionMode}
                                                        name="contentCateg"
                                                        options={this.categories}
                                                        className={`basic-single ${errors.Category ? "is-invalid" : ""}`}
                                                        classNamePrefix="select"
                                                        //placeholder="Tous"
                                                        innerRef={refCategory} {...restCategory}
                                                        onChange={(values) => {
                                                            setValue("Category", values.value.toUpperCase());
                                                            this.forceUpdate();
                                                            this.props.formManager.clearErrors("Category");
                                                        }}
                                                        isValidNewOption={(inputValue) => {
                                                            return inputValue.indexOf("-") < 0 && inputValue.length >= 2 && inputValue.length <= 7;
                                                        }}
                                                        /*onCreateOption={(inputValue) => {
                                                            this.categories.push({ label: inputValue, value: inputValue, isTemp: true });

                                                            setValue("Category", inputValue.toUpperCase());
                                                            this.forceUpdate();
                                                            this.props.formManager.clearErrors("Category");
                                                        }}*/
                                                    />
                                                    <FormFeedback className={`text-start ${errors.Category ? "d-block" : ""}`}>{context.props.i18nManager.t("gal_reqFields")}</FormFeedback>
                                                </Col>
                                                <Col lg="4">
                                                    <Label for="contentLang">{context.props.i18nManager.t("manageContent.langLabel")}</Label>
                                                    <Select
                                                        defaultValue={{ label: "Français", value: "FR" }}
                                                        isDisabled={!this.state.editionMode}
                                                        name="contentLang"
                                                        options={[{ label: "Français", value: "FR" }, { label: "Anglais", value: "EN" }]}
                                                        className={`basic-single`}
                                                        classNamePrefix="select"
                                                        //placeholder="Tous"
                                                        innerRef={refLang} {...restLang}
                                                        onChange={(values) => {
                                                            setValue("Lang", values.value);
                                                        }}
                                                    />
                                                </Col>
                                                {getValues("Category") === "PGE" ? <Col lg="4">
                                                    <Label for="contentPageGroup">{context.props.i18nManager.t("manageContent.groupLabel")}</Label>
                                                    <Select
                                                        defaultValue={defaultGroup}
                                                        isDisabled={!this.state.editionMode}
                                                        name="contentPageGroup"
                                                        options={selectGroups}
                                                        className={`basic-single`}
                                                        classNamePrefix="select"
                                                        //placeholder="Tous"
                                                        innerRef={refGroup} {...restGroup}
                                                        onChange={(values) => {
                                                            setValue("Group", values.value);
                                                        }}
                                                    />
                                                </Col> : ""}
                                            </Row>
                                            <Editor
                                                onInit={(evt, editor) => this.editorRef.current = editor}
                                                initialValue={this.state.content}
                                                disabled={!this.state.editionMode}
                                                init={{
                                                    language: "fr_FR",
                                                    valid_elements: '*[*]',
                                                    valid_children: "+body[style]",
                                                    height: 500,
                                                    menubar: true,
                                                    //readonly: true, >> cf disabled
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | fontsize | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | code | fullscreen | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .sepline { border: 1px dashed red; } .displayNone { display: none } .d-none { display: none } .photo-container.no-photo { display: block !important } table.photo-container.no-photo { display: table !important } .invisible-photo { visibility: initial !important }'
                                                    /*skin: false,
                                                    content_css: false,
                                                    content_style: editorDefault*/
                                                }}
                                            />
                                            <div className="mt-2">{context.props.i18nManager.t("gal_pj")}</div>
                                            <FilesCustom className="config-files" bindID={-1/*this.state.curContentId*/} checkRights={this.props.checkRights} fileTypeID={this.state.entity/*"CONFIG"*/} src={this.state.curContent} srcName={this.state.curContent ? this.state.curContent.title : ""}
                                                files={this.state.files} setFiles={(compFiles) => { this.setState({ files: compFiles }) } }
                                                noUploads={true} badgeRendering={true} shouldRender={true} disabled={!this.state.editionMode} altFilesUrl={`../api/${this.state.entity}/Get${this.state.entity}Files`}
                                                altRemoveFilesUrl={`../api/${this.state.entity}/Remove${this.state.entity}File`} altUploadFilesUrl={`../api/${this.state.entity}/Upload${this.state.entity}Files`} />
                                            <Row>
                                                <Col lg="12" className="text-center">
                                                    {context.state.editionMode && !context.state.curContentId ?
                                                        (this.props.checkRights(rightsTag, "C") ? <Button className={`btn-magnify px-2 mt-0`} color="success" size="sm" disabled={!this.state.editionMode}>
                                                            <i className="fa fa-save"></i>{context.props.i18nManager.t("gal_add")}
                                                        </Button> : "")
                                                        :
                                                        (this.props.checkRights(rightsTag, "U") ? <Button className={`btn-magnify px-2 mt-0`} color="primary" size="sm" disabled={!this.state.editionMode}>
                                                            <i className="fa fa-save"></i>{context.props.i18nManager.t("gal_save")}
                                                        </Button> : "")
                                                    }
                                                    <Button className={`btn-magnify px-2 mt-0`} color="danger" size="sm" onClick={() => { this.setContent(this.state.curContentId) }} disabled={!this.state.editionMode}>
                                                        <i className="fa fa-times"></i>{context.props.i18nManager.t("gal_cancel")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            ) : context.props.i18nManager.t("gal_accessDenied")
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const withUseFormHook = (Component) => {
    return props => {
        const form = useForm();
        return <Component {...props} formManager={{ ...form }} />
    }
}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}


export default withRouter(withI18n(withUseFormHook(ManageContent)));
