import React, { useRef } from 'react';
import Swal from "sweetalert2";

//import { Editor } from '@tinymce/tinymce-react';
import { Editor } from 'components/TinyMCE-react';



import {
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input
} from "reactstrap";

import * as commonFunc from 'scripts/common';

export default function EditorCustom(globalProps) {
    const editorRef = useRef(null);

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [editorContent, setEditorContent] = React.useState("");
    const [templateId, setTemplateId] = React.useState(globalProps.templateId);
    const [fileId, setFileId] = React.useState(-1);
    const [services, setServices] = React.useState([]);

    React.useEffect(() => {
        setEditorContent("");
        setIsLoaded(false);
        setTemplateId(globalProps.templateId);
    }, [globalProps]);


    React.useEffect(() => {
        var args = null;
        commonFunc.getMethod("../api/report/GetReportServices", args).then((response) => {
            var data = response.data;
            if (data.isSuccess) {
                setServices(data.result);

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de chargement des services :<br>" + data.errorMessage
                });
            }
        });
    }, []);


    function loadPreview(fast) {
        //Reset
        setEditorContent("");
        setIsLoaded(false);
        setFileId(-1);
        setTemplateId(globalProps.templateId);
        var id = globalProps.reportId;
        if (parseInt(id) > 0) {
            var args = { id: id };//, isSource: false 
            if (!fast) {
                Swal.fire({
                    title: 'Chargement de l\'aperçu en cours...',
                    didOpen: () => { Swal.showLoading() },
                });

                commonFunc.getMethod("../api/report/GetPreview", args).then((response) => {
                    Swal.close();
                    var data = response.data;

                    if (data.isSuccess) {
                        setEditorContent(data.result);
                        setIsLoaded(true);
                        setFileId(data.bindId);

                        /*$(".previewChange input[type='checkbox']").off("change").on("change", function () {
                            $(this).is(":checked") ? $(this).attr("checked", "checked") : $(this).removeAttr("checked");
                            $(this).addClass("previewChanged");
                        });*/


                    } else if (data.errorCode == 404) {
                        Swal.fire({
                            icon: "question",
                            title: "Nouvel Aperçu Rapport",
                            html: "Voulez-vous créer l'aperçu du rapport pour cette intervention ?",
                            showCancelButton: true,
                            confirmButtonColor: "#28a745",
                            cancelButtonColor: "#dc3545",
                            confirmButtonText: "Oui",
                            cancelButtonText: "Non",
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Création de l\'aperçu du rapport en cours...',
                                    didOpen: () => { Swal.showLoading() }
                                });
                                var req = { id: id, isSource: false, toStore: true };
                                commonFunc.postMethod("../api/report/GeneratePreview", req).then((response) => {
                                    var data = response.data;
                                    Swal.close();
                                    if (data && data.isSuccess) {
                                        loadPreview();
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Echec",
                                            html: "Echec de la génération :<br>" + data.errorMessage
                                        });
                                    }
                                });
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: "Impossible de charger l'aperçu du rapport :<br/>" + data.errorMessage
                        });
                    }

                });
            }
            else {
                //TODO Fast ?
            }
        }
        else {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Aucun élément de sélectionné"
            });
        }
    }

    function savePreview() {
        var id = globalProps.reportId;
        if (parseInt(id) > 0) {
            Swal.fire({
                title: 'Sauvegarde de l\'Aperçu en cours...',
                didOpen: () => { Swal.showLoading() },
            });

            var editorValue = editorRef.current ? editorRef.current.getContent() : "";

            var req = { id: id, isSource: false, toStore: true, data: editorValue };
            commonFunc.postMethod("../api/report/UpdatePreview", req).then((response) => {
                var data = response.data;
                if (data && data.isSuccess) {
                    Swal.close();

                    Swal.fire({
                        icon: "success",
                        title: "Succès",
                        html: "Aperçu Sauvegardé.",
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Echec de sauvegarde :<br>" + response.data.errorMessage
                    });
                }

            });
        }
        else {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Aucun élément de sélectionné"
            });
        }
    }

    function generateFinalReport() {
        var id = globalProps.reportId;
        if (parseInt(id) > 0) {
            Swal.fire({
                icon: "question",
                title: "Rapport Final",
                html: "Voulez-vous générer le rapport final pour cette intervention ?",
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Génération du rapport final en cours...',
                        didOpen: () => { Swal.showLoading() },
                    });

                    var req = { id: id, isSource: false, toStore: true };
                    commonFunc.postMethod("../api/report/GenerateRelease", req).then((response) => {
                        if (response.data) {
                            if (response.data.isSuccess) {
                                Swal.close();

                                Swal.fire({
                                    icon: "success",
                                    title: "Succès",
                                    html: "Rapport Final Généré",
                                    didOpen: () => { Swal.hideLoading() },
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                }).then((result) => {
                                    if (globalProps.refreshReport) {
                                        commonFunc.getMethod("../api/report/GetReport", { id: id }).then((response) => {
                                            var data = response.data;
                                            if (data.isSuccess) {
                                                console.log(data.result);

                                                globalProps.refreshReport(data.result);
                                                if (globalProps.nextTab) globalProps.nextTab();
                                            }
                                            else if (globalProps.nextTab) globalProps.nextTab();
                                        });
                                    }
                                    else if (globalProps.nextTab) globalProps.nextTab();
                                });
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: "Echec de génération :<br>" + response.data.errorMessage
                                });
                            }
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: "Echec de génération"
                            });
                        }
                    });
                }
            });
        }
        else {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Aucun élément de sélectionné"
            });
        }
    }

    function removePreview(doFast) {
        var id = globalProps.reportId;
        if (parseInt(id) > 0) {
            var removeProc = function () {
                var req = { id: id, fileId: fileId, isPermanent: true };
                return commonFunc.postMethod("../api/report/RemovePreview", req).then((response) => {
                    return new Promise((resolve, reject) => {
                        if (response.data) {
                            if (response.data.isSuccess) {
                                Swal.close();

                                /*Swal.fire({
                                    icon: "success",
                                    title: "Succès",
                                    html: "Rapport Final Généré",
                                    didOpen: () => { Swal.hideLoading() },
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                });*/

                                setEditorContent("");
                                setIsLoaded(false);
                                globalProps.loadReport(id).then((data) => {
                                    resolve(response.data.result);
                                });
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: "Echec de Suppression de l'Aperçu :<br>" + response.data.errorMessage
                                });
                                reject(response.data.errorMessage);
                            }
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: "Echec de Suppression de l'Aperçu"
                            });
                            reject(response.error);
                        }
                    });
                });
            };

            if (doFast) {
                return removeProc();
            }
            else {
                Swal.fire({
                    icon: "question",
                    title: "Nouveau Rapport",
                    html: "Voulez-vous supprimer cet Aperçu ?",
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Suppression de l\'Aperçu du rapport...',
                            didOpen: () => { Swal.showLoading() },
                        });

                        return removeProc();
                    }
                });
            }
        }
        else {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Aucun élément de sélectionné"
            });
        }
    }

    function updateReportModel() {
        var id = globalProps.reportId;
        if (parseInt(id) > 0) {
            if (templateId) {
                Swal.fire({
                    icon: "question",
                    title: "Changement de modèle",
                    html: "Voulez-vous changer de modèle de document ?",
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Changement de modèle en cours...',
                            didOpen: () => { Swal.showLoading() },
                        });

                        var req = { id: id, templateId: templateId };
                        commonFunc.postMethod("../api/report/UpdateTemplate", req).then((response) => {
                            if (response.data) {
                                if (response.data.isSuccess) {
                                    Swal.close();

                                    /*Swal.fire({
                                        icon: "success",
                                        title: "Succès",
                                        html: "Rapport Final Généré",
                                        didOpen: () => { Swal.hideLoading() },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    });*/
                                    removePreview(true).then((data) => {
                                        loadPreview();
                                    });
                                }
                                else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Echec",
                                        html: "Echec de génération :<br>" + response.data.errorMessage
                                    });
                                }
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: "Echec de génération"
                                });
                            }
                        });
                    }
                });
            }
            else {
                Swal.fire({
                    icon: "warning",
                    title: "Attention",
                    html: "Aucun modèle n'a été sélectionné"
                });
            }
        }
        else {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Aucun élément de sélectionné"
            });
        }
    }

    //var templatesList = (globalProps.dataCfg.customList && globalProps.dataCfg.customList.length) ? globalProps.dataCfg.customList.filter((elem) => elem.Type === 3) : [];
    var templatesList = [];
    if (services.length && parseInt(globalProps.serviceId) > 0) {
        var service = services.filter((svc) => {
            return svc.idService === parseInt(globalProps.serviceId);
        });
        if (service.length && service[0].templates) {
            templatesList = service[0].templates;
            if (templatesList && templatesList.length) {
                templatesList = templatesList.filter(template => template.isHtml && template.isActive);
            }
        }
    }


    var extraStuff = "";
    /*if (globalProps.dataCfg && globalProps.dataCfg.displayExtraStuff && globalProps.dataCfg.displayExtraStuff.length && globalProps.dataCfg.displayExtraStuff.indexOf("reportModelChange") >= 0
        && templatesList.length > 0) {//TODO : voir si test > 1
        extraStuff = <>
            <Col lg="2">
                <Input name="reportModel" id="reportModel" type="select" className="w-100 ms-2" value={templateId} disabled={(globalProps.report && globalProps.report.status && globalProps.report.status.step >= 3)}
                    onChange={(e) => setTemplateId(parseInt(e.target.value))}>
                    {templatesList.map((elem, index) => {
                        var templateID = elem.Template ? elem.Template : elem.idTemplate;
                        var name = elem.Name ? elem.Name : elem.name;
                        return <option key={index} value={templateID}>{name}</option>;
                    })}
                </Input>
            </Col>
            <Button id="updateReportModel" className={`btn-magnify btn-icon px-2 mt-0`} color="secondary" size="sm" title="Changer de modèle" disabled={(globalProps.report && globalProps.report.status && globalProps.report.status.step >= 3)}
                onClick={() => { updateReportModel() }}>
                <i className="fa fa-redo"></i>
            </Button>
        </>;
    }*/

    return (
        <Row className={globalProps.className}>
            <Col lg="12" className="h-100">
                <Card className="widget dash">
                    <CardHeader>
                        <CardTitle tag="h6">APERÇU</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-file-word fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs="auto">
                                <Button id="btnActPreviewRefresh" className="btn-rotate px-2 mt-0 btn-simple" color="dark" size="sm" onClick={() => { loadPreview() }}>
                                    <i className="fa fa-circle-notch"></i>Charger
                                </Button>
                                <Button id="btnActPreviewRefreshFast" className="btn-rotate px-2 mt-0 btn-simple d-none" color="dark" size="sm" onClick={() => { loadPreview(true) }}>
                                    <i className="fa fa-circle-notch"></i>Charger
                                </Button>
                                {(!globalProps.report || !globalProps.report.status || globalProps.report.status.step < 3) ? <>
                                    <Button id="btnActPreviewSave" className={`btn-magnify px-2 mt-0 ${isLoaded ? "" : "disabled"}`} disabled={!isLoaded} color="primary" size="sm" onClick={() => { savePreview() }}>
                                        <i className="fa fa-save"></i>Sauvegarder
                                    </Button>
                                    <Button id="btnActPreviewGen" className={`btn-magnify px-2 mt-0 ${isLoaded ? "" : "disabled"}`} disabled={!isLoaded} color="warning" size="sm" onClick={() => { generateFinalReport() }}>
                                        <i className="fa fa-cog"></i>Générer PDF
                                    </Button>
                                    <Button id="btnActPreviewDone" className={`btn-magnify px-2 mt-0 d-none`} color="success" size="sm" onClick={() => { globalProps.updateReportStatus(204); }}>
                                        <i className="fa fa-check-square"></i>Rapport Traité
                                    </Button>
                                    <Button id="btnActPreviewRemove" className={`btn-magnify px-2 mt-0 ${isLoaded ? "" : "disabled"}`} disabled={!isLoaded} color="danger" size="sm" onClick={() => { removePreview() }}>
                                        <i className="fa fa-ban"></i>Supprimer
                                    </Button>
                                </> : ""}
                                
                            </Col>
                            {extraStuff}
                        </Row>

                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={editorContent}
                            disabled={!isLoaded || (globalProps.report && globalProps.report.status && globalProps.report.status.step >= 3)}
                            init={{
                                language: "fr_FR",
                                valid_elements: '*[*]',
                                valid_children: "+body[style]",
                                height: 500,
                                menubar: true,
                                //readonly: true, >> cf disabled
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | fontsize | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | code | fullscreen | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .sepline { border: 1px dashed red; } .displayNone { display: none } .d-none { display: none }'
                                /*skin: false,
                                content_css: false,
                                content_style: editorDefault*/
                            }}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}