import React from 'react';
import Swal from "sweetalert2";
import moment from 'moment';

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.fr";


import DPCustom from 'components/DPCustom';

import * as commonFunc from 'scripts/common';

import {
    Input,
    Label,
    FormGroup, FormFeedback,
    InputGroup, InputGroupText, Col
} from "reactstrap";


export default function RadioButtonsFrag({ data, formManager, modelFctManager, bindPropResult, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors, control } = formManager;

    const [curRadio, setCurRadio] = React.useState(data.defaultValue || 0);
    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        var label = data.options.filter(option => option.value === curRadio)
        if (label && label.length) label = label[0].title;
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data, { type: data.subType, value: curRadio, label: label }).then(actionResponse => {
                    //console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }, []);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    /*React.useEffect(() => {
        triggerOnChange(curRadio);
    }, [curRadio]);*/

    function triggerOnChange(curValue) {
        var value = curValue || data.rawValue || (bindPropResult ? getValues(bindPropResult) : "");
        setValue(bindPropResult, value);

        var label = data.options.filter(option => option.value === value)
        if (label && label.length) label = label[0].title;
        if (data.onActionEvents && data.onActionEvents["onChange"]) {
            data.onActionEvents["onChange"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data, { type: data.subType, value: value, label: label }).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.setValue = (value, noChange, src) => {//withRerender
        return new Promise((resolve, reject) => {
            //setValue(bindPropResult, value);
            setCurRadio(value);
            if (!noChange) triggerOnChange(value);

            resolve({ isSuccess: true, result: value });
        });
    };
    data.functionManager.getValue = (src) => {
        return curRadio;
    };

    const { ref: refElem, ...restElem } = register(bindPropResult, {
        value: data.defaultValue,
        valueAsNumber: (data.subType === "integer" || data.subType === "float"),
        onChange: (e) => {
            triggerOnChange();
        }
    });

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));

    function renderInput(widthClass, formClass) {
        //TODO : formattage chargement valeurs

        console.debug(data.options);

        return <div className={`${widthClass}`}>
            {data.options.map((option, index) => {
                if (option.hideOnAction && option.hideOnAction.length) {
                    option.hideOnAction.forEach((args, index) => {
                        modelFctManager.onActionRequired(option.name, args, option).then(actionResponse => {
                            console.debug("RESPONSE");
                            console.debug(actionResponse);
                            if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Echec",
                                    html: actionResponse.errorMessage
                                });
                            }
                        });
                    });
                }
                return option.hidden ? "" : <div key={index} className={`form-check-radio fcheck-sm ${formClass}`}>
                    <Label check >
                        <Input id={`radio_${option.value}`} name={"radio" + data.tag} checked={option.value === curRadio} type="radio" value={option.value} disabled={disabled || option.disabled} onChange={(e) => {
                            var value = isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value);
                            setCurRadio(value);
                            //TODO : change sur children avec la valeur de l'option (disabled + setValue)
                            triggerOnChange(value);
                        }} />
                        <span className="form-check-sign" />
                        {altT(option.title)}
                        {option.children ? <div className="d-inline-block ms-2">{option.children.map((child => {
                            var childDisabled = disabled || (option.value !== curRadio);
                            return modelFctManager.componentTyped(child, { parentData: data, childs: data.children, parentDisabled: childDisabled, type: "radio", value: (childDisabled ? 0 : 1) });
                        }))}</div> : ""}
                    </Label>
                </div>
            })}
        </div>;
    }

    function renderTitle(positions, titleWidthClass) {
        var design = data.customDesign ? data.customDesign.designTitle : null;
        if (!design) design = {}
        if (!design.position) design.position = "left";

        var titleModel = <Label className={`${titleWidthClass} ${(design.position === "left" || design.position === "right") ? "m-0" : ""} ${(!commonFunc.isBlank(data.title) && data.required) ? "mandatory" : ""}`}>{altT(data.title)}</Label>;

        if (!commonFunc.isBlank(data.title) && positions.indexOf(design.position) >= 0) return titleModel;
        else return "";
    }

    var frameClass = "mb-3";
    var frameStyle = {};
    var containerClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    var formClass = "m-0 ";

    var widthClass = "";
    var titleWidthClass = "px-0 ";
    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;
            if (design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(design.boxSize) * 100);
            }

            if (design.margin) {
                frameStyle.margin = design.margin;
            }
            if (design.marginStart) frameStyle.marginLeft = design.marginStart;
            if (design.marginTop) frameStyle.marginTop = design.marginTop;
            if (design.marginEnd) frameStyle.marginRight = design.marginEnd;
            if (design.marginBottom) frameStyle.marginBottom = design.marginBottom;
        }
        if (data.customDesign.designInput) {
            if (data.customDesign.designInput.boxSize) {
                //widthClass += " w-" + parseInt(parseFloat(data.customDesign.designInput.boxSize) * 100);
                widthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designInput.boxSize) * 100);
            }
            
            if (data.customDesign.designInput.alignment === "horizontal") {
                formClass += " d-inline-block me-3";
            }
        }
        if (data.customDesign.designTitle) {
            if (data.customDesign.designTitle.alignment) {
                var split = data.customDesign.designTitle.alignment.split("|");
                split.forEach((part) => {
                    titleWidthClass += " text-" + part.replace("left", "start").replace("right", "end");
                    titleWidthClass = titleWidthClass.replace("text-top", "align-self-start").replace("text-bottom", "align-self-end");
                });
            }
            if (data.customDesign.designTitle.boxSize) {
                //titleWidthClass += " w-" + parseInt(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass = titleWidthClass.replace("px-0", "ps-3 px-md-0").replace("text-end", "text-md-end");
            }
        }

        if (!commonFunc.isBlank(widthClass) || titleWidthClass !== "px-0 ") containerClass += " row m-0 row-cols-lg-auto2 align-items-center";
    }

    /*var baseForm = <div className={`form-check form-check-inline fcheck-sm w-100 ${containerClass} ${formClass}`}>
        {renderTitle(titleWidthClass, renderInput(widthClass))}
    </div>;*/


    var baseForm = <div className={`w-100 ${containerClass}`}>
        {renderTitle(["top", "left"], titleWidthClass)}
        {renderInput(widthClass, formClass)}
        {renderTitle(["bottom", "right"], titleWidthClass)}
        <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
    </div>;

    //console.debug("render RADIO");

    return <Col data-name="RadioButtonsFrag" data-element={data.objType} xs="auto" className={` ${frameClass} ${parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`} style={frameStyle}>{baseForm}</Col>;//align-self-center
}