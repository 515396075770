import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from "react-i18next";
import axios from "axios";
import CGridstack from 'components/CGridstack';

import Swal from "sweetalert2";
import * as commonFunc from 'scripts/common';

import {
    Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter
} from "reactstrap";

import { Menu, Item, Separator, useContextMenu } from "react-contexify"
import "react-contexify/dist/ReactContexify.css";

import Select from 'react-select';

import AGenerateModel, { getRgbaFromHex, convertWidthToGrid } from 'components/generic/AGenerateModel';
import PictureFrag from 'components/generic/PictureFrag';

class Home extends Component {
    static displayName = Home.name;

    static siteModels;

    static modalRoot;
    static user;

    constructor(props) {
        super(props);


        var defaultGridStackData = [
            { id: "gs-storage", hidden: false, type: "WGT_STORAGE", title: "Fichiers Stockés", x: 0, y: 0, w: 3, h: 2 },
            { id: "gs-download", hidden: false, type: "WGT_DOWNLOAD", title: "Fichiers Téléchargés", "data": 0, "altData": 0, x: 3, y: 0, w: 3, h: 2 },
            { id: "gs-email", hidden: false, type: "WGT_EMAIL", title: "Emails", "data": 0, x: 6, y: 0, w: 3, h: 2 },
            { id: "gs-sms", hidden: false, type: "WGT_SMS", title: "Sms", "data": 0, x: 9, y: 0, w: 3, h: 2 },
            { id: "gs-lastusers", hidden: false, type: "WGT_LASTUSERS", title: "Derniers Comptes", x: 0, y: 2, w: 6, h: 6 },

            { id: "gs-lastact", hidden: false, type: "WGT_LASTACT", title: "Actions récentes", x: 6, y: 2, w: 6, h: 6 },
            { id: "gs-payments", hidden: true, type: "TABLE", title: "Départs à solder", x: 0, y: 8, w: 6, h: 5 },
            { id: "gs-activity", hidden: true, type: "TABLE", title: "Suivi des Activités", x: 6, y: 8, w: 6, h: 5 },
            { id: "gs-userscon", hidden: false, type: "CHART_USERSCON", "data": 0, title: "Utilisateur(s) en Ligne", x: 0, y: 13, w: 6, h: 5 },
            { id: "gs-usersact", hidden: true, type: "TABLE", title: "Comptes Utilisateurs actifs", x: 10, y: 13, w: 4, h: 5 },
            //{ id: "gs-network", hidden: false, type: "WGT_NETWORK", title: "Latence Réseau (ms)", x: 6, y: 13, w: 2, h: 2 },
            //{ id: "gs-subs", hidden: false, type: "TABLE", title: "Abonnements SAAS", x: 10, y: 14, w: 2, h: 2 },
            //{ id: "gs-plugins", hidden: false, type: "TABLE", title: "Modules Extension", x: 10, y: 16, w: 2, h: 2 },
        ];

        /*var optDataKey = localStorage.getItem("optionsTDB");
        if (optDataKey === null) {
            localStorage.setItem('optionsTDB', JSON.stringify(defaultGridStackData));
        }
        else {
            defaultGridStackData = JSON.parse(optDataKey);
        }*/

        this.state = { gridData: JSON.stringify(defaultGridStackData), render: false };
    }

    componentDidMount() {
        //if (this.props.updateUser) this.props.updateUser();

        var context = this;
        if (this.props.updateUser) this.props.updateUser(function (curUser) {

            context.user = curUser;

            commonFunc.getMethod("../api/site/GetSiteModels", { id: -1 }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {

                    context.siteModels = {};
                    dataModels.result.models.forEach((model) => {
                        context.siteModels[model.extID] = JSON.parse(model.value);
                    });
                    context.setState({ render: !context.state.render });

                } else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModels") + " :<br>" + dataModels.errorMessage
                    });
                }
            });


            //Gestion Swal/Dialog 1ere ouverture
            var siteForDialog = context.props.getSite();
            if (siteForDialog) {
                var siteExtras = siteForDialog.extras;
                if (!context.modalRoot && curUser && curUser.primarySId >= 3 && !(siteExtras && siteExtras.length && siteExtras.filter(extra => extra.name === "dialogTuto").length > 0)) {//rightsType.manager+   //TEMP : voir quel test appliquer (passer à 4 pour ADMIN)
                    var title = "Initialisation de l'environnement";

                    if (context.modalRoot) {
                        context.modalRoot.unmount();
                        context.modalRoot = null;
                    }

                    //Traitement des informations retourné par le Dialog
                    var callbackModal = function (data, isUpdate) {
                        return new Promise(async (resolve, reject) => {
                            if (data) {

                                var asyncJobs = [];
                                var mainResponse;

                                //Enregistrement des salariés
                                var employees = data.employees;
                                if (employees && Object.keys(employees).length) {
                                    //Appeler un CreateEmployee pour chaque employee
                                    Object.keys(employees).forEach(async (employeeKey, index) => {
                                        var employee = employees[employeeKey];
                                        if (employee && employee.idEmployee === 0 && !employee.added) {
                                            employee.added = true;
                                            asyncJobs[index] = await commonFunc.postMethod("../api/employee/CreateEmployee", employee).then((response => {
                                                return new Promise(resolve => {
                                                    resolve(response);
                                                });
                                            })).catch(error => {
                                                return new Promise((resolve) => {
                                                    resolve({ isSuccess: false, errorMessage: error });
                                                });
                                            });
                                        }
                                    });
                                    //delete data.employees
                                }

                                //Upload Site Avatar
                                //if (pictureData.functionManager && pictureData.functionManager.onSubmit) pictureData.functionManager.onSubmit(null, -1);//TODO : idSite ???

                                var curSiteID = curUser.groupSId || -1;
                                var file = data.file;
                                if (file && !file.closed) {
                                    const formData = new FormData();

                                    var fileUpReq = {
                                        name: file.name,
                                        ftype: file.docType || data.argumentsObj.ftype || "AVA",
                                        bindType: data.argumentsObj.bindType || "",
                                        visibility: 1,
                                        language: "FR",
                                        //fbindId: props.fileTypeID, //Type
                                        bindId: curSiteID,
                                        //bindSubId
                                        fileTypeID: file.docTypeId || data.argumentsObj.fileTypeID,
                                        lastModified: file.lastModifiedDate,
                                        saveProperty: data.argumentsObj.saveProperty || data.bindPropResult,
                                        savePropertyAsUrl: data.argumentsObj.savePropertyAsUrl || false
                                    };

                                    formData.append('file', file);
                                    formData.append('args', JSON.stringify(fileUpReq));


                                    const onUploadProgress = (event) => {
                                        console.debug(event);

                                        file.loaded = event.loaded;
                                        file.total = event.total;
                                        file.progress = Math.round(100 * event.progress);
                                        //setSelectedFile(file);
                                    };
                                    asyncJobs.push(await axios.post(data.argumentsObj.url || "../api/data/UploadFiles", formData, { headers: { "Content-Type": "multipart/form-data", }, onUploadProgress }).then((response => {
                                        file.closed = true;
                                        if (response.data.isSuccess) {
                                            file.validated = true;
                                        }
                                        else {
                                            file.error = response.data.errorMessage;
                                        }
                                        //setSelectedFile(file);
                                        return new Promise(resolve => {
                                            resolve(response);
                                        });
                                    })).catch(error => {
                                        return new Promise((resolve) => {
                                            resolve({ isSuccess: false, errorMessage: error });
                                        });
                                    }));
                                }



                                asyncJobs.push(await commonFunc.postMethod("../api/data/UpdateClientSettings", data).then((async response => {
                                    mainResponse = response;
                                    return new Promise(resolve => {
                                        resolve(response);
                                    });
                                })).catch(error => {
                                    return new Promise((resolve) => {
                                        resolve({ isSuccess: false, errorMessage: error });
                                    });
                                }));


                                if (asyncJobs.length) {
                                    Promise.all(asyncJobs).then(results => {
                                        console.debug(results);
                                        var errorMessage = false;
                                        var flagSuccess = true;
                                        results.forEach(res => {
                                            if (res && res.data) {
                                                flagSuccess &= res.data.isSuccess;
                                                if (!res.data.isSuccess) errorMessage = res.data.errorMessage;
                                            }
                                            else {
                                                flagSuccess = false;
                                                errorMessage = res.errorMessage;
                                            }
                                        });

                                        if (flagSuccess) {
                                            Swal.fire({
                                                icon: "success",
                                                title: "Succès",
                                                html: "Modifications effectuées avec succès.",
                                                didOpen: () => { Swal.hideLoading(); },
                                                allowOutsideClick: false,
                                                allowEscapeKey: false,
                                                allowEnterKey: false,
                                            }).then((result) => {
                                                window.location.reload();
                                            });

                                            context.modalRoot.unmount();
                                            resolve(mainResponse);
                                        }
                                        else {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Erreur",
                                                html: "Erreur lors de l'enregistrement des informations :<br>" + errorMessage
                                            });
                                        }
                                        return;

                                    }, rej => {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Erreur",
                                            html: "Erreur de l'enregistrement des informations :<br>" + rej.message
                                        });
                                    })
                                        .catch(error => {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Erreur",
                                                html: "Erreur de l'enregistrement des informations :<br>" + error.message
                                            });
                                        });
                                }
                            }
                            else {
                                context.modalRoot.unmount();
                                resolve();
                            }
                        });
                    };
                    context.modalRoot = createRoot(document.createElement('div'));
                    context.modalRoot.render(<InitModal parentContext={context} title={title} modelsList={null} callback={callbackModal}></InitModal>);
                }
            }
        });
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount();
            this.modalRoot = null;
        }
    }

    render() {

        console.debug(this.state.gridData);
        console.debug(this.siteModels);


        document.body.classList.add("sidebar-mini");

        return this.user ? (
            <div>
                <h1 className="mb-1">Bienvenue</h1>
                <CGridstack type={"optionsTDB" + (this.user.groupSId || "")} data={this.state.gridData} configModel={this.siteModels} gridClass={""} actionsClass={"text-secondary"} />
            </div>
        ) : "";
    }
}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}


export default withI18n(Home);




//Dialog d'initialisation d'environnement
function InitModal({ title, parentContext, noScroll, modelsList, dataReport, callback }) {

    React.useEffect(() => {
        //console.debug("mount");

        initModal();

        return () => {
            //console.debug("unmount");
            if (window.modalContainer) {
                window.modalContainer.unmount();
                window.modalContainer = null;
            }
        }
    }, []);

    const [shouldRender, setShouldRender] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState();
    const [siteName, setSiteName] = React.useState("");

    //STEP 1
    const [activities, setActivities] = React.useState([
        { "label": "Tous", "value": "*" }, { "label": "Déménagement", "value": "DEMENAG" }, { "label": "Recherche de fuite", "value": "RFND" }, { "label": "Aide à la personne", "value": "AD" }, { "label": "Bâtiment", "value": "BTM" }, { "label": "Immobilier", "value": "IMMOBILIER" }
    ]);
    const [currActivities, setCurrActivities] = React.useState(-1);
    const [currActivityInvalid, setCurrActivityInvalid] = React.useState(false);
    const [color1, setColor1] = React.useState("#103F58");//231D51
    const [color2, setColor2] = React.useState("#103F58");//FFFFFF
    const [color3, setColor3] = React.useState("#DDDDDD");//CC2026
    const [color4, setColor4] = React.useState("#FFFFFF");

    const [site, setSite] = React.useState(null);
    const [services, setServices] = React.useState([]);
    const [currServices, setCurrServices] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [currModel, setCurrModel] = React.useState({ title: "DTL_REPORT_2" });

    const [currModelHome, setCurrModelHome] = React.useState("HOME_2");
    const [currModelReport, setCurrModelReport] = React.useState("DTL_REPORT_2");

    const [siteIsOpen, setSiteIsOpen] = React.useState(true);
    const [modelsIsOpen, setModelsIsOpen] = React.useState(false);
    const [employeesIsOpen, setEmployeesIsOpen] = React.useState(false);
    const [showFinalize, setShowFinalize] = React.useState(false);

    const siteAvatarBtn = React.useRef();
    const modalGenManager = React.useRef();

    const showContextMenu = useContextMenu().show;
    const hideAllMenu = useContextMenu().hideAll;

    //STEP 2
    const [employees, setEmployees] = React.useState({});


    var pictureData = {
        argumentsObj: { "url": "api/site/UploadSiteFiles", "ftype": "AVA", "value": "", "target": "IDSite", "trigger": "", "bindType": "SITE", "fileTypeID": 1, "saveProperty": "Avatar", "savePropertyAsUrl": true, cAcceptTypes: "image/png, image/gif, image/jpg, image/jpeg" },
        customDesign: {
            design: {
                "width": "95px",
                "height": "95px",
                "borderRadius": "50%"
            }
        }
    };

    function initModal() {

        //Rapport >> Sélection de services pour activer la sélection des modèles
        //DT_RPTS / REPORT / DLG_REPORT / DLG_ITV / DT_CTCS / CONTACTS / DLG_CONTACT
        //LST_REPORT / DTL_REPORT / DLG_DTL_REPORT ( / DTL_REPORT_1 / DTL_REPORT_2 )

        //Seulement la sélection de modèles REPORTS
        //Sélection du secteur d'activité >> Agence Modèle (faire un json local >> 1 Déménagement / 2 Service à la personne / 3 Recherche de fuite / etc...)
        //Sélection des services via un TreeView (prévoir un input pour modifier le nom directement)

        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        commonFunc.getMethod("../api/site/GetSite", { id: parentContext.user ? parentContext.user.groupSId : -1 }).then((responseSite) => {
            if (responseSite.data.isSuccess) {
                setSite(responseSite.data.result);

                commonFunc.getMethod("../api/site/GetSiteServices", { withInactive: true }).then((response) => {
                    if (response.data.isSuccess) {
                        setServices(response.data.result.filter(svc => svc.idService > 0 && !svc.isContract && svc.categ === "ACT" && svc.reportCategoryID > 0));

                        /*commonFunc.getMethod("../api/data/GetAllModels", { filter: "report|employee" }).then((responseModels) => {
                            var data = responseModels.data;
                            if (data && data.isSuccess) {
                                var modelsMix = data.result.map(model => model.tag);
                                setModels(modelsMix);
                                console.debug(modelsMix);
                            }
                        });*/
                        /*commonFunc.getMethod("../api/report/GetReportModels", { id: -1 }).then((responseModels) => {
                            Swal.close();

                            var data = responseModels.data;
                            if (data && data.isSuccess) {
                                console.debug(data.result);
                                var modelsReport = data.result.models;
                                setModels(modelsReport);
                            }
                        });*/
                        commonFunc.getMethod("../api/report/GetReportModels", { id: -1 }).then((responseModels) => {

                            var data = responseModels.data;
                            if (data && data.isSuccess) {
                                console.debug(data.result);
                                var modelsReport = data.result.models;

                                commonFunc.getMethod("../api/employee/GetEmployeeModels", { id: -1 }).then((responseEmpModels) => {
                                    Swal.close();

                                    var data = responseEmpModels.data;
                                    if (data && data.isSuccess) {
                                        console.debug(data.result);
                                        var modelsEmployee = data.result.models;

                                        var modelsMix = modelsReport.concat(modelsEmployee);
                                        setModels(modelsMix);
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Erreur",
                                            html: "Echec de récupération des modèles employées :<br>" + responseEmpModels.data.errorMessage
                                        });
                                    }
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Erreur",
                                    html: "Echec de récupération des modèles :<br>" + responseModels.data.errorMessage
                                });
                            }
                        });

                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Echec de récupération des services :<br>" + response.data.errorMessage
                        });
                    }
                });


            } else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Echec de récupération des informations de l'agence :<br>" + responseSite.data.errorMessage
                });
            }
        });

    }

    async function finalizeFirstStep() {
        var checkAct = Array.isArray(currActivities) ? currActivities : [{ value: currActivities }];
        if (!checkAct || checkAct.length <= 0 || !checkAct[0].value || checkAct[0].value <= 0) {
            Swal.fire({
                icon: "warning",
                title: "Attention",
                html: "Veuillez sélectionner un secteur d'activité"
            });
            setCurrActivityInvalid(true);
            setSiteIsOpen(true); setModelsIsOpen(false); setEmployeesIsOpen(false);
            return false;
        }


        var result = {};
        //result.activity = currActivities && currActivities.length ? currActivities[0].value : -1;
        result.activity = currActivities && currActivities.length ? currActivities.map(v => v.value).join(";") : "";
        result.siteName = siteName || (site ? site.name : "");
        result.color1 = color1.replace("#", "#FF");
        result.color2 = color2.replace("#", "#FF");
        result.color3 = color3.replace("#", "#FF");
        result.color4 = color4.replace("#", "#FF");
        result.services = currServices;

        console.debug(result);

        return true;
    }

    async function finalizeSecondStep(ignoreDialog) {

        Swal.fire({
            title: parentContext.props.i18nManager.t("gal_saving"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });


        var result = {};
        //result.activity = currActivities && currActivities.length ? currActivities[0].value : -1;
        result.activity = currActivities && currActivities.length ? currActivities.map(v => v.value).join(";") : "";
        result.siteName = siteName || (site ? site.name : "");
        result.color1 = color1.replace("#", "#FF");
        result.color2 = color2.replace("#", "#FF");
        result.color3 = color3.replace("#", "#FF");
        result.color4 = color4.replace("#", "#FF");
        result.services = currServices;

        result.homeModel = currModelHome;
        result.reportModel = currModelReport;

        result.employees = employees;
        result.file = selectedFile;
        result.argumentsObj = pictureData.argumentsObj;
        result.bindPropResult = pictureData.bindPropResult;

        if (ignoreDialog) result.ignoreDialog = true;

        console.debug(result);


        callback(result);
    }

    function siteAvatarUpdate(file) {
        if (file) {
            if (selectedFile) {
                URL.revokeObjectURL(selectedFile.preview);
            }
            //file.preview = URL.createObjectURL(file);
            setSelectedFile(file);
        }
    }
    function fileDel({ sysEvent, props, triggerEvent, data }) {
        if (pictureData.functionManager) pictureData.functionManager.setValue(null);
        setSelectedFile(null);
    }

    function updateActivity(activities) {
        console.debug(activities);
        //var activityServices = [...services.filter((service) => service.siteID === activity.value)]; // A TEST

        var activityServices;
        if (Array.isArray(activities)) {
            activityServices = JSON.parse(JSON.stringify(services.filter((service) => {
                var flag = false;
                activities.forEach((activity) => {
                    flag |= (activity.value === "*" || (service.businessSector === activity.value || service.businessSector.startsWith(activity.value + ";") || service.businessSector.endsWith(";" + activity.value) || service.businessSector.indexOf(";" + activity.value + ";") > 0));
                    service.isActive = flag;
                });
                return flag;
            })));
        }
        else {
            activityServices = JSON.parse(JSON.stringify(services.filter((service) => activities.value === "*" || (service.businessSector === activities.value || service.businessSector.startsWith(activities.value + ";") || service.businessSector.endsWith(";" + activities.value) || service.businessSector.indexOf(";" + activities.value + ";") > 0))));
        }


        setCurrServices(activityServices);
        setCurrActivities(activities);
        setCurrActivityInvalid(false);
    }

    function updateServiceChecked(serviceID, checked) {
        currServices.forEach((svc) => {
            if (svc.idService === serviceID) svc.isActive = checked;
        });
        setCurrServices(currServices);
        setShouldRender(!shouldRender);
    }
    function updateServiceName(serviceID, name) {
        currServices.forEach((svc) => {
            if (svc.idService === serviceID) svc.name = name;
        });
        setCurrServices(currServices);
        setShouldRender(!shouldRender);
    }
    function updateServiceColor(serviceID, color) {
        currServices.forEach((svc) => {
            if (svc.idService === serviceID) {
                svc.startColor = color;
                svc.endColor = color;
            }
        });
        setCurrServices(currServices);
        setShouldRender(!shouldRender);
    }

    function updateModel(model) {
        //TODO : Il faut qu'il n'y ait qu'un seul Template de isActive:true (1 isHtml et 1 non isHtml)

        if (model.template /*&& !model.template.isActive*/) {
            var modelSvc = currServices.filter(svc => svc.idService === model.template.serviceID);
            if (modelSvc.length && modelSvc[0].templates && modelSvc[0].templates.length) {
                modelSvc[0].templates.forEach(template => {
                    if ((model.template.isHtml && template.isHtml) || (!model.template.isHtml && !template.isHtml)) {
                        template.isActive = false;
                    }
                });
                model.template.isActive = true;
                showModelPreview(model);
            }
            setCurrServices(currServices);
            setShouldRender(!shouldRender);
        }
    }

    function updateSingleModel(keyModel) {
        if (keyModel.startsWith("HOME")) {
            setCurrModelHome(keyModel);
        }
        else {
            setCurrModelReport(keyModel);
        }
        setCurrModel({ title: keyModel });
    }

    function showModelPreview(model, selectModels) {
        var currActiveModel = selectModels.filter(modelItem => modelItem.template && modelItem.template.isActive);
        if (currActiveModel.length) {
            model = currActiveModel[0];
        }
        if (model && model.template && model.template.isActive) {
            setCurrModel(model);
            setShouldRender(!shouldRender);
        }
    }

    function addEmployee() {
        /*employees.push({ idEmployee: Math.floor(Math.random() * 1000000000), firstName: "Toto", name: "TEST" });
        setEmployees(employees);
        setShouldRender(!shouldRender);*/



        var title = "Ajout d'un employé";

        if (window.modalContainer) {
            window.modalContainer.unmount();
            window.modalContainer = null;
        }

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (data, isUpdate) {
            return new Promise((resolve, reject) => {
                if (data) {
                    console.debug(data);
                    employees[Math.floor(Math.random() * 1000000000)] = data;
                    setEmployees(employees);
                    setShouldRender(!shouldRender);

                    window.modalContainer.unmount();
                    resolve();
                }
                else {
                    window.modalContainer.unmount();
                    resolve();
                }
            });
        };

        var employeeModel = models.filter((model) => model.extID === "FAST_EMPLOYEE");
        if (employeeModel && employeeModel.length) {
            window.modalContainer = createRoot(document.createElement('div'));
            window.modalContainer.render(<EmployeeModal parentContext={parentContext} modalGenManager={modalGenManager} title={title} configModel={JSON.parse(employeeModel[0].value)} modelsList={null} callback={callbackModal}></EmployeeModal>);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Erreur",
                html: "Configuration introuvable"
            });
        }
    }

    function removeEmployee(employeeKey) {
        delete employees[employeeKey];
        setEmployees(employees);
        setShouldRender(!shouldRender);
    }

    //console.debug("render modal");


    var groupedServices = Object.groupBy(currServices, (svc, index) => {
        return svc.parentID;
    });
    /*var groupedServices = [];
    if (services.length) {
        services.forEach((svc) => {
            if (svc.parentID < 0) {
                groupedServices.push(svc);
                currServices.forEach((svcAct) => {
                    if (!svc.subServices) svc.subServices = [];
                    svc.subServices.push(svcAct);
                });
            }
        });
    }*/

    console.debug(groupedServices);

    if (site) pictureData.defaultValue = site.avatar;


    var homeModel = null;

    return (
        <Modal isOpen={true} className={`modal-report ${noScroll ? "modal-noscroll" : ""}`} size="xxl" scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>    
            <ModalBody className="px-2" style={{ overflowX: "hidden" }}>
                <Row>
                    <Col className="pb-3" xs="12">
                        <h3 className="mb-2">Bienvenue</h3>
                        <p>Vous pouvez renseigner ces quelques informations afin de personnaliser votre environnement.</p>

                        <Card className="widget dash p-3">
                            <h5 className="title">Agence</h5>
                            <div className="widget-tools collapse-tool" onClick={() => { setSiteIsOpen(!siteIsOpen) }} style={{ cursor: "pointer" }}>
                                <i className={`fa ${siteIsOpen ? "fa-chevron-up" : "fa-chevron-down"} fa-lg mt-3`}></i>
                            </div>
                            <Row className={`${siteIsOpen ? "" : "d-none"}`}>
                                <Col xs="12" sm="4" lg="2">
                                    <h6>Logo Agence</h6>
                                    <div style={{ width: "fit-content" }}>
                                        {site ? <PictureFrag data={pictureData} formManager={{ register: () => { return { ref: siteAvatarBtn }; }, setValue: () => { } }} modelFctManager={{ getRgbaFromHex: getRgbaFromHex, convertWidthToGrid: convertWidthToGrid, extFileCb: siteAvatarUpdate }} bindPropResult="" parentDisabled={false}
                                            srcFileManager={{ className: "siteAvatar", showContextMenu: showContextMenu, hideAllMenu: hideAllMenu }} /> : ""}
                                    </div>
                                </Col>

                                <Col xs="12" sm="8" lg="4">
                                    <h6>Nom Agence</h6>
                                    <Input type="text" className="mb-3" autoComplete="no" aria-autocomplete="none" defaultValue={(site ? site.name : "")} onChange={(event) => { setSiteName(event.target.value); }} />

                                </Col>

                                <Col xs="12" lg="6">
                                    <h6>Secteur d'activité</h6>
                                    <Select
                                        isMulti
                                        name="activitySelection"
                                        defaultValue={currActivities}
                                        options={activities}
                                        className={`basic-multi-select mb-3 ${!currActivityInvalid ? "" : "is-invalid"}`}
                                        classNamePrefix="select"
                                        placeholder="Veuillez sélectionner un secteur d'activité"
                                        onChange={(value) => {
                                            updateActivity(value);
                                        }}
                                    />

                                </Col>

                                <Col xs="12">
                                    <Button className="btn-round btn-sm float-end" color="primary" onClick={() => {
                                        var checkAct = Array.isArray(currActivities) ? currActivities : [{ value: currActivities }];
                                        if (!checkAct || checkAct.length <= 0 || !checkAct[0].value || checkAct[0].value <= 0) {
                                            Swal.fire({
                                                icon: "warning",
                                                title: "Attention",
                                                html: "Veuillez sélectionner un secteur d'activité"
                                            });
                                            setCurrActivityInvalid(true);
                                            setSiteIsOpen(true); setModelsIsOpen(false); setEmployeesIsOpen(false);
                                            return false;
                                        }

                                        setSiteIsOpen(false); setModelsIsOpen(true); setEmployeesIsOpen(false);
                                    }}>Suivant</Button>
                                </Col>

                            </Row>
                        </Card>


                        <Card className="widget dash p-3">
                            <h5 className="title">Application</h5>
                            <div className="widget-tools collapse-tool" onClick={() => { setModelsIsOpen(!modelsIsOpen) }} style={{ cursor: "pointer" }}>
                                <i className={`fa ${modelsIsOpen ? "fa-chevron-up" : "fa-chevron-down"} fa-lg mt-3`}></i>
                            </div>
                            <Row className={`${modelsIsOpen ? "" : "d-none"}`}>

                                <Col sm="12" md="12" xl="6">
                                    <h6>Couleurs de l'application</h6>
                                    <div className="d-inline-block me-3"><Label className="d-inline-block me-2">Couleur 1</Label><Input type="color" className="d-inline-block" autoComplete="no" aria-autocomplete="none" defaultValue={color1} onChange={(event) => { setColor1(event.target.value); }} style={{ width: "50px" }} /></div>
                                    <div className="d-inline-block me-3"><Label className="d-inline-block me-2">Couleur 2</Label><Input type="color" className="d-inline-block" autoComplete="no" aria-autocomplete="none" defaultValue={color2} onChange={(event) => { setColor2(event.target.value); }} style={{ width: "50px" }} /></div>
                                    <div className="d-inline-block me-3"><Label className="d-inline-block me-2">Couleur 3</Label><Input type="color" className="d-inline-block" autoComplete="no" aria-autocomplete="none" defaultValue={color3} onChange={(event) => { setColor3(event.target.value); }} style={{ width: "50px" }} /></div>
                                    <div className="d-inline-block me-3"><Label className="d-inline-block me-2">Couleur Texte</Label><Input type="color" className="d-inline-block" autoComplete="no" aria-autocomplete="none" defaultValue={color4} onChange={(event) => { setColor4(event.target.value); }} style={{ width: "50px" }} /></div>


                                    <div className="d-none">
                                        <h6>Sélection &gt; Accueil Mobile</h6>
                                        <Row>
                                            <Col>
                                                <Card className={`model-selection widget dash p-3 ${currModelHome === "HOME_1" ? "selected" : ""}`} onClick={() => { updateSingleModel("HOME_1"); }}>
                                                    <h6 className="title">Home 1</h6>
                                                    <Row>


                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card className={`model-selection widget dash p-3 ${currModelHome === "HOME_2" ? "selected" : ""}`} onClick={() => { updateSingleModel("HOME_2"); }}>
                                                    <h6 className="title">Home 2</h6>
                                                    <Row>


                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="d-none">
                                        <h6>Sélection &gt; Modèle Rapport</h6>
                                        <Row>
                                            <Col>
                                                <Card className={`model-selection widget dash p-3 ${currModelReport === "DTL_REPORT_1" ? "selected" : ""}`} onClick={() => { updateSingleModel("DTL_REPORT_1"); }}>
                                                    <h6 className="title">Rapport 1</h6>
                                                    <Row>


                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card className={`model-selection widget dash p-3 ${currModelReport === "DTL_REPORT_2" ? "selected" : ""}`} onClick={() => { updateSingleModel("DTL_REPORT_2"); }}>
                                                    <h6 className="title">Rapport 2</h6>
                                                    <Row>


                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>

                                </Col>

                                {currModel ? <Col sm="12" md="12" xl="6">
                                    <div className="d-flex mb-2" style={{ width: "140px" }}>
                                        <div className="border border-dark" style={{ height: "150px", width: "50px", background: "white" }}>
                                            <div style={{ height: "35px", width: "48px", background: color1 }}></div>
                                            <div style={{ height: "85px", width: "48px", background: "white", fontSize: "8px", color: "grey" }}>
                                                <i className="fa fa-file mb-1 ms-1 me-1" style={{ color: color3 }}></i>
                                                titre
                                            </div>
                                            <div style={{ height: "30px", width: "48px", background: color1 }}></div>
                                        </div>
                                        <div className="w-100">
                                            <div className="w-100 d-inline-block" style={{ height: "20px", paddingLeft: "4px", paddingTop: "7px", background: color1, fontSize: "8px", color: color4 }}>titre</div>
                                            <div className="d-inline-block text-center m-1" style={{ width: "40%", height: "34px", background: color2, borderStyle: "solid", borderWidth: "1px", borderColor: color1 }}>
                                                <i className="fa fa-file mb-1" style={{ color: color3 }}></i>
                                                <div className="w-100" style={{ height: "12px", background: color1, marginTop: "-2px", fontSize: "8px", color: color4 }}>titre</div>
                                            </div>
                                            <div className="d-inline-block text-center m-1" style={{ width: "40%", height: "34px", background: color2, borderStyle: "solid", borderWidth: "1px", borderColor: color1 }}>
                                                <i className="fa fa-file mb-1" style={{ color: color3 }}></i>
                                                <div className="w-100" style={{ height: "12px", background: color1, marginTop: "-2px", fontSize: "8px", color: color4 }}>titre</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none">
                                        <h6>{currModel.title}</h6>
                                        <p>Descriptif zdfkzsd jnoijvcadojc nadoskcnoae ncvojanc ojanzcoazndvojbnazd iojvasdoij cnoascnoa ncojbnaqsco jbsdvobn sonvsod bnvcodqsjn coqsdjcn qd</p>
                                        <div className="hidden">
                                            <img className="w-100" alt="preview" src="../api/data/DownloadFileByUri?fileId=97&fileName=20240605142940_Screen_Home.png&accessToken=iUX0XWgQ1YhZ3GLtKgvgYIy2mAJvKgjIEFLpyRUDXF5FweoUCvw%2beqz5vvmgRf8h%2bInSeUac%2f%2bYfCaDKbTv18pE5Z8DLvAjC5ShQz7rx%2b%2b8Unm6e8DbF7Xw3%2bl0sKOwvnA3ytqXU7u7J4MXnn2ecfcjPsFEe0uRzyMrJqyRlMvjF9afQIPms4%2bbGtC6gk7EzfTMBtdzeGehAbR%2fq24UGHP6wkq1ukmn6g9I6%2boi6%2fnDAKKVDyFWeaRS0fjJr2Njx81e4DZVAXUd5ziEtqfyT0nmhNdgi7da7Q2BS4K22NLpNh%2fwiZAsgIzqSTGknBkKR%2bsAD1LkWeVRlS5hj4tqEFihgBda%2bvwmX2ubyvNdVqds5ufxk8LyC81Rj549ifuPdJU1swwC2I8VCc1nshFqkskKbAFb4%2flgwESn4PFmhhrQLvYrZZhlpmX%2fI8xTX3ce5w71VvOhnDbz2Hlxkq5FlcW%2bNVOpMEBBmCW%2blMXlN%2bmmsBbDXDdVx7OyEqK4KO%2fjb4NGglahGaPUWfcMPGyTZtoKO985MgA2B4ofbItynXb1oCePbKXkzpxF6EH9m2LnBrVUgFnNoIiRvFpX0M37WjYNrFiYvt%2fTfq1PmlCnbgD6ERHxzAgGJFvTmXx6yzwQD&newWindow=false" />
                                        </div>
                                    </div>
                                    {homeModel ? <AGenerateModel parentProps={{ type: "site", dataCfg: parentContext.props.dataCfg }} ref={modalGenManager} noButtons
                                        onSubmit={(data, isUpdate) => { return callback(data, isUpdate) }} appProps={parentContext.props} checkRights={parentContext.props.checkRights} getRoutes={parentContext.props.getRoutes} configModel={homeModel} modelsList={modelsList} loadedData={null} />
                                    : ""}

                                </Col> : <Col sm="12" md="12" xl="6"></Col>}

                                <Col>
                                    <Button className="btn-round btn-sm float-end" color="primary" onClick={() => { setSiteIsOpen(false); setModelsIsOpen(false); setEmployeesIsOpen(true); setShowFinalize(true); }}>Suivant</Button>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="widget dash p-3">
                            <h5 className="title">Employés (optionnel)</h5>
                            <div className="widget-tools collapse-tool" onClick={() => { setEmployeesIsOpen(!employeesIsOpen) }} style={{ cursor: "pointer" }}>
                                <i className={`fa ${employeesIsOpen ? "fa-chevron-up" : "fa-chevron-down"} fa-lg mt-3`}></i>
                            </div>
                            <Row className={`${employeesIsOpen ? "" : "d-none"}`}>

                                <h6>Saisie des Employés</h6>

                                <Button className="ms-3 px-3 w-auto" color="primary" onClick={() => {
                                    addEmployee();
                                }}><i className="fa fa-plus"></i>Ajouter</Button>
                                <Row className="w-100">
                                    {Object.keys(employees).map((employeeKey, index) => {
                                        var employee = employees[employeeKey];
                                        return (<div key={"emp" + index} style={{ width: "350px" }}>
                                            <Card className={`widget dash`}>
                                                <CardHeader className="pt-0">
                                                    <CardTitle tag="h6" className="mt-3">{employee.name + " " + employee.firstName}</CardTitle>
                                                    <div className="widget-tools collapse-tool">
                                                        {employee.email}
                                                    </div>
                                                    <div className='borderTheme'></div>
                                                </CardHeader>
                                                <CardBody>
                                                    <Button className="btn-icon" size="sm" color="danger" onClick={() => {
                                                        removeEmployee(employeeKey);
                                                    }}><i className="fa fa-trash"></i></Button>
                                                </CardBody>
                                            </Card>
                                        </div>);
                                    })}
                                </Row>
                            </Row>
                        </Card>

                    </Col>
                </Row>
                <Menu id={`contextMenu-siteAvatar`} onVisibilityChange={() => {/*console.debug(document.querySelector(".contexify") ? document.querySelector(".contexify").outerHTML : "")*/ }}>
                    <Item data="genFileDel" onClick={fileDel.bind(this)}>
                        <i className="fa fa-times" aria-hidden="true"></i>&nbsp;Supprimer
                    </Item>
                </Menu>

            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <div style={{ flex: "auto" }}><Button className="btn-simple px-3 f-start" color="dark" onClick={() => { finalizeSecondStep(true); }}><i className="fa fa-eye-slash"></i>Pas intéressé</Button></div>
                {
                    showFinalize ? <Button className="px-3" color="success" onClick={() => {
                        finalizeFirstStep().then(result => {
                            var flag = result;
                            console.debug(flag);
                            if (flag) finalizeSecondStep();
                        });
                    }}><i className="fa fa-save"></i>Finaliser</Button> : ""
                }
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>Fermer</Button>
            </ModalFooter>
        </Modal>
    );
}


//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de ReportModal
function EmployeeModal({ title, parentContext, modalGenManager, noScroll, configModel, modelsList, dataEmployee, callback }) {
    React.useEffect(() => {
        //console.debug("mount");

        //TODO : Check différence draft/ManualReport

        //if (parentContext.props.dataCfg && parentContext.props.dataCfg.dlgSup_enabledMClient) initClientList(null, 0);//TODO
        initEmployeeModal();
    }, []);



    function initEmployeeModal() {

    }

    //console.debug("render modal");

    configModel = JSON.parse(JSON.stringify(configModel));

    return (
        <Modal isOpen={true} className={`modal-report ${noScroll ? "modal-noscroll" : ""}`} size="xl" scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={{ overflowX: "hidden" }}>
                <AGenerateModel parentProps={{ type: "employee", dataCfg: parentContext.props.dataCfg }} ref={modalGenManager} noButtons
                    onSubmit={(data, isUpdate) => { return callback(data, isUpdate) }} appProps={parentContext.props} checkRights={parentContext.props.checkRights} getRoutes={parentContext.props.getRoutes} configModel={configModel} modelsList={modelsList} loadedData={dataEmployee} />

            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                {
                    dataEmployee ? <Button className="px-3" color="success" onClick={() => {
                        var generateModel = modalGenManager.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm(true);
                        }
                    }}><i className="fa fa-edit"></i>Modifier</Button> : <Button className="px-3" color="success" onClick={() => {
                        var generateModel = modalGenManager.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm();
                        }
                    }}><i className="fa fa-plus"></i>Ajouter</Button>
                }
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>Annuler</Button>
            </ModalFooter>
        </Modal>
    );
}
