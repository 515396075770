import React from 'react';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import {
    Row, Col
} from "reactstrap";

export default function ItemsContainerFrag({ data, bindPropResult, modelFctManager, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };

    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }, []);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.getParent = () => {
        return parentProps.parentData;
    };
    data.functionManager.triggerChange = () => {
        return new Promise((resolve, reject) => {

            if (data.onActionEvents && data.onActionEvents["onChange"]) {
                data.onActionEvents["onChange"].forEach((args, index) => {
                    modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                        console.debug("RESPONSE");
                        console.debug(actionResponse);
                        if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: actionResponse.errorMessage
                            });
                        }
                    });
                });
            }

            resolve({ isSuccess: true, result: 0 });
        });
    };

    var childs = "";
    if (data.children) {
        childs = data.children.map((child) => {
            return modelFctManager.componentTyped(child, { parentData: data, childs: data.children, parentDisabled: (parentDisabled || data.disabled ? true : false) });
        });
    }

    var frameClass = "";
    var frameStyle = {};
    var groupClass = "";
    var groupStyle = {};
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;
            if (data.customDesign.design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.design.boxSize) * 100);
            }


            if (design.backColor) frameStyle.background = modelFctManager.getRgbaFromHex(design.backColor);
            if (design.borderColor || design.borderSize) {
                if (design.borderColor) frameStyle.borderColor = modelFctManager.getRgbaFromHex(design.borderColor);

                if (design.borderSize) frameStyle.borderWidth = design.borderSize;
                else frameStyle.borderWidth = "1px";

                frameStyle.borderStyle = "solid";
            }
            if (design.borderRadius) frameStyle.borderRadius = design.borderRadius;

            if (design.margin) {
                frameClass += " p-0";
                frameStyle.margin = design.margin;
            }
            if (design.marginStart) {
                frameClass += " ps-0";
                frameStyle.marginLeft = design.marginStart;
            }
            if (design.marginTop) frameStyle.marginTop = design.marginTop;
            if (design.marginEnd) {
                frameClass += " pe-0";
                frameStyle.marginRight = design.marginEnd;
            }
            if (design.marginBottom) frameStyle.marginBottom = design.marginBottom;

            switch (parseInt(data.customDesign.design.spacing)) {
                case 1: groupClass += " gx-1"; break;
                case 2: groupClass += " gx-2"; break;
                case 3: groupClass += " gx-3"; break;
                case 4: groupClass += " gx-4"; break;
                case 5: groupClass += " gx-5"; break;
                default:
                    if (data.customDesign.design.spacing > 5) groupClass += " gx-5";
                    break;
            }
            switch (data.customDesign.design.alignment) {
                case "right":
                    //frameStyle.direction = "rtl";
                    groupClass += " justify-content-end";
                    break;
                case "center":
                    groupClass += " justify-content-center";
                    break;
                default:
                    break;
            }
        }
    }

    if (data.subType === "floating") {
        frameClass += " floating-group";
    }

    return <Col data-name="ItemsContainerFrag" data-element={data.objType} xs="auto" className={`${frameClass} p-0`} style={frameStyle}><Row className={`${groupClass} m-0`} style={groupStyle}>{childs}</Row></Col>;
}