import React from 'react';
import { createRoot } from 'react-dom/client';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import {
    Button, Col,
    Label, Input,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";


import * as commonFunc from 'scripts/common';
import CDataTable, { showDtPrevID, showDtNextID, generateTable } from "components/CDataTable";

import CNavbar from 'components/CNavbar';

import Historical from "components/Historical";
import AGenerateModel from 'components/generic/AGenerateModel';

import moment from "moment";
import ToolbarDate from 'components/ToolbarDate';

var modalRoot;

export default function SimpleListGeneric({ data, formManager, modelFctManager, accessList, bindPropResult, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors, control } = formManager;

    const [shouldRender, setShouldRender] = React.useState(false);
    const [listStatus, setListStatus] = React.useState([]);
    const [curStatus, setCurStatus] = React.useState(0);
    const [dataRows, setDataRows] = React.useState([]);
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [dataStamp, setDataStamp] = React.useState(0);
    const [curFilter, setCurFilter] = React.useState({});
    const [dtModel, setDtModel] = React.useState();
    const [dtFunctions, setDtFunctions] = React.useState();

    const [curDate, setCurDate] = React.useState(moment().format());
    const [curPeriod, setCurPeriod] = React.useState("L");

    const modalGenManager = React.createRef();

    React.useEffect(() => {
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }

        loadStatusBtnGrp();
        //getListData();

        return () => {
            //console.debug("unmount");
            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }
        }
    }, []);

    React.useEffect(() => {
        console.debug(data);
        if (data.defaultValue) {
            if (!(systemItem["@@URL_GETLIST@@"] && systemItem["@@URL_GETLIST@@"].argumentsObj && systemItem["@@URL_GETLIST@@"].argumentsObj.cPreventStartLoad)) {
                getListData();
            }
        }
        else {
            setDataRows([]);
            setFilteredRows([]);
            setDataStamp(0);
            if (!dtModel) getModel();
        }
    }, [data]);


    React.useEffect(() => {
        if (dataStamp > 0) getListData();
    }, [curStatus]);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.clearModal = () => {
        return new Promise((resolve, reject) => {
            if (modalRoot) {
                modalRoot.unmount();
                modalRoot = null;
            }

            resolve({ isSuccess: true, result: true });
        });
    };
    data.functionManager.getGetListCpt = () => { return systemItem["@@URL_GETLIST@@"]; };
    data.functionManager.refreshList = () => { getListData(); };


    const { ref: refElem, ...restElem } = register(bindPropResult);

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));


    var systemItem = accessList;//modelFctManager.onActionRequired(data.name, { trigger: "findFragment", target: data.src } , data).then(actionResponse => {
    //Info : data.subType echo à systemItem["@@SECONDARY_KEY@@"].subType

    var primaryKey = commonFunc.camelize(systemItem["@@PRIMARY_KEY@@"].value);
    var secondaryKey = commonFunc.camelize(systemItem["@@SECONDARY_KEY@@"].value);

    var dtConfig = {
        columnDefs: [
            {
                createdCell: (td, cellData, rowData, row, col) => {
                    var currRoot = createRoot(td);

                    var showAddSup = false;
                    if (systemItem["@@URL_ADDSUP@@"]) {
                        if (systemItem["@@URL_ADDSUP@@"].argumentsObj && systemItem["@@URL_ADDSUP@@"].argumentsObj.cShowButton) {
                            var value = commonFunc.getValue(rowData, systemItem["@@URL_ADDSUP@@"].argumentsObj.cShowButton);
                            showAddSup = commonFunc.isBlank(value);
                        }
                        else {
                            showAddSup = true;
                        }
                    }

                    var rightsObj = systemItem["@@URL_ADDSUP@@"];
                    if (showAddSup && rightsObj.checkRights) {
                        showAddSup = modelFctManager.checkRights(rightsObj.checkRights.entity, rightsObj.checkRights.rights);
                    }
                    rightsObj = systemItem["@@URL_UPD@@"];
                    var updRights = true;
                    if (rightsObj && rightsObj.checkRights) {
                        updRights = modelFctManager.checkRights(rightsObj.checkRights.entity, rightsObj.checkRights.rights);
                    }
                    rightsObj = systemItem["@@URL_RMV@@"];
                    var rmvRights = true;
                    if (rightsObj && rightsObj.checkRights) {
                        rmvRights = modelFctManager.checkRights(rightsObj.checkRights.entity, rightsObj.checkRights.rights);
                    }
                    rightsObj = systemItem["@@URL_FLOWS@@"];
                    var flowsRights = true;
                    if (rightsObj && rightsObj.checkRights) {
                        flowsRights = modelFctManager.checkRights(rightsObj.checkRights.entity, rightsObj.checkRights.rights);
                    }
                    if (rightsObj && rightsObj.argumentsObj && rightsObj.argumentsObj.cShowButton) {
                        var value2 = commonFunc.getValue(rowData, rightsObj.argumentsObj.cShowButton);
                        flowsRights &= !commonFunc.isBlank(value2);
                    }


                    var renderSupFields = [];
                    var supFields = systemItem["@@URL_GETLIST@@"];
                    if (supFields && supFields.argumentsObj && supFields.argumentsObj.actionsSupFields && Object.keys(supFields.argumentsObj.actionsSupFields).length) {
                        renderSupFields.push(<><br /><br /></>);
                        Object.keys(supFields.argumentsObj.actionsSupFields).forEach((field) => {
                            var obj = supFields.argumentsObj.actionsSupFields[field];
                            //rowData[supFields]
                            var renderFunc = generateTable(obj.generateFunction, obj.generateArgs);
                            if (renderFunc) {
                                renderSupFields.push(renderFunc(cellData, null, rowData));
                            }
                            else {
                                renderSupFields.push(commonFunc.getValue(rowData, field) || "");
                            }
                        });
                    }


                    currRoot.render(<>{showAddSup ? <Button className='btn-icon m-0 me-1' disabled={disabled} color="success" size="sm"
                        onClick={() => { saveData(rowData[secondaryKey], rowData, true) }} title={t("gal_add")}><i className='fa fa-plus'></i></Button> : ""}
                        {(!showAddSup && systemItem["@@URL_UPD@@"] && updRights) ? <Button className='btn-icon m-0 me-1' disabled={disabled} color="primary" size="sm"
                            onClick={() => { saveData(rowData[secondaryKey], rowData) }} title={t("gal_edit")}><i className='fa fa-edit'></i></Button> : ""}
                        {(systemItem["@@URL_RMV@@"] && rmvRights) ? <Button className='btn-icon m-0' disabled={disabled} color="danger" size="sm"
                            onClick={() => removeData(rowData[secondaryKey], rowData)} title={t("gal_remove")}><i className='fa fa-trash-alt'></i></Button> : ""}
                        {(systemItem["@@URL_FLOWS@@"] && flowsRights) ? <Button className='btn-simple btn-icon m-0' disabled={disabled} color="dark" size="sm"
                            onClick={() => showFlows(rowData[secondaryKey], rowData)} title={t("gal_flows")}><i className='fa fa-clock-rotate-left'></i></Button> : ""}{renderSupFields.map((elem, index) => <span key={"slg" + index}>{elem}</span>)}</>);
                }, "targets": "renderingActions"
            },
        ],
        dtOnSelect: function (dataSel) {
            triggerOnChange();
        },
        dtOnUnselect: function (dataSel) {
            triggerOnChange();
        }
    };


    function dtFunctionPanel(id, fctPanel) {
        var newFcts = dtFunctions || {};
        if (!newFcts[id]) newFcts[id] = {};
        newFcts[id] = fctPanel;
        setDtFunctions(newFcts);
    }

    function getModel() {
        if (data.argumentsObj.dtConfig) {
            modelFctManager.onActionRequired(data.name, { trigger: "getModel", value: data.argumentsObj.dtConfig }, data).then(actionResponse => {
                if (actionResponse.isSuccess) {
                    setDtModel(actionResponse.result);

                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        didOpen: () => { Swal.hideLoading() },
                        html: actionResponse.errorMessage
                    });
                }
            });
        }
    }

    function triggerOnChange() {
        //TODO : récup listValues
        //TODO call triggerOnChange
        /*console.debug(dtFunctions["tableGen" + data.subType]);
        var dataList = dtFunctions["tableGen" + data.subType].getData(true);
        //var dataList = dtFunctions["tableGen" + data.subType].getData(false);

        if (data.onActionEvents && data.onActionEvents["onChange"]) {
            data.onActionEvents["onChange"].forEach((args, index) => {
                if (args.value === "listValues") args.value = dataList.data;

                modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }*/
    }

    function getListData() {
        setDataRows([]);
        setFilteredRows([]);
        setDataStamp(0);

        Swal.fire({
            title: t("gal_loading"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        if (!dtModel) getModel();

        var args = {};
        var getlistItem = systemItem["@@URL_GETLIST@@"];
        if (getlistItem && getlistItem.argumentsObj && Object.keys(getlistItem.argumentsObj).length) {
            Object.keys(getlistItem.argumentsObj).forEach((key) => {
                var valueArgs = getlistItem.argumentsObj[key];
                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                else args[key] = valueArgs;

                //Récup du statut par défaut (soit valeur defaultArgs, soit celle en state)
                if (valueArgs === "curStatus") {
                    var defaultStatus = curStatus;
                    var getstatusItem = systemItem["@@URL_GETSTATUS@@"];
                    if (!defaultStatus && getstatusItem && getstatusItem.argumentsObj && Object.keys(getstatusItem.argumentsObj).length) {
                        defaultStatus = getstatusItem.argumentsObj["defaultStatus"]
                    }
                    args[key] = defaultStatus;
                }
            });
            if (args.filters && (args.filterInput != null && args.filterInput !== undefined)) {
                if (!commonFunc.isBlank(args.filterInput)) {
                    args.filters = [...args.filters];
                    args.filters.push(args.filterInput);
                }
                delete args.filterInput;
            }

            var useDate = getlistItem.argumentsObj.cUseToolbarDate;
            if (useDate) {
                args.startDate = curDate;
                args.period = curPeriod;
            }

            var callbackResponse = (srcElem) => {
                if (srcElem && srcElem.length && srcElem[0].extras) {
                    srcElem.forEach((row) => {
                        row.orgExtras = row.extras;
                        row.extras = {};
                        if (row.orgExtras && row.orgExtras.length) {
                            row.orgExtras.forEach((extra) => {
                                row.extras[extra.name] = extra.value;
                            });
                        }
                    });
                }
                if (srcElem && srcElem.length && srcElem[0].hasOwnProperty("custom")) {
                    srcElem.forEach((row) => {
                        if (row.custom) {
                            row.custom.orgExtras = row.custom.extras;
                            row.custom.extras = {};
                            if (row.custom.orgExtras && row.custom.orgExtras.length) {
                                row.custom.orgExtras.forEach((extra) => {
                                    row.custom.extras[extra.name] = extra.value;
                                });
                            }
                        }
                    });
                }
                Swal.close();
                setDataRows(srcElem);
                setFilteredRows(filterData(curFilter, srcElem));
                setDataStamp(new Date().getTime());
            };

            if (getlistItem.argumentsObj.useProp) {
                var list = [];
                var valueArgs = getlistItem.argumentsObj.useProp;
                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) list = (systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                else list = valueArgs;
                callbackResponse(list);
            }
            else {
                var url = "../" + getlistItem.argumentsObj.url;

                var callMethod = commonFunc.getMethod;
                if (getlistItem.argumentsObj.cMethod === "POST") callMethod = commonFunc.postMethod;

                callMethod(url, args).then((response) => {

                    if (response.data) {
                        if (response.data.isSuccess) {
                            if (response.data.extraInfo && response.data.extraInfo.isSuccess) {
                                callbackResponse(response.data.extraInfo.result);
                            }
                            else if (Array.isArray(response.data.result)) {
                                callbackResponse(response.data.result);
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: t("gal_error"),
                                    didOpen: () => { Swal.hideLoading() },
                                    html: t(data.subType + "_errorGetlist") + " :<br>" + response.data.errorMessage
                                });
                            }
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: t("gal_error"),
                                didOpen: () => { Swal.hideLoading() },
                                html: t(data.subType + "_errorGetlist") + " :<br>" + response.data.errorMessage
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            didOpen: () => { Swal.hideLoading() },
                            html: t(data.subType + "_errorGetlist")
                        });
                    }
                });
            }
        }
        else {
            if (!getlistItem) {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    didOpen: () => { Swal.hideLoading() },
                    html: t("gal_errorCfg")
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    didOpen: () => { Swal.hideLoading() },
                    html: t("gal_invalidArgs")
                });
            }
        }
    }

    function filterData(localFilter, rows) {
        if (!rows) rows = dataRows;

        var filteredRows = rows.filter((row) => {
            var flag = true;
            Object.keys(localFilter).forEach((key) => {
                var filterValue = localFilter[key];
                flag = (commonFunc.isBlank(filterValue) || commonFunc.getValue(row, key) === filterValue);
            });
            return flag;
        });

        return filteredRows;
    }

    function saveList() {
        var saveItem = systemItem["@@URL_SAVELIST@@"];
        var saveProcess = function () {
            Swal.fire({
                title: t("gal_saving"),
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            var args = {};
            if (saveItem && saveItem.argumentsObj && Object.keys(saveItem.argumentsObj).length) {
                var dataList = dtFunctions["tableGen" + data.subType].getData(saveItem.argumentsObj.onlySelected);

                var postData = dataList.data;
                if (!commonFunc.isBlank(saveItem.subType)) {
                    switch (saveItem.subType) {
                        case "keyItem":
                            postData = dataList.data.map((row, index) => { return { id: row[dataList.rowId], value: "", tag: "" } });
                            break;
                        default: break;
                    }
                }

                Object.keys(saveItem.argumentsObj).forEach((key) => {
                    var valueArgs = saveItem.argumentsObj[key];
                    if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                    else args[key] = valueArgs;
                    if (valueArgs === "listValues") args[key] = postData;
                });

                var url = "../" + saveItem.argumentsObj.url;

                console.debug(args);
                //return;//TEMP
                commonFunc.postMethod(url, args).then((response) => {
                    var dataSave = response.data;
                    if (dataSave) {
                        if (dataSave.isSuccess) {
                            Swal.fire({
                                icon: "success",
                                title: (t(data.subType + "_success") === (data.subType + "_success") ? t("gal_success") : t(data.subType + "_success")),
                                html: (t(data.subType + "_successUpd") === (data.subType + "_successUpd") ? t("gal_successUpd") : t(data.subType + "_successUpd")),
                                didOpen: () => { Swal.hideLoading() },
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                            }).then((result) => {
                                getListData();
                            });

                        }
                        else {
                            var supErrors = "";
                            if (dataSave.errors && dataSave.errors.length) {
                                supErrors = dataSave.errors.map((error) => {
                                    return "<br>" + error.desc + "(" + error.value + ")";
                                });
                            }
                            Swal.fire({
                                icon: "error",
                                title: t("gal_error"),
                                html: t("gal_errorUpd") + " :<br>" + dataSave.errorMessage + supErrors,
                                didOpen: () => { Swal.hideLoading() },
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            html: t("gal_errorUpd"),
                            didOpen: () => { Swal.hideLoading() },
                        });
                    }
                });
            }
            else {
                if (!saveItem) {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        html: t("gal_errorCfg"),
                        didOpen: () => { Swal.hideLoading() },
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        html: t("gal_invalidArgs")
                    });
                }
            }
        };

        if (saveItem && saveItem.argumentsObj && saveItem.argumentsObj.unconfirmProcess) {
            saveProcess();
        }
        else {
            Swal.fire({
                icon: "question",
                title: t(data.subType + "_updListTitle"),
                html: t(data.subType + "_updListTitle2"),
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: t("gal_yes"),
                cancelButtonText: t("gal_no"),
            }).then((result) => {
                if (result.value) {
                    saveProcess();
                }
            });
        }
    }

    function removeList() {
        var removeItem = systemItem["@@URL_REMOVELIST@@"];
        var removeProcess = function () {
            Swal.fire({
                title: t("gal_removing"),
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            var args = {};
            if (removeItem && removeItem.argumentsObj && Object.keys(removeItem.argumentsObj).length) {
                var dataList = dtFunctions["tableGen" + data.subType].getData(removeItem.argumentsObj.onlySelected);

                var postData = dataList.data;
                if (!commonFunc.isBlank(removeItem.subType)) {
                    switch (removeItem.subType) {
                        case "keyItem":
                            postData = dataList.data.map((row, index) => { return { id: row[dataList.rowId], value: "", tag: "" } });
                            break;
                        default: break;
                    }
                }

                Object.keys(removeItem.argumentsObj).forEach((key) => {
                    var valueArgs = removeItem.argumentsObj[key];
                    if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                    else args[key] = valueArgs;
                    if (valueArgs === "listValues") args[key] = postData;
                });

                var url = "../" + removeItem.argumentsObj.url;

                console.debug(args);
                //return;//TEMP
                commonFunc.postMethod(url, args).then((response) => {
                    var dataSave = response.data;
                    if (dataSave) {
                        if (dataSave.isSuccess) {
                            Swal.fire({
                                icon: "success",
                                title: (t(data.subType + "_success") === (data.subType + "_success") ? t("gal_success") : t(data.subType + "_success")),
                                html: (t(data.subType + "_successRmv") === (data.subType + "_successRmv") ? t("gal_successRmv") : t(data.subType + "_successRmv")),
                                didOpen: () => { Swal.hideLoading() },
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                            }).then((result) => {
                                getListData();
                            });

                        }
                        else {
                            var supErrors = "";
                            if (dataSave.errors && dataSave.errors.length) {
                                supErrors = dataSave.errors.map((error) => {
                                    return "<br>" + error.desc + "(" + error.value + ")";
                                });
                            }
                            Swal.fire({
                                icon: "error",
                                title: t("gal_error"),
                                html: t("gal_errorRmv") + " :<br>" + dataSave.errorMessage + supErrors,
                                didOpen: () => { Swal.hideLoading() },
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            html: t("gal_errorRmv"),
                            didOpen: () => { Swal.hideLoading() },
                        });
                    }
                });
            }
            else {
                if (!removeItem) {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        html: t("gal_errorCfg"),
                        didOpen: () => { Swal.hideLoading() },
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        html: t("gal_invalidArgs")
                    });
                }
            }
        };

        if (removeItem && removeItem.argumentsObj && removeItem.argumentsObj.unconfirmProcess) {
            removeProcess();
        }
        else {
            Swal.fire({
                icon: "question",
                title: t("gal_rmvTitle"),
                html: t(data.subType + "_rmvListTitle"),
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: t("gal_yes"),
                cancelButtonText: t("gal_no"),
            }).then((result) => {
                if (result.value) {
                    removeProcess();
                }
            });
        }
    }
    function loadStatusBtnGrp() {
        setListStatus([]);

        Swal.fire({
            title: t("gal_loading"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var args = {};
        var getstatusItem = systemItem["@@URL_GETSTATUS@@"];
        if (getstatusItem && getstatusItem.argumentsObj && Object.keys(getstatusItem.argumentsObj).length) {
            Object.keys(getstatusItem.argumentsObj).forEach((key) => {
                var valueArgs = getstatusItem.argumentsObj[key];
                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                else args[key] = valueArgs;
            });

            var url = "../" + getstatusItem.argumentsObj.url;
            commonFunc.getMethod(url, args).then((response) => {

                if (response.data) {
                    if (response.data.isSuccess) {

                        Swal.close();
                        setListStatus(response.data.result);
                        setCurStatus(getstatusItem.argumentsObj.defaultStatus);
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            didOpen: () => { Swal.hideLoading() },
                            html: t(data.subType + "_errorGetstatus") + " :<br>" + response.data.errorMessage
                        });
                    }
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        didOpen: () => { Swal.hideLoading() },
                        html: t(data.subType + "_errorGetstatus")
                    });
                }
            });
        }
        else {
            if (!getstatusItem) {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    didOpen: () => { Swal.hideLoading() },
                    html: t("gal_errorCfg")
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    didOpen: () => { Swal.hideLoading() },
                    html: t("gal_invalidArgs")
                });
            }
        }
    }

    function getData(idElem) {
        return new Promise((resolve, reject) => {
            var id = idElem;
            if (id > 0) {
                Swal.fire({
                    title: t("gal_loading"),
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                var args = {};
                var getItem = systemItem["@@URL_GET@@"];
                if (getItem && getItem.argumentsObj && Object.keys(getItem.argumentsObj).length) {
                    Object.keys(getItem.argumentsObj).forEach((key) => {
                        var valueArgs = getItem.argumentsObj[key];
                        if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                        else args[key] = valueArgs;
                    });

                    var url = "../" + getItem.argumentsObj.url;
                    commonFunc.getMethod(url, args).then((response) => {

                        if (response.data) {
                            if (response.data.isSuccess) {

                                Swal.close();

                                resolve(response.data);
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: t("gal_error"),
                                    didOpen: () => { Swal.hideLoading() },
                                    html: t(data.subType + "_errorGet") + " :<br>" + response.data.errorMessage
                                });
                                resolve({ isSuccess: false, result: (t(data.subType + "_errorGet") + " :<br>" + response.data.errorMessage) });
                            }
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: t("gal_error"),
                                didOpen: () => { Swal.hideLoading() },
                                html: t(data.subType + "_errorGet")
                            });
                            resolve({ isSuccess: false, result: t(data.subType + "_errorGet") });
                        }
                    });
                }
                else {
                    if (!getItem) {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            didOpen: () => { Swal.hideLoading() },
                            html: t("gal_errorCfg")
                        });
                        resolve({ isSuccess: false, result: t("gal_errorCfg") });
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            didOpen: () => { Swal.hideLoading() },
                            html: t("gal_invalidArgs")
                        });
                        resolve({ isSuccess: false, result: t("gal_invalidArgs") });
                    }
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    html: t("gal_invalidID")
                });
                resolve({ isSuccess: false, result: t("gal_invalidID") });
            }
        });
    }

    function saveData(idElem, rowData, forcedCreate) {
        if (disabled) return;
        if (modalRoot) {
            modalRoot.unmount();
            modalRoot = null;
        }

        var currData = rowData;

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (dataElem, isUpdate) {
            return new Promise((resolve, reject) => {
                if (dataElem) {
                    Swal.fire({
                        title: t("gal_saving"),
                        didOpen: () => { Swal.showLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });

                    dataElem[secondaryKey] = isUpdate ? currData[secondaryKey] : 0;

                    var saveItem = isUpdate ? systemItem["@@URL_UPD@@"] : (systemItem["@@URL_ADDSUP@@"] || systemItem["@@URL_ADD@@"]);
                    if (saveItem && saveItem.argumentsObj && Object.keys(saveItem.argumentsObj).length) {
                        var url = "../" + saveItem.argumentsObj.url;

                        if (!dataElem.avoidMerge) {
                            if (currData && currData.hasOwnProperty("custom")) {
                                dataElem = commonFunc.mergeDeep({}, currData.custom, dataElem);
                                dataElem.idEntity = currData.custom ? currData.custom.idEntity : 0;
                            }
                            else {
                                dataElem = commonFunc.mergeDeep({}, currData, dataElem);
                            }

                            dataElem.status = null;
                            dataElem.category = null;
                            dataElem.custom = null;
                            dataElem.parent = null;
                            dataElem.site = null;
                            dataElem.client = null;
                            dataElem.material = null;//Patch Upd Contact
                        }

                        console.debug(dataElem);
                        //return new Promise((resolve, reject) => { resolve({ isSuccess: false }); });//TEMP
                        commonFunc.postMethod(url, dataElem).then((response) => {
                            var dataSave = response.data;
                            if (dataSave) {
                                if (dataSave.isSuccess) {
                                    Swal.fire({
                                        icon: "success",
                                        title: t("gal_success"),
                                        html: t("gal_success" + (isUpdate ? "Upd" : "Add")),
                                        didOpen: () => { Swal.hideLoading() },
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    }).then((result) => {
                                        getListData();
                                    });

                                    //dataSave.result.idResponse = dataSave.result[secondaryKey];
                                    //resolve(dataSave.result);
                                    dataElem[secondaryKey] = dataSave.result;
                                    dataElem.idResponse = dataSave.result;
                                    resolve(dataElem);

                                    modalRoot.unmount();
                                }
                                else {
                                    var supErrors = "";
                                    if (dataSave.errors && dataSave.errors.length) {
                                        supErrors = dataSave.errors.map((error) => {
                                            return "<br>" + error.desc + "(" + error.value + ")";
                                        });
                                    }
                                    Swal.fire({
                                        icon: "error",
                                        title: t("gal_error"),
                                        html: t("gal_error" + (isUpdate ? "Upd" : "Add")) + " :<br>" + dataSave.errorMessage + supErrors,
                                        didOpen: () => { Swal.hideLoading() },
                                    });
                                    resolve(dataSave);
                                }
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: t("gal_error"),
                                    html: t("gal_error" + (isUpdate ? "Upd" : "Add")),
                                    didOpen: () => { Swal.hideLoading() },
                                });
                                resolve();
                            }
                        });
                    }
                    else {
                        if (!saveItem) {
                            Swal.fire({
                                icon: "error",
                                title: t("gal_error"),
                                html: t("gal_errorCfg"),
                                didOpen: () => { Swal.hideLoading() },
                            });
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: t("gal_error"),
                                html: t("gal_invalidArgs")
                            });
                        }
                        modalRoot.unmount();
                        resolve();
                    }
                }
                else {
                    modalRoot.unmount();
                    resolve();
                }
            });
        };


        modelFctManager.onActionRequired(data.name, { trigger: "getModel", value: data.argumentsObj.dialogConfig }, data).then(actionResponse => {
            if (actionResponse.isSuccess) {
                var type = systemItem["@@PRIMARY_KEY@@"].subType;
                var subType = systemItem["@@SECONDARY_KEY@@"].subType;
                var genId = systemItem[primaryKey].defaultValue;

                if (systemItem["@@URL_GET@@"] && idElem) {
                    getData(idElem).then((responseData) => {
                        if (responseData.isSuccess) {
                            currData = responseData.result;

                            modalRoot = createRoot(document.createElement('div'));
                            modalRoot.render(<SLGenModal t={t} configModel={actionResponse.result} appProps={modelFctManager.appProps} checkRights={modelFctManager.checkRights} dataCfg={modelFctManager.dataCfg} getRoutes={modelFctManager.getRoutes} genRef={modalGenManager} genId={genId} genType={type} subType={subType} itemData={data} noScroll={data.argumentsObj.dialogNoScroll ? true : false} size={data.argumentsObj.dialogSize} title={currData ? t(data.subType + "_updTitle") : t(data.subType + "_addTitle")} dataElem={currData} forcedCreate={forcedCreate} callback={callbackModal} />);
                        }
                    });
                }
                else {
                    modalRoot = createRoot(document.createElement('div'));
                    modalRoot.render(<SLGenModal t={t} configModel={actionResponse.result} appProps={modelFctManager.appProps} checkRights={modelFctManager.checkRights} dataCfg={modelFctManager.dataCfg} getRoutes={modelFctManager.getRoutes} genRef={modalGenManager} genId={genId} genType={type} subType={subType} itemData={data} noScroll={data.argumentsObj.dialogNoScroll ? true : false} size={data.argumentsObj.dialogSize} title={currData ? t(data.subType + "_updTitle") : t(data.subType + "_addTitle")} dataElem={currData} forcedCreate={forcedCreate} callback={callbackModal} />);
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    html: actionResponse.errorMessage
                });
            }
        });
    }

    //TODO : Ajout arguments sur ReportItem pour :
    //validation (0, 1 >> validation classique, 2 >> validation par code)
    //permanent (suppression permanente ou non)
    function removeData(id, rowData) {
        return new Promise((resolve, reject) => {
            if (disabled) { resolve({ isSuccess: false, result: "Composant Désactivé" }); return; }
            var row = rowData;
            var title = "";
            if ((!id && row) || (id && row && id === row[secondaryKey])) {
                id = id || row[secondaryKey];
                title = (row.name || "") + " " + (row.firstName || "");
            }
            if (parseInt(id) > 0) {
                var removeItem = systemItem["@@URL_RMV@@"];
                if (removeItem && removeItem.argumentsObj && Object.keys(removeItem.argumentsObj).length) {
                    Swal.fire({
                        icon: "question",
                        title: t("gal_rmvTitle"),
                        html: t(data.subType + "_rmvTitle") + "<br/>[" + id + "] " + title,
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: t("gal_yes"),
                        cancelButtonText: t("gal_no"),
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: t("gal_removing"),
                                didOpen: () => { Swal.showLoading() }
                            });
                            var args = { id: id };

                            Object.keys(removeItem.argumentsObj).forEach((key) => {
                                var valueArgs = removeItem.argumentsObj[key];
                                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                                else args[key] = valueArgs;
                            });

                            var url = "../" + removeItem.argumentsObj.url;
                            commonFunc.postMethod(url, args).then((response) => {
                                Swal.close();
                                var data = response.data;
                                if (data && data.isSuccess) {
                                    getListData();//TODO : check si besoin de refresh supplémentaire
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: t("gal_error"),
                                        html: t("gal_errorRmv") + "<br/>" + response.data.errorMessage
                                    });
                                }
                                resolve(data);
                            });

                        }
                        else {
                            resolve({ isSuccess: true, result: "" });
                        }
                    });
                }
                else {
                    if (!removeItem) {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            html: t("gal_errorCfg")
                        });
                        resolve({ isSuccess: false, result: t("gal_errorCfg") });
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            html: t("gal_invalidArgs")
                        });
                        resolve({ isSuccess: false, result: t("gal_invalidArgs") });
                    }
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    html: t("gal_invalidID")
                });
                resolve({ isSuccess: false, result: t("gal_invalidID") });
            }
        });
    }

    function showFlows(idElem, rowData) {
        if (disabled) { return; }
        if (modalRoot) {
            modalRoot.unmount();
            modalRoot = null;
        }

        if (parseInt(idElem) > 0) {
            //Traitement des informations retourné par le Dialog
            var callbackModal = function (dataElem, isUpdate) {
                modalRoot.unmount();
            };

            //setValue(secondaryKey, idElem);
            if (!systemItem[secondaryKey]) systemItem[secondaryKey] = {};
            systemItem[secondaryKey].defaultValue = idElem;

            modalRoot = createRoot(document.createElement('div'));
            modalRoot.render(<FlowsModal t={t} itemData={data} title={t(data.subType + "_flowsTitle")} idElem={idElem} rowData={rowData}
                parentContext={{ getData: getData, systemItem: systemItem, modelFctManager: modelFctManager, parentProps: parentProps }} callback={callbackModal} />);

        }
        else {
            Swal.fire({
                icon: "error",
                title: t("gal_error"),
                html: t("gal_invalidID")
            });
        }
    }

    /****** GESTION DATE (ToolbarDate) ******/

    function selPreviousPeriod() {
        var curMoment = new moment(curDate);
        var view = curPeriod;
        var begin;

        switch (view) {
            case "D": //JOUR
                begin = curMoment.clone().subtract(1, 'days');
                break;

            case "L": //SEMAINE 5J
                begin = curMoment.clone().startOf('isoWeek').subtract(7, 'days');
                break;

            case "W": //SEMAINE
                begin = curMoment.clone().startOf('isoWeek').subtract(7, 'days');
                break;

            case "M": //MOIS /JOURS
                begin = curMoment.clone().startOf('month').subtract(1, 'days').startOf('month');
                break;
            default: break;
        }
        if (begin) {
            setCurDate(begin.startOf('day').format());
        }
    }

    function datepickerChange(date) {
        if (!date) date = moment(curDate);

        setCurDate(date.format());
    }

    function selNextPeriod() {
        var curMoment = new moment(curDate);
        var view = curPeriod;
        var begin;

        switch (view) {
            case "D": //JOUR
                begin = curMoment.clone().add(1, 'days')
                break;

            case "L": //SEMAINE 5J
                begin = curMoment.clone().endOf('isoWeek').add(1, 'days');
                break;

            case "W": //SEMAINE
                begin = curMoment.clone().endOf('isoWeek').add(1, 'days');
                break;

            case "M": //MOIS /JOURS
                begin = curMoment.clone().endOf('month').add(1, 'days');
                break;
            default: break;
        }
        if (begin) {
            setCurDate(begin.startOf('day').format());
        }
    }

    function getFormattedDate() {
        switch (curPeriod) {
            case "D": return moment(curDate).format("dddd DD MMMM YYYY");
            case "L": return moment(curDate).startOf('isoWeek').format("ddd DD MMMM YYYY [(S]W[)]") + " au " + moment(curDate).endOf('isoWeek').subtract(2, 'days').format("ddd DD MMMM YYYY [(S]W[)]");
            case "W": return moment(curDate).startOf('isoWeek').format("ddd DD MMMM YYYY [(S]W[)]") + " au " + moment(curDate).endOf('isoWeek').format("ddd DD MMMM YYYY [(S]W[)]");
            case "M": return moment(curDate).startOf('month').format("ddd DD MMMM YYYY") + " au " + moment(curDate).endOf('month').format("ddd DD MMMM YYYY");
            default: return "";
        }
    }

    /****** FIN GESTION DATE (ToolbarDate) ******/


    //TODO : design
    var frameClass = "";
    var containerClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    var widthClass = "";
    var titleWidthClass = "px-0 ";
    if (data.customDesign) {
        if (data.customDesign.design) {
            if (data.customDesign.design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.design.boxSize) * 100);
            }
        }

        if (!commonFunc.isBlank(widthClass) || titleWidthClass !== "px-0 ") containerClass += " row m-0 row-cols-lg-auto2 align-items-center";
    }

    var rightsObj = systemItem["@@URL_SAVELIST@@"];
    var saveListRights = true;
    if (rightsObj && rightsObj.checkRights) {
        saveListRights = modelFctManager.checkRights(rightsObj.checkRights.entity, rightsObj.checkRights.rights);
    }
    rightsObj = systemItem["@@URL_REMOVELIST@@"];
    var removeListRights = true;
    if (rightsObj && rightsObj.checkRights) {
        removeListRights = modelFctManager.checkRights(rightsObj.checkRights.entity, rightsObj.checkRights.rights);
    }
    rightsObj = systemItem["@@URL_ADD@@"];
    var addRights = true;
    if (rightsObj && rightsObj.checkRights) {
        addRights = modelFctManager.checkRights(rightsObj.checkRights.entity, rightsObj.checkRights.rights);
    }

    var searchTag = "";
    var markClass = "";
    if (data.argumentsObj.cSearchParams) {
        searchTag = getValues(commonFunc.camelize(data.argumentsObj.cSearchParams, true)) || "";
        markClass = data.argumentsObj.cMarkClass || "";
    }

    var baseForm = <>
        {systemItem["@@URL_GETLIST@@"] && systemItem["@@URL_GETLIST@@"].argumentsObj && systemItem["@@URL_GETLIST@@"].argumentsObj.cUseToolbarDate ?
            <ToolbarDate refreshContent={getListData} customTitle={getFormattedDate()/*this.state.curDate.toLocaleString("fr-FR", { month: "long" })*/} hideRefresh={true}
                currentPeriod={curPeriod} updatePeriod={setCurPeriod}
                selPreviousPeriod={selPreviousPeriod}
                datepickerChange={datepickerChange}
                selNextPeriod={selNextPeriod}
                datepickerOpt={{ showWeeks: true, initialValue: moment(new Date()).utc().toDate() }}
                customAddBtn={<div className="d-none"></div>}></ToolbarDate>
            : ""}

        <div className="float-start">
            {!(systemItem["@@URL_GETLIST@@"] && systemItem["@@URL_GETLIST@@"].argumentsObj && systemItem["@@URL_GETLIST@@"].argumentsObj.cHideButton) ?
                (<Button className="btn-rotate px-2 mt-0 btn-simple" color="dark" size="sm" onClick={() => {
                    var getlistItem = systemItem["@@URL_GETLIST@@"];
                    if (getlistItem && getlistItem.argumentsObj && getlistItem.argumentsObj.useProp) {
                        modelFctManager.onActionRequired(data.name, { trigger: "refreshElem" }, data).then(actionResponse => {
                            if (actionResponse.isSuccess) {
                                //getListData();
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: t("gal_error"),
                                    html: actionResponse.errorMessage
                                });
                            }
                        });
                    }
                    else {
                        getListData();
                    }
                }}>
                    <i className="fa fa-sync"></i>{t(data.subType + "_refresh")}
                </Button>)
                : ""}
            {systemItem["@@URL_SAVELIST@@"] && saveListRights ? <Button className="btn-magnify px-2 mt-0" disabled={disabled} color="primary" size="sm" onClick={() => { saveList() }}>
                <i className="fa fa-save"></i>{t(data.subType + "_saveList")}
            </Button> : ""}
            {systemItem["@@URL_REMOVELIST@@"] && removeListRights ? <Button className="btn-magnify px-2 mt-0" disabled={disabled} color="danger" size="sm" onClick={() => { removeList() }}>
                <i className="fa fa-times"></i>{t(data.subType + "_removeList")}
            </Button> : ""}
            {systemItem["@@URL_ADD@@"] && addRights ? <Button className="btn-magnify px-2 mt-0" disabled={disabled} color="success" size="sm" onClick={() => { saveData() }}>
                <i className="fa fa-plus"></i>{t(data.subType + "_add")}
            </Button> : ""}
            <div className="d-inline-block" style={{ marginTop: "-10px", maxWidth:"950px" }}>
                <div className="row m-0">
                    {data.children ? data.children.map((child) => {
                        return modelFctManager.componentTyped(child, parentProps);
                    }) : ""}
                </div>
            </div>
        </div>
        <CNavbar className="float-end">
            <div className="text-end">
            {!(systemItem["@@URL_GETSTATUS@@"] && systemItem["@@URL_GETSTATUS@@"].argumentsObj && systemItem["@@URL_GETSTATUS@@"].argumentsObj.cHideButton) ? listStatus.map((dt) => {
                var statusClass = "form-badge-radio mb-1 ";
                statusClass += dt.css ? dt.css.replace("fa-", "untrigger") : "";
                return dt.isSchedulable ? <div id={`st_${dt.idStatus}`} key={dt.idStatus} className={statusClass}>
                    <div className='form-check-radio fcheck-sm mb-0'>
                        <Label check >
                            <Input id={`status_${dt.idStatus}`} name={"status"} /*disabled={this.state.editionMode}*/ checked={dt.idStatus === curStatus} type="radio" data-lvlv={dt.step} value={dt.idStatus} onChange={(e) => {
                                var value = parseInt(e.target.value);
                                setCurStatus(value);
                            }} />
                            <span className="form-check-sign" />
                            {(() => {
                                var classFa = "";
                                if (dt.css && dt.css.indexOf("fa-")) {
                                    dt.css.split(" ").forEach((classElem) => {
                                        //console.debug(classElem.startsWith("fa-") + classElem);
                                        classFa += (classElem.startsWith("fa-") ? (classElem + " ") : "");
                                    });
                                }
                                return <i className={`fa ${classFa} me-1`}></i>
                            })()}
                            {dt.name}
                        </Label>
                    </div>
                </div> : "";
            }) : ""}
            </div>
        </CNavbar>
        <div id={data.subType + "_select_filters"} className="d-none w-auto p-0">
            {data.options ? data.options.map((optionSelect, indexSelect) => {
                return <>
                    <Label className={`px-0 m-0 d-inline-block me-2`} for={"select" + indexSelect}>{optionSelect.title}</Label>
                    <Input key={indexSelect} id={"select" + indexSelect} size="sm" className={`w-auto d-inline-block`} type="select" disabled={disabled} onChange={(e) => {
                        var selValue = e.target.value;
                        var getlistItem = systemItem["@@URL_GETLIST@@"];
                        if (getlistItem && getlistItem.argumentsObj && optionSelect.defaultValue) {
                            getlistItem.argumentsObj[optionSelect.defaultValue] = selValue;
                            getListData();
                        }
                        else {
                            curFilter[optionSelect.value] = selValue;
                            setCurFilter(curFilter);
                            setFilteredRows(filterData(curFilter));
                            setDataStamp(new Date().getTime());
                        }
                    }}>
                        {(() => {
                            var options = [{ selected: true, title: "Tous", value: -1 }];

                            if (optionSelect.options) {
                                options = optionSelect.options;
                            }
                            /*else {
                                //TODO : alimenter les options avec les data
                                dataRows.forEach((row) => {
                                    var value = commonFunc.getValue(row, optionSelect.value);
                                    if (value) {
                                        options.push({ title: value, value: value });
                                    }
                                });
                            }*/

                            return options.map((option, index) => {
                                return <option className={`${option.hidden ? "d-none" : ""}`} key={index} selected={option.selected} value={option.value}>
                                    {option.customDesign && option.customDesign.design && option.customDesign.design.icon ? <i className={`fa ${option.customDesign.design.icon}`}></i> : ""}
                                    {option.title}
                                </option>
                            });
                        })()}
                    </Input>
                </>
            }) : ""}
        </div>
        {dtModel ? <CDataTable key={"gen" + data.subType} dtId={"tableGen" + data.subType} data={filteredRows} localConfig={dtConfig} tag={data.argumentsObj.dtConfig} config={dtModel} stamp={dataStamp} attrSup={{ "dt-filter-target": (data.subType + "_select_filters") }}
            functionPanel={dtFunctionPanel} searchParams={searchTag} markClass={markClass} className="table-striped table-hover nowrap" style={{ fontSize: "90%" }} /> : ""}
    </>;

    return <Col xs="auto" className={`${frameClass} ${parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`}>{baseForm}</Col>;//align-self-center
}


function SLGenModal({ t, title, configModel, dataCfg, appProps, checkRights, getRoutes, genRef, genId, genType, subType, itemData, noScroll, size, dataElem, forcedCreate, callback }) {

    return (
        <Modal isOpen={true} className={`modal-${itemData.subType} ${noScroll ? "modal-noscroll" : ""}`} size={size ? size : "xxl"} scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={noScroll ? { overflow: "visible" } : { overflowX: "hidden" }}>
                <AGenerateModel ref={genRef} noButtons onSubmit={(data, isUpdate) => callback(data, isUpdate)} appProps={appProps} checkRights={checkRights} getRoutes={getRoutes} parentProps={{ id: genId, type: genType, subType: subType, dataCfg: dataCfg }} keyMode={2} configModel={configModel} loadedData={dataElem} />
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                {
                    (dataElem && !forcedCreate) ? <Button className="px-3" color="primary" onClick={() => {
                        var generateModel = genRef.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm(true);
                        }
                    }}><i className="fa fa-edit"></i>{t("gal_edit")}</Button> : <Button className="px-3" color="success" onClick={() => {
                        var generateModel = genRef.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm();
                        }
                        }}><i className="fa fa-plus"></i>{t("gal_add")}</Button>
                }
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>{t("gal_cancel")}</Button>
            </ModalFooter>
        </Modal>
    );
}

function FlowsModal({ t, title, itemData, noScroll, idElem, rowData, dataFlows, parentContext, callback }) {

    const [flows, setFlows] = React.useState(dataFlows || []);

    var flowsItem = parentContext.systemItem["@@URL_FLOWS@@"];

    React.useEffect(() => {
        if (!flowsItem.argumentsObj.componentUrl) {
            initFlows();
        }
    }, []);

    function initFlows() {
        var currData = rowData;

        var args = {};
        if (flowsItem && flowsItem.argumentsObj && Object.keys(flowsItem.argumentsObj).length && flowsItem.argumentsObj.url) {
            Object.keys(flowsItem.argumentsObj).forEach((key) => {
                var valueArgs = flowsItem.argumentsObj[key];
                if (valueArgs.startsWith && valueArgs.startsWith("prop:")) args[key] = (parentContext.systemItem[commonFunc.camelize(valueArgs.replace("prop:", ""), true)] || {}).defaultValue;
                else args[key] = valueArgs;
            });

            var url = "../" + flowsItem.argumentsObj.url;
            commonFunc.getMethod(url, args).then((response) => {

                if (response.data) {
                    if (response.data.isSuccess) {

                        Swal.close();


                        setFlows(response.data.result);
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: t("gal_error"),
                            didOpen: () => { Swal.hideLoading() },
                            html: t(itemData.subType + "_errorGet") + " :<br>" + response.data.errorMessage
                        });
                    }
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: t("gal_error"),
                        didOpen: () => { Swal.hideLoading() },
                        html: t(itemData.subType + "_errorGet")
                    });
                }
            });
        }
        else if (parentContext.systemItem["@@URL_GET@@"] && idElem) {
            parentContext.getData(idElem).then((responseData) => {
                if (responseData.isSuccess) {
                    currData = responseData.result;

                    if (Array.isArray(currData)) {
                        setFlows(currData);
                    }
                    else {
                        setFlows(currData.flows);
                    }
                }
            });
        }
        else if (currData.flows) {
            if (Array.isArray(currData)) {
                setFlows(currData);
            }
            else {
                setFlows(currData.flows);
            }
        }
        else {
            if (!flowsItem) {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    html: t("gal_errorCfg")
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: t("gal_error"),
                    html: t("gal_invalidArgs")
                });
            }
        }
    }

    return (
        <Modal isOpen={true} className={`modal-flows ${noScroll ? "modal-noscroll" : ""}`} size="xxl" scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={{ overflowX: "hidden" }}>
                <Historical type="modelFlows" elemId={idElem} rows={flows} url={flowsItem.argumentsObj.componentUrl ? "../" + flowsItem.argumentsObj.componentUrl : ""} getRoutes={parentContext.modelFctManager.getRoutes} shouldRender={true}>
                    {flowsItem.children ? flowsItem.children.map((child) => {
                        return parentContext.modelFctManager.componentTyped(child, parentContext.parentProps);
                    }) : ""}
                </Historical>
            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>{t("gal_cancel")}</Button>
            </ModalFooter>
        </Modal>
    );
}