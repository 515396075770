import { Navigate } from 'react-router-dom';

const RouteGuard = ({ isLoggedIn, children }) => {
    console.debug("render RouteGuard " + isLoggedIn);
    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }
    return children;
};

export default RouteGuard;