import React, { useState } from 'react';
import { Row, Col, List, ListInlineItem, ButtonGroup, Button, Navbar, NavbarToggler, Collapse, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Input, Label } from 'reactstrap';
import * as commonFunc from 'scripts/common';
import moment from "moment";
import Swal from "sweetalert2";

import DPButton from "components/DPButton";
import CNavbar from 'components/CNavbar';

function ToolbarDate(props) {
    //{props.children}
    React.useEffect(() => {
        if (props.currentStyle) setCurrentStyle(props.currentStyle);
    }, [props.currentStyle]);

    const [currentDate, setCurrentDate] = useState(props.datepickerOpt ? props.datepickerOpt.initialValue : new Date());
    const [planStyleOpen, setPlanStyleOpen] = useState(false);
    const [planFilterOpen, setPlanFilterOpen] = useState(false);

    const [currentStyle, setCurrentStyle] = useState(props.currentStyle);

    const togglePlanStyle = () => setPlanStyleOpen(!planStyleOpen);
    const togglePlanFilter = () => setPlanFilterOpen(!planFilterOpen);

    var styles = [
        { bindid: "A", bindview: "agenda", filter: 0, label: "Agenda" },
        { bindid: "C", bindview: "month", filter: 0, label: "Calendrier", classSup: "d-none todo" },
        { bindid: "L", bindview: "list", filter: 0, label: "Liste", classSup:"showOnRes d-none2" },
        { bindid: "S", bindview: "tline", filter: 1, label: "Par Ressource" },
        { bindid: "G", bindview: "groupDayRes", label: "Regroupé Par Jour/Salarié", classSup: "d-none todo" },
        { bindid: "R", bindview: "groupResDay", label: "Regroupé Par Jour", classSup: "d-none todo" },
    ];

    var periods = [
        { bindid: "D", label: "Jour" },
        { bindid: "L", label: "Sem. 5J" },
        { bindid: "W", label: "Semaine" },
        { bindid: "M", label: "Mois" }
    ];


    var filters = [
        //{ bindid: "", label: "Par Employé/Interventions" },
        { bindid: "employee", label: "Par Employé" },
        { bindid: "client", label: "Par Client" },
        { bindid: "product", label: "Par Session" },
        //{ bindid: "material", label: "Par Matériel" }
    ];

    function getStyleLabel() {
        //console.debug("updateLabel " + props.resourceInfo);
        var filteredStyles = styles.filter(function (style) { return style.bindview === currentStyle.replace("stretch", ""); });
        //console.debug("updateLabel " + filteredStyles.length);
        if (filteredStyles.length) return props.resourceInfo + " - " + filteredStyles[0].label;
        return props.resourceInfo;
    }

    function addElement() {
        if (props.addCustomElement) {
            props.addCustomElement();
        }
        else {
            Swal.fire({
                icon: "warning",
                title: "Echec",
                html: "Ajout custom non paramètré"
            });
        }
    }


    return (
        <div id="ppnelHeader" className="dashboard-header text-center px-2">
            <Row id="planHeader" style={{ overflow: "visible" }} >
                <Col lg="4" sm="7" className="text-start px-0 px-sm-2">
                    <List type="inline">
                        <ListInlineItem>
                            <ButtonGroup>
                                <Button type="button" id="pplanSelPeriodPrevious" color="dark" className="btn-simple btn-icon" onClick={() => props.selPreviousPeriod()}><i className="fa fa-arrow-circle-left"></i></Button>
                                <DPButton
                                    className={""}
                                    showWeekNumbers={props.datepickerOpt && props.datepickerOpt.showWeeks}
                                    dateFormat="dd/MM/yyyy"
                                    timeFormat={false}
                                    locale="fr"
                                    selected={currentDate}
                                    onChange={(date) => { setCurrentDate(date); props.datepickerChange(moment(date)) }}
                                    btnProps={{ type: "button", id: "pplanSeldate", color: "dark", className: "btn-simple btn-icon m-0", children: <i className="fa fa-calendar"></i> }}
                                />
                                <Button type="button" id="pplanSelPeriodNext" color="dark" className="btn-simple btn-icon" onClick={() => props.selNextPeriod()}><i className="fa fa-arrow-circle-right"></i></Button>
                            </ButtonGroup>
                        </ListInlineItem>
                        <ListInlineItem>
                            <ButtonGroup>
                                {props.hideRefresh ? "" : <Button type="button" id="pplanSelPeriodRefresh" color="dark" className="btn-simple btn-icon btn-rotate" onClick={() => { props.refreshContent() }}><i className="fa fa-sync"></i></Button>}
                                {props.updateFilter ? <Dropdown isOpen={planFilterOpen} toggle={togglePlanFilter} className="ms-2">
                                    <DropdownToggle caret color="dark" className="btn-simple px-3"><span className="d-md-none"><i className="fa fa-gear"></i></span><span className="d-none d-md-inline-block">Catégories</span></DropdownToggle>
                                    <DropdownMenu>
                                        {filters.map((filter, index) => {
                                            return <DropdownItem className="d-inline-flex" key={index} data-bindid={filter.bindid}
                                                onClick={() => props.updateFilter(filter.bindid)}>{filter.label} {props.currentFilter === filter.bindid ? <i className="fa fa-circle-check float-end mt-1 ms-2"></i> : ""}</DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown> : ""}
                            </ButtonGroup>
                        </ListInlineItem>
                        <ListInlineItem className="hidden">
                            <ButtonGroup>
                                <Button type="button" id="pplanSelPeriodNow" color="dark" className="btn-simple px-3" onClick={() => { setCurrentDate(new Date()); props.datepickerChange(moment().utc()); }}><i className="fa fa-calendar"></i>&nbsp;Aujourd'hui</Button>
                            </ButtonGroup>
                        </ListInlineItem>
                        <ListInlineItem>
                            <ButtonGroup>
                                {props.customAddBtn ? props.customAddBtn : <Button type="button" id="pplanSelAdd" color="success" className="px-3" onClick={() => addElement()}><i className="fa fa-calendar-plus"></i><span className="d-none d-md-inline-block">&nbsp;Nouveau</span></Button>}
                                {props.printModule ? <Button type="button" id="pplanSelPrint" color="dark" className="btn-simple btn-icon" title="Imprimer" onClick={() => props.printModule()}><i className="fa fa-print"></i></Button> : ""}
                            </ButtonGroup>
                        </ListInlineItem>
                    </List>
                </Col>

                <Col lg="4" sm="5" className="text-center px-0 px-sm-2">
                    <List type="inline">
                        <ListInlineItem>
                            <h3 id="pplanSelPeriod" className="m-0 p-0">{props.customTitle ? props.customTitle : "Planning"}</h3>
                            {props.backToAllRes ? <Button id="pplanSelResBack" color="success" size="sm" className={"showOnRes rounded-circle px-2 " + (commonFunc.isBlank(props.resourceInfo) ? "d-none" : "")} title="Retour à la liste globale"
                                onClick={() => props.backToAllRes(0)}><i className="fa fa-list"></i></Button> : ""}
                            <h4 id="pplanSelDispRes" className="d-none">{currentStyle ? getStyleLabel() : ""}</h4>
                        </ListInlineItem>
                    </List>
                </Col>

                <Col lg="4" className="text-end px-0 px-sm-2">
                    <CNavbar togglerChilds={<i className="fa fa-filter"></i>}>
                        <Nav className="ms-auto d-block" navbar>
                            {props.updatePeriod ? <NavItem className="d-inline-block">
                                <ButtonGroup>
                                    {periods.map((period, index) => {
                                        var className = "px-3 text-nowrap ";
                                        var color = "dark";
                                        if (period.bindid === props.currentPeriod) {
                                            color = "info";
                                            className += "btn-selected";
                                        }
                                        else {
                                            className += "btn-simple";
                                        }
                                        return <Button key={index} type="button" color={color} className={className} data-bindid={period.bindid} onClick={() => { props.updatePeriod(period.bindid); }}>{period.label}</Button>
                                    })}
                                </ButtonGroup>
                            </NavItem> : ""}
                            {props.updateStyle ? <NavItem className="d-inline-block">
                                <Dropdown isOpen={planStyleOpen} toggle={togglePlanStyle} className="ms-2">
                                    <DropdownToggle caret color="dark" className="btn-simple px-3">Style</DropdownToggle>
                                    <DropdownMenu>
                                        {styles.map((style, index) => {
                                            var className = style.classSup;
                                            if (!commonFunc.isBlank(className) && className.indexOf("showOnRes") >= 0 && !commonFunc.isBlank(props.resourceInfo)) className = className.replace("d-none", "");
                                            //className += " d-inline-flex";
                                            return <DropdownItem key={index} className={className} data-filter={style.filter} data-bindview={style.bindview} data-bindid={style.bindid}
                                                onClick={() => props.updateStyle(style.bindview)}>{style.label} {(currentStyle && currentStyle.replace("stretch", "") === style.bindview) ? <i className="fa fa-circle-check float-end mt-1 ms-1"></i> : ""}</DropdownItem>
                                        })}
                                    </DropdownMenu>

                                </Dropdown>
                            </NavItem> : ""}
                            {props.statusContainer ? <NavItem className="d-inline-block">
                                {props.statusContainer}
                            </NavItem> : ""}
                            <NavItem className="d-inline-block">
                                <Form className="d-flex">
                                    {props.setSelAll ? <FormGroup check className="fcheck-sm ms-1">
                                        <Label check title="Tout Afficher">
                                            <Input type="checkbox" checked={props.selAll} onChange={() => props.setSelAll(!props.selAll)} />
                                            <span className="form-check-sign" />
                                            <i className="fa fa-users" aria-hidden="true"></i>
                                        </Label>
                                    </FormGroup> : ""}
                                    {props.updateDispSynt ? <FormGroup check className="fcheck-sm ms-1">
                                        <Label check title="Vue condensée">
                                            <Input type="checkbox" checked={props.dispSynt} onChange={() => props.updateDispSynt(!props.dispSynt)} />
                                            <span className="form-check-sign" />
                                            <i className="fa fa-compress" aria-hidden="true"></i>
                                        </Label>
                                    </FormGroup> : ""}
                                </Form>
                                <label title="Actualisation Auto" className="ms-1 hidden"> <i className="fa fa-refresh me-1" aria-hidden="true"></i><input type="checkbox" id="planSelDispRfrsh" disabled="disabled" className="disabled" /></label>
                            </NavItem>
                        </Nav>
                    </CNavbar>
                </Col>
            </Row>
        </div>
    );
}

export default ToolbarDate;

