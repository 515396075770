import React from 'react';
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import * as commonFunc from 'scripts/common';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {
    Input,
    Label,
    FormGroup, FormFeedback,
    InputGroup, InputGroupText, Col
} from "reactstrap";

export default function SpinnerFrag({ data, formManager, modelFctManager, bindPropResult, parentDisabled, parentProps }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors, control } = formManager;

    const [options, setOptions] = React.useState(data.options || []);
    const [currValue, setCurrValue] = React.useState(loadValue());
    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        if (parentProps && parentProps.value >= 0) {
            var newValue = parentProps.value ? (currValue || 0) : 0;
            //setCurrValue(newValue);
            setValue(bindPropResult, newValue);
        }
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data).then(actionResponse => {
                    //console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }, []);

    React.useEffect(() => {
        if (parentProps && parentProps.value >= 0) {
            var newValue = parentProps.value ? (currValue || 0) : 0;
            setValue(bindPropResult, newValue);
            setShouldRender(!shouldRender);
        }
    }, [parentProps.value]);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    const { ref: refElem, ...restElem } = register(bindPropResult, {
        value: data.defaultValue || ((data.options && data.options.length) ? data.options[0].value : ""),
        valueAsNumber: (data.subType === "integer" || data.subType === "float"),
        onChange: (e) => {
            var selValue = e.target.value;
            /*if (data.subType === "integer" || data.subType === "float") {
                selValue = parseInt(selValue);
            }*/
            onChange(selValue);
        }
    });

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.setValue = (value, withRerender, src) => {
        return new Promise((resolve, reject) => {
            setValue(bindPropResult, value);
            setCurrValue(value);
            if (withRerender) setShouldRender(!shouldRender);

            resolve({ isSuccess: true, result: value });
        });
    };
    data.functionManager.getValue = (src) => {
        //return currValue;
        return currValue || loadValue();
    };
    data.functionManager.getSelectedOption = (src) => {
        if (options) {
            var optionsFilter = options.filter((option) => option.value == (src || currValue || loadValue()));
            console.debug(optionsFilter);
            if (optionsFilter.length) {
                return optionsFilter[0];
            }
        }
        return null;
    };
    data.functionManager.getSrcData = (src) => {
        if (options) {
            var optionsFilter = options.filter((option) => option.value == (src || currValue || loadValue()));
            console.debug(optionsFilter);
            if (optionsFilter.length) {
                return optionsFilter[0].srcData;
            }
        }
        return null;
    };
    data.functionManager.triggerChange = (alternativeValue) => {
        return new Promise((resolve, reject) => {
            onChange(alternativeValue || currValue);

            resolve({ isSuccess: true, result: (alternativeValue || currValue) });
        });
    };
    data.functionManager.populateData = populateData;

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));

    function onChange(value, newOptions) {
        console.debug("onChange");
        setCurrValue(value);

        var localOptions = ((newOptions && newOptions.length) ? newOptions : options);
        var srcData;
        if (options) {
            var optionsFilter = localOptions.filter((option) => option.value == value);
            if (optionsFilter.length) {
                srcData = optionsFilter[0].srcData;
            }
        }

        if (data.onActionEvents && data.onActionEvents["onChange"]) {
            data.onActionEvents["onChange"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data, { value: value, srcData: srcData, withRerender: true }).then(actionResponse => {
                    console.debug("RESPONSE");
                    console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }

    function populateData(dataOptions, triggerOnChange) {
        return new Promise((resolve, reject) => {
            //console.debug("spinnerFrag populateOptions " + bindPropResult);
            //data.options = dataOptions;
            var defaultOptions = data.options ? data.options.filter((opt) => opt.subType === "default") : [];
            if (dataOptions && dataOptions.length) {
                var newOptions = [...defaultOptions, ...dataOptions];
                setOptions(newOptions);

                //Vérification de la présence de la valeur actuelle dans la nouvelle liste (si non, va chercher defaultValue OU option.selected)
                var selectValue = parseInt(currValue) ? parseInt(currValue) : currValue;
                var foundedOption = dataOptions.filter((elem) => elem.value === selectValue && elem.value != -1);

                var newValue = loadValue(newOptions, foundedOption.length ? selectValue : null);
                if (triggerOnChange) onChange(newValue, newOptions);
                else setCurrValue(newValue);
                if (parentProps && parentProps.value >= 0) {
                    newValue = parentProps.value ? newValue : 0;
                }
                setValue(bindPropResult, newValue);

                resolve({ isSuccess: true, result: newValue });
            }
            else {
                setOptions(defaultOptions);

                var componentVal = bindPropResult ? getValues(bindPropResult) : "";

                if (triggerOnChange) onChange(componentVal);

                resolve({ isSuccess: true, result: componentVal });
            }
        });
    }

    function loadValue(newOptions, cptValue) {
        //console.debug(cptValue);
        if (!newOptions) newOptions = options;
        //var value = !commonFunc.isBlank(data.value) ? data.value : data.defaultValue;
        var value = !commonFunc.isBlank(cptValue) ? cptValue : data.defaultValue;
        var dOption = newOptions.filter((elem) => elem.value === value);
        var loadedValue = -1;
        if ((!commonFunc.isBlank(value) && (data.subType && (data.subType.startsWith("multiple") || data.subType.endsWith("create")))) || newOptions.length <= 0 || dOption.length > 0) {
            loadedValue = value;
        }
        else {
            if (!data.subType || !data.subType.startsWith("multiple")) {
                var selectedOption = newOptions.filter((elem) => elem.selected);
                if (selectedOption.length > 0) {
                    loadedValue = selectedOption[0].value;
                }
                else {
                    loadedValue = newOptions[0].value;
                }
            }
            else {
                loadedValue = "";
            }
        }
        //console.debug(loadedValue);

        return loadedValue;
    }

    function renderInput(widthClass) {
        //Gestion select multiple
        if (data.subType && (data.subType.startsWith("multiple") || data.subType.endsWith("create"))) {
            var customOpt = [];
            options.forEach((option, index) => {
                customOpt.push({ value: option.value, label: option.title });
            });

            var compArgs = {
                isMulti: data.subType.startsWith("multiple"),
                id:"select" + bindPropResult,
                className:`${widthClass}`,
                isDisabled: disabled, /*innerRef={refElem} {...restElem}*/
                formatOptionLabel: (option, { context }) => {
                    return option.label;
                },
                onChange: (e) => {
                    if (Array.isArray(e)) {
                        var values
                        if (data.propNameJsType === "array") {
                            values = e.map(v => v.value);
                        }
                        else {
                            values = e.map(v => v.value).join(";");
                        }
                        onChange(values);
                        setValue(bindPropResult, values);
                    }
                    else {
                        onChange(e.value);
                        setValue(bindPropResult, e.value);
                    }
                },
                invalid:commonFunc.getValue(errors, bindPropResult) ? true : false,
                value: (() => {
                    var arrayOptions;
                    if (Array.isArray(currValue)) {
                        arrayOptions = currValue;
                    }
                    else if (currValue && currValue.split) {
                        arrayOptions = currValue.split(";");
                    }
                    console.debug(currValue);
                    console.debug(arrayOptions);
                    var option;
                    if (arrayOptions) {
                        option = arrayOptions.map(v => {
                            if (!commonFunc.isBlank(v)) {
                                var value = parseInt(v) ? parseInt(v) : v;
                                var filterOption = options.filter(opt => opt.value === value);
                                var label = filterOption.length > 0 ? filterOption[0].title : v;
                                return { label: label, value: value };
                            }
                            else {
                                return "";
                            }
                        });
                    }
                    else {
                        option = (currValue ? currValue : "");
                    }
                    return option;
                })(),
                options:customOpt
            };

            //Gestion création option
            if (data.subType.endsWith("create")) {
                return <CreatableSelect {...compArgs} />;
            }
            else {
                return <Select {...compArgs} />;
            }
        }
        else {
            return <Input id={"select" + bindPropResult} className={`${widthClass}`} type="select" disabled={disabled} innerRef={refElem} {...restElem} invalid={commonFunc.getValue(errors, bindPropResult) ? true : false} value={currValue}>
                {(() => {
                    var optionsGrp = {};
                    options.forEach((option, index) => {
                        if (option.objType) {
                            if (!optionsGrp[option.objType]) optionsGrp[option.objType] = [];
                            optionsGrp[option.objType].push(option);
                        }
                        else {
                            if (!optionsGrp["none"]) optionsGrp["none"] = [];
                            optionsGrp["none"].push(option);
                        }
                    });

                    var optionRendering = (option, index) => {
                        return <option className={`${option.hidden ? "d-none" : ""}`} key={index} selected={option.selected} value={option.value}>
                            {option.customDesign && option.customDesign.design && option.customDesign.design.icon ? <i className={`fa ${option.customDesign.design.icon}`}></i> : ""}
                            {option.title}
                        </option>
                    };

                    return Object.keys(optionsGrp).map((key, indexGrp) => {
                        var values = optionsGrp[key];
                        if (key === "none") {
                            return values.map(optionRendering);
                        }
                        else {
                            return <optgroup key={indexGrp} label={key}>
                                {values.map(optionRendering)}
                            </optgroup>
                        }
                    });
                })()}
            </Input>;
        }
    }

    function renderTitle(positions, titleWidthClass) {
        var design = data.customDesign ? data.customDesign.designTitle : null;
        if (!design) design = {}
        if (!design.position) design.position = "left";

        var titleModel = <Label className={`${titleWidthClass} ${(design.position === "left" || design.position === "right") ? "m-0" : ""} ${(!commonFunc.isBlank(data.title) && data.required) ? "mandatory" : ""}`} for={"input" + bindPropResult}>{data.title}</Label>;

        if (!commonFunc.isBlank(data.title) && positions.indexOf(design.position) >= 0) return titleModel;
        else return "";
    }

    var frameClass = "d-inline-block mb-3";
    var frameStyle = {};
    var containerClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    var formClass = "";

    var widthClass = "";
    var titleWidthClass = "px-0 ";
    if (data.customDesign) {
        if (data.customDesign.design) {
            if (data.customDesign.design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.design.boxSize) * 100);
            }
            if (data.customDesign.design.margin) {
                frameStyle.margin = data.customDesign.design.margin;
                frameClass = frameClass.replace("mb-3", "");
            }
        }
        if (data.customDesign.designInput) {
            if (data.customDesign.designInput.boxSize) {
                //widthClass += " w-" + parseInt(parseFloat(data.customDesign.designInput.boxSize) * 100);
                widthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designInput.boxSize) * 100);
            }
        }
        if (data.customDesign.designTitle) {
            if (data.customDesign.designTitle.alignment) {
                var split = data.customDesign.designTitle.alignment.split("|");
                split.forEach((part) => {
                    titleWidthClass += " text-" + part.replace("left", "start").replace("right", "end");
                    titleWidthClass = titleWidthClass.replace("text-top", "align-self-start").replace("text-bottom", "align-self-end");
                });
            }
            if (data.customDesign.designTitle.position === "floating") {
                formClass += " form-floating min-input";
            }
            if (data.customDesign.designTitle.boxSize) {
                //titleWidthClass += " w-" + parseInt(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass = titleWidthClass.replace("px-0", "ps-3 px-md-0").replace("text-end", "text-md-end");
            }
        }

        if (!commonFunc.isBlank(widthClass) || titleWidthClass !== "px-0 ") containerClass += " row m-0 row-cols-lg-auto2 align-items-center";

        if (!data.customDesign.designTitle) {
            widthClass += " w-100";
        }
    }



    var baseForm = <div className={`${containerClass} ${formClass}`}>
        {renderTitle(["top", "left"], titleWidthClass)}
        <div className={`${widthClass}`}>
            {renderInput()}
            <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : ""}</FormFeedback>
        </div>
        {renderTitle(["bottom", "right", "floating"], titleWidthClass)}
    </div>;

    return <Col data-name="SpinnerFrag" data-element={data.objType} xs="auto" className={` ${frameClass} ${parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`} style={frameStyle}>{baseForm}</Col>;//align-self-center
}