import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    FormFeedback
} from "reactstrap";

import Swal from "sweetalert2";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.fr";

import * as commonFunc from 'scripts/common';

function RegisterStepUser(props) {
    const { t } = useTranslation();

    //Création du schéma pour le paramètrage du formulaire
    //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    //const phoneRegExp = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/u;
    const schema = yup.object().shape({
        name: yup.string().required(t("gal_reqFields")).min(2, t("gal_minChar", [2])).max(50, t("gal_maxChar", [50])),
        firstName: yup.string().required(t("gal_reqFields")).min(2, t("gal_minChar", [2])).max(50, t("gal_maxChar", [50])),
        //phone: yup.string().matches(phoneRegExp, t("gal_invalidPhone")).required(t("gal_reqFields")).min(2, t("gal_minChar", [2])).max(14, t("gal_maxChar", [14])),
        //email: yup.string().required(t("gal_reqFields")).email(t("gal_invalidEmail")).min(2, t("gal_minChar", [2])).max(50, t("gal_maxChar", [50])),
        country: yup.string().test('is-country',
            //'${path} is not France',
            t("gal_invalidCountry"),
            (value, context) => value === 'France',)
    }).required();

    React.useEffect(() => {
        if (props.triggerAnimate) props.triggerAnimate();
    }, [props.shouldRender]);

    //Paramètrage du formulaire
    const { control, register, getValues, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });


    function redirectExternalLogin(provider) {
        getCurrentBrowserFingerPrint().then((fingerprint) => {
            window.location.assign('../ExternalLogin/ExternalLogin?provider=' + provider + '&deviceId=' + fingerprint);
        });
    }

    async function triggerSubmit(data) {
        /*Swal.fire({
            title: t("log_checkInfos"),
            didOpen: () => { Swal.showLoading() }
        });*/

        //Check?

        if (props.updateStep) props.updateStep(data);
    }

    const [triggerUpdate, setTriggerUpdate] = React.useState(false);
    const [rSelected, setRSelected] = React.useState("M");

    const { ref: refName, ...restName } = register("name");
    const { ref: refFirstName, ...restFirstName } = register("firstName");
    //const { ref: refPhone, ...restPhone } = register("phone");
    //const { ref: refEmail, ...restEmail } = register("email");
    const { ref: refCountry, ...restCountry } = register("country", {
        //Mise en place pour refresh en live le passwordStrength
        onChange: (e) => { setTriggerUpdate(!triggerUpdate) },
    });

    return (
        <Form action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerSubmit)}>
            {/*<CardHeader>
                <CardTitle className='header my-0 py-0' tag="h5">{t("register_userInfos")}</CardTitle>
                <div className="social">
                    <Button title={t("register_authGoogle")} className="btn-icon btn-round" color="google" type="button" onClick={() => redirectExternalLogin("Google")}>
                        <i className="fa-brands fa-google" />
                    </Button>
                    <Button title={t("register_authMicrosoft")} className="btn-icon btn-round " color="microsoft" type="button" onClick={() => redirectExternalLogin("Microsoft")}>
                        <i className="fa-brands fa-microsoft" />
                    </Button>
                    <Button title={t("register_authFacebook")} className="btn-icon btn-round hidden" color="facebook" type="button" onClick={() => redirectExternalLogin("Facebook")}>
                        <i className="fa-brands fa-facebook-f" />
                    </Button>
                    <p className="card-description mb-0">{t("register_or")}</p>
                </div>
            </CardHeader>
            */}
            <CardHeader className="bg-white">
                <CardTitle className='header my-0 py-0' tag="h5">{t("register_companyInfos")}</CardTitle>
            </CardHeader>
            <CardBody>
                {/*
                <ButtonGroup className="float-start mb-2">
                    <Button
                        color="secondary"
                        size="sm"
                        outline
                        onClick={() => setRSelected("M")}
                        active={rSelected === "M"}>{t("register_titleMister")}</Button>
                    <Button
                        color="secondary"
                        size="sm"
                        outline
                        onClick={() => setRSelected("Mme")}
                        active={rSelected === "Mme"}>{t("register_titleMiss")}</Button>
                    <Button
                        color="secondary"
                        size="sm"
                        outline
                        onClick={() => setRSelected("Autre")}
                        active={rSelected === "Autre"}>{t("register_titleOther")}</Button>
                </ButtonGroup>
                */}
                <div className="text-start pb-2">
                    <Label className="mandatory" for="inputname">{t("register_name")}</Label>
                    <Controller
                        control={control}
                        name='name'
                        render={({ field: { ref, ...field } }) => (
                            <Cleave id="inputname" className={`form-control floating-size ${errors.name ? "is-invalid" : ""}`} placeholder={t("register_name")} autoComplete="no" aria-autocomplete="none"
                                options={{ delimiter: "@#/*", uppercase: true }}
                                value={field.value}
                                htmlRef={ref} {...field} />
                        )}
                    />
                    <FormFeedback className={`text-start ${errors.name ? "d-block" : ""}`}>{errors.name ? errors.name.message : t("gal_reqFields")}</FormFeedback>
                </div>
                <div className="text-start pb-2">
                    <Label className="mandatory" for="inputfirstName">{t("register_firstname")}</Label>
                    <Input id="inputfirstName" className="floating-size" type="text" autoComplete="no" aria-autocomplete="none" placeholder={t("register_firstname")} innerRef={refFirstName} {...restFirstName} invalid={errors.firstName ? true : false} />
                    <FormFeedback className={`text-start ${errors.firstName ? "d-block" : ""}`}>{errors.firstName ? errors.firstName.message : t("gal_reqFields")}</FormFeedback>
                </div>

                <div className="text-start pb-2">
                    <Label className="mandatory" for="inputcountry">{t("register_country")}</Label>
                    <Input id="inputcountry" className="w-100 mb-2 floating-size" name="inputcountry" type="select" innerRef={refCountry} {...restCountry} invalid={errors.country ? true : false} defaultValue={"France"}>
                        <option value="France">France</option>
                        <option value="Autre">Autre</option>
                    </Input>
                    <FormFeedback className={`text-start ${errors.country ? "d-block" : ""}`}>{errors.country ? errors.country.message : t("gal_reqFields")}</FormFeedback>
                </div>
                {/*<InputGroup className="single-input">
                    <InputGroupText className="mandatory">
                        <i className="fa fa-mobile-screen" />
                    </InputGroupText>
                    <div className="form-floating min-input">
                        <Controller
                            control={control}
                            name='phone'
                            render={({ field: { ref, ...field } }) => (
                                <Cleave id="inputphone" className={`form-control ${errors.phone ? "is-invalid" : ""}`} autoComplete="no" aria-autocomplete="none" placeholder={t("register_phone")}
                                    options={{ phone: true, phoneRegionCode: (getValues("country") === "France" ? "FR" : "AU") }}
                                    value={field.value}
                                    htmlRef={ref} {...field} />
                            )}
                        />
                        <Label for="inputphone">{t("register_phone")}</Label>
                    </div>
                    <FormFeedback className={`text-start ${errors.phone ? "d-block" : ""}`}>{errors.phone ? errors.phone.message : t("gal_reqFields")}</FormFeedback>
                </InputGroup>
               */}
            </CardBody>
            <CardFooter className="text-end mt-0">
                {props.prevStep ? <Button title={t("register_previous")} className="btn-icon rounded-circle bg-altLink-color btn btn-primary me-2" color="info" onClick={(e) => { e.preventDefault(); props.prevStep() }}><i className="fa fa-arrow-left" style={{ fontSize: "1.6em" }}></i></Button> : ""}
                <Button title={t("register_next")} className="btn-icon rounded-circle bg-altLink-color btn btn-primary" color="info"><i className="fa fa-arrow-right" style={{ fontSize: "1.6em" }}></i></Button>
            </CardFooter>
        </Form>
    );
}

export default RegisterStepUser;
