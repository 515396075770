import React, { Component } from 'react';
import axios from "axios";
import { Route, Routes, Navigate } from 'react-router-dom';
import { appRoutes, keysToComponentMap } from './AppRoutes';
import Layout from './components/layout/Layout';
import RouteGuard from './components/RouteGuard';
import 'assets/css/common.css';
import 'assets/css/animate-4.1.1.min.css';
import 'assets/css/material_design_colors_full.css';

//TEMP : client
import 'assets/css/default-theme.css';

import Swal from "sweetalert2";
import moment from "moment";
import 'moment/locale/fr';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import avatar from "assets/img/faces/default_profile.png";

import * as commonFunc from 'scripts/common';
export default class App extends Component {
    static displayName = App.name;

    dataCfg;

    constructor(props) {
        super(props);

        Swal.close();

        //Initialisation Axios
        const headers = {
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${TOKEN}`
            withCredentials: true
        };
        axios.defaults.headers = headers;

        var token = localStorage.getItem('token');
        if (token != null) {
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
        }

        var context = this;
        //Intercepteur d'erreur
        axios.interceptors.response.use((response) => response, (error) => {
            if (error.response) {
                console.debug(error.response.request.responseURL);
                if (error.response.status === 401) {
                    if (!error.response.request.responseURL.endsWith("logout") && context.state.isLoggedIn) {
                        console.debug("LogOut Interceptor");
                        Swal.close();
                        context.logOut();
                    }
                    else {
                        console.debug("Logout/Déco");
                        if (context.state.isLoggedIn) {
                            context.setState({ mergedRoutes: appRoutes, isLoggedIn: false, user: {}, defaultPath: "/" });
                            context.forceUpdate();
                        }
                        else {
                            context.forceUpdate();
                        }
                    }
                }
                else if (error.response.status === 400) {
                    //error.message = "Echec d'identification : Accès réfusé";
                }
                /*else if (error.response.status === 405) {
                    Swal.fire({
                        icon: "error",
                        title: "Accès refusé"
                    });
                    throw error;
                }*/
                else {
                    throw error;
                }
            }
            else {
                throw error;
            }
        });

        i18n.use(initReactI18next).init({
            resources: {},
            lng: "fr",
            fallbackLng: ['fr', 'en'],

            interpolation: {
                escapeValue: false
            }
        });

        this.scrollerRef = React.createRef(null);

        //Récup Css File ?? (constructor OU getDerivedStateFromProps OU componentDidMount)
        //var domain_name = document.location.hostname;
        //loadCssFile("../Content/" + domain_name + ".css");

        this.state = { mergedRoutes: null, isLoggedIn: App.hasJWT(), user: {}, site: null, defaultPath: "/" };//TEMP PB LOGIN
    }

    componentDidMount() {
        this.initApp();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.isLoggedIn && prevState.isLoggedIn !== this.state.isLoggedIn) {
            this.initApp();
        }
    }

    async initApp() {
        console.debug("TRY initApp");
        this.setState({ mergedRoutes: null });

        var context = this;

        var i18nProcess = function (dataModels) {
            var i18nRes = {
                "fr": {
                    "translation": {
                        "gal_error": "Erreur",
                        "gal_warning": "Attention",
                        "gal_reqFields": "Ce champ est obligatoire",
                        "gal_reqSameFields": "Le champ doit correspondre au champ précédent",
                        "gal_maxChar": "Ce champ ne doit pas dépasser {{0}} caractères.",
                        "gal_minChar": "Ce champ nécessite au moins {{0}} caractères.",
                        "gal_invalidEmail": "Adresse email invalide",
                        "gal_invalidCountry": "Ce pays n'est pas encore éligible à notre offre pour le moment",
                        "gal_invalidPhone": "Numéro de téléphone invalide",
                        "gal_acceptGtc": "J'accepte les",
                        "gal_acceptGts": "J'ai lu et j'accepte les",
                        "gal_termsConditions": "Conditions Générales",
                        "gal_salesConditions": "Conditions Générales de Vente",
                        "log_checkInfos": "Vérification des informations...",
                        "login_accountFailed": "Vous n'êtes pas encore inscrit avec ce compte, veuillez passer par la page d'inscription",
                        "register_checkOffers": "Voir les autres offres",
                        "register_or": "OU",
                        "register_pwd": "Mot de passe",
                        "register_reg": "Inscription",
                        "register_name": "Nom",
                        "register_next": "Suivant",
                        "register_email": "Email",
                        "register_phone": "Téléphone",
                        "register_text1": "Développez votre métier avec la formule qui vous convient",
                        "register_text2": "We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub.",
                        "register_text3": "There is also a Fully Customizable CMS Admin Dashboard for this product.",
                        "register_title1": "Marketing",
                        "register_title2": "Fully Coded in HTML5",
                        "register_title3": "Built Audience",
                        "register_cardCVC": "CVC",
                        "register_cardExp": "Expire Fin",
                        "register_confPwd": "Confirmer mot de passe",
                        "register_country": "Pays",
                        "register_cardName": "Nom du titulaire",
                        "register_payInfos": "Informations paiement",
                        "register_previous": "Précédent",
                        "register_siretNum": "Numéro siret",
                        "register_companyPc": "Code postal",
                        "register_firstname": "Prénom",
                        "register_legalForm": "Forme Juridique",
                        "register_reqFields": "Champs obligatoires",
                        "register_titleMiss": "Mme",
                        "register_userInfos": "Informations personnelles",
                        "register_authGoogle": "Identification via votre compte google",
                        "register_cardNumber": "Numéro de carte",
                        "register_loginInfos": "Créer votre Mot de Passe",
                        "register_reqCardExp": "Expiration invalide. Veuillez saisir un mois et une année valide",
                        "register_titleOther": "Autre",
                        "register_accessLogin": "Déjà un compte ? Se connecter !",
                        "register_companyCity": "Ville",
                        "register_companyName": "Nom de l'entreprise",
                        "register_registerBtn": "S'inscrire",
                        "register_titleMister": "M",
                        "register_authFacebook": "Identification via votre compte facebook",
                        "register_companyInfos": "Informations personnelles",
                        "register_authMicrosoft": "Identification via votre compte microsoft",
                        "register_companyAddress": "Adresse entreprise",
                        "register_errorPwdStrength": "Votre mot de passe doit contenir :<br/>&bull; 6 caractères minimum<br/>&bull; 1 minuscule<br/>&bull; 1 majuscule<br/>&bull; 1 chiffre<br/>&bull; 1 caractère spécial"
                    }
                },
                "en": {
                    "translation": {

                    }
                }
            };

            var configI18 = null;
            if (dataModels && dataModels.length) {
                var resFilter = dataModels.filter((model) => model.extID === "I18");
                if (resFilter && resFilter.length) {
                    configI18 = resFilter[0];
                }
            }
            if (configI18) {
                var i18nConfigRes = JSON.parse(configI18.value);
                i18nRes = Object.assign(i18nRes, i18nConfigRes);
            }

            i18n.use(initReactI18next).init({
                // the translations
                // (tip move them in a JSON file and import them,
                // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
                resources: i18nRes,
                lng: "fr", // if you're using a language detector, do not define the lng option
                fallbackLng: ['fr', 'en'],

                interpolation: {
                    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
                }
            }, function () {
                if (!context.state.isLoggedIn) context.setState({ mergedRoutes: appRoutes });
            });
        };


        if (context.state.isLoggedIn) {
            i18nProcess();
            context.getConfigs().then((respObj) => {
                i18nProcess(respObj.models);
            });
        }
        else {
            i18nProcess();
        }
    }

    render() {
        document.body.classList.remove("planning-mode");

        console.debug("render App " + this.state.isLoggedIn);

        console.debug(this.state.mergedRoutes);

        if (!this.state.mergedRoutes) { return null; }
        else {
            return (
                <Layout ref={this.scrollerRef} routes={this.state.mergedRoutes} isLoggedIn={this.state.isLoggedIn} logOut={this.logOut.bind(this)} user={this.state.user} checkRights={this.checkRights.bind(this)} alternativeDisplay={this.alternativeDisplay}>
                    <Routes>
                        {this.getRoutes(this.state.mergedRoutes)}
                    </Routes>
                </Layout>
            );
        }
    }

    //----Custom Functions----
    setLoggedStatus(value) {
        console.debug("change loginValue " + value);
        this.setState({ isLoggedIn: value });
    }

    convertRoutes(routes) {
        var routeIndex = routes.filter((route) => route.index);
        routes.forEach(route => {
            if (route.collapse) {
                var subRouteIndex = this.convertRoutes(route.views);
                if (subRouteIndex) routeIndex = [subRouteIndex];
            }
            if (route.keyComponent) {
                route.component = keysToComponentMap[route.keyComponent];
            }
        });
        return routeIndex[0];
    }
    convertContentRoutes(models, routesParent) {

        var contentRoutes = [];
        models.forEach(model => {
            var binds = model.value.split("-");
            if (binds.length === 3 || (binds.length === 4 && parseInt(binds[3]) === 1)) {
                var customRoute = { path: "/showContent?ID=" + model.id + "&bindID=" + model.value, name: model.tag, mini: model.tag.substring(0, 1), component: keysToComponentMap["showContent"], layout: "" };


                var parent = routesParent.filter(function (e) { return e.tag === binds[1]; });
                if (parent.length) {
                    parent[0].views.push(customRoute);
                    parent[0].redirect = false;
                }
                else {
                    delete customRoute.mini;
                    customRoute.icon = "";
                    contentRoutes.push(customRoute);
                }
            }
        });
        return contentRoutes;
    }

    getRoutes(routes) {
        var getScroller = this.scrollerRef.current ? this.scrollerRef.current.getScroller : null;

        console.debug("update Routes " + this.state.isLoggedIn);
        return routes.map((route, index) => {
            //const { component, ...rest } = route;

            if (route.collapse) {
                return this.getRoutes(route.views);
            }
            if (route.login || (route.all && !this.state.isLoggedIn)) {
                //return <Route key={index} {...rest} element={(isLoggedIn ? <Navigate to="/" /> : component)} />;
                const authElem = React.cloneElement(route.component, { setLoggedStatus: this.setLoggedStatus.bind(this) });
                return (<Route key={index} path={route.path} index={route.index} element={(this.state.isLoggedIn ? <Navigate to="/" /> : authElem)} />);
            }
            else {
                if (this.checkRights(route.entity, route.rights)) {
                    const propsElem = route.component ? React.cloneElement(route.component, { getLoggedInInfo: () => this.state.isLoggedIn, getUser: () => this.state.user, getSite: () => this.state.site, updateUser: this.updateUser.bind(this), checkRights: this.checkRights.bind(this), getRoutes: () => this.state.mergedRoutes, getScroller: getScroller, dataCfg: this.dataCfg }) : <h5>Page introuvable</h5>;
                    if (this.state.isLoggedIn && window.location.pathname === "/" && (!route.path || route.path === "/")) {
                        return (<Route key={index} path={route.path} index={route.index} element={<Navigate to={this.state.defaultPath} />} />);
                    }
                    else {
                        return (<Route key={index} path={route.path} index={route.index} element={<RouteGuard isLoggedIn={this.state.isLoggedIn}>{propsElem}</RouteGuard>} />);
                    }
                }
                else {
                    return "";
                }
            }
        });
    }

    getConfigs() {
        var context = this;

        return new Promise((resolve, reject) => {
            commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: "CONFIG" /*, extId:"MNU"*/}).then((response) => {
                var mergedRoutes = appRoutes;
                if (response && response.data.isSuccess) {
                    var configMNU = null;
                    if (response.data.result.models && response.data.result.models.length) {
                        var resFilter = response.data.result.models.filter((model) => model.extID === "MNU");
                        if (resFilter && resFilter.length) {
                            configMNU = resFilter[0];
                        }
                    }
                    if (!configMNU) {
                        if (!(response.data.result.models && response.data.result.models.length)) context.logOut();
                        return; //TODO check bancal, plantage si pas de menu
                    }

                    var routesConfig = JSON.parse(configMNU.value) || [];
                    var routeIndex = context.convertRoutes(routesConfig);

                    //Récupération du user pour récupération de la page par défaut + vérification affichage des Pages ShowContent
                    context.updateUser(async function (user) {

                        commonFunc.getMethod("../api/site/GetSite", { id: user ? user.groupSId : -1 }).then((responseSite) => {
                            var indexTarget = user.rights ? user.rights.flags.filter((flag) => flag.active && flag.extId > 0) : [];
                            if (indexTarget.length) {
                                var targetRoute = routesConfig.filter((route) => {
                                    var flag = route.entity === indexTarget[0].entity && indexTarget[0].value.indexOf(route.rights) >= 0;
                                    if (!flag && route.views && route.views.length) {
                                        var subRoute = route.views.filter((subRoute) => subRoute.entity === indexTarget[0].entity && indexTarget[0].value.indexOf(subRoute.rights) >= 0);
                                        if (subRoute.length) {
                                            routeIndex = subRoute[0];
                                        }
                                    }
                                    return flag;
                                });
                                if (targetRoute.length) {
                                    routeIndex = targetRoute[0];
                                }
                            }
                            /*if (routeIndex) {
                                mergedRoutes.forEach((route) => route.index = false);
                                var newRouteIndex = {
                                    index: true,
                                    name: routeIndex.name,
                                    icon: routeIndex.icon,
                                    component: routeIndex.component,
                                    layout: "",
                                    redirect: true
                                };
                                routesConfig.push(newRouteIndex);
                            }*/

                            mergedRoutes = appRoutes.concat(routesConfig);

                            //[{"ID": 59, "DDD": "1970-01-01T00:00:00", "Categ": "PGE", "Titre": "COMPTE_BIENVENUE_CLIENTEXTERNE", "BindID": "PGE-TAB-7360091809-1"}, {"ID": 80, "DDD": "1970-01-01T00:00:00", "Categ": "PGE", "Titre": "DOC - DOCUMENTS TECHNIQUES", "BindID": "PGE-DOC-915331492-1"}, {"ID": 79, "DDD": "1970-01-01T00:00:00", "Categ": "PGE", "Titre": "FAQ - TELECOMMANDE", "BindID": "PGE-TUT-4944830920-1"}, {"ID": 43, "DDD": "1970-01-01T00:00:00", "Categ": "PGE", "Titre": "TICKET_ACCUSERECEPTION", "BindID": "PGE-TAB-687683590-1"}, {"ID": 45, "DDD": "1970-01-01T00:00:00", "Categ": "PGE", "Titre": "TICKET_ACTION", "BindID": "PGE-TAB-9611796413-1"}, {"ID": 44, "DDD": "1970-01-01T00:00:00", "Categ": "PGE", "Titre": "TICKET_CLOTURE", "BindID": "PGE-TAB-158951936-1"}, {"ID": 69, "DDD": "1970-01-01T00:00:00", "Categ": "PGE", "Titre": "TICKET_NOUVEAUMESSAGE_SUPPORT", "BindID": "PGE-TAB-9343919037-1"}]
                            commonFunc.getMethod("../api/custom/GetModelsAsKeyItems", { id: -1, categ: "PGE" }).then((responsePge) => {
                                if (responsePge.data.isSuccess && responsePge.data.result && responsePge.data.result.length) {
                                    var convertCRoutes = context.convertContentRoutes(responsePge.data.result, mergedRoutes);
                                    if (context.checkRights("CUSTOM_PGE", "L", user.rights ? user.rights.flags : null)) mergedRoutes = mergedRoutes.concat(convertCRoutes);
                                }

                                var configPCFG = null;
                                if (response.data.result.models && response.data.result.models.length) {
                                    var resFilter = response.data.result.models.filter((model) => model.extID === "PCFG");
                                    if (resFilter && resFilter.length) {
                                        configPCFG = resFilter[0];
                                    }
                                }
                                if (configPCFG) {
                                    var dataCfgObj = JSON.parse(configPCFG.value);
                                    if (dataCfgObj == null) {
                                        //Config par defaut
                                        dataCfgObj = {
                                            dlgSup_altRequired_1: true,
                                            dlgSup_checkEnd_1: true,
                                            dlgSup_useManager: false,
                                            dlgSup_contact_1: true,
                                            dlgSup_enableSend_1: true,//Renommé dlgSup_disabledSend_1
                                            smsModel: "Nous vous confirmons le rendez-vous du {0} à {1}.",
                                            contactTypes: [
                                                { "ID": 1, "IsDefault": true, "ContactType": "Contact Client", "Role": "CLIENT" },
                                                { "ID": 2, "IsDefault": false, "ContactType": "Contact Externe", "Role": "EXTERNE" },
                                                { "ID": 3, "IsDefault": false, "ContactType": "Voisin", "Role": "VOISIN" }
                                            ],
                                            customList: []
                                        };
                                    }

                                    context.dataCfg = dataCfgObj;
                                } else {
                                    //Echec de config
                                }


                                if (responseSite.data.isSuccess) {
                                    context.setState({ mergedRoutes: mergedRoutes, defaultPath: routeIndex ? routeIndex.path : "/", site: responseSite.data.result });
                                }
                                else {
                                    context.setState({ mergedRoutes: mergedRoutes, defaultPath: routeIndex ? routeIndex.path : "/" });
                                }
                                resolve({ routes: mergedRoutes, models: response.data.result.models });
                            });
                        });
                    });
                }
                else {
                    context.setState({ mergedRoutes: mergedRoutes, site: null });
                    resolve({ routes: mergedRoutes, models: null });
                }

            });
        });
    }

    //Code ok, des problèmes peuvent survenir sur un plantage au niveau de la réponse de l'appel
    logOut() {
        var context = this;

        Swal.fire({
            title: "Déconnexion en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        commonFunc.postMethod("../api/logout", null).then((response) => {
            console.debug("RESPONSE LOGOUT ");
            Swal.close();
            localStorage.removeItem('token');
            axios.defaults.headers['Authorization'] = null;
            //window.location.href = "/";
            if (context.state.isLoggedIn) {
                console.debug("user LOGOUT ");
                console.debug(context);
                console.debug(context.setState);

                context.setState({ mergedRoutes: appRoutes, isLoggedIn: false, user: {}, defaultPath: "/" }, () => {
                    console.debug("STATES UPDATED");
                    console.debug(context.state.isLoggedIn);
                });
                //context.forceUpdate();


                if (document.getElementsByClassName("modal").length) {
                    document.getElementsByClassName("modal")[0].parentElement.remove();
                }
            }
        });
    }

    alternativeDisplay() {
        document.body.classList.toggle("alt-display");
    }

    static hasJWT() {
        let flag = false;

        //check user has JWT token
        localStorage.getItem("token") ? flag = true : flag = false

        return flag
    }

    static async getUser(callback) {
        await commonFunc.getMethod("../api/GetUser", null/*{ level: 3 }*/).then((response) => {// /!\ level:3 >> perte des rights
            //console.debug("RESPONSE TEST");
            //console.debug(response);

            if (callback && typeof callback === "function") { callback(response.data); }
        });
    }

    async updateUser(callback) {
        var context = this;
        await App.getUser(function (userResponse) {
            //console.debug(userResponse);
            if (userResponse.isSuccess) {
                userResponse.result.avatar = (!commonFunc.isBlank(userResponse.result.avatar) ? userResponse.result.avatar : avatar);

                console.debug("Change user/login values " + App.hasJWT());
                context.setState({ user: userResponse.result, isLoggedIn: App.hasJWT() });
                if (callback && typeof callback === "function") { callback(userResponse.result); }
            }
            else {
                /*if (userResponse.errorCode == 403) {
                    context.logOut();
                } else {*/
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors du chargement de l'utilisateur :<br>" + userResponse.errorMessage
                    });
                //}
            }
        });
    }

    checkRights(entity, rights, customFlags) {
        var hasRights = false;
        if (commonFunc.isBlank(entity)) return true;

        if (customFlags || this.state.user.rights) {
            var userRights = customFlags || this.state.user.rights.flags;
            var currRight = userRights.filter((flag) => {
                if (flag.entities) {
                    return flag.active && flag.value.indexOf(rights) >= 0 && flag.entities.indexOf(";" + entity + ";") >= 0;
                }
                else {
                    return flag.active && entity === flag.entity && flag.value.indexOf(rights) >= 0;
                }
            });
            hasRights = currRight.length > 0;
        }

        return hasRights;
    }

}