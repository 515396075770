import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    FormFeedback
} from "reactstrap";

import Swal from "sweetalert2";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

import * as commonFunc from 'scripts/common';

function RegisterStepEmail(props) {
    const { t } = useTranslation();

    //Création du schéma pour le paramètrage du formulaire

    const schema = yup.object().shape({
           email: yup.string().required(t("gal_reqFields")).email(t("gal_invalidEmail")).min(2, t("gal_minChar", [2])).max(128, t("gal_maxChar", [128])),
    }).required();

    React.useEffect(() => {
        if (props.triggerAnimate) props.triggerAnimate();
    }, [props.shouldRender]);

    //Paramètrage du formulaire
    const { control, register, getValues, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });


    function redirectExternalLogin(provider) {
        getCurrentBrowserFingerPrint().then((fingerprint) => {
            //navigate("../ExternalLogin/ExternalLogin");
            window.location.assign('../ExternalLogin/ExternalLogin?provider=' + provider + '&deviceId=' + fingerprint);
        });
    }

    async function triggerSubmit(data) {
        Swal.fire({
            title: t("log_checkInfos"),
            didOpen: () => { Swal.showLoading() }
        });

        commonFunc.postMethod("../api/ValidateEmail", { email: data.email }).then((response) => {
            var result = response.data;
            if (result.isSuccess) {
                Swal.close();
                data.title = rSelected;
                if (props.updateStep) props.updateStep(data);
            }
            else if (result.errorCode == 400 || result.errorCode == 500) {
                Swal.fire({
                    icon: 'error',
                    title: t("gal_error"),
                    html: result.errorMessage,
                });
            }
        });
    }

    const [triggerUpdate, setTriggerUpdate] = React.useState(false);
    const [rSelected, setRSelected] = React.useState("M");

    const { ref: refEmail, ...restEmail } = register("email");


    return (
        <Form action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerSubmit)}>
            <CardHeader className="bg-white">
                <CardTitle className='header my-0 py-0' tag="h5">{t("register_userInfos")}</CardTitle>
                <div className="social">
                    <Button title={t("register_authGoogle")} className="btn-icon btn-round hidden" color="google" type="button" onClick={() => redirectExternalLogin("Google")}>
                        <i className="fa-brands fa-google" />
                    </Button>
                    <Button title={t("register_authMicrosoft")} className="btn-icon btn-round hidden" color="microsoft" type="button" onClick={() => redirectExternalLogin("Microsoft")}>
                        <i className="fa-brands fa-microsoft" />
                    </Button>
                    <Button title={t("register_authFacebook")} className="btn-icon btn-round hidden" color="facebook" type="button" onClick={() => redirectExternalLogin("Facebook")}>
                        <i className="fa-brands fa-facebook-f" />
                    </Button>
                    <p className="card-description mb-0 hidden">{t("register_or")}</p>
                </div>
            </CardHeader>
            <CardBody>
                <InputGroup className="single-input">
                    <InputGroupText className="mandatory">
                        <i className="fa fa-envelope" />
                    </InputGroupText>
                    <div className="form-floating min-input">
                        <Input id="inputemail" type="email" autoComplete="no" aria-autocomplete="none" placeholder={t("register_email")} innerRef={refEmail} {...restEmail} invalid={errors.email ? true : false} />
                        <Label for="inputemail">{t("register_email")}</Label>
                    </div>
                    <FormFeedback className={`text-start ${errors.email ? "d-block" : ""}`}>{errors.email ? errors.email.message : t("gal_reqFields")}</FormFeedback>
                </InputGroup>
            </CardBody>
            <CardFooter className="text-end mt-0">
                <Button title={t("register_next")} className="btn-icon rounded-circle bg-altLink-color btn btn-primary" color="info"><i className="fa fa-arrow-right" style={{ fontSize: "1.6em" }}></i></Button>
            </CardFooter>
        </Form>
    );
}

export default RegisterStepEmail;
