import React, { Component } from 'react';
export default class Img extends Component {
    state = { loaded: false, error: true };

    onLoad = () => {
        this.setState({ loaded: true, error: false });
        console.log("Loaded!");
    };
    onError = () => {
        this.setState({ loaded: false, error: true });
        console.log("Error!");
    };

    render() {
        const { loaded, error } = this.state;

        return (
            <img alt=""
                {...this.props}
                className={this.props.className + " img-loading" + (loaded ? " img-loaded" : "") + (error ? " d-none" : "") }
                onLoad={this.onLoad}
                onError={this.onError}
            />
        );
    }
}