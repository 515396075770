import React, { Component } from 'react';

import AGenerateModel from 'components/generic/AGenerateModel';

export default class ShowDetails extends Component {

    generateManager = React.createRef();

    componentDidMount() {
        //this.props.loadElem(this.state.id);
    }
    componentDidUpdate(prevProps) {
        //console.debug("willUpdate ShowDetails " + this.props.id);
        //console.debug("willUpdate ShowDetails " + (prevProps.id !== this.props.id));
        if (prevProps.id !== 0 && this.props.id !== 0 && (prevProps.id !== this.props.id || (this.props.shouldRender && prevProps.formEditing === this.props.formEditing))) {
            if (this.props.loadElem) this.props.loadElem(this.props.id);
        }
    }

    render() {
        //Config
        var configDetails = this.props.configModel;
        var config = JSON.parse(JSON.stringify(configDetails));

        let contents = ((this.props.id > 0 || this.props.formEditing) && this.props.shouldRender)
            ? <AGenerateModel ref={this.props.genRef || this.generateManager} noButtons={this.props.noButtons} modelsList={this.props.modelsList} onSubmit={(data, isUpdate) => this.props.saveElem(data, isUpdate)} appProps={this.props.appProps} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} parentProps={this.props} configModel={config} loadedData={this.props.data} formEditing={this.props.formEditing} />
            : <p><em>Chargement...</em></p>;

        return (
            <div>
                {contents}
            </div>
        );
    }
}