import React, { Component } from 'react';
import * as commonFunc from 'scripts/common';
import { Button, Form,  Row, Col, Label, Input, FormFeedback } from 'reactstrap';

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import DPCustom from 'components/DPCustom';
import moment from "moment";



//Création du schéma pour le paramètrage du formulaire
const itvModalSchema = yup.object().shape({
    aupdItvSd: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode === "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//date
    aupdItvSh: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode === "H" ? schema.required("Ce champ est obligatoire") : schema;
    }),//time
    aupdItvEh: yup.string().when('currentDateMode', (currentDateMode, schema) => {
        return currentDateMode === "H" ? schema.required("Ce champ est obligatoire") : schema;
    })
}).required();



export default class Debug extends Component {
    static displayName = Debug.name;

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://code.jquery.com/jquery-3.7.1.min.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();

        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "https://oskala.softena.com/Scripts/common.js?v=3.0";
        script2.async = true;
        script2.onload = () => this.scriptLoaded();

        document.body.appendChild(script2);

        //require('scripts/common-debug.js')
    }

    scriptLoaded() {
        //Debug.sort();
    }

    render() {
        return (<>
            <h5>Debug Page</h5>
            <DebugComponent/>
        </>);
    }
}


function DebugComponent(props) {

    const { control, register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(itvModalSchema) });

    React.useEffect(() => {
        let defaultValues = {};
        defaultValues.aupdItvSd = new Date("2023-11-27");
        defaultValues.aupdItvSh = "11:53";
        defaultValues.aupdItvEh = "14:57";
        reset({ ...defaultValues });
    }, []);


    //const { ref: refItvSd, ...restItvSd } = register("aupdItvSd");
    const { ref: refItvSh, ...restItvSh } = register("aupdItvSh");
    const { ref: refItvEh, ...restItvEh } = register("aupdItvEh");

    function triggerItvModalSubmit(data) {
        console.debug(data);
        console.debug(commonFunc.formatDateDMY(data.aupdItvSd));
        console.debug(commonFunc.formatDateDMY(new Date(data.aupdItvSd)));
    }

    console.debug(errors);
    return (<>
        <Form id="frmaupditv" action="" className="form needs-validation" method="" onSubmit={handleSubmit(triggerItvModalSubmit)}>
            <div id="dlg-addUpdateItv-2" className="filter-period" data-bindtag="H">
                <Row className="align-items-center mt-2">
                    <Col sm="2"><Label for="aupdItvSd">Date :</Label></Col>
                    <Col sm="4">
                        <Controller
                            control={control}
                            name='aupdItvSd'
                            render={({ field: { ref, ...field } }) => (
                                <DPCustom
                                    className={""}
                                    showWeekNumbers={props.datepickerOpt && props.datepickerOpt.showWeeks}
                                    dateFormat="dd/MM/yyyy"
                                    timeFormat={false}
                                    locale="fr"
                                    selected={field.value ? field.value : moment(new Date()).utc().toDate()}
                                    innerRef={ref} {...field}
                                    invalid={errors.aupdItvSd ? true : false}
                                />
                            )}
                        />
                        <FormFeedback className="text-start">{errors.aupdItvSd ? errors.aupdItvSd.message : "Ce champ est obligatoire"}</FormFeedback>
                    </Col>
                    <Col sm="5"><Label>{" "}</Label></Col>
                </Row>
                <div className="hr-line-dashed"></div>
                <Row className="align-items-center mt-2">
                    <Col sm="2"><Label for="aupdItvSh">De :</Label></Col>
                    <Col sm="3">
                        <Input id="aupdItvSh" name="aupdItvSh" type="time"
                            innerRef={refItvSh} {...restItvSh}
                            invalid={errors.aupdItvSh ? true : false} />
                        <FormFeedback className="text-start">{errors.aupdItvSh ? errors.aupdItvSh.message : "Ce champ est obligatoire"}</FormFeedback>
                    </Col>
                    <Col sm="1"><Label for="aupdItvEh">{" à "}</Label></Col>
                    <Col sm="3">
                        <Input id="aupdItvEh" name="aupdItvEh" type="time"
                            innerRef={refItvEh} {...restItvEh}
                            invalid={errors.aupdItvEh ? true : false} />
                        <FormFeedback className="text-start">{errors.aupdItvEh ? errors.aupdItvEh.message : "Ce champ est obligatoire"}</FormFeedback>
                    </Col>
                </Row>
            </div>
            <Button className="px-3" color={props.itvId > 0 ? "primary" : "success"} form="frmaupditv">
                <i className="fa fa-save"></i>{props.itvId > 0 ? "Enregistrer" : "Ajouter"}
            </Button>
        </Form>

    </>);
}

