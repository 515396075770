import React, { Component } from 'react';
import PDFObject from 'pdfobject';
import PropTypes from "prop-types";

import * as commonFunc from 'scripts/common';

class PdfViewer extends Component {
    componentDidMount() {
        this.processPreview(this.props);
    }
    componentDidUpdate() {
        this.processPreview(this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.url !== this.props.url || nextProps.shouldRender !== this.props.shouldRender) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        const { width, height, containerId, shouldRender } = this.props;

        //console.debug("render " + shouldRender);

        return shouldRender ? <div /*style={{ width, height }}*/ id={containerId} className="d-flex" /> : <></>;
        /*<pdfadata src="facture.xml" format="application/xml">
            <!-- Contenu du fichier XML de la facture au format français -->
            <facture>
                <titre>Facture</titre>
                <informations>
                    <numero>12345</numero>
                    <date>15 septembre 2023</date>
                    <!-- Autres informations sur la facture -->
                </informations>
                <destinataire>
                    <nom>Client XYZ</nom>
                    <adresse>123 Rue de la Facture</adresse>
                    <!-- Autres informations sur le destinataire -->
                </destinataire>
                <items>
                    <item>
                        <description>Produit A</description>
                        <quantite>2</quantite>
                        <prix_unitaire>10.00</prix_unitaire>
                        <!-- Autres détails de l'item -->
                    </item>
                    <!-- Ajoutez d'autres items ici -->
                </items>
                <total>20.00</total>
            </facture>
        </pdfadata>*/
    }

    processPreview({ url, containerId }) {
        //console.debug("showPreview " + url);

        var optPdf = {
            pdfOpenParams: {
                navpanes: 0,
                toolbar: 0,
                statusbar: 0,
                //view: "FitV",
                pagemode: "thumbs",
                //zoom: "page-fit",//page-width, page-height, page-fit, auto
            },
            PDFJS_URL: process.env.PUBLIC_URL + "/PDFjs/web/viewer.html",
            fallbackLink: false,
            forcePDFJS: true
        };
        PDFObject.embed(url, `#${containerId}`, optPdf);
    }
}

PdfViewer.propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    containerId: PropTypes.string,
    shouldRender: PropTypes.bool,
};

// /!\ PB avec les useEffect
PdfViewer.defaultProps = {
    width: '100%',
    height: '100%',
    containerId: 'pdf-viewer',
    shouldRender: true,
};

export default PdfViewer;