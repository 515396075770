import React, { Component } from 'react';
import Swal from "sweetalert2";

import * as commonFunc from 'scripts/common';
import * as filesFunc from 'scripts/DTAFiles';

import {
    Row, Col,
    Button, Label, Input
} from "reactstrap";

import PdfViewer from 'components/PdfViewer';

class ReportFinal extends Component {

    constructor(props) {
        super(props);
        this.state = { files: [], status: [], currStatus: 350, url: "", removeEnabled: false };
    }

    componentDidMount(prevProps, prevState) {
        this.loadReportFinal(this.props);
    }
    componentDidUpdate(prevProps, prevState) {
        const { reportId, shouldRender } = this.props;
        if (prevProps.reportId !== reportId || prevProps.shouldRender !== shouldRender) {
            this.loadReportFinal(this.props);
        }
        else {
            //Juste url
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.reportId !== this.props.reportId || nextProps.shouldRender !== this.props.shouldRender || nextState.url !== this.state.url || nextState.filesStamp !== this.state.filesStamp) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        //console.debug("render " + this.props.shouldRender);
        var context = this;

        return this.props.shouldRender ? (<div>
            <div>
                <Button id="btnActFinalDataRefresh" className="btn-rotate px-2 my-0 btn-simple" color="dark" size="sm" onClick={() => { this.setState({ files: [], url: "", removeEnabled: false }); this.loadReportFinal(); }}>
                    <i className="fa fa-sync"></i>Actualiser
                </Button>
                {(!this.props.report || !this.props.report.status || this.props.report.status.step === 2) && this.props.checkRights("REPORT", "S") ? <Button id="btnActFinalDone" className="btn-magnify px-2 my-0" color="success" size="sm" onClick={() => { this.props.updateReportStatus(204); }}>
                    <i className="fa fa-check-square"></i>Rapport Traité
                </Button> : ""}
                {this.props.checkRights("REPORT", "U") ? <Button id="btnActFinalDataEmail" className="btn-magnify px-2 my-0 btn-simple" color="dark" size="sm" onClick={() => { this.props.sendEmail(); }}>
                    <i className="fa fa-envelope"></i>Envoi Email
                </Button> : ""}
                {this.props.checkRights("REPORT_FILES", "D") ? <Button id="btnActFinalRemove" className={`btn-magnify px-2 my-0 ${this.state.removeEnabled ? "" : "disabled"}`} disabled={!this.state.removeEnabled} color="danger" size="sm" onClick={() => { this.removePDFReport(); }}>
                    <i className="fa fa-ban"></i>Repasser en correction
                </Button> : ""}
                {(!this.props.report || !this.props.report.status || (this.props.report.status.step >= 3 && this.props.report.status.step < 5)) && this.props.checkRights("REPORT", "S") ? <Button id="btnActFinalDataArchive" className="btn-magnify px-2 my-0" color="secondary" size="sm" onClick={() => { this.props.updateReportStatus(206); }}>
                    <i className="fa fa-archive"></i>Archiver
                </Button> : ""}

                {this.props.report ? <div id="btnLstSubStatus" className="float-end">
                    {this.state.status.map((status, index) => <div key={index} className={`form-check-radio fcheck-sm m-0 d-inline-block me-3`}>
                        <Label check >
                            <Input id={`radio_${status.idStatus}`} name={"radioReportSubStatus"} checked={status.idStatus === context.state.currStatus} type="radio" value={status.idStatus} disabled={false} onChange={(e) => {
                                var value = isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value);

                                if (this.props.report) this.props.updateReportStatus(this.props.report.statusID, value, status.name);
                            }} />
                            <span className="form-check-sign" />
                            {status.css ? <i className={`fa ${status.css} me-2`}></i> : ""}
                            {status.name}
                        </Label>
                    </div>)}
                </div> : ""}
                <div className="hr-line-dashed" style={{clear:"both"}}></div>
            </div>

            <Row>
                <Col lg="12">
                    <div id="aupdActFinalFilesLst">
                        {this.state.files}
                    </div>
                </Col>
            </Row>

            <PdfViewer url={this.state.url} containerId="embdActFinalPDf" shouldRender={this.props.shouldRender}></PdfViewer>

        </div>) : <></>;
    }

    loadReportFinal() {
        this.setState({ files: [], status: [], currStatus: 350, url: "", removeEnabled: false });
        const { report, reportId, shouldRender } = this.props;

        var context = this;
        if (shouldRender && reportId > 0) {
            var min = -1;
            var max = null;
            commonFunc.getMethod("../api/report/GetStatus", { isSubStatus: true/*, level: curStep*/ }).then((response) => {//TODO : test avec level
                var data = response.data;

                if (data.isSuccess) {
                    var itemOptions = [];
                    var loadedStepID = report ? report.stepID : 0;
                    var defaultStepID = 350;
                    data.result.forEach((status) => {
                        if (!status.isAutomatic && (min == null || min === undefined || status.step > parseInt(min)) && (max == null || max === undefined || status.step < parseInt(max))) {
                            if (loadedStepID === status.idStatus) defaultStepID = status.idStatus;
                            itemOptions.push(status);//status.css
                        }
                    });

                    if (itemOptions && itemOptions.length > 0) {
                        context.setState({ currStatus: (defaultStepID || itemOptions[0].value), status: itemOptions });
                    }
                }


                filesFunc.loadFilesLst(reportId, -1, {
                    srcElem: report, className: "report-final", finalFilesOnly: true, selectedFileCond: (dt) => { return dt.idFile === report.resultFileID/*dt.categ === "PDF"*/ }, altFilesUrl: "../api/report/GetReportFiles", clickCallback: (dt, elem) => {
                        var title = commonFunc.cellFormatterFileType(dt.name);
                        if (title && title.toLowerCase().endsWith(".pdf")) {
                            const file = {
                                fileId: dt.idFile,
                                fileName: dt.name,
                                container: dt.rootContainer,
                                etag: dt.etag,
                                accessToken: dt.absoluteUri,
                                newWindow: false
                            };
                            var filePath = "/api/data/DownloadFileByUri?" + commonFunc.toParams(file);

                            context.setState({ url: filePath });
                        }
                    }
                }).then((result) => {
                    if (result) {
                        //console.debug(result);

                        var url = "";
                        var removeEnabled = false;
                        if (result.dataFiles) {
                            result.dataFiles.forEach((dt, i) => {
                                //if (dt.categ === "PDF") {
                                if ((commonFunc.isBlank(url) && dt.categ === "PDF") || dt.idFile === report.resultFileID) {
                                    //url = "../api/data/DownloadFileByUri?fileId=" + parseInt(dt.idFile) + "&bindID=" + reportId + "&newWindow=false&file=fic.pdf";//&filetypeId=0
                                    const file = {
                                        fileId: dt.idFile,
                                        fileName: dt.name,
                                        container: dt.rootContainer,
                                        etag: dt.etag,
                                        accessToken: dt.absoluteUri,
                                        newWindow: false
                                    };
                                    url = "/api/data/DownloadFileByUri?" + commonFunc.toParams(file);
                                    removeEnabled = true;
                                }
                            });
                        }
                        context.setState({ files: result.files, url: url, filesStamp: new Date().getTime(), removeEnabled: removeEnabled });
                    }
                });
            });
        }
    }

    removePDFReport() {
        var context = this;

        var dataReport = this.props.report;
        var id = dataReport.idReport;
        if (parseInt(id) > 0) {
            Swal.fire({
                icon: "question",
                title: "Suppression du Rapport PDF",
                html: "Voulez-vous supprimer ce Rapport Final PDF ?",
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#dc3545",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Suppression du Rapport Final PDF...',
                        didOpen: () => { Swal.showLoading() },
                    });
                    var req = { id: id, fileID: dataReport.resultFileID, isPermanent: true, fallbackStatusID: -1 };
                    //var statusChange = $("#content").data("reportStatusOnPDFRemove");

                    var statusChange = context.props.dataCfg ? context.props.dataCfg.reportStatusOnPDFRemove : -1;
                    if (statusChange && dataReport.statusID > statusChange) {//Si config + Statut supérieur à "A corriger"
                        req.fallbackStatusID = parseInt(statusChange);
                    }

                    commonFunc.postMethod("../api/report/RemoveRelease", req).then((response) => {
                        var data = response.data;
                        Swal.close();
                        if (data && data.isSuccess) {
                            context.loadReportFinal();
                            if (req.fallbackStatus > 0) { context.props.loadReport(id); }
                            // $("#btnLstItvsRefresh").click();
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: "Echec de suppression du Suppression du Rapport PDF<br/>" + data.errorMessage
                            });
                        }
                    });
                }
            });
        }
    }

}

export default ReportFinal;