import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import Swal from "sweetalert2";

import { Button } from "reactstrap";

import * as commonFunc from 'scripts/common';
import CDataTable from "components/CDataTable";

export default class ManageUsers extends Component {
    static displayName = ManageUsers.name;

    constructor(props) {
        super(props);
        this.state = { users: [], loading: true, dataStamp: 0, isShowDetails: false };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function () {
            context.populateUsersData();
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");

        //Update Scroller Size
        if (prevState.isShowDetails && !this.state.isShowDetails) {
            if (this.props.getScroller) this.props.getScroller().update();
        }

        //this.populateUsersData();
    }

    render() {
        var context = this;

        //TODO : createdCell >> dépendance du contexte pour éxecution de la fonction
        //TEMP : doit dépendre d'une configuration (tout virer sauf createdCell et cbInitComplete)
        var dtConfig = {
            generateColumns: true,
            cExportSelector: "th:not(.excludedCol, .excludedExport)",
            cColvisSelector: ":not(.excludedCol, .excludedColvis)",
            lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "Tous"]],
            pageLength: 5,
            scrollY: true,
            rowId: 'id',
            responsive: true,
            ordering: true,
            columns: [
                { customTitle: "ID", customClass: "excludedColvis renderingHidden2", data: 'id' },
                { customTitle: 'Login', customClass: "respPrio", data: 'userLogin' },
                { customTitle: 'Utilisateur', data: 'userName' },
                { customTitle: 'IP', data: 'connection.ip' },
                { customTitle: 'Droits', data: 'primarySId' },
                { customTitle: 'Type', data: 'primaryGroupId' },
                { customTitle: 'Mat.', data: 'primaryGroupId' },
                { customTitle: 'Connecté&nbsp;le', customClass: "renderingDatetime", data: 'connection.startDate' },
                { customTitle: 'Fin&nbsp;le', customClass: "renderingDatetime", data: 'connection.expireDate' },
                { customTitle: 'Appli ', customClass: "renderingEllipsis respPrio", data: 'connection.userAgent' },
                { customTitle: 'Actif', customClass: "renderingCheck", data: 'status' },
                { customTitle: 'SSL', customClass: "renderingCheck", data: 'connection.isSecured' },
                { customTitle: 'AppID', data: 'connection.deviceId' },
                { customTitle: 'CliID', data: 'clientID' },
                { customTitle: 'Action', customClass: "renderingLogOut respPrio", data: 'id' },
                { customTitle: 'Type', data: 'connection.authType' },

            ],
            columnDefs: [
                {
                    generateFunction: "datetime",
                    targets: "renderingDatetime"
                },
                {
                    generateFunction: "check",
                    targets: "renderingCheck"
                },
                {
                    generateFunction: "ellipsis",
                    targets: "renderingEllipsis"
                },
                {
                    responsivePriority: true,
                    targets: "respPrio"
                },
                {
                    targets: "renderingHidden",
                    visible: false
                },
                {
                    targets: "searchableDisabled",
                    searchable: false
                },
                {
                    targets: "sortingDisabled",
                    orderable: false
                },
                {
                    createdCell: (td, cellData, rowData, row, col) => {
                        var currRoot = createRoot(td);
                        currRoot.render(this.props.checkRights("USER", "U") ? <Button className='btn-icon' color="primary" size="sm" onClick={() => context.forceLogout(rowData.userLogin)} title="Déconnecter"><i className='fa fa-edit'></i></Button> : "");
                    }, "targets": "renderingLogOut"
                },
            ],
            cbInitComplete: function (cRootList) {
                //Swal.close();

                /*cRootList.map((prop, elem) => {
                    console.debug(elem);
                    if (elem.classList.contains("cneedroot")) {
                        var rootElem = createRoot(elem);
                        switch (elem.getAttribute("cneedroot")) {
                            case "forceLogout":
                                rootElem.render(<button className="btn btn-primary btn-icon" onClick={() => context.forceLogout('hey!')}><i className='fa fa-edit'></i></button>);
                                break;
                            default:
                                break;
                        }
                    }
                    return null;
                });*/
            }
        };

        let contents = this.state.loading ? <p><em>Chargement...</em></p> :
            <div>
                <CDataTable className="table-bordered table-striped table-hover" dtId={"tableUsers"} data={this.state.users} localConfig={dtConfig} categ={"DT_USERS"} selectEvent={(id) => this.showDetails(id)} stamp={this.state.dataStamp} />
            </div>;

        return (
            <div>

                <h1 id="tabelLabel" >Utilisateurs</h1>
                <p>Liste des utilisateurs</p>
                <Button className="btn-rotate px-2 btn-simple" color="dark" size="sm" onClick={() => this.populateUsersData()}>
                    <i className="fa fa-sync"></i>Actualiser
                </Button>
                {this.props.checkRights("USER", "U") ? <Button className="btn-sm btn-move-right px-2" color="danger" size="sm" onClick={() => this.disconnectUsers()}>
                    <i className="fa fa-right-from-bracket me-2"></i>Déco. Globale
                </Button> : ""}
                {contents}
            </div>
        );
    }

    async populateUsersData() {
        this.setState({ users: [], loading: true, dataStamp: 0, isShowDetails: false });
        var context = this;

        Swal.fire({
            title: "Chargement en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        commonFunc.getMethod("../api/GetOnlineUsers", null).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {
                    Swal.close();
                    context.setState({ users: response.data.result, loading: false, dataStamp: new Date().getTime(), isShowDetails: false });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        html: "Erreur lors de la récupération des utilisateurs :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de la récupération des utilisateurs"
                });
            }
        });

    }

    forceLogout(login) {
        var context = this;

        Swal.fire({
            icon: "question",
            title: "Utilisateurs",
            html: "Etes-vous sûr de vouloir déconnecter cet utilisateur ?",
            showCancelButton: true,
            confirmButtonColor: "#28a745",
            cancelButtonColor: "#dc3545",
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: "Déconnexion en cours...",
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });

                var req = { login: login };
                commonFunc.postMethod("../api/ForceUserLogout", req).then((response) => {
                    if (response.data) {
                        if (response.data.isSuccess) {
                            context.populateUsersData();
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de la déconnection de l'utilisateur :<br>" + response.data.errorMessage
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur lors de la déconnection de l'utilisateur"
                        });
                    }
                });
            }
        });
    }

    disconnectUsers() {
        var context = this;

        Swal.fire({
            icon: "question",
            title: "Utilisateurs",
            html: "Etes-vous sûr de vouloir déconnecter tous les utilisateurs ?",
            showCancelButton: true,
            confirmButtonColor: "#28a745",
            cancelButtonColor: "#dc3545",
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: "Déconnexion en cours...",
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                commonFunc.postMethod("../api/ForceAllUsersLogout", null).then((response) => {
                    if (response.data) {
                        if (response.data.isSuccess) {
                            context.populateUsersData();
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de la déconnection des utilisateurs :<br>" + response.data.errorMessage
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur lors de la déconnection des utilisateurs"
                        });
                    }
                });
            }
        });
    }
}
