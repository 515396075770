/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
} from "reactstrap";

function AuthNavbar(props) {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [color, setColor] = React.useState("navbar-transparent");
    // this function opens and closes the collapse on small devices
    // it also adds navbar-transparent class to the navbar when closed
    // ad bg-white when opened
    const toggleCollapse = () => {
        if (!collapseOpen) {
            setColor("bg-white");
        } else {
            setColor("navbar-transparent");
        }
        setCollapseOpen(!collapseOpen);
    };

    var logo = "assets/img/bg/logo-softena-white.png";
    return (
        <Navbar
            className={`fixed-top my-0 p-0 ${color}`}
            expand="lg"
        >
            <div className="navbar-wrapper">
                <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                        src={require('assets/img/bg/logo-softena-white.png')}
                        height='48'
                        alt=''
                        loading='lazy'
                        className='mb-2'
                    />
                    <span className="d-none d-md-inline-block"> • Plateforme Oskala</span>
                </NavbarBrand>
            </div>
            <Nav className="ms-auto d-block" navbar>
                <NavItem className="d-inline-block">
                    <NavLink /*to="/sign-up"*/ className="nav-link text-white" onClick={() => { window.location.href = "https://www.oskala.com/Prices"; }}>
                        <i className="fa fa-pen-to-square opacity-100" title="S'inscrire" />
                        <span className="d-none d-md-inline ps-1 fw-bold">S'inscrire</span>
                    </NavLink>
                </NavItem>
                <NavItem className="d-inline-block">
                    <NavLink to="/login" className="nav-link text-white">
                        <i className="fa fa-right-to-bracket opacity-100" title="Se connecter" />
                        <span className="d-none d-md-inline ps-1 fw-bold">Se connecter</span>
                    </NavLink>
                </NavItem>
            </Nav>
        </Navbar>
    );
}

export default AuthNavbar;
