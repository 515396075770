import React, { useState } from 'react';
import { Navbar, NavbarToggler, Collapse } from 'reactstrap';

function CNavbar(props) {
    const [isRightOpen, setIsRightOpen] = useState(false);

    const toggleRight = () => setIsRightOpen(!isRightOpen);

    return (
        <Navbar light expand="lg" className={`p-0 subnavbar mb-3 ${props.className}`}>
            <NavbarToggler onClick={toggleRight}>{props.togglerChilds}</NavbarToggler>
            <Collapse isOpen={isRightOpen} navbar>
                {props.children}        
            </Collapse>
        </Navbar>
    );
}

export default CNavbar;

