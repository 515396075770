import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import {
    Button, Input, Label,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form
} from "reactstrap";

import FilesCustom from 'components/FilesCustom';
import ShowDetails from 'components/ShowDetails';
import Historical from 'components/Historical';

import CNavbar from 'components/CNavbar';

import * as commonFunc from 'scripts/common';
import CDataTable, { showDtPrevID, showDtSelID, showDtNextID, setDtNoSelect } from "components/CDataTable";

import { withRouter } from 'components/withRouter';

class ManageClients extends Component {
    static displayName = ManageClients.name;

    static clientSchema;
    static clientModels;
    static refreshCb;
    static modalRoot;

    constructor(props) {
        super(props);

        this.clientGen = React.createRef(null);

        this.state = { clients: [], curClient: null, curClientId: 0, status: [], defaultStatus: 900, curStatus: 900/*, historicalReports: true*/, loading: true, dataStamp: 0, activeTab: "1", title: props.i18nManager.t("client.noSelected"), editionMode: false };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function (curUser) {

            commonFunc.getMethod("../api/client/GetClientModels", { id: -1 }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {

                    context.clientModels = {};
                    dataModels.result.models.forEach((model) => {
                        context.clientModels[model.extID] = JSON.parse(model.value);
                    });

                    /*commonFunc.getMethod("../api/client/GetSchemaModel", null).then((response) => {
                        var dataSchema = response.data
                        if (dataSchema.isSuccess) {

                            context.clientSchema = dataSchema.result;*/

                            //Chargement via url
                            var defClientLoadId = 0;
                            var defClientActionId = 0;
                            if (context.props.searchParams) {
                                defClientLoadId = parseInt(context.props.searchParams.get("clientId")) || 0;
                                defClientActionId = parseInt(context.props.searchParams.get("actionId")) || 0;
                            }
                            if (defClientLoadId > 0) {
                                var defClientStatusId = parseInt(context.props.searchParams.get("statusId")) || 900;

                                context.loadStatusBtnGrp(defClientStatusId);

                                context.getClients(defClientStatusId, false, function () {
                                    /*$("#lstActs_filter input[type='search']").val(defReportLoadId);
                                    showDtSelID("lstActs", defReportLoadId);
                                    $('html, body').animate({
                                        scrollTop: $("#detailAct").offset().top
                                    }, 500);

                                    if (defClientActionId == 2) {
                                        $(".action-editReport:visible").click();
                                    }*/
                                });
                            } else {
                                context.loadStatusBtnGrp(900);

                                context.getClients();

                                /*if (defClientActionId == 1) {
                                    $(".action-addReport:visible").click();
                                }*/
                            }

                        /*}
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de la récupération du schéma client"
                            });
                        }
                    });*/

                } else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_failLoadModels")  + " :<br>" + dataModels.errorMessage
                    });
                }
            });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");
        if (/*prevState.historicalReports !== this.state.historicalReports || */prevState.curStatus !== this.state.curStatus) {
            this.getClients();
        }

        //Update Scroller Size
        if (prevState.isShowDetails && !this.state.isShowDetails) {
            if (this.props.getScroller) this.props.getScroller().update();
        }

        //Callback initCreateClient
        if (this.state.curClientId === 0 /*&& prevState.curClientId !== this.state.curClientId*/ && this.state.editionMode && !this.state.isShowDetails) {
            this.setState({ isShowDetails: true });
        }
        if (prevState.editionMode !== this.state.editionMode && this.dtFunctions && this.dtFunctions["tableClients"]) this.dtFunctions["tableClients"].setDisabled(this.state.editionMode);
    }

    componentWillUnmount() {
        //console.debug("unmount");
        if (this.modalRoot) {
            this.modalRoot.unmount(); 
            this.modalRoot = null;
        }
    }

    dtFunctionPanel(id, fctPanel) {
        if (!this.dtFunctions) this.dtFunctions = {};
        if (!this.dtFunctions[id]) this.dtFunctions[id] = {};
        this.dtFunctions[id] = fctPanel;
    }

    render() {
        var context = this;

        var listCb = function () {
            if (context.refreshCb) {
                if (context.refreshCb && typeof (context.refreshCb) === "function") { context.refreshCb(); }
                context.refreshCb = null;
            }
        };

        //TODO : createdCell >> dépendance du contexte pour éxecution de la fonction
        var dtConfig = {
            columnDefs: [
                {
                    createdCell: (td, cellData, rowData, row, col) => {
                        var currRoot = createRoot(td);
                        currRoot.render(<Button className='btn-icon btn-simple m-0' color="dark" size="sm" disabled={this.state.editionMode}
                            onClick={() => context.loadClient(rowData.idClient, rowData)} title={context.props.i18nManager.t("gal_show")} ><i className='fa fa-eye'></i></Button>);
                    }, "targets": "renderingShow"
                },
            ],
            dtOnSelect: function (dataSel) {
                var id = dataSel.idClient;
                if (parseInt(id) > 0) {
                    if (!context.state.editionMode) context.loadClient(id, dataSel);
                }
            },
            dtOnUnselect: function (dataSel) {
                if (!context.state.editionMode) context.loadClient();
            },
            dtOnDblClick: function (event) {
                /*var id = parseInt(getDtSelID("lstActs"));
                var currId = parseInt($(this).closest("tr").prop("id"));
                if (currId != id) {
                    scrollToId("detailAct");
                    showDtSelID("lstActs", currId);
                }
                else {
                    if (currId > 0) {
                        scrollToId("detailAct");
                    }
                }*/
            },
            cbInitComplete: function (cRootList) {
                //Swal.close();

                /*cRootList.map((prop, elem) => {
                    console.debug(elem);
                    if (elem.classList.contains("cneedroot")) {
                        var rootElem = createRoot(elem);
                        switch (elem.getAttribute("cneedroot")) {
                            case "forceLogout":
                                rootElem.render(<button className="btn btn-primary btn-icon" onClick={() => context.forceLogout('hey!')}><i className='fa fa-edit'></i></button>);
                                break;
                            default:
                                break;
                        }
                    }
                    return null;
                });*/

                //$("#report_select_filters").appendTo($(".dt-buttons").parent().parent());
                //$("#report_select_filters").removeClass("d-none").addClass("d-inline-block");

                //updateSidebar();

                listCb();
            },
            cbCustom: function () {
                listCb();
            }
        };

        //console.debug("render again");

        let contents = this.state.loading ? <p><em>{context.props.i18nManager.t("gal_loading")}</em></p> :
            <div>
                {this.clientModels ? <CDataTable key="clients" dtId={"tableClients"} data={this.state.clients} localConfig={dtConfig} tag={"DT_CLTS"} config={this.clientModels["DT_CLTS"]} stamp={this.state.dataStamp} functionPanel={this.dtFunctionPanel.bind(this)}
                    className="table-striped table-hover nowrap" style={{ fontSize: "90%" }} /> : ""}
            </div>;

        return (
            <div className="tableDetails">
                <Card className={`tableContainer widget dash ${this.state.editionMode ? "module-disabled" : ""}`}>
                    <CardHeader>
                        <CardTitle tag="h6">{context.props.i18nManager.t("client.title")}</CardTitle>
                        <div className="widget-tools collapse-tool">
                            <i className="fa fa-table-list fa-4x"></i>
                        </div>
                        <div className='borderTheme'></div>
                    </CardHeader>
                    <CardBody>
                        <div className="float-start">
                            <Button className="btn-rotate px-2 btn-simple mt-0" disabled={this.state.editionMode} color="dark" size="sm" onClick={() => this.getClients()}>
                                <i className="fa fa-sync"></i>{context.props.i18nManager.t("gal_refresh")}
                            </Button>
                            {this.props.checkRights("CLIENT", "C") ? <Button className="btn-magnify px-2 mt-0" disabled={this.state.editionMode} color="success" size="sm" onClick={() => this.initCreateClient()}>
                                <i className="fa fa-plus-square"></i>{context.props.i18nManager.t("client.add")}
                            </Button> : ""}
                        </div>
                        <CNavbar className="float-end">
                            <div className="text-end">
                                {this.state.status.map((dt) => {

                                    //TODO : statusBtnAltProc

                                    var statusClass = "form-badge-radio mb-1 ";
                                    statusClass += dt.css ? dt.css.replace("fa-", "untrigger") : "";
                                    return dt.isSchedulable ? <div id={`st_${dt.idStatus}`} key={dt.idStatus} className={statusClass}>
                                        <div className='form-check-radio fcheck-sm mb-0'>
                                            <Label check >
                                                <Input id={`status_${dt.idStatus}`} name={"status"} disabled={this.state.editionMode} checked={dt.idStatus === context.state.curStatus} type="radio" data-lvlv={dt.step} value={dt.idStatus} onChange={(e) => {
                                                    var value = parseInt(e.target.value);

                                                    context.setState({ curStatus: value, curClientId: 0, curClient: null, isShowDetails: false });
                                                }} />
                                                <span className="form-check-sign" />
                                                {(() => {
                                                    var classFa = "";
                                                    if (dt.css && dt.css.indexOf("fa-")) {
                                                        dt.css.split(" ").forEach((classElem) => {
                                                            //console.debug(classElem.startsWith("fa-") + classElem);
                                                            classFa += (classElem.startsWith("fa-") ? (classElem + " ") : "");
                                                        });
                                                    }
                                                    return <i className={`fa ${classFa} me-1`}></i>
                                                })()}
                                                {dt.name}
                                            </Label>
                                        </div>
                                    </div> : "";
                                })}
                            </div>
                        </CNavbar>
                        {contents}
                    </CardBody>
                </Card>

                {this.state.isShowDetails ? (<div className="detailsContainer">
                    <Card className="widget dash text-white" color="theme">
                        <CardHeader>
                            <Row>
                                <Col lg="10" className="offset-lg-1">
                                    <CardTitle tag="h6" className="text-center">
                                        {this.state.title}
                                    </CardTitle>
                                </Col>
                                <Col lg="1" className="d-flex text-end float-end justify-content-end">
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActPrev" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curClientId || 0;
                                        showDtPrevID("tableClients", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-left"></i></Button>
                                    <Button className="btn btn-icon my-0 text-dark" color="light" id="selActNext" type="button" disabled={this.state.editionMode} onClick={() => {
                                        var prevId = context.state.curClientId || 0;
                                        showDtNextID("tableClients", prevId);
                                    }}><i aria-hidden="true" className="fa fa-arrow-circle-right"></i></Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Nav pills className={`${this.state.editionMode ? "invisible" : ""}`}>
                                {this.props.checkRights("CLIENT", "R") ? <NavItem><NavLink className={`${this.state.activeTab === '1' ? "active" : ""}`} onClick={() => { this.switchTab("1") }}>{context.props.i18nManager.t("client.tabDetails")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("CLIENT_AGREEMENTS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '2' ? "active" : ""}`} onClick={() => { this.switchTab("2") }}>{context.props.i18nManager.t("client.tabAgreements")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("CLIENT_DEMANDS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '7' ? "active" : ""}`} onClick={() => { this.switchTab("7") }}>{context.props.i18nManager.t("client.tabDemands")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("CLIENT_CONTACTS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '3' ? "active" : ""}`} onClick={() => { this.switchTab("3") }}>{context.props.i18nManager.t("client.tabContacts")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("CLIENT_FILES", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '4' ? "active" : ""}`} onClick={() => { this.switchTab("4") }}>{context.props.i18nManager.t("client.tabDocs")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("CLIENT_EVENTS", "L") ? <NavItem className="d-none"><NavLink className={`${this.state.activeTab === '5' ? "active" : ""}`} onClick={() => { this.switchTab("5") }}>{context.props.i18nManager.t("client.tabItvs")}</NavLink></NavItem> : ""}
                                {this.props.checkRights("CLIENT_FLOWS", "L") ? <NavItem><NavLink className={`${this.state.activeTab === '6' ? "active" : ""}`} onClick={() => { this.switchTab("6") }}>{context.props.i18nManager.t("client.tabFlows")}</NavLink></NavItem> : ""}
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.props.checkRights("CLIENT", "R") ? <TabPane tabId="1">
                            <ShowDetails id={this.state.curClientId} type="client" genRef={this.clientGen} data={this.state.curClient} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.clientModels ? this.clientModels["CLIENT"] : {}} modelsList={this.clientModels} loadElem={this.loadClient.bind(this)} saveElem={this.saveClient.bind(this)} removeElem={this.removeClient.bind(this)} updateStatusElem={this.updateClientStatus.bind(this)} setEditingMode={this.setEditingMode.bind(this)} formEditing={this.state.editionMode} shouldRender={this.state.activeTab === '1'} />
                        </TabPane> : ""}
                        {this.props.checkRights("CLIENT_AGREEMENTS", "L") ? <TabPane tabId="2">
                            <ShowDetails id={this.state.curClientId} type="clientAgreements" data={this.state.curClient} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.clientModels ? this.clientModels["AGREEMENTS"] : {}} modelsList={this.clientModels} shouldRender={this.state.activeTab === '2'} />
                        </TabPane> : ""}
                        {this.props.checkRights("CLIENT_DEMANDS", "L") ? <TabPane tabId="7">
                            <ShowDetails id={this.state.curClientId} type="clientDemands" data={this.state.curClient} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.clientModels ? this.clientModels["DEMANDS"] : {}} modelsList={this.clientModels} shouldRender={this.state.activeTab === '7'} />
                        </TabPane> : ""}
                        {this.props.checkRights("CLIENT_CONTACTS", "L") ? <TabPane tabId="3">
                            <ShowDetails id={this.state.curClientId} type="clientContacts" data={this.state.curClient} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.clientModels ? this.clientModels["CONTACTS"] : {}} modelsList={this.clientModels} shouldRender={this.state.activeTab === '3'} />
                        </TabPane> : ""}
                        {this.props.checkRights("CLIENT_FILES", "L") ? <TabPane id="aupdAct-3" tabId="4">
                            <Form id="frmaupdact3">
                                <FilesCustom className="client-files" bindID={this.state.curClientId} rightType="CLIENT_FILES" checkRights={this.props.checkRights} fileTypeID="CLIENT" src={this.state.curClient} srcName={this.state.curClient ? this.state.curClient.name : ""} withFilters={true} shouldRender={this.state.activeTab === '4'} altFilesTypes="../api/client/GetClientFilesType" altFilesUrl="../api/client/GetClientFiles" altRemoveFilesUrl="../api/client/RemoveClientFile" altUploadFilesUrl="../api/client/UploadClientFiles" />
                            </Form>
                        </TabPane> : ""}
                        {this.props.checkRights("CLIENT_EVENTS", "L") ? <TabPane tabId="5">
                            <ShowDetails id={this.state.curClientId} type="clientItvs" data={this.state.curClient} dataCfg={this.props.dataCfg} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} noButtons configModel={this.clientModels ? this.clientModels["ITVS"] : {}} modelsList={this.clientModels} loadElem={this.loadClient.bind(this)} shouldRender={this.state.activeTab === '5'} />
                        </TabPane> : ""}
                        {this.props.checkRights("CLIENT_FLOWS", "L") ? <TabPane tabId="6">
                            <Row className="client-history">
                                <Col lg="12">
                                    <Card className="widget dash">
                                        <CardHeader>
                                            <CardTitle tag="h6">{context.props.i18nManager.t("client.flowsTitle")}</CardTitle>
                                            <div className="widget-tools collapse-tool">
                                                <i className="fa fa-history fa-4x"></i>
                                            </div>
                                            <div className='borderTheme'></div>
                                        </CardHeader>
                                        <CardBody>
                                            <Historical type="client" elemId={context.state.curClientId} url="../api/client/GetClientFlows" getRoutes={context.props.getRoutes} shouldRender={context.state.activeTab === '6'} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane> : ""}
                    </TabContent></div>) : ""}
            </div>
        );
    }

    async getClients(statusId, forcedUpdate, callback) {
        if (!forcedUpdate && this.state.editionMode) return;
        this.setState({ clients: [], curClientId: 0, curClient: null/*, loading: true*/, dataStamp: 0, isShowDetails: false, editionMode: false });
        var context = this;

        if (callback) {
            context.refreshCb = callback;
        }

        Swal.fire({
            title: context.props.i18nManager.t("gal_loading"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var args = {};
        args.state = statusId || this.state.curStatus || 900;

        commonFunc.getMethod("../api/client/GetClients", args).then((response) => {
            if (response.data) {
                if (response.data.isSuccess) {

                    /*$('#lstActs thead th').each(function () {
                        var title = $(this).text();
                        if ($(this).hasClass("searchable")) {
                            $(this).append('<input type="text" class="form-control col-lg-12 form-control-sm" placeholder="' + title + '" />');
                        }
                    });*/

                    Swal.close();
                    context.setState({ clients: response.data.result, loading: false, dataStamp: new Date().getTime(), isShowDetails: false });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_error"),
                        html: context.props.i18nManager.t("client.failLoadClients") + " :<br>" + response.data.errorMessage
                    });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_error"),
                    html: context.props.i18nManager.t("client.failLoadClients")
                });
            }
        });

    }

    loadStatusBtnGrp(defaultID) {
        var context = this;

        commonFunc.getMethod("../api/client/GetStatus", null).then((response) => {
            var data = response.data;

            //Libelle = name + ForeColor = foreColor + Opacite >> removed + Niveau = step + Categ = categ + Actif = isActive + Planifiable = isSchedulable + css + valeur = value
            if (data.isSuccess) {

                context.setState({ status: data.result, defaultStatus: defaultID });

            } else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_fail"),
                    html: context.props.i18nManager.t("gal_failLoadStatus") + "<br/>" + response.data.errorMessage
                });
            }

        });
    }

    async loadClient(id) {
        var context = this;

        var callback = this.loadReportCb;
        this.loadReportCb = null;

        this.setState({ title: context.props.i18nManager.t("client.noSelected"), curClientId: 0, curClient: null, isShowDetails: (id > 0), editionMode: false });

        return new Promise((resolve, reject) => {

            var args = {};
            //args.state = 201;
            args.id = id;
            if (parseInt(id) > 0) {
                Swal.fire({
                    title: context.props.i18nManager.t("gal_loading"),
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                commonFunc.getMethod("../api/client/GetClient", args).then((response) => {
                    var data = response.data;
                    if (data.isSuccess) {
                        console.log(data.result);

                        if (callback && typeof (callback) === "function") { callback(data); }

                        Swal.close();

                        if (context.state.isShowDetails) context.setState({ title: ("[" + (data.result.category ? data.result.category.name : "") + "] " + data.result.name + " " + data.result.firstName), curClientId: id, curClient: data.result, isShowDetails: true });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: context.props.i18nManager.t("gal_error"),
                            html: context.props.i18nManager.t("client.failLoadClient") + " :<br>" + data.errorMessage
                        });
                    }
                    resolve(data);
                });

            }
            else {
                resolve({ isSuccess: true, result: "" });
            }
        });
    }

    async saveClient(userClient, isUpdate) {
        var context = this;
        const dataClient = this.state.curClient || {};

        Swal.fire({
            title: context.props.i18nManager.t("gal_saving"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });


        if (!userClient.avoidMerge) {
            userClient = commonFunc.mergeDeep({}, dataClient, userClient);

            /*userSite.contacts = null;
            userSite.agreements = null;
            userSite.clientFiles = null;
            userSite.clientParents = null;
            userSite.demands = null;
            userSite.flows = null;
            userSite.matchesAssigned = null;
            userSite.models = null;*/
            userClient.accountUser = null;
            userClient.billingFirm = null;
            userClient.category = null;
            userClient.parent = null;
            userClient.group = null;
            userClient.site = null;
            userClient.status = null;
            userClient.subStatus = null;
            userClient.tenantUserAccess = null;
        }

        console.debug(userClient);
        //return new Promise((resolve, reject) => { resolve({ isSuccess: false }); });//TEMP
        return commonFunc.postMethod(isUpdate ? "../api/client/UpdateClient" : "../api/client/CreateClient", userClient).then((response) => {
            Swal.close();
            return new Promise((resolve, reject) => {
                var data = response.data;
                if (data && data.isSuccess) {
                    Swal.fire({
                        icon: "success",
                        title: context.props.i18nManager.t("gal_success"),
                        html: (isUpdate ? context.props.i18nManager.t("gal_successUpd") : context.props.i18nManager.t("gal_successAdd")),
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        /*context.getClients(0, true, () => {
                            showDtSelID("tableClients", data.result.idClient);
                        });*/
                        context.loadClient(data.result.idClient);//userClient.idClient
                    });

                    data.result.idResponse = data.result.idClient;
                    resolve(data.result);

                } else {
                    var supErrors = "";
                    if (data.errors && data.errors.length) {
                        supErrors = data.errors.map((error) => {
                            return "<br>" + error.desc + "(" + error.value + ")";
                        });
                    }
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_errorSave") + " :<br>" + data.errorMessage + supErrors
                    });
                    resolve(data);
                }
            });
        });
    }

    removeClient(id, title) {
        var context = this;
        return new Promise((resolve, reject) => {
            var row = context.state.curClient;
            if ((!id && row) || (id && row && id === row.idClient)) {
                id = id || row.idClient;
                title = row.name + " " + row.firstName;
            }
            if (parseInt(id) > 0) {
                Swal.fire({
                    icon: "question",
                    title: context.props.i18nManager.t("gal_removing"),
                    html: context.props.i18nManager.t("client.rmvTitle") + "<br/>[" + id + "] " + title,
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#dc3545",
                    confirmButtonText: context.props.i18nManager.t("gal_yes"),
                    cancelButtonText: context.props.i18nManager.t("gal_no"),
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: context.props.i18nManager.t("client.removing"),
                            didOpen: () => { Swal.showLoading() }
                        });
                        var req = { id: id, isPermanent: false };
                        commonFunc.postMethod("../api/client/RemoveClient", req).then((response) => {
                            Swal.close();
                            var data = response.data;
                            if (data && data.isSuccess) {
                                context.getClients();//TODO : check si besoin de refresh supplémentaire
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: context.props.i18nManager.t("gal_fail"),
                                    html: context.props.i18nManager.t("gal_errorRmv") + "<br/>" + response.data.errorMessage
                                });
                            }
                            resolve(data);
                        });

                    }
                    else {
                        resolve({ isSuccess: true, result: "" });
                    }
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_fail"),
                    html: context.props.i18nManager.t("gal_noSelectedElem")
                });
                resolve({ isSuccess: false, result: context.props.i18nManager.t("gal_noSelectedElem") });
            }
        });
    }


    updateClientStatus(statusId, stepId, customLabel) {
        var context = this;
        return new Promise((resolve, reject) => {
            var id = context.state.curClientId;
            if (parseInt(id) > 0) {
                if (statusId > 0) {
                    var label = "";
                    var reloadClient = true;

                    var curStatus = context.state.status.filter((status) => status.idStatus === statusId);
                    label = customLabel || (curStatus.length ? curStatus[0].name : "");


                    Swal.fire({
                        icon: "question",
                        title: context.props.i18nManager.t("client.updStatus"),
                        html: context.props.i18nManager.t("client.statusAs", [label]),
                        showCancelButton: true,
                        confirmButtonColor: "#28a745",
                        cancelButtonColor: "#dc3545",
                        confirmButtonText: context.props.i18nManager.t("gal_yes"),
                        cancelButtonText: context.props.i18nManager.t("gal_no"),
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: context.props.i18nManager.t("client.updatingStatus"),
                                didOpen: () => { Swal.showLoading() },
                            });
                            var req = { id: id, newStatus: statusId, newStep: stepId || -1 };

                            commonFunc.postMethod("../api/client/UpdateStatus", req).then((response) => {

                                Swal.close();
                                if (response.data && response.data.isSuccess) {
                                    /*context.getClients(0, true, () => {
                                        if (reloadClient) showDtSelID("tableClients", id);
                                    });*///TODO : check si besoin de refresh supplémentaire
                                    if (reloadClient) context.loadClient(id);
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: context.props.i18nManager.t("gal_fail"),
                                        html: context.props.i18nManager.t("gal_failUpdStatus") + "<br/>" + response.data.errorMessage
                                    });
                                }
                                resolve(response.data);
                            });
                        }
                        else {
                            resolve({ isSuccess: true, result: "" });
                        }
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: context.props.i18nManager.t("gal_fail"),
                        html: context.props.i18nManager.t("gal_invalidStatus")
                    });
                    resolve({ isSuccess: false, result: context.props.i18nManager.t("gal_invalidStatus") });
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: context.props.i18nManager.t("gal_fail"),
                    html: context.props.i18nManager.t("gal_noSelectedElem")
                });
                resolve({ isSuccess: false, result: context.props.i18nManager.t("gal_noSelectedElem") });
            }
        });
    }

    initCreateClient() {
        if (this.state.editionMode) return;
        setDtNoSelect("tableClients");
        //this.clientGen.current.resetForm();
        this.setState({ title: this.props.i18nManager.t("client.create"), curClientId: 0, curClient: null, isShowDetails: false, editionMode: true, activeTab: "1" });
    }

    switchTab(numTab) {
        if (this.state.editionMode) return;
        this.setState({ activeTab: numTab })
    }

    setEditingMode(value, isDuplicate) {
        var title = this.state.curClient ? (this.state.curClient.name + " " + this.state.curClient.firstName) : "";
        this.setState({ title: (isDuplicate ? this.props.i18nManager.t("client.duplicate") : this.props.i18nManager.t("client.edit")) + " - " + title, editionMode: value });
    }
}

const withI18n = (Component) => {
    return props => {
        const i18n = useTranslation();
        return <Component {...props} i18nManager={{ ...i18n }} />
    }
}

export default withRouter(withI18n(ManageClients));