import React, { Component } from 'react';

import Swal from "sweetalert2";

import {
    Button, Input, Label,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form
} from "reactstrap";

import ShowDetails from 'components/ShowDetails';
import AGenerateModel from 'components/generic/AGenerateModel';

import * as commonFunc from 'scripts/common';

import { withRouter } from 'components/withRouter';

class ManageParameters extends Component {
    static displayName = ManageParameters.name;

    static refreshCb;
    static modalRoot;

    constructor(props) {
        super(props);

        this.paramGen = React.createRef(null);

        this.state = { paramModels: null, activeTab: "1", title: "PARAMÈTRES" };
    }

    componentDidMount() {
        //console.debug("MOUNT");

        var context = this;

        if (this.props.updateUser) this.props.updateUser(function (curUser) {
            
            commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: "PARAMS" }).then((responseModels) => {
                var dataModels = responseModels.data;
                if (dataModels.isSuccess) {

                    var paramModels = null;
                    dataModels.result.models.forEach((model) => {
                        if (!paramModels) paramModels = {};
                        paramModels[model.extID] = JSON.parse(model.value);
                    });
                    context.setState({ paramModels: paramModels });

                    //Chargement via url
                    var defParamLoadId = 0;
                    var defParamActionId = 0;
                    if (context.props.searchParams) {
                        defParamLoadId = parseInt(context.props.searchParams.get("paramId")) || 0;
                        defParamActionId = parseInt(context.props.searchParams.get("actionId")) || 0;
                    }
                    if (defParamLoadId > 0) {
                        var defParamStatusId = parseInt(context.props.searchParams.get("statusId")) || 1100;

                        //TODO : onglet par défaut etc...
                    } else {
                        //TODO : onglet par défaut etc...

                        /*if (defParamActionId == 1) {
                            $(".action-addReport:visible").click();
                        }*/
                    }

                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Echec",
                        html: "Echec de chargement des modèles paramètrages :<br>" + dataModels.errorMessage
                    });
                }
            });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        //console.debug("UPDATE");
    }

    componentWillUnmount() {
        //console.debug("unmount");
    }

    render() {
        //console.debug("render again");

        return (
            <div className="tableDetails">
                {this.state.paramModels ? <div className="detailsContainer">
                    <Card className="widget dash text-white" color="theme">
                        <CardHeader>
                            <Row>
                                <Col lg="10" className="offset-lg-1">
                                    <CardTitle tag="h6" className="text-center">
                                        {this.state.title}
                                    </CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Nav pills>
                                {this.props.checkRights("PARAMS", "R") ? <NavItem><NavLink className={`${this.state.activeTab === '1' ? "active" : ""}`} onClick={() => { this.switchTab("1") }}>Paramètres Généraux</NavLink></NavItem> : ""}
                                {this.props.checkRights("SERVICE", "U") ? <NavItem><NavLink className={`${this.state.activeTab === '2' ? "active" : ""}`} onClick={() => { this.switchTab("2") }}>Services</NavLink></NavItem> : ""}
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.props.checkRights("PARAMS", "R") ? <TabPane tabId="1">
                            {this.state.activeTab === '1' ? <AGenerateModel ref={this.paramGen} noButtons modelsList={this.state.paramModels} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} configModel={this.state.paramModels ? this.state.paramModels["PARAMS"] : {}} /> : ""}
                        </TabPane> : ""}
                        {this.props.checkRights("SERVICE", "U") ? <TabPane tabId="2">
                            {this.state.activeTab === '2' ? <AGenerateModel ref={this.paramGen} noButtons modelsList={this.state.paramModels} appProps={this.props} checkRights={this.props.checkRights} getRoutes={this.props.getRoutes} configModel={this.state.paramModels ? this.state.paramModels["SERVICES"] : {}} /> : ""}
                        </TabPane> : ""}
                    </TabContent>
                </div> : <p><em>Chargement...</em></p>}
            </div>
        );
    }

    switchTab(numTab) {
        this.setState({ activeTab: numTab })
    }
}


/* SERVICES
<Col sm="12" md="6" xl="3" className="p-xl-0">
                        <h6>Secteur d'activité</h6>
                        <Select
                            name="activitySelection"
                            defaultValue={currActivity}
                            options={activities}
                            className={`basic-multi-select mb-3 ${!currActivityInvalid ? "" : "is-invalid"}`}
                            classNamePrefix="select"
                            placeholder="Veuillez sélectionner un secteur d'activité"
                            onChange={(value) => { updateActivity(value); }}
                        />

                        <h6>Sélection des services/modèles d'Intervention</h6>

                        {(groupedServices && Object.keys(groupedServices).length) ? Object.keys(groupedServices).map((parentSvcKey, index) => {
                            var childServices = groupedServices[parentSvcKey];
                            var parentSvc = childServices[0].parent;

                            return <div key={"parentSvc-" + parentSvcKey} className="selectmodel-parentsvc">
                                {parentSvc ? <Label className="fw-bold mt-2 selectmodel-parentlabel">{parentSvc.name}</Label> : ""}
                                {childServices.map((service, index) => {
                                    var modelsFiltered = service.templates && service.templates.length > 0 ? models.filter((model) => {
                                        return service.templates.filter((template) => {
                                            if (template.extID === model.extID) model.template = template;
                                            return template.extID === model.extID;
                                        }).length > 0;
                                    }) : [];

                                    var htmlModels = modelsFiltered.filter(model => model.isHtml).map((model, indexModel) => { return { value: model.idModel, label: model.title } });
                                    var jsonModels = modelsFiltered.filter(model => !model.isHtml).map((model, indexModel) => { return { value: model.idModel, label: model.title } });

                                    var checked = service.isActive;

                                    return <div key={"svc" + service.idService}>

                                        <div className={`form-check form-check-inline fcheck-sm me-2`}>
                                            <Label check for={"modalSelection-" + index} className={`d-inline-block px-0`} style={{ minWidth: "16px" }}>
                                                <div className={`d-inline-block`}>
                                                    <Input type="checkbox" id={"modalSelection-" + index} checked={checked} onChange={(e) => { updateServiceChecked(service.idService, e.target.checked); }} />
                                                    <span className={`form-check-sign`} />
                                                </div>
                                            </Label>
                                        </div>
                                        <Input type="text" className={`w-auto d-inline-block ${!commonFunc.isBlank(service.name) ? "" : "is-invalid"}`} bsSize={"sm"} autoComplete="no" aria-autocomplete="none" defaultValue={service.name} onChange={(event) => { updateServiceName(service.idService, event.target.value); }} />
                                        <Input type="color" className="d-inline-block" autoComplete="no" aria-autocomplete="none" defaultValue={service.startColor} onChange={(event) => { updateServiceColor(service.idService, event.target.value); }} style={{ width: "50px" }} />

                                        {(htmlModels.length || jsonModels.length) ? <div className="w-100">
                                            {
<>
    {htmlModels.length ? <div className="selectmodel-service"><Label>Rapport</Label><Select
        name="modelHtmlSelection"
        defaultValue={htmlModels[0]}
        options={htmlModels}
        className={`basic-multi-select`}
        classNamePrefix="select"
        onChange={(value) => { updateModel(value); }}
    /><Button className="btn-icon" size="sm" color="primary" onClick={() => { showModelPreview(null, modelsFiltered.filter(model => model.isHtml)); }}><i className="fa fa-eye"></i></Button></div> : ""}
    {jsonModels.length ? <div className="selectmodel-service"><Label>Appli.</Label><Select
        name="modelJsonSelection"
        defaultValue={jsonModels[0]}
        options={jsonModels}
        className={`basic-multi-select`}
        classNamePrefix="select"
        onChange={(value) => { updateModel(value); }}
    /><Button className="btn-icon" size="sm" color="primary" onClick={() => { showModelPreview(null, modelsFiltered.filter(model => !model.isHtml)); }}><i className="fa fa-eye"></i></Button></div> : ""}
</>
                                            }
                                        </div > : ""}
                                    </div >;
                                })}
                            </div >
                        }) : "Aucun services paramètrable"}
                    </Col >

*/


export default withRouter(ManageParameters);
