/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
} from "reactstrap";

import Swal from "sweetalert2";

import * as commonFunc from 'scripts/common';

function Auth2(props) {
    console.debug(props);
    console.log(props);

    const { state } = useLocation();
    const { login } = state; // Read values passed on state


    React.useEffect(() => {
        document.body.classList.toggle("login-page");
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };
    });

    const navigate = useNavigate();



    const [segments, setSegments] = React.useState([""]);


    //Initialisation Axios 2Auth
    var token = sessionStorage.getItem('token2');
    if (token != null && login != null) {
        //axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
    else {
        Swal.fire({
            icon: "error",
            title: "Erreur",
            html: "Accès impossible sans authentification au préalable"
        }).then((result) => {
            navigate("../login");
        });
        return "";
    }



    function updateCode(event, index, value) {
        if (event.nativeEvent.inputType !== "insertFromPaste") {
            var currCode = segments;
            currCode[index] = value;

            setSegments(currCode);
        }
    }

    function onPaste(event) {
        const pasted = event.clipboardData.getData("text/plain");
        //setSegments(pasted.split("").slice(0, segments.length));
        //setSegments([pasted]);
        setSegments([pasted.split(' ').join('')]);
    }

    function sendNewCode() {
        //TODO
        alert("TODO : envoyer nouveau code");

    }

    async function handleSubmit(e) {
        e.preventDefault();
        console.debug(e);

        Swal.fire({
            title: "Connexion en cours...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });


        //var req = { code: segments.join("") };
        login.AccessCode = segments.join("");

        commonFunc.postMethod("../api/logintwostep", login).then((response) => {
            console.debug("RESPONSE LOGIN2AUTH");
            console.debug(response);

            if (response.data.isSuccess) {
                let result = response.data.result;


                // Récupération du token JWT depuis le localStorage
                // const token = localStorage.getItem('token');

                sessionStorage.removeItem('token2');

                // Inclusion du token JWT dans les en-têtes de la requête
                var token = result.accessToken;
                localStorage.setItem('token', token);
                axios.defaults.headers['Authorization'] = `Bearer ${token}`;

                // Faites votre appel API avec axios ou un autre client HTTP
                Swal.close();
                Swal.fire({
                    title: "Récupération en cours...",
                    didOpen: () => { Swal.showLoading() },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });

                commonFunc.getMethod("../api/GetUser", null).then((response) => {
                    console.debug("RESPONSE GetUser");
                    console.debug(response);

                    if (response.data) {
                        if (response.data.isSuccess) {
                            Swal.close();
                            props.setLoggedStatus(true);
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de la récupération de l'utilisateur :<br>" + response.data.errorMessage
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur lors de la récupération de l'utilisateur"
                        });
                    }
                });
            } else {
                console.debug("LOGIN ERR");
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Erreur lors de l'authentification :<br> " + response.data.errorMessage
                });
            }

        });
    }

    console.debug("render");


    return (
        <div className="login-page">
            <Container>
                <Row>
                    <Col className="ms-auto me-auto" lg="4" md="6">
                        <Card className="card-login text-center">
                            <Form action="" className="form" method="" onSubmit={handleSubmit}>
                                <CardHeader>
                                    <CardTitle className='header' tag="h3">Vérification en 2 étape</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {segments.map((s, key) =>
                                            <Col lg="12" md="12" key={parseInt(Math.random() * 10000000)}>
                                                <Input type="text" className="border-secondary p-2 h5 text-center" defaultValue={s} onPaste={onPaste}
                                                    onChange={(event) => { updateCode(event, key, event.target.value) }} />
                                            </Col>
                                        )}
                                    </Row>
                                    <br />
                                    <div className="text-end">
                                        Vous ne l'avez pas reçu ?
                                        <Link className="link-secondary ms-1" onClick={sendNewCode}>
                                            Renvoyer un nouveau code
                                        </Link>
                                    </div>

                                </CardBody>
                                <CardFooter>
                                    <Button
                                        block
                                        className="btn-round mb-3"
                                        color="warning"
                                    >
                                        Soumettre le code
                                    </Button>
                                </CardFooter>
                            </Form>
                        </Card>

                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("assets/img/bg/backblurt1.jpg")})`,
                }}
            />
        </div >

    );
}

export default Auth2;
