import React from "react";
import { createRoot } from 'react-dom/client';
import {  useNavigate, useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";


// reactstrap components
import {
    Card, CardHeader, CardBody, CardTitle, FormGroup, CardFooter,
    Container,
    Row,
    Col,
    Label,
    Button, Form, Input, Badge,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import Swal from "sweetalert2";

import * as commonFunc from 'scripts/common';

import AGenerateModel from 'components/generic/AGenerateModel';


function Register(props) {
    const { t } = useTranslation();

    const [payment, setPayment] = React.useState("");
    const [paymentUrl, setPaymentUrl] = React.useState("#");

    const [activeSubscription, setActiveSubscription] = React.useState({});
    const [currentProduct, setCurrentProduct] = React.useState();
    const [monthNb, setMonthNb] = React.useState(1);
    const [paymentMode, setPaymentMode] = React.useState(1);
    const [invoice, setInvoice] = React.useState({});
    const [cgvChecked, setCgvChecked] = React.useState(false);
    const [addressError, setAddressError] = React.useState(false);
    const [cgvError, setCgvError] = React.useState(false);
    const [productUrlInfos, setProductUrlInfos] = useSearchParams();

    const redirectFormSubmit = React.createRef(0);
    const redirectForm = React.createRef(0);


    const [models, setModels] = React.useState([]);
    const modalGenManager = React.createRef(0);

    /*function verifyCallback(response) {
        if (response) {
            console.log("g-recaptcha-response verifyCallback Success ");
            var userCaptcha = {};
            userCaptcha.captchaID = response;
            userCaptcha.captchaType = "Google";
            userCaptcha.captchaKey = "g-recaptcha-response";
            userCaptcha.captchaID = response;
            var ctrl = $("#gCaptcha");
            ctrl.removeData("response");
            ctrl.data("response", userCaptcha);
            ctrl.removeClass("error").addClass("success");
            $("#submitAccount").tooltip('hide');
        } else {
            console.error("g-recaptcha-response verifyCallback Failure");
        }
    };

    function expiredCallback(response) {
        console.log("Google Captcha expiredCallback");
        $("#gCaptcha").removeData("response");
        $("#gCaptcha").removeClass("success").addClass("error");
    }*/

    React.useEffect(() => {
        initSubscription();
    }, []);

    React.useEffect(() => {
        if (paymentUrl != "#" && redirectFormSubmit && redirectFormSubmit.current) { redirectFormSubmit.current.click(); }
    }, [paymentUrl]);

    const navigate = useNavigate();

    function initSubscription() {
        Swal.fire({
            title: "Chargement en cours...   ",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        commonFunc.getMethod("../api/GetActiveSubscription", { level: 2 }).then((response) => {
            var data = response.data;
            if (data.isSuccess) {

                setActiveSubscription(data.result);

                var productId = parseInt(productUrlInfos.get("productId")) || 10;//10 >> Correspond à la version trial
                commonFunc.getMethod("../api/GetProductOffers", { id: productId }).then((response) => {
                    if (response.data) {
                        if (response.data.isSuccess) {
                            Swal.close();

                            if (response.data.result && response.data.result.length) {
                                var product = response.data.result[0];

                                if (product && product.productCycle && product.productCycle.categ === "M1") {
                                    setCurrentProduct(product);
                                }
                                else {
                                    setCurrentProduct(product);
                                    //subscribeToOffer(null, product);
                                }
                                updateInvoiceInfos(1, product);
                            }
                        }
                        else {
                            Swal.fire({
                                icon: "error",
                                title: "Erreur",
                                html: "Erreur lors de la récupération de l'offre sélectionné :<br>" + response.data.errorMessage
                            });
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Erreur lors de la récupération de l'offre sélectionné"
                        });
                    }
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de chargement de l'abonnement :<br>" + data.errorMessage
                });
            }
        });
    }


    function updateInvoiceInfos(curMonthNb, product) {
        if (!product) product = currentProduct;

        Swal.fire({
            title: "Chargement en cours...   ",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        var invoiceItem = { productID: product.idProduct, quantity: curMonthNb, cycleID: product.productCycle.idCycle, isBillable: false };
        invoiceItem.title = product.name;
        invoiceItem.refProd = product.categ;

        commonFunc.postMethod("../api/GenerateInvoiceFromSubscription", invoiceItem).then((response) => {
            var data = response.data;
            if (data.isSuccess) {

                setInvoice(data.result || 0);

                Swal.close();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de chargement de les abonnements :<br>" + data.errorMessage
                });
            }
        });
    }

    function getModels() {
        var localModels = models;
        if (localModels.length > 0) {
            return new Promise((resolve, reject) => {
                resolve(localModels);
            });
        }
        else {
            Swal.fire({
                title: "Chargement en cours...   ",
                didOpen: () => { Swal.showLoading() },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            return commonFunc.getMethod("../api/site/GetSiteModels", { id: -1, categ: "SITES" }).then((responseModels) => {
                return new Promise((resolve, reject) => {
                    var data = responseModels.data;
                    if (data && data.isSuccess) {
                        console.debug(data.result);
                        localModels = data.result.models;

                        setModels(localModels);

                        Swal.close();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            html: "Echec de récupération des modèles :<br>" + responseModels.data.errorMessage
                        });
                    }

                    resolve(localModels);
                });
            });
        }
    }

    function updateSiteAddress() {
        setAddressError(false);

        var title = "Modification Compte Client";

        if (window.modalContainer) {
            window.modalContainer.unmount();
            window.modalContainer = null;
        }

        //Traitement des informations retourné par le Dialog
        var callbackModal = function (data, isUpdate) {
            return new Promise((resolve, reject) => {
                if (data) {
                    console.debug(data);

                    saveCustomer(data, isUpdate);

                    window.modalContainer.unmount();
                    resolve();
                }
                else {
                    window.modalContainer.unmount();
                    resolve();
                }
            });
        };

        var parentContext = { props: props };
        var srcObject = activeSubscription.customer;

        getModels().then((result) => {
            var siteModel = result.filter((model) => model.extID === "FAST_CUSTOMER");
            if (siteModel && siteModel.length) {
                window.modalContainer = createRoot(document.createElement('div'));
                window.modalContainer.render(<SiteModal parentContext={parentContext} modalGenManager={modalGenManager} title={title} configModel={JSON.parse(siteModel[0].value)} modelsList={null} dataSite={srcObject} callback={callbackModal}></SiteModal>);
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    html: "Configuration introuvable"
                });
            }
        });
    }

    function saveCustomer(userClient, isUpdate) {
        Swal.fire({
            title: t("gal_saving"),
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        if (!userClient.avoidMerge) {
            userClient = commonFunc.mergeDeep({}, activeSubscription.customer, userClient);

            /*userClient.clientFiles = null;
            userClient.clientParents = null;
            userClient.contacts = null;
            userClient.agreements = null;
            userClient.demands = null;
            userClient.flows = null;
            userClient.matchesAssigned = null;
            userClient.models = null;*/
        }

        console.debug(userClient);
        //return new Promise((resolve, reject) => { resolve({ isSuccess: false }); });//TEMP
        return commonFunc.postMethod(isUpdate ? "../api/UpdateSubscriptionCustomer" : "../api/CreateSubscriptionCustomer", userClient).then((response) => {
            Swal.close();
            return new Promise((resolve, reject) => {
                var data = response.data;
                if (data && data.isSuccess) {
                    Swal.fire({
                        icon: "success",
                        title: t("gal_success"),
                        html: (isUpdate ? t("gal_successUpd") : t("gal_successAdd")),
                        didOpen: () => { Swal.hideLoading() },
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        initSubscription();
                    });

                    if (data.result) data.result.idResponse = data.result.idClient;
                    resolve(data.result);

                } else {
                    var supErrors = "";
                    if (data.errors && data.errors.length) {
                        supErrors = data.errors.map((error) => {
                            return "<br>" + error.desc + "(" + error.value + ")";
                        });
                    }
                    Swal.fire({
                        icon: "error",
                        title: t("gal_fail"),
                        html: t("gal_errorSave") + " :<br>" + data.errorMessage + supErrors
                    });
                    resolve(data);
                }
            });
        });
    }


    function subscribeToOffer(e, product) {
        var provider = "";
        var fingerprint = "";


        if (!activeSubscription.customer || !activeSubscription.customer.billingAddress || activeSubscription.customer.billingAddressID <= 0 /*|| commonFunc.isBlank(activeSubscription.customer.billingAddress.addressMain)
            || commonFunc.isBlank(activeSubscription.customer.billingAddress.city) || activeSubscription.customer.billingAddress.pc === 0*/) {
            Swal.fire({
                icon: 'warning',
                position: "top",
                title: "Informations manquantes",
                html: "Veuillez renseigner une adresse de facturation afin de souscrire à l'offre",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            setAddressError(true);
            return;
        }

        if (!cgvChecked) {
            Swal.fire({
                icon: 'warning',
                position: "top",
                title: "Informations manquantes",
                html: "Veuillez accepter les Conditions générales de vente afin de souscrire à l'offre",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            setCgvError(true);
            return;
        }


        Swal.fire({
            title: "Souscription au produit...",
            didOpen: () => { Swal.showLoading() },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        console.debug(monthNb);

        //window.location.assign('../ExternalLogin/RedirectToPaymentBackend?test=' + provider + '&deviceId=' + fingerprint);
        //return;

        var invoiceItem = { productID: product.idProduct, quantity: monthNb, cycleID: product.productCycle.idCycle, isBillable: true };
        invoiceItem.title = product.name;
        invoiceItem.refProd = product.categ;

        invoiceItem.paymentMode = paymentMode;

        commonFunc.postMethod("../api/GenerateInvoiceFromSubscription", invoiceItem).then((response) => {
            var data = response.data;
            if (data.isSuccess) {
                console.log(data.result);//URL Stripe ???
                setPaymentUrl(data.result.redirectUrl);
                setPayment(data.result.redirectPayload);

                console.debug(redirectFormSubmit.current);
                //redirectFormSubmit.current.click();
                Swal.close();
                //window.location.assign('../AccessStripe/AccessStripe?provider=' + provider + '&deviceId=' + fingerprint + '&offerId=' + product.idProduct);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Echec",
                    html: "Echec de chargement de les abonnements :<br>" + data.errorMessage
                });
            }
        });
    }
    function updatePaymentMode(paymentMode) {
        setPaymentMode(paymentMode);
    }



    console.debug(currentProduct);

    return (
        <div className="register-page">
            <Container className="mt-0">
                {props.getLoggedInInfo && (activeSubscription && activeSubscription.status && activeSubscription.status.step >= 2) ? <Button className="btn-icon btn-round m-0" onClick={(e) => navigate(-1)}><i className="fa fa-arrow-left"></i></Button> : ""}
                <Row className="align-items-center">
                    <Col className="ms-auto" lg="5">
                        {(currentProduct ? <>
                            <div className="mb-5">
                                <h5 className="title mb-0 d-inline-block">Votre Offre</h5>
                                <div className="ms-3 d-inline-block">
                                    <Button className={`btn-round btn-self-magnify ${currentProduct.themeClass}`} color="primary" onClick={() => {
                                        //var win = window.open(currentProduct.url, "_blank");
                                        //win.focus();
                                        window.location.href = currentProduct.url + "?chooseProduct=1&srcUrl=" + window.location.href + "&destUrl=" + window.location.origin + "/ConfirmSubscription";
                                    }}>{t("register_checkOffers")}</Button>
                                </div>
                            </div>
                            <div className="info-area info-horizontal">
                                <div className="icon icon-primary d-none">
                                    <i className="fa fa-tv" />
                                </div>
                                <div className={`subscription-${currentProduct.themeClass}`}>
                                    <h3 className="info-title m-0 mb-2">{currentProduct.name}&nbsp;{currentProduct.description}</h3>
                                    <h6 className="info-title">{currentProduct.productCycle ? currentProduct.productCycle.name : ""}</h6>
                                    <Card style={{ maxWidth: "200px" }}>
                                        <img
                                            className="img-fluid subscription-img"
                                            alt="..."
                                            src={currentProduct.avatar || require("assets/img/bg/logooskala.png")}
                                            style={{ borderRadius: "12px" }}
                                        />
                                    </Card>
                                    <p className="description">{currentProduct.descriptionAlt}</p>
                                </div>
                            </div>
                        </>
                            : <Button className={`btn-round btn-self-magnify`} color="primary" onClick={() => {
                                //var win = window.open(currentProduct.url, "_blank");
                                //win.focus();
                                window.location.href = activeSubscription.product.url + "?chooseProduct=1&srcUrl=" + window.location.href + "&destUrl=" + window.location.origin + "/ConfirmSubscription";
                            }}>{t("register_checkOffers")}</Button>)}

                        <Card className={`card-signup text-center ${addressError ? "border-danger" : ""}`}>

                            <div className="px-2">

                                <div className="text-start p-3">
                                    <h5 className="title mb-2">Informations Facturation</h5>

                                    <div className="float-start">
                                        <h6 className="title mb-2">{activeSubscription.customer ? (activeSubscription.customer.name + " " + activeSubscription.customer.firstName) : ""}</h6>
                                        <div><Badge className={`w-auto`} color="secondary">{activeSubscription.customer ? activeSubscription.customer.email : ""}</Badge></div>
                                        <div>Téléphone : {activeSubscription.customer ? ((activeSubscription.customer.tel || activeSubscription.customer.gsm) ? (activeSubscription.customer.tel || activeSubscription.customer.gsm) : "Non renseigné") : "Non renseigné"}</div>
                                    </div>
                                    <Button className="btn-round m-0 float-end mb-2" size="sm" color="primary" onClick={() => updateSiteAddress()}>Modifier</Button>
                                    <hr className="hr-line-solid" style={{ clear: "both" }} />
                                    <div>{activeSubscription.customer && activeSubscription.customer.billingAddress ? (<>
                                        <b>Adresse de facturation</b><div className="d-inline-block ms-1 mb-1"><span className="mandatory"></span></div><br />
                                        {!commonFunc.isBlank(activeSubscription.customer.billingAddress.addressMain) ? (<>{activeSubscription.customer.billingAddress.addressMain}<br /></>) : ""}
                                        {!commonFunc.isBlank(activeSubscription.customer.billingAddress.addressPlus) ? (<>{activeSubscription.customer.billingAddress.addressPlus}<br /></>) : ""}
                                        {!commonFunc.isBlank(activeSubscription.customer.billingAddress.addressPlus2) ? (<>{activeSubscription.customer.billingAddress.addressPlus2}<br /></>) : ""}
                                        {activeSubscription.customer.billingAddress.pc + " " + activeSubscription.customer.billingAddress.city}
                                    </>) : ""}</div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col className={`mx-auto ${"animate__animated animate__bounceInDown"}`} lg="5" md="12">
                        <Card className={`card-signup text-center`}>
                            <CardHeader className="mb-2 pt-2" style={{ backgroundColor:"#00000008", borderBottom: "1px solid rgba(0, 0, 0, .125)" }}><h3 className="mb-1">Paiement</h3></CardHeader>

                            <div className="px-2">
                                <Row className={`justify-content-center ${currentProduct && currentProduct.productCycle && currentProduct.productCycle.categ === "M1" ? "" : "d-none"}`}>
                                    <Label for="monthNb">Choix de la durée d'abonnement :</Label>
                                    <Col xs="auto">
                                        <Input id="monthNb" className="w-auto" name="monthNb" type="select"
                                            defaultValue={monthNb}
                                            onChange={(e) => {
                                                var curMonthNb = parseInt(e.target.value);
                                                updateInvoiceInfos(curMonthNb);
                                                setMonthNb(curMonthNb);
                                            }}>
                                            <option value={1}>1 Mois</option>
                                            <option value={2}>2 Mois</option>
                                            <option value={3}>3 Mois</option>
                                            <option value={4}>4 Mois</option>
                                            <option value={5}>5 Mois</option>
                                            <option value={6}>6 Mois</option>
                                            <option value={7}>7 Mois</option>
                                            <option value={8}>8 Mois</option>
                                            <option value={9}>9 Mois</option>
                                            <option value={10}>10 Mois</option>
                                            <option value={11}>11 Mois</option>
                                        </Input>
                                    </Col>
                                </Row>
                                <hr className="hr-line-solid" />
                                {currentProduct && invoice ? <>
                                    <Row>
                                        <Col xs="2" />
                                        <Col xs="8">
                                            <h5 className="m-0 float-start" style={{ height: "100%", alignContent: "center" }}>Total HT :</h5>
                                            <div className="subscription-price float-end" style={{ fontSize: "1.8em" }}>{invoice.totalET || 0} €</div>
                                        </Col>
                                        <Col xs="2" />
                                        <Col xs="2" />
                                        <Col xs="8">
                                            <h5 className="m-0 float-start" style={{ height: "100%", alignContent: "center" }}>Montant TTC :</h5>
                                            <div className="subscription-price float-end" style={{fontSize:"1.8em"}}>{invoice.amount || 0} €</div>
                                        </Col>
                                        <Col xs="2" />
                                    </Row>
                                    <hr className="hr-line-solid" />

                                    {false /*(monthNb > 1 || (currentProduct && currentProduct.productCycle && currentProduct.productCycle.categ !== "M1"))*/ ? <>
                                    <h6>Mode de paiement</h6>
                                    <Row>
                                        <Col>
                                            <Card className={`model-selection widget dash p-2 ${paymentMode === 1 ? "selected" : ""}`} onClick={() => { updatePaymentMode(1) }}>
                                                <h6 className="title">Paiement en 1 fois</h6>
                                                <Row>


                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className={`model-selection widget dash p-2 ${paymentMode === 2 ? "selected" : ""}`} onClick={() => { updatePaymentMode(2) }}>
                                                <h6 className="title">Paiement en 2 fois</h6>
                                                <Row>


                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col className="hidden">
                                            <Card className={`model-selection widget dash p-2 ${paymentMode === 3 ? "selected" : ""}`} onClick={() => { updatePaymentMode(3) }}>
                                                <h6 className="title">Paiement en 3 fois</h6>
                                                <Row>


                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row></> : "" }

                                    <FormGroup check className={`fcheck-sm text-start ${cgvError ? "border border-danger rounded p-1" : ""}`}>
                                        <Label className="mandatory" check>
                                            <Input defaultChecked={false} type="checkbox" onChange={(e) => {
                                                if (e.target.checked) {
                                                    setCgvError(false);
                                                    setCgvChecked(true);
                                                } else {
                                                    setCgvChecked(false);
                                                }
                                            }} />
                                            <span className="form-check-sign" />{t("gal_acceptGts") + " "}
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                {t("gal_salesConditions")}
                                            </a>
                                            .
                                        </Label>
                                    </FormGroup>
                                    <div><Button className="btn-round btn-selected text-white py-2" size="lg" color="none" onClick={(e) => subscribeToOffer(e, currentProduct)}><i className="fa fa-circle-check me-2"></i>Souscrire</Button></div>
                                </> : ""}
                            
                                <Form
                                    className="d-none"
                                    ref={redirectForm}
                                    action={paymentUrl}
                                    method="POST"
                                >
                                    <div className="content" dangerouslySetInnerHTML={{ __html: payment }}></div>
                                    <button
                                        ref={redirectFormSubmit}
                                        className="active:bg-blue-600 hover:shadow-lg focus:outline-none px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none"
                                        type="submit"
                                    >
                                        Send a message
                                    </button>
                                </Form>
                            </div>
                        </Card>
                        <Card className={`card-signup text-center`}>
                            <CardHeader className="mb-2 pt-2" style={{ backgroundColor: "#00000008", borderBottom: "1px solid rgba(0, 0, 0, .125)" }}><h3 className="mb-1">Moyen paiement</h3></CardHeader>

                            <div className="px-2">
                                <img className="m-1 rounded" src={require("assets/img/icon/cb (1).png")} alt="CB"
                                    style={{ width: "48px" }} />
                                <img className="m-1 rounded" src={require("assets/img/icon/e-cartebleue (1).png")} alt="ECB"
                                    style={{ width: "60px" }} />
                                <img className="m-1 rounded" src={require("assets/img/icon/mastercard (2).png")} alt="MasterCard"
                                    style={{ width: "48px" }} />
                                <img className="m-1 rounded" src={require("assets/img/icon/visa (1).png")} alt="Visa"
                                    style={{ width: "48px" }} />
                                <img className="m-1 rounded" src={require("assets/img/icon/visa_electron (1).png")} alt="VisaElectron"
                                    style={{ width: "48px" }} />
                            </div>
                            <div className="mt-2">
                                <img className="m-1 rounded float-end" src={require("assets/img/icon/logo-pci-black.png")} alt="PCI"
                                        style={{ width: "48px" }} />
                            </div>
                        </Card>
                        <Card className={`card-signup text-center`}>
                            <CardHeader className="mb-2 pt-2" style={{ backgroundColor: "#00000008", borderBottom: "1px solid rgba(0, 0, 0, .125)" }}><h3 className="mb-1">Besoin d'aide</h3></CardHeader>

                            <div className="px-2">
                                <div><b><i className="fa fa-circle-question me-2"></i>Vous rencontrez un problème ou avez besoin de conseil sur le choix de votre offre ?</b></div>

                                <h5 className="mt-2"><i className="fa fa-phone me-2"></i><b>02 43 49 62 27</b></h5>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("assets/img/bg/backblur22.jpg")})`,
                }}
            />
        </div>
    );
}

export default Register;



//Création d'un "function component" pour gérer l'affichage du Dialog
//props correspond aux attributs fournies lors de la déclaration de ReportModal
function SiteModal({ title, parentContext, modalGenManager, noScroll, configModel, modelsList, dataSite, callback }) {
    React.useEffect(() => {
        //console.debug("mount");

        //TODO : Check différence draft/ManualReport

        //if (parentContext.props.dataCfg && parentContext.props.dataCfg.dlgSup_enabledMClient) initClientList(null, 0);//TODO
        initSiteModal();
    }, []);



    function initSiteModal() {

    }

    //console.debug("render modal");

    configModel = JSON.parse(JSON.stringify(configModel));

    return (
        <Modal isOpen={true} className={`modal-report ${noScroll ? "modal-noscroll" : ""}`} size="xl" scrollable keyboard={false} toggle={() => { callback(); }} backdrop="static" style={{ paddingTop: "0px" }}>
            <ModalHeader className="d-flex" toggle={() => { callback(); }}>{title}</ModalHeader>
            <ModalBody className="px-2" style={{ overflowX: "hidden" }}>
                <AGenerateModel parentProps={{ type: "site", dataCfg: parentContext.props.dataCfg }} ref={modalGenManager} noButtons
                    onSubmit={(data, isUpdate) => { return callback(data, isUpdate) }} appProps={parentContext.props} checkRights={parentContext.props.checkRights} getRoutes={parentContext.props.getRoutes} configModel={configModel} modelsList={modelsList} loadedData={dataSite} />

            </ModalBody>
            <ModalFooter className="px-2 flex-nowrap">
                {
                    dataSite ? <Button className="px-3" color="success" onClick={() => {
                        var generateModel = modalGenManager.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm(true);
                        }
                    }}><i className="fa fa-edit"></i>Modifier</Button> : <Button className="px-3" color="success" onClick={() => {
                        var generateModel = modalGenManager.current;
                        if (generateModel && generateModel.submitForm) {
                            generateModel.submitForm();
                        }
                    }}><i className="fa fa-plus"></i>Ajouter</Button>
                }
                <Button className="px-3" color="danger" onClick={() => { callback(); }}><i className="fa fa-times"></i>Annuler</Button>
            </ModalFooter>
        </Modal>
    );
}