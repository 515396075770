import React, { Component } from 'react';
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';

const OffCanvas = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState();
    const [visibility, setVisibility] = React.useState(true);

    React.useImperativeHandle(ref, () => ({
        close: () => { setVisibility(true); setOpen(false) },
        manageVisibility: (visibilityExt) => { setVisibility(visibilityExt) }
    }));

    function switchOpen() {
        setVisibility(true);
        setOpen(!open);
    }

    // /!\ scrollable agit seulement comme une surcouche au backdrop
    return (<div className={`${props.className}`}>
        <div className="floating-group">
            <Button className="btn btn-simple btn-icon btn-dark" onClick={switchOpen.bind(this)}><i className={`fa ${open ? "fa-chevron-right" : "fa-chevron-left"}`}></i></Button>
        </div>
        <Offcanvas className={`${visibility ? "" : "d-none"}`} isOpen={open} direction={props.direction || "end"} backdrop={props.backdrop} scrollable={props.scrollable} onOpened={props.onOpened}>
            <OffcanvasHeader toggle={switchOpen.bind(this)}>
                {props.title || "Panneau"}
            </OffcanvasHeader>
            <OffcanvasBody>
                {props.children}
            </OffcanvasBody>
        </Offcanvas>
    </div>);
});

export default OffCanvas;