import React from 'react';
import Swal from "sweetalert2";
import moment from 'moment';

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.fr";


import DPCustom from 'components/DPCustom';

import * as commonFunc from 'scripts/common';

import {
    Input,
    Label,
    FormGroup, FormFeedback,
    InputGroup, InputGroupText, Col
} from "reactstrap";


export default function CheckboxFrag({ data, formManager, modelFctManager, bindPropResult, parentProps, parentDisabled }) {
    const { t } = useTranslation();
    const altT = (label, args) => { return (label && label.startsWith("i18n:")) ? t(label.replace("i18n:", ""), args) : label };
    const { register, getValues, setValue, errors, control } = formManager;

    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        //var val = bindPropResult ? getValues(bindPropResult) : "";
        var val = formatValue(data.defaultValue, false, true);
        if (data.onActionEvents && data.onActionEvents["onLoad"]) {
            data.onActionEvents["onLoad"].forEach((args, index) => {
                modelFctManager.onActionRequired(data.name, args, data, { value: val }).then(actionResponse => {
                    //console.debug(actionResponse);
                    if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                        Swal.fire({
                            icon: "error",
                            title: "Echec",
                            html: actionResponse.errorMessage
                        });
                    }
                });
            });
        }
    }, []);

    if(modelFctManager && modelFctManager.commonRenderProc) modelFctManager.commonRenderProc(data);

    var context = this;

    function formatValue(value, forceSet, noSet) {
        var formatValue = value;
        if (data.format) {
            data.rawValue = value;

            if (data.format === "true") {
                formatValue = (value && value !== "false");
            }
            else {
                formatValue = value ? data.format : "";
            }
            if (!noSet) setValue(bindPropResult, formatValue);
        }
        else if (forceSet) {
            setValue(bindPropResult, value);
        }
        return formatValue;
    }

    data.functionManager = {};
    data.functionManager.rerender = () => { setShouldRender(!shouldRender) };
    data.functionManager.setValue = (value, withRerender, src) => {
        return new Promise((resolve, reject) => {
            //setValue(bindPropResult, value);
            formatValue(value, true);
            if (withRerender) setShouldRender(!shouldRender);

            resolve({ isSuccess: true, result: value });
        });
    };

    const { ref: refElem, ...restElem } = register(bindPropResult, {
        value: formatValue(data.defaultValue, false, true),
        valueAsNumber: (data.subType === "integer" || data.subType === "float"),
        onChange: (e) => {
            var val = bindPropResult ? getValues(bindPropResult) : "";
            formatValue(val);

            if (data.onActionEvents && data.onActionEvents["onChange"]) {
                data.onActionEvents["onChange"].forEach((args, index) => {
                    modelFctManager.onActionRequired(data.name, args, data, { value: val }).then(actionResponse => {
                        console.debug("RESPONSE");
                        console.debug(actionResponse);
                        if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: actionResponse.errorMessage
                            });
                        }
                    });
                });
            }
            if (data.onActionEvents && data.onActionEvents["onClick"]) {
                data.onActionEvents["onClick"].forEach((args, index) => {
                    modelFctManager.onActionRequired(data.name, args, data, { value: val }).then(actionResponse => {
                        console.debug("RESPONSE");
                        console.debug(actionResponse);
                        if (actionResponse && !actionResponse.isSuccess && (!Swal.isVisible() || actionResponse.forceMessage)) {
                            Swal.fire({
                                icon: "error",
                                title: "Echec",
                                html: actionResponse.errorMessage
                            });
                        }
                    });
                });
            }
        }
    });

    var disabled = ((data.disabled ? true : false) || (!data.ignoreParentDisabled ? parentDisabled : false));

    function renderInput(widthClass) {
        //TODO : formattage chargement valeurs

        //INFO : Désactivation defaultChecked car rentrais en confusion avec le register ci-dessus
        // defaultChecked={formatValue(data.defaultValue, false, true)}
        return <div className={`d-inline-block ${widthClass}`} style={{ position: "relative", top: "-12px" }}>
            <Input type="checkbox" id={"input" + bindPropResult} placeholder={altT(data.placeHolder)} readOnly={data.readOnly} disabled={disabled}
                innerRef={refElem} {...restElem} invalid={commonFunc.getValue(errors, bindPropResult) ? true : false} />
            <span className={`form-check-sign`} />
        </div>;
    }

    function renderTitle(titleWidthClass, contentInput) {
        var design = data.customDesign ? data.customDesign.designTitle : null;
        if (!design) design = {}
        if (!design.position) design.position = "right";

        var titleContainer = <><div className="d-inline-block ">{altT(data.title)}</div>{data.children ? data.children.map((child) => modelFctManager.componentTyped(child, parentProps)) : ""}</>;



        //TODO : w-100 à voir avec les children... + /!\ le click s'effectue sur tout le label...
        return <Label check for={"input" + bindPropResult} className={`d-inline-block px-0 w-100 ${(!commonFunc.isBlank(data.title) && data.required) ? "mandatory" : ""}`} style={{minWidth:"16px"}}>
            {(design.position === "top" || design.position === "left") ? <div className={`d-inline-block me-2 ${titleWidthClass}`}>{titleContainer}</div> : ""}
            {contentInput}
            {(design.position === "bottom" || design.position === "right") ? <div className={`d-inline-block ms-${commonFunc.isBlank(data.title) ? 2 : 4} ${titleWidthClass}`}>{titleContainer}</div> : ""}
            <FormFeedback className={`text-start ${commonFunc.getValue(errors, bindPropResult) ? "d-block" : ""}`}>{commonFunc.getValue(errors, bindPropResult) ? commonFunc.getValue(errors, bindPropResult).message : t("gal_reqFields")}</FormFeedback>
        </Label>;
    }

    var frameClass = "mb-3";
    var frameStyle = {};
    var containerClass = "";
    if (data.hidden) frameClass += " d-none";
    if (data.checkRights) {
        var hasRights = modelFctManager.checkRights(data.checkRights.entity, data.checkRights.rights);
        if (!hasRights) frameClass += " d-none";
    }

    var formClass = "form-group m-0 ";

    var widthClass = "";
    var titleWidthClass = "px-0 ";
    if (data.customDesign) {
        if (data.customDesign.design) {
            var design = data.customDesign.design;
            if (data.customDesign.design.boxSize) {
                //frameClass += " w-" + parseInt(parseFloat(data.customDesign.design.boxSize) * 100);
                frameClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.design.boxSize) * 100);
            }

            if (design.margin) {
                frameStyle.margin = design.margin;
                frameClass = frameClass.replace("mb-3", "");
            }
            if (design.marginStart) frameStyle.marginLeft = design.marginStart;
            if (design.marginTop) frameStyle.marginTop = design.marginTop;
            if (design.marginEnd) frameStyle.marginRight = design.marginEnd;
            if (design.marginBottom) {
                frameStyle.marginBottom = design.marginBottom;
                frameClass = frameClass.replace("mb-3", "");
            }
        }
        if (data.customDesign.designInput) {
            if (data.customDesign.designInput.boxSize) {
                //widthClass += " w-" + parseInt(parseFloat(data.customDesign.designInput.boxSize) * 100);
                widthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designInput.boxSize) * 100);
            }
        }
        if (data.customDesign.designTitle) {
            if (data.customDesign.designTitle.alignment) {
                var split = data.customDesign.designTitle.alignment.split("|");
                split.forEach((part) => {
                    titleWidthClass += " text-" + part.replace("left", "start").replace("right", "end");
                    titleWidthClass = titleWidthClass.replace("text-top", "align-self-start").replace("text-bottom", "align-self-end");
                });
            }
            if (data.customDesign.designTitle.boxSize) {
                //titleWidthClass += " w-" + parseInt(parseFloat(data.customDesign.designTitle.boxSize) * 100);
                titleWidthClass += modelFctManager.convertWidthToGrid(parseFloat(data.customDesign.designTitle.boxSize) * 100).replace("col-12", "").replace("md-", "");
                titleWidthClass = titleWidthClass.replace("px-0", "ps-3 px-md-0").replace("text-end", "text-md-end");
            }
        }

        if (!commonFunc.isBlank(widthClass) || titleWidthClass !== "px-0 ") containerClass += " row m-0 row-cols-lg-auto2 align-items-center";
    }

    var baseForm = <div className={`form-check form-check-inline fcheck-sm w-100 ${containerClass} ${formClass}`}>
        {renderTitle(titleWidthClass, renderInput(widthClass))}
    </div>;


    //console.debug("render input");

    return <Col data-name="CheckboxFrag" data-element={data.objType} xs="auto" className={` ${frameClass} ${parentProps.parentData.customDesign && parentProps.parentData.customDesign.design && parentProps.parentData.customDesign.design.spacing ? "" : "p-0"}`} style={frameStyle}>{baseForm}</Col>;//align-self-center
}